import { Card } from "react-bootstrap";
import InsightsSpinner from "./insightsspinner/InsightsSpinner";
import Histogram from "./charts/Histogram";
import { sort } from "../../utils/sort";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";
import { useFilters } from "../../hooks/useFilters";
import { useSearchParams } from "react-router-dom";

const BeatsHistogram = ({ beatsCountResponse }: { beatsCountResponse: any }) => {
    const [searchParams] = useSearchParams();
    const { selectedAgency } = useSingleAgencyFilter();
    const { filters, beatsData, selectedBeats } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });
    const beatCountDataFetching = beatsCountResponse.isFetching;
    const beatCountData = beatsCountResponse.data;
    // if we have selectedBeats we only want to show those on the histogram otherwise show all beats
    const beats = selectedBeats?.length > 0 ? selectedBeats : beatsData?.results;

    const histBuckets =
        !beatCountDataFetching &&
        beatCountData &&
        beatCountData?.aggregations &&
        Object.keys(beatCountData?.aggregations)
            .map((beat: any) => {
                // backend agg will return agency_desc + "-" + beat_name for each beat so we need to remove the agency_desc
                // from the name when displaying on the chart
                const agency_desc = beats.find(
                    (filterBeat: any) => filterBeat.agency_desc + "-" + filterBeat.beat_name === beat
                )?.agency_desc;
                return { beat: beat.replace(agency_desc + "-", ""), count: beatCountData?.aggregations[beat]?.doc_count };
            })
            .sort(sort("beat", true))
            .reduce(
                (coll: any, curr: any) => {
                    coll.labels.push(curr.beat);
                    coll.counts.push(curr.count);
                    return coll;
                },
                { labels: [], counts: [] }
            );

    return (
        <Card>
            <Card.Header>
                <Card.Title className="m-0" as="h3">
                    Beats
                </Card.Title>
            </Card.Header>
            <Card.Body className="overflow-auto" style={{ height: "28em" }}>
                {beatCountDataFetching ? (
                    <InsightsSpinner />
                ) : histBuckets && histBuckets.labels && histBuckets.labels.length ? (
                    <Histogram
                        title=""
                        processedData={histBuckets || {}}
                        xAxis=""
                        yAxis="Number of Events"
                        onPointClick={(e: any) => {
                            // Multi-agency situations may have multiple beats of the same name make sure we have the correct one
                            // when passing the data to main search
                            const subAgencyBeat = beatsData.results.find((b: any) => {
                                return !!selectedAgency
                                    ? b.agency_id === selectedAgency.fm_nickname && e.point.category === b.beat_name
                                    : e.point.category === b.beat_name;
                            });

                            let newFilters = { ...filters };
                            newFilters["beats"] = [
                                {
                                    name: subAgencyBeat.agency_desc + "-" + subAgencyBeat.beat_name,
                                    agency_desc: subAgencyBeat.agency_desc,
                                    agency_id: subAgencyBeat.agency_id,
                                    beat_name: subAgencyBeat.beat_name,
                                },
                            ];

                            if (selectedAgency) {
                                newFilters["agency_ids"] = [subAgencyBeat.agency_id];
                                searchParams.set("agencies", selectedAgency?.fm_uuid);
                            }

                            searchParams.set("filters", JSON.stringify(newFilters));

                            window.open(`/app/search?${searchParams}`, "_blank");
                        }}
                    />
                ) : (
                    <h1 className="text-center">No Events</h1>
                )}
            </Card.Body>
        </Card>
    );
};

export default BeatsHistogram;
