import React from "react";
import { faRefresh } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";

type OwnProps = {
    userExists: boolean;
    isMaintenanceMode: boolean;
};

/**
 *
 * Renders the maintenance page when the current agency is in maintenance mode,
 * and redirects to / when not in maintenance mdoe
 * @returns <MaintenancePage />
 */
const ConnectedMaintenancePage = () => {
    const user = useAppSelector(({ user }) => user.userObject);

    return <MaintenancePage userExists={!!user && user.id} isMaintenanceMode={!!user && user.agency_maintenance_mode} />;
};

export const MaintenancePage = ({ userExists, isMaintenanceMode }: OwnProps) => {
    const navigate = useNavigate();

    React.useEffect(() => {
        if (userExists && !isMaintenanceMode) {
            navigate("/");
        }
    }, [isMaintenanceMode, navigate, userExists]);

    return (
        <Container className="h-100 d-flex flex-1 align-items-center justify-content-center">
            <Row>
                <Col className="text-center">
                    <h1 className="display-3 fw-bold">We'll be back soon</h1>
                    <p className="fs-2 mb-6 mx-auto">We are currently down for maintenance</p>
                    <Button onClick={() => navigate(0)}>
                        <FontAwesomeIcon icon={faRefresh} /> Check for updates
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default ConnectedMaintenancePage;
