import { faGavel } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Stack, Table } from "react-bootstrap";
import { INCIDENT_ACCENT } from "../../utils/colors";
import { formatBirthdayAndDOB, formatDate } from "../../utils/date";
import { formatName } from "../../utils/person";
import SortableHeaderColumn from "../SortableHeader";
import Tags from "../tags/Tags";
import { sortSortableHeader } from "../../utils/sort";
import { SortDirectionEnum } from "../../types/old_v1/types";
import { useColumnSort } from "../../hooks/useColumnSort";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import { useManageSearchParams } from "../../hooks/useManageSearchParams";
import useAgencySettings from "../../hooks/useAgencySettings";
import LinkWrapper from "../LinkWrapper";

type OwnProps = {
    selectedIncident: any;
    setSelectedIncident: Function;
    defaultSort?: string;
    defaultSortOrder?: SortDirectionEnum;
};

const AssociatedPeopleModal = ({
    selectedIncident,
    setSelectedIncident,
    defaultSort = "birthdate",
    defaultSortOrder = SortDirectionEnum.DESCENDING,
}: OwnProps) => {
    const { constructPersonUrl } = useMultiAgency();
    const { processSearchParams } = useManageSearchParams();
    const { incidentText } = useAgencySettings();
    const { sortColumn, sortDirection, setSort } = useColumnSort(defaultSort, defaultSortOrder);
    const sortedPeople = (Array.isArray(selectedIncident?.involved_people) ? [...selectedIncident.involved_people] : []).sort(
        sortSortableHeader(sortColumn, ["full_name", "birthdate", "gender", "role", "race"].includes(sortColumn), sortDirection)
    );

    return (
        <Modal show={!!selectedIncident} onHide={() => setSelectedIncident(null)} size="xl">
            <Modal.Header closeButton>
                <>
                    <FontAwesomeIcon icon={faGavel} className="fs-2 me-3" color={INCIDENT_ACCENT} />
                    <Modal.Title className="me-3">
                        {incidentText} {selectedIncident.incident_number}{" "}
                        {selectedIncident.incident_type ? ` | ${selectedIncident.incident_type}` : ""}
                    </Modal.Title>
                    <span>{formatDate(selectedIncident.occurred_at)}</span>
                </>
            </Modal.Header>
            <Modal.Body className="overflow-auto">
                <Stack gap={3}>
                    <Tags tags={selectedIncident.flags} />
                    <h3>Associated People ({sortedPeople?.length})</h3>
                    <Table>
                        <thead>
                            <tr>
                                <SortableHeaderColumn
                                    columnName="Name"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey={"full_name"}
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="DOB"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey={"birthdate"}
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="Race"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey={"race"}
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="Gender"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey={"gender"}
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="Role"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey={"role"}
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {sortedPeople.map((person: any) => {
                                const constructedPersonURL = constructPersonUrl(
                                    selectedIncident?.agency_id,
                                    person,
                                    processSearchParams(["query"])
                                );
                                return (
                                    <tr key={`${person.person_unique_key_str}-${person.role}`}>
                                        <td>
                                            <LinkWrapper
                                                isAnchor={constructedPersonURL.isNextFELink}
                                                className="ms-3 d-inline-block pendo_associated_people_table_item_link"
                                                to={constructedPersonURL.url}
                                                onClick={() => setSelectedIncident(null)}
                                            >
                                                {formatName(person)}
                                            </LinkWrapper>
                                        </td>
                                        <td>{!!person.birthdate && <span>{formatBirthdayAndDOB(person.birthdate)}</span>}</td>
                                        <td>{person.race}</td>
                                        <td>{person.gender}</td>
                                        <td>{person.role}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Stack>
            </Modal.Body>
        </Modal>
    );
};

export default AssociatedPeopleModal;
