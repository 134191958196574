import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../support/errorboundary/ErrorBoundary";
import { getIncidentAndCallDataFromBucket } from "../../../utils/elastic";

type GridOwnProps = {
    data: any;
    cat: string;
    subcat: string;
    apiUrl: string;
};

const getDataFromBuckets = (data: any) => {
    const dataToReturn = {
        oneWeek: {
            incidents: 0,
            calls: 0,
        },
        oneMonth: {
            incidents: 0,
            calls: 0,
        },
        threeMonths: {
            incidents: 0,
            calls: 0,
        },
    };

    if (data?.buckets) {
        const oneWeekBucket = data?.buckets.find((bucket: any) => bucket.key === "7-day");
        const oneWeekData = getIncidentAndCallDataFromBucket(oneWeekBucket);

        const oneMonthBucket = data?.buckets.find((bucket: any) => bucket.key === "1-month");
        const oneMonthData = getIncidentAndCallDataFromBucket(oneMonthBucket);

        const threeMonthsBucket = data?.buckets.find((bucket: any) => bucket.key === "3-month");
        const threeMonthsData = getIncidentAndCallDataFromBucket(threeMonthsBucket);

        dataToReturn.oneWeek.calls = oneWeekData.calls;
        dataToReturn.oneWeek.incidents = oneWeekData.incidents;

        dataToReturn.oneMonth.calls = oneMonthData.calls;
        dataToReturn.oneMonth.incidents = oneMonthData.incidents;

        dataToReturn.threeMonths.calls = threeMonthsData.calls;
        dataToReturn.threeMonths.incidents = threeMonthsData.incidents;
    }

    return dataToReturn;
};

/**
 * Creates the Grid presentation component
 * @param data
 * @param cat
 * @param subcat
 * @constructor
 */

const Grid = ({ data, cat, subcat, apiUrl }: GridOwnProps) => {
    const searchParams = new URLSearchParams({ query: subcat, category: cat, subcat, page: apiUrl });
    const partialUrl = `../metrics-results?${searchParams.toString()}`;
    let cleanedData;

    if (data?.types_count) {
        cleanedData = getDataFromBuckets(data?.types_count);
    }
    return (
        <ErrorBoundary errorComponentVariant="large">
            <div className="table-responsive">
                <Table hover={true}>
                    <thead>
                        <tr>
                            <th className="text-center"></th>
                            <th className="text-center">Last Week</th>
                            <th className="text-center">Last Month</th>
                            <th className="text-center">Last 3 Months</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key="calls">
                            <td className="text-center fw-bold">911 Calls</td>
                            <td id={`${cat}-${subcat}-lastWeek-calls`} className="text-center fw-bold">
                                {cleanedData?.oneWeek?.calls ? (
                                    <Link to={`${partialUrl}&index=calls&daysBack=7`}>{cleanedData?.oneWeek?.calls}</Link>
                                ) : (
                                    0
                                )}
                            </td>
                            <td id={`${cat}-${subcat}-lastMonth-calls`} className="text-center fw-bold">
                                {cleanedData?.oneMonth?.calls ? (
                                    <Link to={`${partialUrl}&index=calls&daysBack=30`}>{cleanedData?.oneMonth?.calls}</Link>
                                ) : (
                                    0
                                )}
                            </td>
                            <td id={`${cat}-${subcat}-lastThreeMonths-calls`} className="text-center fw-bold">
                                {cleanedData?.threeMonths?.calls ? (
                                    <Link to={`${partialUrl}&index=calls&daysBack=90`}>{cleanedData?.threeMonths?.calls}</Link>
                                ) : (
                                    0
                                )}
                            </td>
                        </tr>
                        <tr key="incidents">
                            <td className="text-center fw-bold">Police Reports</td>
                            <td id={`${cat}-${subcat}-lastWeek-reports`} className="text-center fw-bold">
                                {cleanedData?.oneWeek?.incidents ? (
                                    <Link to={`${partialUrl}&index=incidents&daysBack=7`}>{cleanedData?.oneWeek?.incidents}</Link>
                                ) : (
                                    0
                                )}
                            </td>
                            <td id={`${cat}-${subcat}-lastMonth-reports`} className="text-center fw-bold">
                                {cleanedData?.oneMonth?.incidents ? (
                                    <Link to={`${partialUrl}&index=incidents&daysBack=30`}>{cleanedData?.oneMonth?.incidents}</Link>
                                ) : (
                                    0
                                )}
                            </td>
                            <td id={`${cat}-${subcat}-lastThreeMonths-reports`} className="text-center fw-bold">
                                {cleanedData?.threeMonths?.incidents ? (
                                    <Link to={`${partialUrl}&index=incidents&daysBack=90`}>{cleanedData?.threeMonths?.incidents}</Link>
                                ) : (
                                    0
                                )}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </ErrorBoundary>
    );
};

export default Grid;
