import React from "react";

type OwnProps = {
    small?: boolean;
    centered?: boolean;
};

const FMSpinner = ({ small, centered }: OwnProps) => {
    return (
        <div
            className={`spinner-border ${!!small ? "spinner-border-sm" : ""} ${centered ? "mx-auto" : ""} text-primary`}
            role="status"
            data-testid="spinner"
        >
            <span className="visually-hidden">Loading...</span>
        </div>
    );
};

export default FMSpinner;
