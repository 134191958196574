import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faPoliceBox } from "@fortawesome/pro-light-svg-icons";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useGetUniqueCallTypesQuery } from "../../../api/api";
import { processFilterAgg } from "../../../utils/old_v1/helpers";
import OptionFilter from "./OptionFilter";
import { RadarConfigNameEnum } from "../../../types/old_v1/types";
import useAgencySettings from "../../../hooks/useAgencySettings";
import { useFilters } from "../../../hooks/useFilters";

const CallTypeFilterConnected = ({ close, clearView, pendoClass = "" }: { close: Function; clearView?: boolean; pendoClass?: string }) => {
    const { data: optionData } = useGetUniqueCallTypesQuery({});
    const options = processFilterAgg(optionData);

    return <CallTypeFilter close={close} options={options} clearView={clearView} pendoClass={pendoClass} />;
};

const CallTypeFilter = ({
    close,
    options,
    clearView,
    pendoClass = "",
}: {
    close: Function;
    options: any[];
    clearView?: boolean;
    pendoClass?: string;
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { callText } = useAgencySettings();
    const { updateFilters } = useFilters({ skipBeatsQuery: true });
    const callTypes = searchParams.get("callTypes");
    const callTypesList = callTypes ? callTypes.split(",") : [];
    const label = callTypes
        ? callTypesList.length > 1
            ? `${callTypesList.length} ${callText} Types`
            : decodeURIComponent(callTypes)
        : `${callText} Types`;

    const clear = () => {
        const updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.delete("callTypes");
        if (clearView) {
            updatedSearchParams.set("view", RadarConfigNameEnum.CUSTOM);
        }
        setSearchParams(updatedSearchParams.toString());
        updateFilters("callTypes", null, updatedSearchParams);
    };

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            placement={"bottom-start"}
            overlay={
                <Popover className="mw-100 hide-arrow">
                    <Popover.Body>
                        <OptionFilter
                            options={options}
                            paramName="callTypes"
                            pendoClass={pendoClass}
                            close={close}
                            label={`Choose ${callText} Types`}
                            clearView={clearView}
                        />
                    </Popover.Body>
                </Popover>
            }
        >
            <Button
                data-testid="call-filter-button"
                className="cursor-pointer mb-2 ms-2 text-nowrap rounded-pill"
                variant={callTypesList && callTypesList.length ? "secondary" : "outline-secondary"}
                size="sm"
                onClick={() => close("callTypes")}
            >
                <FontAwesomeIcon icon={faPoliceBox} />
                &nbsp;{label}
                {callTypes && (
                    <FontAwesomeIcon
                        size="sm"
                        icon={faCircleXmark}
                        className="ms-1"
                        onClick={(e) => {
                            e.stopPropagation();
                            clear();
                        }}
                    />
                )}
            </Button>
        </OverlayTrigger>
    );
};

export default CallTypeFilterConnected;
