import { useAppSelector } from "../app/hooks";
import { PermissionType } from "../types/old_v1/types";

type PermissionsConfig = {
    hasPermission: (permission: PermissionType) => boolean;
};

/**
 * Hook that exposes whether user has certain permission via hasPermission function
 * @returns {PermissionsConfig}
 */
const usePermissions = (): PermissionsConfig => {
    const userObject = useAppSelector(({ user }) => user.userObject);

    const hasPermission = (permission: PermissionType) => userObject?.permissions?.includes(permission) || false;

    return {
        hasPermission,
    };
};

export default usePermissions;
