import { useSearchParams } from "react-router-dom";
import { useMultiAgency } from "./useMultiAgency";
import { getQueryParams } from "../utils/url";

const useSingleAgencyFilter = () => {
    const [searchParams] = useSearchParams();
    const { isMultiAgency, userSubAgencies } = useMultiAgency();

    const agencyUUID = searchParams.get("agencyUUID");

    const subAgencies = userSubAgencies as any[];
    const urlAgency: any = subAgencies?.find((agency: any) => agency?.fm_uuid === agencyUUID);

    const selectedAgency = !isMultiAgency ? undefined : urlAgency || (subAgencies?.length ? subAgencies[0] : {});

    // agencyUUID is only needed for multiagency
    if (isMultiAgency) {
        searchParams.set("agencyUUID", selectedAgency?.fm_uuid);
    } else {
        searchParams.delete("agencyUUID");
    }

    const queryParams = getQueryParams(searchParams);

    return {
        selectedAgency,
        queryParams,
        searchParams,
    };
};

export default useSingleAgencyFilter;
