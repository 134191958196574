import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, ListGroup, Offcanvas } from "react-bootstrap";
import { useSignOut } from "../hooks/useSignOut";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setShowFeedbackModal } from "../app/slices/user/userSlice";
import usePermissions from "../hooks/usePermissions";
import ErrorBoundary from "../pages/support/errorboundary/ErrorBoundary";

type OwnProps = {
    showUserMenu: boolean;
    setShowUserMenu: Function;
};
export const UserMenu = ({ showUserMenu, setShowUserMenu }: OwnProps) => {
    const dispatch = useAppDispatch();
    const { userObject } = useAppSelector(({ user }) => user);
    const { hasPermission } = usePermissions();

    const navigate = useNavigate();

    const closeMenu = () => {
        setShowUserMenu(false);
    };

    const openFeedbackModal = () => {
        dispatch(setShowFeedbackModal({ show: true }));
        closeMenu();
    };

    const { signOutAndReRoute } = useSignOut();

    const signOut = () => {
        closeMenu();
        signOutAndReRoute();
    };

    const openPicker = () => {
        window.location.assign("/user/switch-agency");
        closeMenu();
    };

    const openSettings = () => {
        navigate("settings");
        closeMenu();
    };

    const openKnowledgeBase = () => {
        navigate("knowledge-base/public");
        closeMenu();
    };

    return (
        <Offcanvas show={showUserMenu} onHide={closeMenu} placement="end">
            <Offcanvas.Header closeButton className="border-bottom d-flex align-items-start">
                <div className="flex-column">
                    <h3>Admin Menu</h3>
                    <div className="mb-3">
                        {userObject?.first_name || ""} {userObject?.last_name || ""}
                    </div>
                    <div className="text-muted">{userObject?.agency_name || ""}</div>
                    <div className="text-muted">{userObject?.agency_shortname || ""}</div>
                </div>
            </Offcanvas.Header>
            {/** error boundary placed here and not also around header, because app will not be operable if header errors
             * so higher level error boundary will handle that case */}
            <ErrorBoundary errorComponentVariant="large">
                <Offcanvas.Body>
                    <Container>
                        <ListGroup variant="flush">
                            {hasPermission("switch_agencies") && (
                                <ListGroup.Item>
                                    <span role="button" className="nav-link" onClick={openPicker}>
                                        Switch Agency
                                    </span>
                                </ListGroup.Item>
                            )}
                            {hasPermission("user_admin_view_users") && (
                                <ListGroup.Item>
                                    <Link className="nav-link" to="/app/user-admin" onClick={closeMenu}>
                                        User Administration
                                    </Link>
                                </ListGroup.Item>
                            )}
                            {hasPermission("knowledge_base_view") && (
                                <ListGroup.Item>
                                    <span role="button" className="nav-link" onClick={openKnowledgeBase}>
                                        Knowledge Base
                                    </span>
                                </ListGroup.Item>
                            )}
                            {hasPermission("submit_feedback") && (
                                <ListGroup.Item>
                                    <span role="button" className="nav-link" onClick={openFeedbackModal}>
                                        Submit Feedback
                                    </span>
                                </ListGroup.Item>
                            )}
                            <ListGroup.Item>
                                <span role="button" className="nav-link" onClick={openSettings}>
                                    Settings
                                </span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span role="button" className="nav-link text-danger" onClick={signOut}>
                                    Sign Out
                                </span>
                            </ListGroup.Item>
                            <ListGroup.Item className="d-none"> </ListGroup.Item>
                        </ListGroup>
                    </Container>
                </Offcanvas.Body>
            </ErrorBoundary>
        </Offcanvas>
    );
};

export default UserMenu;
