import React from "react";
import { ASCENDING, DESCENDING } from "../../utils/old_v1/sort";

export const useColumnSort = (
    defaultColumn: string,
    defaultDirection?: string
): {
    sortColumn: string;
    sortDirection: string;
    setSort: (column: string, revertToDefault?: boolean, newSortDirection?: string) => void;
} => {
    const [sortColumn, setSortColumn] = React.useState<string>(defaultColumn);
    const [sortDirection, setSortDirection] = React.useState<string>(defaultDirection ?? ASCENDING);

    const setSort = (column: string, revertToDefault?: boolean, newSortDirection?: string): void => {
        if (column === sortColumn) {
            const otherDirection = sortDirection === ASCENDING ? DESCENDING : ASCENDING;
            setSortDirection(otherDirection);
        } else {
            setSortColumn(column);
            if (newSortDirection) {
                setSortDirection(newSortDirection);
            } else if (revertToDefault && defaultDirection) {
                setSortDirection(defaultDirection);
            } else {
                setSortDirection(ASCENDING);
            }
        }
    };

    return {
        sortColumn,
        sortDirection,
        setSort,
    };
};
