import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetUserAgenciesQuery, useGetUserDetailsQuery, useUpdateUserAgenciesMutation } from "../../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faUser } from "@fortawesome/pro-regular-svg-icons";
import FMSpinner from "../../components/Spinner";
import { useAppDispatch } from "../../app/hooks";
import { setUserObject } from "../../app/slices/user/userSlice";
import { Alert, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { faPoliceBox } from "@fortawesome/pro-light-svg-icons";
import SortButton from "../../components/SortButton";
import { SortConfig, SortDirectionEnum } from "../../types/old_v1/types";
import { sortSortableHeader } from "../../utils/sort";

type OwnProps = {
    agencies: any[] | undefined;
    update: (roleId: string) => void;
    loading: boolean;
    updatedAgencySuccessfully?: boolean | null;
    setUpdatedAgencySuccessfully: React.Dispatch<React.SetStateAction<boolean | null>>;
};

const sortConfig: SortConfig = {
    agency_name: {
        label: "Name",
        defaultSortDirection: SortDirectionEnum.ASCENDING,
        sortString: true,
    },
};

const CustomerSitePicker = ({ agencies, update, loading, updatedAgencySuccessfully, setUpdatedAgencySuccessfully }: OwnProps) => {
    const [filterString, setFilterString] = React.useState("");
    const [sortField, setSortField] = React.useState("agency_name");
    const [sortDirection, setSortDirection] = React.useState(SortDirectionEnum.ASCENDING);

    const filteredAgencies = !!agencies
        ? agencies.filter(
              (agency) =>
                  agency?.agency_name?.toLowerCase().includes(filterString) ||
                  agency?.agency_shortname?.toLowerCase().includes(filterString) ||
                  agency?.agency_state?.toLowerCase().includes(filterString) ||
                  agency?.uuid?.toLowerCase().includes(filterString)
          )
        : [];

    const sortedAgencies = [...filteredAgencies].sort(sortSortableHeader(sortField, true, sortDirection));
    const chooseAgency = (agencyId: string) => {
        // update agency and set search param flag for alert
        update(agencyId);
    };

    const onAlertClose = () => {
        setUpdatedAgencySuccessfully(null);
    };

    return (
        <Container className="my-4" fluid="xl">
            <Row className="d-flex justify-content-center mb-3">
                <Col xs={9}>
                    <Alert
                        variant={updatedAgencySuccessfully === null || updatedAgencySuccessfully ? "success" : "danger"}
                        dismissible
                        onClose={onAlertClose}
                        show={updatedAgencySuccessfully !== null}
                    >
                        <span>
                            {updatedAgencySuccessfully === null || updatedAgencySuccessfully
                                ? "Updated agency successfully!"
                                : "There was an issue switching agencies"}
                        </span>
                    </Alert>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mb-3">
                <Col xs={5} className="d-flex align-items-center">
                    <h2 className="text-nowrap">
                        <FontAwesomeIcon icon={faPoliceBox} />
                        &nbsp;Switch Agency
                    </h2>
                </Col>
                <Col xs={12} sm={12} md={4}>
                    <Stack gap={3} direction="horizontal">
                        <Form.Control
                            data-testid="customer-site-input"
                            as="input"
                            placeholder="Search"
                            onChange={(e) => setFilterString(e.target.value)}
                            autoFocus
                        />
                        <SortButton
                            sortField={sortField}
                            setSortField={setSortField}
                            sortConfig={sortConfig}
                            sortDirection={sortDirection}
                            setSortDirection={setSortDirection}
                        />
                    </Stack>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={12} md={9}>
                    <div className="fm-bg-color border-0 border-md rounded p-4 d-flex flex-column flex-grow-1 overflow-auto mb-5">
                        <ul className="list-group list-group-flush list my-n3">
                            <>
                                {agencies &&
                                    !loading &&
                                    agencies?.length > 0 &&
                                    sortedAgencies.map((agency) => (
                                        <li className="list-group-item" key={agency.id}>
                                            <div className="row align-items-center">
                                                <div className="col ms-n2">
                                                    <h4 className="mb-1 name">
                                                        <span className="active">{agency.agency_name}</span>
                                                    </h4>

                                                    <p className="small mb-0">
                                                        <span className="text-muted">
                                                            {agency.agency_shortname} | {agency.agency_state} | {agency.uuid}
                                                        </span>
                                                    </p>

                                                    <p className="small mb-0">{agency.role}</p>
                                                </div>

                                                <div className="col-auto">
                                                    <button
                                                        onClick={() => chooseAgency(agency.id as string)}
                                                        data-testid={agency.agency_shortname}
                                                        className={`btn  ${
                                                            agency.current_role ? "btn-sm btn-success text-white" : "btn-outline-primary"
                                                        }`}
                                                        disabled={agency.current_role ? agency.current_role : false}
                                                        data-bs-dismiss="modal"
                                                    >
                                                        {agency.current_role ? (
                                                            <>
                                                                <FontAwesomeIcon icon={faCheck} /> Selected
                                                            </>
                                                        ) : (
                                                            <>
                                                                <FontAwesomeIcon icon={faUser} /> Select
                                                            </>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                {loading && (
                                    <div className="text-center p-4">
                                        <FMSpinner />
                                    </div>
                                )}
                                {!loading && sortedAgencies?.length === 0 && (
                                    <div className="text-center p-4">
                                        <h3 className="mb-0">No Agencies Found</h3>
                                    </div>
                                )}
                            </>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

const ConnectedCustomerSitePicker = () => {
    const [agencies, setAgencies] = React.useState([]);
    const [skip, setSkip] = React.useState(true);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [updatedAgencySuccessfully, setUpdatedAgencySuccessfully] = React.useState<boolean | null>(null);

    const { data, error, isLoading, isFetching } = useGetUserAgenciesQuery({});
    // skip initial render until updateRole executes and succeeds, then run useGetUserDetailsQuery
    const { data: userData, refetch } = useGetUserDetailsQuery({}, { skip });

    const [updateRole] = useUpdateUserAgenciesMutation();

    const update = (id: string) => {
        updateRole({ id })
            .then(() => {
                // set skip to false to activate useGetUserDetailsQuery
                setSkip(false);
                refetch();
            })
            .catch(() => {
                setUpdatedAgencySuccessfully(false);
            });
    };

    React.useEffect(() => {
        // We've switched customers, time to update redux, nav to landing, and reload to refresh data completely.
        if (userData) {
            dispatch(setUserObject(userData?.results));
            setUpdatedAgencySuccessfully(true);
        }
    }, [userData, dispatch, navigate]);

    React.useEffect(() => {
        if (data?.results) {
            setAgencies(data?.results as any);
        }
    }, [data, error]);

    return (
        <CustomerSitePicker
            agencies={agencies}
            update={update}
            loading={isLoading || isFetching}
            updatedAgencySuccessfully={updatedAgencySuccessfully}
            setUpdatedAgencySuccessfully={setUpdatedAgencySuccessfully}
        />
    );
};

CustomerSitePicker.displayName = "CustomerSitePicker";
export default ConnectedCustomerSitePicker;
