import { Button } from "react-bootstrap";
import React from "react";
import { FollowType, useFollow } from "../hooks/useFollow";
import FmAlert from "../pages/support/FmAlert";

type OwnProps = {
    followText: string;
    type: FollowType;
    variant?: string;
};
const FollowButton = ({ followText, type, variant }: OwnProps) => {
    const { saveFollow, showAlert, hideAlert, alertContent, followed } = useFollow(type, followText);
    const pendoClassName = `pendo_${followed ? "unfollow" : "follow"}_button`;

    const confirmUnfollow = () => {
        if (window.confirm("Are you sure you want to unfollow?")) {
            saveFollow();
        }
    };

    return (
        <div>
            {showAlert && (
                <div className="mt-4 mx-4 position-fixed mx-auto" style={{ zIndex: 10, top: "60px", left: 0, width: "100%" }}>
                    <div className="d-flex justify-content-center w-50 mx-auto text-center">
                        <FmAlert alertKey={alertContent.key} variant={alertContent.variant} onClose={hideAlert} />
                    </div>
                </div>
            )}
            <Button
                variant={variant || "outline-light"}
                size="sm"
                onClick={followed ? confirmUnfollow : saveFollow}
                className={`me-2 ${pendoClassName}`}
            >
                {followed ? `Following` : `Follow`}
            </Button>
        </div>
    );
};

export default FollowButton;
