export const unique = (arr: any) => Array.from(new Set(arr));

export const sameElements = (a: any[], b: any[]) => a.sort().join(",") === b.sort().join(",");

export const chunkArray = (a: any[], chunkSize: number) => {
    return a.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);
};

export const range = (size: number, startAt: number = 0) => {
    return Array.from(Array(size).keys()).map((i) => i + startAt);
};
