import React from "react";
import { Card } from "react-bootstrap";
import { useGetCallNotesQuery } from "../../../api/api";
import CardHeader from "../../../components/cardheader/CardHeader";
import { elasticHitsExtraction } from "../../../utils/elastic";
import BaseCallNotes from "./BaseCallNotes";
import CallNotesModal from "./CallNotesModal";
import { useLocation } from "react-router-dom";
import { getBeautifiedESKeyValuePairs } from "../../../utils/getKeyValuePairs";
import { useMultiAgency } from "../../../hooks/useMultiAgency";

type ConnectedProps = {
    callId: any;
    modalOption?: boolean;
    agencyId: string;
};

const CallNotesConnected = ({ callId, modalOption = true, agencyId }: ConnectedProps) => {
    const { getAgencyUUID } = useMultiAgency();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const query = params.get("query");

    const { data } = useGetCallNotesQuery({ id: callId, query, agencyUUID: getAgencyUUID(agencyId) }, { skip: !callId });

    const callNotes = elasticHitsExtraction(data);

    return <CallNotes callNotes={callNotes} modalOption={modalOption} />;
};

type OwnProps = {
    callNotes: any[];
    modalOption?: boolean;
};
const CallNotes = ({ callNotes, modalOption }: OwnProps) => {
    const [showMore, setShowMore] = React.useState(false);

    let beautifiedKeyValuePairs: any[] = [];
    if (callNotes && callNotes.length > 0) {
        callNotes.forEach((note: any) => {
            // copy highlights over into data to be formatted for modal display
            const highlightObject: Record<string, any> = {};
            if (note?.highlight) {
                Object.entries(note?.highlight).forEach(([key, value]) => (highlightObject[key as string] = value));
            }

            beautifiedKeyValuePairs.push(
                getBeautifiedESKeyValuePairs(
                    {
                        ...note._source,
                        ...highlightObject,
                    },
                    ["security_level"]
                )
            );
        });
    }

    const agencyId = callNotes && callNotes.length && callNotes[0]?._source?.agency_id;

    return modalOption ? (
        <React.Fragment>
            <Card className="h-100">
                <CardHeader title="Call Notes" showMoreFn={() => setShowMore(true)} pendoClassName="call-notes" />
                <Card.Body className="overflow-auto">
                    <BaseCallNotes callNotes={callNotes} />
                </Card.Body>
            </Card>
            <CallNotesModal callNotes={beautifiedKeyValuePairs} show={showMore} close={() => setShowMore(false)} agencyId={agencyId} />
        </React.Fragment>
    ) : (
        <BaseCallNotes callNotes={callNotes} />
    );
};

export default CallNotesConnected;
