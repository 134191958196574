import React from "react";
import { SortDirectionEnum } from "../types/old_v1/types";

/**
 * Little sort state helper, usually paired within a component with `<SortButton />`
 * @param defaultField Field to sort by default
 * @param defaultDirection
 * @returns
 */
const useSortState = (defaultField: string, defaultDirection: SortDirectionEnum) => {
    const [sortField, setSortField] = React.useState(defaultField);
    const [sortDirection, setSortDirection] = React.useState<SortDirectionEnum>(defaultDirection);

    return {
        sortField,
        setSortField,
        sortDirection,
        setSortDirection,
    };
};

export default useSortState;
