import React from "react";
import MapComponent from "./MapComponent";
import { LayerTypes, SourceConfig } from "../../types/old_v1/types";
import { MapLayerMouseEvent } from "mapbox-gl";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clusterMouseEnter, clusterMouseLeave } from "./eventhandlers/clusterMapEventHandlers";
import { buildDataSources, createAddressGeoJson, createGeoJson } from "./utils/gis";
import { useClusterMapEventHandlers } from "../../hooks/useClusterMapEventHandlers";

type OwnProps = {
    id?: string;
    searchResults: any;
    hoverEvent?: Function;
    type?: string;
    searchParams: any;
};

const SearchResultMap = ({ id = "search-result-map", searchResults, hoverEvent, type, searchParams }: OwnProps) => {
    const [dataSources, setDataSources] = React.useState<SourceConfig[]>([]);
    const dispatch = useAppDispatch();
    const { clusterClick } = useClusterMapEventHandlers();

    const currentPopupProperties = useAppSelector(({ map }) => map.popupProperties);

    React.useEffect(() => {
        if (!!searchResults) {
            const hits = searchResults;
            const geojson = type === LayerTypes.address ? createAddressGeoJson(hits) : createGeoJson(hits);

            const sources: SourceConfig[] = buildDataSources(geojson);
            setDataSources(sources);
        }
    }, [searchResults, type]);

    const mouseEnterEventHandler = (event: MapLayerMouseEvent) => {
        clusterMouseEnter(event, dispatch, hoverEvent);
    };

    const mouseLeaveEventHandler = () => {
        clusterMouseLeave(currentPopupProperties, dispatch, hoverEvent);
    };

    const clickEventHandler = (event: MapLayerMouseEvent) => {
        clusterClick(event);
    };

    return (
        <MapComponent
            fillContainer
            id={id}
            sources={dataSources}
            layerMouseEnterEventHandler={mouseEnterEventHandler}
            layerMouseOutEventHandler={mouseLeaveEventHandler}
            layerClickEventHandler={clickEventHandler}
        />
    );
};
export default SearchResultMap;
