const PERSON_ACCENT = "#008000";
const CALL_ACCENT = "#0784B5";
const ADDRESS_ACCENT = "#FF0000";
const INCIDENT_ACCENT = "#FFA500";
const IMPORTANT_EVENT_ACCENT = "#ffc107";
const NON_IMPORTANT_EVENT_ACCENT = "#6c757d";
const MAP_BORDER_ACCENT = "#989595";
export const LIGHT_GRAY = "#cccccc";
export const WHITE = "#fff";
export const BLACK = "#000";
export const TEAL = "#00ffc4";
export const VIOLET = "#9068f6";
export const YELLOW = "#ffea00";

// Heatmap colors
export const LIGHTER_BLUE = "#ADD8E6";
export const LIGHT_BLUE = "#7fb9da";
export const DARK_BLUE = "#083370";

export {
    PERSON_ACCENT,
    CALL_ACCENT,
    ADDRESS_ACCENT,
    INCIDENT_ACCENT,
    IMPORTANT_EVENT_ACCENT,
    NON_IMPORTANT_EVENT_ACCENT,
    MAP_BORDER_ACCENT,
};
