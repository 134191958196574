import React from "react";
import { format, parseISO } from "date-fns";
import { Card, ListGroup, Row, Col } from "react-bootstrap";
import { useGetActiveUsersQuery } from "../../api/api";
import FMSpinner from "../Spinner";

type User = {
    user_email: string;
    user_first_name: string;
    user_last_name: string;
    last_activity: string;
};

type OwnProps = {
    loading: boolean;
    users: User[];
};

/**
 * Displays the list of users that have run a query in the past 5 minutes.
 * @param loading
 * @param users
 * @constructor
 */
const ActiveUsers = ({ loading, users }: OwnProps) => {
    return (
        <Card>
            <Card.Header>Active Users (Searches since {format(new Date(), "MM/dd/yyyy HH:mm aaa")})</Card.Header>
            <Card.Body>
                {loading ? (
                    <Row>
                        <Col sm={{ span: 2, offset: 5 }}>
                            <FMSpinner />
                        </Col>
                    </Row>
                ) : (
                    <ListGroup>
                        {users &&
                            users?.length > 0 &&
                            users?.map((user: User) => (
                                <ListGroup.Item key={user?.user_email}>
                                    <div>
                                        <b>User:</b> {user?.user_first_name} {user?.user_last_name}
                                    </div>
                                    <div>
                                        <b>Email:</b> {user?.user_email}
                                    </div>
                                    <div>
                                        <b>Last Activity:</b> {format(parseISO(user?.last_activity), "MM/dd/yyyy HH:mm aaa")}
                                    </div>
                                </ListGroup.Item>
                            ))}
                        {(!users || users.length === 0) && <span>No active users in the past 60 minutes</span>}
                    </ListGroup>
                )}
            </Card.Body>
        </Card>
    );
};

const ActiveUsersConnected = () => {
    const { data, isLoading, isFetching } = useGetActiveUsersQuery({});

    return <ActiveUsers loading={isLoading || isFetching} users={data?.success ? data?.data?.results : []} />;
};

ActiveUsersConnected.displayName = "ActiveUsers";
export default ActiveUsersConnected;
