import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import FMSpinner from "../Spinner";
import { useGetIndexStatsQuery } from "../../api/api";
import { formatDate } from "../../utils/date";
import useAgencySettings from "../../hooks/useAgencySettings";

type OwnProps = {
    loading: boolean;
    stats: any;
};

/**
 * Displays the count of documents and the latest occurred_at data in each index (calls and incidents for now)
 * @param loading
 * @param stats
 * @constructor
 */
const IndexStats = ({ loading, stats }: OwnProps) => {
    const { incidentTextPlural, callTextPlural } = useAgencySettings();
    return (
        <Card>
            <Card.Header>Data Sync Info</Card.Header>
            <Card.Body>
                {loading ? (
                    <Row>
                        <Col sm={{ span: 2, offset: 5 }}>
                            <FMSpinner />
                        </Col>
                    </Row>
                ) : (
                    <Table>
                        <thead>
                            <tr>
                                <th>Index</th>
                                <th>Count</th>
                                <th>Most Recent</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{callTextPlural}</td>
                                <td>{stats?.calls?.count?.toLocaleString()}</td>
                                <td>{formatDate(stats?.calls?.latest)}</td>
                            </tr>
                            <tr>
                                <td>{incidentTextPlural}</td>
                                <td>{stats?.incidents?.count?.toLocaleString()}</td>
                                <td>{formatDate(stats?.incidents?.latest)}</td>
                            </tr>
                        </tbody>
                    </Table>
                )}
            </Card.Body>
        </Card>
    );
};

const IndexStatsConnected = () => {
    const { data, isLoading, isFetching } = useGetIndexStatsQuery({});

    return <IndexStats loading={isLoading || isFetching} stats={data} />;
};

IndexStatsConnected.displayName = "IndexStats";
export default IndexStatsConnected;
