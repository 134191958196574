import React from "react";
import { Modal } from "react-bootstrap";
import Highlighter from "../highlighter/Highlighter";
import ErrorBoundary from "../../pages/support/errorboundary/ErrorBoundary";

interface IModalCenter {
    children: React.ReactChild;
    title?: string | null;
    footer?: React.ReactChild;
    showCloseButton?: boolean;
    titleClasses?: string;
    show: boolean;
    setShow?: () => void;
    dialogClassName?: string;
    headerClasses?: string;
}

const ModalCenter = ({
    children,
    title,
    footer,
    showCloseButton = true,
    titleClasses,
    show,
    setShow,
    dialogClassName,
    headerClasses,
}: IModalCenter) => {
    return (
        <Modal show={show} onHide={setShow} centered={true} dialogClassName={dialogClassName}>
            <Modal.Header closeButton={showCloseButton} className={`modal-title ${headerClasses}`}>
                {title && (
                    <h2 data-testid="modal-header-title" className={`modal-title ${titleClasses}`}>
                        <Highlighter>{title}</Highlighter>
                    </h2>
                )}
            </Modal.Header>
            <ErrorBoundary errorComponentVariant="large">
                <Modal.Body>{children}</Modal.Body>
                {footer && <Modal.Footer>{footer}</Modal.Footer>}
            </ErrorBoundary>
        </Modal>
    );
};

export default ModalCenter;
