import { Card, Form, Stack } from "react-bootstrap";
import ShareEntity from "../../components/share/ShareEntity";
import Highlighter from "../../components/highlighter/Highlighter";
import { renderField } from "../../utils/string";
import AddressMap from "./AddressMap";
import PanelCell from "../../components/entity/PanelCell";
import { useSearchParams } from "react-router-dom";
import FollowButton from "../../components/FollowButton";
import AgencyDescription from "../../components/AgencyDescription";
import Button from "react-bootstrap/Button";
import React from "react";
import PeopleAtAddressModal from "../../components/entity/PeopleAtAddressModal";
import FMSpinner from "../../components/Spinner";
import useAgencySettings from "../../hooks/useAgencySettings";

type OwnProps = {
    address: any;
    isFetchingPremNotes: boolean;
    premNotesData: any;
    addressResidents: any[];
    events: any[];
};

const DEFAULT_DISTANCE = "50ft";

const DISTANCE_UNITS = [
    { name: "50 feet", value: "50ft", pendoClassName: "pendo_distance_radius_50ft" },
    { name: "100 feet", value: "100ft", pendoClassName: "pendo_distance_radius_100ft" },
    { name: "250 feet", value: "250ft", pendoClassName: "pendo_distance_radius_250ft" },
    { name: "100 yards", value: "100yd", pendoClassName: "pendo_distance_radius_100yd" },
    { name: "200 yards", value: "200yd", pendoClassName: "pendo_distance_radius_200yd" },
];
const AddressOverview = ({ address, premNotesData, addressResidents, isFetchingPremNotes, events }: OwnProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { incidentTextPlural } = useAgencySettings();
    const query = searchParams.get("query") || address.address;
    const distance = searchParams.get("distance") || DEFAULT_DISTANCE;
    const [showPeopleModal, setShowPeopleModal] = React.useState(false);

    const onDistanceChange = (event: any) => {
        const distance = event.target.value;
        let updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.set("distance", distance);
        setSearchParams(updatedSearchParams.toString());
    };

    let followText = "";
    if (address?.respond_to_address) {
        followText = address?.respond_to_address;
    } else {
        followText = address?.address;
    }

    return (
        <>
            <Card className="border-0 border-md overflow-hidden">
                <Card.Header className="bg-dark py-3 d-flex justify-content-between flex-wrap">
                    <div className="align-self-center text-white d-flex flex-column">
                        <span className="fw-bold">Address Details</span>
                        <AgencyDescription agencyDescription={address?.agency_desc} />
                    </div>
                    <Stack direction="horizontal" gap={2} className="align-items-start">
                        <FollowButton followText={followText} type={"address"} />
                        <ShareEntity />
                    </Stack>
                </Card.Header>
                <Card.Body className="p-0">
                    <AddressMap address={address} events={events} />
                    <Stack className="p-3" gap={3}>
                        <span className="fw-bold fs-3">
                            <Highlighter>{renderField(address?.full_address || address?.address, "Unknown Address")}</Highlighter>
                        </span>
                        <div>
                            <label>Viewing {incidentTextPlural} in radius of:&nbsp;</label>
                            {/* Desktop */}
                            <Form.Select
                                style={{ maxWidth: "10em" }}
                                name="distanceChange"
                                onChange={onDistanceChange}
                                value={distance}
                                size="sm"
                                className="pendo_distance_select cursor-pointer d-none d-md-block"
                            >
                                {DISTANCE_UNITS.map((distanceUnit) => (
                                    <option key={distanceUnit.value} value={distanceUnit.value} className={distanceUnit.pendoClassName}>
                                        {distanceUnit.name}
                                    </option>
                                ))}
                            </Form.Select>
                            {/* Mobile */}
                            <Form.Select
                                name="distanceChange"
                                onChange={onDistanceChange}
                                value={distance}
                                className="pendo_distance_select cursor-pointer d-block d-md-none w-100"
                            >
                                {DISTANCE_UNITS.map((distanceUnit) => (
                                    <option key={distanceUnit.value} value={distanceUnit.value} className={distanceUnit.pendoClassName}>
                                        {distanceUnit.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Stack>
                </Card.Body>
                {addressResidents && (
                    <Card.Header className="py-4 fs-3 text-center">
                        <Button
                            className="pendo_view_potential_residents"
                            onClick={() => setShowPeopleModal(true)}
                            disabled={addressResidents.length === 0}
                        >
                            {addressResidents.length} Potential {addressResidents.length === 1 ? "Resident" : "Residents"}
                        </Button>
                    </Card.Header>
                )}
                <Card.Body className="p-0">
                    {isFetchingPremNotes && (
                        <div className="text-center my-3">
                            <FMSpinner />
                        </div>
                    )}
                    {/* Show only if we got a success */}
                    {premNotesData?.success &&
                        !isFetchingPremNotes &&
                        (!premNotesData?.prem_notes || premNotesData?.prem_notes.length === 0) && (
                            <div className="px-3 py-3">
                                <span>No address notes found.</span>
                            </div>
                        )}
                    {!isFetchingPremNotes &&
                        premNotesData &&
                        premNotesData?.prem_notes?.map((note: any) => {
                            return <PanelCell key={note.id} fieldName="Notes" fieldValue={renderField(note.source.note)} />;
                        })}
                </Card.Body>
            </Card>
            <PeopleAtAddressModal
                query={query}
                show={showPeopleModal}
                close={() => setShowPeopleModal(false)}
                people={addressResidents}
                agencyId={address?.agency_id}
            />
        </>
    );
};

export default AddressOverview;
