import { faUpRightAndDownLeftFromCenter } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge, Modal, Table } from "react-bootstrap";
import { useColumnSort } from "../../hooks/useColumnSort";
import { formatDate } from "../../utils/date";
import { sortSortableHeader } from "../../utils/sort";
import { SortDirectionEnum } from "../../types/old_v1/types";
import SortableHeaderColumn from "../SortableHeader";

type OwnProps = {
    tags: any[];
    showExpand?: boolean;
};
const CastleRockTags = ({ tags, showExpand }: OwnProps) => {
    const { sortColumn, sortDirection, setSort } = useColumnSort("start_date", SortDirectionEnum.DESCENDING);
    const sortedTags = (Array.isArray(tags) ? [...tags] : []).sort(sortSortableHeader(sortColumn, true, sortDirection));

    const [showAlertModal, setShowAlertModal] = React.useState(false);

    return (
        <>
            {tags && tags.length > 0 && (
                <div className="mb-3">
                    <Badge
                        className={`fs-5 d-inline-block badge me-2 mb-1 align-content-bottom ${
                            showExpand ? "cursor-pointer" : ""
                        } pendo_person_alerts_modal_button`}
                        onClick={() => showExpand && setShowAlertModal(true)}
                        bg="warning"
                    >
                        Alert{showExpand && <FontAwesomeIcon size="sm" className="ps-2" icon={faUpRightAndDownLeftFromCenter} />}
                    </Badge>
                </div>
            )}
            <Modal show={showAlertModal} onHide={() => setShowAlertModal(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title as="h2">Alerts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table size="sm" responsive>
                        <thead>
                            <tr>
                                <SortableHeaderColumn
                                    columnName="Alert Code"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="alert_code_description"
                                    defaultDirection={SortDirectionEnum.ASCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="Flag Type"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="flag_type"
                                    defaultDirection={SortDirectionEnum.ASCENDING}
                                />
                                <th>Remarks</th>
                                <SortableHeaderColumn
                                    columnName="Entry Date"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="entry_date"
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnClass="ps-3"
                                    columnName="Start Date"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="start_date"
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnClass="ps-3"
                                    columnName="Last Modified Date"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="when_modified"
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnClass="ps-3"
                                    columnName="Expiration Date"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="expiration_date"
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {sortedTags.map((tag) => (
                                <tr
                                    key={`${tag.alert_code_description}-${tag.flag_type}-${tag.entry_date}-${tag.start_date}-${tag.when_modified}-${tag.expiration_date}`}
                                >
                                    <td className="text-nowrap">{tag.alert_code_description}</td>
                                    <td className="text-nowrap">{tag.flag_type}</td>
                                    <td>{tag.remarks}</td>
                                    <td className="text-nowrap">{formatDate(tag.entry_date)}</td>
                                    <td className="text-nowrap ps-3">{formatDate(tag.start_date)}</td>
                                    <td className="text-nowrap ps-3">{formatDate(tag.when_modified)}</td>
                                    <td className="text-nowrap ps-3">{formatDate(tag.expiration_date)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CastleRockTags;
