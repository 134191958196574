import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";

const PARAM_NAME = "timeRange";

const HOURS = Array.from(Array(24).keys());

interface ITimeRangeProps {
    fullWidth?: boolean;
}

const TimeRange = ({ fullWidth = false }: ITimeRangeProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const timeRange = searchParams.get("timeRange");
    const splitTime = timeRange?.split("-");
    const [startTime, setStartTime] = React.useState(splitTime?.[0] || "");
    const [endTime, setEndTime] = React.useState(splitTime?.[1] || "");

    const resetTimeParams = React.useCallback(() => {
        const updatedSearchParams = new URLSearchParams(searchParams);
        setStartTime("");
        setEndTime("");
        updatedSearchParams.delete(PARAM_NAME);
        setSearchParams(updatedSearchParams);
    }, [searchParams, setSearchParams]);

    const updateTimeParams = (paramValue: string) => {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.set(PARAM_NAME, paramValue);
        updatedSearchParams.delete("page");
        setSearchParams(updatedSearchParams);
    };

    const handleStartChange = (timeValue: any) => {
        const value = timeValue?.value;
        if (value === undefined) {
            resetTimeParams();
        } else {
            setStartTime(value);
            if (endTime && parseInt(value) <= parseInt(endTime)) {
                updateTimeParams(`${value}-${endTime}`);
            }
        }
    };

    const handleEndChange = (timeValue: any) => {
        const value = timeValue?.value;
        if (value === undefined) {
            resetTimeParams();
        } else {
            setEndTime(value);
            if ((startTime !== undefined || startTime !== null) && parseInt(value) >= parseInt(startTime)) {
                updateTimeParams(`${startTime}-${value}`);
            }
        }
    };

    React.useEffect(() => {
        const splitTime = timeRange?.split("-");
        if (!timeRange || (splitTime && (!splitTime[0] || !splitTime[1]))) {
            resetTimeParams();
        }
    }, [timeRange, resetTimeParams]);

    const options: readonly any[] = HOURS.map((hour) => {
        return {
            value: hour,
            label: hour < 10 ? `0${hour}:00` : `${hour}:00`,
        };
    });
    let selectedStart = null;
    let selectedEnd = null;
    if (startTime !== undefined && startTime !== "") {
        selectedStart = { value: startTime, label: parseInt(startTime) <= 9 ? `0${startTime}:00` : `${startTime}:00` };
    }
    if (endTime !== undefined && endTime !== "") {
        selectedEnd = { value: endTime, label: parseInt(endTime) <= 9 ? `0${endTime}:00` : `${endTime}:00` };
    }

    return (
        <Col>
            <Row>
                <Col xs={fullWidth ? 12 : 6}>
                    <Form.Label className="mb-0">Start Time</Form.Label>
                    <Select
                        isSearchable={true}
                        isClearable={true}
                        options={options}
                        isOptionDisabled={(option) => parseInt(option.value) >= parseInt(endTime)}
                        onChange={(e) => {
                            handleStartChange(e);
                        }}
                        placeholder="Select Start Time"
                        value={selectedStart}
                        className="react-select-container pendo_time_range_start_time_filter_button"
                        classNamePrefix="react-select"
                    />
                </Col>
                <Col xs={fullWidth ? 12 : 6}>
                    <Form.Label className="mb-0">End Time</Form.Label>
                    <Select
                        isSearchable={true}
                        isClearable={true}
                        options={options}
                        isOptionDisabled={(option) => parseInt(option.value) <= parseInt(startTime)}
                        onChange={(e) => {
                            handleEndChange(e);
                        }}
                        placeholder="Select End Time"
                        value={selectedEnd}
                        className="react-select-container pendo_time_range_end_time_filter_button"
                        classNamePrefix="react-select"
                    />
                </Col>
            </Row>
        </Col>
    );
};

export default TimeRange;
