import React from "react";
import { Card, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useGetOfficerInsightsIncidentsOverTimeQuery } from "../../../api/api";
import { getQueryParams } from "../../../utils/url";
import LineChart from "../../insightsdashboard/charts/LineChart";
import { format, subMonths, subWeeks, subYears } from "date-fns";
import { calculateEnabledDateViews } from "../../../utils/insights";
import { InsightsDateViewEnum } from "../../../types/types";
import useAgencySettings from "../../../hooks/useAgencySettings";
import { useFilters } from "../../../hooks/useFilters";

const now = new Date();

const IncidentsOverTime = () => {
    const [dateInterval, setDateInterval] = React.useState(InsightsDateViewEnum.DAY);
    const [searchParams] = useSearchParams();
    const { incidentTextPlural } = useAgencySettings();
    const { beatsIsFetching, filterPayload } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });

    const queryParams = getQueryParams(searchParams);
    const { data: incidentsOverTimeResponse, isLoading } = useGetOfficerInsightsIncidentsOverTimeQuery(
        {
            ...queryParams,
            filters: filterPayload,
            interval: dateInterval,
        },
        { skip: beatsIsFetching }
    );
    const lineChartData = !isLoading && incidentsOverTimeResponse?.data.results;
    const dateRange = searchParams.get("dateRange");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");

    const dateButtonStatuses = calculateEnabledDateViews(dateRange || "", startDate || "", endDate || "", now);

    React.useEffect(() => {
        if (dateRange) {
            if (["4days", "8days", "30days"].includes(dateRange)) {
                setDateInterval(InsightsDateViewEnum.DAY);
            } else if ("90days" === dateRange) {
                setDateInterval(InsightsDateViewEnum.WEEK);
            } else if ("1year" === dateRange) {
                setDateInterval(InsightsDateViewEnum.MONTH);
            } else if ("3years" === dateRange) {
                setDateInterval(InsightsDateViewEnum.YEAR);
            }
        } else {
            setDateInterval(InsightsDateViewEnum.DAY);
        }
    }, [dateRange]);

    return (
        <Card style={{ height: "100%" }}>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title className="m-0" as="h3">
                    {incidentTextPlural} Over Time
                </Card.Title>
                <ToggleButtonGroup name="options" size="sm" type="radio" value={dateInterval} onChange={setDateInterval}>
                    <ToggleButton id={InsightsDateViewEnum.DAY} value={InsightsDateViewEnum.DAY} variant="outline-primary">
                        Day
                    </ToggleButton>
                    <ToggleButton
                        id={InsightsDateViewEnum.WEEK}
                        value={InsightsDateViewEnum.WEEK}
                        variant="outline-primary"
                        disabled={!dateButtonStatuses[InsightsDateViewEnum.WEEK]}
                    >
                        Weekly
                    </ToggleButton>
                    <ToggleButton
                        id={InsightsDateViewEnum.MONTH}
                        value={InsightsDateViewEnum.MONTH}
                        variant="outline-primary"
                        disabled={!dateButtonStatuses[InsightsDateViewEnum.MONTH]}
                    >
                        Month
                    </ToggleButton>
                    <ToggleButton
                        id={InsightsDateViewEnum.YEAR}
                        value={InsightsDateViewEnum.YEAR}
                        variant="outline-primary"
                        disabled={!dateButtonStatuses[InsightsDateViewEnum.YEAR]}
                    >
                        Year
                    </ToggleButton>
                </ToggleButtonGroup>
            </Card.Header>
            <Card.Body>
                {lineChartData && lineChartData.labels && lineChartData.labels.length ? (
                    <LineChart
                        processedData={lineChartData}
                        mainSeriesName={incidentTextPlural}
                        xAxis="Date"
                        yAxis={`Number of ${incidentTextPlural}`}
                        onClickFn={(e: any) => {
                            const queryParams = getQueryParams(searchParams);
                            const urlParams = new URLSearchParams();

                            const endDate = e.point.category;
                            let startDate;
                            if (dateInterval === InsightsDateViewEnum.DAY) {
                                startDate = endDate;
                            } else {
                                let tmpDate = new Date(endDate);
                                let sd;
                                if (dateInterval === InsightsDateViewEnum.WEEK) {
                                    sd = subWeeks(tmpDate, 1);
                                } else if (dateInterval === InsightsDateViewEnum.MONTH) {
                                    sd = subMonths(tmpDate, 1);
                                } else if (dateInterval === InsightsDateViewEnum.YEAR) {
                                    sd = subYears(tmpDate, 1);
                                }
                                if (sd) {
                                    startDate = format(sd, "yyyy-MM-dd");
                                }
                            }

                            if (queryParams.tags) {
                                urlParams.set("tags", queryParams.tags);
                            }
                            urlParams.set("dateRange", "custom");
                            urlParams.set("startDate", startDate);
                            urlParams.set("endDate", endDate);
                            urlParams.set("indexType", "incidents");

                            if (queryParams.filters) {
                                urlParams.set("filters", queryParams.filters);
                            }

                            if (queryParams.officer) {
                                urlParams.set("officer", encodeURIComponent(queryParams.officer));
                            }
                            window.open(`/app/search?${urlParams.toString()}`, "_blank");
                        }}
                    />
                ) : (
                    <h1 className="text-center">No Events</h1>
                )}
            </Card.Body>
        </Card>
    );
};

export default IncidentsOverTime;
