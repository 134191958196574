import { useSearchParams } from "react-router-dom";

/**
 * Helper hook to facilitate processing search params as needed
 */
export const useManageSearchParams = () => {
    const [searchParams] = useSearchParams();

    /**
     *
     * @param allowSearchParamNameList list of search params to allow into processed search params
     * @param disallowSearchParamNameList Only utilized if allowSearchParamNameList is an empty list. List of search params to not include in processed search params
     * @returns
     */
    const processSearchParams = (allowSearchParamNameList: string[], disallowSearchParamNameList: string[] = []) => {
        const newSearchParams = new URLSearchParams();

        if (allowSearchParamNameList.length) {
            searchParams.forEach((value, key) => {
                if (allowSearchParamNameList.includes(key)) {
                    newSearchParams.set(key, value);
                }
            });
        } else if (disallowSearchParamNameList.length) {
            searchParams.forEach((value, key) => {
                if (!disallowSearchParamNameList.includes(key)) {
                    newSearchParams.set(key, value);
                }
            });
        } else {
            searchParams.forEach((value, key) => {
                newSearchParams.set(key, value);
            });
        }

        return newSearchParams;
    };

    return {
        processSearchParams,
    };
};
