import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { Alert, Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
// v1 api call
import { useCreateRadarConfigMutation, useGetRadarConfigsQuery } from "../../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";

const RadarConfigModal = ({ show, close }: { show: boolean; close: Function }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { selectedAgency } = useSingleAgencyFilter();
    const [alertMsg, setAlertMsg] = React.useState<string | null>(null);

    const [createRadarConfig] = useCreateRadarConfigMutation();
    const { data: radarConfigsData } = useGetRadarConfigsQuery({});

    const radarConfigNames =
        (radarConfigsData && radarConfigsData.results && radarConfigsData.results.map((config: any) => config.config_name)) || [];

    const initial = {
        configNameDupe: "",
        configName: "",
    };

    const saveRadarConfig = (values: any) => {
        const saveQueryParams = new URLSearchParams(searchParams.toString());
        saveQueryParams.set("view", "CUSTOM");

        // add agencyUUID to config to save, if no uuid is already set and this is a multiagency
        if (!saveQueryParams.get("agencyUUID") && !!selectedAgency?.fm_uuid) {
            saveQueryParams.set("agencyUUID", selectedAgency?.fm_uuid);
        }
        createRadarConfig({ queryStringParams: saveQueryParams.toString(), configName: values.configName }).then((response: any) => {
            if (response?.data?.success) {
                const updatedViewID = response.data.results?.id;
                if (updatedViewID) {
                    // update page search params to reflect updated values
                    const updatedSearchParams = new URLSearchParams(searchParams.toString());
                    updatedSearchParams.set("view", updatedViewID);

                    if (!updatedSearchParams.get("agencyUUID") && !!selectedAgency?.fm_uuid) {
                        updatedSearchParams.set("agencyUUID", selectedAgency?.fm_uuid);
                    }
                    setSearchParams(updatedSearchParams.toString());
                }
                close();
            } else {
                setAlertMsg("Failed to save config!");
            }
        });
    };

    return (
        <Modal centered show={show}>
            <>
                <Formik
                    initialValues={initial}
                    onSubmit={saveRadarConfig}
                    validationSchema={Yup.object({
                        configNameDupe: Yup.string().when("configName", {
                            is: (n: string) => radarConfigNames.includes(n),
                            then: Yup.string().required("This selection name already exists. Please choose a different name."),
                            otherwise: Yup.string(),
                        }),
                    })}
                >
                    {({ handleChange, handleSubmit, errors, values }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Header>
                                <Modal.Title as="h3">Save Your Custom Radar Filter Selection</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FloatingLabel label="Selection Name">
                                    <Form.Control type="name" name="configName" onChange={handleChange} />
                                </FloatingLabel>
                            </Modal.Body>
                            <Modal.Footer>
                                {!!errors.configNameDupe && (
                                    <span>
                                        <FontAwesomeIcon icon={faWarning} className="text-warning" /> {errors.configNameDupe}
                                    </span>
                                )}
                                <Button variant="danger" onClick={() => close()}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={!values.configName || radarConfigNames.includes(values.configName)}
                                    type="submit"
                                    variant="success"
                                    className="pendo-radar-filter-selection-save-last"
                                >
                                    Confirm
                                </Button>
                                {!!alertMsg && (
                                    <Alert className="mt-3" variant="warning" onClose={() => setAlertMsg(null)} dismissible>
                                        <Alert.Heading>Failed to Save</Alert.Heading>
                                        <p>
                                            Failed to save selection, please try again. If issue persists, please contact system
                                            administrator.
                                        </p>
                                    </Alert>
                                )}
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </>
        </Modal>
    );
};

export default RadarConfigModal;
