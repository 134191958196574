export const getBeautifiedESKeyValuePairs = (obj: any, arrayOfSpecialKeys: string[] = []): { beautifiedKey: string; value: any }[] => {
    const keysArray = ["agency_id", "index", "source", "highlights", "involved_people", "flags", "offenses", ...arrayOfSpecialKeys];

    return getKeyValuePairs(obj, keysArray);
};

export const getKeyValuePairs = (obj: any, arrayOfSpecialKeys: string[] = []): { beautifiedKey: string; value: any }[] => {
    const valuesToReturn: { beautifiedKey: string; value: any }[] = [];

    obj &&
        Object.keys(obj).forEach((key) => {
            const beautifiedKeyValuePair = { beautifiedKey: beautifyKey(key), value: obj[key] };

            if (!arrayOfSpecialKeys.includes(key)) {
                if (Array.isArray(beautifiedKeyValuePair.value)) {
                    beautifiedKeyValuePair.value = beautifiedKeyValuePair.value
                        .map((v: any) => {
                            let ret = "";
                            if (typeof v === "object" && v !== null) {
                                ret = Object.keys(v)
                                    .map((k: any) => {
                                        return `${k}: ${v[k]}`;
                                    })
                                    .join(", ");
                            } else if (Array.isArray(v)) {
                                ret = v.join(", ");
                            } else {
                                ret = v;
                            }
                            return ret;
                        })
                        .join(" | ");
                }
                valuesToReturn.push(beautifiedKeyValuePair);
            }
        });
    // Make them alphabetical order at least
    return valuesToReturn.sort((a, b) => {
        if (a.beautifiedKey < b.beautifiedKey) {
            return -1;
        }
        if (a.beautifiedKey > b.beautifiedKey) {
            return 1;
        }
        return 0;
    });
};

export const beautifyKey = (key: string): string => {
    const beautifiedKey = key.replace(/_/g, " ");
    return beautifiedKey;
};
