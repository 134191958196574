import { LayerTypes } from "../../../types/old_v1/types";

/**
 *
 * @param {string} index the name of the index returned from ES
 * @returns {LayerTypes}
 */
export const indexToLayerType = (index: string) => {
    let layerType = LayerTypes.none;

    if (index) {
        if (index.includes("calls")) {
            layerType = LayerTypes.call;
        }

        if (index.includes("incidents")) {
            layerType = LayerTypes.incident;
        }

        if (index.includes("people")) {
            layerType = LayerTypes.person;
        }

        if (index.includes("addresses")) {
            layerType = LayerTypes.address;
        }
    }

    return layerType;
};
