import React from "react";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { useGetCommunityMetricsDataQuery } from "../../api/api";
import { useAppSelector } from "../../app/hooks";
import CommunityDashboardApis from "./CommunityDashboardApi.json";
import FMSpinner from "../../components/Spinner";
import ErrorComponent from "../support/errorboundary/ErrorComponent";
import GridAndGraph from "./metricsdashboard/GridAndGraph";
import { getAggregationsFromElasticSearchResponse } from "../../utils/elastic";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import { Link, useSearchParams } from "react-router-dom";

type ComDashApi = {
    category: string;
    subcategory: string;
    api: string;
    disabledForAgency?: string[];
};

type OwnProps = {
    categoriesList: string[];
    currentTab: string;
    setTab: (val: string) => void;
    subcategoriesAndApis: ComDashApi[] | null;
};

export type GridData = {
    lastWeek: number;
    lastMonth: number;
    last3Months: number;
};

export type GraphData = {
    day: {
        labels: string[];
        values: string[];
    };
    percentiles: {
        25: number;
        75: number;
    };
};

export type MetricsData = {
    category: string;
    subcategory: string;
    grid: {
        calls: GridData;
        incidents: GridData;
    };
    lineGraph: {
        calls: GraphData;
        incidents: GraphData;
    };
};

enum TrendTabs {
    GunViolence = "Gun & Violence Interventions",
    Juvenile = "Juvenile & School Interventions",
    MentalHealth = "Mental Health Interventions",
    DomesticViolence = "Domestic Violence Intervention",
    DrugOverdose = "Drug & Overdose Interventions",
    PersonInNeed = "Person-in-Need Interventions",
    AtlBolo = "Attempt to Locate - ATL/BOLO",
    Burglary = "Burglaries & Robberies",
    CommunityNeeds = "Community Needs and Asks",
    Internet = "Online / Internet Crimes",
}

const ConnectedGridAndGraph = ({ apiData }: { apiData: ComDashApi }) => {
    const { data, isLoading, isFetching } = useGetCommunityMetricsDataQuery({
        api: apiData.api,
    });
    const loading = isLoading || isFetching;
    let beautifiedData;

    if (data) {
        beautifiedData = getAggregationsFromElasticSearchResponse(data);
    }
    const showError = data && data.success === false;
    return (
        <>
            <div className="mb-3">
                <h1>{apiData.subcategory}</h1>
                {loading ? (
                    <div className="d-flex justify-content-center border-bottom border-2 mt-5 pb-5">
                        <FMSpinner />
                    </div>
                ) : null}
                {showError ? (
                    <div className="d-flex justify-content-center align-items-center border-bottom border-2 my-5">
                        <ErrorComponent variant="large" />
                    </div>
                ) : (
                    <>
                        {data ? (
                            <>
                                <GridAndGraph
                                    cat={apiData.category}
                                    subcat={apiData.subcategory}
                                    data={beautifiedData as any}
                                    apiUrl={apiData.api}
                                />
                            </>
                        ) : null}
                    </>
                )}
            </div>
        </>
    );
};

/**
 * Creates the Metrics Dashboard presentation component
 * @param categoriesList
 * @param currentTab
 * @param setTab
 * @param subcategoriesAndApis
 * @constructor
 */

const MetricsDashboard = ({ categoriesList, currentTab, setTab, subcategoriesAndApis }: OwnProps) => {
    return (
        <Row className="g-3 pt-3">
            <Col md={12} lg={2}>
                <Card>
                    <Card.Body>
                        <Nav activeKey={currentTab} onSelect={(selectedKey) => setTab(selectedKey || "")}>
                            {categoriesList.map((tab: string) => (
                                <Nav.Item key={tab}>
                                    <Nav.Link
                                        as={Link}
                                        to={`/app/metrics-dashboard?tab=${
                                            Object.keys(TrendTabs)[Object.values(TrendTabs).indexOf(tab as TrendTabs)]
                                        }`}
                                        className={`text-wrap ${currentTab === tab ? " text-primary fw-bold" : "text-black"}`}
                                        eventKey={tab}
                                    >
                                        {tab}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={12} lg={10} className="mb-3">
                <Card>
                    <Card.Body className="p-4 h-100">
                        {subcategoriesAndApis ? (
                            subcategoriesAndApis.map((subcategory) => (
                                <div key={subcategory.subcategory}>
                                    <ConnectedGridAndGraph apiData={subcategory} />
                                </div>
                            ))
                        ) : (
                            <div className="w-100 text-center">
                                <FMSpinner />
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

/**
 * Creates the Metrics Dashboard connected component
 * @constructor
 */

const ConnectedMetricsDashboard = () => {
    const userAgency = useAppSelector((state) => state.user.userAgency);
    const [searchParams] = useSearchParams();
    const urlTab = searchParams.get("tab") || "";
    useSetPageTitle("ForceMetrics | Insights Dashboard");

    const categoriesList: string[] = Array.from(
        new Set(
            CommunityDashboardApis.map((queryApi) => {
                if (queryApi.disabledForAgency && !queryApi.disabledForAgency.includes(userAgency)) {
                    return queryApi.category;
                }
                return queryApi.category;
            }).sort((a, b) => (a < b ? -1 : 1))
        )
    );

    const getTabValue = (tab: string) => {
        return Object.values(TrendTabs)[Object.keys(TrendTabs).indexOf(tab)];
    };

    const initialTab = urlTab ? getTabValue(urlTab) : categoriesList[0];
    const [currentTab, setCurrentTab] = React.useState<string>(initialTab);
    const [currentSubcategories, setCurrentSubcategories] = React.useState<ComDashApi[] | null>([]);

    const setTab = React.useCallback(
        (tab: string) => {
            if (tab !== currentTab) {
                setCurrentTab(tab);
                setCurrentSubcategories(null);
            }
        },
        [currentTab]
    );

    React.useEffect(() => {
        if (urlTab !== "" && urlTab !== currentTab) {
            setTab(getTabValue(urlTab));
        }
    }, [currentTab, setTab, urlTab]);

    React.useEffect(() => {
        const subCats = CommunityDashboardApis.filter((queryApi: ComDashApi) => {
            return queryApi.category === currentTab
                ? queryApi.disabledForAgency && !queryApi.disabledForAgency.includes(userAgency)
                : false;
        });

        setCurrentSubcategories(subCats);
    }, [currentTab, userAgency]);

    return (
        <MetricsDashboard
            categoriesList={categoriesList}
            subcategoriesAndApis={currentSubcategories}
            currentTab={currentTab}
            setTab={setTab}
        />
    );
};

export default ConnectedMetricsDashboard;
