import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import Logo from "../support/logo/Logo";
import { useAppSelector } from "../../app/hooks";
import Disclaimer from "../support/Disclaimer";
import { getRefreshToken, tokenIsValid } from "../../utils/token";
import useNextAwarePathname from "../../hooks/useNextAwarePathname";

const AuthPage = () => {
    const user = useAppSelector(({ user }) => user);
    const refreshToken = getRefreshToken();
    const [searchParams] = useSearchParams();
    const authenticated = user.isLoggedIn && !!user.userObject && tokenIsValid(refreshToken);
    const { canPerformNextFESearch, landingPath } = useNextAwarePathname();

    if (authenticated) {
        try {
            if (searchParams.get("redirect")) {
                const fullUrlToNav = new URL(`${window.location.origin}${searchParams.get("redirect")}` || "");
                if (canPerformNextFESearch) {
                    window.location.assign(`${fullUrlToNav.pathname}${fullUrlToNav.search}`);
                }

                return <Navigate to={`${fullUrlToNav.pathname}${fullUrlToNav.search}`} />;
            } else {
                if (canPerformNextFESearch) {
                    window.location.assign(landingPath);
                } else {
                    return <Navigate to={landingPath} />;
                }
            }
        } catch (error) {
            console.log(error);
            if (canPerformNextFESearch) {
                window.location.assign(landingPath);
            } else {
                return <Navigate to={landingPath} />;
            }
        }
    }

    return (
        <Container data-testid="login-form-container">
            <Row className="justify-content-center">
                <Logo className="d-none d-md-block w-50" />
                <Logo className="d-block d-md-none w-75" />
            </Row>
            <Outlet />
            <Row className="justify-content-center">
                <Col lg={{ span: 8 }} className="p-4 mt-5">
                    <Disclaimer className="text-muted" />
                </Col>
            </Row>
        </Container>
    );
};

export default AuthPage;
