import { useSearchParams } from "react-router-dom";
import TagDetails from "../../utils/TagDetails";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, OverlayTrigger, Popover, Stack } from "react-bootstrap";
import { format, isValid, parseISO } from "date-fns";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";
import { useFilters } from "../../hooks/useFilters";
import React from "react";

type OwnProps = {
    displayLabel: string;
    data: any;
    pendoClass?: string;
    dataLabel: string;
    andFilter: boolean;
    hidePrefixText?: boolean; // if true, will hide the "and" | "or" before the badge
    canDelete?: boolean;
    icon?: any;
    showModal?: Function | null;
};

// NOTE: this is only for the getDisplay dateRange case, it is not meant to be reusuable code
const formatDateForGetDisplay = (dates: Array<string>): Array<string> => {
    // if we don't meet this require, just return the passed array
    if (dates.length === 2 && isValid(parseISO(dates[0])) && isValid(parseISO(dates[1]))) {
        const start = format(parseISO(dates[0]), "MM-dd-yyyy");
        const end = format(parseISO(dates[1]), "MM-dd-yyyy");
        return [start, end];
    }
    return dates;
};

const FilterBadge = ({
    displayLabel,
    data,
    pendoClass = "",
    dataLabel,
    andFilter,
    hidePrefixText = false,
    canDelete = true,
    icon,
    showModal = () => null,
}: OwnProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setSearchParams] = useSearchParams();
    const { searchParams } = useSingleAgencyFilter();
    const dataArray = data?.split(",");
    const tagInfo = TagDetails();
    const { updateFilters } = useFilters({ skipBeatsQuery: true });

    const getTagLabel = (tag: string | null) => {
        return tagInfo.find((detail) => tag === detail.code)?.name || "";
    };

    const getDisplay = (option: string) => {
        let display = "";
        let start = "";
        let end = "";
        switch (dataLabel) {
            //time filter constructed as {startDate}-{endDate}
            case "dateRange":
                [start, end] = formatDateForGetDisplay(option.split(" - "));
                // This check to ensure both dates are viable, otherwise supply option
                display = start && end ? `${start} - ${end}` : option;
                break;
            case "timeRange":
                start = option.split("-")[0];
                end = option.split("-")[1];
                display = `${parseInt(start) <= 9 ? `0${start}` : start}:00 - ${parseInt(end) <= 9 ? `0${end}` : end}:00`;
                break;
            case "tags":
                display = getTagLabel(option);
                break;
            case "indexType":
                display = option.charAt(0).toUpperCase() + option.slice(1);
                break;
            default:
                display = option;
        }

        return decodeURIComponent(display || "");
    };

    const removeFilter = (dataLabel: string) => {
        if (!canDelete) {
            return;
        }

        searchParams.delete(dataLabel);
        setSearchParams(searchParams);

        updateFilters(dataLabel, null, searchParams);
    };

    if (!dataArray) {
        return <></>;
    }

    return data.length > 0 ? (
        <>
            {!hidePrefixText && <FilterConjunctionText>{andFilter ? "and" : "or"}</FilterConjunctionText>}

            {!["officer", "dispatcher", "agencies", "entity"].includes(dataLabel) && dataArray.length > 1 && (
                <FilterConjunctionText>{andFilter ? "all of" : "any of"}</FilterConjunctionText>
            )}

            {dataArray.length <= 2 && (
                <OverlayTrigger
                    rootClose
                    trigger={["hover", "focus"]}
                    placement={"bottom-start"}
                    defaultShow={false}
                    overlay={
                        <Popover>
                            <Popover.Body>
                                <div className="">
                                    {dataArray.map((option: string, index: number) => {
                                        return dataArray.length - 1 === index ? (
                                            <span key={option} className="d-block text-truncate">
                                                {getDisplay(option)}
                                            </span>
                                        ) : (
                                            <React.Fragment key={option}>
                                                <span className="d-block text-truncate">{getDisplay(option)},</span>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <Badge
                        className={`p-0 fs-4 align-middle d-flex align-items-center fw-normal ${
                            showModal ? "cursor-pointer" : ""
                        } overflow-hidden text-black fm-filter-badge text-capitalize ${pendoClass}`}
                        bg="secondary"
                        style={{ maxWidth: "250px" }}
                    >
                        <span
                            className={`py-2 ${canDelete ? "ps-3" : "px-3"} ${showModal ? "" : "cursor-default"} text-truncate`}
                            onClick={showModal ? () => showModal() : () => null}
                        >
                            {icon && <FontAwesomeIcon className="me-2" icon={icon} />}
                            {dataArray.map((option: string, index: number) => {
                                // NOTE: Use comma seperation to not infer and/or in the filter group context, may revisit
                                const commaString = dataArray.length > 1 && index !== dataArray.length - 1 ? ", " : "";
                                return `${getDisplay(option)}${commaString}`;
                            })}
                        </span>
                        {canDelete && (
                            <span
                                className={`ms-2 fm-btn-danger-hover h-100 d-flex align-items-center px-3 fs-5 cursor-pointer ${
                                    pendoClass + "_remove"
                                }`}
                                onClick={() => removeFilter(dataLabel)}
                                data-testid="clickable-filter-badge"
                            >
                                <FontAwesomeIcon icon={faXmark} />
                            </span>
                        )}
                    </Badge>
                </OverlayTrigger>
            )}
            {dataArray.length > 2 && (
                <OverlayTrigger
                    rootClose
                    trigger={["hover", "focus"]}
                    placement={"bottom-start"}
                    defaultShow={false}
                    overlay={
                        <Popover>
                            <Popover.Body>
                                <Stack className="d-flex flex-wrap">
                                    {dataArray.map((option: string, index: number) => {
                                        return dataArray.length - 1 === index ? (
                                            <span key={option} className="d-block w-100 text-truncate">
                                                {getDisplay(option)}
                                            </span>
                                        ) : (
                                            <span key={option} className="d-block w-100 text-truncate">
                                                {getDisplay(option)},&nbsp;
                                            </span>
                                        );
                                    })}
                                </Stack>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <Badge
                        className={`p-0 fs-4 align-middle d-flex align-items-center fw-normal ${
                            showModal ? "cursor-pointer" : "cursor-default"
                        } overflow-hidden text-black fm-filter-badge ${pendoClass}`}
                        bg="secondary"
                        style={{ maxWidth: "250px" }}
                    >
                        <span className={`py-2 ${canDelete ? "ps-3" : "px-3"}`} onClick={showModal ? () => showModal() : () => null}>
                            {icon ? <FontAwesomeIcon className="me-2" icon={icon} /> : null}
                            {`${dataArray.length} ${displayLabel}`}
                        </span>
                        {canDelete && (
                            <span
                                data-testid="clickable-filter-badge"
                                className={`ms-2 fm-btn-danger-hover h-100 d-flex align-items-center px-3 fs-5 cursor-pointer ${
                                    pendoClass + "_remove"
                                }`}
                                onClick={() => removeFilter(dataLabel)}
                            >
                                <FontAwesomeIcon icon={faXmark} />
                            </span>
                        )}
                    </Badge>
                </OverlayTrigger>
            )}
        </>
    ) : (
        <></>
    );
};

const FilterConjunctionText = ({ children }: { children: string }) => (
    <span className="d-flex align-items-center text-primary text-decoration-underline">{children}</span>
);

export default FilterBadge;
