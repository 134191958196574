import { faMessage } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Stack, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useGetSharedUrlsForUserQuery } from "../../api/api";
import LoadingModal from "../../components/LoadingModal";
import { formatDate } from "../../utils/date";
import { FromES, UserShare } from "../../types/old_v1/types";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import { getUrlPathname } from "../../utils/url";

const SharesConnected = () => {
    const { data, error, isFetching } = useGetSharedUrlsForUserQuery({}, { refetchOnMountOrArgChange: true });

    if (!!error) {
        return <Alert variant="danger">Server error retrieving shares</Alert>;
    }

    return <Shares shares={data?.share_hits || []} isLoading={isFetching} />;
};

type OwnProps = {
    shares: FromES<UserShare>[];
    isLoading: boolean;
};
const Shares = ({ shares, isLoading }: OwnProps) => {
    useSetPageTitle("ForceMetrics | Shared with You");
    const navigate = useNavigate();
    const sortedShares = [...shares].sort((share1, share2) => (share1.shared_at <= share2.shared_at ? 1 : -1));

    const goTo = (share: any) => {
        navigate(getUrlPathname(share?.uuid_url));
    };
    return (
        <>
            <h2 className="mt-3">
                <FontAwesomeIcon icon={faMessage} />
                &nbsp;Shared with You
            </h2>
            <LoadingModal show={isLoading} />
            <Table responsive>
                <thead className="sticky-header">
                    <tr>
                        <th>Title</th>
                        <th>Shared By</th>
                        <th>Shared At</th>
                        <th>Last Viewed</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedShares &&
                        sortedShares.length > 0 &&
                        sortedShares.map((share) => (
                            <tr key={share.id}>
                                <td className="cursor-pointer" onClick={() => goTo(share)}>
                                    <Stack direction="horizontal" gap={1}>
                                        <FontAwesomeIcon
                                            icon={faMessage}
                                            className={`${!share.viewed_at ? "text-danger" : "text-primary"} me-2`}
                                        />
                                        <span className={`${!share.viewed_at ? "text-danger" : ""}`}>
                                            {!!share.title ? share.title : "Shared Item"}
                                        </span>
                                    </Stack>
                                </td>
                                <td className="text-nowrap">{share.shared_by_email}</td>
                                <td className="text-nowrap">{formatDate(share.shared_at)}</td>
                                <td className="text-nowrap">{formatDate(share.viewed_at)}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </>
    );
};

export default SharesConnected;
