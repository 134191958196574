import { Col, Container, Nav, Row } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import { useGetAddressDetailQuery, useGetAddressEventsQuery, useGetAddressResidentsQuery, useGetPremNotesQuery } from "../../api/api";
import AddressOverview from "./AddressOverview";
import { getUniqueAssociatedPeopleFromEvents } from "../../utils/address";
import AssociatedPeopleView from "../../components/entity/AssociatedPeopleView";
import { SortConfig, SortDirectionEnum } from "../../types/old_v1/types";
import { getQueryParams } from "../../utils/url";
import EventList from "../../components/entity/EventList";
import BackToSearchButton from "../../components/BackToSearchButton";
import React from "react";
import { scrollToTop } from "../../utils/helpers";
import { sortSortableHeader } from "../../utils/old_v1/sort";
import { getArrayOfDataFromElasticSearchReponse } from "../../utils/elastic";
import { useScrollToTopMultiDevice } from "../../hooks/useScrollToTopMultiDevice";

enum TabEnum {
    OVERVIEW = "overview",
    INCIDENTS = "incidents",
    MENTIONS = "mentions",
    ASSOCIATED_PEOPLE = "associated-people",
    ANALYTICS = "analytics",
}

const DEFAULT_DISTANCE = "50ft";

const ConnectedAddressPage = () => {
    const { agencyUUID } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const queryParams = getQueryParams(searchParams);
    const address = searchParams.get("address");
    const query = searchParams.get("query");
    const gpsLat = searchParams.get("gpsLat");
    const gpsLon = searchParams.get("gpsLon");
    const distance = searchParams.get("distance") || DEFAULT_DISTANCE;
    useSetPageTitle(`ForceMetrics | Address ${address}`);
    const listRef = React.useRef<any>(null);

    // set current tab to url param if value in tab enum; else, default to overview tab
    const currentTab = Object.values(TabEnum).includes(searchParams.get("currentTab") as TabEnum)
        ? (searchParams.get("currentTab") as TabEnum)
        : TabEnum.INCIDENTS;

    const payload = {
        ...queryParams,
        dateRange: queryParams.dateRange || "all_time",
        search_for: query,
        gps_lat: gpsLat,
        gps_lon: gpsLon,
        full_address: address,
        distance: distance,
        agencyUUID,
        formattedResults: true,
    };

    const {
        data: eventDataRes,
        error: eventError,
        isLoading: eventLoading,
        isFetching: eventFetching,
    } = useGetAddressEventsQuery({ ...payload, page: 1, pageSize: 10000 });

    const { data: addressDataRes, isLoading: addressLoading } = useGetAddressDetailQuery({ ...payload });

    const { data: premNotesDataRes, isFetching: isFetchingPremnotes } = useGetPremNotesQuery(
        {
            address:
                addressDataRes && addressDataRes?.address?.length
                    ? addressDataRes?.address[0].source?.address || addressDataRes?.address[0].source.incident_address
                    : "",
            city:
                addressDataRes && addressDataRes?.address?.length
                    ? addressDataRes?.address[0].source.city || addressDataRes?.address[0].source.incident_city
                    : "",
            state:
                addressDataRes && addressDataRes?.address?.length
                    ? addressDataRes?.address[0].source.state || addressDataRes?.address[0].source.incident_state
                    : "",
            zipcode:
                addressDataRes && addressDataRes?.address?.length
                    ? addressDataRes?.address[0].source.zipcode || addressDataRes?.address[0].source.incident_zipcode
                    : "",
            agencyUUID,
            formattedResults: true,
        },
        { skip: !addressDataRes?.success }
    );

    const {
        data: addressResidentsData,
        error: addressResidentsError,
        isLoading: addressResidentsIsLoading,
        isFetching: addressResidentsIsFetching,
    } = useGetAddressResidentsQuery({ full_address: address, agencyUUID }, { skip: !addressDataRes?.success });
    let addressResidents = [];
    if (!addressResidentsError && !addressResidentsIsFetching && !addressResidentsIsLoading && addressResidentsData) {
        addressResidents = getArrayOfDataFromElasticSearchReponse(addressResidentsData);
    }

    let eventCount = eventDataRes?.count;
    let people: any[] = React.useMemo(() => [], []);
    let peopleCount = 0;
    if (!eventError && !eventLoading && !eventFetching && eventDataRes) {
        people = getUniqueAssociatedPeopleFromEvents(eventDataRes?.address_events);
        peopleCount = people.length;
    }

    const setCurrentTab = (value: TabEnum) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("currentTab", value);
        setSearchParams(newSearchParams);
    };

    let addressData = {};
    if (!eventLoading && eventDataRes?.address_events && !addressLoading && addressDataRes?.address?.length) {
        const addressWithLocation = addressDataRes?.address?.find(
            (addressItem: any) => addressItem.source.gps_lat && addressItem.source.gps_lon
        );

        const data = addressWithLocation || addressDataRes?.address[0];

        addressData = {
            eventCount: eventDataRes?.address_events?.length,
            ...data.source,
        };
    }

    // This is not very performant to link the associated events data to the correct person
    // Utilizing useMemo prevents this from having to run extra times whenever the component needs to
    // re-render. This relies on the eventDataRes and people to have fresh data to memo.
    people = React.useMemo(
        () =>
            people.map((person) => {
                const associatedEvents = eventDataRes.address_events
                    .filter(
                        (event: any) =>
                            !!event.source?.involved_people?.find((involvedPerson: any) => involvedPerson.person_id === person.person_id)
                    )
                    .map((event: any) => event.source);
                const latest_incident =
                    associatedEvents && associatedEvents.length > 0
                        ? associatedEvents?.map((item: any) => item)?.sort(sortSortableHeader("occurred_at", true, "desc"))[0]
                        : [];

                return {
                    ...person,
                    associated_events: associatedEvents,
                    latest_incident_occurred_at: latest_incident?.occurred_at,
                    associated_event_count: associatedEvents.length,
                };
            }),
        [eventDataRes, people]
    );

    const pageResetDependencies = React.useMemo(
        () => ({ currentTab, searchParams, scrollToId: "mobileAddressNav" }),
        [currentTab, searchParams]
    );

    // Scroll to top of page on every new load of the event page
    React.useEffect(() => {
        scrollToTop();
    }, []);

    // Only scroll to top of page when currentTab changes and the screensize is larger or equal to mobile size
    useScrollToTopMultiDevice(currentTab, searchParams, "mobileAddressNav");

    return (
        <AddressPage
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            address={addressData}
            addressLoading={addressLoading}
            addressResidents={addressResidents}
            isFetchingPremNotes={isFetchingPremnotes}
            premNotes={premNotesDataRes}
            eventCount={eventCount}
            eventLoading={eventLoading}
            peopleCount={peopleCount}
            scrollToTop={scrollToTop}
            listRef={listRef}
            people={people}
            events={eventDataRes?.address_events || []}
            pageReset={pageResetDependencies}
            gpsLat={gpsLat}
            gpsLon={gpsLon}
            distance={distance}
        />
    );
};

const sortConfig: SortConfig = {
    birthdate: {
        label: "DOB",
        defaultSortDirection: SortDirectionEnum.DESCENDING,
        sortString: true,
    },
    "first_name,last_name": {
        label: "Name",
        defaultSortDirection: SortDirectionEnum.ASCENDING,
        sortString: true,
    },
    latest_incident_occurred_at: {
        label: "Latest Event",
        defaultSortDirection: SortDirectionEnum.DESCENDING,
        sortString: true,
    },
    associated_event_count: {
        label: "Associated Events",
        defaultSortDirection: SortDirectionEnum.DESCENDING,
        sortString: true,
    },
};

type OwnProps = {
    currentTab: TabEnum;
    setCurrentTab: (value: TabEnum) => void;
    address: any;
    addressLoading: boolean;
    addressResidents: any[];
    isFetchingPremNotes: boolean;
    premNotes: any;
    eventCount: number;
    eventLoading: boolean;
    peopleCount: number;
    scrollToTop: any;
    listRef: any;
    people: any;
    pageReset: any;
    events: any;
    gpsLat: any;
    gpsLon: any;
    distance: any;
};
const AddressPage = ({
    currentTab,
    setCurrentTab,
    address,
    addressResidents,
    addressLoading,
    isFetchingPremNotes,
    premNotes,
    eventCount,
    eventLoading,
    peopleCount,
    scrollToTop,
    listRef,
    people,
    pageReset,
    events,
    gpsLon,
    gpsLat,
    distance,
}: OwnProps) => {
    return (
        <>
            <Container className="my-4" fluid="xl">
                <Row>
                    <Col className="mb-4 d-block d-md-none" xs={12}>
                        <BackToSearchButton />
                    </Col>
                    <Col className="d-block p-0 mb-4" xs={12} md={3}>
                        <AddressOverview
                            address={address}
                            isFetchingPremNotes={addressLoading || isFetchingPremNotes}
                            premNotesData={premNotes}
                            addressResidents={addressResidents}
                            events={events}
                        />
                    </Col>
                    <Col xs={12} md={9} className="px-0 px-md-4" ref={listRef}>
                        <Nav className="d-none d-md-flex border-bottom-0" variant="tabs" activeKey={currentTab}>
                            <AddressDesktopNavItems
                                currentTab={currentTab}
                                setCurrentTab={setCurrentTab}
                                eventCount={eventCount}
                                peopleCount={peopleCount}
                            />
                        </Nav>
                        <Nav
                            id="mobileAddressNav"
                            className="d-md-none gap-3 d-flex flex-column text-center p-4"
                            variant="pills"
                            activeKey={currentTab}
                        >
                            <AddressMobileNavItems
                                currentTab={currentTab}
                                setCurrentTab={setCurrentTab}
                                eventCount={eventCount}
                                peopleCount={peopleCount}
                            />
                        </Nav>
                        <div className="min-vh-100-md h-auto fm-bg-color border-0 border-md rounded-end rounded-bottom p-4 d-flex flex-column flex-grow-1">
                            {/* Incidents View */}
                            {currentTab === TabEnum.INCIDENTS && (
                                <EventList
                                    isLoading={eventLoading}
                                    eventData={events}
                                    scrollToTop={scrollToTop}
                                    pageReset={pageReset}
                                    showInvolvedPeople={true}
                                    // hiding agencies filter in this view since address page is agency specific
                                    hideAgenciesFilter={true}
                                />
                            )}
                            {currentTab === TabEnum.ASSOCIATED_PEOPLE && (
                                <AssociatedPeopleView
                                    isLoading={eventLoading}
                                    associatedPeople={people || []}
                                    associatedPeopleCount={peopleCount}
                                    sortConfig={sortConfig}
                                    defaultSortField="associated_event_count"
                                    defaultSortDirection={sortConfig["birthdate"].defaultSortDirection}
                                    scrollToTop={scrollToTop}
                                    pageReset={pageReset}
                                    agencyId={address?.agency_id}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

type TNavItems = { currentTab?: string; setCurrentTab: (value: TabEnum) => void; eventCount: number; peopleCount: number };

const AddressDesktopNavItems = ({ setCurrentTab, eventCount, peopleCount }: TNavItems) => {
    return (
        <>
            <Nav.Item>
                <Nav.Link onClick={() => setCurrentTab(TabEnum.INCIDENTS)} eventKey={TabEnum.INCIDENTS}>
                    Associated Events
                    {eventCount > 0 && ` - ${eventCount}`}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={`${peopleCount < 1 ? "cursor-not-allowed" : ""}`}>
                <Nav.Link
                    disabled={peopleCount < 1}
                    onClick={() => setCurrentTab(TabEnum.ASSOCIATED_PEOPLE)}
                    eventKey={TabEnum.ASSOCIATED_PEOPLE}
                >
                    Associated People
                    {peopleCount > 0 && ` - ${peopleCount}`}
                </Nav.Link>
            </Nav.Item>
        </>
    );
};

const AddressMobileNavItems = ({ currentTab, setCurrentTab, eventCount, peopleCount }: TNavItems) => {
    return (
        <>
            <Nav.Item>
                <Nav.Link
                    className={`${currentTab === TabEnum.INCIDENTS ? "active" : ""} rounded border border-primary`}
                    onClick={() => setCurrentTab(TabEnum.INCIDENTS)}
                    eventKey={TabEnum.INCIDENTS}
                >
                    Associated Events
                    {eventCount > 0 && ` - ${eventCount}`}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    className={`${currentTab === TabEnum.ASSOCIATED_PEOPLE ? "active" : ""} rounded border border-primary`}
                    onClick={() => setCurrentTab(TabEnum.ASSOCIATED_PEOPLE)}
                    eventKey={TabEnum.ASSOCIATED_PEOPLE}
                    disabled={peopleCount < 1}
                >
                    Associated People
                    {peopleCount > 0 && ` - ${peopleCount}`}
                </Nav.Link>
            </Nav.Item>
        </>
    );
};

export default ConnectedAddressPage;
