import React from "react";
import { useGetDispatcherListQuery } from "../../api/api";
import SingleSelectFilter from "./SingleSelectFilter";
import { TAggregationType, byNameSort } from "../../hooks/useSearchFiltersAggregations";
import aggregationDataPresenceCount from "../../utils/aggregationDataPresenceCount";

const DispatcherFilter = ({ dispatcherAggregation }: { dispatcherAggregation?: Array<TAggregationType> }) => {
    return <ConnectedDispatcherFilterDropdown dispatcherAggregation={dispatcherAggregation} />;
};

const ConnectedDispatcherFilterDropdown = ({ dispatcherAggregation }: { dispatcherAggregation?: Array<TAggregationType> }) => {
    const { data: dispatcherListResponse } = useGetDispatcherListQuery({});

    const options = React.useMemo(
        () =>
            dispatcherListResponse?.data?.results
                .map((dispatcher: { value: string; name: string }) => {
                    const { present, count } = aggregationDataPresenceCount(dispatcherAggregation, dispatcher.name);

                    return {
                        value: dispatcher.value,
                        name: dispatcher.name,
                        present,
                        count,
                    };
                })
                .sort(byNameSort),
        [dispatcherAggregation, dispatcherListResponse]
    );
    return (
        <SingleSelectFilter
            pendoClass="pendo_dispatcher_filter_select"
            options={options || []}
            urlParamName="dispatcher"
            filterLabel="Select Dispatcher"
        />
    );
};

export default DispatcherFilter;
