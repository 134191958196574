import React from "react";
import { useParams } from "react-router-dom";
import { useGetPageUrlFromShareUUIDQuery, useGetUserDetailsQuery } from "../../api/api";
import LoadingModal from "../../components/LoadingModal";
import NotFound from "../support/NotFound";
import { useAppDispatch } from "../../app/hooks";
import { setUserObject } from "../../app/slices/user/userSlice";
import usePermissions from "../../hooks/usePermissions";

type OwnProps = {
    isError: boolean;
    isLoading: boolean;
    urlToNavigate?: string;
};

export function TranslateShareUUIDPage({ isError, isLoading, urlToNavigate }: OwnProps) {
    React.useEffect(() => {
        if (!isLoading && !!urlToNavigate) {
            window.location.replace(urlToNavigate);
        }
    }, [isLoading, urlToNavigate]);

    return (
        <>
            <LoadingModal show={!isError && isLoading} />
            {isError && <NotFound />}
        </>
    );
}

function ConnectedTranslateShareUUIDPage() {
    const dispatch = useAppDispatch();
    const { hasPermission } = usePermissions();
    const [skip, setSkip] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const { shareUUID } = useParams();
    const { data: userDetails } = useGetUserDetailsQuery({}, { skip });
    const { data } = useGetPageUrlFromShareUUIDQuery({ uuid: shareUUID }, { skip: !shareUUID });

    let invalidUrl = false;
    let urlToNavigate: string | undefined = undefined;

    // check for valid URL
    if (!!data?.url) {
        try {
            const newUrl = new URL(data.url, window.location.origin);
            urlToNavigate = `${newUrl.pathname}?${newUrl.searchParams}`;
        } catch (e) {
            invalidUrl = true;
        }
    }

    // Update user object before proceeding to ensure redux has up to date agency information stored
    React.useEffect(() => {
        if (userDetails) {
            dispatch(setUserObject(userDetails.results));
            setIsLoading(false);
        }
    }, [userDetails, dispatch]);

    React.useEffect(() => {
        if (!!urlToNavigate) {
            // if user can switch agencies (i.e. FM user), need to update user object to get current active agency
            // since BE will change active agency if share belongs to different agency than current.
            // Fetch user details fresh to get up to date value to prevent FM user seeing 404 page
            if (hasPermission("switch_agencies")) {
                setSkip(false);
            } else {
                // if user cannot switch agencies, there is no risk of current agency
                // on FE being out of date and can proceed
                setIsLoading(false);
            }
        }
    }, [hasPermission, urlToNavigate]);

    return (
        <TranslateShareUUIDPage
            isError={(!!data && !data.success) || (!!userDetails && !userDetails.success) || invalidUrl}
            isLoading={isLoading}
            urlToNavigate={urlToNavigate}
        />
    );
}

export default ConnectedTranslateShareUUIDPage;
