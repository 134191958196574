import { Stack } from "react-bootstrap";
import Highlighter from "../highlighter/Highlighter";
import { renderField } from "../../utils/string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import LinkWrapper from "../LinkWrapper";
import useNextAwarePathname from "../../hooks/useNextAwarePathname";

type OwnProps = {
    fieldName: string;
    fieldValue: string;
    link: string;
    newTabLink?: boolean;
    pendoClass?: string;
};
const LinkPanelCell = ({ fieldName, fieldValue, link, newTabLink = false, pendoClass = "" }: OwnProps) => {
    const { canPerformNextFESearch } = useNextAwarePathname();
    const renderValue = renderField(fieldValue);
    return (
        <Stack className="border-top px-3 py-2">
            <span className="fw-bold">{fieldName}</span>
            <LinkWrapper isAnchor={canPerformNextFESearch} className={pendoClass} to={link} target={newTabLink ? "_blank" : ""}>
                <div className="d-flex flex-row justify-content-start">
                    {newTabLink ? <FontAwesomeIcon icon={faUpRightFromSquare} className="text-primary me-2 mt-1" /> : null}
                    <div>
                        <Highlighter>{renderValue}</Highlighter>
                    </div>
                </div>
            </LinkWrapper>
        </Stack>
    );
};

export default LinkPanelCell;
