import React from "react";
import { Badge, Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightAndDownLeftFromCenter, faCheck } from "@fortawesome/pro-light-svg-icons";
import { useColumnSort } from "../../hooks/useColumnSort";
import SortableHeaderColumn from "../SortableHeader";
import { sortSortableHeader } from "../../utils/sort";
import { SortDirectionEnum } from "../../types/old_v1/types";
import { formatDate } from "../../utils/date";

const DEFAULT_SORT = "hazardousSort";

export const WacoPersonTags = ({ tags, showExpand = false }: { tags: any[]; expandAlertsOnClick?: boolean; showExpand?: boolean }) => {
    const [showAlertModal, setShowAlertModal] = React.useState(false);
    const isHazardous = tags && tags.some((t: any) => t.is_hazardous);
    const nonHazardousAlerts = tags && tags.some((t: any) => !t.is_hazardous);
    const hasTags = isHazardous || nonHazardousAlerts;

    const { sortColumn, sortDirection, setSort } = useColumnSort(DEFAULT_SORT, SortDirectionEnum.DESCENDING);
    const sortedTags = (Array.isArray(tags) ? [...tags] : [])
        .map((tag) => ({ ...tag, hazardousSort: `${tag.is_hazardous}-${tag.entry_date}` }))
        .sort(sortSortableHeader(sortColumn, true, sortDirection));

    return (
        <React.Fragment>
            {hasTags && (
                <div className={hasTags ? "" : "mb-3"}>
                    {isHazardous && (
                        <Badge
                            className={`pendo_person_hazardous_alert_modal_button fs-5 d-inline-block badge me-2 mb-1 align-content-bottom ${
                                showExpand ? "cursor-pointer" : ""
                            }`}
                            onClick={() => showExpand && setShowAlertModal(true)}
                            bg="danger"
                        >
                            Hazardous&nbsp;{showExpand && <FontAwesomeIcon size="sm" icon={faUpRightAndDownLeftFromCenter} />}
                        </Badge>
                    )}
                    {nonHazardousAlerts && (
                        <Badge
                            className={`pendo_person_alert_modal_button fs-5 d-inline-block badge me-2 mb-1 align-content-bottom ${
                                showExpand ? "cursor-pointer" : ""
                            }`}
                            onClick={() => showExpand && setShowAlertModal(true)}
                            bg="warning"
                        >
                            Alert&nbsp;{showExpand && <FontAwesomeIcon size="sm" icon={faUpRightAndDownLeftFromCenter} />}
                        </Badge>
                    )}
                </div>
            )}
            <Modal show={showAlertModal} onHide={() => setShowAlertModal(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title as="h2">Alerts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table size="sm" responsive>
                        <thead>
                            <tr>
                                <SortableHeaderColumn
                                    columnName="Hazardous?"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey={DEFAULT_SORT}
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="Flag Type"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="flag_type"
                                    defaultDirection={SortDirectionEnum.ASCENDING}
                                />
                                <th>Caution</th>
                                <th>Remarks</th>
                                <SortableHeaderColumn
                                    columnName="Entry Date"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="entry_date"
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnClass="px-3"
                                    columnName="Start Date"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="start_date"
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="Clear Date"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="clear_date"
                                    defaultDirection={SortDirectionEnum.DESCENDING}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {sortedTags.map((tag) => (
                                <tr key={`${tag.expiration_date}-${tag.entry_date}-${tag.clear_date}-${tag.flag_type}-${tag.officer}`}>
                                    <td className="text-center text-nowrap">
                                        {tag.is_hazardous ? <FontAwesomeIcon className="text-danger" icon={faCheck} /> : null}
                                    </td>
                                    <td className="text-nowrap">{tag.flag_type}</td>
                                    <td>{tag.hazards}</td>
                                    <td>{tag.remarks}</td>
                                    <td className="text-nowrap">{formatDate(tag.entry_date)}</td>
                                    <td className="text-nowrap px-3">{formatDate(tag.start_date)}</td>
                                    <td className="text-nowrap">{formatDate(tag.clear_date)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};
