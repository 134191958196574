import { Link } from "react-router-dom";
import { Button, Row, Col, Container } from "react-bootstrap";

/**
 * Creates the Not Found presentation component with link back to home
 * @constructor
 */
const NotFound = () => {
    return (
        <Container fluid={true}>
            <Row className="align-items-center justify-content-center min-vh-100">
                <Col md={5} lg={4}>
                    <h1 className="display-3 fw-bold text-center">Error</h1>
                    <p className="mb-5 text-center text-muted">
                        We ran into an issue while processing your request. Our team has been alerted that something went wrong.
                    </p>
                    <div className="text-center">
                        <Button as={Link as any} to="/" className="back-to-home">
                            Back to our home page
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default NotFound;
