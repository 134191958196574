import { faPenClip } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, ListGroup } from "react-bootstrap";
import { formatDate } from "../../utils/date";
import { NewNarrative } from "../../types/old_v1/types";

type OwnProps = {
    narrative: NewNarrative;
};

const SimpleNarrativeListItem = ({ narrative }: OwnProps) => {
    return (
        <ListGroup.Item as={Card} className="p-0 mb-3 border-0">
            <Card.Header className="w-100 d-flex justify-content-between align-items-center border-0 rounded overflow-hidden">
                <div className="fw-bold">
                    <FontAwesomeIcon icon={faPenClip} className="me-3" />
                    {!!narrative.created_at && <span className="fw-normal me-3">{formatDate(narrative.created_at)}</span>}
                    <span className="fw-bold">{narrative.author}</span>
                </div>
            </Card.Header>
        </ListGroup.Item>
    );
};

export default SimpleNarrativeListItem;
