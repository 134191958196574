import React from "react";
import Highlighter from "../../../components/highlighter/Highlighter";
import { underToWords } from "../../../utils/string";

type OwnProps = {
    callNotes: any[];
};

const IGNORE_FIELDS = [
    "agency",
    "agency_id",
    "call_key",
    "call_number",
    "created_at",
    "hidden",
    "highlights",
    "index",
    "operator_terminal",
    "operator_terminal.keyword",
    "segment_name",
    "segment_name.keyword",
    "segment_time",
    "segment_time.keyword",
    "sequence_id",
    "source",
    "unit",
    "unit.keyword",
];

const WacoCallNotes = ({ callNotes }: OwnProps) => {
    let sortedCallNotes = callNotes ? [...callNotes] : [];
    sortedCallNotes = sortedCallNotes.sort((a: any, b: any) => {
        const aVal = a?._source?.sequence_id || 10000;
        const bVal = b?._source?.sequence_id || 10000;
        return aVal > bVal ? 1 : -1;
    });

    const getValue = (callNote: any, field: string) => {
        const val = callNote && callNote[field];
        return Array.isArray(val) ? val.join("") : val;
    };

    return (
        <>
            {!!sortedCallNotes && sortedCallNotes.length && Array.isArray(sortedCallNotes)
                ? sortedCallNotes.map((note: any, index: number) => {
                      const noteSource = { ...note._source, ...note.highlight };
                      const otherKeys = Object.keys(noteSource)
                          .sort()
                          .filter((n: string) => !IGNORE_FIELDS.includes(n) && noteSource[n])
                          .filter((field) => !field.includes(".keyword"));
                      const segmentName =
                          Array.isArray(noteSource.segment_name) && noteSource.segment_name.length
                              ? noteSource.segment_name[0]
                              : noteSource.segment_name;
                      const operatorTerminal =
                          Array.isArray(noteSource.operator_terminal) && noteSource.operator_terminal.length
                              ? noteSource.operator_terminal[0]
                              : noteSource.operator_terminal;
                      const unit = Array.isArray(noteSource.unit) && noteSource.unit.length ? noteSource.unit[0] : noteSource.unit;
                      return (
                          <p className={`mb-1${index === 0 ? "" : " mt-4"}`} key={note._id} style={{ whiteSpace: "pre-line" }}>
                              <strong>
                                  {note._source && note._source.segment_time && note._source.segment_time.substring(0, 8)}&nbsp;
                                  <Highlighter>{segmentName}</Highlighter>&nbsp;
                                  <Highlighter>{operatorTerminal}</Highlighter>
                                  {noteSource.unit && (
                                      <React.Fragment>
                                          &nbsp;
                                          <span className="text-decoration-underline">
                                              <Highlighter>{unit}</Highlighter>
                                          </span>
                                      </React.Fragment>
                                  )}
                              </strong>
                              &nbsp;
                              {otherKeys.map((field: string, fidx: number) => {
                                  return (
                                      <React.Fragment key={field}>
                                          <strong className="text-muted">{underToWords(field)}</strong>:&nbsp;
                                          {<Highlighter>{getValue(noteSource, field)}</Highlighter>}
                                          {fidx < otherKeys.length - 1 ? ", " : ""}
                                      </React.Fragment>
                                  );
                              })}
                          </p>
                      );
                  })
                : null}
        </>
    );
};

export default WacoCallNotes;
