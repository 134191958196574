import React from "react";
import { Button, Col, Form, Row, Stack, Table } from "react-bootstrap";
import { useGetUserStatsQuery } from "../../api/api";
import { ago } from "../../utils/date";
import LoadingModal from "../LoadingModal";
import SortableHeaderColumn from "../../components/SortableHeader";
import { useColumnSort } from "../../hooks/old_v1/useColumnSort";
import { ASCENDING, sort, sortSortableHeader } from "../../utils/old_v1/sort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateBack } from "@fortawesome/pro-light-svg-icons";

type OwnProps = {
    data: any[];
    loading: boolean;
};

const DEFAULT_SORT = "last_name,first_name";
const DEFAULT_SORT_ORDER = ASCENDING;

const ConnectedUserStatistics = () => {
    const { data, isLoading, isFetching } = useGetUserStatsQuery({});
    return <UserStatistics data={data} loading={isLoading || isFetching} />;
};

export const UserStatistics = ({ data: incomingData, loading }: OwnProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { sortColumn, sortDirection, setSort } = useColumnSort(DEFAULT_SORT, DEFAULT_SORT_ORDER);
    const [displayPeople, setDisplayPeople] = React.useState<any>(undefined);

    let sortedPeople = (Array.isArray(incomingData) ? [...incomingData] : []).sort(
        sortSortableHeader(sortColumn, !["follows_count", "count", "count_day"].includes(sortColumn), sortDirection)
    );

    React.useEffect(() => {
        if (incomingData) {
            let people = (Array.isArray(incomingData) ? [...incomingData] : []).sort(
                sortSortableHeader(sortColumn, !["follows_count", "count", "count_day"].includes(sortColumn), sortDirection)
            );
            setDisplayPeople(people);
        }
    }, [incomingData, sortColumn, sortDirection]);

    const handleChange = (e: any) => {
        const val = e?.target?.value ? e.target.value.toLowerCase() : "";
        const list = (Array.isArray(incomingData) ? [...incomingData] : []).filter((item: any) => {
            return item?.last_name?.toLowerCase().startsWith(val) || item?.first_name?.toLowerCase().startsWith(val);
        });
        list.sort(sort("last_name,first_name", true));
        setDisplayPeople(list);
    };

    const clear = () => {
        if (inputRef && inputRef?.current) {
            inputRef.current.value = "";
        }
        setDisplayPeople(sortedPeople);
    };

    return (
        <>
            <Row>
                <Col sm={4}>
                    <Stack direction="horizontal" gap={3}>
                        <Form.Control ref={inputRef} as="input" placeholder="Search" onChange={handleChange} />
                        <Button variant="outline-danger" onClick={clear} type="button" className="text-nowrap shadow-none" size="sm">
                            <FontAwesomeIcon icon={faArrowRotateBack} className="me-2" />
                            Clear
                        </Button>
                    </Stack>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table responsive>
                        <thead>
                            <tr>
                                <SortableHeaderColumn
                                    sortFn={setSort}
                                    columnName="Name"
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="last_name"
                                    defaultDirection={ASCENDING}
                                />
                                <SortableHeaderColumn
                                    sortFn={setSort}
                                    columnName="Email"
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="email"
                                    defaultDirection={ASCENDING}
                                />
                                <SortableHeaderColumn
                                    sortFn={setSort}
                                    columnName="Total"
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="count"
                                    defaultDirection={ASCENDING}
                                />
                                <SortableHeaderColumn
                                    sortFn={setSort}
                                    columnName="24H"
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="count_day"
                                    defaultDirection={ASCENDING}
                                />
                                <SortableHeaderColumn
                                    sortFn={setSort}
                                    columnName="Follows"
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="follows_count"
                                    defaultDirection={ASCENDING}
                                />
                                <SortableHeaderColumn
                                    sortFn={setSort}
                                    columnName="Last Login"
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="last_login"
                                    defaultDirection={ASCENDING}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {displayPeople &&
                                displayPeople.map((user: any) => (
                                    <tr key={user?.email}>
                                        <td>{`${user?.last_name}, ${user?.first_name}`}</td>
                                        <td>{user?.email}</td>
                                        <td>{user?.count}</td>
                                        <td>{user?.count_day}</td>
                                        <td>{user?.follows_count}</td>
                                        <td>{user?.last_login ? ago(new Date(), user?.last_login) : "never"}</td>
                                    </tr>
                                ))}
                            {(!displayPeople || displayPeople.length === 0) && (
                                <tr>
                                    <td className="border-0" colSpan={7}>
                                        No Results
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <LoadingModal show={loading} />
        </>
    );
};

export default ConnectedUserStatistics;
