import React from "react";
import { Button, Row, Col, Stack } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import { useRunPreAuthApiMutation, ACCOUNT_SETUP_VALIDATE_PASSWORD, ACCOUNT_SETUP_VALIDATE_TOKEN } from "../../api/api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import TextField from "../../components/formcontrols/TextField";
import FmAlert from "../support/FmAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { useStep } from "./AccountSetup";
import { setOnboardingObject } from "../../app/slices/user/userSlice";
import { OnboardingObject } from "../../types/old_v1/types";

type OwnProps = {
    onboardingObject: OnboardingObject;
};

type FormProps = {
    password: string;
    passwordConfirm: string;
};

const Step1PasswordConnected = () => {
    const user = useAppSelector(({ user }) => user);
    const onboardingObject = user.onboardingObject;
    return <Step1Password onboardingObject={onboardingObject} />;
};

// for Yup validation message, needs to return a function
export const getInvalidPasswordMessage = () => {
    return (
        <span className="text-danger">
            Passwords must:
            <ul>
                <li>Not be too similar to name</li>
                <li>Minimum 8 characters</li>
                <li>Not too common</li>
                <li>Not entirely numbers</li>
            </ul>
        </span>
    );
};

/**
 * First page in the create new account process.  Presents 2 password fields for the user to create a password.
 * Some minor validation is performed in this page to make sure the password inputs match but the server runs a more
 * thorough check to make sure the new password isn't too common.  If the server determines the password is not valid
 * errors will be returned to indicate what the problem is.  This page will display an Alert with the appropriate
 * error message if invalid or will load the next page to pick the mfa if it is valid.
 * @constructor
 */
const Step1Password = ({ onboardingObject }: OwnProps) => {
    useSetPageTitle("ForceMetrics | Step1 - Password");
    const { setStep } = useStep();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const tokenFromEmail = searchParams.get("token");
    const [alert, setAlert] = React.useState<string | undefined>();
    const [validateToken, validateTokenResult] = useRunPreAuthApiMutation();
    const [validatePassword, validatePasswordResult] = useRunPreAuthApiMutation();
    const [password, setPassword] = React.useState<string | null>();
    const initial: FormProps = {
        password: "",
        passwordConfirm: "",
    };

    let onboarding_token: string | undefined = tokenFromEmail
        ? tokenFromEmail
        : onboardingObject && onboardingObject.onboarding_token
        ? onboardingObject.onboarding_token
        : undefined;

    React.useEffect(() => {
        if (!onboarding_token) {
            setAlert("token_invalid");
        }
    }, [onboarding_token]);

    // validate the token from the email
    React.useEffect(() => {
        setStep(1);
        if (tokenFromEmail || onboarding_token) {
            validateToken({ route: ACCOUNT_SETUP_VALIDATE_TOKEN, onboarding_token });
        }
    }, [tokenFromEmail, validateToken, onboarding_token, setStep]);

    // listen for validateToken response and display appropriate alert if error or !success
    React.useEffect(() => {
        if (validateTokenResult.data) {
            if (validateTokenResult.data.error || !validateTokenResult.data.success) {
                setAlert(validateTokenResult.data.error || "other");
            }
        }
    }, [validateTokenResult]);

    // sends the password to the server for validation
    const submitPassword = (values: FormProps) => {
        setPassword(values.password);
        const payload = {
            password1: values.password,
            password2: values.passwordConfirm,
            onboarding_token,
            route: ACCOUNT_SETUP_VALIDATE_PASSWORD,
        };
        validatePassword(payload);
    };

    // Listens for the results from validatePassword
    React.useEffect(() => {
        if (validatePasswordResult.data) {
            if (validatePasswordResult.data.error || !validatePasswordResult.data.success) {
                setAlert(validatePasswordResult.data.error || "other");
            }
            if (validatePasswordResult.data.success) {
                // password is valid, setup the onboarding object in redux and move to the mfa selection screen
                setStep(2);
                dispatch(setOnboardingObject({ ...validatePasswordResult.data, step: 2, password, onboarding_token }));
                navigate("/account-setup/step2-select-mfa");
            }
        }
    }, [validatePasswordResult, dispatch, navigate, setStep, onboarding_token, password]);

    // used for disabling submit button
    const loading = validatePasswordResult && validatePasswordResult.isLoading;

    return (
        <div className="mt-4">
            <div className="d-flex justify-content-center p-3">
                <h2>Create Password</h2>
            </div>
            {alert && (
                <Row className="justify-content-center">
                    <Col md={8} lg={6}>
                        {onboarding_token ? <FmAlert alertKey={alert} onClose={() => setAlert(undefined)} /> : <FmAlert alertKey={alert} />}
                    </Col>
                </Row>
            )}
            <Row className="justify-content-center">
                <Col xs={9} sm={7} md={5} lg={4} xl={3} className="border p-4 bg-filled">
                    <Stack>
                        <Formik
                            initialValues={initial}
                            onSubmit={(values) => {
                                submitPassword(values);
                            }}
                            validationSchema={Yup.object({
                                password: Yup.string().min(8, getInvalidPasswordMessage).required("Required"),
                                passwordConfirm: Yup.string()
                                    .oneOf([Yup.ref("password"), null], "Passwords must match")
                                    .required("Required"),
                            })}
                        >
                            {(props) => (
                                <Form>
                                    <TextField label="Password" name="password" type="password" />
                                    <TextField label="Re-enter Password" name="passwordConfirm" type="password" />
                                    <Button type="submit" variant="primary" disabled={loading || !props.dirty} className="mt-2 float-end">
                                        <FontAwesomeIcon icon={loading ? faSpinner : faPaperPlane} className="me-2" />
                                        Next
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Stack>
                </Col>
            </Row>
        </div>
    );
};

Step1Password.displayName = "Step1Password";
export default Step1PasswordConnected;
