import React from "react";
import { Col, Form, Row, Stack } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import SingleSelectFilter from "./SingleSelectFilter";

const AgeFilter = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const params = new URLSearchParams(searchParams);
    const age = params.get("age");
    const ageType = params.get("ageType");
    const minAge = ageType === "range" ? age?.split("-")[0] : "";
    const maxAge = ageType === "range" ? age?.split("-")[1] : "";
    const [filterAge, setFilterAge] = React.useState(age || "");
    const [filterAgeMin, setFilterAgeMin] = React.useState(minAge || "");
    const [filterAgeMax, setFilterAgeMax] = React.useState(maxAge || "");

    const applyAge = () => {
        params.delete("page");
        filterAge === "" ? params.delete("age") : params.set("age", filterAge);
        navigate(`.?${params.toString()}`);
    };

    const applyAgeRange = () => {
        const valid = parseInt(filterAgeMin) <= parseInt(filterAgeMax);
        if (!!filterAgeMin && !!filterAgeMax && valid) {
            params.delete("page");
            params.set("age", `${filterAgeMin}-${filterAgeMax}`);
            navigate(`.?${params.toString()}`);
        }
    };

    const handleChange = (e: any, setFunc: Function) => {
        const value = e.target.value ? e.target.value.toLowerCase() : "";
        setFunc(value);
    };

    const options = [
        { value: "single", name: "Single Age" },
        { value: "range", name: "Age Range" },
    ];

    return (
        <Stack gap={3}>
            <SingleSelectFilter
                pendoClass="pendo_select_age_filter_type"
                options={options || []}
                urlParamName="ageType"
                filterLabel="Select Age Filter Type"
                clearURLParams={["age"]}
            />
            {ageType === "range" && parseInt(filterAgeMin) > parseInt(filterAgeMax) && (
                <span className="text-danger">Minimum age must be less than maximum age</span>
            )}
            {ageType === "single" && (
                <Form.Control
                    onChange={(e) => handleChange(e, setFilterAge)}
                    onMouseLeave={applyAge}
                    onBlur={applyAge}
                    placeholder="Set age"
                    defaultValue={filterAge}
                    className="pendo_single_age_filter_input"
                    type="number"
                ></Form.Control>
            )}
            {ageType === "range" && (
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className="text-nowrap">Minimum Age</Form.Label>
                            <Form.Control
                                onChange={(e) => handleChange(e, setFilterAgeMin)}
                                onMouseLeave={applyAgeRange}
                                onBlur={applyAgeRange}
                                placeholder="Set age"
                                defaultValue={filterAgeMin}
                                className="pendo_min_age_filter_input"
                                type="number"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label className="text-nowrap">Maximum Age</Form.Label>
                            <Form.Control
                                onChange={(e) => handleChange(e, setFilterAgeMax)}
                                onMouseLeave={applyAgeRange}
                                onBlur={applyAgeRange}
                                placeholder="Set age"
                                defaultValue={filterAgeMax}
                                className="pendo_max_age_filter_input"
                                type="number"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            )}
        </Stack>
    );
};

AgeFilter.displayName = "AgeFilterConnected";
export default AgeFilter;
