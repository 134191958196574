import { Alert, Card, Modal, Stack, Table } from "react-bootstrap";
import { useGetAllSharedUrlsQuery } from "../../api/api";
import React from "react";
import { FromES, Share, UserShare } from "../../types/old_v1/types";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMessage, faX } from "@fortawesome/pro-light-svg-icons";
import LoadingModal from "../../components/LoadingModal";
import { formatDate } from "../../utils/date";
import { getUrlPathname } from "../../utils/url";

const AllSharesConnected = () => {
    const { data, error, isFetching } = useGetAllSharedUrlsQuery({}, { refetchOnMountOrArgChange: true });

    if (!!error) {
        return <Alert variant="danger">Server error retrieving shares</Alert>;
    }

    return (
        <Card className="overflow-hidden">
            <Card.Header>Shared URLs</Card.Header>
            <Card.Body>
                <AllShares shares={data?.share_hits || []} isLoading={isFetching} />
            </Card.Body>
        </Card>
    );
};

type OwnProps = {
    shares: FromES<Share>[];
    isLoading: boolean;
};
const AllShares = ({ shares, isLoading }: OwnProps) => {
    const navigate = useNavigate();
    const sortedShares = [...shares].sort((share1, share2) => (share1?.shared_at <= share2?.shared_at ? 1 : -1));
    const [showViewedModal, setShowViewedModal] = React.useState(false);
    const [selectedShare, setSelectedShare] = React.useState<any>();

    const goTo = (share: any) => {
        navigate(getUrlPathname(share?.shared_with[0]?.uuid_url));
    };

    const findLastViewed = (sharedWith: UserShare[]) => {
        return sharedWith
            .map((share) => {
                return share?.viewed_at === null ? "" : share?.viewed_at;
            })
            .sort((a: any, b: any) => (a < b ? 1 : -1))[0];
    };

    const toggleModal = (share: any) => {
        setSelectedShare(share);
        setShowViewedModal(true);
    };

    return (
        <>
            <LoadingModal show={isLoading} />
            <Table responsive>
                <thead className="sticky-header">
                    <tr>
                        <th>Title</th>
                        <th>Shared By</th>
                        <th>Shared At</th>
                        <th>Last Viewed</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedShares &&
                        sortedShares.length > 0 &&
                        sortedShares.map((share) => (
                            <tr key={share?.id}>
                                <td className="cursor-pointer" onClick={() => goTo(share)}>
                                    <Stack direction="horizontal" gap={1}>
                                        <FontAwesomeIcon icon={faMessage} className={"text-primary me-2"} />
                                        <span>{!!share?.title ? share?.title : "Shared Item"}</span>
                                    </Stack>
                                </td>
                                <td className="text-nowrap">{share?.shared_with[0]?.shared_by_email}</td>
                                <td className="text-nowrap">{formatDate(share?.shared_at)}</td>
                                <td className="text-nowrap cursor-pointer" onClick={() => toggleModal(share)}>
                                    {formatDate(findLastViewed(share?.shared_with))}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            <Modal show={showViewedModal} onHide={() => setShowViewedModal(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title as="h2">Share viewed by</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th>Viewed</th>
                                <th>Shared to</th>
                                <th>Viewed at</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedShare?.shared_with?.map((sw: any) => {
                                return (
                                    <tr key={sw?.shared_to}>
                                        <td>
                                            {sw?.viewed_at !== null ? (
                                                <FontAwesomeIcon color={"green"} icon={faCheck} />
                                            ) : (
                                                <FontAwesomeIcon color={"red"} icon={faX} />
                                            )}
                                        </td>
                                        <td>{sw?.shared_to}</td>
                                        <td>{formatDate(sw?.viewed_at)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AllSharesConnected;
