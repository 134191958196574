import { Button } from "react-bootstrap";
import Highlighter from "../../../components/highlighter/Highlighter";
import { underToWords } from "../../../utils/string";
import { format, parseISO } from "date-fns";

type OwnProps = {
    callNotes: any[];
    isTruncated?: boolean;
    setCurrentTab?: () => void;
};

const IGNORE_FIELDS = [
    "id",
    "agency",
    "agency_id",
    "agency_desc",
    "call_key",
    "call_number",
    "created_at",
    "hidden",
    "highlights",
    "index",
    "operator_terminal",
    "operator_terminal.keyword",
    "segment_name",
    "segment_name.keyword",
    "segment_time",
    "segment_time.keyword",
    "sequence_id",
    "source",
    "unit.keyword",
];

const AdvanceCallNotes = ({ callNotes, isTruncated = false, setCurrentTab = () => null }: OwnProps) => {
    let sortedCallNotes = callNotes ? [...callNotes] : [];
    sortedCallNotes = sortedCallNotes.sort((a: any, b: any) => {
        const aVal = a?.sequence_id || 10000;
        const bVal = b?.sequence_id || 10000;
        return aVal > bVal ? 1 : -1;
    });

    const getValue = (callNote: any, field: string) => {
        const val = callNote && callNote[field];
        return Array.isArray(val) ? val.join("") : val;
    };

    return (
        <>
            {!!sortedCallNotes && sortedCallNotes.length && Array.isArray(sortedCallNotes)
                ? sortedCallNotes.map((note: any, index: number) => {
                      const otherKeys = Object.keys(note)
                          .sort()
                          .filter((n: string) => !IGNORE_FIELDS.includes(n) && note[n])
                          .filter((field) => !field.includes(".keyword"));
                      const segmentName =
                          Array.isArray(note?.segment_name) && note?.segment_name?.length ? note?.segment_name[0] : note?.segment_name;
                      const operatorTerminal =
                          Array.isArray(note?.operator_terminal) && note?.operator_terminal?.length
                              ? note?.operator_terminal[0]
                              : note?.operator_terminal;
                      const unit = Array.isArray(note?.unit) && note?.unit?.length ? note?.unit[0] : note?.unit;

                      // Beaware of case sensitive keys
                      const commentIndex = otherKeys.indexOf("Comment");
                      const unitIndex = otherKeys.indexOf("unit");

                      // If comment exists, move it to front in the array
                      // Comment gets to be in front because it tends to have human-made data which is most valuable typically.
                      if (commentIndex > -1) {
                          otherKeys.splice(commentIndex, 1);
                          otherKeys.unshift("Comment");
                      }
                      // If unit exists, move it in front of comment in the array
                      // Unit came from a historic decision to match the old system as close as possible,
                      // that's why it gets to be in front when present
                      if (unitIndex > -1) {
                          otherKeys.splice(unitIndex, 1);
                          otherKeys.unshift("unit");
                      }

                      return (
                          <p className={`mb-1${index === 0 ? "" : " mt-4"}`} key={note?.id} style={{ whiteSpace: "pre-line" }}>
                              {(segmentName || operatorTerminal || unit || (note && note?.segment_time) || note?.created_at) && (
                                  <>
                                      <small className="border-bottom">
                                          {note?.segment_time && (
                                              <span className="text-secondary">
                                                  {note?.segment_time && format(parseISO(note?.created_at), "MM-dd-yy HH:mm:ss")}
                                              </span>
                                          )}

                                          <strong className="ps-2">
                                              <Highlighter>{segmentName}</Highlighter>&nbsp;
                                              <Highlighter>{operatorTerminal}</Highlighter>
                                          </strong>
                                      </small>
                                      &nbsp;
                                      <br />
                                  </>
                              )}
                              {!isTruncated &&
                                  otherKeys.map((field: string, fidx: number) => {
                                      return (
                                          <span key={field}>
                                              <strong className="text-muted">{underToWords(field)}</strong>:&nbsp;
                                              {<Highlighter>{getValue(note, field)}</Highlighter>}
                                              {fidx < otherKeys.length - 1 ? ", " : ""}
                                          </span>
                                      );
                                  })}
                          </p>
                      );
                  })
                : null}
            {isTruncated && sortedCallNotes.length === 5 && (
                <div>
                    <Button className="px-0 pendo_advance_call_notes_see_more" variant="link" onClick={setCurrentTab}>
                        View More
                    </Button>
                </div>
            )}
        </>
    );
};

export default AdvanceCallNotes;
