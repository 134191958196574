import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useUpdateUrl } from "../../hooks/useUpdateUrl";
import { scrollToTop } from "../../utils/helpers";

const pendoPaginationClass = "pendo_pagination_link";

type OwnProps = {
    page?: string | number | null;
    url: string;
    totalPages: number;
    additionalPaginationClasses?: string;
    pagesDisplayCount?: number;
};

type PageLinkProps = {
    url?: string;
    tabIndex?: number;
    title: string;
    disabled?: boolean;
    active?: boolean;
    scrollToTop?: any;
};

/**
 * Component that renders an individual link or link-like HTML to a specific page in the paginator
 * @param   {string} url The url to link to. If using internal state than this must be an empty string ""
 * @param   {number} tabIndex An index for keyboard navigation
 * @param   {string} title The text to display in the link
 * @param   {boolean} disabled Indicates if link is disabled
 * @param   {boolean} active Indicates if link is active
 * @returns <PageLink page={page} url={url} tabIndex={tabIndex} title={title} disabled={false} active={true} />
 */

const PageLink = ({ url, tabIndex, title, disabled, active }: PageLinkProps) => {
    if (disabled) {
        return (
            <Button disabled variant="outline-primary" tabIndex={tabIndex}>
                {title}
            </Button>
        );
    }

    if (!!url) {
        return (
            <Link
                className={`${pendoPaginationClass} btn ${active ? "btn-primary" : "btn-outline-primary"}`}
                role="button"
                to={url}
                tabIndex={1}
                onClick={() => scrollToTop()}
            >
                {title}
            </Link>
        );
    }

    return <div />;
};

// this pagesDisplayCount determines how many pages we populate between the prev and next buttons
const getPages = (pageNumber: number, url: any, updateUrl: any, pagesDisplayCount = 7) => {
    return Array.from({ length: pagesDisplayCount }, (_, i) => {
        const pn: any = i + pageNumber - 3;
        return {
            title: pn.toString(),
            url: !!url ? updateUrl("page", pn) : undefined,
            disabled: false,
            page: pn,
        };
    });
};

const getPageArray = (pages: any, totalPages: number, pageNumber: number, url: any, updateUrl: any) => {
    return [
        { title: "<<", url: !!url ? updateUrl("page", 1) : undefined, disabled: pageNumber === 1, page: 1 },
        {
            title: "<",
            url: !!url ? (pageNumber > 1 ? updateUrl("page", pageNumber - 1) : updateUrl()) : undefined,
            disabled: pageNumber === 1,
            page: Math.max(pageNumber - 1, 1),
        },
        ...pages,
        {
            title: ">",
            url: !!url ? updateUrl("page", pageNumber + 1) : undefined,
            disabled: pageNumber === totalPages,
            page: Math.min(pageNumber + 1, totalPages),
        },
        {
            title: ">>",
            url: !!url ? updateUrl("page", totalPages) : undefined,
            disabled: pageNumber === totalPages,
            page: totalPages,
        },
    ];
};

/**
 * Component that renders the full paginator. Can use query string parameter links or have a fully managed internal state
 * @param   {string | number | null} page The current page number to link to, whether an actual link or a page setter action
 * @param   {string} url The url to link to. If using internal state than this must be an empty string ""
 * @param   {number} totalPages The total number of pages in this paginator
 * @returns <FMPagination page={page} url={url} totalPages={10} />
 */

const FMPagination = ({ page = 1, url, totalPages = 1, pagesDisplayCount = 7 }: OwnProps) => {
    const updateUrl = useUpdateUrl(url);

    const pageNumber = Math.min(parseInt(page as string), totalPages) || 1;
    const pages = getPages(pageNumber, url, updateUrl, pagesDisplayCount);

    const pageArray = getPageArray(pages, totalPages, pageNumber, url, updateUrl);

    return (
        <ButtonGroup size="sm" className="pagination">
            {pageArray.map(({ url, title, disabled, page }) => {
                return page >= 1 && page <= totalPages ? (
                    <PageLink key={`key-${title}`} url={url} title={title} disabled={disabled} active={page === pageNumber} />
                ) : null;
            })}
        </ButtonGroup>
    );
};

export const FMPaginationNonLinks = ({
    page,
    totalPages,
    setPage,
    scrollToId = "mainContainer",
    pagesDisplayCount = 7,
}: {
    page: number;
    totalPages: number;
    setPage: any;
    scrollToId?: string;
    pagesDisplayCount?: number;
}) => {
    const pageNumber = page;
    const pages = getPages(pageNumber, null, null, pagesDisplayCount);
    const pageArray = getPageArray(pages, totalPages, pageNumber, null, null);
    return (
        <ButtonGroup size="sm" className="pagination">
            {pageArray.map(({ page, title, disabled }) =>
                page >= 1 && page && page <= totalPages ? (
                    <Button
                        className={pendoPaginationClass}
                        key={`key-${title}`}
                        disabled={disabled}
                        active={page === pageNumber}
                        onClick={() => {
                            setPage(page);
                            scrollToTop(scrollToId);
                        }}
                        variant="outline-primary"
                    >
                        {title}
                    </Button>
                ) : null
            )}
        </ButtonGroup>
    );
};

export default FMPagination;
