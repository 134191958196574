import React from "react";
import SearchInput from "../../components/SearchInput";
import { Col, Row } from "react-bootstrap";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import Logo from "../support/logo/Logo";
import PhoneAndPositionModal from "./PhoneAndPositionModal";
import usePermissions from "../../hooks/usePermissions";

type SearchLandingProps = {
    includeSearchHistory: boolean;
};

/**
 * Creates the SearchLandingPage presentation component
 * @param includeSearchHistory
 * @constructor
 */

export const SearchLandingPage = ({ includeSearchHistory }: SearchLandingProps) => {
    const { hasPermission } = usePermissions();

    return (
        <div className="overflow-hidden p-4">
            <PhoneAndPositionModal />

            <Row className="d-flex">
                <div className="d-flex flex-row justify-content-center mt-5">
                    <Logo style={{ width: "min(400px, 80%)" }} className="mb-5 ms-n5" />
                </div>
            </Row>

            <Row className="d-md-none mt-3"></Row>
            {hasPermission("search") && (
                <Row className="d-flex justify-content-center">
                    <Col xs={12} md={10} xl={8} xxl={6}>
                        <SearchInput searchPath="../search" includeSearchHistory={includeSearchHistory} />
                    </Col>
                </Row>
            )}
            {/* marginTop is an exact measurement based on search input spacing between text of Forcemetrics and quote */}
            <Row style={{ marginTop: "49px" }} className="text-center">
                <Col xs={12}>
                    <h2 className="fw-normal fst-italic">"Safety Starts with Understanding"</h2>
                </Col>
            </Row>
        </div>
    );
};

/**
 * Creates the SearchLandingPage connected component
 * @constructor
 */
const SearchLandingPageConnected = () => {
    useSetPageTitle("ForceMetrics | Home");

    return <SearchLandingPage includeSearchHistory={true} />;
};

export default SearchLandingPageConnected;
