import React from "react";
import { Button, Col, Form, Modal, Row, Stack, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateBack, faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";

type OwnProps = {
    users: any;
    setSelectedUser: Function;
    selectedUser: any;
    setActiveFilter?: Function;
};

export const UserSearchBar = ({ users, setSelectedUser, selectedUser, setActiveFilter }: OwnProps) => {
    const [displayUsers, setDisplayUsers] = React.useState<any[]>([]);
    const [show, setShow] = React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    // function that creates a list of objects for the modal to display names and emails.
    const getSearchList = (users: any) => {
        return users.reduce((accum: any, item: any) => {
            let tmp = [...accum];

            tmp.push({
                userName: `${item?.last_name}, ${item?.first_name}`,
                email: item?.email,
                firstName: item?.first_name,
                lastName: item?.last_name,
            });

            return tmp;
        }, []);
    };

    // sets up the username list for the modal
    React.useEffect(() => {
        if (users) {
            const results = users?.results ? users?.results : users;
            const searchList = getSearchList(results);
            setDisplayUsers(searchList);
        }
    }, [users]);

    // The modal input field used to filter down the list of names displayed for filtering
    const handleChange = (e: any) => {
        const value = e.target.value ? e.target.value.toLowerCase() : "";
        if (users) {
            const results = users?.results ? users?.results : users;
            const list = results.filter((item: any) => {
                return item?.last_name?.toLowerCase().startsWith(value) || item?.first_name?.toLowerCase().startsWith(value);
            });
            const searchList = getSearchList(list);
            setDisplayUsers(searchList);
        }
    };

    // The modal function to handle when a row (username) is selected to filter by
    const handleClick = (user: any) => {
        clear();
        setShow(false);
        setSelectedUser(user);
        //user admin page filter goes to all when searching for user
        if (setActiveFilter) {
            setActiveFilter("all");
        }
        // runSearch({ saved_date: DESCENDING }, user.email);
    };

    // convenience function to run the ES search api
    // const runSearch = (sort: { [key: string]: any }, email?: string) => {
    //     if (email) {
    //         getSearches({ page: 1, page_size: PAGE_SIZE, selectedUser: email, sort });
    //     } else {
    //         getSearches({ page: 1, page_size: PAGE_SIZE, sort });
    //     }
    // };

    // the function for the reset if a name is select
    const reset = () => {
        if (selectedUser) {
            setSelectedUser(undefined);
            // runSearch({ saved_date: DESCENDING });
        }
    };

    // the clear button function to remove a selected user in the modal
    const clear = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = "";
            const searchList = getSearchList(users?.results || []);
            setDisplayUsers(searchList);
        }
    };

    return (
        <>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Search for User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Stack direction="horizontal" gap={3}>
                                <Form.Control ref={inputRef} as="input" placeholder="Search" onChange={handleChange} autoFocus />
                                <Button variant="primary" onClick={clear}>
                                    Clear
                                </Button>
                            </Stack>
                        </Col>
                    </Row>
                    <Row>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayUsers &&
                                    displayUsers.length > 0 &&
                                    displayUsers.map((user: any) => {
                                        return (
                                            <tr key={user?.email} onClick={() => handleClick(user)} className="cursor-pointer">
                                                <td>{user?.userName}</td>
                                                <td>{user?.email}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
            </Modal>
            <Col sm={4}>
                <Stack direction="horizontal" className="text-nowrap justify-content-between border rounded p-2 mb-2 mt-2">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                    <div className="cursor-pointer mt-2" onClick={() => setShow(true)}>
                        <u>{(selectedUser && selectedUser?.userName) || "All Users"}</u>
                    </div>
                    <Button onClick={reset} size="sm" variant="outline-danger" disabled={!selectedUser}>
                        <FontAwesomeIcon icon={faArrowRotateBack} className="me-2" /> Clear
                    </Button>
                </Stack>
            </Col>
        </>
    );
};
