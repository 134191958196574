import React from "react";
import { chunkArray } from "../utils/array";
import { defaultMobileSizePx } from "../constants/constants";

type PaginationConfig = {
    currentPage: number;
    setCurrentPage: Function;
    currentPageData: any[];
    totalPages: number;
};

/**
 *
 * @param data array of data to page
 * @param pageSize size of each page
 * @param pageResetDependency dependency on which to reset page number to 0
 * @param resetCallback function to run in addition to resetting page number to 0 on pageResetDependency change
 * @returns {PaginationConfig}
 */
export const usePagination = (data: any[], pageSize: number, pageResetDependency?: any, resetCallback?: Function): PaginationConfig => {
    const [currentPage, setCurrentPage] = React.useState<number>(0);
    const chunkedArray = chunkArray(data, pageSize);

    React.useEffect(() => {
        if (!!pageResetDependency) {
            // reset page when we change result count on mobile to scrollIntoView the target container
            !!resetCallback &&
                resetCallback(window.innerWidth <= defaultMobileSizePx ? pageResetDependency.scrollToId : "mainContainer", true);
            setCurrentPage(0);
        }
    }, [pageResetDependency, resetCallback]);

    return {
        currentPage,
        setCurrentPage,
        currentPageData: chunkedArray[currentPage] ?? [],
        totalPages: chunkedArray.length,
    };
};
