import React from "react";
import { SortDirectionEnum } from "../types/old_v1/types";

export const useColumnSort = (
    defaultColumn: string,
    defaultDirection?: SortDirectionEnum
): {
    sortColumn: string;
    sortDirection: SortDirectionEnum;
    setSort: (column: string, revertToDefault?: boolean, newSortDirection?: SortDirectionEnum) => void;
} => {
    const [sortColumn, setSortColumn] = React.useState<string>(defaultColumn);
    const [sortDirection, setSortDirection] = React.useState<SortDirectionEnum>(defaultDirection ?? SortDirectionEnum.ASCENDING);

    const setSort = (column: string, revertToDefault?: boolean, newSortDirection?: SortDirectionEnum): void => {
        if (column === sortColumn) {
            const otherDirection =
                sortDirection === SortDirectionEnum.ASCENDING ? SortDirectionEnum.DESCENDING : SortDirectionEnum.ASCENDING;
            setSortDirection(otherDirection);
        } else {
            setSortColumn(column);
            if (newSortDirection) {
                setSortDirection(newSortDirection);
            } else if (revertToDefault && defaultDirection) {
                setSortDirection(defaultDirection);
            } else {
                setSortDirection(SortDirectionEnum.ASCENDING);
            }
        }
    };

    return {
        sortColumn,
        sortDirection,
        setSort,
    };
};
