import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ErrorComponentVariantType } from "../../../types/types";

type OwnProps = {
    message?: string;
    variant?: ErrorComponentVariantType;
};

const ErrorComponent = ({ message = "An error occurred. Our team has been alerted", variant }: OwnProps) => {
    return (
        <>
            {variant === "page" ? (
                <Container fluid={true} className="d-flex flex-column flex-grow-1 justify-content-center">
                    <Row className="align-items-center justify-content-center">
                        <Col md={5} lg={4}>
                            <h1 className="display-3 fw-bold text-center">Error</h1>
                            <p className="mb-5 text-center text-muted">
                                There was an issue processing your request. Our team has been alerted that something went wrong.
                            </p>
                            <div className="text-center">
                                <Button onClick={() => window.location.assign("/")} className="back-to-home">
                                    Back to our home page
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            ) : variant === "large" ? (
                <div className="d-flex flex-column h-100 w-100">
                    <h1 className="display-3 fw-bold text-center">Error</h1>
                    <p className="text-center text-muted">
                        There was an issue processing your request. Our team has been alerted that something went wrong.
                    </p>
                </div>
            ) : (
                <div>
                    <FontAwesomeIcon icon={faTriangleExclamation} color="red" style={{ height: "35px" }} />
                    {message ? <p>{message}</p> : null}
                </div>
            )}
        </>
    );
};

export default ErrorComponent;
