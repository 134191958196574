import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faPenToSquare, faPlay, faXmark } from "@fortawesome/pro-light-svg-icons";
import { useSearchParams } from "react-router-dom";
import { isBefore, isEqual, parseISO, sub } from "date-fns";
import { useNavigate } from "react-router";
import { formatDateToISO } from "../../../utils/date";

type DateRangeProps = {
    close: Function;
    pendoClass?: string;
};

const now = new Date();

export const calculateDates = (dateRange: string, startDate: string, endDate: string, now: Date) => {
    let start, end;
    if (dateRange === "custom") {
        const parsedStart = parseISO(startDate);
        const parsedEnd = parseISO(endDate);
        start = parsedStart.toString() !== "Invalid Date" ? formatDateToISO(parseISO(startDate)) : "";
        end = parsedEnd.toString() !== "Invalid Date" ? formatDateToISO(parseISO(endDate)) : "";
    } else {
        end = dateRange === "custom" ? "" : formatDateToISO(now);
        switch (dateRange) {
            case "3days":
                start = formatDateToISO(sub(now, { days: 3 }));
                break;
            case "7days":
                start = formatDateToISO(sub(now, { days: 7 }));
                break;
            case "4days":
                start = formatDateToISO(sub(now, { days: 4 }));
                break;
            case "8days":
                start = formatDateToISO(sub(now, { days: 8 }));
                break;
            case "30days":
                start = formatDateToISO(sub(now, { days: 30 }));
                break;
            case "90days":
                start = formatDateToISO(sub(now, { days: 90 }));
                break;
            case "1year":
                start = formatDateToISO(sub(now, { days: 365 }));
                break;
            case "3years":
                start = formatDateToISO(sub(now, { days: 1096 }));
                break;
            default:
                start = "";
        }
    }
    return {
        startDate: start,
        endDate: end,
    };
};

const DateRange = ({ close, pendoClass = "" }: DateRangeProps) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const params = React.useRef(new URLSearchParams(searchParams));
    const defaultDateRange = window.location.pathname.includes("map") ? "30days" : "3years";
    const dateRange = React.useRef(params.current.get("dateRange")).current || defaultDateRange;
    const startDate = React.useRef(params.current.get("startDate")).current ?? "";
    const endDate = React.useRef(params.current.get("endDate")).current ?? "";
    const [range, setRange] = React.useState({ startDate: "", endDate: "" });
    const [selected, setSelected] = React.useState(dateRange);

    // Initially populates range
    React.useEffect(() => {
        const calculatedRange = calculateDates(dateRange, startDate, endDate, now);
        setRange(calculatedRange);
    }, [dateRange, startDate, endDate]);

    const handleOptionClick = (option: string) => {
        if (option === "custom") {
            setRange({ startDate: "", endDate: "" });
        } else {
            const calculatedRange = calculateDates(option, range.startDate, range.endDate, now);
            setRange(calculatedRange);
        }
        setSelected(option);
    };

    const checkDateRangeValid = () => {
        return (
            !!range.startDate &&
            !!range.endDate &&
            (isBefore(parseISO(range.startDate), parseISO(range.endDate)) || isEqual(parseISO(range.startDate), parseISO(range.endDate)))
        );
    };

    const apply = () => {
        params.current.delete("page");
        params.current.set("dateRange", selected);
        if (selected === "custom") {
            if (checkDateRangeValid()) {
                params.current.set("startDate", range.startDate);
                params.current.set("endDate", range.endDate);
                navigate(`.?${params.current.toString()}`);
            }
        } else {
            params.current.delete("startDate");
            params.current.delete("endDate");
            navigate(`.?${params.current.toString()}`);
        }
        close("dateRange");
    };

    const handleDateInput = (field: string, value: string) => {
        setSelected("custom");
        setRange({ ...range, ...{ [field]: value } });
    };

    const getOptionButtonVariant = (option: string) => {
        return selected === option ? "primary" : "outline-primary";
    };

    return (
        <div>
            <Row className="gx-1 text-nowrap">
                <Col className="text-center">
                    <Button
                        className={`mb-2 three-days-filter ${pendoClass ? `${pendoClass}-4-days` : ""}`}
                        variant={getOptionButtonVariant("4days")}
                        onClick={() => handleOptionClick("4days")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 4 Days
                    </Button>
                </Col>
                <Col className="text-center">
                    <Button
                        className={`mb-2 seven-days-filter ${pendoClass ? `${pendoClass}-8-days` : ""}`}
                        variant={getOptionButtonVariant("8days")}
                        onClick={() => handleOptionClick("8days")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 8 Days
                    </Button>
                </Col>
                <Col className="text-center">
                    <Button
                        className={`mb-2 thirty-days-filter ${pendoClass ? `${pendoClass}-30-days` : ""}`}
                        variant={getOptionButtonVariant("30days")}
                        onClick={() => handleOptionClick("30days")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 30 days
                    </Button>
                </Col>
                <Col className="text-center">
                    <Button
                        className={`mb-2 ninety-days-filter ${pendoClass ? `${pendoClass}-90-days` : ""}`}
                        variant={getOptionButtonVariant("90days")}
                        onClick={() => handleOptionClick("90days")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 90 days
                    </Button>
                </Col>
                <Col className="text-center">
                    <Button
                        className={`mb-2 one-year-filter ${pendoClass ? `${pendoClass}-1-year` : ""}`}
                        variant={getOptionButtonVariant("1year")}
                        onClick={() => handleOptionClick("1year")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 1 year
                    </Button>
                </Col>
                <Col className="text-center">
                    <Button
                        className={`mb-2 three-years-filter ${pendoClass ? `${pendoClass}-3-years` : ""}`}
                        variant={getOptionButtonVariant("3years")}
                        onClick={() => handleOptionClick("3years")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 3 years
                    </Button>
                </Col>
                <Col className="text-center">
                    <Button
                        className="mb-2 custom-filter"
                        variant={getOptionButtonVariant("custom")}
                        onClick={() => handleOptionClick("custom")}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} /> Custom
                    </Button>
                </Col>
            </Row>
            <Row className="mt-5 ps-3 pe-3 mb-3 justify-content-center">
                <Col sm={6} md={5} className="mb-2">
                    <label className="form-label" htmlFor="startDate">
                        Start Date
                    </label>
                    <input
                        className="form-control"
                        type="date"
                        id="startDate"
                        value={range.startDate}
                        onChange={(e) => handleDateInput("startDate", e.target.value)}
                    />
                </Col>
                <Col sm={6} md={5} className="mb-2">
                    <label className="form-label" htmlFor="endDate">
                        End Date
                    </label>
                    <input
                        className="form-control"
                        type="date"
                        id="endDate"
                        value={range.endDate}
                        onChange={(e) => handleDateInput("endDate", e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="p-3">
                <Col xs={4}>
                    <Button variant="outline-secondary" className="text-nowrap" onClick={() => close("dateRange")}>
                        <FontAwesomeIcon icon={faXmark} /> Cancel
                    </Button>
                </Col>
                <Col>
                    <Button
                        disabled={!checkDateRangeValid()}
                        className={`float-end text-nowrap ${pendoClass ? `${pendoClass}-apply` : ""}`}
                        variant="primary"
                        onClick={apply}
                    >
                        <FontAwesomeIcon icon={faPlay} /> Apply
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

DateRange.displayName = "DateRange";
export default DateRange;
