import React from "react";
import { Button, Card, Stack } from "react-bootstrap";
import Filters from "./Filters";
import { faRotateLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "react-router-dom";
import { useFilters } from "../../hooks/useFilters";
import FmAlert from "../../pages/support/FmAlert";
import { TEventFiltersAggregations } from "../modalFilters/FiltersModal";

type FiltersProps = {
    selectedEntities: string[];
    defaultDateRange: string;
    eventFiltersAggregations?: TEventFiltersAggregations;
};

const FilterCard = ({ selectedEntities, defaultDateRange, eventFiltersAggregations }: FiltersProps) => {
    const [searchParams] = useSearchParams();
    const params = React.useMemo(() => new URLSearchParams(searchParams), [searchParams]);
    const query = params.get("query");
    const { resetFilters } = useFilters({ skipBeatsQuery: true });

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertContent] = React.useState({
        variant: "",
        key: "",
    });

    // hide saved search alert on any param change
    React.useEffect(() => {
        setShowAlert(false);
    }, [params]);

    const reset = () => {
        resetFilters({ dateRange: query === "" ? "4days" : defaultDateRange });
    };

    return (
        <Card className="rounded overflow-hidden">
            <Card.Header className="fm-bg-color px-4 py-3">
                <h3 className="mb-0">Filters</h3>
            </Card.Header>
            <Card.Body className="p-0 pb-3">
                <Stack gap={1} className="flex-grow-0 px-2">
                    {showAlert && (
                        <FmAlert variant={alertContent.variant} alertKey={alertContent.key} onClose={() => setShowAlert(false)} />
                    )}
                    <Stack>
                        {/* Only display reset filters button if at least one filter is displayed */}
                        <>
                            <Button
                                variant="outline-secondary"
                                className="text-nowrap my-4 pendo_reset_filters_sidebar_button"
                                size="sm"
                                onClick={reset}
                            >
                                <FontAwesomeIcon icon={faRotateLeft} /> Reset Filters
                            </Button>
                        </>
                        <Filters
                            eventFiltersAggregations={eventFiltersAggregations}
                            selectedEntities={selectedEntities}
                            defaultDateRange={defaultDateRange}
                        />
                    </Stack>
                </Stack>
            </Card.Body>
        </Card>
    );
};

export default FilterCard;
