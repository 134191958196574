import { TAggregationType } from "../hooks/useSearchFiltersAggregations";

const aggregationDataPresenceCount = (aggregation: TAggregationType[] | undefined, searchKey: string) => {
    let present = null;
    let count = null;
    if (aggregation) {
        present = false;
        count = 0;
        aggregation.forEach((item: TAggregationType) => {
            if (item.key === searchKey) {
                present = true;
                count = item.count;
                return;
            }
        });
    }
    return {
        present,
        count,
    };
};

export default aggregationDataPresenceCount;
