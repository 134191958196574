import React from "react";
import { useGetOfficerListQuery } from "../../api/api";
import SingleSelectFilter from "./SingleSelectFilter";
import { TAggregationType, byNameSort } from "../../hooks/useSearchFiltersAggregations";
import aggregationDataPresenceCount from "../../utils/aggregationDataPresenceCount";

const OfficerFilter = ({ officerAggregation }: { officerAggregation?: Array<TAggregationType> }) => {
    return <ConnectedOfficerFilterDropdown officerAggregation={officerAggregation} />;
};

const ConnectedOfficerFilterDropdown = ({ officerAggregation }: { officerAggregation?: Array<TAggregationType> }) => {
    const { data: officerListResponse } = useGetOfficerListQuery({});

    const options = React.useMemo(
        () =>
            officerListResponse?.data?.results
                .map((officer: { value: string; name: string }) => {
                    const { present, count } = aggregationDataPresenceCount(officerAggregation, officer.name);

                    return {
                        value: officer.value,
                        name: officer.name,
                        present,
                        count,
                    };
                })
                .sort(byNameSort),
        [officerAggregation, officerListResponse]
    );

    return (
        <SingleSelectFilter
            pendoClass="pendo_officer_filter_select"
            options={options || []}
            urlParamName="officer"
            filterLabel="Select Officer"
        />
    );
};

export default OfficerFilter;
