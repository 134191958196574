import React from "react";
import { Button, ListGroup, OverlayTrigger, Popover, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faMessageSms, faMobile } from "@fortawesome/pro-light-svg-icons";
import { useAppSelector } from "../../app/hooks";

type OwnProps = {
    setMfaType: Function;
    disabled: boolean;
};

/**
 * Displays the buttons for the 2 mfa types sms or auth app.  Sets the selection with setMfaType setting either
 * sms or app
 * @param setMfaType
 * @constructor
 */
const MfaOptions = ({ setMfaType, disabled }: OwnProps) => {
    const user = useAppSelector(({ user }) => user);

    const popover = (
        <Popover id="auth-info">
            <Popover.Header>What is an Authenticator App</Popover.Header>
            <Popover.Body>
                <p>
                    An Authenticator app generates 2-step verification codes from an installed app on your mobile device. These apps are
                    free to install and provide more robust security than receiving a code via text message.
                </p>{" "}
                <p>
                    If you select this option, install an authenticator or use an existing authenticator app on your mobile device and use
                    the QR code provided on the next page to configure the app.
                </p>
                Here are a few popular authenticator apps you can find in your phone's app store:
                <ul>
                    <li>Google Authenticator</li>
                    <li>LastPass Authenticator</li>
                    <li>Microsoft Authenticator</li>
                </ul>
            </Popover.Body>
        </Popover>
    );

    return (
        <ListGroup>
            {/* TODO: TECHDEBT: For onboarding the user, only used for GA-BHPD1 */}
            {user?.mfaData?.agency_id !== "ga-bhpd1" && user?.onboardingObject?.agency_id !== "ga-bhpd1" && (
                <ListGroup.Item className="p-4">
                    <div className="d-flex justify-content-center">
                        <h2>Text Message Verification Code</h2>
                    </div>
                    <div className="d-flex justify-content-center">Have a code sent via text message to your mobile device</div>
                    <div className="d-flex justify-content-center mt-3">
                        <Button size="sm" className="text-nowrap" onClick={() => setMfaType("sms")} disabled={disabled}>
                            <FontAwesomeIcon icon={faMessageSms} className="me-2" />
                            Text Message
                        </Button>
                    </div>
                </ListGroup.Item>
            )}

            <ListGroup.Item className="p-4">
                <div className="d-flex justify-content-center">
                    <Stack direction="horizontal" gap={3}>
                        <h2>Authentication App Verification Code</h2>
                        <h2 className="cursor-pointer">
                            <OverlayTrigger trigger={["click", "hover"]} overlay={popover}>
                                <FontAwesomeIcon icon={faCircleQuestion} />
                            </OverlayTrigger>
                        </h2>
                    </Stack>
                </div>
                <div className="d-flex justify-content-center">Get a code from an app installed on your mobile device</div>
                <div className="d-flex justify-content-center mt-3">
                    <Button size="sm" className="text-nowrap" onClick={() => setMfaType("app")} disabled={disabled}>
                        <FontAwesomeIcon icon={faMobile} className="me-2" />
                        Authentication App
                    </Button>
                </div>
            </ListGroup.Item>
        </ListGroup>
    );
};

MfaOptions.displayName = "MfaOptions";
export default MfaOptions;
