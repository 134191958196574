import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartTreeMap, faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import OptionFilter from "./OptionFilter";
import { OptionType } from "../../../types/old_v1/types";
import { useFilters } from "../../../hooks/useFilters";
import { useMultiAgency } from "../../../hooks/useMultiAgency";

const BeatsFilterConnected = ({ close, pendoClass = "" }: { close: Function; pendoClass?: string }) => {
    const { isMultiAgency } = useMultiAgency();
    const { filters, beatsData } = useFilters({ isSingleAgencyMode: true });

    if (beatsData && beatsData?.results?.length > 0) {
        let options: any[] = [];
        let selectedOptions: any[] = [];

        beatsData?.results?.forEach((beatData: any) => {
            options = !options.find((o) => o.agency_desc === beatData.agency_desc && o.beat_name === beatData.beat_name)
                ? options.concat({
                      agency_desc: beatData.agency_desc,
                      agency_id: beatData.agency_id,
                      beat_name: beatData.beat_name,
                      display: beatData.beat_name,
                      value: beatData.beat_name,
                  })
                : options;
        });

        // pre-build selected options since we have more complicated logic for this filter
        if (!!filters && !!filters.beats && beatsData?.success) {
            selectedOptions = beatsData?.results
                .filter((beat: any) => {
                    return filters.beats.find(
                        (filterBeat: any) => filterBeat.agency_desc === beat.agency_desc && filterBeat.beat_name === beat.beat_name
                    );
                })
                .map((beat: any) => {
                    return {
                        agency_desc: beat.agency_desc,
                        agency_id: beat.agency_id,
                        beat_name: beat.beat_name,
                        display: beat.beat_name,
                        value: beat.beat_name,
                    };
                });
        }

        options.sort((a: any, b: any) => {
            if (!isMultiAgency) {
                return a.beat_name < b.beat_name ? -1 : 1;
            } else {
                return a.agency_desc + "-" + a.beat_name < b.agency_desc + "-" + b.beat_name ? -1 : 1;
            }
        });

        return <BeatsFilter close={close} pendoClass={pendoClass} options={options} selectedOptions={selectedOptions} />;
    }

    return null;
};

const BeatsFilter = ({
    close,
    options,
    pendoClass = "",
    selectedOptions,
}: {
    close: Function;
    options: OptionType[];
    pendoClass?: string;
    selectedOptions: any[];
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { updateFilters } = useFilters({ skipBeatsQuery: true });
    const beatsList = selectedOptions ? selectedOptions.map((beat) => beat.value) : [];
    const label =
        selectedOptions && selectedOptions.length
            ? selectedOptions.length > 1
                ? `${selectedOptions.length} Beats`
                : beatsList.toString()
            : "Beats";

    const clear = () => {
        const updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.delete("beats");
        setSearchParams(updatedSearchParams.toString());
        updateFilters("beats", null, updatedSearchParams);
    };

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            placement={"bottom-start"}
            overlay={
                <Popover className="mw-100 hide-arrow">
                    <Popover.Body>
                        <OptionFilter
                            pendoClass={pendoClass}
                            options={options}
                            paramName="beats"
                            close={close}
                            label="Choose Beats"
                            selectAll
                            addParamToUrl={false}
                            prebuiltSelectedOptions={selectedOptions}
                        />
                    </Popover.Body>
                </Popover>
            }
        >
            <Button
                data-testid="beats-filter-button"
                className="cursor-pointer mb-2 ms-2 text-nowrap rounded-pill"
                variant={beatsList && beatsList.length ? "secondary" : "outline-secondary"}
                size="sm"
                onClick={() => close("beats")}
            >
                <FontAwesomeIcon icon={faChartTreeMap} />
                &nbsp;{label}
                {beatsList && beatsList.length > 0 && (
                    <FontAwesomeIcon
                        size="sm"
                        icon={faCircleXmark}
                        className="ms-1"
                        onClick={(e) => {
                            e.stopPropagation();
                            clear();
                        }}
                    />
                )}
            </Button>
        </OverlayTrigger>
    );
};

export default BeatsFilterConnected;
