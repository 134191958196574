import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./pages/support/ProtectedRoute";
import NotFound from "./pages/support/NotFound";
import Auth from "./pages/auth/Auth";
import Login from "./pages/auth/Login";
import MfaContainer from "./pages/auth/MfaContainer";
import SelectMfa from "./pages/auth/SelectMfa";
import EnterMfaCode from "./pages/auth/EnterMfaCode";
import ReconfigureMfa from "./pages/auth/ReconfigureMfa";
import AccountSetup from "./pages/auth/AccountSetup";
import Step1Password from "./pages/auth/Step1Password";
import Step2SelectMfa from "./pages/auth/Step2SelectMfa";
import CustomerSitePicker from "./pages/support/CustomerSitePicker";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ChangePasswordEnterPassword from "./pages/auth/ChangePasswordEnterPassword";
import Navigation from "./components/navigation/Navigation";
import SearchResults from "./pages/search/SearchResults";
import SearchLandingPageConnected from "./pages/searchlanding/SearchLanding";
import MaintenancePage from "./pages/support/MaintenancePage";
import EventPage from "./pages/event/EventPage";
import MapSearch from "./pages/search/MapSearch";
import ConnectedPersonPage from "./pages/person/PersonPage";
import ConnectedAddressPage from "./pages/address/AddressPage";
import usePermissions from "./hooks/usePermissions";
import AgencyMetricsDashboard from "./pages/agencymetrics/AgencyMetricsDashboard";
import ConnectedDispatcherInsightsDashboard from "./pages/dispatcherinsightsdashboard/DispatcherInsightsDashboard";
import Follows from "./pages/follows/Follows";
import InsightsDashboard from "./pages/insightsdashboard/InsightsDashboard";
import KnowledgeBase from "./pages/knowledgebase/KnowledgeBase";
import MetricsDashboard from "./pages/metricsdashboard/MetricsDashboard";
import ConnectedMetricsResults from "./pages/metricsdashboard/MetricsResults";
import ConnectedOfficerInsightsDashboard from "./pages/officerinsightsdashboard/OfficerInsightsDashboard";
import Radar from "./pages/radar/Radar";
import SearchHistory from "./pages/searchhistory/SearchHistory";
import SettingsPage from "./pages/settingspage/SettingsPage";
import SharesConnected from "./pages/shares/Shares";
import TranslateShareUUIDPage from "./pages/translateshareuuid/TranslateShareUUIDPage";
import UserAdmin from "./pages/useradmin/UserAdmin";
import ValidateAgencyUUID from "./pages/agencyuuid/ValidateAgencyUUID";
import RouteUUIDForwarder from "./pages/agencyuuid/RouteUUIDForwarder";
import { useMultiAgency } from "./hooks/useMultiAgency";
import Unauthorized from "./pages/support/Unauthorized";
import ErrorBoundary from "./pages/support/errorboundary/ErrorBoundary";
import SAMLHandler from "./pages/auth/SAMLHandler";

const AppRouterV2 = () => {
    const { isMultiAgency } = useMultiAgency();
    const { hasPermission } = usePermissions();

    const [showNotifications, setShowNotifications] = React.useState<boolean>(false);
    return (
        <ErrorBoundary errorComponentVariant="page">
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<NotFound />} />
                    <Route path="403" element={<Unauthorized />} />
                    <Route path="auth" element={<Auth />}>
                        <Route path="sign-in" element={<Login />} />
                        <Route path="mfa" element={<MfaContainer />} />
                        <Route path="reconfigure-mfa" element={<ReconfigureMfa />}>
                            <Route path="select-mfa" element={<SelectMfa />} />
                            <Route path="enter-code" element={<EnterMfaCode />} />
                        </Route>
                        <Route path="saml" element={<SAMLHandler />} />
                    </Route>
                    <Route path="account-setup" element={<AccountSetup />}>
                        <Route path="step1-password" element={<Step1Password />} />
                        <Route path="step2-select-mfa" element={<Step2SelectMfa />} />
                        <Route path="step3-enter-code" element={<EnterMfaCode />} />
                    </Route>
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="change-password" element={<ChangePasswordEnterPassword />} />
                    <Route path="/" element={<ProtectedRoute />}>
                        <Route index element={<Navigate to="app" />} />
                        <Route path="share/:shareUUID" element={<TranslateShareUUIDPage />} />

                        <Route
                            path="app"
                            element={<Navigation showNotifications={showNotifications} setShowNotifications={setShowNotifications} />}
                        >
                            <Route index element={<SearchLandingPageConnected />} />
                            <Route path="search">
                                {hasPermission("search") && <Route index element={<SearchResults />} />}
                                {hasPermission("map") && <Route path="map" element={<MapSearch />} />}
                                {/***
                                 * non multi agency entity routes for backwards compatiblity
                                 * NOTE: These routes are intentionally designed NOT to forward for multi agency set ups.
                                 * There's no reliable way to determine which agency to forward a user to in that case
                                 */}
                                {!isMultiAgency && (
                                    <>
                                        {["call/:callId", "call/:callId/incident/:incidentId", "incident/:incidentId"].map(
                                            (path: string, index: number) => (
                                                <Route key={index} path={path} element={<RouteUUIDForwarder />} />
                                            )
                                        )}
                                        <Route path="person/:personId" element={<RouteUUIDForwarder />} />
                                        <Route path="address" element={<RouteUUIDForwarder />} />
                                    </>
                                )}
                                {/***
                                 * Have to support this intermediate entity URL pattern for v1 links that may have been
                                 * bookmarked / shared after the introduction of the uuid to the url
                                 */}
                                <Route path=":agencyUUID">
                                    {["call/:callId", "call/:callId/incident/:incidentId", "incident/:incidentId"].map(
                                        (path: string, index: number) => (
                                            <Route key={index} path={path} element={<RouteUUIDForwarder />} />
                                        )
                                    )}
                                    <Route path="person/:personId" element={<RouteUUIDForwarder />} />
                                    <Route path="address" element={<RouteUUIDForwarder />} />
                                </Route>
                            </Route>
                            <Route path=":agencyUUID" element={<ValidateAgencyUUID />}>
                                {["call/:callId", "call/:callId/incident/:incidentId", "incident/:incidentId"].map(
                                    (path: string, index: number) => (
                                        <Route key={index} path={path} element={<EventPage />} />
                                    )
                                )}
                                <Route path="person/:personId" element={<ConnectedPersonPage />} />
                                <Route path="address" element={<ConnectedAddressPage />} />
                            </Route>
                            <Route path="search-history" element={<SearchHistory />} />
                            {hasPermission("follows") && <Route path="follows" element={<Follows />} />}
                            {hasPermission("share") && <Route path="shares" element={<SharesConnected />} />}
                            {hasPermission("radar_main") && <Route path="radar" element={<Radar />} />}
                            {hasPermission("knowledge_base_view") &&
                                ["knowledge-base", "knowledge-base/:filter", "knowledge-base/:filter/:articleId"].map(
                                    (path: string, index: number) => <Route key={index} path={path} element={<KnowledgeBase />} />
                                )}
                            <Route path="settings" element={<SettingsPage />} />
                            {hasPermission("user_admin_view_users") && <Route path="user-admin" element={<UserAdmin />} />}
                            {hasPermission("app_stats_main") && <Route path="agency-metrics/:tab" element={<AgencyMetricsDashboard />} />}
                            {hasPermission("insights_dashboard_trends") && (
                                <Route path="metrics-dashboard" element={<MetricsDashboard />} />
                            )}
                            {hasPermission("insights_dashboard_trends") && (
                                <Route path="metrics-results" element={<ConnectedMetricsResults />} />
                            )}
                            <Route path="switch-agency" element={<CustomerSitePicker />} />
                            <Route path="maintenance" element={<MaintenancePage />} />
                            {hasPermission("insights_dashboard_dashboard") && (
                                <Route path="insights-dashboard" element={<InsightsDashboard />} />
                            )}
                            {hasPermission("insights_dashboard_officer") && (
                                <Route path="officer-insights-dashboard" element={<ConnectedOfficerInsightsDashboard />} />
                            )}
                            {hasPermission("insights_dashboard_dispatcher") && (
                                <Route path="dispatcher-insights-dashboard" element={<ConnectedDispatcherInsightsDashboard />} />
                            )}
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

AppRouterV2.displayName = "AppRouterV2";
export default AppRouterV2;
