import { Card, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faArrowCircleRight, faPhone } from "@fortawesome/pro-light-svg-icons";
import { CALL_ACCENT } from "../../utils/colors";
import { Link } from "react-router-dom";
import Tags from "../../components/tags/Tags";
import { formatDate } from "../../utils/date";
import Highlighter from "../../components/highlighter/Highlighter";
import { stripHtml } from "./IncidentResult";
import { highlightDisplay } from "../../utils/display";
import ResultHeader from "./ResultHeader";
import AgencyDescription from "../../components/AgencyDescription";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import useAgencySettings from "../../hooks/useAgencySettings";

type OwnProps = {
    call: any;
    linkParams: any;
    mapHighlight?: boolean;
};

const CallResult = ({ call, linkParams, mapHighlight = false }: OwnProps) => {
    const { constructEventUrl } = useMultiAgency();
    const { callText } = useAgencySettings();
    const link = constructEventUrl(call?.source?.agency_id, call?.source?.id ? call?.source?.id : call?.id, undefined, linkParams);

    return (
        <Card className="border-0" id={call.source.id}>
            <ResultHeader
                mapHighlight={mapHighlight}
                icon={faPhone}
                iconColor={CALL_ACCENT}
                leftContent={
                    <>
                        <div>
                            {!!call?.archived && <FontAwesomeIcon icon={faArchive} className="fs-2 me-3" color="gray" />}

                            <Link className="pendo_call_result d-inline fw-bold" data-testid="call-result" to={link}>
                                {callText} {call?.source.call_number} {call?.source.call_type ? ` | ${call?.source.call_type}` : ""}
                            </Link>
                        </div>
                        <AgencyDescription className="text-secondary" agencyDescription={call?.source?.agency_desc} />
                    </>
                }
                rightContent={<span>{formatDate(call?.source.occurred_at)}</span>}
            />
            <Card.Body className="overflow-hidden pt-2 fm-left-indent">
                <Tags tags={call?.source?.flags || []} />
                <div data-testid="third-row-container">
                    {call?.source?.highlight?.full_address ? (
                        <Highlighter>{call?.source?.highlight?.full_address[0]}</Highlighter>
                    ) : (
                        <span>{call?.source.full_address}</span>
                    )}
                </div>
                <div className="fw-normal" data-testid="fourth-row-container">
                    {call?.source?.highlight?.call_notes && (
                        <div className="d-flex flex-row justify-content-start">
                            <span className="pe-3">
                                <span className="text-secondary me-2">Notes:</span>
                                <Highlighter>{`${stripHtml(call?.source?.highlight?.call_notes[0])}...`}</Highlighter>
                            </span>
                        </div>
                    )}
                </div>
                <div>
                    {call?.source?.highlight && (
                        <div className="d-inline-flex d-md-none">
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="top"
                                overlay={
                                    <Popover>
                                        <Popover.Header>Match Previews</Popover.Header>
                                        <Popover.Body>
                                            <ul>
                                                {call?.source?.highlight &&
                                                    Object.keys(call?.source?.highlight).map((text: any, index: number) => {
                                                        return (
                                                            <li key={index}>
                                                                <Highlighter className="pe-3">
                                                                    {`${highlightDisplay[text]}: ${stripHtml(
                                                                        call?.source?.highlight[text].join(", ")
                                                                    )}`}
                                                                </Highlighter>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <span className="cursor-pointer text-primary d-inline-flex align-items-center mt-2 fm-preview-font-size">
                                    <FontAwesomeIcon icon={faArrowCircleRight} />
                                    &nbsp;Preview
                                </span>
                            </OverlayTrigger>
                        </div>
                    )}
                    {call?.source?.highlight && (
                        <div className="d-none d-md-flex">
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="top-start"
                                overlay={
                                    <Popover>
                                        <Popover.Header>Match Previews</Popover.Header>
                                        <Popover.Body>
                                            <ul>
                                                {call?.source?.highlight &&
                                                    Object.keys(call?.source?.highlight).map((text: any, index: number) => {
                                                        return (
                                                            <li key={index}>
                                                                <Highlighter className="pe-3">
                                                                    {`${highlightDisplay[text]}: ${stripHtml(
                                                                        call?.source?.highlight[text].join(", ")
                                                                    )}`}
                                                                </Highlighter>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <span className="cursor-pointer text-primary d-inline-flex align-items-center mt-2 fm-preview-font-size">
                                    <FontAwesomeIcon icon={faArrowCircleRight} />
                                    &nbsp;Preview
                                </span>
                            </OverlayTrigger>
                        </div>
                    )}
                </div>
            </Card.Body>
        </Card>
    );
};

export default CallResult;
