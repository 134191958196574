import React from "react";
import Highlighter from "./highlighter/Highlighter";

const PhoneLink = ({ number }: { number: string }) => {
    const numberStripped = number.replace("-", "").replace("(", "").replace(")", "");
    return (
        <a href={`tel:${numberStripped}`}>
            <Highlighter>{number}</Highlighter>
        </a>
    );
};

export default PhoneLink;
