import { Stack } from "react-bootstrap";
import Highlighter from "../highlighter/Highlighter";
import { renderField } from "../../utils/string";

type OwnProps = {
    fieldName: string;
    fieldValue: string;
};
const PanelCell = ({ fieldName, fieldValue }: OwnProps) => {
    const renderValue = renderField(fieldValue);
    return (
        <Stack className="border-top px-3 py-2">
            <span className="fw-bold">{fieldName}</span>
            <span>
                <Highlighter>{renderValue}</Highlighter>
            </span>
        </Stack>
    );
};

export default PanelCell;
