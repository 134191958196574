import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useFilters } from "../../../hooks/useFilters";

type OwnProps = {
    close: Function;
    selectedAgencyUUID: string;
    agencies: any[];
};

const SingleAgencyFilter = ({ close, selectedAgencyUUID, agencies }: OwnProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { updateFilters } = useFilters({ skipBeatsQuery: true });

    const apply = (agency: string) => {
        searchParams.set("agencyUUID", agency);

        // clear selected personId on agency switch so the new agency isn't filtering for someone who doesn't exist to it
        searchParams.delete("personId");
        // clear beats for single agency mode(dashboards and radar) pages to avoid URL being out of sync with display
        updateFilters("beats", null, searchParams);

        setSearchParams(searchParams);
        close("agencyUUID");
    };

    return (
        <div style={{ width: "350px" }}>
            {agencies.map((agency) => (
                <Row className="p-3" key={agency?.fm_uuid}>
                    <Col>
                        <Form.Check
                            className={`ms-4 mt-2`}
                            type="radio"
                            label={agency?.name}
                            value={agency?.fm_uuid}
                            id={agency?.fm_uuid}
                            checked={selectedAgencyUUID === agency?.fm_uuid}
                            onChange={(e) => apply(e.target.value)}
                        />
                    </Col>
                </Row>
            ))}
            <Row className="p-3">
                <Col xs={4}>
                    <Button variant="outline-secondary" onClick={() => close("indexType")}>
                        <FontAwesomeIcon icon={faXmark} /> Cancel
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default SingleAgencyFilter;
