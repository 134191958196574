import { faMinus, faPenClip, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Badge, Button, Card, Collapse, ListGroup, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { parseAsHTML } from "../../utils/string";
import { formatDate } from "../../utils/date";
import { NarrativeSummary, NewNarrative } from "../../types/old_v1/types";
import { getBeautifiedESKeyValuePairs } from "../../utils/getKeyValuePairs";
import NarrativesModal from "./NarrativesModal";
import { faInfoCircle, faUserPolice } from "@fortawesome/pro-light-svg-icons";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { useAppSelector } from "../../app/hooks";
import "./NarrativeListItem.css";
import { getNarrativeStyleNormalizationClass } from "../../utils/helpers";

const NarrativesAlert = ({ isViewingSummary }: { isViewingSummary: boolean }) => {
    return (
        <span>
            <FontAwesomeIcon icon={faInfoCircle} className="me-4" />
            {isViewingSummary ? "Narrative Summary" : "Narrative"}
        </span>
    );
};

type OwnProps = {
    narrative: NewNarrative;
    textSummary?: NarrativeSummary;
    handleNarrativeToggle: Function;
    isOpen: boolean;
};
const NarrativeListItem = ({ narrative, textSummary, handleNarrativeToggle, isOpen }: OwnProps) => {
    const user = useAppSelector(({ user }) => user.userObject);
    const [isViewingSummary, setIsViewingSummary] = React.useState(!!textSummary);
    const [showSeeMore, setShowSeeMore] = React.useState(false);
    const isOfficerNarrative = narrative.narrative_type === "officer";

    const beautifiedKeyValuePairs: any[] = getBeautifiedESKeyValuePairs(narrative, ["security_level", "contains_highlight"]);

    return (
        <>
            <ListGroup.Item
                as={Card}
                className={`${isOfficerNarrative ? "border border-primary rounded" : ""} p-0 mb-3 border-top overflow-hidden`}
            >
                <Card.Header className="w-100 d-flex justify-content-between align-items-center">
                    <div className="fw-bold d-flex flex-wrap align-items-center">
                        <FontAwesomeIcon icon={faPenClip} className="me-3" />
                        <span className="me-2">Narrative</span>
                        <span className="me-2 fw-normal">{narrative.author}</span>
                        {isOfficerNarrative && (
                            <Badge className="me-2" pill bg="primary">
                                <FontAwesomeIcon icon={faUserPolice} />
                                <span className="ms-2">Officer</span>
                            </Badge>
                        )}
                        {narrative.contains_highlight && (
                            <Badge bg="warning">
                                <span className="fs-5 text-dark">Contains Match</span>
                            </Badge>
                        )}
                    </div>
                    <div className="d-flex flex-nowrap align-items-center">
                        {!!narrative.created_at && <span>{formatDate(narrative.created_at)}</span>}
                        <Button
                            className="ms-3 pendo_event_narrative_expand_toggle"
                            variant="outline-secondary"
                            size="sm"
                            onClick={() => handleNarrativeToggle()}
                        >
                            <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
                        </Button>
                    </div>
                </Card.Header>
                <Collapse in={isOpen} appear={isOpen}>
                    <div>
                        <Card.Body style={{ whiteSpace: "pre-line" }}>
                            {!!textSummary?.summary && (
                                <Alert variant={isViewingSummary ? "info" : "secondary"} className="py-2">
                                    <Stack direction="horizontal" className="justify-content-between flex-wrap">
                                        {isViewingSummary ? (
                                            <OverlayTrigger
                                                //  zIndex is the same as modal
                                                overlay={
                                                    <Tooltip style={{ zIndex: "3000" }}>
                                                        <span>
                                                            Please note that text summaries are merely excerpts of full reports and may omit
                                                            certain information or be open to interpretation due to the information that is
                                                            included or not included therein. Text summaries are offered 'as is,' without
                                                            any assurances of completeness, accuracy, usefulness, or timeliness, and without
                                                            any explicit or implied warranties. It is crucial not to make public safety
                                                            decisions, take actions or fail to take actions based solely on text summaries.
                                                            ForceMetrics, along with its partners, agents, or employees, is not liable for
                                                            any consequential, special, or similar damages, even if the possibility of such
                                                            damages has been communicated, for the reliance on text summaries.
                                                        </span>
                                                    </Tooltip>
                                                }
                                                placement="bottom"
                                            >
                                                <span>
                                                    <NarrativesAlert isViewingSummary={isViewingSummary} />
                                                </span>
                                            </OverlayTrigger>
                                        ) : (
                                            <NarrativesAlert isViewingSummary={isViewingSummary} />
                                        )}

                                        <Button
                                            variant="link"
                                            style={{ fontWeight: 500 }}
                                            className="p-0 text-decoration-none pendo_event_narrative_summary"
                                            onClick={() => setIsViewingSummary(!isViewingSummary)}
                                        >
                                            {!isViewingSummary && <FontAwesomeIcon icon={faArrowLeft} className="me-2" />}
                                            {isViewingSummary ? "View Original" : "View Summary"}
                                            {isViewingSummary && <FontAwesomeIcon icon={faArrowRight} className="ms-2" />}
                                        </Button>
                                    </Stack>
                                </Alert>
                            )}
                            {/* TODO: DEBT: DIRTY way of normalizing narratives for now */}
                            {!isViewingSummary ? (
                                <div className={getNarrativeStyleNormalizationClass(user?.agency_shortname)}>
                                    {parseAsHTML(narrative.body || "")}
                                </div>
                            ) : (
                                <div>{parseAsHTML(textSummary?.summary || "")}</div>
                            )}
                            <div className="d-flex mt-3 justify-content-end">
                                {!isViewingSummary && (
                                    <Button
                                        variant="link"
                                        className="p-0 pendo_event_narrative_see_more"
                                        onClick={() => setShowSeeMore(true)}
                                    >
                                        See More
                                    </Button>
                                )}
                            </div>
                        </Card.Body>
                    </div>
                </Collapse>
            </ListGroup.Item>
            <NarrativesModal
                agency_shortname={user?.agency_shortname || ""}
                narrative={beautifiedKeyValuePairs}
                show={showSeeMore}
                close={setShowSeeMore}
                id={narrative.id}
            />
        </>
    );
};

export default NarrativeListItem;
