import React from "react";
import { MapLayerMouseEvent } from "mapbox-gl";
import { SourceConfig } from "../../types/old_v1/types";
import MapComponent from "../../components/map/MapComponent";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { preparePersonMapConfig } from "../../hooks/usePersonSearchLayer";
import { useClusterMapEventHandlers } from "../../hooks/useClusterMapEventHandlers";
import { prepareEventMapConfig } from "../../hooks/useEventSearchLayer";
import { useParams } from "react-router-dom";

type OwnProps = {
    person?: any;
    eventData?: any[];
};

const PersonMap = ({ person, eventData }: OwnProps) => {
    const { personId } = useParams();
    const [disableRebounding, setDisableRebounding] = React.useState(false);
    const [configs, setConfigs] = React.useState<SourceConfig[]>([]);
    const dispatch = useDispatch();
    const currentPopupProperties = useAppSelector(({ map }) => map.popupProperties);
    const { clusterMouseEnter, clusterMouseLeave, clusterClick } = useClusterMapEventHandlers();

    React.useEffect(() => {
        const newConfigs: SourceConfig[] = [];
        if (!!person) {
            newConfigs.push(preparePersonMapConfig([person], null, false));
            if (eventData) {
                newConfigs.push({ ...prepareEventMapConfig(eventData, false), setBounds: !person?.gps_lon && !person?.gps_lat });
            }
        }
        setConfigs(newConfigs);
    }, [person, eventData]);

    // recenter map on person id change
    React.useEffect(() => {
        setDisableRebounding(false);
    }, [personId]);

    const mouseEnterEventHandler = (event: MapLayerMouseEvent) => {
        setDisableRebounding(true);
        clusterMouseEnter(event, dispatch);
    };

    const mouseLeaveEventHandler = () => {
        clusterMouseLeave(currentPopupProperties, dispatch);
    };

    const clickEventHandler = (event: MapLayerMouseEvent) => {
        clusterClick(event);
    };

    return (
        <div className="w-100" style={{ height: "250px" }}>
            <MapComponent
                id="person-map"
                sources={configs}
                fillContainer
                isNotInteractive={false}
                layerClickEventHandler={clickEventHandler}
                layerMouseEnterEventHandler={mouseEnterEventHandler}
                layerMouseOutEventHandler={mouseLeaveEventHandler}
                disableRebounding={disableRebounding}
                navigationControl={false}
                fullscreenControl={true}
            />
        </div>
    );
};

export default PersonMap;
