export const renderOrDash = (value: any, placeholder: string = "--") => (value ? value : placeholder);

export const renderOrDashSpecialCase = (value: any, specialCaseToDash: any, placeholder: string = "--") => {
    if (value === specialCaseToDash) {
        return placeholder;
    } else {
        return renderOrDash(value, placeholder);
    }
};

export const highlightDisplay: any = {
    "involved_people.full_name": "Involved People",
    incident_type: "Incident Type",
    contact_person: "Contact Person",
    caller_name: "Caller Name",
    full_address: "Address",
    call_type: "Call Type",
    call_number: "Call Number",
    contact_phone_number: "Contact Phone Number",
    incident_number: "Incident Number",
    license_plate_number: "License Plate Number",
    call_notes: "Call Notes",
};
