import { formatDate, SHORT_DATE } from "../../../../utils/date";
import { LineChartDataPoint } from "../../../../types/types";

/**
 * Utility that determines the min and max values of lineData used to plot a line chart
 * @param lineData
 */
export const getMinMax = (lineData: Array<LineChartDataPoint>) => {
    const yValues = lineData.map((datum) => datum.y);
    return { min: Math.round(Math.min(...yValues)), max: Math.round(Math.max(...yValues)) };
};

/**
 * Utility that determines the scale of the data points to plot i.e.: 10's, 100's, 10000's, etc.
 * @param lineData
 */
export const calcScale = (lineData: Array<LineChartDataPoint>) => {
    const { max } = getMinMax(lineData);
    const breaks = [1000000, 100000, 10000, 1000, 100, 10];
    let scale = 0;
    for (let point of breaks) {
        if (max && max / point >= 1) {
            scale = point;
            break;
        }
    }
    return scale;
};

/**
 * Utility that calculates the line charts domain (the min and max values to plat on the y axis)
 * @param lineData
 */
export const calcLineChartDomainSettings = (lineData: Array<LineChartDataPoint>): { y: [number, number] } => {
    const { max } = getMinMax(lineData);
    let top = Math.round(max + max * 0.05);
    return { y: [0, top === 0 ? 1 : top] };
};

/**
 * Utility that calculates the y axis values where horizontal reference lines are placed on a line chart.
 * Uses the scale and places a line at each ie: 10, 20, 30... from min to max if the scale is 10
 * @param lineData
 */
export const calcHorizontalRefLines = (lineData: Array<LineChartDataPoint>) => {
    const domain = calcLineChartDomainSettings(lineData);
    let bottom = domain.y[0];
    let top = domain.y[1];
    const mid = Math.round(Math.round(((top - bottom) / 2) * 10) / 10 + bottom);
    return [bottom, mid, top];
};

export const reducePoints = (lineData: LineChartDataPoint[], small: boolean) => {
    if (!lineData || !lineData.length) {
        return [];
    }

    if ((small && lineData.length <= 10) || (!small && lineData.length <= 20)) {
        return lineData;
    }
    const values = small ? 10 : 20;
    const steps = (lineData.length - 1) / (values - 1);
    const points = [];
    for (let i = 0; i < values; i++) {
        points.push(lineData[Math.round(i * steps)]);
    }
    return points;
};

export const formatLineDataDates = (elements: any[], field: string) => {
    if (!elements || !elements.length || !field) {
        return [];
    }
    return elements.map((item) => {
        if (item[field]) {
            let clone = Object.assign({}, item);
            clone[field] = formatDate(clone[field], "", SHORT_DATE);
            return clone;
        } else {
            return item;
        }
    });
};

export const trimLabels = (elements: LineChartDataPoint[], numChars: number = 10) => {
    if (!elements || !elements.length) {
        return [];
    }
    return elements.map((item) => {
        let clone = { ...item };
        if (clone.x.length > 10) {
            clone.x = clone.x.substring(0, numChars) + "...";
            return clone;
        } else {
            return item;
        }
    });
};
