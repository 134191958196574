import { Alert, Button, Col, ListGroup, Row, Stack } from "react-bootstrap";
import FMSpinner from "../../components/Spinner";
import { NewNarrative } from "../../types/old_v1/types";
import SimpleNarrativeListItem from "./SimpleNarrativeListItem";

type OwnProps = {
    narratives: any[];
    isNarrativesFetching: boolean;
    incidentId: string;
    setCurrentTab: () => void;
};

const ConnectedSimpleNarrativesView = ({ narratives, isNarrativesFetching, setCurrentTab }: OwnProps) => {
    let processedNarratives = narratives;

    return <SimpleNarrativesView narratives={processedNarratives} isLoading={isNarrativesFetching} setCurrentTab={setCurrentTab} />;
};

type NarrativesViewProps = {
    narratives: NewNarrative[];
    isLoading: boolean;
    setCurrentTab: () => void;
};
const SimpleNarrativesView = ({ narratives, isLoading, setCurrentTab }: NarrativesViewProps) => {
    return (
        <Stack className="mt-3">
            {!isLoading ? (
                <>
                    {narratives.length > 0 ? (
                        <Row>
                            <Col xs={12}>
                                <ListGroup>
                                    {narratives.slice(0, 5).map((narrative) => (
                                        <SimpleNarrativeListItem narrative={narrative} key={narrative.id} />
                                    ))}
                                    {narratives.length > 5 && (
                                        <div>
                                            <Button
                                                className="pendo_event_simple_narratives_view_more"
                                                variant="link"
                                                onClick={setCurrentTab}
                                            >
                                                View More
                                            </Button>
                                        </div>
                                    )}
                                </ListGroup>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col xs={12}>
                                <Alert variant="warning">No narratives to view.</Alert>
                            </Col>
                        </Row>
                    )}
                </>
            ) : (
                <div className="d-flex justify-content-center">
                    <FMSpinner />
                </div>
            )}
        </Stack>
    );
};

export default ConnectedSimpleNarrativesView;
