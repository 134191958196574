import { faPersonDress, faPerson, faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ListGroup, Card, Container, Row, Col, Button, Modal, Table, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PERSON_ACCENT } from "../../app/colors";
import SortableHeaderColumn from "../../components/SortableHeader";
import Tags from "../../components/tags/Tags";
import useAgencySettings from "../../hooks/useAgencySettings";
import { useManageSearchParams } from "../../hooks/useManageSearchParams";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import { formatBirthdayAndDOB, formatDate } from "../../utils/date";
import { DESCENDING, sortSortableHeader } from "../../utils/old_v1/sort";
import { formatName } from "../../utils/person";
import { useColumnSort } from "../../hooks/old_v1/useColumnSort";
import { differenceInCalendarYears, parseISO } from "date-fns";
import LinkWrapper from "../../components/LinkWrapper";

const DEFAULT_SORT = "occurred_at";
const DEFAULT_SORT_ORDER = DESCENDING;

/* Unknown for NaN or non-available birthdate, occurred_at should always be present */
const displayNameAndAgeDifference = (name: string, occurred_at: string, birthdate: string) =>
    `${name} (${differenceInCalendarYears(parseISO(occurred_at || ""), parseISO(birthdate || "")) || "Unknown"})`;

type OwnProps = {
    associatedPerson: any;
    associatedIncidents: any[];
    showRole: boolean;
    agencyId: string | undefined;
    currentPerson: any;
    rolesDictionary: any;
};

const PersonCard = ({ associatedPerson, associatedIncidents, showRole, agencyId, currentPerson, rolesDictionary }: OwnProps) => {
    const { constructEventUrl, constructPersonUrl } = useMultiAgency();
    const { processSearchParams } = useManageSearchParams();
    const { incidentText } = useAgencySettings();
    const { sortColumn, sortDirection, setSort } = useColumnSort(DEFAULT_SORT, DEFAULT_SORT_ORDER);
    const [showModal, setShowModal] = React.useState(false);

    const sortedEvents = [...associatedIncidents].sort(
        sortSortableHeader(sortColumn, ["incident_number", "occurred_at"].includes(sortColumn), sortDirection)
    );

    const formattedName = formatName(associatedPerson);

    const constructedPersonURL = constructPersonUrl(agencyId, associatedPerson, processSearchParams([], ["currentTab"]));

    return (
        <ListGroup.Item as={Card} className="p-0 mb-3 border-0">
            <Card.Header className="w-100 d-flex align-items-center justify-content-between border rounded">
                <div className="d-flex flex-wrap align-items-center">
                    <FontAwesomeIcon
                        icon={
                            associatedPerson.gender === "F" || associatedPerson.gender?.toLowerCase() === "female"
                                ? faPersonDress
                                : faPerson
                        }
                        className="fs-2 me-4"
                        color={PERSON_ACCENT}
                    />
                    {!!formattedName ? (
                        <LinkWrapper
                            className="fw-bold me-3 pendo_person_name_link_button"
                            isAnchor={constructedPersonURL.isNextFELink}
                            to={constructedPersonURL.url}
                        >
                            {formattedName}
                        </LinkWrapper>
                    ) : (
                        <span className="fw-bold me-3">Unknown</span>
                    )}
                    {!!associatedPerson.birthdate && <span>DOB: {formatBirthdayAndDOB(associatedPerson.birthdate)}</span>}
                </div>
                <div>
                    {showRole && (
                        <div>
                            Role: <span className="fw-bold">{associatedPerson.role}</span>
                        </div>
                    )}
                    <span>Person ID: {associatedPerson.person_id || "Unknown"}</span>
                    <br />
                </div>
            </Card.Header>
            <Card.Body className="overflow-hidden pt-2 fm-left-indent">
                <Container className="m-0">
                    <Row>
                        <Col xs={12} md={6} className="fw-normal text-nowrap px-0">
                            <Row>
                                <Col className="text-wrap">
                                    <span>{associatedPerson.full_address}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={5} sm={4} md={6}>
                                    <span>
                                        <span className="text-secondary me-2">Gender:</span>
                                        <span className="">{associatedPerson.gender || "Unknown"}</span>
                                    </span>
                                </Col>
                                <Col xs={5} sm={4} md={6}>
                                    <span>
                                        <span className="text-secondary me-2">Race:</span>
                                        <span>{associatedPerson.race || "Unknown"}</span>
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col className="d-none d-md-flex justify-content-end">
                                    <span>
                                        Last {incidentText}: {formatDate(associatedPerson.latest_incident_occurred_at)}
                                    </span>
                                </Col>
                                <Col className="d-flex d-md-none p-0">
                                    <span>
                                        Last {incidentText}: {formatDate(associatedPerson.latest_incident_occurred_at)}
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-none d-md-flex justify-content-end">
                                    <Button
                                        className="p-0 pendo_associated_event_modal_button"
                                        variant="link"
                                        onClick={() => setShowModal(true)}
                                    >
                                        {associatedIncidents.length} Associated {associatedIncidents.length > 1 ? "Events" : "Event"}
                                    </Button>
                                </Col>
                                <Col className="d-flex d-md-none p-0">
                                    <Button
                                        className="p-0 pendo_associated_event_modal_button"
                                        variant="link"
                                        onClick={() => setShowModal(true)}
                                    >
                                        {associatedIncidents.length} Associated {associatedIncidents.length > 1 ? "Events" : "Event"}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
            <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
                <Modal.Header closeButton>
                    <div className="d-flex w-100">
                        <Modal.Title className="me-3">
                            <FontAwesomeIcon icon={faUser} className="fs-2 me-2" />
                            <span>{formattedName || "Unknown"}</span>
                        </Modal.Title>
                        <div className="flex-grow-1 d-flex align-items-center ">
                            {!!associatedPerson.birthdate && <span>DOB: {formatBirthdayAndDOB(associatedPerson.birthdate)}</span>}
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="overflow-auto">
                    <h3>Associated Events ({associatedIncidents?.length})</h3>
                    <Table>
                        <thead>
                            <tr>
                                <SortableHeaderColumn
                                    columnName="Event Name"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey={"incident_number"}
                                    defaultDirection={DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="Date"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey={"occurred_at"}
                                    defaultDirection={DESCENDING}
                                />
                                <th>Age & Role At {incidentText}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedEvents?.map((event: any) => {
                                return (
                                    <tr key={event.incident_number}>
                                        <td>
                                            <Link
                                                className="ms-3 d-inline-block pendo_associated_events_modal_incident_link_button"
                                                to={constructEventUrl(
                                                    event?.agency_id,
                                                    event?.call_number?.length ? event.call_number[0] : undefined,
                                                    event?.incident_number,
                                                    processSearchParams(["query"])
                                                )}
                                            >
                                                {incidentText} {event?.incident_number}{" "}
                                                {event?.incident_type ? ` | ${event?.incident_type}` : ""}
                                            </Link>
                                            <div className="ms-3">
                                                <Tags tags={event?.flags || []} />
                                            </div>
                                        </td>
                                        <td>{formatDate(event.occurred_at)}</td>
                                        <td>
                                            <Stack>
                                                <div>
                                                    <span className="me-2">
                                                        {displayNameAndAgeDifference(
                                                            associatedPerson?.full_name,
                                                            event?.occurred_at,
                                                            associatedPerson?.birthdate
                                                        )}
                                                    </span>
                                                    <small className="fw-bold">{event?.roles?.join(", ") || "--"}</small>
                                                </div>
                                                <div>
                                                    <span className="me-2">
                                                        {displayNameAndAgeDifference(
                                                            currentPerson?.full_name,
                                                            event?.occurred_at,
                                                            currentPerson?.birthdate
                                                        )}
                                                    </span>
                                                    <small className="fw-bold">
                                                        {rolesDictionary?.[event.incident_number]?.join(", ") || "--"}
                                                    </small>
                                                </div>
                                            </Stack>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </ListGroup.Item>
    );
};

export default PersonCard;
