import React from "react";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useChangePasswordMutation } from "../../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faSpinner } from "@fortawesome/pro-light-svg-icons";

const ChangePasswordSchema = Yup.object().shape({
    passwordOld: Yup.string().required("Required"),
    passwordNew: Yup.string().min(8, "Must be at least 8 characters").required("Required"),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref("passwordNew"), null], "New passwords must match!")
        .required("Required"),
});

type FormProps = {
    passwordOld: string;
    passwordNew: string;
    passwordConfirm: string;
};

const ChangePasswordForm = () => {
    const [showAlert, setShowAlert] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [changePassword, results] = useChangePasswordMutation();
    const isError = results.isError || !results?.data?.success;

    const initialValues = {
        passwordOld: "",
        passwordNew: "",
        passwordConfirm: "",
    };

    const submitFn = (values: FormProps) => {
        setShowAlert(false);
        changePassword({
            password_old: values.passwordOld,
            password_new: values.passwordNew,
            password_confirm: values.passwordConfirm,
        });
    };

    React.useEffect(() => {
        if (results?.isLoading) {
            setLoading(true);
        } else if (loading) {
            setLoading(false);
        }

        if (!results?.isUninitialized && !results?.isLoading) {
            if (isError) {
                setShowAlert(true);
                setMessage(results?.data?.error);
            } else {
                setShowAlert(true);
                setMessage("Password updated successfully!");
            }
        }
    }, [results, loading, isError]);

    return (
        <div className="row">
            <div className="col-md-6 col-12">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values) => submitFn(values)}
                    validationSchema={ChangePasswordSchema}
                >
                    {({ errors, touched, handleChange, handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="mb-2">
                                <Form.Group controlId="formPasswordOld">
                                    <Form.Label className="w-100">Old Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="passwordOld"
                                        onChange={handleChange}
                                        autoFocus={true}
                                        required={true}
                                    />
                                    {errors?.passwordOld && touched?.passwordOld ? (
                                        <div className="text-danger">{errors?.passwordOld}</div>
                                    ) : null}
                                </Form.Group>
                            </div>

                            <div className="mb-2">
                                <Form.Group controlId="formPasswordNew">
                                    <Form.Label className="w-100">New Password</Form.Label>
                                    <Form.Control type="password" name="passwordNew" onChange={handleChange} required={true} />
                                    {errors?.passwordNew && touched?.passwordNew ? (
                                        <div className="text-danger">{errors?.passwordNew}</div>
                                    ) : null}
                                </Form.Group>
                            </div>

                            <div className="mb-5">
                                <Form.Group controlId="formConfirmNewPassword">
                                    <Form.Label className="w-100">Confirm New Password</Form.Label>
                                    <Form.Control type="password" name="passwordConfirm" onChange={handleChange} required={true} />
                                    {errors?.passwordConfirm && touched?.passwordConfirm ? (
                                        <div className="text-danger">{errors?.passwordConfirm}</div>
                                    ) : null}
                                </Form.Group>
                            </div>
                            {showAlert && (
                                <Alert variant={isError ? "danger" : "success"}>
                                    <span>{message}</span>
                                </Alert>
                            )}
                            <Button type="submit" className="text-nowrap" disabled={loading}>
                                <FontAwesomeIcon icon={loading ? faSpinner : faSave} />
                                <span className="ms-2">Save</span>
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="col-md-6 col-12 pt-2">
                Your password should be:
                <ul>
                    <li>Minimum of 8 characters</li>
                    <li>Not too similar to your email address</li>
                    <li>Not too common</li>
                    <li>Not entirely numeric</li>
                </ul>
            </div>
        </div>
    );
};

export default ChangePasswordForm;
