import React from "react";
import { Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useGetNarrativesQuery, useGetTextSummarizationsQuery } from "../../../api/api";
import CardHeader from "../../../components/cardheader/CardHeader";
import { getArrayOfDataFromElasticSearchReponse } from "../../../utils/elastic";
import { processNarrativeData, processTextSummarizationData } from "../../../utils/old_v1/narratives";
import BaseNarratives from "./BaseNarratives";
import NarrativesModal from "./NarrativesModal";
import { getBeautifiedESKeyValuePairs } from "../../../utils/getKeyValuePairs";
import usePermissions from "../../../hooks/usePermissions";
import { useMultiAgency } from "../../../hooks/useMultiAgency";

type ConnectedProps = {
    incidentId?: string;
    modalOption?: boolean;
    agencyId: string;
};
const NarrativesConnected = ({ incidentId, modalOption = true, agencyId }: ConnectedProps) => {
    const { getAgencyUUID } = useMultiAgency();
    const { hasPermission } = usePermissions();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const query = params.get("query");
    const queryType = !!params.get("queryType") ? params.get("queryType") : "cross_fields";
    const { data: textSummarizationData } = useGetTextSummarizationsQuery(
        { id: incidentId, queryType, query, agencyUUID: getAgencyUUID(agencyId) },
        { skip: !incidentId || !hasPermission("view_narrative_summaries") }
    );
    const { data } = useGetNarrativesQuery(
        { id: incidentId, query, queryType, agencyUUID: getAgencyUUID(agencyId) },
        { skip: !incidentId }
    );
    const narratives = data && processNarrativeData(data);
    const textSummarizations =
        (textSummarizationData && processTextSummarizationData(getArrayOfDataFromElasticSearchReponse(textSummarizationData))) || {};

    return <Narratives narratives={narratives} textSummarizations={textSummarizations} modalOption={modalOption} />;
};

const Narratives = ({
    narratives,
    textSummarizations,
    modalOption,
}: {
    narratives: [];
    textSummarizations: any;
    modalOption?: boolean;
}) => {
    const [showMore, setShowMore] = React.useState(false);

    let beautifiedKeyValuePairs: any[] = [];
    if (narratives && narratives.length > 0) {
        narratives.forEach((note: any) => {
            beautifiedKeyValuePairs.push(getBeautifiedESKeyValuePairs(note, ["security_level"]));
        });
    }

    return modalOption ? (
        <React.Fragment>
            <Card className="h-100">
                <CardHeader title="Narratives" showMoreFn={() => setShowMore(true)} pendoClassName="incident-narratives" />
                <Card.Body className="overflow-auto">
                    <BaseNarratives narratives={narratives} textSummarizations={textSummarizations} />
                </Card.Body>
            </Card>
            <NarrativesModal
                narratives={beautifiedKeyValuePairs}
                textSummarizations={textSummarizations}
                show={showMore}
                close={setShowMore}
            />
        </React.Fragment>
    ) : (
        <BaseNarratives narratives={narratives} textSummarizations={textSummarizations} />
    );
};

export default NarrativesConnected;
