import React from "react";

/**
 * Used to resize map container height as the window height changes.  Requires the ref to the div being sized and returns
 * the value of the width as the window size is changed.
 * @param divRef - React ref to div map container that will be sized
 * @param minHeight - minHeight is the padding that will be subtracted off of the divRef height.  Ensures the element
 * in the divRef has minHeight padding applied so the element doesn't stretch to the full divRef height
 */

export const useTrackDimension = (divRef: React.MutableRefObject<HTMLDivElement | null>, minHeight: number = 0, minWidth: number = 0) => {
    const [height, setHeight] = React.useState(0);
    const [width, setWidth] = React.useState(0);

    const handleResize = React.useCallback(() => {
        if (divRef.current) {
            const boundingRect = divRef.current.getBoundingClientRect();
            const { height, width } = boundingRect;
            let roundedHeight = Math.round(height);
            if (roundedHeight && roundedHeight > minHeight) {
                setHeight(roundedHeight - minHeight);
            }
            let roundedWidth = Math.round(width);
            if (roundedWidth && roundedWidth > minWidth) {
                setWidth(roundedWidth - minWidth);
            }
        }
    }, [divRef, minHeight, minWidth]);

    React.useEffect(() => {
        const div = divRef.current as Element;

        if (div) {
            const observer = new ResizeObserver((entries) => {
                if (!entries.length) return;

                if (entries[0].contentRect) {
                    setHeight(entries[0].contentRect.height);
                    setWidth(entries[0].contentRect.width);
                }
            });

            observer.observe(div);

            return () => observer.unobserve(div);
        }
    }, [divRef]);

    return { height, width, handleResize };
};
