import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Modal, Nav, OverlayTrigger, Popover, Row, Stack, Table } from "react-bootstrap";
import {
    useDeactivateUserMutation,
    useEditUserMutation,
    useGetUsersListQuery,
    useMfaResetUserMutation,
    useReactivateUserMutation,
    useResendUserInvitationMutation,
} from "../../api/api";
import SortableHeaderColumn from "../../components/SortableHeader";
import { ASCENDING, DESCENDING, sortSortableHeader } from "../../utils/old_v1/sort";
import { useColumnSort } from "../../hooks/old_v1/useColumnSort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faEnvelope, faPlus, faRocket, faShield, faUsers } from "@fortawesome/pro-solid-svg-icons";
import AddUserModal from "../../components/useradmin/AddUserModal";
import { formatDate } from "../../utils/date";
import "./UserAdmin.css";
import { underToWords } from "../../utils/string";
import usePermissions from "../../hooks/usePermissions";
import LoadingModal from "../../components/LoadingModal";
import { usePagination } from "../../hooks/usePagination";
import { FMPaginationNonLinks } from "../../components/pagination/Pagination";
import EditUserModal from "../../components/useradmin/EditUserModal";

const DEFAULT_SORT = "email";
const DEFAULT_SORT_ORDER = ASCENDING;

enum TabEnum {
    ACTIVE = "Active",
    DEACTIVATED = "Deactivated",
    ALL = "All",
}

const UserAdminConnected = () => {
    const { data, isLoading } = useGetUsersListQuery({});
    const users = (!isLoading && data && data.results && data.results.map((u: any) => ({ ...u, ...u.user }))) || [];

    return <UserAdmin users={users} isLoading={isLoading} />;
};

const UserAdmin = ({ users, isLoading }: { users: any[]; isLoading: boolean }) => {
    const { hasPermission } = usePermissions();
    const canUserAdminEditUsers = hasPermission("user_admin_edit_users");
    const canUserAdminViewSecurityLevel = hasPermission("user_admin_view_security_level");
    const canUserAdminEditSecurityLevel = hasPermission("user_admin_edit_security_level");

    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [deactivateUserLocal, setDeactivateUserLocal] = useState<any>(null);
    const [reactivateUserLocal, setReactivateUserLocal] = useState<any>(null);
    const [mfaResetUserLocal, setMfaResetUserLocal] = useState<any>(null);
    const [resendUserLocal, setResendUserLocal] = useState<any>(null);
    const [showAlert, setShowAlert] = useState(false); // alert messages
    const [alertMessage, setAlertMessage] = useState<any>(null);
    const [currentTab, setCurrentTab] = useState<TabEnum>(TabEnum.ACTIVE);
    const [selectedUser, setSelectedUser] = React.useState<any>(null);
    const [editedUser, setEditedUser] = React.useState<any>({});
    const [filterString, setFilterString] = React.useState<string>("");
    const listRef = React.useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 10000);
        }
    }, [showAlert]);

    const toggleAddUserModal = () => {
        setShowAddUserModal(!showAddUserModal);
    };

    const setAndShowAlert = (title: string, message: string, variant: string) => {
        setAlertMessage({ title, message, variant });
        setShowAlert(true);
    };

    const [reactivateUser] = useReactivateUserMutation();
    const [deactivateUser] = useDeactivateUserMutation();
    const [mfaResetUser] = useMfaResetUserMutation();
    const [resendUser] = useResendUserInvitationMutation();
    const [editUserApi] = useEditUserMutation();

    const resendUserClick = (user: any) => {
        resendUser({ user_id: user.id }).then((response: any) => {
            if (response?.data?.success === true) {
                setAndShowAlert("Resend Invite", `Successfully sent new invite to ${user.email}`, "success");
            } else {
                setAndShowAlert("Resend Invite", "An error has occurred, please try again.", "danger");
            }
            setResendUserLocal(null);
        });
    };

    const deactivateUserClick = (user: any) => {
        deactivateUser({ user_id: user.id }).then((response: any) => {
            if (response?.data?.success === true) {
                setAndShowAlert("Deactivate User", `Successfully deactivated ${user?.full_name}.`, "success");
            } else {
                setAndShowAlert("Deactivate User", "An error has occurred, please try again.", "danger");
            }
            setDeactivateUserLocal(null);
        });
    };

    const mfaResetUserClick = (user: any) => {
        mfaResetUser({ user_id: user.id }).then((response: any) => {
            if (response?.data?.success === true) {
                setAndShowAlert("MFA Reset", `Successfully reset MFA for ${user?.full_name}.`, "success");
            } else {
                setAndShowAlert("MFA Reset", "An error has occurred, please try again.", "danger");
            }
            setMfaResetUserLocal(null);
        });
    };

    const reactivateUserClick = (user: any) => {
        reactivateUser({ user_id: user.id }).then((response: any) => {
            if (response?.data?.success === true) {
                setAndShowAlert("Reactivate User", `Successfully reactivated ${user?.full_name}`, "success");
            } else {
                setAndShowAlert("Reactivate Reset", "An error has occurred, please try again.", "warning");
            }
            setReactivateUserLocal(null);
        });
    };

    const editUser = () => {
        editUserApi({ ...selectedUser, ...editedUser }).then((response: any) => {
            //update user object in list to display new edited version
            const index = users.findIndex((u) => u.id === selectedUser.id);
            users[index].user = response.data.results;
            users[index] = { ...users[index], ...response.data.results };
            setSelectedUser(null);
            setAndShowAlert("User Updated", `Successfully updated ${selectedUser.email}`, "success");
        });
    };

    const { sortColumn, sortDirection, setSort } = useColumnSort(DEFAULT_SORT, DEFAULT_SORT_ORDER);

    //filter based on user search bar
    let filteredUsers =
        filterString !== undefined
            ? users.filter(
                  (user) =>
                      user?.last_name?.toLowerCase().startsWith(filterString) ||
                      user?.first_name?.toLowerCase().startsWith(filterString) ||
                      user?.email?.toLowerCase().startsWith(filterString)
              )
            : users;

    //filter based on active/deactivated radio buttons
    if (currentTab === TabEnum.ACTIVE) {
        filteredUsers = filteredUsers.filter((user) => user.active);
    } else if (currentTab === TabEnum.DEACTIVATED) {
        filteredUsers = filteredUsers.filter((user) => !user.active);
    }

    const sortedUsers = (Array.isArray(users) ? [...filteredUsers] : []).sort(
        sortSortableHeader(sortColumn, sortColumn !== "phone", sortDirection)
    );

    const scrollToTop = React.useCallback(() => listRef?.current?.scrollIntoView(), [listRef]);

    const { currentPage, setCurrentPage, currentPageData, totalPages } = usePagination(sortedUsers, 20, filterString, scrollToTop);

    const editUserCellClick = (user: any) => {
        if (canUserAdminEditUsers) {
            setSelectedUser(user);
        }
    };

    return (
        <div>
            <Container className="my-4" fluid ref={listRef}>
                <LoadingModal show={isLoading} />
                {showAlert ? (
                    <Alert className="mt-3" variant={alertMessage.variant} onClose={() => setShowAlert(false)} dismissible>
                        <Alert.Heading>{alertMessage.title}</Alert.Heading>
                        <p>{alertMessage.message}</p>
                    </Alert>
                ) : null}
                {showAddUserModal && (
                    <AddUserModal
                        showModal={showAddUserModal}
                        toggleModal={toggleAddUserModal}
                        setAndShowAlert={setAndShowAlert}
                        showSecurityLevel={canUserAdminEditSecurityLevel}
                    />
                )}
                {!!selectedUser && (
                    <EditUserModal
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        setEditedUser={setEditedUser}
                        editedUser={editedUser}
                        canUserAdminEditSecurityLevel={canUserAdminEditSecurityLevel}
                        editUser={editUser}
                    />
                )}
                <Row className="mt-2">
                    <Col className="d-flex align-items-center">
                        <h2 className="mt-2 text-nowrap">
                            <FontAwesomeIcon icon={faUsers} />
                            &nbsp;User Admin Portal
                        </h2>
                    </Col>
                    <Col xs={12} md={8} lg={6} xl={4}>
                        <Stack direction="horizontal" gap={5} className="d-flex justify-content-end me-2">
                            <Form.Control as="input" placeholder="Search" onChange={(e) => setFilterString(e.target.value)} autoFocus />
                            {hasPermission("user_admin_create_users") && (
                                <Button variant="outline-primary" onClick={toggleAddUserModal} className="text-nowrap">
                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                    Add User
                                </Button>
                            )}
                        </Stack>
                    </Col>
                </Row>
                <Nav className="mt-3 d-none d-md-flex border-bottom-0" variant="tabs" activeKey={currentTab}>
                    <Nav.Item key={TabEnum.ACTIVE}>
                        <Nav.Link onClick={() => setCurrentTab(TabEnum.ACTIVE)} eventKey={TabEnum.ACTIVE}>
                            <span>{TabEnum.ACTIVE}</span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item key={TabEnum.DEACTIVATED}>
                        <Nav.Link onClick={() => setCurrentTab(TabEnum.DEACTIVATED)} eventKey={TabEnum.DEACTIVATED}>
                            <span>{TabEnum.DEACTIVATED}</span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item key={TabEnum.ALL}>
                        <Nav.Link onClick={() => setCurrentTab(TabEnum.ALL)} eventKey={TabEnum.ALL}>
                            <span>{TabEnum.ALL}</span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <div className="fm-bg-color border-0 border-md rounded-end rounded-bottom p-4 d-flex flex-column flex-grow-1 overflow-auto">
                    <Table className="user-admin-table" responsive striped>
                        <thead className="sticky-header overflow-hidden">
                            <tr>
                                <SortableHeaderColumn
                                    columnName="Name"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="full_name"
                                    defaultDirection={ASCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="Email"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey={DEFAULT_SORT}
                                    defaultDirection={DEFAULT_SORT_ORDER}
                                />
                                <SortableHeaderColumn
                                    columnName="Phone Number"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="phone"
                                    defaultDirection={ASCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="Created"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="date_joined"
                                    defaultDirection={DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="Last Login"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="last_login"
                                    defaultDirection={DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="MFA Type"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="mfa_type"
                                    defaultDirection={DESCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="Position"
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="position"
                                    defaultDirection={ASCENDING}
                                />
                                {canUserAdminViewSecurityLevel && (
                                    <SortableHeaderColumn
                                        columnName="Agency Security Level"
                                        sortFn={setSort}
                                        activeSortField={sortColumn}
                                        activeSortDirection={sortDirection}
                                        sortKey="security_level"
                                        defaultDirection={DESCENDING}
                                    />
                                )}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPageData.map((user) => (
                                <tr key={user.id} className={`${canUserAdminEditUsers ? "cursor-pointer" : ""}`}>
                                    <td onClick={() => editUserCellClick(user)}>{user.full_name}</td>
                                    <td onClick={() => editUserCellClick(user)}>{user.email}</td>
                                    <td onClick={() => editUserCellClick(user)}>{user.phone}</td>
                                    <td onClick={() => editUserCellClick(user)}>{formatDate(user.date_joined)}</td>
                                    <td onClick={() => editUserCellClick(user)}>{formatDate(user.last_login)}</td>
                                    <td onClick={() => editUserCellClick(user)}>
                                        {user.mfa_type && user.mfa_type.toUpperCase() === "SMS"
                                            ? "Text"
                                            : user.mfa_type &&
                                              (user.mfa_type.toUpperCase() === "OTP" || user.mfa_type.toUpperCase() === "APP")
                                            ? "App"
                                            : "--"}
                                    </td>
                                    <td onClick={() => editUserCellClick(user)}>{underToWords(user.position)}&nbsp;</td>
                                    {canUserAdminViewSecurityLevel && (
                                        <td onClick={() => editUserCellClick(user)}>{user?.security_level}&nbsp;</td>
                                    )}
                                    <td className="text-nowrap">
                                        {hasPermission("user_admin_resend_invite") && (
                                            <OverlayTrigger
                                                rootClose
                                                trigger={["hover", "focus"]}
                                                placement={"bottom-start"}
                                                defaultShow={false}
                                                overlay={
                                                    <Popover>
                                                        <Popover.Body>Resend User Invite</Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <Button
                                                    type="button"
                                                    onClick={() => setResendUserLocal(user)}
                                                    disabled={!!user.last_login || !user.active}
                                                    variant="outline-primary"
                                                    className="border-0"
                                                >
                                                    <FontAwesomeIcon icon={faEnvelope} size="xl" />
                                                </Button>
                                            </OverlayTrigger>
                                        )}
                                        {hasPermission("user_admin_reset_mfa") && (
                                            <OverlayTrigger
                                                rootClose
                                                trigger={["hover", "focus"]}
                                                placement={"bottom-start"}
                                                defaultShow={false}
                                                overlay={
                                                    <Popover>
                                                        <Popover.Body>Reset MFA</Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <Button
                                                    type="button"
                                                    onClick={() => setMfaResetUserLocal(user)}
                                                    disabled={!user.mfa_type || user.mfa_type.toLowerCase() === "none"}
                                                    variant={
                                                        !user.mfa_type || user.mfa_type.toLowerCase() === "none"
                                                            ? "outline-secondary"
                                                            : "outline-success"
                                                    }
                                                    className="border-0"
                                                >
                                                    <FontAwesomeIcon icon={faShield} size="xl" />
                                                </Button>
                                            </OverlayTrigger>
                                        )}

                                        {user.active
                                            ? hasPermission("user_admin_deactivate_users") && (
                                                  <OverlayTrigger
                                                      rootClose
                                                      trigger={["hover", "focus"]}
                                                      placement={"bottom-start"}
                                                      defaultShow={false}
                                                      overlay={
                                                          <Popover>
                                                              <Popover.Body>Deactivate User</Popover.Body>
                                                          </Popover>
                                                      }
                                                  >
                                                      <Button
                                                          type="button"
                                                          onClick={() => setDeactivateUserLocal(user)}
                                                          variant="outline-danger"
                                                          className="border-0"
                                                      >
                                                          <FontAwesomeIcon icon={faBan} size="xl" />
                                                      </Button>
                                                  </OverlayTrigger>
                                              )
                                            : hasPermission("user_admin_reactivate_users") && (
                                                  <OverlayTrigger
                                                      rootClose
                                                      trigger={["hover", "focus"]}
                                                      placement={"bottom-start"}
                                                      defaultShow={false}
                                                      overlay={
                                                          <Popover>
                                                              <Popover.Body>Reactivate User</Popover.Body>
                                                          </Popover>
                                                      }
                                                  >
                                                      <Button
                                                          type="button"
                                                          onClick={() => setReactivateUserLocal(user)}
                                                          variant="outline-primary"
                                                          className="border-0"
                                                      >
                                                          <FontAwesomeIcon icon={faRocket} size="xl" />
                                                      </Button>
                                                  </OverlayTrigger>
                                              )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="d-flex justify-content-end mt-2">
                    <FMPaginationNonLinks
                        page={currentPage + 1}
                        setPage={(page: number) => {
                            setCurrentPage(page - 1);
                            scrollToTop();
                        }}
                        totalPages={totalPages}
                    />
                </div>

                <Modal show={!!deactivateUserLocal} onHide={() => setDeactivateUserLocal(null)} data-testid="deactivateModal">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2">Deactivate User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you would like to deactivate {deactivateUserLocal?.full_name}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setDeactivateUserLocal(null)}>
                            Cancel
                        </Button>
                        <Button variant="success" onClick={() => deactivateUserClick(deactivateUserLocal)}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={!!mfaResetUserLocal} onHide={() => setMfaResetUserLocal(null)} data-testid="mfaModal">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2">Reset MFA</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you would like to reset the mfa for {mfaResetUserLocal?.full_name}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setMfaResetUserLocal(null)}>
                            Cancel
                        </Button>
                        <Button variant="success" onClick={() => mfaResetUserClick(mfaResetUserLocal)}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={!!resendUserLocal} onHide={() => setResendUserLocal(null)} data-testid="resendModal">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2">Resend User Invite</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you would like to resend the onboarding email for {resendUserLocal?.full_name}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setResendUserLocal(null)}>
                            Cancel
                        </Button>
                        <Button variant="success" onClick={() => resendUserClick(resendUserLocal)}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={!!reactivateUserLocal} onHide={() => setReactivateUserLocal(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title as="h2">Reactivate</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you would like to reactivate {reactivateUserLocal?.full_name}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setReactivateUserLocal(null)}>
                            Cancel
                        </Button>
                        <Button variant="success" onClick={() => reactivateUserClick(reactivateUserLocal)}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
};

export default UserAdminConnected;
