import { MetricsData } from "../MetricsDashboard";
import LineGraph from "./LineGraph";
import Grid from "./Grid";
import { Col, Row } from "react-bootstrap";

type GridAndGraphOwnProps = {
    cat: string;
    subcat: string;
    data: MetricsData;
    apiUrl: string;
};

/**
 * Creates the GridAndGraph presentation component
 * @param cat
 * @param subcat
 * @param data
 * @param apiUrl
 * @constructor
 */

const GridAndGraph = ({ cat, subcat, data, apiUrl }: GridAndGraphOwnProps) => {
    return (
        <Row className="border-bottom border-2 mt-5">
            <Col xs={12} xl={4}>
                <Grid data={data} subcat={subcat} cat={cat} apiUrl={apiUrl} />
            </Col>
            <Col xs={12} xl={8} className="mb-5">
                <LineGraph
                    data={data}
                    name={subcat}
                    partialUrl={`../metrics-results?query=${encodeURIComponent(subcat)}&category=${encodeURIComponent(
                        cat
                    )}&subcat=${encodeURIComponent(subcat)}&page=${apiUrl}`}
                />
            </Col>
        </Row>
    );
};

export default GridAndGraph;
