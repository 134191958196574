import { TEventFiltersAggregations } from "../components/modalFilters/FiltersModal";

export type TAggregationType = { key: string; count: number };

export const useSearchFiltersAggregations = (eventFiltersAggregations: TEventFiltersAggregations) => {
    const labelAggregation = eventFiltersAggregations?.labels.map((item: { key: string; doc_count: number }) => ({
        key: item.key,
        count: item.doc_count,
    }));
    const dispatcherAggregation = eventFiltersAggregations?.dispatchers.map((item: { key: string; doc_count: number }) => ({
        key: item.key,
        count: item.doc_count,
    }));
    const officerAggregation = eventFiltersAggregations?.officers.map((item: { key: string; doc_count: number }) => ({
        key: item.key,
        count: item.doc_count,
    }));
    const callTypeAggregation = eventFiltersAggregations?.call_types.map((item: { key: string; doc_count: number }) => ({
        key: item.key,
        count: item.doc_count,
    }));
    const incidentTypeAggregation = eventFiltersAggregations?.incident_types.map((item: { key: string; doc_count: number }) => ({
        key: item.key,
        count: item.doc_count,
    }));
    const offensesAggregation = eventFiltersAggregations?.offenses.map((item: { key: string; doc_count: number }) => ({
        key: item.key,
        count: item.doc_count,
    }));
    const callSourcesAggregation = eventFiltersAggregations?.call_sources.map((item: { key: string; doc_count: number }) => ({
        key: item.key,
        count: item.doc_count,
    }));
    const callPriorityAggregation = eventFiltersAggregations?.call_priorities.map((item: { key: string; doc_count: number }) => ({
        key: item.key,
        count: item.doc_count,
    }));

    return {
        labelAggregation,
        dispatcherAggregation,
        officerAggregation,
        callTypeAggregation,
        incidentTypeAggregation,
        offensesAggregation,
        callSourcesAggregation,
        callPriorityAggregation,
    };
};

export const byNameSort = (a: { name: string }, b: { name: string }) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);

export default useSearchFiltersAggregations;
