import React from "react";
import { MapLayerMouseEvent } from "mapbox-gl";
import { SourceConfig } from "../../types/old_v1/types";
import MapComponent from "../../components/map/MapComponent";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { prepareAddressMapConfig } from "../../hooks/useAddressSearchLayer";
import { useClusterMapEventHandlers } from "../../hooks/useClusterMapEventHandlers";
import { prepareEventMapConfig } from "../../hooks/useEventSearchLayer";

type OwnProps = {
    address?: any;
    events?: any[];
};
const AddressMap = ({ address, events }: OwnProps) => {
    const [disableRebounding, setDisableRebounding] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const currentPopupProperties = useAppSelector(({ map }) => map.popupProperties);
    const { clusterMouseEnter, clusterMouseLeave, clusterClick } = useClusterMapEventHandlers();
    const [configs, setConfigs] = React.useState<SourceConfig[]>([]);

    React.useEffect(() => {
        const newConfigs: SourceConfig[] = [];
        if (!!address) {
            newConfigs.push(prepareAddressMapConfig([{ source: address }], false));

            if (events) {
                newConfigs.push(prepareEventMapConfig(events, false));
            }
        }

        setConfigs(newConfigs);
    }, [address, events]);

    const mouseEnterEventHandler = (event: MapLayerMouseEvent) => {
        setDisableRebounding(true);
        clusterMouseEnter(event, dispatch);
    };

    const mouseLeaveEventHandler = () => {
        clusterMouseLeave(currentPopupProperties, dispatch);
    };

    const clickEventHandler = (event: MapLayerMouseEvent) => {
        clusterClick(event);
    };

    return (
        <div className="w-100" style={{ height: "200px" }}>
            <MapComponent
                id="address-map"
                sources={configs}
                fillContainer
                isNotInteractive={false}
                layerMouseEnterEventHandler={mouseEnterEventHandler}
                layerMouseOutEventHandler={mouseLeaveEventHandler}
                layerClickEventHandler={clickEventHandler}
                disableRebounding={disableRebounding}
            />
        </div>
    );
};

export default AddressMap;
