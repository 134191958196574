import { MapStyles, SourceConfig } from "../types/old_v1/types";
import { LayerProps } from "react-map-gl";
import { scaleLinear } from "d3-scale";
import React from "react";
import { useDarkMode } from "./useDarkMode";
import { DARK_BLUE, LIGHTER_BLUE } from "../app/colors";
import { useMultiAgency } from "./useMultiAgency";
import { useFilters } from "./useFilters";
import { useWalmart } from "./useWalmart";

// Build the mapbox structured data to show the beat outlines and heatmap sections for map search and insight dashboards
const buildData = (beatsCountResponse: any, beatsResponse: any) => {
    const beatCounts = beatsCountResponse && beatsCountResponse?.data?.aggregations;

    const beatCountValues = beatCounts && Object.values(beatCounts).map((bucket: any) => bucket.doc_count);
    const minScale = beatCountValues && Math.min(...beatCountValues);
    const maxScale = beatCountValues && Math.max(...beatCountValues);
    const colorScale = beatCountValues && scaleLinear<string, number>().domain([minScale, maxScale]).range([LIGHTER_BLUE, DARK_BLUE]);

    let concatFeatures: any[] = [];
    beatsResponse?.results.forEach((beat: any) => {
        const beatCount = beatCounts?.[beat.agency_desc + "-" + beat.beat_name]?.doc_count;
        const fillColor = colorScale && colorScale(beatCount);
        beat.geometry.forEach((beatGeo: any) => {
            concatFeatures.push({
                geometry: beatGeo,
                properties: {
                    agency_desc: beat.agency_desc,
                    agency_id: beat.agency_id,
                    beat_name: beat.beat_name,
                    multi_agency_beat_name: beat.agency_desc + "-" + beat.beat_name,
                    beatCount,
                    fillColor,
                },
            });
        });
    });

    return {
        type: "FeatureCollection",
        features: concatFeatures,
    };
};

// Build the mapbox layer filters that correspond with the mapbox structured beat data built above used by mapbox to determine
// which beats are selected and need to be displayed
const buildLayers = (beats: any, filterBeats: any, isDarkMode: boolean, isWalmart: boolean) => {
    const beatLayers: LayerProps[] = [];
    beats.features.forEach((feature: any, index: number) => {
        const filter = ["all", ["==", ["get", "multi_agency_beat_name"], feature.properties.multi_agency_beat_name]];
        if (filterBeats.length) {
            filter.push(["in", ["get", "multi_agency_beat_name"], ["literal", filterBeats]]);
        }

        beatLayers.push(
            {
                id: `beat${feature.properties.multi_agency_beat_name}-${index}`,
                type: "fill",
                filter,
                paint: {
                    "fill-color": !!feature.properties.fillColor ? feature.properties.fillColor : LIGHTER_BLUE,
                    "fill-outline-color": "#0b110b",
                    "fill-opacity": 0.4,
                },
            },
            {
                id: `beat-layer-count-${index}`,
                type: "symbol",
                source: `beat${feature.properties.multi_agency_beat_name}-${index}`,
                filter,
                layout: {
                    "text-field": isWalmart ? "{multi_agency_beat_name} ({beatCount})" : "{beatCount}",
                    "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                    "text-size": 12,
                    "text-anchor": "center",
                },
                paint: {
                    "text-color": isDarkMode ? "white" : "black",
                },
            }
        );
    });

    return beatLayers;
};

export const useBeatLayer = (style: MapStyles) => {
    const { isMultiAgency } = useMultiAgency();
    const { beatsData, beatsIsFetching, selectedBeats } = useFilters({ includeGeometry: true });
    const [beatSourceConfig, setBeatSourceConfig] = React.useState<SourceConfig | undefined>();

    React.useEffect(() => {
        if (beatsData && beatsData.results) {
            const beatLayers: LayerProps[] = [];
            const isDarkMap = style === "dark" || style === "satellite";

            let concatFeatures: any[] = [];
            beatsData?.results.forEach((beat: any) => {
                beat.geometry.forEach((beatGeo: any) => {
                    concatFeatures.push({
                        geometry: beatGeo,
                        properties: {
                            agency_desc: beat.agency_desc,
                            agency_id: beat.agency_id,
                            beat_name: beat.beat_name,
                            es_docs: beat.es_docs,
                            multi_agency_beat_name: beat.agency_desc + "-" + beat.beat_name,
                        },
                    });
                });
            });

            const beats = {
                type: "FeatureCollection",
                features: concatFeatures,
            };

            if (selectedBeats) {
                beatsData.results.forEach((beat: any, index: any) => {
                    const beatNameList = selectedBeats?.map((beat: any) => beat.agency_desc + "-" + beat.beat_name);
                    if (beatNameList.length) {
                        beatLayers.push(
                            {
                                id: `beat${beat.multi_agency_beat_name}-${index}`,
                                type: "line",
                                filter: ["match", ["get", "multi_agency_beat_name"], beatNameList, true, false],
                                paint: {
                                    "line-color": "#FF5733",
                                    "line-width": 3,
                                },
                            },
                            {
                                id: `beat-layer-count-${beat.multi_agency_beat_name}-${index}`,
                                type: "symbol",
                                source: `beat${beat.multi_agency_beat_name}`,
                                filter: ["match", ["get", "multi_agency_beat_name"], beatNameList, true, false],
                                layout: {
                                    "text-field": isMultiAgency ? "{multi_agency_beat_name}" : "{beat_name}",
                                    "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                                    "text-size": 12,
                                    "text-anchor": "bottom-left",
                                },
                                paint: {
                                    "text-color": isDarkMap ? "white" : "black",
                                },
                            }
                        );
                    }
                });
            }

            setBeatSourceConfig({
                id: "beat-config",
                data: beats,
                setBounds: false,
                cluster: false,
                layers: beatLayers,
            });
        }
    }, [beatsData, style, isMultiAgency, selectedBeats]);

    return {
        beatSourceConfig,
        beatsFetching: beatsIsFetching,
    };
};

export const useBeatHeatLayer = (beatsCountResponse: any) => {
    const { isWalmart } = useWalmart();
    const [beatSourceConfig, setBeatSourceConfig] = React.useState<SourceConfig | undefined>();
    const { isDarkMode } = useDarkMode();
    const { selectedBeats, beatsData, beatsIsFetching } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });
    const filterBeats = React.useMemo(() => selectedBeats?.map((beat: any) => beat.agency_desc + "-" + beat.beat_name), [selectedBeats]);

    React.useEffect(() => {
        if (beatsData && beatsData?.results) {
            const beats = buildData(beatsCountResponse, beatsData);
            const beatLayers: LayerProps[] = buildLayers(beats, filterBeats, isDarkMode, isWalmart);

            setBeatSourceConfig({
                id: "beat-config",
                data: beats,
                setBounds: true,
                cluster: false,
                layers: beatLayers,
            });
        }
    }, [beatsData, beatsCountResponse, isDarkMode, isWalmart, filterBeats]);

    return {
        beatSourceConfig,
        beatsFetching: beatsIsFetching,
    };
};

export const useOfficerBeatHeatLayer = (beatsCountResponse: any) => {
    const { isWalmart } = useWalmart();
    const [beatSourceConfig, setBeatSourceConfig] = React.useState<SourceConfig | undefined>();
    const { beatsData, beatsIsFetching, selectedBeats } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });
    const filterBeats = React.useMemo(() => selectedBeats?.map((beat: any) => beat.agency_desc + "-" + beat.beat_name), [selectedBeats]);

    const { isDarkMode } = useDarkMode();

    React.useEffect(() => {
        if (beatsData && beatsData.results) {
            const beats = buildData(beatsCountResponse, beatsData);
            const beatLayers: LayerProps[] = buildLayers(beats, filterBeats, isDarkMode, isWalmart);

            setBeatSourceConfig({
                id: "beat-config",
                data: beats,
                setBounds: true,
                cluster: false,
                layers: beatLayers,
            });
        }
    }, [beatsData, isDarkMode, filterBeats, beatsCountResponse, isWalmart]);

    return {
        beatSourceConfig,
        beatsFetching: beatsIsFetching,
    };
};

export const useDispatcherBeatHeatLayer = (beatsCountResponse: any) => {
    const { isWalmart } = useWalmart();
    const [beatSourceConfig, setBeatSourceConfig] = React.useState<SourceConfig | undefined>();
    const { isDarkMode } = useDarkMode();
    const { selectedBeats, beatsData, beatsIsFetching } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });
    const filterBeats = React.useMemo(() => selectedBeats?.map((beat: any) => beat.agency_desc + "-" + beat.beat_name), [selectedBeats]);

    React.useEffect(() => {
        if (beatsData && beatsData.results) {
            const beats = buildData(beatsCountResponse, beatsData);
            const beatLayers: LayerProps[] = buildLayers(beats, filterBeats, isDarkMode, isWalmart);

            setBeatSourceConfig({
                id: "beat-config",
                data: beats,
                setBounds: true,
                cluster: false,
                layers: beatLayers,
            });
        }
    }, [beatsCountResponse, beatsData, filterBeats, isDarkMode, isWalmart]);

    return {
        beatSourceConfig,
        beatsFetching: beatsIsFetching,
    };
};
