import React from "react";
import { Alert, Button, Stack } from "react-bootstrap";
import { renderOrDash } from "../../../utils/display";
import { formatDate } from "../../../utils/date";
import Highlighter from "../../../components/highlighter/Highlighter";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { useMultiAgency } from "../../../hooks/useMultiAgency";
import { useManageSearchParams } from "../../../hooks/useManageSearchParams";
import useAgencySettings from "../../../hooks/useAgencySettings";
import AddressSearchModal from "../../../components/entity/AddressSearchModal";

const BaseIncidentDetails = ({ incident, query, newTabLink }: { incident?: any; query?: any; newTabLink?: boolean }) => {
    const [prefillSearch, setPrefillSearch] = React.useState("");
    const { processSearchParams } = useManageSearchParams();
    const { callTextPlural, incidentText } = useAgencySettings();
    const { constructAddressUrl, constructEventUrl } = useMultiAgency();
    const incidentAddressLink = !!incident && constructAddressUrl(incident?.source?.agency_id, incident, query);
    const statutes = incident?.offenses && incident.offenses.map((offense: any) => offense.statute).filter((statute: any) => !!statute);
    const offenses = incident?.offenses && incident.offenses.map((offense: any) => offense.charge_abbr).filter((offense: any) => !!offense);
    const incidentNumber = incident?.highlights?.incident_number
        ? incident.highlights.incident_number[0].text
        : incident?.incident_number || "";
    const incidentType = incident?.highlights?.incident_type ? incident.highlights.incident_type[0].text : incident?.incident_type || "";

    const address = !!incident?.highlights?.full_address ? incident.highlights.full_address[0].text : incident?.full_address || "";

    const licensePlateHighlights = incident?.highlights?.license_plate_number || [];
    let licensePlates = !!incident?.license_plate_number?.length
        ? incident.license_plate_number.map((license: string) => {
              return {
                  display: license,
                  highlight: licensePlateHighlights.find((high: any) => {
                      return high?.text?.includes(license);
                  }),
              };
          })
        : [];

    const canViewMapboxSearchboxModal = window?.__RUNTIME_CONFIG__?.USE_LOCATION_SEARCH_MODAL === "true";

    return (
        <>
            {!!incident && incidentType && incidentNumber ? (
                <Stack gap={3} direction="vertical">
                    <div>
                        <strong>ID</strong>
                        <div>
                            <Highlighter>{incidentNumber}</Highlighter>
                        </div>
                    </div>
                    <div>
                        <strong>Occurred At</strong>
                        <div>{formatDate(incident?.occurred_at)}</div>
                    </div>
                    <div>
                        <strong>Type</strong>
                        <div>
                            <Highlighter>{renderOrDash(incidentType)}</Highlighter>
                        </div>
                    </div>
                    <div>
                        <strong>Responsible Officer</strong>
                        <div>{renderOrDash(incident?.responsible_officer)}</div>
                    </div>
                    <div>
                        <strong>Contact</strong>
                        <div>{renderOrDash(incident?.contact_person)}</div>
                    </div>
                    <div>
                        <strong>Address</strong>
                        {!address && <div data-testid="empty-incident-address">--</div>}
                        {canViewMapboxSearchboxModal ? (
                            <>
                                <Button
                                    onClick={() => setPrefillSearch(address)}
                                    variant="link"
                                    className="p-0 d-flex flex-row justify-content-start text-start"
                                >
                                    <Highlighter>{address}</Highlighter>
                                </Button>
                                <AddressSearchModal
                                    show={prefillSearch !== ""}
                                    hideModalFn={() => setPrefillSearch("")}
                                    prefillSearch={prefillSearch}
                                />
                            </>
                        ) : (
                            address && (
                                <Link to={incidentAddressLink ? incidentAddressLink : ""} target={newTabLink ? "_blank" : ""}>
                                    <div className="d-flex flex-row justify-content-start">
                                        {newTabLink ? (
                                            <FontAwesomeIcon icon={faUpRightFromSquare} className="text-primary me-2 mt-1" />
                                        ) : null}
                                        <div>
                                            <div>
                                                <Highlighter>{renderOrDash(address)}</Highlighter>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        )}
                    </div>
                    <div>
                        <strong>State Statutes</strong>
                        <div>{statutes && statutes.length > 0 ? statutes.join(", ") : "--"}</div>
                    </div>
                    <div>
                        <strong>Offense Codes</strong>
                        <div>{offenses && offenses.length > 0 ? offenses.join(", ") : "--"}</div>
                    </div>
                    {incident?.call_number && Array.isArray(incident.call_number) && incident.call_number.length > 0 && (
                        <div>
                            <strong>Associated {callTextPlural}</strong>
                            {incident.call_number.map((callNumber: string) => (
                                <Link
                                    key={callNumber}
                                    to={constructEventUrl(
                                        incident?.source?.agency_id,
                                        callNumber,
                                        incident?.incident_number,
                                        processSearchParams(["query"], [])
                                    )}
                                    target={newTabLink ? "_blank" : ""}
                                >
                                    <div className="d-flex flex-row justify-content-start">
                                        {newTabLink ? (
                                            <FontAwesomeIcon icon={faUpRightFromSquare} className="text-primary me-2 mt-1" />
                                        ) : null}
                                        {callNumber}
                                    </div>
                                </Link>
                            ))}
                        </div>
                    )}
                    {incident?.call_number && !Array.isArray(incident.call_number) && (
                        <div>
                            <strong>Associated {callTextPlural}</strong>
                            <Link
                                key={incident.call_number}
                                to={constructEventUrl(
                                    incident?.source?.agency_id,
                                    incident.call_number,
                                    incident?.incident_number,
                                    processSearchParams(["query"], [])
                                )}
                                target={newTabLink ? "_blank" : ""}
                            >
                                <div className="d-flex flex-row justify-content-start">
                                    {newTabLink ? <FontAwesomeIcon icon={faUpRightFromSquare} className="text-primary me-2 mt-1" /> : null}
                                    {incident.call_number}
                                </div>
                            </Link>
                        </div>
                    )}
                    {incident?.case_status && (
                        <div>
                            <strong>Case Status</strong>
                            <div>{incident.case_status}</div>
                        </div>
                    )}
                    <div>
                        <strong>Associated License Plates</strong>
                        {licensePlates.length > 0 ? (
                            licensePlates.map((license: any) => (
                                <Link
                                    key={license.display}
                                    to={`/app/search?query=${license.display}&dateRange=3years`}
                                    target={newTabLink ? "_blank" : ""}
                                >
                                    <div className="d-flex flex-row justify-content-start">
                                        {newTabLink ? (
                                            <FontAwesomeIcon icon={faUpRightFromSquare} className="text-primary me-2 mt-1" />
                                        ) : null}
                                        <Highlighter>
                                            {license.highlight && license.highlight.text ? license.highlight.text : license.display}
                                        </Highlighter>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <div>--</div>
                        )}
                    </div>
                </Stack>
            ) : (
                <Alert className="text-center" variant="secondary">
                    No {incidentText}
                </Alert>
            )}
        </>
    );
};

export default BaseIncidentDetails;
