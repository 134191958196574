import React from "react";
import { Col, ListGroup, Row, Stack } from "react-bootstrap";
import IncidentCard from "../../components/entity/IncidentCard";
import { sortSortableHeader } from "../../utils/sort";
import { SortConfig, SortDirectionEnum } from "../../types/old_v1/types";
import useSortState from "../../hooks/useSortState";
import SortButton from "../../components/SortButton";
import { FMPaginationNonLinks } from "../../components/pagination/Pagination";
import { usePagination } from "../../hooks/usePagination";
import tagDetails from "../../utils/TagDetails";
import NoResults from "../../components/NoResults";
import FMSpinner from "../../components/Spinner";

const defaultSortConfig: SortConfig = {
    occurred_at: {
        label: "Occurred At",
        defaultSortDirection: SortDirectionEnum.DESCENDING,
        sortString: true,
    },
};

type TEventViewProps = {
    incidentsData: any;
    sortConfig?: SortConfig;
    isLoading?: boolean;
    defaultSortField?: string;
    defaultSortDirection?: SortDirectionEnum;
    scrollToTop?: any;
    pageReset: any;
};

const AssociatedIncidentsView = ({
    incidentsData = [],
    sortConfig = defaultSortConfig,
    defaultSortField = "occurred_at",
    defaultSortDirection = SortDirectionEnum.DESCENDING,
    isLoading = false,
    scrollToTop,
    pageReset,
}: TEventViewProps) => {
    const [incidentFilterString, setIncidentFilterString] = React.useState("");
    const { sortField, setSortField, sortDirection, setSortDirection } = useSortState(defaultSortField, defaultSortDirection);
    const tags = tagDetails();

    const sortedIncidents = [...incidentsData]
        .filter((incident) => {
            if (!incidentFilterString) {
                return true;
            }

            const lowercaseFilter = incidentFilterString.toLowerCase();
            return (
                `${incident.incident_number}`.toLowerCase().includes(lowercaseFilter) ||
                `${incident.call_type}`.toLowerCase().includes(lowercaseFilter) ||
                `${incident.full_address}`.toLowerCase().includes(lowercaseFilter) ||
                `${incident.incident_type}`.toLowerCase().includes(lowercaseFilter) ||
                incident?.roles?.some((role: string) => role.toLowerCase().includes(lowercaseFilter)) ||
                incident?.flags?.some((flag: any) => {
                    const tag = tags.find((tagDatum) => flag === tagDatum.code);
                    return flag.includes(lowercaseFilter) || tag?.name.toLowerCase().includes(lowercaseFilter);
                })
            );
        })
        .sort(sortSortableHeader(sortField, true, sortDirection));

    const reset = React.useMemo(
        () => ({ ...pageReset, sortField, sortDirection, incidentFilterString }),
        [pageReset, sortDirection, sortField, incidentFilterString]
    );

    const { currentPage, currentPageData, totalPages, setCurrentPage } = usePagination(sortedIncidents || [], 10, reset, scrollToTop);

    const filterEventList = (incident: any) => {
        setIncidentFilterString(incident.target.value);
    };

    return (
        <Stack gap={3} direction="vertical">
            <Stack direction="horizontal" gap={5}>
                <SortButton
                    sortConfig={sortConfig}
                    sortField={sortField}
                    setSortField={setSortField}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                />
                <input
                    name="associated-event-filter-input"
                    className="form-control pendo_text_filter_input"
                    onChange={filterEventList}
                    placeholder="Filter by Event Number, Type, Address, Labels, or Role"
                />
            </Stack>
            {isLoading && <FMSpinner centered={true} />}
            {!isLoading && sortedIncidents.length === 0 && <NoResults />}
            <ListGroup className="gap-5 mt-3">
                {currentPageData?.map((incident: any) => (
                    <IncidentCard key={incident?.id} incident={incident} showRoles={true} showInvolvedPeople={true} />
                ))}
            </ListGroup>
            <Row className="mt-5 mb-2 d-flex justify-content-center">
                <Col xs={12} md={9}>
                    <FMPaginationNonLinks
                        page={currentPage + 1}
                        setPage={(page: number) => setCurrentPage(page - 1)}
                        totalPages={totalPages}
                        scrollToId={pageReset?.scrollToId}
                    />
                </Col>
            </Row>
        </Stack>
    );
};

export default AssociatedIncidentsView;
