import { ReactNode } from "react";
import { Link } from "react-router-dom";

const LinkWrapper = ({
    isAnchor,
    to,
    className,
    children,
    target,
    testId,
    onClick = () => {},
}: {
    isAnchor: boolean;
    to: string;
    className?: string;
    children: ReactNode;
    target?: string;
    testId?: string;
    onClick?: () => void;
}) => {
    return isAnchor ? (
        <a data-isnextlink="true" href={to} onClick={onClick} className={className} target={target} data-testid={testId}>
            {children}
        </a>
    ) : (
        <Link data-isnextlink="false" to={to} onClick={onClick} className={className} target={target} data-testid={testId}>
            {children}
        </Link>
    );
};

export default LinkWrapper;
