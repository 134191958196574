import React from "react";
import FMSpinner from "../../../components/Spinner";

const InsightsSpinner = () => {
    return (
        <div className="text-center mt-5">
            <FMSpinner />
        </div>
    );
};

export default InsightsSpinner;
