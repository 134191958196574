import React from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useBeatHeatLayer } from "../../hooks/useBeatLayer";
import { SourceConfig } from "../../types/types";
import MapComponent from "../../components/map/MapComponent";
import InsightsSpinner from "./insightsspinner/InsightsSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt } from "@fortawesome/pro-regular-svg-icons";
import ModalHeader from "./modalheader/ModalHeader";
import { MapLayerMouseEvent } from "mapbox-gl";
import { useClusterMapEventHandlers } from "../../hooks/useClusterMapEventHandlers";

const InsightsMap = ({ beatsCountResponse }: { beatsCountResponse: any }) => {
    const [showModal, setShowModal] = React.useState(false);
    const [dataSources, setDataSources] = React.useState<SourceConfig[]>([]);
    const { insightBeatClick } = useClusterMapEventHandlers();

    const { beatSourceConfig, beatsFetching } = useBeatHeatLayer(beatsCountResponse);

    React.useEffect(() => {
        const configs: SourceConfig[] = [];

        if (beatSourceConfig && !beatsFetching) {
            configs.push(beatSourceConfig);
            setDataSources(configs);
        }
    }, [beatSourceConfig, beatsFetching]);

    const clickEventHandler = (event: MapLayerMouseEvent) => {
        insightBeatClick(event, dataSources);
    };

    return (
        <React.Fragment>
            <Card>
                <Card.Header>
                    <Card.Title className="m-0" as="h3">
                        Map
                        <Button
                            onClick={() => setShowModal(true)}
                            variant="outline-primary"
                            className="cursor pointer float-end pt-0 pb-0"
                            size="sm"
                        >
                            <span className="me-2">See More</span>
                            <FontAwesomeIcon icon={faExpandAlt} size="xs" />
                        </Button>
                    </Card.Title>
                </Card.Header>
                <Card.Body style={{ height: "28em" }} className="p-0">
                    {beatsFetching ? (
                        <InsightsSpinner />
                    ) : (
                        <MapComponent
                            id="insights-map"
                            sources={dataSources}
                            fillContainer
                            clickableBeats={true}
                            layerClickEventHandler={clickEventHandler}
                            padding={10}
                        />
                    )}
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={() => setShowModal(false)} size="xl" scrollable={true}>
                <ModalHeader title="Map" />
                <Modal.Body className="p-0">
                    {beatsFetching ? (
                        <InsightsSpinner />
                    ) : (
                        <MapComponent id="insights-map-modal" sources={dataSources} fillContainer padding={20} />
                    )}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default InsightsMap;
