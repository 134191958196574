import { Card, Col, Container, Nav, Row, Stack, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import MapComponent from "../../components/map/MapComponent";
import React from "react";
import SearchInput from "../../components/SearchInput";
import { EntitiesType, SortConfig, SortDirectionEnum, SourceConfig } from "../../types/old_v1/types";
import { Entities } from "../../constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCircleExclamation, faFilter, faHouse, faMap, faPeople } from "@fortawesome/pro-light-svg-icons";
import { ADDRESS_ACCENT, CALL_ACCENT, PERSON_ACCENT } from "../../utils/colors";
import { useLocation, useSearchParams } from "react-router-dom";
import { getQueryParams } from "../../utils/url";
import { useGetAddressesQuery, useGetEventsQuery, useGetFuzzyPeopleQuery, useGetPeopleQuery } from "../../api/api";
import CallResult from "./CallResult";
import IncidentResult from "./IncidentResult";
import PersonResult from "./PersonResult";
import { useNavigate } from "react-router";
import AddressResult from "./AddressResult";
import { THREE_YEARS } from "../radar/filters/Filters";
import FilterBar from "../../components/modalFilters/FilterBar";
import FMPagination, { FMPaginationNonLinks } from "../../components/pagination/Pagination";
import { prepareEventMapConfig } from "../../hooks/useEventSearchLayer";
import { preparePersonMapConfig } from "../../hooks/usePersonSearchLayer";
import Button from "react-bootstrap/Button";
import { prepareAddressMapConfig } from "../../hooks/useAddressSearchLayer";
import FilterCard from "../../components/filters/FilterCard";
import NoResults from "../../components/NoResults";
import { usePagination } from "../../hooks/usePagination";
import FiltersModal, { TEventFiltersAggregations } from "../../components/modalFilters/FiltersModal";
import SortButton from "../../components/SortButton";
import { scrollToTop } from "../../utils/helpers";
import { useManageSortSearchParam } from "../../hooks/useManageSortSearchParam";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import ShareEntity from "../../components/share/ShareEntity";
import FollowButton from "../../components/FollowButton";
import { useDarkMode } from "../../hooks/useDarkMode";
import { setShowFeedbackModal } from "../../app/slices/user/userSlice";
import { useAppDispatch } from "../../app/hooks";
import FMSpinner from "../../components/Spinner";
import { useFilters } from "../../hooks/useFilters";
import usePermissions from "../../hooks/usePermissions";

type TSearchResults = {
    searchParams: any;
    isFetchingEvents: boolean;
    isFetchingPeople: boolean;
    isFetchingAddresses: boolean;
    isFetchingFuzzyPeople: boolean;
    isFetchingBeats: boolean;
    events: any;
    eventFiltersAggregations: TEventFiltersAggregations;
    people: any;
    addresses: any;
    nonPaginatedAddresses: any;
    addressPage: any;
    totalAddressPages: any;
    setAddressPage: any;
    fuzzyPeople?: any;
    eventsCount: number;
    peopleCount: number;
    addressesCount: number;
    selectedTab: EntitiesType;
    dataSources: any[];
    pinnedResult: any;
    setPinnedResult: Function;
    listRef: any;
    filterList?: any;
};

type TRenderListItems = {
    selectedTab: any;
    listDataLength: any;
    isFetchingEvents: boolean;
    isFetchingPeople: boolean;
    isFetchingAddresses: boolean;
    isFetchingFuzzyPeople: boolean;
    isFetchingBeats: boolean;
    events: any;
    eventFiltersAggregations: TEventFiltersAggregations;
    pinnedResult: any;
    linkParams: any;
    people: any;
    fuzzyPeople: any;
    addresses: any;
    nonPaginatedAddresses: any;
    addressPage: any;
    totalAddressPages: any;
    setAddressPage: any;
    page: any;
    pathname: any;
    totalPages: any;
    listRef: any;
    pageSize: number;
    filterList?: any;
};
type TNavViews = {
    eventsCount: number;
    peopleCount: number;
    addressesCount: number;
    searchParams: any;
    selectedTab?: EntitiesType;
};

const ITEMS_PER_PAGE = 10;

export const yieldValidatedPageSize = (pageSize: string | null) => {
    return pageSize && ["10", "25", "50"].includes(pageSize) ? parseInt(pageSize) : ITEMS_PER_PAGE;
};

const SearchResultsConnected = () => {
    const [search] = useSearchParams();
    const searchParams = React.useMemo(() => new URLSearchParams(search), [search]);
    const pageSize = yieldValidatedPageSize(searchParams.get("pageSize"));
    const queryParams = { ...getQueryParams(searchParams), pageSize };
    const [dataSources, setDataSources] = React.useState<SourceConfig[]>([]);
    const [pinnedResult, setPinnedResult] = React.useState<any>();
    const listRef = React.useRef<any>(null);
    const { filterPayload, beatsIsFetching } = useFilters({});
    const { hasPermission } = usePermissions();

    const { data: events, isFetching: isFetchingEvents } = useGetEventsQuery(
        {
            formattedResults: true,
            ...queryParams,
            filters: filterPayload,
            source: "main_search",
        },
        { skip: beatsIsFetching }
    );
    const { data: peopleResponse, isFetching: isFetchingPeople } = useGetPeopleQuery(
        {
            formattedResults: true,
            source: "main_search",
            ...queryParams,
            filters: filterPayload,
        },
        { skip: beatsIsFetching || hasPermission("use_nextfe_search") }
    );
    const { data: addressesResponse, isFetching: isFetchingAddresses } = useGetAddressesQuery(
        {
            formattedResults: true,
            source: "main_search",
            ...queryParams,
            filters: filterPayload,
        },
        { skip: beatsIsFetching || hasPermission("use_nextfe_search") }
    );

    const { data: fuzzyPeopleResponse, isFetching: isFetchingFuzzyPeople } = useGetFuzzyPeopleQuery(
        {
            formattedResults: true,
            ...queryParams,
            filters: filterPayload,
        },
        { skip: hasPermission("use_nextfe_search") }
    );

    const eventFiltersAggregations = events?.aggregations;
    const eventsCount = events && events.success && events?.count ? events.count : 0;

    // for peopleCount, use the people object or if 0, try to see if there are fuzzy matches and use that count.  The ui should only
    // show 0 count on the people tab if there are no hits from the  people or people fuzzy queries
    const peopleCount =
        peopleResponse && peopleResponse.success && peopleResponse.count
            ? peopleResponse.count
            : fuzzyPeopleResponse && fuzzyPeopleResponse.success
            ? fuzzyPeopleResponse.count
            : 0;

    const addressesCount = addressesResponse?.success && addressesResponse?.count ? addressesResponse.count : 0;
    const eventsArray = events ? events.search_results : [];
    let fuzzyPeopleArray = [];
    if (peopleResponse?.people && fuzzyPeopleResponse?.people) {
        fuzzyPeopleArray = fuzzyPeopleResponse.people.filter((item: any) => !peopleResponse.people.find((p: any) => p.id === item.id));
    }

    // If user has next fe permission force them onto Event tab to avoid UI issues
    let tab = hasPermission("use_nextfe_search") ? Entities.EVENT : (searchParams.get("selectedTab") as EntitiesType) || Entities.EVENT;

    const pageResetDependencies = React.useMemo(() => ({ tab, pageSize, scrollToId: "mainSearchScrollTarget" }), [tab, pageSize]);

    const {
        currentPage: addressPage,
        currentPageData: addressData,
        totalPages: totalAddressPages,
        setCurrentPage: setAddressPage,
    } = usePagination(addressesResponse?.addresses || [], pageSize, pageResetDependencies, scrollToTop);

    const { updateSortUrlParam } = useManageSortSearchParam();

    React.useEffect(() => {
        updateSortUrlParam();
    }, [searchParams, updateSortUrlParam]);

    React.useEffect(() => {
        const configs: SourceConfig[] = [];
        if (events && events?.search_results && tab === Entities.EVENT) {
            configs.push(prepareEventMapConfig(events.search_results, false, pinnedResult));
        }

        if (peopleResponse && peopleResponse?.people?.length && tab === Entities.PERSON) {
            configs.push(preparePersonMapConfig(peopleResponse.people, pinnedResult));
        }

        //Using non-paginated address data on map due to infinite useEffect loop when using the paginated data
        if (addressesResponse && addressesResponse?.addresses?.length && tab === Entities.ADDRESS) {
            configs.push(prepareAddressMapConfig(addressesResponse?.addresses, false, pinnedResult));
        }

        if (configs.length) {
            setDataSources(configs);
        }
    }, [events, peopleResponse, tab, pinnedResult, addressesResponse]);

    React.useEffect(() => {
        setPinnedResult(null);
    }, [searchParams]);

    return (
        <SearchResults
            searchParams={searchParams}
            isFetchingEvents={isFetchingEvents}
            isFetchingPeople={isFetchingPeople}
            isFetchingAddresses={isFetchingAddresses}
            isFetchingFuzzyPeople={isFetchingFuzzyPeople}
            isFetchingBeats={beatsIsFetching}
            events={eventsArray}
            eventFiltersAggregations={eventFiltersAggregations}
            eventsCount={eventsCount}
            people={peopleResponse?.people || []}
            peopleCount={peopleCount}
            addresses={addressData || []}
            nonPaginatedAddresses={addressesResponse?.addresses}
            addressPage={addressPage}
            totalAddressPages={totalAddressPages}
            setAddressPage={setAddressPage}
            addressesCount={addressesCount}
            fuzzyPeople={fuzzyPeopleArray}
            selectedTab={tab}
            dataSources={dataSources}
            pinnedResult={pinnedResult}
            setPinnedResult={setPinnedResult}
            listRef={listRef}
        />
    );
};

export const SearchResults = ({
    searchParams,
    isFetchingEvents,
    isFetchingPeople,
    isFetchingAddresses,
    isFetchingFuzzyPeople,
    isFetchingBeats,
    events,
    eventFiltersAggregations,
    eventsCount,
    people,
    peopleCount,
    addresses,
    nonPaginatedAddresses,
    addressPage,
    totalAddressPages,
    setAddressPage,
    addressesCount,
    fuzzyPeople,
    selectedTab,
    dataSources,
    pinnedResult,
    setPinnedResult,
    listRef,
}: TSearchResults) => {
    const { pathname } = useLocation();
    const query = searchParams.get("query");
    const page = searchParams.get("page") || 1;
    const navigate = useNavigate();
    const { hasPermission } = usePermissions();
    const { isDarkMode } = useDarkMode();
    const [disableRebound, setDisableRebound] = React.useState(false);

    //If query changes we need to re-bind the map around the new results
    React.useEffect(() => {
        setDisableRebound(false);
    }, [query]);

    let listDataLength;

    if (selectedTab === Entities.PERSON) {
        listDataLength = peopleCount;
    } else if (selectedTab === Entities.ADDRESS) {
        listDataLength = addressesCount;
    } else {
        listDataLength = eventsCount;
    }

    const pageSize = yieldValidatedPageSize(searchParams.get("pageSize"));
    const totalPages = listDataLength && pageSize ? Math.ceil(listDataLength / (pageSize as any)) : 0;

    const linkParams = new URLSearchParams();
    if (!!query) {
        linkParams.set("query", query);
    }

    const setSelectedTab = (tabKey: any) => {
        if (tabKey) {
            searchParams.set("selectedTab", tabKey);
            searchParams.delete("page");
            navigate(`.?${searchParams.toString()}`);
        }
    };

    //If map dot result is clicked then set the pinned result and disable map re-binding
    const clickEvent = (event: any) => {
        if (event && event.features.length && event.features[0].properties) {
            setPinnedResult(event.features[0].properties);
            setDisableRebound(true);
        } else {
            setPinnedResult(null);
        }
    };

    const switchToMap = () => {
        // if query search param is not set, then set it
        // before navigating to map page to ensure the map
        // runs the search query
        if (!searchParams.get("query")) {
            searchParams.set("query", "");
        }
        navigate(`map?${searchParams.toString()}`);
    };

    const { showMultiAgencyFilter } = useMultiAgency();

    return (
        <Container
            className={`p-4 ${hasPermission("use_nextfe_search") ? `d-flex justify-content-center` : ""}`}
            fluid="xl"
            ref={listRef}
            data-testid="main-search-container"
        >
            <Row className={hasPermission("use_nextfe_search") ? `d-flex justify-content-center w-100` : ""}>
                {!hasPermission("use_nextfe_search") && (
                    <Col className="d-none d-md-block px-0" md={3}>
                        <Card className="border-0 mb-4 bg-transparent">
                            <Card.Header className="bg-dark py-3 d-flex justify-content-between flex-wrap">
                                <div className="align-self-center text-white d-flex flex-column">
                                    <span className="fw-bold">Search Results</span>
                                </div>
                                <Stack direction="horizontal" gap={2} className="align-items-start">
                                    <FollowButton followText={query} type={"search"} />
                                    <ShareEntity />
                                </Stack>
                            </Card.Header>
                            <Card.Body className="p-0 rounded bg-transparent">
                                <div className="rounded-right rounded-bottom overflow-hidden">
                                    <MapComponent
                                        id="map-main-search"
                                        mapV2
                                        sources={dataSources}
                                        layerClickEventHandler={clickEvent}
                                        disableRebounding={disableRebound}
                                    />
                                </div>
                                <div className="d-flex gap-2 flex-wrap mt-4 px-4">
                                    {(selectedTab === Entities.EVENT || selectedTab === Entities.PERSON) && (
                                        <Button
                                            variant="outline-secondary"
                                            className="pendo_switch_to_map text-nowrap flex-grow-1"
                                            size="sm"
                                            onClick={switchToMap}
                                        >
                                            <FontAwesomeIcon icon={faMap} />
                                            &nbsp;Switch to map view
                                        </Button>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                        {(selectedTab !== Entities.ADDRESS || showMultiAgencyFilter) && (
                            <FilterCard
                                eventFiltersAggregations={eventFiltersAggregations}
                                selectedEntities={[selectedTab || Entities.EVENT]}
                                defaultDateRange={THREE_YEARS}
                            />
                        )}
                    </Col>
                )}
                <Col xs={12} md={9} className="px-0 px-md-4">
                    {!hasPermission("use_nextfe_search") && (
                        <div id="mainSearchScrollTarget" className="p-4 p-md-0">
                            <SearchInput searchPath="./" />
                            <Stack direction="horizontal" gap={2} className="d-md-none align-items-start">
                                <FollowButton variant={isDarkMode ? "" : "outline-dark"} followText={query} type={"search"} />
                                <ShareEntity />
                            </Stack>
                        </div>
                    )}

                    <Nav
                        className="d-md-none gap-3 d-flex flex-column text-center p-4"
                        variant="pills"
                        onSelect={(tabKey) => setSelectedTab(tabKey || Entities.EVENT)}
                    >
                        <MobileNavItems
                            eventsCount={eventsCount}
                            peopleCount={peopleCount}
                            addressesCount={addressesCount}
                            searchParams={searchParams}
                            selectedTab={selectedTab}
                        />
                    </Nav>
                    <Nav
                        variant="tabs"
                        className="d-none d-md-flex border-bottom-0"
                        activeKey={selectedTab}
                        onSelect={(tabKey) => setSelectedTab(tabKey || Entities.EVENT)}
                    >
                        <DesktopNavItems
                            eventsCount={eventsCount}
                            peopleCount={peopleCount}
                            addressesCount={addressesCount}
                            searchParams={searchParams}
                        />
                    </Nav>
                    <div className="fm-bg-color border-0 border-md rounded-end rounded-bottom p-4">
                        <RenderListItems
                            selectedTab={selectedTab}
                            listDataLength={listDataLength}
                            isFetchingEvents={isFetchingEvents}
                            isFetchingPeople={isFetchingPeople}
                            isFetchingAddresses={isFetchingAddresses}
                            isFetchingFuzzyPeople={isFetchingFuzzyPeople}
                            isFetchingBeats={isFetchingBeats}
                            events={events}
                            eventFiltersAggregations={eventFiltersAggregations}
                            pinnedResult={pinnedResult}
                            linkParams={linkParams}
                            people={people}
                            fuzzyPeople={fuzzyPeople}
                            addresses={addresses}
                            nonPaginatedAddresses={nonPaginatedAddresses}
                            addressPage={addressPage}
                            totalAddressPages={totalAddressPages}
                            setAddressPage={setAddressPage}
                            page={page}
                            pathname={pathname}
                            totalPages={totalPages}
                            pageSize={pageSize}
                            listRef={listRef}
                            // ensure rerender when sort changes so the RenderListItems component is correct
                            key={searchParams.get("sort")}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

const RenderListItems = ({
    selectedTab,
    listDataLength,
    isFetchingEvents,
    isFetchingPeople,
    isFetchingAddresses,
    isFetchingFuzzyPeople,
    isFetchingBeats,
    events,
    eventFiltersAggregations,
    pinnedResult,
    linkParams,
    people,
    fuzzyPeople,
    addresses,
    nonPaginatedAddresses,
    addressPage,
    totalAddressPages,
    setAddressPage,
    page,
    pathname,
    totalPages,
    pageSize,
}: TRenderListItems) => {
    const dispatch = useAppDispatch();
    const [show, setShow] = React.useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { hasPermission } = usePermissions();

    const sortConfig: SortConfig = {
        relevance: {
            label: "Relevance",
            defaultSortDirection: SortDirectionEnum.NONE,
            sortString: true,
            isDisabled: searchParams.get("query")?.length === 0,
        },
        date: {
            label: "Date",
            defaultSortDirection: SortDirectionEnum.DESCENDING,
            sortString: true,
        },
    };

    // The apply button will set or remove params based on Sort Button changes
    const apply = (option: string, direction?: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("sort", option);
        // setting direction via arrow button
        if (direction) {
            newSearchParams.set("sortDirection", direction);
            // removing sortDirection when config option is none
        } else if (sortConfig[option]?.defaultSortDirection === SortDirectionEnum.NONE) {
            newSearchParams.delete("sortDirection");
            // setting sortDirection on first change to option without NONE
        } else if (sortConfig[option]?.defaultSortDirection !== SortDirectionEnum.NONE) {
            newSearchParams.set("sortDirection", sortConfig[option]?.defaultSortDirection);
        }

        navigate(`.?${newSearchParams.toString()}`);
    };

    const generateAllTimeSearchUrl = () => {
        const urlSearchParams = new URLSearchParams(searchParams);
        urlSearchParams.set("dateRange", "all_time");
        return urlSearchParams.toString();
    };

    const goToAllTimeURL = () => {
        navigate(`.?${generateAllTimeSearchUrl()}`);
    };

    const showFeedback = () => {
        dispatch(setShowFeedbackModal({ show: true, status: "concern" }));
    };

    const allTimeParamCheck = searchParams.get("dateRange") !== "all_time";

    const { showMultiAgencyFilter } = useMultiAgency();

    const setPageSize = (pageSize: string | null) => {
        if (pageSize) {
            searchParams.set("pageSize", yieldValidatedPageSize(pageSize).toString());
            searchParams.set("page", "1");
            navigate(`.?${searchParams.toString()}`);
        }
    };

    return (
        <div className="mb-5">
            <div className={`p-0 pb-5`}>
                <div className="align-self-center d-md-flex">
                    {!hasPermission("use_nextfe_search") && (
                        <div className="me-md-3 mb-3 mb-md-0">
                            <Button
                                className="pendo_filters_modal_open_button cursor-pointer container-md justify-content-center d-inline-flex align-items-center gap-2 text-nowrap rounded sort-filter"
                                onClick={() => setShow(true)}
                                disabled={selectedTab === Entities.ADDRESS && !showMultiAgencyFilter}
                            >
                                <FontAwesomeIcon icon={faFilter} /> Filters
                            </Button>
                        </div>
                    )}
                    <FilterBar showModal={!hasPermission("use_nextfe_search") && !show ? () => setShow(true) : null} tab={selectedTab} />
                </div>
                {!!listDataLength && selectedTab === "event" && (
                    <div className="d-md-flex justify-content-md-between mt-3 mb-4">
                        <SortButton
                            sortField={searchParams.get("sort") || "relevance"}
                            sortConfig={sortConfig}
                            sortDirection={
                                searchParams.get("sortDirection") !== null
                                    ? searchParams.get("sortDirection") === SortDirectionEnum.DESCENDING
                                        ? SortDirectionEnum.DESCENDING
                                        : SortDirectionEnum.ASCENDING
                                    : sortConfig[searchParams.get("sort") || "relevance"]?.defaultSortDirection
                            }
                            apply={apply}
                        />
                        {hasPermission("use_nextfe_search") && (
                            <div className="mt-2 mt-md-0">
                                Looking for the Search page?{" "}
                                <a href={`/search/results/events${searchParams.get("query") ? `?query=${searchParams.get("query")}` : ""}`}>
                                    Click here!
                                </a>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <FiltersModal
                eventFiltersAggregations={eventFiltersAggregations}
                show={show}
                handleClose={() => setShow(false)}
                selectedTab={selectedTab}
                resultsText={selectedTab === "person" ? "People" : "Events"}
                defaultFilterValues={{ dateRange: "3years" }}
                defaultDateRange="all_time"
                totalSearchResults={listDataLength}
            />
            {!isFetchingEvents && listDataLength >= 10000 && (
                <span className="fw-bold">Displaying 10K results. Refine your search criteria for better results.</span>
            )}
            {!isFetchingEvents && !isFetchingBeats && listDataLength === 0 && (
                <div>
                    <NoResults
                        text={
                            <span>
                                {(searchParams.get("query")?.length || 0) > 0 ? "No results found for" : "No results found"}
                                <strong>{(searchParams.get("query")?.length || 0) > 0 && ` ${searchParams.get("query")}`}</strong>
                            </span>
                        }
                    />
                    {!hasPermission("use_nextfe_search") && (
                        <>
                            <div>If you were expecting results, please try the following:</div>
                            <ul>
                                {allTimeParamCheck && (
                                    <li>
                                        Expand the Date Range filter to{" "}
                                        <Button
                                            className="pendo_no_results_all_time_button p-0 align-baseline"
                                            variant="link"
                                            onClick={goToAllTimeURL}
                                        >
                                            All Time
                                        </Button>
                                    </li>
                                )}
                                <li>Check your search term for typos or alternative spellings</li>
                                <li>Remove filters that may be limiting your results</li>
                                <li>Remove any quotation marks</li>
                                <li>If searching for an address, try only the street number and name (e.g. 123 Main Street)</li>
                            </ul>
                            <div>
                                Still can’t find what you’re looking for?{" "}
                                <Button
                                    className="pendo_no_results_feedback_button p-0 align-baseline"
                                    variant="link"
                                    onClick={showFeedback}
                                >
                                    Click here to let us know!
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            )}

            <Stack gap={5} className="mb-5">
                {selectedTab === Entities.EVENT && events && (
                    <>
                        {(isFetchingEvents || isFetchingBeats) && <SearchResultsLoadingSpinner text="Running Event Search..." />}
                        {pinnedResult?.id && pinnedResult?.type === "call" && (
                            <>
                                <CallResult
                                    key={pinnedResult?.id}
                                    call={events.find((e: any) => e.source.id === pinnedResult?.id)}
                                    linkParams={linkParams}
                                    mapHighlight={true}
                                />
                                <hr />
                            </>
                        )}
                        {pinnedResult?.id && pinnedResult?.type === "incident" && (
                            <>
                                <IncidentResult
                                    key={pinnedResult?.id}
                                    incident={events.find((e: any) => e.source.id === pinnedResult?.id)}
                                    linkParams={linkParams}
                                    mapHighlight={true}
                                />
                                <hr />
                            </>
                        )}
                        {!isFetchingEvents &&
                            events.map((event: any) => {
                                if (event.index.includes("call")) {
                                    return <CallResult key={event.id} call={event} linkParams={linkParams} />;
                                } else {
                                    return <IncidentResult key={event.id} incident={event} linkParams={linkParams} />;
                                }
                            })}
                    </>
                )}
                {selectedTab === Entities.PERSON && people && (
                    <>
                        {(isFetchingPeople || isFetchingFuzzyPeople) && (people.length === 0 || fuzzyPeople.length === 0) && (
                            <SearchResultsLoadingSpinner text="Running People Search..." />
                        )}

                        {pinnedResult?.id && pinnedResult?.type === "person" && (
                            <>
                                <PersonResult
                                    key={pinnedResult?.id}
                                    person={people.find((e: any) => e.source.id === pinnedResult?.id).source}
                                    linkParams={linkParams}
                                    mapHighlight={true}
                                />
                                <hr />
                            </>
                        )}

                        {!isFetchingPeople &&
                            people.map((person: any) => {
                                return <PersonResult key={person.id} person={person.source} linkParams={linkParams} />;
                            })}

                        {!isFetchingFuzzyPeople && fuzzyPeople.length > 0 && (
                            <>
                                <h3 className="mt-3">Possible Matches</h3>
                                {fuzzyPeople.map((person: any) => {
                                    return <PersonResult key={person.id} person={person.source} linkParams={linkParams} />;
                                })}
                            </>
                        )}
                    </>
                )}
                {selectedTab === Entities.ADDRESS && addresses && (
                    <>
                        {isFetchingAddresses && nonPaginatedAddresses?.length === 0 && (
                            <SearchResultsLoadingSpinner text="Running Address Search..." />
                        )}
                        {pinnedResult?.address_id && pinnedResult?.type === "address" && (
                            <>
                                <AddressResult
                                    key={pinnedResult?.id}
                                    address={nonPaginatedAddresses.find((e: any) => e.source.id === pinnedResult?.address_id)}
                                    linkParams={linkParams}
                                    mapHighlight={true}
                                />
                                <hr />
                            </>
                        )}
                        {addresses.map((address: any, index: number) => {
                            return <AddressResult key={`${address.address_id} ${index}`} address={address} linkParams={linkParams} />;
                        })}
                    </>
                )}
            </Stack>
            {!isFetchingEvents && listDataLength !== 0 && (
                <Stack>
                    <Row className="mb-2">
                        <Col xs={12} md={3}>
                            <label className="text-nowrap">
                                <b>Results Per Page:</b>
                            </label>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Col xs={12} md={3} className="mb-2">
                            <ToggleButtonGroup
                                name="options"
                                size="sm"
                                type="radio"
                                value={pageSize.toString()}
                                onChange={(e) => setPageSize(e)}
                            >
                                <ToggleButton id="10" value="10" variant="outline-primary">
                                    10
                                </ToggleButton>
                                <ToggleButton id="25" value="25" variant="outline-primary">
                                    25
                                </ToggleButton>
                                <ToggleButton id="50" value="50" variant="outline-primary">
                                    50
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Col>
                        <Col xs={12} md={9}>
                            {selectedTab === Entities.ADDRESS && (
                                <FMPaginationNonLinks
                                    page={addressPage + 1}
                                    setPage={(page: number) => setAddressPage(page - 1)}
                                    totalPages={totalAddressPages}
                                    pagesDisplayCount={5}
                                />
                            )}
                            {selectedTab !== Entities.ADDRESS && (
                                <FMPagination page={page} url={pathname} totalPages={totalPages} pagesDisplayCount={5} />
                            )}
                        </Col>
                    </Row>
                </Stack>
            )}
        </div>
    );
};

const DesktopNavItems = ({ eventsCount, peopleCount, addressesCount, searchParams }: TNavViews) => {
    const { hasPermission } = usePermissions();

    return (
        <>
            <Nav.Item className="pendo_events_tab">
                <Nav.Link eventKey={Entities.EVENT}>
                    <span>
                        <FontAwesomeIcon icon={faCalendarCircleExclamation} className="me-2" color={CALL_ACCENT} />
                        <span className="d-sm-none">{eventsCount}</span>
                        <span className="d-none d-sm-inline-block">{`Events - ${eventsCount}`}</span>
                    </span>
                </Nav.Link>
            </Nav.Item>
            {!hasPermission("use_nextfe_search") && (
                <>
                    <Nav.Item className={`pendo_people_tab ${!searchParams.get("query") || peopleCount < 1 ? "cursor-not-allowed" : ""}`}>
                        <Nav.Link eventKey={Entities.PERSON} disabled={!searchParams.get("query") || peopleCount < 1}>
                            <span>
                                <FontAwesomeIcon icon={faPeople} className="me-2" color={PERSON_ACCENT} />
                                <span className="d-sm-none">{peopleCount}</span>
                                <span className="d-none d-sm-inline-block">{`People - ${peopleCount}`}</span>
                            </span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                        className={`pendo_addresses_tab ${!searchParams.get("query") || addressesCount < 1 ? "cursor-not-allowed" : ""}`}
                    >
                        <Nav.Link eventKey={Entities.ADDRESS} disabled={!searchParams.get("query") || addressesCount < 1}>
                            <span>
                                <FontAwesomeIcon icon={faHouse} className="me-2" color={ADDRESS_ACCENT} />
                                <span className="d-sm-none">{addressesCount}</span>
                                <span className="d-none d-sm-inline-block">{`Addresses - ${addressesCount}`}</span>
                            </span>
                        </Nav.Link>
                    </Nav.Item>
                </>
            )}
        </>
    );
};

const MobileNavItems = ({ eventsCount, peopleCount, addressesCount, searchParams, selectedTab }: TNavViews) => {
    const { hasPermission } = usePermissions();

    return (
        <>
            <Nav.Item className="pendo_events_mobile_tab">
                <Nav.Link
                    className={`rounded border border-primary ${selectedTab === Entities.EVENT ? "active" : ""}`}
                    eventKey={Entities.EVENT}
                >
                    <span>
                        <FontAwesomeIcon icon={faCalendarCircleExclamation} className="me-2 d-none d-sm-inline-block" color={CALL_ACCENT} />
                        <span>{`Events - ${eventsCount}`}</span>
                    </span>
                </Nav.Link>
            </Nav.Item>
            {!hasPermission("use_nextfe_search") && (
                <>
                    <Nav.Item className={`pendo_people_mobile_tab ${!searchParams.get("query") ? "cursor-not-allowed" : ""}`}>
                        <Nav.Link
                            className={`rounded border border-primary ${selectedTab === Entities.PERSON ? "active" : ""}`}
                            eventKey={Entities.PERSON}
                            disabled={!searchParams?.get("query") || peopleCount < 1}
                        >
                            <span>
                                <FontAwesomeIcon icon={faPeople} className="me-2 d-none d-sm-inline-block" color={PERSON_ACCENT} />
                                <span>{`People - ${peopleCount}`}</span>
                            </span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`pendo_addresses_mobile_tab ${!searchParams.get("query") ? "cursor-not-allowed" : ""}`}>
                        <Nav.Link
                            className={`rounded border border-primary ${selectedTab === Entities.ADDRESS ? "active" : ""}`}
                            eventKey={Entities.ADDRESS}
                            disabled={!searchParams?.get("query") || addressesCount < 1}
                        >
                            <span>
                                <FontAwesomeIcon icon={faHouse} className="me-2 d-none d-sm-inline-block" color={ADDRESS_ACCENT} />
                                <span>{`Addresses - ${addressesCount}`}</span>
                            </span>
                        </Nav.Link>
                    </Nav.Item>
                </>
            )}
        </>
    );
};

const SearchResultsLoadingSpinner = ({ text = "Running Search..." }) => (
    <div className="d-flex justify-content-center mb-5">
        <FMSpinner />
        <h3 className="ms-3 mt-2">{text}</h3>
    </div>
);

export default SearchResultsConnected;
