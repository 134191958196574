import React from "react";
import { Card } from "react-bootstrap";
import SeeMore from "./SeeMore";
import usePermissions from "../../hooks/usePermissions";
import { useMultiAgency } from "../../hooks/useMultiAgency";

type OwnProps = {
    title?: string;
    titleComponent?: React.ReactNode;
    showMoreFn?: Function;
    pendoClassName?: string;
    showShareOption?: boolean;
    followsButton?: React.ReactNode;
    agency?: string;
};

const CardHeader = ({ title, titleComponent, showMoreFn, pendoClassName, showShareOption, followsButton, agency }: OwnProps) => {
    const { isMultiAgency } = useMultiAgency();
    const { hasPermission } = usePermissions();

    return (
        <Card.Header className="d-flex justify-content-between align-items-center w-100">
            <div>
                {!!title && <span className="fw-bold">{title}</span>}
                {isMultiAgency && !!agency && <small className="text-muted">&nbsp;({agency})</small>}
                {!!titleComponent && titleComponent}
            </div>
            <div>
                {showMoreFn && <SeeMore onClick={showMoreFn} pendoClassName={pendoClassName} />}
                {hasPermission("follows") && followsButton}
            </div>
        </Card.Header>
    );
};

export default CardHeader;
