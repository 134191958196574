import { Card, ListGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faArrowCircleRight, faPhone } from "@fortawesome/pro-light-svg-icons";
import { CALL_ACCENT } from "../../utils/colors";
import { Link } from "react-router-dom";
import Tags from "../tags/Tags";
import { formatDate } from "../../utils/date";
import { useManageSearchParams } from "../../hooks/useManageSearchParams";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import useAgencySettings from "../../hooks/useAgencySettings";
import Highlighter from "../highlighter/Highlighter";
import { highlightDisplay } from "../../utils/display";
import { stripHtml } from "../../pages/search/IncidentResult";

type OwnProps = {
    call: any;
};

const CallCard = ({ call }: OwnProps) => {
    const { constructEventUrl } = useMultiAgency();
    const { processSearchParams } = useManageSearchParams();
    const { callText } = useAgencySettings();

    return (
        <ListGroup.Item className="mb-3 p-0 border-0" as={Card}>
            <Card.Header className="d-flex justify-content-between gap-3 border rounded">
                <div className="flex-grow-1">
                    <FontAwesomeIcon icon={faPhone} className="fs-2" color={CALL_ACCENT} />
                    {call?.archived ? <FontAwesomeIcon icon={faArchive} className="fs-2 mt-2" color="gray" /> : null}

                    <Link
                        className="ms-3 d-inline fw-bold pendo_call_link_button"
                        to={constructEventUrl(call?.agency_id, call?.call_number, undefined, processSearchParams(["dateRange", "query"]))}
                    >
                        {callText} {call.call_number} {call.call_type ? ` | ${call.call_type}` : ""}
                    </Link>
                    <span className="d-block float-md-end">{formatDate(call.occurred_at)}</span>
                </div>
            </Card.Header>
            <Card.Body className="overflow-hidden fm-left-indent">
                <Tags tags={call?.flags || []} />
                <div>{call.full_address}</div>
                <div>
                    {call?.highlight && (
                        <div className="d-inline-flex d-md-none">
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="top"
                                overlay={
                                    <Popover>
                                        <Popover.Header>Match Previews</Popover.Header>
                                        <Popover.Body>
                                            <ul>
                                                {call?.highlight &&
                                                    Object.keys(call?.highlight).map((text: any, index: number) => {
                                                        return (
                                                            <li key={index}>
                                                                <Highlighter className="pe-3">
                                                                    {`${highlightDisplay[text]}: ${stripHtml(
                                                                        call?.highlight[text].join(", ")
                                                                    )}`}
                                                                </Highlighter>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <span className="cursor-pointer text-primary d-inline-flex align-items-center mt-2 fm-preview-font-size">
                                    <FontAwesomeIcon icon={faArrowCircleRight} />
                                    &nbsp;Preview
                                </span>
                            </OverlayTrigger>
                        </div>
                    )}
                    {call?.highlight && (
                        <div className="d-none d-md-flex">
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="top-start"
                                overlay={
                                    <Popover>
                                        <Popover.Header>Match Previews</Popover.Header>
                                        <Popover.Body>
                                            <ul>
                                                {call?.highlight &&
                                                    Object.keys(call?.highlight).map((text: any, index: number) => {
                                                        return (
                                                            <li key={index}>
                                                                <Highlighter className="pe-3">
                                                                    {`${highlightDisplay[text]}: ${stripHtml(
                                                                        call?.highlight[text].join(", ")
                                                                    )}`}
                                                                </Highlighter>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <span className="cursor-pointer text-primary d-inline-flex align-items-center mt-2 fm-preview-font-size">
                                    <FontAwesomeIcon icon={faArrowCircleRight} />
                                    &nbsp;Preview
                                </span>
                            </OverlayTrigger>
                        </div>
                    )}
                </div>
            </Card.Body>
        </ListGroup.Item>
    );
};

export default CallCard;
