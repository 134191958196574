import { Card, Col, Container, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faLocationCheck } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { ADDRESS_ACCENT } from "../../app/colors";
import { formatDate } from "../../utils/date";
import ResultHeader from "./ResultHeader";
import AgencyDescription from "../../components/AgencyDescription";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import { useManageSearchParams } from "../../hooks/useManageSearchParams";
import useAgencySettings from "../../hooks/useAgencySettings";

type OwnProps = {
    address: any;
    linkParams: any;
    mapHighlight?: boolean;
};

const AddressResult = ({ address, linkParams, mapHighlight = false }: OwnProps) => {
    const { constructAddressUrl } = useMultiAgency();
    const { processSearchParams } = useManageSearchParams();
    const { incidentTextPlural, callTextPlural } = useAgencySettings();

    const gpsLat = address?.source?.gps_lat;
    const gpsLon = address?.source?.gps_lon;

    const addressLink = constructAddressUrl(address?.source?.agency_id, address?.source, processSearchParams(["query"]).get("query"));

    return (
        <Card id={address?.source?.address_id} className="border-0">
            <ResultHeader
                mapHighlight={mapHighlight}
                icon={faHome}
                iconColor={ADDRESS_ACCENT}
                leftContent={
                    <>
                        <Link data-testid="address-result" className="pendo_address_result d-inline-block fw-bold" to={addressLink}>
                            {address?.source?.full_address}
                            {gpsLat && gpsLon && (
                                <OverlayTrigger
                                    trigger={["hover", "focus"]}
                                    placement="right"
                                    overlay={
                                        <Popover>
                                            <Popover.Header>Nearby Results Available</Popover.Header>
                                            <Popover.Body>This address record includes geo coordinates and events nearby</Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <FontAwesomeIcon className="ms-3" icon={faLocationCheck} color="green" />
                                </OverlayTrigger>
                            )}
                        </Link>
                        <AgencyDescription className="text-secondary" agencyDescription={address?.source?.agency_desc} />
                    </>
                }
            />
            <Card.Body>
                <Container fluid className="p-0">
                    <Row className="g-3">
                        <Col xs={12}>
                            <span data-testid="occurred-at">Most Recent Event: {formatDate(address?.occurred_at)}</span>
                        </Col>
                        <Col className="text-wrap mt-0" xs={12} md={4} lg={3}>
                            <span className="text-secondary me-2">{callTextPlural}:</span>
                            <span>{address?.counts?.calls || 0}</span>
                        </Col>
                        <Col className="text-wrap mt-0" xs={12} md={4} lg={3}>
                            <span className="text-secondary me-2">{incidentTextPlural}:</span>
                            <span>{address?.counts?.incidents || 0}</span>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
};

export default AddressResult;
