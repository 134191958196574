import React from "react";
import { scrollToTop } from "../utils/helpers";
import { defaultMobileSizePx } from "../constants/constants";

/*
 * React Hook to set the page title on initial page load
 */
export function useScrollToTopMultiDevice(currentTab: string, searchParams: any, mobileScrollTarget: string): void {
    React.useEffect(() => {
        if (window.innerWidth >= 769 && searchParams.get("currentTab") !== null) {
            scrollToTop();
        }
        if (window.innerWidth <= defaultMobileSizePx && searchParams.get("currentTab") !== null) {
            scrollToTop(mobileScrollTarget);
        }
    }, [currentTab, searchParams, mobileScrollTarget]);
}
