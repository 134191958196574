import { Container, Modal } from "react-bootstrap";
import BaseAssociatedPeople from "./BaseAssociatedPeople";
import ModalHeader from "../ModalHeader";
import { scrollToTop } from "../../utils/helpers";

type OwnProps = {
    show: boolean;
    close: () => void;
    people: any;
    query?: string;
    agencyId: string;
};
const PeopleAtAddressModal = ({ show, close, people, query, agencyId }: OwnProps) => {
    return (
        <Modal show={show} onHide={close} size="xl" scrollable={true}>
            <ModalHeader title="People at this Address" />
            <Modal.Body id="associatedPeopleModalBody" className="px-0">
                <Container fluid>
                    <BaseAssociatedPeople
                        query={query}
                        people={people}
                        hideFilter={false}
                        showIncidents={false}
                        showRole={false}
                        newTabLink={true}
                        agencyId={agencyId}
                        scrollToTopFn={scrollToTop}
                    />
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default PeopleAtAddressModal;
