import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { useRunPreAuthApiMutation, VALIDATE_CHANGE_PASSWORD_TOKEN_ROUTE, SUBMIT_CHANGE_PASSWORD_ROUTE } from "../../api/api";
import { Button, Col, Row, Stack, Container } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextField from "../../components/formcontrols/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faSpinner } from "@fortawesome/pro-light-svg-icons";
import FmAlert from "../support/FmAlert";
import { getInvalidPasswordMessage } from "./Step1Password";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import Disclaimer from "../support/Disclaimer";
import Logo from "../support/logo/Logo";

type FormProps = {
    password: string;
    passwordConfirm: string;
};

/**
 * New password entry screen for the change password process.
 *
 * @constructor
 */
const ChangePasswordEnterPassword = () => {
    useSetPageTitle("ForceMetrics | Change Password");
    const [alert, setAlert] = React.useState<string | undefined>();
    const [passwordChanged, setPasswordChanged] = React.useState<boolean>(false);
    const [validatePasswordResetToken, tokenResults] = useRunPreAuthApiMutation();
    const [changePassword, changePasswordResult] = useRunPreAuthApiMutation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const tokenFromEmail = searchParams.get("token");

    // Verify the token is valid
    React.useEffect(() => {
        if (tokenFromEmail) {
            setAlert(undefined); // clear any alerts
            validatePasswordResetToken({ token: tokenFromEmail, enforceMfa: true, route: VALIDATE_CHANGE_PASSWORD_TOKEN_ROUTE });
        } else {
            // something is terribly wrong or someone tried this page without going through the password reset process.
            setAlert("change_password_token_invalid");
        }
    }, [tokenFromEmail, validatePasswordResetToken]);

    // Listen for the validatePasswordResetToken response
    React.useEffect(() => {
        if (tokenResults.isError) {
            setAlert("change_password_token_invalid");
        }
        if (tokenResults.data) {
            if (tokenResults.data && !tokenResults.data.success) {
                setAlert(tokenResults.data.error);
            }
        }
    }, [tokenResults]);

    const submitPasswordReset = (values: FormProps) => {
        changePassword({ ...values, token: tokenFromEmail!, route: SUBMIT_CHANGE_PASSWORD_ROUTE });
        setAlert("password_changed");
        setPasswordChanged(true);
    };

    // Listen for setPasswordReset results
    React.useEffect(() => {
        if (changePasswordResult.data) {
            if (changePasswordResult.data && changePasswordResult.data.error) {
                setAlert(changePasswordResult.data.error);
                setPasswordChanged(false);
            } else if (changePasswordResult.data && !changePasswordResult.data.success) {
                setAlert("server_error");
                setPasswordChanged(false);
            }
        }
    }, [changePasswordResult, dispatch, navigate]);

    const initial = {
        password: "",
        passwordConfirm: "",
    };

    const loading = tokenResults.isLoading || changePasswordResult.isLoading;

    return (
        <Container>
            <Row className="justify-content-center">
                <Logo className="w-50" />
            </Row>
            <div className="d-flex justify-content-center mb-5">
                <h1 className="text-center">Change Password</h1>
            </div>
            {!passwordChanged && (
                <div className="d-flex justify-content-center p-3">
                    <h2>Enter New Password</h2>
                </div>
            )}
            {alert && (
                <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6} className="mt-2">
                        <FmAlert alertKey={alert} variant={alert === "password_changed" ? "success" : "danger"} />
                    </Col>
                </Row>
            )}
            {!passwordChanged && (
                <Row className="justify-content-center">
                    <Col xs={9} sm={7} md={5} lg={4} xl={3} className="border p-4 bg-filled">
                        <Stack>
                            <Formik
                                initialValues={initial}
                                onSubmit={(values) => {
                                    submitPasswordReset(values);
                                }}
                                validationSchema={Yup.object({
                                    password: Yup.string().min(8, getInvalidPasswordMessage).required("Required"),
                                    passwordConfirm: Yup.string()
                                        .oneOf([Yup.ref("password"), null], "Passwords must match")
                                        .required("Required"),
                                })}
                            >
                                {(props) => (
                                    <Form>
                                        <TextField label="Password" name="password" type="password" disabled={loading} />
                                        <TextField label="Re-enter Password" name="passwordConfirm" type="password" disabled={loading} />
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            disabled={loading || !props.dirty}
                                            className="mt-2 float-end"
                                        >
                                            <FontAwesomeIcon icon={loading ? faSpinner : faPaperPlane} className="me-2" />
                                            Next
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Stack>
                    </Col>
                </Row>
            )}
            <Row className="justify-content-center">
                <Col lg={8} className="p-4 mt-5">
                    <Disclaimer className="text-muted" />
                </Col>
            </Row>
        </Container>
    );
};

ChangePasswordEnterPassword.displayName = "ChangePasswordEnterPassword";
export default ChangePasswordEnterPassword;
