import { DARK_BLUE, LIGHT_BLUE, WHITE } from "./colors";

export const heatmapStops = [
    [0, WHITE],
    [0.5, LIGHT_BLUE],
    [1, DARK_BLUE],
];

export const getHeatmapMax = (data: number[][]) => {
    const baselineMaxValue = 10;
    if (!data) {
        return baselineMaxValue;
    }
    const maxValue = Math.max(...data.map((arr: number[]) => arr[2]));
    return maxValue > baselineMaxValue ? maxValue : baselineMaxValue;
};
