import { getQueryParams } from "../../utils/url";

export const buildBaseNavigateParams = (searchParams: any) => {
    const queryParams = getQueryParams(searchParams);
    const urlParams = new URLSearchParams();

    if (queryParams.dateRange) {
        urlParams.set("dateRange", queryParams.dateRange);
    }
    if (queryParams.startDate) {
        urlParams.set("startDate", queryParams.startDate);
    }
    if (queryParams.endDate) {
        urlParams.set("endDate", queryParams.endDate);
    }
    if (queryParams.tags) {
        urlParams.set("tags", queryParams.tags);
    }

    return urlParams;
};

export interface ElasticDateHistoAggRes {
    key: string;
    key_as_string: string;
    doc_count: number;
}

export const processLineChartData = function (res: any) {
    let avg = res && res?.aggregations && res?.aggregations?.avg_incidents && res?.aggregations?.avg_incidents?.value;
    return (
        res &&
        res?.aggregations &&
        res?.aggregations?.over_time &&
        res?.aggregations?.over_time?.buckets &&
        res?.aggregations?.over_time?.buckets?.reduce(
            (coll: { labels: [string]; counts: [number]; avg: [number] }, curr: ElasticDateHistoAggRes) => {
                coll.labels.push(curr.key_as_string);
                coll.counts.push(curr.doc_count);
                coll.avg.push(avg);
                return coll;
            },
            { labels: [], counts: [], avg: [] }
        )
    );
};
