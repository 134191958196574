import { Alert, Col, Container, OverlayTrigger, Row, Stack, Table, Tooltip } from "react-bootstrap";
import SortableHeaderColumn from "../../components/SortableHeader";
import { useColumnSort } from "../../hooks/useColumnSort";
import { EvidenceType, SortDirectionEnum } from "../../types/types";
import { formatDate } from "../../utils/date";
import { sortSortableHeader } from "../../utils/sort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { faCircle, faFileArchive, faFileAudio, faVideo, faImage } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import FMSpinner from "../../components/Spinner";
import { usePagination } from "../../hooks/usePagination";
import { FMPaginationNonLinks } from "../../components/pagination/Pagination";

const DEFAULT_SORT = "uploaded_on";
const DEFAULT_SORT_ORDER = SortDirectionEnum.DESCENDING;

export const filterEvidence = (singleEvidence: EvidenceType, filterString: string) => {
    if (!filterString) {
        return true;
    }
    const lowercaseFilter = filterString.toLowerCase();
    const includedFilterFields = ["title", "owner", "uploaded_by", "duration", "id", "categories"];
    for (const key in singleEvidence) {
        if (includedFilterFields.includes(key)) {
            if (singleEvidence[key]?.toString()?.toLowerCase()?.includes(lowercaseFilter)) {
                return true;
            }
        }
    }
    return false;
};

type OwnProps = {
    incident: any;
    evidence: EvidenceType[];
    isEvidenceFetching: boolean;
    pageReset: any;
    scrollToTop: any;
};

// NOTE: These highlight components and functions are not the same highligting functions as we have elsewhere.
// These are specific to this component, do not expand these at all!
// Unless a specific JIRA ticket is created to do so.
const defaultHighlight = (s: string) => <b>{s}</b>;

const HighlighterSplit = ({ text, highlight }: { text: string; highlight: RegExp }) => {
    const splitText = [];
    let lastOffset = 0;

    text.replace(highlight, (val, offset) => {
        splitText.push(text.slice(lastOffset, offset), defaultHighlight(val));
        lastOffset = offset + val.length;
        return val;
    });

    splitText.push(text.slice(lastOffset));

    return splitText;
};

const HighlightMatchIncidentNumber = ({ incidentNumber = "", externalId = "" }: { incidentNumber: string; externalId: string }) => {
    const incidentNumberSplitHyphen = incidentNumber?.split("-");
    const incidentNumberJoined = incidentNumberSplitHyphen?.join("");

    const matchOriginal = new RegExp(incidentNumber, "gi");
    const matchJoined = new RegExp(incidentNumberJoined, "gi");

    let resp = HighlighterSplit({ text: externalId, highlight: matchOriginal });
    if (resp.length === 1) {
        resp = HighlighterSplit({ text: externalId, highlight: matchJoined });
    }

    return (
        <span>
            {resp.map((part, index) => (
                <span key={index}>{part}</span>
            ))}
        </span>
    );
};

const EvidenceView = ({ incident, evidence, isEvidenceFetching, pageReset, scrollToTop }: OwnProps) => {
    const [filterString, setFilterString] = React.useState("");
    const { sortColumn, sortDirection, setSort } = useColumnSort(DEFAULT_SORT, DEFAULT_SORT_ORDER);

    const sortedEvidence = [...evidence]
        .filter((singleEvidence) => filterEvidence(singleEvidence, filterString))
        .sort(sortSortableHeader(sortColumn, true, sortDirection));

    const reset = React.useMemo(
        () => ({ ...pageReset, sortColumn, sortDirection, filterString }),
        [pageReset, sortDirection, sortColumn, filterString]
    );

    const { currentPage, currentPageData, totalPages, setCurrentPage } = usePagination(sortedEvidence || [], 10, reset, scrollToTop);

    const iconMap: { [key: string]: IconDefinition } = {
        audio: faFileAudio,
        video: faVideo,
        document: faFileArchive,
        image: faImage,
        firingLog: faCircle,
        masterCopy: faCircle,
    };

    const incidentNumber = incident?.source?.incident_number || "";

    return isEvidenceFetching ? (
        <FMSpinner />
    ) : evidence.length > 0 ? (
        <Container fluid>
            <Row>
                <Col>
                    <input
                        data-testid="filter-evidence-input"
                        className="form-control pendo_filter_evidence_input mb-3"
                        name="filter-evidence-input"
                        onChange={(event) => setFilterString(event.target.value)}
                        placeholder="Filter by text"
                    />
                </Col>
            </Row>
            <Row>
                <Table size="sm" responsive>
                    <thead>
                        <tr>
                            <th />
                            <SortableHeaderColumn
                                columnName="Title"
                                columnClass="pendo_evidence_table_title_header"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey="title"
                                defaultDirection={SortDirectionEnum.ASCENDING}
                            />
                            <SortableHeaderColumn
                                columnName="Owner"
                                columnClass="pendo_evidence_table_owner_header"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey="owner"
                                defaultDirection={SortDirectionEnum.ASCENDING}
                            />
                            <SortableHeaderColumn
                                columnName="Uploaded By"
                                columnClass="pendo_evidence_table_uploaded_by_header"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey="uploaded_by"
                                defaultDirection={SortDirectionEnum.ASCENDING}
                            />
                            <SortableHeaderColumn
                                columnName="Recorded On"
                                columnClass="pendo_evidence_table_recorded_on_header"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey="recorded_on"
                                defaultDirection={SortDirectionEnum.DESCENDING}
                            />
                            <SortableHeaderColumn
                                columnName="Uploaded On"
                                columnClass="pendo_evidence_table_uploaded_on_header"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey="uploaded_on"
                                defaultDirection={DEFAULT_SORT_ORDER}
                            />
                            <SortableHeaderColumn
                                columnName="Duration"
                                columnClass="pendo_evidence_table_duration_header"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey="duration"
                                defaultDirection={SortDirectionEnum.DESCENDING}
                            />
                        </tr>
                    </thead>
                    <tbody data-testid="evidence-table-body">
                        {currentPageData.map((singleEvidence) => {
                            const duration = isNaN(parseFloat(singleEvidence.duration))
                                ? "--"
                                : `${Math.round(parseFloat(singleEvidence.duration))}s`;
                            return (
                                <React.Fragment key={singleEvidence.id}>
                                    <tr>
                                        <td className="border-bottom-0">
                                            <OverlayTrigger
                                                overlay={<Tooltip id="evidence-icon-tooltip">{singleEvidence.file_type}</Tooltip>}
                                                placement="top"
                                                trigger={["hover", "click", "focus"]}
                                                rootClose
                                            >
                                                <FontAwesomeIcon icon={iconMap[(singleEvidence?.file_type as string) || ""] || faCircle} />
                                            </OverlayTrigger>
                                        </td>
                                        <td className="border-bottom-0 text-nowrap pe-3">
                                            <OverlayTrigger
                                                overlay={<Tooltip id="evidence-title-tooltip">{singleEvidence?.title || "--"}</Tooltip>}
                                                placement="top"
                                                trigger={["hover", "focus"]}
                                                rootClose
                                            >
                                                <a target="_blank" rel="noreferrer" href={singleEvidence?.url || ""}>
                                                    <span className="d-block d-md-none text-truncate" style={{ maxWidth: "250px" }}>
                                                        {singleEvidence?.title || "--"}
                                                    </span>
                                                    <span className="d-none d-md-block text-truncate" style={{ width: "200px" }}>
                                                        {singleEvidence?.title || "--"}
                                                    </span>
                                                </a>
                                            </OverlayTrigger>
                                        </td>
                                        <td className="border-bottom-0 text-nowrap pe-3">
                                            <OverlayTrigger
                                                overlay={<Tooltip id="evidence-owner-tooltip">{singleEvidence?.owner || "--"}</Tooltip>}
                                                placement="top"
                                                trigger={["hover", "click", "focus"]}
                                                rootClose
                                            >
                                                <span>
                                                    <span className="d-block d-md-none text-truncate" style={{ maxWidth: "250px" }}>
                                                        {singleEvidence?.owner || "--"}
                                                    </span>
                                                    <span className="d-none d-md-block text-truncate" style={{ width: "125px" }}>
                                                        {singleEvidence?.owner || "--"}
                                                    </span>
                                                </span>
                                            </OverlayTrigger>
                                        </td>
                                        <td className="border-bottom-0 text-nowrap pe-3">
                                            <OverlayTrigger
                                                overlay={<Tooltip id="evidence-uploaded-by-tooltip">{singleEvidence?.uploaded_by}</Tooltip>}
                                                placement="top"
                                                trigger={["hover", "click", "focus"]}
                                                rootClose
                                            >
                                                <span>
                                                    <span className="d-block d-md-none text-truncate" style={{ maxWidth: "250px" }}>
                                                        {singleEvidence?.uploaded_by || "--"}
                                                    </span>
                                                    <span className="d-none d-md-block text-truncate" style={{ width: "125px" }}>
                                                        {singleEvidence?.uploaded_by || "--"}
                                                    </span>
                                                </span>
                                            </OverlayTrigger>
                                        </td>
                                        <td className="border-bottom-0 text-nowrap pe-3 text-end">
                                            <Stack direction="vertical">
                                                <span>{formatDate(singleEvidence.recorded_on, "--", "MM/dd/yyyy")}</span>
                                                <span>{formatDate(singleEvidence.recorded_on, "--", "h:mm aaa")}</span>
                                            </Stack>
                                        </td>
                                        <td className="border-bottom-0 text-nowrap pe-3 text-end">
                                            <Stack direction="vertical">
                                                <span>{formatDate(singleEvidence.uploaded_on, "--", "MM/dd/yyyy")}</span>
                                                <span>{formatDate(singleEvidence.uploaded_on, "--", "h:mm aaa")}</span>
                                            </Stack>
                                        </td>
                                        <td className="border-bottom-0 text-nowrap pe-3 text-end">{duration}</td>
                                    </tr>
                                    <tr>
                                        <td />
                                        <td colSpan={6}>
                                            <span className="fw-bold">Categories:&nbsp;</span>
                                            {singleEvidence?.categories?.join(", ") || "N/A"}
                                            <br />
                                            <span className="text-muted fs-5">
                                                <span>ID:&nbsp;</span>
                                                <HighlightMatchIncidentNumber
                                                    incidentNumber={incidentNumber}
                                                    externalId={singleEvidence?.external_id}
                                                />
                                            </span>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Col xs={12} md={{ span: 8, offset: 2 }} className="mt-3">
                    <FMPaginationNonLinks
                        page={currentPage + 1}
                        setPage={(page: number) => setCurrentPage(page - 1)}
                        totalPages={totalPages}
                        scrollToId={pageReset?.scrollToId}
                    />
                </Col>
            </Row>
        </Container>
    ) : (
        <Alert variant="warning">No evidence to view.</Alert>
    );
};

export default EvidenceView;
