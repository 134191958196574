import banner from "../../../static/img/brand3.png";
import bannerWhite from "../../../static/img/brand.svg";
import { Image } from "react-bootstrap";
import "./logo.css";

const Logo = (props: any) => {
    const computedClassName = props?.className || "";
    return (
        <>
            <Image {...props} className={`${computedClassName} logo light-mode`} src={banner} alt="FM Logo" />
            <Image {...props} className={`${computedClassName} logo dark-mode`} src={bannerWhite} alt="FM Logo" />
        </>
    );
};

export default Logo;
