import React from "react";
import { Image } from "react-bootstrap";
import streets from "../../static/img/streets.png";
import light from "../../static/img/light.png";
import outdoor from "../../static/img/outdoor.png";
import dark from "../../static/img/dark.png";
import satellite from "../../static/img/satellite.png";
import { MapStyles } from "../../types/old_v1/types";

type OwnProps = {
    selectedStyle: string;
    setSelectedStyle: React.Dispatch<React.SetStateAction<MapStyles>>;
};

/**
 * Component for picking the base map
 * @param {string} selectedStyle State variable passed from parent component that tracks which style has been selected
 * @param {React.Dispatch<React.SetStateAction<MapStyles>>} setSelectedStyle selectedStyle state setter
 * @returns {<BaseMapSelector />}
 */

const BaseMapSelector = ({ selectedStyle, setSelectedStyle }: OwnProps) => {
    const [layersExpanded, setLayersExpanded] = React.useState<boolean>(false);

    return (
        <div className={"position-absolute d-flex map-app-layer-container"}>
            <div
                className="position-relative border border-secondary rounded cursor-pointer"
                style={{ height: "75px", width: "75px" }}
                onClick={() => setLayersExpanded(!layersExpanded)}
            >
                <Image className="rounded" src={streets} height={73} width={73} />
            </div>
            {layersExpanded ? (
                <div className="ms-4 border rounded d-flex" style={{ height: "75px" }} onClick={() => setLayersExpanded(false)}>
                    <div className="pt-2 ps-3" onClick={() => setSelectedStyle("streets")} data-testid="streets-layer-option">
                        <Image
                            className={`rounded cursor-pointer ${selectedStyle === "streets" ? "border border-dark border-2" : null}`}
                            src={streets}
                            height={48}
                            width={48}
                        />
                        <div className="text-center ">
                            <small>Streets</small>
                        </div>
                    </div>
                    <div className="pt-2 ps-3" onClick={() => setSelectedStyle("satellite")} data-testid="satellite-layer-option">
                        <Image
                            className={`rounded cursor-pointer ${selectedStyle === "satellite" ? "border border-dark border-2" : null}`}
                            src={satellite}
                            height={48}
                            width={48}
                        />
                        <div className="text-center ">
                            <small>Satellite</small>
                        </div>
                    </div>
                    <div className="pt-2 ps-3" onClick={() => setSelectedStyle("light")} data-testid="light-layer-option">
                        <Image
                            className={`rounded cursor-pointer ${selectedStyle === "light" ? "border border-dark border-2" : null}`}
                            src={light}
                            height={48}
                            width={48}
                        />
                        <div className="text-center ">
                            <small>Light</small>
                        </div>
                    </div>
                    <div className="pt-2 ps-3" onClick={() => setSelectedStyle("dark")} data-testid="dark-layer-option">
                        <Image
                            className={`rounded cursor-pointer ${selectedStyle === "dark" ? "border border-dark border-2" : null}`}
                            src={dark}
                            height={48}
                            width={48}
                        />
                        <div className="text-center ">
                            <small>Dark</small>
                        </div>
                    </div>
                    <div className="pt-2 ps-3 pe-3" onClick={() => setSelectedStyle("outdoor")} data-testid="outdoor-layer-option">
                        <Image
                            className={`rounded cursor-pointer ${selectedStyle === "outdoor" ? "border border-dark border-2" : null}`}
                            src={outdoor}
                            height={48}
                            width={48}
                        />
                        <div className="text-center ">
                            <small>Outdoor</small>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default BaseMapSelector;
