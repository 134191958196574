import { Alert, Table } from "react-bootstrap";
import SortableHeaderColumn from "../../components/SortableHeader";
import { useColumnSort } from "../../hooks/useColumnSort";
import { SortDirectionEnum } from "../../types/types";
import { sortSortableHeader } from "../../utils/sort";
import FMSpinner from "../../components/Spinner";
import "./OffensesView.css";

type OwnProps = {
    isLoading: boolean;
    offenses: {
        offense_code: string;
        offense_desc: string;
    }[];
};

const OffensesView = ({ isLoading, offenses }: OwnProps) => {
    const { sortColumn, sortDirection, setSort } = useColumnSort("", SortDirectionEnum.ASCENDING);
    const sortedOffenses = (Array.isArray(offenses) ? [...offenses] : []).sort(
        sortSortableHeader(sortColumn, ["offense_code", "offense_desc"].includes(sortColumn), sortDirection)
    );

    return isLoading ? (
        <div className="d-flex">
            <FMSpinner centered />
        </div>
    ) : offenses.length ? (
        <Table>
            <thead className="fm-bg-color">
                <tr>
                    <SortableHeaderColumn
                        columnClass="fw-medium"
                        columnName="Offense Code"
                        sortFn={setSort}
                        activeSortField={sortColumn}
                        activeSortDirection={sortDirection}
                        sortKey="offense_code"
                        defaultDirection={SortDirectionEnum.ASCENDING}
                    />
                    <SortableHeaderColumn
                        columnClass="fw-medium"
                        columnName="Description"
                        sortFn={setSort}
                        activeSortField={sortColumn}
                        activeSortDirection={sortDirection}
                        sortKey="offense_desc"
                        defaultDirection={SortDirectionEnum.ASCENDING}
                    />
                </tr>
            </thead>
            <tbody className="border-top">
                {sortedOffenses.map((offense) => (
                    // Using both values to avoid any possible duplicate keys
                    <tr key={`${offense?.offense_code}-${offense?.offense_desc}`}>
                        <td>{offense?.offense_code || "--"}</td>
                        <td>{offense?.offense_desc || "--"}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    ) : (
        <Alert className="mt-3" variant="warning">
            No offenses.
        </Alert>
    );
};

export default OffensesView;
