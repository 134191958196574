import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDarkMode } from "../../../hooks/useDarkMode";
import { handleHighChartDarkMode } from "../../../utils/helpers";

interface HistogramProcessedDataType {
    labels: [string];
    counts: [number];
}

interface HistogramProps {
    title: string;
    processedData: HistogramProcessedDataType;
    xAxis: string;
    yAxis: string;
    onPointClick?: any;
}

/**
 * Component for a wrapper around Highcharts histogram
 */
const Histogram = ({ title, processedData, xAxis, yAxis, onPointClick }: HistogramProps) => {
    const { isDarkMode } = useDarkMode();
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const options = {
        chart: {
            type: "bar",
        },
        title: {
            text: title,
            style: {
                fontWeight: "bold",
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    style: {
                        textOverflow: "ellipsis",
                    },
                },
            },
        },
        xAxis: {
            categories: processedData.labels,
            title: {
                text: xAxis,
            },
            labels: {
                style: {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: 100,
                },
            },
        },
        yAxis: {
            title: {
                text: yAxis,
            },
            allowDecimals: false,
        },
        tooltip: {
            formatter(this: any) {
                return this.y;
            },
            shared: true,
        },
        series: [
            {
                type: "bar",
                data: processedData.counts,
                showInLegend: false,
                cursor: onPointClick ? "pointer" : "auto",
                ...(onPointClick
                    ? {
                          point: {
                              events: {
                                  click: onPointClick,
                              },
                          },
                      }
                    : {}),
            },
        ],
    };
    handleHighChartDarkMode(options, isDarkMode);
    return (
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} containerProps={{ style: { height: "100%" } }} />
    );
};

export default React.memo(Histogram);
