import { useAppSelector } from "../app/hooks";

const useIntegrations = () => {
    const user = useAppSelector(({ user }) => user?.userObject);
    const axonEvidenceEnabled = user?.agency_feature_axonevidence || false;
    const personPhotosEnabled = user?.agency_feature_personphotos || false;

    return {
        axonEvidenceEnabled,
        personPhotosEnabled,
    };
};

export default useIntegrations;
