// v1 api call
import { useGetCallQuery, useGetIncidentQuery } from "../../api/api";
import { elasticHitsExtraction } from "../../utils/elastic";
// v1 component
import { EventCardBody } from "./event/EventCard";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import { faTimes, faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Offcanvas, Button, Accordion } from "react-bootstrap";
import CallNotesConnected from "./callnotes/CallNotes";
import NarrativesConnected from "./narratives/Narratives";
import useAgencySettings from "../../hooks/useAgencySettings";

const RadarEventPreviewPanel = ({ selectedEvent, setSelectedEvent }: { selectedEvent: any; setSelectedEvent: (event: any) => void }) => {
    const { getAgencyUUID } = useMultiAgency();
    const { callText } = useAgencySettings();
    const { constructEventUrl } = useMultiAgency();

    // check if selected event is a call or incident in order to construct the correct link
    const selectedEventIsCall = selectedEvent && selectedEvent._index.includes("_calls");
    const selectedEventCallNumber = selectedEvent
        ? Array.isArray(selectedEvent._source.call_number)
            ? selectedEvent._source.call_number[0]
            : selectedEvent._source.call_number
        : "";

    const selectedEventLink = selectedEvent
        ? selectedEventIsCall
            ? // if call, construct url with call number
              constructEventUrl(selectedEvent?._source?.agency_id, selectedEventCallNumber, undefined, undefined)
            : // if incident, construct url with incident number
              constructEventUrl(selectedEvent?._source?.agency_id, undefined, selectedEvent?.incident_number, undefined)
        : null;

    //check if call number is array from an incident or if we need to query for incident using the calls call number
    const callId =
        selectedEvent?.call_number && Array.isArray(selectedEvent?.call_number)
            ? selectedEvent?.call_number[0]
            : selectedEvent?.call_number;

    // check if incident number is populated in order to query for incident using the calls call number
    const incidentParams = selectedEvent?.incident_number
        ? { incident_id: selectedEvent?.incident_number, agencyUUID: getAgencyUUID(selectedEvent?.agency_id) }
        : { id: callId, agencyUUID: getAgencyUUID(selectedEvent?.agency_id) };

    // query for call using call number
    const { data: callData } = useGetCallQuery({ id: callId, agencyUUID: getAgencyUUID(selectedEvent?.agency_id) }, { skip: !callId });

    // query for incident using incident number
    const { data: incidentData } = useGetIncidentQuery(incidentParams, { skip: !callId && !selectedEvent?.incident_number });

    let eventPopulatedWithCallAndIncident = {
        ...selectedEvent,
    };
    if (callData) {
        const hits = elasticHitsExtraction(callData);
        const hit = hits && hits.length && hits[0];
        if (hit) {
            // include the call data in the event
            // formatting call as needed for EventCardBody
            eventPopulatedWithCallAndIncident.call = { ...hit, ...hit?._source, source: hit?._source };
        }
    }

    if (incidentData) {
        const hits = elasticHitsExtraction(incidentData);
        const hit = hits && hits.length && hits[0];
        if (hit) {
            // include the incident data in the event
            // formatting incident as needed for EventCardBody
            eventPopulatedWithCallAndIncident.incident = { ...hit, ...hit?._source, source: hit?._source };
        }
    }

    return (
        <Offcanvas style={{ minWidth: "40%" }} show={!!selectedEvent} onHide={() => setSelectedEvent(null)} placement="end">
            <Offcanvas.Header>
                <div className="d-flex flex-row-reverse justify-content-between" style={{ width: "100%" }}>
                    <div className="align-self-center">
                        <FontAwesomeIcon className="cursor-pointer" icon={faTimes} onClick={() => setSelectedEvent(null)} />
                    </div>
                    {!!selectedEventLink && (
                        <a href={selectedEventLink} target="_blank" rel="noreferrer">
                            <Button variant="primary" size="sm">
                                <FontAwesomeIcon className="cursor-pointer" icon={faUpRightFromSquare} />
                                &nbsp;Go To
                            </Button>
                        </a>
                    )}
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Accordion defaultActiveKey={["event", "callNotes", "narratives"]} alwaysOpen>
                    <Accordion.Item eventKey="event">
                        <Accordion.Header>Event Information</Accordion.Header>
                        <Accordion.Body className="px-0">
                            <EventCardBody event={eventPopulatedWithCallAndIncident} />
                        </Accordion.Body>
                    </Accordion.Item>
                    {eventPopulatedWithCallAndIncident?.call?._source?.call_number && (
                        <Accordion.Item eventKey="callNotes">
                            <Accordion.Header>{callText} Notes</Accordion.Header>
                            <Accordion.Body>
                                <CallNotesConnected
                                    callId={eventPopulatedWithCallAndIncident?.call?._source?.call_number}
                                    modalOption={false}
                                    agencyId={eventPopulatedWithCallAndIncident?.call?._source?.agency_id}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                    {eventPopulatedWithCallAndIncident?.incident?._source?.incident_number && (
                        <Accordion.Item eventKey="narratives">
                            <Accordion.Header>Narratives</Accordion.Header>
                            <Accordion.Body>
                                <NarrativesConnected
                                    incidentId={eventPopulatedWithCallAndIncident?.incident?._source?.incident_number}
                                    modalOption={false}
                                    agencyId={eventPopulatedWithCallAndIncident?.incident?._source?.agency_id}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                </Accordion>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default RadarEventPreviewPanel;
