import { InsightsDateViewEnum } from "../types/old_v1/types";
import { calculateDates } from "../components/filters/DateRange";
import { differenceInDays, parseISO } from "date-fns";

export const calculateEnabledDateViews = (dateRange: string, startDateParam: string, endDateParam: string, now: Date) => {
    const { startDate, endDate } = calculateDates(dateRange, startDateParam, endDateParam, now);
    const parsedStart = parseISO(startDate);
    const parsedEnd = parseISO(endDate);
    const diffInDays = differenceInDays(parsedEnd, parsedStart);

    return {
        [InsightsDateViewEnum.WEEK]: diffInDays > 7,
        [InsightsDateViewEnum.MONTH]: diffInDays > 30,
        [InsightsDateViewEnum.YEAR]: diffInDays > 365,
    };
};
