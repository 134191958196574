import React, { ComponentType } from "react";
import ErrorComponent from "./ErrorComponent";
import { ErrorComponentVariantType } from "../../../types/types";
import { connect } from "react-redux";
import { User } from "../../../app/slices/user/types";
import { RootState } from "../../../app/store";

type Props = {
    children: any;
    user: User;
    errorComponentVariant?: ErrorComponentVariantType;
};

type State = {
    hasError: boolean;
};

const baseUrl = window?.__RUNTIME_CONFIG__?.REACT_APP_DJANGO_BASE_URL;

// This component has been created using the guide here:
// https://reactjs.org/docs/error-boundaries.html
// Currently, there are not hooks to handle the
// getDerivedStateFromError and componentDidCatch functions

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        // eslint-disable-next-line no-console
        console.log("error", error);
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // log the error to an error reporting service here
        const user = this.props.user;
        const userMessage = `${
            user.userObject?.id
                ? `| Agency: ${user.userObject?.agency_shortname} | ID: ${user.userObject?.id} | Name: ${user.userObject?.full_name} | Status: ${user?.status}`
                : ""
        }`;
        const message = `${window?.location}\n| ${navigator?.userAgent}\n| ${error.message}\n${userMessage}`;
        const body = {
            msg: message,
            error,
            errorInfo,
        };

        // No reason for this api call to be cached in RTKQ nor do we need to handle any errors if it fails.
        try {
            fetch(`${baseUrl}/v2/misc/report-error`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });
        } catch (e) {
            console.error(e);
        }

        // eslint-disable-next-line no-console
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorComponent variant={this.props.errorComponentVariant} />;
        }

        return this.props.children;
    }
}

const mapStateToProps = (state: RootState) => ({
    user: state.user,
});

export default connect(mapStateToProps)(ErrorBoundary as ComponentType<any>);
