import FMSpinner from "../../components/Spinner";
import BaseCallNotes from "./callnotes/BaseCallNotes";

type OwnProps = {
    callNotes: any[];
    isLoading: boolean;
    isTruncated?: boolean;
    setCurrentTab?: () => void;
};
const CallNotesView = ({ callNotes, isLoading, isTruncated = false, setCurrentTab = () => null }: OwnProps) => {
    return !isLoading ? (
        <BaseCallNotes callNotes={callNotes} isTruncated={isTruncated} setCurrentTab={setCurrentTab} />
    ) : (
        <div className="text-center">
            <FMSpinner />
        </div>
    );
};

export default CallNotesView;
