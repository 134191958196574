import { Card } from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import { useSearchParams } from "react-router-dom";
import { useGetOfficerIncidentsByDayQuery } from "../../../api/api";
import { getQueryParams } from "../../../utils/url";
import InsightsSpinner from "../../insightsdashboard/insightsspinner/InsightsSpinner";
import { useDarkMode } from "../../../hooks/useDarkMode";
import { handleHighChartDarkMode } from "../../../utils/helpers";
import { getHeatmapMax, heatmapBaseConfig, heatmapStops, initializeHighchartsHeatmap } from "../../insightsdashboard/heatmap";
import { getByDaySearchParams } from "../../insightsdashboard/insights";
import useAgencySettings from "../../../hooks/useAgencySettings";
import { useFilters } from "../../../hooks/useFilters";

const initializedHighcharts = initializeHighchartsHeatmap();

const IncidentsByDay = () => {
    const [searchParams] = useSearchParams();
    const queryParams = getQueryParams(searchParams);
    const { beatsIsFetching, filterPayload } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });
    const { data: incidentsByDayResponse, isFetching } = useGetOfficerIncidentsByDayQuery(
        {
            ...queryParams,
            filters: filterPayload,
        },
        { skip: beatsIsFetching }
    );
    const { incidentTextPlural } = useAgencySettings();
    const { isDarkMode } = useDarkMode();

    let processedData = incidentsByDayResponse?.data?.processed_data ? [...incidentsByDayResponse.data.processed_data] : [[]];
    const months = incidentsByDayResponse?.data?.months || [];

    const options = {
        chart: {
            type: "heatmap",
            plotBorderWidth: 1,
        },
        legend: false,
        title: {
            text: "",
        },
        colorAxis: {
            stops: heatmapStops,
            max: getHeatmapMax(processedData),
        },
        xAxis: {
            min: 1,
            max: 31,
            tickInterval: 1,
            title: {
                text: "Date",
            },
        },
        yAxis: {
            categories: months,
            title: {
                text: "Month and Year",
            },
            reversed: true,
        },
        tooltip: {
            formatter(this: any) {
                return `${incidentTextPlural}: ${this.point.value}`;
            },
            shared: true,
        },
        series: [
            {
                ...heatmapBaseConfig,
                name: { incidentTextPlural },
                point: {
                    events: {
                        click: (e: any) => {
                            const newSearchParams = getByDaySearchParams(e, searchParams, months);
                            newSearchParams.set("indexType", "incidents");
                            window.open(`/app/search?${newSearchParams}`, "_blank");
                        },
                    },
                },
                data: processedData,
            },
        ],
    };

    handleHighChartDarkMode(options, isDarkMode);

    return (
        <Card>
            <Card.Header>
                <Card.Title className="m-0" as="h3">
                    {incidentTextPlural} Per Month and Date
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {isFetching ? (
                    <InsightsSpinner />
                ) : (
                    processedData && <HighchartsReact highcharts={initializedHighcharts} options={options} />
                )}
            </Card.Body>
        </Card>
    );
};

export default IncidentsByDay;
