import { useLocation } from "react-router-dom";

export function useUpdateUrl(url: string) {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const updateUrl = (name?: string, value?: string | number) => {
        if (name && value) {
            searchParams.delete(name);
            searchParams.append(name, typeof value === "number" ? value.toString() : value);
        }
        return `${url}?${searchParams.toString()}`;
    };

    return updateUrl;
}
