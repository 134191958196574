import { InsightsDateViewEnum } from "../../types/types";
import { calculateDates } from "../../components/filters/DateRange";
import { differenceInDays, parseISO, sub } from "date-fns";
import { formatDateToISO, getMonthNumber } from "../../utils/date";

export const calculateEnabledDateViews = (dateRange: string, startDateParam: string, endDateParam: string, now: Date) => {
    const { startDate, endDate } = calculateDates(dateRange, startDateParam, endDateParam, now);
    const parsedStart = parseISO(startDate);
    const parsedEnd = parseISO(endDate);
    const diffInDays = differenceInDays(parsedEnd, parsedStart);

    return {
        [InsightsDateViewEnum.WEEK]: diffInDays > 7,
        [InsightsDateViewEnum.MONTH]: diffInDays > 30,
        [InsightsDateViewEnum.YEAR]: diffInDays > 365,
    };
};

export const getByHourSearchParams = (e: any, latestDateString: string | null, searchParams: URLSearchParams) => {
    const newSearchParams = new URLSearchParams(searchParams);
    const now = new Date();
    const nowString = formatDateToISO(now);
    const startDate = sub(now, { days: (!!latestDateString && latestDateString > nowString ? 5 : 6) - e.point.y });

    const startTime = parseInt(e.point.x);
    newSearchParams.set("timeRange", `${startTime}-${(startTime + 1) % 24}`);
    newSearchParams.set("dateRange", "custom");
    newSearchParams.set("startDate", formatDateToISO(startDate));
    newSearchParams.set("endDate", formatDateToISO(startDate));

    return newSearchParams;
};

export const getByTimeBlockSearchParams = (e: any, latestDateString: string | null, searchParams: URLSearchParams) => {
    const newSearchParams = new URLSearchParams(searchParams);
    const now = new Date();
    const nowString = formatDateToISO(now);
    const startDate = sub(now, { days: (!!latestDateString && latestDateString > nowString ? 5 : 6) - e.point.y });

    // last block is from 16 to 0 since there is no hour 24
    const timeBlockStringMap = ["0-8", "8-16", "16-0"];
    newSearchParams.set("timeRange", timeBlockStringMap[e.point.x]);
    newSearchParams.set("dateRange", "custom");
    newSearchParams.set("startDate", formatDateToISO(startDate));
    newSearchParams.set("endDate", formatDateToISO(startDate));

    return newSearchParams;
};

export const getByDaySearchParams = (e: any, searchParams: URLSearchParams, months: string[]) => {
    const newSearchParams = new URLSearchParams(searchParams);
    const navDay = `${e.point.x < 10 ? "0" : ""}${e.point.x}`;
    const monthYear = months[e.point.y].split(" ");
    const navMonth = getMonthNumber(monthYear[0]);
    const navYear = monthYear[1];
    const date = `${navYear}-${navMonth}-${navDay}`;

    newSearchParams.set("dateRange", "custom");
    newSearchParams.set("startDate", date);
    newSearchParams.set("endDate", date);

    return newSearchParams;
};
