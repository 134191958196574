import { Card } from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import { useGetIncidentsByTimeBlockQuery } from "../../api/api";
import { getDayString } from "../../utils/date";
import InsightsSpinner from "./insightsspinner/InsightsSpinner";
import { useDarkMode } from "../../hooks/useDarkMode";
import { handleHighChartDarkMode } from "../../utils/helpers";
import { getHeatmapMax, heatmapBaseConfig, heatmapStops, initializeHighchartsHeatmap } from "./heatmap";
import { getByTimeBlockSearchParams } from "./insights";
import useInsights from "../../hooks/useInsights";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";
import useAgencySettings from "../../hooks/useAgencySettings";
import { useFilters } from "../../hooks/useFilters";

const initializedHighcharts = initializeHighchartsHeatmap();

const IncidentsByTimeBlock = () => {
    const { searchParams } = useInsights();
    const { queryParams } = useSingleAgencyFilter();
    const { filterPayload, beatsIsFetching } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });
    const { data, isLoading, isFetching } = useGetIncidentsByTimeBlockQuery(
        { ...queryParams, filters: filterPayload },
        { skip: beatsIsFetching }
    );
    const { incidentTextPlural } = useAgencySettings();
    let days = [] as string[];
    const { isDarkMode } = useDarkMode();
    let latestDateString: string | null = null;

    let processedData = null;
    if (data && !isLoading) {
        const buckets =
            data && data?.aggregations && data?.aggregations?.incidents_over_time && data?.aggregations?.incidents_over_time?.buckets;

        if (buckets && buckets.length) {
            latestDateString = buckets[buckets.length - 1]?.key_as_string?.split(" ")[0];
            processedData = buckets.map((bucket: any) => {
                const parts = bucket.key_as_string.split(" ");
                const dayString = getDayString(new Date(parts[0]).getUTCDay());
                if (!days.includes(dayString)) {
                    days.push(dayString);
                }
                const day = days.length - 1;
                let hour = parseInt(parts[1]);
                if (hour !== 0) {
                    hour = hour === 8 ? 1 : 2;
                }
                return [hour, day, bucket.doc_count];
            });
        }
    }

    const options = {
        chart: {
            type: "heatmap",
            plotBorderWidth: 1,
        },
        legend: false,
        title: {
            text: "",
        },
        colorAxis: {
            stops: heatmapStops,
            max: getHeatmapMax(processedData),
        },
        xAxis: {
            min: 0,
            max: 2,
            tickInterval: 1,
            categories: ["00:00-08:00", "08:00-16:00", "16:00-24:00"],
            title: {
                text: "Time Block",
            },
        },
        yAxis: {
            categories: days,
            title: {
                text: "Days",
            },
            reversed: true,
        },
        tooltip: {
            formatter(this: any) {
                return `<b>${incidentTextPlural}: ${this.point.value}</b>`;
            },
            shared: true,
        },
        series: [
            {
                ...heatmapBaseConfig,
                name: incidentTextPlural,
                data: processedData,
                point: {
                    events: {
                        click: (e: any) => {
                            const newSearchParams = getByTimeBlockSearchParams(e, latestDateString, searchParams);
                            newSearchParams.set("indexType", "incidents");
                            window.open(`/app/search?${newSearchParams}`, "_blank");
                        },
                    },
                },
            },
        ],
    };

    handleHighChartDarkMode(options, isDarkMode);

    return (
        <Card>
            <Card.Header>
                <Card.Title className="m-0" as="h3">
                    {incidentTextPlural} Per Day and Time Block
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {isFetching ? (
                    <InsightsSpinner />
                ) : (
                    processedData && <HighchartsReact highcharts={initializedHighcharts} options={options} />
                )}
            </Card.Body>
        </Card>
    );
};

export default IncidentsByTimeBlock;
