import { LayerProps } from "react-map-gl";
import { Entities, FilterParamNames } from "../../constants/constants";

export type FromES<T> = T & { [key: string]: unknown };

export type UserShare = {
    id: string;
    shared_at: string;
    uuid_url: string;
    viewed_at: string;
    title: string;
    shared_by_email: string;
};

export type Share = {
    id: string;
    shared_at: string;
    url: string;
    title: string;
    shared_with: UserShare[];
};

export type SearchHistory = {
    id: string;
    user_first_name: string;
    user_last_name: string;
    user_email: string;
    saved_date: string;
    body: any;
};

export enum ResultTypesEnum {
    incident = "incident",
    address = "address",
    person = "person",
    call = "call",
    all = "all",
}

export enum LayerTypes {
    call = "call",
    address = "address",
    incident = "incident",
    person = "person",
    event = "event",
    list = "list",
    clickList = "clickList",
    none = "",
}

export type MapStyles = "streets" | "outdoor" | "light" | "dark" | "satellite";

export enum SettingsTabTypes {
    personalInformation = "personalInformation",
    password = "password",
    mfa = "mfa",
    lightDarkMode = "lightDarkMode",
}

export type SourceConfig = {
    data: any;
    id: string;
    cluster?: boolean;
    layers: LayerProps[];
    setBounds: boolean;
    clusterProperties?: any;
};

export declare type BoundingBox = {
    top_left: number[];
    bottom_right: number[];
};

export type SearchQueryParams = {
    agencies?: string | null;
    query: string | null;
    page: string | null;
    pageSize: string | null;
    tags: string | null;
    sort: string | null;
    dateRange?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    offenseCodes?: string | null;
    stateStatutes?: string | null;
    race?: string | null;
    gender?: string | null;
    bounding_box?: BoundingBox | undefined;
    selectedTab?: string | null;
    queryType: string | null;
    type?: string | null;
    entity?: string | null;
    beats?: any;
    isMap?: string | null;
    officer?: string | null;
    dispatcher?: string | null;
    filters?: string | null;
    include_geometry?: boolean;
};

export type Address = {
    agency_id: string;

    address?: string;
    between?: string;
    city?: string;
    coordinates?: string;
    cross?: string;
    full_address?: string;
    gps_lat?: number;
    gps_lon?: number;
    id?: string;
    number_of_calls?: number;
    prem_notes?: string;
    recent_call_type?: string;
    state?: string;
    zipcode?: string;
};

export type BasicAddress = {
    address: string[];
    fullAddress: string | null;
    gpsLat: number | null;
    gpsLon: number | null;
};

export type LinkedAddress = BasicAddress & {
    addressId: string | null;
};

export type Narrative = MatchableAgencyEntity & {
    author: string;
    rawBody: string;
    date: string;
    time: string;
    formattedBody: string[];
};

export type Tag = {
    tagName: string;
    color: string;
    isFm: boolean;
    code: string;
};

export type CommonPerson = LinkedAddress & {
    birthdate: string | null;
    eyes: string | null;
    fullName: string | null;
    hair: string | null;
    height: string | null;
    race: string | null;
    sex: string | null;
    weight: number;
    displayAge: string;
    name: string;
    age: string;
    personType?: string;
    tags?: Tag[];
};

export type CallNote = MatchableAgencyEntity &
    Tag & {
        rawBody: string;
        formattedBody: string[];
    };

export type Call = MatchableAgencyEntityResult<"call"> &
    LinkedAddress & {
        archived: false;
        callNoteCount: number;
        callNotes: CallNote[];
        callNumber: string | null;
        callType: string | null;
        contactPerson: string | null;
        id: string;
        incident: Incident;
        occuredAt: string | null;
        whenReported: string | null;
        whoTookCall: string | null;
        hasIncident: boolean;
        onlyIncident: boolean;
        responsibleOfficer?: string;
        nature?: string;
        tags?: Tag[];
    };

export type MixedResults = Call | Address | Person | Incident;

export type SearchResults = Paginated & {
    addressCount: number;
    callCount: number;
    incidentCount: number;
    personCount: number;
    searchResults: Call[] | Address[] | Person[] | MixedResults[];
    type: ResultTypesEnum;
};

export type PersonResult = {
    agency_id: string;

    first_name?: string;
    middle_name?: string;
    last_name?: string;
};

export type Person = MatchableAgencyEntityResult<"person"> &
    CommonPerson & {
        deathdate: string | null;
        dlComplete: string | null;
        id: string | null;
        incidents: Incident[];
        phoneMain: string | null;
        personId?: string;
        phoneWork?: string | null;
        mentions?: SearchResults[];
    };

export type IncidentBody = Body;

export type IncidentPeople = Paginated & {
    people: Person[];
};

export type Incident = MatchableAgencyEntityResult<"incident"> &
    LinkedAddress & {
        archived: boolean | null;
        callId: string | null;
        contactPerson?: string | null;
        id: string;
        narrativeCount: number;
        narratives: Narrative[];
        occurredAt: string | null;
        occurredAtRaw: string | null;
        offenseCodes: string[];
        offenseCodesDesc: string | null;
        people?: Person[];
        receivedBy: string | null;
        reportedAt: string | null;
        reportedAtRaw: string | null;
        responsibleOfficer: string | null;
        tags?: Tag[];
        rawIncidentType: string;
        incidentType: string;
        incidentNumber: string;
        statutes: string[];
    };

export type Body = {
    id: string | null;
    pageNumber?: string | null;
    query?: string;
};

export type Paginated = {
    pageNumber: number;
    pageSize: number;
};

type Match = {
    [key: string]: string[];
};

export type PaginatedWithTotalCount = Paginated & {
    totalCount: number;
};

export type AgencyEntity = {
    agencyId: string;
};

export type MatchableAgencyEntity = AgencyEntity & {
    matches?: Match;
};

export type EntityTypes = "call" | "incident" | "address" | "person";

export type MatchableAgencyEntityResult<T = EntityTypes> = MatchableAgencyEntity & {
    type?: T;
};

export type List<T> = {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
};

export type PopupProperties = {
    eventCount?: any;
    address?: string;
    date?: string;
    callType?: string;
    callNumber?: string;
    name?: string;
    type?: string;
    lng: number;
    lat: number;
    callCount?: number;
    mostRecentCallNature?: string;
    useOffset?: boolean;
    number?: string;
    incidentType?: string;
    lookupIds?: string[];
    closeButton?: boolean;
    totalCalls?: number;
    totalPeople?: number;
    totalAddresses?: number;
    totalIncidents?: number;
    itemList?: any[];
    searchQuery?: string;
    hoverEvent?: Function;
};

export interface LineChartDataPoint {
    x: string;
    y: number;
}

export enum SortEnum {
    BEST,
    DATE_ASC,
    DATE_DESC,
}

/**
 * Props used for person details entity views
 */
export type PersonDetailsViewProps = {
    isLoadingPerson: boolean;
    isLoadingIncidents: boolean;
    personId: string | undefined;
    person: any;
    people: any[] | undefined;
    aliases: any[];
    incidents: any[];
};

/**
 * The object used during authentication for creating users and updating a user's mfa choice.
 */
export type OnboardingObject = {
    mfa_type?: string;
    email?: string;
    phone?: string;
    token?: string;
    mfa_url?: string;
    mfaChange?: boolean;
    onboarding_token?: string;
    password?: string;
    complete?: boolean;
    step?: number;
    agency_shortname?: string;
    // TODO: TECHDEBT: For onboarding the user, only used for GA-BHPD1
    agency_id?: string;
};

/**
 * Used for Radar config list
 */
export enum RadarConfigNameEnum {
    IMPORTANT_EVENT = "IMPORTANT_EVENT",
    PUBLIC_SAFETY = "PUBLIC_SAFETY",
    PUBLIC_NUISANCE = "PUBLIC_NUISANCE",
    ALL = "ALL",
    CUSTOM = "CUSTOM",
    PATROL_OFFICER = "PATROL_OFFICER",
    SUPERVISOR = "SUPERVISOR",
    COMMAND_STAFF = "COMMAND_STAFF",
}

/**
 * Used for insights dash
 */
export enum InsightsDateViewEnum {
    DAY = "d",
    WEEK = "w",
    MONTH = "M",
    YEAR = "y",
}

export enum EmployeePositionsEnum {
    OFFICER = "officer",
    INVESTIGATOR = "investigator",
    FRONT_LINE_SUPERVISOR = "front_line_supervisor",
    COMMAND_STAFF = "command_staff",
    DISPATCHER = "dispatcher",
    ANALYST = "analyst",
    OTHER_EMPLOYEE = "other_employee",
    NOT_SET = "not_set",
}

export type PermissionType =
    // admin
    | "user_admin_view_users"
    | "user_admin_edit_users"
    | "user_admin_create_users"
    | "user_admin_deactivate_users"
    | "user_admin_reactivate_users"
    | "user_admin_reset_mfa"
    | "user_admin_resend_invite"
    | "user_admin_view_security_level"
    | "user_admin_edit_security_level"
    | "saml_admin"
    | "switch_agencies"
    // user profile
    | "user_set_phone_number"
    | "user_change_password"
    | "user_reset_mfa"
    // main site
    | "search"
    | "follows"
    | "map"
    | "submit_feedback"
    | "api_tester"
    | "share"
    // insights
    | "insights_dashboard_dashboard"
    | "insights_dashboard_officer"
    | "insights_dashboard_dispatcher"
    | "insights_dashboard_trends"
    // App Stats
    | "app_stats_main"
    | "app_stats_search_stats"
    | "app_stats_user_searches"
    | "app_stats_shared_urls"
    // radar
    | "radar_main"
    | "radar_save_configs"
    // knowledge base
    | "knowledge_base_view"
    | "knowledge_base_create"
    | "knowledge_base_edit"
    | "knowledge_base_approve"
    | "view_narrative_summaries"
    | "arrest_details"
    // permission to route to next fe search instead of fev2
    | "use_nextfe_search"
    | "use_nextfe_person_detail"
    | "use_multiagency";

export type Token = {
    exp: number;
    iat: number;
    jti: string;
    token_type: string;
    user_id: string | number;
};

export type NewNarrative = FromES<{
    id: string;
    created_at: string;
    author: string;
    contains_highlight?: boolean;
    body: string;
}>;

export type NarrativeSummary = FromES<{
    id: string;
    agency_id: string;
    incident_number: string;
    author: string;
    created_at: string;
    summary: string;
    model_id: string;
    summarization_date: string;
}>;

export type AssociatedPerson = FromES<{
    person_address_zip: string | null;
    birthdate: string | null;
    role: string | null;
    race: string | null;
    gender: string | null;
    last_name: string | null;
    full_address: string | null;
    middle_name: string | null;
    person_address_city: string | null;
    ssn: string | null;
    phone_main: string | null;
    full_name: string | null;
    person_address_state: string | null;
    first_name: string | null;
    person_address_street: string | null;
    person_id: string | null;
}>;

export enum SortDirectionEnum {
    NONE = "none",
    ASCENDING = "asc",
    DESCENDING = "desc",
}

export type SortConfig = {
    [key: string]: {
        label: string;
        defaultSortDirection: SortDirectionEnum;
        sortString: boolean;
        isDisabled?: boolean;
    };
};

export type FilterParamNamesType = typeof FilterParamNames[keyof typeof FilterParamNames];

// TODO: Update references to LayerTypes to EntitiesType instead, where applicable
export type EntitiesType = typeof Entities[keyof typeof Entities];

export type OptionType = {
    value: string; // what would be set in the URL
    display: string; // what would be displayed for plain English display
};
