import { Card } from "react-bootstrap";
import { SettingsTabTypes } from "../../types/old_v1/types";
import usePermissions from "../../hooks/usePermissions";

type OwnProps = {
    currentTab: SettingsTabTypes;
    changeTab: Function;
};

const NavBar = ({ currentTab, changeTab }: OwnProps) => {
    const { hasPermission } = usePermissions();

    const tabs = [
        {
            type: SettingsTabTypes.personalInformation,
            label: "Personal Information",
        },
    ];

    if (hasPermission("user_change_password")) {
        tabs.push({
            type: SettingsTabTypes.password,
            label: "Change Password",
        });
    }

    if (hasPermission("user_reset_mfa")) {
        tabs.push({
            type: SettingsTabTypes.mfa,
            label: "Multi-Factor Authentication (MFA)",
        });
    }
    tabs.push({
        type: SettingsTabTypes.lightDarkMode,
        label: "Light/Dark Mode",
    });

    const getCurrentTabBorderStyle = (tab: SettingsTabTypes) => {
        return currentTab === tab ? "border-5 border-start border-primary" : "";
    };
    const getCurrentTabListItemStyle = (tab: SettingsTabTypes) => {
        return currentTab === tab ? "text-primary" : "text-secondary";
    };

    return (
        <Card className="d-flex flex-column flex-grow-1 rounded-0">
            <Card.Body>
                <h1 className="text-primary mt-1 fw-bold">Settings</h1>
                {tabs.map((tab) => (
                    <div key={tab.type} className={`my-4 cursor-pointer ${getCurrentTabBorderStyle(tab.type)}`}>
                        <li
                            className={`nav-link ms-3 p-0 fw-bold ${getCurrentTabListItemStyle(tab.type)}`}
                            onClick={() => changeTab(tab.type)}
                        >
                            {tab.label}
                        </li>
                    </div>
                ))}
            </Card.Body>
        </Card>
    );
};

export default NavBar;
