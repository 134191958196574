import React from "react";
import { Badge, Button, Container, Nav, Navbar, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../static/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faMailbox } from "@fortawesome/pro-solid-svg-icons";
import usePermissions from "../../hooks/usePermissions";
import useNextAwarePathname from "../../hooks/useNextAwarePathname";
// Leaving this in just in case we have to bring it back
// import {getRadarInitialLink} from "../radar/utils";

type OwnProps = {
    notificationsCount?: number;
    setShowNotifications: Function;
    setShowUserMenu: Function;
};
const MobileNavBar = ({ notificationsCount = 0, setShowNotifications, setShowUserMenu }: OwnProps) => {
    const { hasPermission } = usePermissions();
    const { canPerformNextFESearch, landingPath } = useNextAwarePathname();

    const landingLinkHref = canPerformNextFESearch ? landingPath : "#";
    const landingLinkTo = canPerformNextFESearch ? "#" : landingPath;

    return (
        <Navbar collapseOnSelect bg="dark" variant="dark" expand="md" className="d-md-none z-index-1000">
            <Container fluid>
                <Stack direction="horizontal">
                    <Navbar.Brand href={landingLinkHref} as={canPerformNextFESearch ? undefined : Link} to={landingLinkTo}>
                        <img src={logo} className="mx-auto header-logo-height" alt="ForceMetrics logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </Stack>
                <Navbar.Text className="ms-auto">
                    <Nav.Item>
                        <Button type="button" className="border-0 bg-transparent" size="sm" onClick={() => setShowNotifications(true)}>
                            <FontAwesomeIcon icon={faMailbox} size="lg" />
                            {notificationsCount > 0 && (
                                <sup>
                                    <Badge bg="danger" pill={true}>
                                        {notificationsCount}
                                    </Badge>
                                </sup>
                            )}
                        </Button>
                        <Button className="border-0 bg-transparent" size="sm" onClick={() => setShowUserMenu(true)}>
                            <FontAwesomeIcon icon={faGear} size="lg" />
                        </Button>
                    </Nav.Item>
                </Navbar.Text>
                <Navbar.Collapse>
                    <Nav>
                        <Nav.Link href={landingPath}>Home</Nav.Link>
                        {hasPermission("map") && <Nav.Link href="/app/search/map?dateRange=30days&sort=date">Map</Nav.Link>}
                        {hasPermission("insights_dashboard_trends") && <Nav.Link href="/app/metrics-dashboard">Insights</Nav.Link>}
                        {/* NOTE: don't show radar as navigable in mobile */}
                        {hasPermission("app_stats_main") && (
                            <Nav.Link href="/app/agency-metrics/stats">
                                <span className="text-nowrap">App Stats</span>
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

MobileNavBar.displayName = "MobileNavBar";
export default MobileNavBar;
