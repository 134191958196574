import React from "react";
import { Card } from "react-bootstrap";
import { sort } from "../../../utils/sort";
import Histogram from "../../insightsdashboard/charts/Histogram";
import InsightsSpinner from "../../insightsdashboard/insightsspinner/InsightsSpinner";
import { useSearchParams } from "react-router-dom";
import useAgencySettings from "../../../hooks/useAgencySettings";

const CallHistogram = ({ callBuckets, isFetching }: { callBuckets: any[]; isFetching: boolean }) => {
    const [searchParams] = useSearchParams();
    const { callText, callTextPlural } = useAgencySettings();
    const processedCallBucket =
        callBuckets &&
        [...callBuckets].sort(sort("key", true)).reduce(
            (coll: any, curr: any) => {
                coll.labels.push(curr.key);
                coll.counts.push(curr.doc_count);
                return coll;
            },
            { labels: [], counts: [] }
        );

    return (
        <Card>
            <Card.Header>
                <Card.Title className="m-0" as="h3">
                    Top {callText} Types
                </Card.Title>
            </Card.Header>
            <Card.Body className="overflow-auto" style={{ height: "28em" }}>
                {isFetching ? (
                    <InsightsSpinner />
                ) : processedCallBucket && processedCallBucket.labels && processedCallBucket.labels.length ? (
                    <Histogram
                        title=""
                        processedData={processedCallBucket || {}}
                        xAxis=""
                        yAxis={`Number of ${callTextPlural}`}
                        onPointClick={(e: any) => {
                            const newSearchParams = new URLSearchParams(searchParams);
                            newSearchParams.set("callTypes", encodeURIComponent(e.point.category));
                            window.open(`/app/search?${newSearchParams}`, "_blank");
                        }}
                    />
                ) : (
                    <h1 className="text-center">No {callTextPlural}</h1>
                )}
            </Card.Body>
        </Card>
    );
};

export default CallHistogram;
