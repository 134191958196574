import { Button, Form, Modal } from "react-bootstrap";
import { EmployeePositionsEnum } from "../../types/old_v1/types";
import { underToWords } from "../../utils/string";
import React from "react";

type OwnProps = {
    selectedUser: any;
    setSelectedUser: Function;
    setEditedUser: Function;
    editedUser: any;
    canUserAdminEditSecurityLevel: boolean;
    editUser: Function;
};

const EditUserModal = ({ selectedUser, setSelectedUser, setEditedUser, editedUser, canUserAdminEditSecurityLevel, editUser }: OwnProps) => {
    return (
        <Modal
            show={!!selectedUser}
            onHide={() => {
                setSelectedUser(null);
            }}
        >
            <Modal.Header closeButton>Edit User</Modal.Header>
            <Modal.Body>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                    className="mb-3"
                    type="text"
                    name="first_name"
                    defaultValue={selectedUser?.first_name}
                    onChange={(event) => setEditedUser({ ...editedUser, firstName: event.target.value })}
                    autoComplete="off"
                    style={{ borderRight: "none" }}
                />
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                    className="mb-3"
                    type="text"
                    name="last_name"
                    defaultValue={selectedUser?.last_name}
                    onChange={(event) => setEditedUser({ ...editedUser, lastName: event.target.value })}
                    autoComplete="off"
                    style={{ borderRight: "none" }}
                />
                {canUserAdminEditSecurityLevel && (
                    <>
                        <Form.Label>Security Level</Form.Label>
                        <Form.Select
                            className="mb-3"
                            defaultValue={selectedUser?.security_level}
                            onChange={(event) =>
                                setEditedUser({
                                    ...editedUser,
                                    securityLevel: parseInt(event.target.value),
                                })
                            }
                        >
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Form.Select>
                    </>
                )}
                <Form.Label>Employee Position</Form.Label>
                <Form.Select
                    className="mb-3"
                    defaultValue={selectedUser?.position}
                    onChange={(event) =>
                        setEditedUser({
                            ...editedUser,

                            agencyPosition: event.target.value,
                        })
                    }
                >
                    {Object.values(EmployeePositionsEnum).map((option: string) => (
                        <option key={option} value={option}>
                            {underToWords(option)}
                        </option>
                    ))}
                </Form.Select>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={() => {
                        setSelectedUser(null);
                    }}
                >
                    Cancel
                </Button>
                <Button variant="success" onClick={() => editUser()}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditUserModal;
