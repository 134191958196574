import React from "react";
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryLine, VictoryTooltip, VictoryVoronoiContainer } from "victory";
import { calcHorizontalRefLines, calcLineChartDomainSettings } from "../../charts/utils/charts";
import { format } from "date-fns";
import { useDarkMode } from "../../../../hooks/useDarkMode";
import { NORMAL_DATE } from "../../../../utils/date";
import { BLACK, LIGHT_GRAY, WHITE } from "../../../../utils/colors";

const getXDomain = (data: any) => {
    if (data && Array.isArray(data) && data.length) {
        return { x: [data[0].x, data[data.length - 1].x] };
    }
};

const formatYTick = (tick: any) => {
    // check to see if the tick has a decimal, if it does, we don't want to return it
    const hasDecimal = tick - Math.floor(tick) !== 0;

    if (!hasDecimal) {
        return tick;
    } else {
        return null;
    }
};

const DashboardSimpleLine = ({ data, partialUrl, ...rest }: any) => {
    let domain: any = calcLineChartDomainSettings(data);
    const xDomain = getXDomain(data);
    domain = { ...domain, ...xDomain };

    const [hoverLine, setHoverLine] = React.useState<any>("");

    const { width, height } = rest as any;

    const dataDisplay = (datum: { x: string | number; y: number }): string => {
        if (typeof datum.x === "object") {
            return `${format(datum.x, NORMAL_DATE)} - ${datum.y}`;
        } else {
            return null as any;
        }
    };

    const { isDarkMode } = useDarkMode();

    return (
        <VictoryChart
            width={width}
            height={height}
            domain={domain}
            scale={{ x: "time", y: "linear" }}
            containerComponent={
                <VictoryVoronoiContainer
                    className="mt-n5 graph-click"
                    responsive={false}
                    voronoiDimension="x"
                    labels={({ datum }) => dataDisplay(datum)}
                    onActivated={(event) => {
                        const x = event[0].x;
                        setHoverLine(x);
                    }}
                    labelComponent={<VictoryTooltip flyoutPadding={10} />}
                />
            }
        >
            <VictoryAxis
                tickFormat={(t) => formatYTick(t)}
                dependentAxis={true}
                tickLabelComponent={<VictoryLabel style={[{ fill: isDarkMode ? WHITE : BLACK }]} />}
                style={{
                    axis: { stroke: LIGHT_GRAY },
                }}
                tickValues={calcHorizontalRefLines(data)}
            />
            <VictoryAxis
                tickFormat={(t) => {
                    return format(t, NORMAL_DATE);
                }}
                dependentAxis={false}
                tickLabelComponent={<VictoryLabel angle={-35} dy={6} style={[{ fill: isDarkMode ? "#fff" : BLACK }]} />}
                style={{
                    axis: { stroke: LIGHT_GRAY },
                }}
                tickCount={5}
            />
            <VictoryLine
                interpolation="linear"
                style={{
                    data: { stroke: "#c43a31", strokeWidth: 2 },
                }}
                data={data}
                {...rest}
            />
            <VictoryLine
                interpolation="linear"
                style={{
                    data: { stroke: "#3E65B6", strokeWidth: 1, margin: "10px" },
                }}
                x={() => hoverLine}
            />
        </VictoryChart>
    );
};

export default DashboardSimpleLine;
