import { Card } from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import { useGetIncidentsByDayQuery } from "../../api/api";
import { range } from "../../utils/array";
import { getMonthNum, getMonthString } from "../../utils/date";
import InsightsSpinner from "./insightsspinner/InsightsSpinner";
import { useDarkMode } from "../../hooks/useDarkMode";
import { handleHighChartDarkMode } from "../../utils/helpers";
import { getHeatmapMax, heatmapStops } from "../../utils/heatmap";
import { getByDaySearchParams } from "./insights";
import { heatmapBaseConfig, initializeHighchartsHeatmap } from "./heatmap";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";
import useInsights from "../../hooks/useInsights";
import useAgencySettings from "../../hooks/useAgencySettings";
import { useFilters } from "../../hooks/useFilters";

const initializedHighcharts = initializeHighchartsHeatmap();

const IncidentsByDay = () => {
    const { queryParams } = useSingleAgencyFilter();
    const { incidentText, incidentTextPlural } = useAgencySettings();
    const { searchParams } = useInsights();
    const { filterPayload, beatsIsFetching } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });
    const { data, isLoading, isFetching } = useGetIncidentsByDayQuery(
        { ...queryParams, filters: filterPayload },
        { skip: beatsIsFetching }
    );
    const currentMonth = getMonthNum();
    const { isDarkMode } = useDarkMode();

    let processedData = null;
    let months = [] as string[];
    if (data && !isLoading) {
        const buckets =
            data && data?.aggregations && data?.aggregations?.incidents_over_time && data?.aggregations?.incidents_over_time?.buckets;

        if (buckets && buckets.length) {
            const firstDate = buckets[0].key_as_string.split("-");
            const minYear = firstDate[0];
            const firstMonth = parseInt(firstDate[1]);
            processedData = buckets.map((bucket: any) => {
                const parts = bucket.key_as_string.split("-");
                const year = parts[0];
                const day = parseInt(parts[2]);
                const month = parseInt(parts[1]) - currentMonth + (year === minYear ? 0 : 12);
                return [day, month, bucket.doc_count, "test"];
            });
            const lastDate = buckets[buckets.length - 1].key_as_string.split("-");
            const thisMonth = parseInt(lastDate[1]);
            months = [
                ...range(13 - firstMonth, firstMonth).map((month) => getMonthString(month) + " " + minYear.toString()),
                ...range(thisMonth, 1).map((month) => getMonthString(month) + " " + lastDate[0]),
            ];
        }
    }

    const options = {
        chart: {
            type: "heatmap",
            plotBorderWidth: 1,
        },
        legend: false,
        title: {
            text: "",
        },
        colorAxis: {
            stops: heatmapStops,
            max: getHeatmapMax(processedData),
        },
        xAxis: {
            min: 1,
            max: 31,
            tickInterval: 1,
            title: {
                text: "Date",
            },
        },
        yAxis: {
            categories: months,
            title: {
                text: "Month and Year",
            },
            reversed: true,
        },
        tooltip: {
            formatter(this: any) {
                return `${incidentTextPlural}: ${this.point.value}`;
            },
            shared: true,
        },
        series: [
            {
                ...heatmapBaseConfig,
                name: incidentTextPlural,
                point: {
                    events: {
                        click: (e: any) => {
                            const newSearchParams = getByDaySearchParams(e, searchParams, months);
                            newSearchParams.set("indexType", "incidents");

                            window.open(`/app/search?${newSearchParams}`, "_blank");
                        },
                    },
                },
                data: processedData,
            },
        ],
    };

    handleHighChartDarkMode(options, isDarkMode);

    return (
        <Card>
            <Card.Header>
                <Card.Title className="m-0" as="h3">
                    {incidentText} Per Month and Date
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {isFetching ? (
                    <InsightsSpinner />
                ) : (
                    processedData && <HighchartsReact highcharts={initializedHighcharts} options={options} />
                )}
            </Card.Body>
        </Card>
    );
};

export default IncidentsByDay;
