import React from "react";
import { useGetUniqueCallPrioritiesQuery } from "../../api/api";
import { processFilterAgg } from "../../utils/helpers";
import MultiSelectFilter from "./MultiSelectFilter";
import aggregationDataPresenceCount from "../../utils/aggregationDataPresenceCount";
import { byNameSort, TAggregationType } from "../../hooks/useSearchFiltersAggregations";

const CallPrioritiesFilterConnected = ({ callPrioritiesAggregation }: { callPrioritiesAggregation?: Array<TAggregationType> }) => {
    const { data: optionData } = useGetUniqueCallPrioritiesQuery({});
    const callPriorities = processFilterAgg(optionData);

    const options = React.useMemo(
        () =>
            callPriorities
                .map((callPriorities: { id: string; option: string }) => {
                    const { present, count } = aggregationDataPresenceCount(callPrioritiesAggregation, callPriorities?.id);

                    return {
                        id: callPriorities?.id,
                        value: callPriorities?.option,
                        name: callPriorities?.option,
                        present,
                        count,
                    };
                })
                .sort(byNameSort),
        [callPriorities, callPrioritiesAggregation]
    );

    return (
        <MultiSelectFilter
            ariaLabel="Call Priorities"
            pendoClass="pendo_call_priorities_filter_select"
            options={options}
            urlParamName={"callPriorities"}
            inputPlaceholder={`Select Call Priorities`}
        />
    );
};

export default CallPrioritiesFilterConnected;
