import React from "react";

/*
 * React Hook to set the page title on initial page load
 */
export function useSetPageTitle(title: string): void {
    React.useEffect(() => {
        document.title = title;
    });
}
