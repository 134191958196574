const useEncoderDecoder = ({ rawData, encodedData }: { rawData?: string | null; encodedData?: string | null }) => {
    let decodedData = "";

    if (rawData) {
        encodedData = encodeURIComponent(rawData);
    }

    if (encodedData) {
        decodedData = decodeURIComponent(encodedData);
    }

    return {
        encodedData,
        decodedData,
    };
};

export default useEncoderDecoder;
