import { useDispatch } from "react-redux";
import { SAML_PROCESSING_ROUTE, useRunPreAuthApiMutation } from "../../api/api";
import { setIsLoggedIn, setUserObject } from "../../app/slices/user/userSlice";
import { Alert, Col, Row } from "react-bootstrap";
import LoadingModal from "../../components/LoadingModal";
import React from "react";
import { setAccessToken, setRefreshToken } from "../../utils/token";
import useNextAwarePathname, { useNextAwareNavigate } from "../../hooks/useNextAwarePathname";

/**
 * Extracts SAML cookie value and passes to BE to exchange for tokens & user data
 */
const SAMLHandler = () => {
    const dispatch = useDispatch();
    const { landingPath } = useNextAwarePathname();
    const { nextAwareNavigate } = useNextAwareNavigate();

    const [processSAMLCookie, processSAMLCookieResult] = useRunPreAuthApiMutation();

    if (processSAMLCookieResult.isUninitialized) {
        processSAMLCookie({ route: SAML_PROCESSING_ROUTE });
    }

    if (processSAMLCookieResult.isError) {
        return (
            <Row>
                <Col lg={{ offset: 2, span: 8 }}>
                    <Alert variant="danger">
                        An error occurred while authenticating. Please try authenticating again. If the problem persists, contact your
                        system administrator.
                    </Alert>
                </Col>
            </Row>
        );
    } else if (!!processSAMLCookieResult.data) {
        setAccessToken(processSAMLCookieResult.data.access);
        setRefreshToken(processSAMLCookieResult.data.refresh);
        dispatch(setIsLoggedIn(true));
        dispatch(setUserObject(processSAMLCookieResult.data.user));
        nextAwareNavigate(landingPath);
    }

    return <LoadingModal show={processSAMLCookieResult.isLoading} />;
};

export default SAMLHandler;
