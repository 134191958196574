import React from "react";
import { useSelector } from "react-redux";
import { useDeleteFollowMutation, useSaveFollowMutation } from "../api/api";
import { Follow } from "../app/slices/user/types";

type FollowConfig = {
    saveFollow: () => void;
    showAlert: boolean;
    hideAlert: () => void;
    alertContent: {
        variant: string;
        key: string;
    };
    followed: boolean;
};

export type FollowType = "address" | "person" | "search";

export function useFollow(type: FollowType, followText: string): FollowConfig {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertContent, setAlertContent] = React.useState({
        variant: "",
        key: "",
    });
    const follows = useSelector((state: any) => state.user.follows);
    const [followed, setFollowed] = React.useState<boolean>(!!follows.find((follow: Follow) => follow.text === followText));

    const [saveFn, saveFnResult] = useSaveFollowMutation();

    const saveFollow = () => {
        saveFn({ text: followText });
        setFollowed(true);
    };

    const [deleteFn, deleteFnResult] = useDeleteFollowMutation();

    const deleteFollow = () => {
        const follow = follows.find((follow: Follow) => follow.text === followText);
        deleteFn({ id: follow.id });
        setFollowed(false);
    };

    const handleFollow = () => {
        if (followed) {
            deleteFollow();
        } else {
            saveFollow();
        }
    };

    const hideAlert = () => {
        setShowAlert(false);
    };

    React.useEffect(() => {
        setFollowed(!!follows.find((follow: Follow) => follow.text === followText));
    }, [follows, followText]);

    React.useEffect(() => {
        if (!!saveFnResult.error) {
            const err = saveFnResult.error as any;
            if (err.data.data) {
                setAlertContent({
                    variant: "warning",
                    key: "follow_failed",
                });
                setShowAlert(true);
            }
        }

        if (saveFnResult.data && saveFnResult.data.success && followed) {
            setAlertContent({
                variant: "success",
                key: `follow_${type}_success`,
            });
            setShowAlert(true);
        }

        if (deleteFnResult.data && deleteFnResult.data.success && !followed) {
            setAlertContent({
                variant: "success",
                key: `unfollow_${type}_success`,
            });
            setShowAlert(true);
        }
    }, [followText, saveFnResult, type, deleteFnResult, followed]);

    return {
        saveFollow: handleFollow,
        showAlert,
        hideAlert,
        alertContent,
        followed,
    };
}
