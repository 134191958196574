import { Button, Card, Col, Modal, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { faGavel, faPhone } from "@fortawesome/pro-regular-svg-icons";
import ShareEntity from "../../components/share/ShareEntity";
import { Link, useNavigate } from "react-router-dom";
import Tags from "../../components/tags/Tags";
import EventMap from "./EventMap";
import { formatDate } from "../../utils/date";
import Highlighter from "../../components/highlighter/Highlighter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CALL_ACCENT, INCIDENT_ACCENT } from "../../utils/colors";
import PanelCell from "../../components/entity/PanelCell";
import { renderField } from "../../utils/string";
import AgencyDescription from "../../components/AgencyDescription";
import LinkCell from "../../components/LinkCell";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import { useManageSearchParams } from "../../hooks/useManageSearchParams";
import { getBeautifiedESKeyValuePairs } from "../../utils/getKeyValuePairs";
import { sort } from "../../utils/sort";
import React from "react";
import FMSpinner from "../../components/Spinner";
import { getAddressLinkIsDisabled } from "../../utils/address";
import useAgencySettings from "../../hooks/useAgencySettings";
import { getValidatedLabels } from "../../utils/TagDetails";
import AddressSearchModal from "../../components/entity/AddressSearchModal";
import useNextAwarePathname from "../../hooks/useNextAwarePathname";

const buildLicensePlateUrl = (baseUrl: "/search/results/events" | "/app/search", licensePlate: string) => {
    const newSearchParams = new URLSearchParams({
        query: licensePlate,
    });

    if (baseUrl === "/app/search") {
        newSearchParams.set("dateRange", "all_time");
        newSearchParams.set("sort", "relevance");
    }
    return `${baseUrl}?${newSearchParams}`;
};

type OwnProps = {
    call: any;
    incident: any;
    isCallFetching: boolean;
    isIncidentFetching: boolean;
};
const EventOverview = ({ call, incident, isCallFetching, isIncidentFetching }: OwnProps) => {
    const [whichEventType, setWhichEventType] = React.useState("");
    const [prefillSearch, setPrefillSearch] = React.useState("");
    const { incidentText, callText, callTextPlural } = useAgencySettings();
    const [selectedDetails, setSelectedDetails] = React.useState<any[]>([]);
    const [showDetailsModal, setShowDetailsModal] = React.useState(false);
    const navigate = useNavigate();
    const { constructEventUrl, constructAddressUrl } = useMultiAgency();
    const { canPerformNextFESearch, mainSearchPath } = useNextAwarePathname();
    const { processSearchParams } = useManageSearchParams();
    const processedSearchParams = processSearchParams(["query"]);
    const callLabels = getValidatedLabels((call?.source?.flags || []) as [string]).map((tag) => tag.code);
    const incidentLabels = getValidatedLabels((incident?.source?.flags || []) as [string]).map((tag) => tag.code);
    const canViewMapboxSearchboxModal = window?.__RUNTIME_CONFIG__?.USE_LOCATION_SEARCH_MODAL === "true";

    const callToDisplay = call?.source;
    const incidentToDisplay = incident?.source;

    const incidentKeyValues = React.useCallback(
        () => getBeautifiedESKeyValuePairs(incidentToDisplay, ["narratives"]).sort(sort("beautifiedKey")),
        [incidentToDisplay]
    );
    const callKeyValues = React.useCallback(
        () => getBeautifiedESKeyValuePairs(call?.source, ["call_notes"]).sort(sort("beautifiedKey")),
        [call]
    );

    const handleCloseModal = () => {
        setSelectedDetails([]);
        setShowDetailsModal(false);
        setWhichEventType("");
    };

    const callAddressLink = constructAddressUrl(callToDisplay?.agency_id, callToDisplay, processedSearchParams.get("query"));
    const incidentAddressLink = constructAddressUrl(incidentToDisplay?.agency_id, incidentToDisplay, processedSearchParams.get("query"));

    const onAddressClick = (addressString: string, addressLink: string) => {
        if (canViewMapboxSearchboxModal) {
            setPrefillSearch(addressString);
        } else {
            navigate(addressLink);
        }
    };

    const onHideMapboxSearchboxModal = () => {
        setPrefillSearch("");
    };

    return (
        <>
            <Card className="border-0 border-md overflow-hidden">
                <Card.Header className="bg-dark py-3 d-flex justify-content-between flex-wrap">
                    <div className="align-self-center text-white d-flex flex-column">
                        <span className="fw-bold">Event Details</span>
                        <AgencyDescription agencyDescription={call?.source?.agency_desc || incident?.source?.agency_desc} />
                    </div>
                    <div>
                        <ShareEntity />
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <EventMap call={call} incident={incident} isLoading={isCallFetching || isIncidentFetching} />
                </Card.Body>
                <Card.Header className="py-4 fs-3">
                    <FontAwesomeIcon icon={faPhone} className="fs-2 me-2" color={CALL_ACCENT} />
                    {callText} ID: {renderField(call?.source?.call_number)}
                    {callLabels.length > 0 && (
                        <div className="pt-2">
                            <Tags tags={callLabels} />
                        </div>
                    )}
                </Card.Header>
                <Card.Body className="p-0 mb-2">
                    {isCallFetching && (
                        <div className="text-center my-3">
                            <FMSpinner />
                        </div>
                    )}
                    {call && Object.keys(call).length !== 0 && (
                        <>
                            <PanelCell fieldName="Type" fieldValue={callToDisplay?.call_type} />
                            <PanelCell fieldName="Occurred At" fieldValue={formatDate(callToDisplay?.occurred_at)} />
                            <LinkCell
                                fieldName="Address"
                                fieldValue={callToDisplay?.full_address}
                                testId="call-address-link"
                                cellClick={() => onAddressClick(callToDisplay?.full_address, callAddressLink)}
                                disabled={
                                    canViewMapboxSearchboxModal ? !callToDisplay?.full_address : getAddressLinkIsDisabled(callAddressLink)
                                }
                                pendoClass="pendo_event_call_address_link"
                            />
                            <PanelCell fieldName="Received By" fieldValue={callToDisplay?.who_took_call} />
                            <PanelCell
                                fieldName="Caller Info"
                                fieldValue={`${callToDisplay?.caller_name || "Unknown Name"} <br/> ${
                                    callToDisplay?.contact_phone_number || "Unknown Phone"
                                }`}
                            />
                            <LinkCell
                                fieldName=""
                                fieldValue="See More"
                                cellClick={() => {
                                    setSelectedDetails(callKeyValues);
                                    setShowDetailsModal(true);
                                    setWhichEventType(callText);
                                }}
                                pendoClass="pendo_event_call_see_more"
                            />
                        </>
                    )}
                    {!isCallFetching && (!call || Object.keys(call).length === 0) && (
                        <div className="px-3 py-3">
                            <span>No associated call</span>
                        </div>
                    )}
                </Card.Body>
                <Card.Header className="py-4 fs-3">
                    <FontAwesomeIcon icon={faGavel} className="fs-2 me-2" color={INCIDENT_ACCENT} />
                    {incidentText} ID: <Highlighter>{renderField(incidentToDisplay?.id)}</Highlighter>
                    {incidentLabels.length > 0 && (
                        <div className="pt-2">
                            <Tags tags={incidentLabels} />
                        </div>
                    )}
                </Card.Header>
                <Card.Body className="p-0 mb-2">
                    {isIncidentFetching && (
                        <div className="text-center my-3">
                            <FMSpinner />
                        </div>
                    )}
                    {incident && Object.keys(incident).length !== 0 && (
                        <>
                            <PanelCell fieldName="Type" fieldValue={incidentToDisplay?.incident_type} />
                            <PanelCell fieldName="Occurred At" fieldValue={formatDate(incidentToDisplay?.occurred_at)} />
                            <LinkCell
                                fieldName="Address"
                                fieldValue={incidentToDisplay?.full_address}
                                cellClick={() => onAddressClick(incidentToDisplay?.full_address, incidentAddressLink)}
                                testId="incident-address-link"
                                disabled={
                                    canViewMapboxSearchboxModal
                                        ? !incidentToDisplay?.full_address
                                        : getAddressLinkIsDisabled(incidentAddressLink)
                                }
                                pendoClass="pendo_event_incident_address"
                            />
                            <PanelCell fieldName="Responsible Officer" fieldValue={incidentToDisplay?.responsible_officer} />
                            <PanelCell fieldName="Contact Name" fieldValue={incidentToDisplay?.contact_person} />
                            <PanelCell fieldName="Case Status" fieldValue={incidentToDisplay?.case_status} />
                            {incidentToDisplay?.call_number?.length > 0 && (
                                <OverlayTrigger
                                    trigger={["click"]}
                                    rootClose
                                    overlay={
                                        <Popover>
                                            <Popover.Header>Associated {callTextPlural}</Popover.Header>
                                            <Popover.Body className="d-flex flex-column">
                                                {incidentToDisplay?.call_number.map((callNumber: string) => (
                                                    <Link
                                                        key={callNumber}
                                                        className="d-inline-flex justify-content-center pendo_event_incident_call_link"
                                                        to={constructEventUrl(
                                                            incidentToDisplay?.agency_id,
                                                            callNumber,
                                                            incidentToDisplay?.incident_number,
                                                            processedSearchParams
                                                        )}
                                                    >
                                                        <div>{callNumber}</div>
                                                    </Link>
                                                ))}
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <span>
                                        <LinkCell
                                            fieldName={`Associated ${callTextPlural}`}
                                            fieldValue={incidentToDisplay?.call_number.length}
                                        />
                                    </span>
                                </OverlayTrigger>
                            )}
                            {incidentToDisplay?.license_plate_number?.length > 0 ? (
                                <OverlayTrigger
                                    trigger={["click"]}
                                    rootClose
                                    overlay={
                                        <Popover>
                                            <Popover.Header>Associated License Plates</Popover.Header>
                                            <Popover.Body className="d-flex flex-column">
                                                {incidentToDisplay.license_plate_number.map((license: string) => {
                                                    const linkClassNames = "d-inline-flex justify-content-center pendo_license_plate_link";
                                                    return canPerformNextFESearch ? (
                                                        <a
                                                            key={license}
                                                            href={buildLicensePlateUrl(mainSearchPath, license)}
                                                            className={linkClassNames}
                                                        >
                                                            <div>{license}</div>
                                                        </a>
                                                    ) : (
                                                        <Link
                                                            key={license}
                                                            to={buildLicensePlateUrl(mainSearchPath, license)}
                                                            className={linkClassNames}
                                                        >
                                                            <div>{license}</div>
                                                        </Link>
                                                    );
                                                })}
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <span>
                                        <LinkCell
                                            fieldName="Associated License Plates"
                                            fieldValue={incidentToDisplay.license_plate_number?.length}
                                        />
                                    </span>
                                </OverlayTrigger>
                            ) : (
                                <PanelCell fieldName="Associated License Plates" fieldValue="--" />
                            )}
                            <LinkCell
                                fieldName=""
                                fieldValue="See More"
                                cellClick={() => {
                                    setSelectedDetails(incidentKeyValues);
                                    setShowDetailsModal(true);
                                    setWhichEventType(incidentText);
                                }}
                                pendoClass="pendo_event_incident_see_more"
                            />
                        </>
                    )}
                    {!isIncidentFetching && (!incident || Object.keys(incident).length === 0) && (
                        <div className="px-3 py-3">
                            <span>No Associated {incidentText}</span>
                        </div>
                    )}
                </Card.Body>
            </Card>
            <Modal show={showDetailsModal} onHide={handleCloseModal} size="xl">
                <Modal.Header closeButton>
                    <div className="d-flex w-100">
                        <Modal.Title className="me-3">
                            <span className="text-capitalize">{whichEventType} Event Details</span>
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <Row>
                        {selectedDetails.map((keyValue) => (
                            <Col xs={12} md={6} key={keyValue.beautifiedKey}>
                                <span className="fw-bold me-3 text-capitalize">{keyValue.beautifiedKey}:</span>
                                <span className="text-break">{keyValue.value}</span>
                            </Col>
                        ))}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* Mapbox address suggestions based on call/incident full address */}
            {canViewMapboxSearchboxModal && (
                <AddressSearchModal show={prefillSearch !== ""} hideModalFn={onHideMapboxSearchboxModal} prefillSearch={prefillSearch} />
            )}
        </>
    );
};

export default EventOverview;
