import { Card, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faGavel } from "@fortawesome/pro-light-svg-icons";
import { INCIDENT_ACCENT } from "../../utils/colors";
import { Link } from "react-router-dom";
import React from "react";
import Tags from "../../components/tags/Tags";
import { formatDate } from "../../utils/date";
import Highlighter from "../../components/highlighter/Highlighter";
import AssociatedPeopleModal from "../../components/entity/AssociatedPeopleModal";
import { highlightDisplay } from "../../utils/display";
import ResultHeader from "./ResultHeader";
import AgencyDescription from "../../components/AgencyDescription";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import useAgencySettings from "../../hooks/useAgencySettings";

// leaving this function here for now since it is only used here
// removing all html tags and \n's except the highlight <em> and </em>
export const stripHtml = (input: string) => input.replace(/<(?!\/?em(?=>|\s.*>))\/?.*?>|\\n/gi, "");

type OwnProps = {
    incident: any;
    linkParams: any;
    mapHighlight?: boolean;
};

const IncidentResult = ({ incident, linkParams, mapHighlight = false }: OwnProps) => {
    const { constructEventUrl } = useMultiAgency();
    const { incidentText } = useAgencySettings();
    const [selectedIncident, setSelectedIncident] = React.useState<any>();

    return (
        <Card className="border-0" id={incident?.source?.id}>
            <ResultHeader
                mapHighlight={mapHighlight}
                icon={faGavel}
                iconColor={INCIDENT_ACCENT}
                leftContent={
                    <>
                        <Link
                            data-testid="incident-result"
                            className="pendo_incident_result d-inline fw-bold"
                            to={constructEventUrl(
                                incident?.source?.agency_id,
                                incident?.source?.call_number && incident.source.call_number.length
                                    ? incident?.source?.call_number[0]
                                    : undefined,
                                incident?.source?.id ? incident?.source?.id : incident?.id,
                                linkParams
                            )}
                        >
                            {incidentText} {incident?.source.incident_number}{" "}
                            {incident?.source?.incident_type ? ` | ${incident?.source?.incident_type}` : ""}
                        </Link>
                        <AgencyDescription className="text-secondary" agencyDescription={incident?.source?.agency_desc} />
                    </>
                }
                rightContent={<span>{formatDate(incident?.source?.occurred_at)}</span>}
            />
            <Card.Body className="overflow-hidden pt-2 fm-left-indent">
                <Tags tags={incident?.source?.flags || []} />
                <div data-testid="third-row-container">
                    {incident.source && incident.source.highlight && incident?.source?.highlight?.full_address ? (
                        <Highlighter>{incident?.source?.highlight?.full_address[0]}</Highlighter>
                    ) : (
                        <span>{incident?.source?.full_address}</span>
                    )}
                </div>
                <div className="fw-normal pt-2" data-testid="fourth-row-container">
                    {incident?.source?.narratives && incident?.source?.narratives[0]?.highlight?.body && (
                        <div className="d-flex flex-row justify-content-between gap-4">
                            <div>
                                <span className="text-secondary me-2">Narratives:</span>
                                <Highlighter className="pe-3">{`${stripHtml(
                                    incident?.source?.narratives[0]?.highlight?.body[0]
                                )}...`}</Highlighter>
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    {((incident?.source?.narratives && incident?.source?.narratives[0]?.highlight?.body) ||
                        incident?.source?.highlight) && (
                        <div className="d-inline-flex d-md-none flex-grow-1">
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="top"
                                overlay={
                                    <Popover>
                                        <Popover.Header>Match Previews</Popover.Header>
                                        <Popover.Body>
                                            <ul>
                                                {incident?.source?.narratives[0]?.highlight?.body.map((text: any, index: number) => {
                                                    return (
                                                        <li key={index}>
                                                            <Highlighter className="pe-3">{stripHtml(text)}</Highlighter>
                                                        </li>
                                                    );
                                                })}
                                                {incident?.source?.highlight &&
                                                    Object.keys(incident?.source?.highlight).map((text: any, index: number) => {
                                                        return (
                                                            <li key={index}>
                                                                <Highlighter className="pe-3">
                                                                    {`${highlightDisplay[text]}: ${stripHtml(
                                                                        incident?.source?.highlight[text].join(", ")
                                                                    )}`}
                                                                </Highlighter>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <span className="cursor-pointer text-primary d-inline-flex align-items-center mt-2 fm-preview-font-size fm-preview-font-size">
                                    <FontAwesomeIcon icon={faArrowCircleRight} />
                                    &nbsp;Preview
                                </span>
                            </OverlayTrigger>
                        </div>
                    )}
                    {((incident?.source?.narratives && incident?.source?.narratives[0]?.highlight?.body) ||
                        incident?.source?.highlight) && (
                        <div className="d-none d-md-flex">
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="auto-start"
                                overlay={
                                    <Popover>
                                        <Popover.Header>Match Previews</Popover.Header>
                                        <Popover.Body>
                                            <ul>
                                                {incident?.source?.narratives[0]?.highlight?.body.map((text: any, index: number) => {
                                                    return (
                                                        <li key={index}>
                                                            <Highlighter className="pe-3">{stripHtml(text)}</Highlighter>
                                                        </li>
                                                    );
                                                })}
                                                {incident?.source?.highlight &&
                                                    Object.keys(incident?.source?.highlight).map((text: any, index: number) => {
                                                        return (
                                                            <li key={index}>
                                                                <Highlighter className="pe-3">
                                                                    {`${highlightDisplay[text]}: ${stripHtml(
                                                                        incident?.source?.highlight[text].join(", ")
                                                                    )}`}
                                                                </Highlighter>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <span className="cursor-pointer text-primary d-inline-flex align-items-center mt-2 fm-preview-font-size">
                                    <FontAwesomeIcon icon={faArrowCircleRight} />
                                    &nbsp;Preview
                                </span>
                            </OverlayTrigger>
                        </div>
                    )}
                </div>
            </Card.Body>
            {!!selectedIncident && <AssociatedPeopleModal selectedIncident={selectedIncident} setSelectedIncident={setSelectedIncident} />}
        </Card>
    );
};

export default IncidentResult;
