import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapProvider } from "react-map-gl";
import { persistor, store } from "./v2/app/store";
import { PersistGate } from "redux-persist/integration/react";
import AppRouterV2 from "./v2/AppRouterV2";

const container = document.getElementById("root")!;
const root = createRoot(container);

const AppRoot = () => {
    return <AppRouterV2 />;
};

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <MapProvider>
                    <AppRoot />
                </MapProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
