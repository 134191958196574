import { DARK_BLUE, LIGHT_BLUE, WHITE } from "../../utils/colors";
import Highcharts from "highcharts";
import HighchartsHeatmap from "highcharts/modules/heatmap";

export const heatmapStops = [
    [0, WHITE],
    [0.5, LIGHT_BLUE],
    [1, DARK_BLUE],
];

export const getHeatmapMax = (data: number[][]) => {
    const baselineMaxValue = 10;
    if (!data) {
        return baselineMaxValue;
    }
    const maxValue = Math.max(...data.map((arr: number[]) => arr[2]));
    return maxValue > baselineMaxValue ? maxValue : baselineMaxValue;
};

// inspired by thread here: https://github.com/highcharts/highcharts/issues/17273
export const getContrastOverride = (H: any) => {
    const SVGRenderer = H.SVGRenderer;
    SVGRenderer.prototype.getContrast = function (rgba: string) {
        let rgbaValues = H.Color.parse(rgba);
        let vals = rgbaValues.rgba;
        vals[2] *= 2; // weight blue component heavier
        return vals[0] + vals[1] + vals[2] > 2.8 * 255 ? "#000000" : "#FFFFFF";
    };
};

export const initializeHighchartsHeatmap = () => {
    const HighchartsCopy = { ...Highcharts };
    getContrastOverride(HighchartsCopy);
    HighchartsHeatmap(HighchartsCopy);
    return HighchartsCopy;
};

export const heatmapBaseConfig = {
    borderWidth: 0.5,
    borderColor: "white",
    cursor: "pointer",
    dataLabels: {
        enabled: true,
        style: {
            fontWeight: "normal",
            textOutline: 0,
        },
    },
};
