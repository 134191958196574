import React, { ClassAttributes, InputHTMLAttributes } from "react";

const Disclaimer = ({ ...props }: InputHTMLAttributes<HTMLInputElement> & ClassAttributes<HTMLInputElement>) => {
    return (
        <span {...props}>
            This system is a restricted information system and for use by authorized users only. All individuals using this system, with or
            without authority, or in excess of their authority, are subject to having all of their activities on this system monitored,
            recorded and/or subject to audit. Anyone using this system expressly consents to such monitoring and abuse of this system may be
            subject to criminal and/or civil penalties.
        </span>
    );
};

Disclaimer.displayName = "Disclaimer";
export default Disclaimer;
