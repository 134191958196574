import React, { useState } from "react";
import { useGetSearchHistoryQuery } from "../../api/api";
import { getHitDataAndCountsFromElasticSearchResponse } from "../../utils/elastic";
import { Stack, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { convertBodyParamNames, getUrl } from "../../utils/old_v1/helpers";
import { ago } from "../../utils/date";
import SearchHistoryLabels from "../../components/searchhistorylabels/SearchHistoryLabels";
import { FMPaginationNonLinks } from "../../components/pagination/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleHistoryCircleUser } from "@fortawesome/pro-regular-svg-icons";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import LoadingModal from "../../components/LoadingModal";
import { faMap, faSearch } from "@fortawesome/pro-light-svg-icons";
import useNextAwarePathname from "../../hooks/useNextAwarePathname";

const PAGE_SIZE = 100;

const SearchHistoryConnected = () => {
    const [page, setPage] = useState(1);
    let { data, isLoading } = useGetSearchHistoryQuery({ page, page_size: PAGE_SIZE }, { refetchOnMountOrArgChange: true });
    if (!isLoading && data && data.success) {
        data = getHitDataAndCountsFromElasticSearchResponse(data);
    }

    return isLoading ? <LoadingModal show={true} /> : <SearchHistory data={data} page={page} setPage={setPage} />;
};

export const SearchHistory = ({ data, page, setPage }: { data: any; page: number; setPage?: any }) => {
    useSetPageTitle("ForceMetrics | Search History");
    const { mainSearchPath, canPerformNextFESearch } = useNextAwarePathname();

    let totalPages = data && data.count && data.count.value;
    if (totalPages) {
        totalPages = Math.ceil(totalPages / 100);
    }

    const HistoryItemContent = ({ isMapSearch, text }: { isMapSearch: boolean; text: string }) => {
        return (
            <>
                <FontAwesomeIcon icon={isMapSearch ? faMap : faSearch} size="sm" className="me-2" />
                {text}
            </>
        );
    };
    return (
        <Stack direction="vertical" className="mx-4 flex-grow-1 overflow-auto">
            <h2 className="mt-3">
                <FontAwesomeIcon icon={faRectangleHistoryCircleUser} />
                &nbsp;Search History
            </h2>
            <Table responsive>
                <thead className="sticky-header">
                    <tr>
                        <th>Search Term</th>
                        <th>Filters</th>
                        <th>Searched</th>
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        data.hits &&
                        data.hits.map((search: any) => {
                            const isMapSearch = search.body.source === "map";
                            const queryOnlyParams = new URLSearchParams({ query: search?.body?.search_for || "" });

                            return (
                                <tr key={search.id} className={"history-item"}>
                                    <td>
                                        {!isMapSearch && canPerformNextFESearch ? (
                                            <a data-testid="history-item-link" href={`${mainSearchPath}?${queryOnlyParams.toString()}`}>
                                                <HistoryItemContent isMapSearch={false} text={search?.body?.search_for || ""} />
                                            </a>
                                        ) : (
                                            <Link
                                                data-testid="history-item-link"
                                                to={getUrl({ body: convertBodyParamNames(search.body) }, isMapSearch)}
                                            >
                                                <HistoryItemContent isMapSearch={isMapSearch} text={search?.body?.search_for || ""} />
                                            </Link>
                                        )}
                                    </td>
                                    <td>{search.body && <SearchHistoryLabels body={search.body} id={search.id} />}</td>
                                    <td>{ago(new Date(), search.saved_date)}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
            <div className="d-flex justify-content-end mt-4 mb-2">
                {totalPages > 0 && <FMPaginationNonLinks page={page} totalPages={totalPages} setPage={setPage} />}
            </div>
        </Stack>
    );
};

export default SearchHistoryConnected;
