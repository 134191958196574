import React from "react";
import { useGetUniqueCallTypesQuery } from "../../api/api";
import { processFilterAgg } from "../../utils/helpers";
import MultiSelectFilter from "./MultiSelectFilter";
import { TAggregationType, byNameSort } from "../../hooks/useSearchFiltersAggregations";
import aggregationDataPresenceCount from "../../utils/aggregationDataPresenceCount";
import useAgencySettings from "../../hooks/useAgencySettings";

const CallTypeFilterConnected = ({ callTypeAggregation }: { callTypeAggregation?: Array<TAggregationType> }) => {
    const { data: optionData } = useGetUniqueCallTypesQuery({});
    const { callText } = useAgencySettings();
    const callTypes = processFilterAgg(optionData);

    const options = React.useMemo(
        () =>
            callTypes
                .map((callType: { id: string; option: string }) => {
                    const { present, count } = aggregationDataPresenceCount(callTypeAggregation, callType?.option);

                    return {
                        id: callType?.id,
                        value: callType?.option,
                        name: callType?.option,
                        present,
                        count,
                    };
                })
                .sort(byNameSort),
        [callTypes, callTypeAggregation]
    );

    return (
        <MultiSelectFilter
            ariaLabel="Call Type"
            pendoClass="pendo_call_types_filter_select"
            options={options}
            urlParamName={"callTypes"}
            inputPlaceholder={`Select ${callText} Types`}
        />
    );
};

export default CallTypeFilterConnected;
