import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AppState = {
    toastDelay: number;
    toastMessage: string | null;
    showSitePickerModal: boolean;
    showDarkLightModeModal: boolean;
    showFeedbackModal: boolean;
    showUserMenu: boolean;
    currentCommunityTab: string | null;
};

const initialState: AppState = {
    toastDelay: 3000,
    toastMessage: null,
    showSitePickerModal: false,
    showDarkLightModeModal: false,
    showFeedbackModal: false,
    showUserMenu: false,
    currentCommunityTab: null,
};

/*
 * Function to generate the action creators and action types for reducers that relate to app
 */
export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setToastMessage: (state, action: PayloadAction<string>) => {
            state.toastDelay = initialState.toastDelay;
            state.toastMessage = action.payload;
        },
        setToastMessageWithConfig: (state, action: PayloadAction<{ delay: number | undefined; message: string }>) => {
            state.toastDelay = action.payload.delay ?? initialState.toastDelay;
            state.toastMessage = action.payload.message;
        },
        setShowSitePickerModal: (state, action: PayloadAction<boolean>) => {
            state.showSitePickerModal = action.payload;
        },
        setShowFeedbackModal: (state, action: PayloadAction<boolean>) => {
            state.showFeedbackModal = action.payload;
        },
        setShowDarkLightModeModal: (state, action: PayloadAction<boolean>) => {
            state.showDarkLightModeModal = action.payload;
        },
        setCurrentCommunityTab: (state, action: PayloadAction<string>) => {
            state.currentCommunityTab = action.payload;
        },
        setShowUserMenu: (state, action: PayloadAction<boolean>) => {
            state.showUserMenu = action.payload;
        },
    },
});

export const {
    setToastMessage,
    setToastMessageWithConfig,
    setShowSitePickerModal,
    setShowFeedbackModal,
    setShowDarkLightModeModal,
    setCurrentCommunityTab,
    setShowUserMenu,
} = appSlice.actions;

export default appSlice.reducer;
