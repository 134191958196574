import React from "react";

const ContactMeToggle = ({ field, props }: any) => {
    return (
        <div className="form-check form-switch d-inline-block ms-4">
            <input
                className="form-check-input"
                type="checkbox"
                id="contactMeSwitch"
                name="contactMe"
                {...field}
                {...props}
                data-testid="toggle"
            />
            <label className="form-check-label" htmlFor="contactMeSwitch"></label>
        </div>
    );
};

export default ContactMeToggle;
