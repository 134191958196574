import { Card } from "react-bootstrap";
import React from "react";
import { faMapPin, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { INCIDENT_ACCENT } from "../../utils/colors";

type OwnProps = {
    mapHighlight: boolean;
    icon: IconDefinition;
    iconColor: string;
    customIconClass?: string;
    leftContent: React.ReactNode;
    rightContent?: React.ReactNode;
};

const ResultHeader = ({ mapHighlight, icon, iconColor, customIconClass, leftContent, rightContent }: OwnProps) => {
    return (
        <Card.Header className={`${mapHighlight ? "bg-danger bg-opacity-25" : ""} d-flex gap-3 border rounded`}>
            {mapHighlight && <FontAwesomeIcon icon={faMapPin} className="fs-2" color={INCIDENT_ACCENT} />}
            <FontAwesomeIcon icon={icon} className={`fs-2 ${customIconClass}`} color={iconColor} />
            <div className="d-flex flex-row flex-grow-1 flex-wrap">
                <div className="d-flex flex-column flex-grow-1">{leftContent}</div>
                {rightContent}
            </div>
        </Card.Header>
    );
};

export default ResultHeader;
