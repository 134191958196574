import { faCircleXmark, faInfoCircle, faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, OverlayTrigger, Popover, Table, Tooltip } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useColumnSort } from "../../hooks/useColumnSort";
import { formatBirthdayAndDOB } from "../../utils/date";
import SortableHeaderColumn from "../../components/SortableHeader";
import { renderOrDash } from "../../utils/display";
import { sortSortableHeader } from "../../utils/sort";
import { usePagination } from "../../hooks/usePagination";
import { FMPaginationNonLinks } from "../../components/pagination/Pagination";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import { SortDirectionEnum } from "../../types/types";
import React from "react";
import useAgencySettings from "../../hooks/useAgencySettings";

const DEFAULT_SORT = "total";
const DEFAULT_SORT_ORDER = SortDirectionEnum.DESCENDING;

const InvolvedPeople = ({
    people,
    ignorePersonSelect = false,
    dashboardTable = false,
    eventCount = 0,
}: {
    people: any;
    ignorePersonSelect?: boolean;
    dashboardTable?: boolean;
    eventCount?: number;
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const personId = searchParams.get("personId");
    const [showWarningBanner, setShowWarningBanner] = React.useState<boolean>(true);

    const { constructPersonUrl } = useMultiAgency();
    const { callTextPlural, incidentTextPlural } = useAgencySettings();

    const onPersonSelect = (pid: any) => {
        let updatedSearchParams = new URLSearchParams(searchParams.toString());
        setSearchParams(updatedSearchParams.toString());
        if (pid === personId) {
            updatedSearchParams.delete("personId");
        } else {
            updatedSearchParams.set("personId", pid);
        }
        setSearchParams(updatedSearchParams.toString());
    };

    const filteredPeople = dashboardTable ? [...people.filter((p: any) => p.total > 0)] : people;

    const { sortColumn, sortDirection, setSort } = useColumnSort(DEFAULT_SORT, DEFAULT_SORT_ORDER);
    const sortedPeople = (Array.isArray(filteredPeople) ? [...filteredPeople] : []).sort(
        sortSortableHeader(sortColumn, ["last_name", "first_name", "birthdate"].includes(sortColumn), sortDirection)
    );

    const { currentPage, setCurrentPage, currentPageData: peopleArray, totalPages } = usePagination(sortedPeople, 10, searchParams);

    React.useEffect(() => {
        if (searchParams.get("indexType") === "calls") {
            setShowWarningBanner(true);
        }
    }, [searchParams]);
    // if clickable, return a clickable row for filtering and clickable icon for person details page
    // Otherwise, return a non-clickable row wrapped in a popover
    const renderRow = (p: any) => {
        if (p.clickable) {
            return (
                <tr
                    key={p.person_id}
                    onClick={() => !ignorePersonSelect && onPersonSelect(p.person_id)}
                    className={`${ignorePersonSelect ? "" : "cursor-pointer"}${
                        p.person_id === personId ? " table-info" : ""
                    } pendo-radar-select-person`}
                >
                    <td>
                        {p.person_id === personId && <FontAwesomeIcon icon={faCircleXmark} size="sm" className="me-1" />}
                        {renderOrDash(p.last_name)}
                    </td>
                    <td>{renderOrDash(p.first_name)}</td>
                    <td className="text-nowrap">{formatBirthdayAndDOB(p.birthdate)}</td>
                    <td className="text-center">{p.offender}</td>
                    <td className="text-center">{p.victim}</td>
                    <td className="text-center">{p.mention}</td>
                    <td className="text-center">{p.total}</td>
                    <td className="text-center">
                        <a
                            href={constructPersonUrl(p.agency_id, p).url}
                            className="pendo-radar-select-person-page-link"
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <FontAwesomeIcon icon={faUpRightFromSquare} />
                        </a>
                    </td>
                </tr>
            );
        } else {
            return (
                <OverlayTrigger
                    key={p.person_id}
                    trigger={["hover", "focus"]}
                    delay={250}
                    placement="top"
                    rootClose
                    overlay={
                        <Popover id={p.person_id}>
                            <Popover.Header>Missing ID</Popover.Header>
                            <Popover.Body>
                                This person does not have an ID in the source data so filtering and viewing details are disabled.
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <tr className="cursor-help">
                        <td>{renderOrDash(p.last_name)}</td>
                        <td>{renderOrDash(p.first_name)}</td>
                        <td className="text-nowrap">{formatBirthdayAndDOB(p.birthdate)}</td>
                        <td className="text-center">{p.offender}</td>
                        <td className="text-center">{p.victim}</td>
                        <td className="text-center">{p.mention}</td>
                        <td className="text-center">{p.total}</td>
                        <td className="text-center"></td>
                    </tr>
                </OverlayTrigger>
            );
        }
    };

    return (
        <>
            {searchParams.get("indexType") === "calls" && showWarningBanner && (
                <Alert
                    className="fw-normal my-2 d-inline-flex gap-4 align-items-center justify-content-between"
                    variant="warning"
                    dismissible
                    onClick={() => setShowWarningBanner(false)}
                >
                    <div>
                        <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                        {callTextPlural} are not connected to active people.
                    </div>
                </Alert>
            )}
            <div className="text-muted font-weight-normal ms-1 position-relative">
                <small>People: {sortedPeople.length || 0}</small>
                <OverlayTrigger
                    overlay={
                        <Tooltip className="position-fixed" id="radar-active-people-tip">
                            Active people are associated people from {incidentTextPlural}.
                        </Tooltip>
                    }
                    placement="bottom"
                    trigger={["hover", "focus", "click"]}
                >
                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                </OverlayTrigger>
            </div>
            <div id="involvedPeopleTable" className="table-responsive">
                <Table size="sm" className="flex-grow-1 overflow-auto" data-testid="active-people-table">
                    <thead className="fm-bg-color sticky-header">
                        <tr>
                            <SortableHeaderColumn
                                columnName="Last Name"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey="last_name"
                                defaultDirection={SortDirectionEnum.ASCENDING}
                            />
                            <SortableHeaderColumn
                                columnName="First Name"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey="first_name"
                                defaultDirection={SortDirectionEnum.ASCENDING}
                            />
                            <SortableHeaderColumn
                                columnName="DOB (Age)"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey="birthdate"
                                defaultDirection={SortDirectionEnum.ASCENDING}
                            />
                            <SortableHeaderColumn
                                columnName="Offender"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey="offender"
                                defaultDirection={SortDirectionEnum.DESCENDING}
                            />
                            <SortableHeaderColumn
                                columnName="Victim"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey="victim"
                                defaultDirection={SortDirectionEnum.DESCENDING}
                            />
                            <SortableHeaderColumn
                                columnName="Involved"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey="mention"
                                defaultDirection={SortDirectionEnum.DESCENDING}
                            />
                            <SortableHeaderColumn
                                columnName="Total"
                                sortFn={setSort}
                                activeSortField={sortColumn}
                                activeSortDirection={sortDirection}
                                sortKey={DEFAULT_SORT}
                                defaultDirection={SortDirectionEnum.DESCENDING}
                            />
                            <th />
                        </tr>
                    </thead>
                    <tbody>{peopleArray.map((p: any) => renderRow(p))}</tbody>
                </Table>
            </div>
            {totalPages > 1 && (
                <div className="d-flex justify-content-end mt-2">
                    <FMPaginationNonLinks
                        page={currentPage + 1}
                        totalPages={totalPages}
                        setPage={(page: number) => {
                            setCurrentPage(page - 1);
                            document.getElementById("involvedPeopleTable")?.scrollTo({ top: 0 });
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default InvolvedPeople;
