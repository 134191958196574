import React from "react";
import { Alert, Container, Modal } from "react-bootstrap";
import ModalHeader from "../../../components/ModalHeader";
import BeautifiedKeyValuePairDisplay from "../../../utils/beautifiedkeyvaluepairdisplay/BeautifiedKeyValuePairDisplay";
import useAgencySettings from "../../../hooks/useAgencySettings";

const CallNotesModal = ({
    callNotes,
    show,
    close,
    agencyId,
}: {
    callNotes: any;
    show: boolean;
    close: any;
    agencyId: string | null | undefined;
}) => {
    const { callText } = useAgencySettings();
    let sortedCallNotes = [...callNotes];
    if (agencyId === "tx-wcpd1") {
        sortedCallNotes = sortedCallNotes.sort((a: any, b: any) => {
            let aVal = 10000;
            const aObj = a.find((a: any) => a.beautifiedKey === "sequence id");
            if (aObj && !isNaN(aObj.value)) {
                aVal = aObj.value;
            }
            let bVal = 10000;
            const bObj = b.find((b: any) => b.beautifiedKey === "sequence id");
            if (bObj && !isNaN(bObj.value)) {
                bVal = bObj.value;
            }
            return aVal > bVal ? 1 : -1;
        });
    }

    return (
        <Modal show={show} onHide={close} size="xl" scrollable={true}>
            <ModalHeader title="Call Notes" />
            <Modal.Body className="px-0">
                <Container fluid>
                    {sortedCallNotes && sortedCallNotes.length ? (
                        sortedCallNotes.map((pair: any, index: number) => (
                            <React.Fragment key={index}>
                                <BeautifiedKeyValuePairDisplay value={pair} showBreak={index < sortedCallNotes.length} />
                            </React.Fragment>
                        ))
                    ) : (
                        <Alert variant="warning">No {callText} notes at this time. Data is processing.</Alert>
                    )}
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default CallNotesModal;
