import React from "react";
import { useSearchParams } from "react-router-dom";
import { getTagKey, getTagName } from "../../utils/TagDetails";
import Histogram from "../insightsdashboard/charts/Histogram";

type OwnProps = {
    tags: any;
    isClickable: boolean;
    indexType?: string;
};

const TagsHistogram = ({ tags, isClickable, indexType }: OwnProps) => {
    const [searchParams] = useSearchParams();
    const selectedTagsList = searchParams.get("tags");
    const selectedTags = selectedTagsList ? selectedTagsList.split(",") : [];

    const processedData =
        tags &&
        Object.keys(tags)
            .map((tag: string) => ({ tag, tagDisplay: getTagName(tag) }))
            .sort((a: any, b: any) => a.tagDisplay.localeCompare(b.tagDisplay))
            .map((tagDetails: any) => tagDetails.tag)
            .reduce(
                (coll: any, curr: string) => {
                    coll.labels.push(getTagName(curr));
                    const dataPoint = { y: tags[curr] } as { y: number; color?: string };
                    if (selectedTags.includes(curr)) {
                        dataPoint["color"] = "#007bff";
                    }
                    coll.counts.push(dataPoint);
                    return coll;
                },
                { labels: [], counts: [] }
            );

    return (
        processedData && (
            <Histogram
                title=""
                processedData={processedData || {}}
                xAxis=""
                yAxis="Number of Events"
                onPointClick={
                    isClickable
                        ? (e: any) => {
                              const newSearchParams = new URLSearchParams(searchParams);
                              newSearchParams.set("tags", getTagKey(e.point.category));
                              if (indexType) {
                                  newSearchParams.set("indexType", indexType);
                              }
                              window.open(`/app/search?${newSearchParams}`, "_blank");
                          }
                        : undefined
                }
            />
        )
    );
};

export default TagsHistogram;
