import React from "react";
import { useGetEventsQuery } from "../api/api";
import { createGeoJson } from "../components/map/utils/gis";
import { CALL_ACCENT, INCIDENT_ACCENT, MAP_BORDER_ACCENT, WHITE } from "../utils/colors";
import { LayerTypes, SearchQueryParams, SourceConfig } from "../types/old_v1/types";
import { elasticHitsExtraction } from "../utils/elastic";

export type EventSearchLayerConfig = {
    eventSourceConfig: SourceConfig | undefined;
    eventsFetching: boolean;
    eventsLoading: boolean;
    eventHits: any;
    eventCount: number;
    eventUnpagedCounts: number;
};

/**
 * Hook used for fetching and building an event search layer
 * @param {SearchQueryParams} searchQueryParams Query parameters used for calling search API.
 * @returns {EventSearchLayerConfig}
 */

export const useEventSearchLayer = (searchQueryParams: SearchQueryParams): EventSearchLayerConfig => {
    const queryParams = { ...searchQueryParams };
    // override the parameters that could come from URL
    queryParams["type"] = "events";

    const [eventSourceConfig, setEventSourceConfig] = React.useState<SourceConfig | undefined>();
    const eventResponse = useGetEventsQuery(queryParams, {
        skip: queryParams.query === null || (queryParams.entity !== null && !queryParams?.entity?.split(",").includes(LayerTypes.event)),
    });

    React.useEffect(() => {
        if (eventResponse && eventResponse.data) {
            const hits = elasticHitsExtraction(eventResponse.data);
            if (hits) {
                const config = prepareEventMapConfig(hits, true);
                setEventSourceConfig(config);
            }
        }
    }, [eventResponse]);

    return {
        eventSourceConfig,
        eventsFetching: eventResponse.isFetching,
        eventsLoading: eventResponse.isLoading,
        eventHits: eventResponse.data ? elasticHitsExtraction(eventResponse.data) : [],
        eventCount: eventResponse.data && eventResponse.data.success ? eventResponse.data.hits.hits.length : 0,
        eventUnpagedCounts: eventResponse.data && eventResponse.data.success ? eventResponse.data.hits.total.value : 0,
    };
};

export const prepareEventMapConfig = (hits: Array<any>, cluster: boolean, pinnedResult?: any) => {
    const features = createGeoJson(hits);

    const config: SourceConfig = {
        data: features,
        id: LayerTypes.event,
        setBounds: true,
        cluster: cluster,
        layers: [
            {
                id: "event-layer",
                type: "circle",
                source: "event-layer",
                filter: ["==", ["get", "type"], "call"],
                paint: {
                    "circle-color": CALL_ACCENT,
                    "circle-radius": 8,
                    "circle-stroke-color": ["case", ["==", ["get", "id"], pinnedResult?.id || ""], "#FF0000", MAP_BORDER_ACCENT],
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": 0.7,
                },
            },
            {
                id: "event-incident-layer",
                type: "circle",
                source: "event-layer",
                filter: ["==", ["get", "type"], "incident"],
                paint: {
                    "circle-color": INCIDENT_ACCENT,
                    "circle-radius": 8,
                    "circle-stroke-color": ["case", ["==", ["get", "id"], pinnedResult?.id || ""], WHITE, MAP_BORDER_ACCENT],
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": 0.7,
                },
            },
            {
                id: "event-layer-cluster",
                type: "circle",
                source: "event-layer",
                filter: ["has", "point_count"],
                paint: {
                    "circle-color": INCIDENT_ACCENT,
                    "circle-stroke-color": MAP_BORDER_ACCENT,
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": 0.7,
                    "circle-radius": ["step", ["get", "point_count"], 10, 20, 20, 30, 30, 40, 40, 50, 50],
                },
            },
            {
                id: "event-layer-cluster-count",
                type: "symbol",
                source: "event-layer",
                filter: ["has", "point_count"],
                layout: {
                    "text-field": "{point_count}",
                    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                    "text-size": 12,
                },
                paint: {
                    "text-color": "white",
                },
            },
        ],
    };
    return config;
};
