import React from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import UserMenu from "../UserMenu";
import { useGetFollowsHitsQuery, useGetRadarConfigsQuery, useGetSharedUrlsForUserQuery } from "../../api/api";
import { resetUserAgencyCoords, setFollowsList, setUserAgencyCoords } from "../../app/slices/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FeedbackModal from "../../pages/feedbackmodal/FeedbackModal";
import { identifyPendoUser, initializePendo } from "../../utils/pendo";
import "./navigation.css";
import EulaModal from "../../pages/EulaModal";
import NotificationsList from "../../pages/NotificationsList";
import LargeNavBar from "./LargeNavBar";
import MobileNavBar from "./MobileNavBar";
import usePermissions from "../../hooks/usePermissions";
import ErrorBoundary from "../../pages/support/errorboundary/ErrorBoundary";

type OwnProps = {
    showNotifications: boolean;
    setShowNotifications: Function;
};
const Navigation = ({ showNotifications, setShowNotifications }: OwnProps) => {
    initializePendo();
    const { hasPermission } = usePermissions();
    const [showUserMenu, setShowUserMenu] = React.useState<boolean>(false);
    const user = useAppSelector((state) => state.user.userObject);
    const { data: followsData } = useGetFollowsHitsQuery(undefined, { skip: !hasPermission("follows") });
    const { data: sharesData } = useGetSharedUrlsForUserQuery({}, { skip: !hasPermission("share") });
    const { data: radarConfigsData } = useGetRadarConfigsQuery({});
    const [follows, setFollows] = React.useState<any[]>([]);
    const [followsCount, setFollowsCount] = React.useState(0);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (followsData && followsData.success && followsData.follows_hits) {
            setFollows(followsData.follows_hits);
            const count = followsData.follows_hits.filter((f: any) => f.eventCount > 0).length;
            setFollowsCount(count);
            dispatch(setFollowsList(followsData.follows_hits));
        }
    }, [followsData, dispatch]);

    React.useEffect(() => {
        if (user && user.id) {
            identifyPendoUser(user);
            if (user.agency_coordinates) {
                const [latitude, longitude] = user.agency_coordinates;
                if (latitude && longitude) {
                    dispatch(
                        setUserAgencyCoords({
                            latitude: parseFloat(latitude),
                            longitude: parseFloat(longitude),
                            zoom: 9,
                        })
                    );
                }
            } else {
                dispatch(resetUserAgencyCoords());
            }
        }
    }, [user, dispatch]);

    const shareHits = sharesData?.share_hits || [];
    const notificationsCount = followsCount + shareHits.filter((share: any) => !share?.viewed_at).length;

    return (
        <React.Fragment>
            <MobileNavBar
                notificationsCount={notificationsCount}
                setShowNotifications={setShowNotifications}
                setShowUserMenu={setShowUserMenu}
            />
            <LargeNavBar
                notificationsCount={notificationsCount}
                setShowNotifications={setShowNotifications}
                setShowUserMenu={setShowUserMenu}
                radarConfigsData={radarConfigsData}
            />
            <NotificationsList
                follows={follows}
                shares={shareHits}
                showNotifications={showNotifications}
                setShowNotifications={setShowNotifications}
            />
            <UserMenu showUserMenu={showUserMenu} setShowUserMenu={setShowUserMenu} />
            <FeedbackModal />
            <EulaModal />
            {/* boundary placed here so that error below does not block user from viewing user menu or navbar  */}
            <ErrorBoundary errorComponentVariant="page">
                <Container id="mainContainer" fluid className="fm-bg-color-darker d-flex flex-column flex-grow-1 overflow-auto g-0 g-md-3">
                    <Outlet />
                </Container>
            </ErrorBoundary>
        </React.Fragment>
    );
};

export default Navigation;
