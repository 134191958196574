import React from "react";
import { Row, Col } from "react-bootstrap";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as solidCircle, faLock as solidLock, faPenCircle as solidPenCircle } from "@fortawesome/pro-solid-svg-icons";
import { faPenCircle } from "@fortawesome/pro-light-svg-icons";
import { OnboardingObject } from "../../types/old_v1/types";
import { useAppSelector } from "../../app/hooks";

type ContextType = { setStep: Function };

type OwnProps = {
    onboardingObject: OnboardingObject | null;
};

const ReconfigureMfaConnected = () => {
    const user = useAppSelector(({ user }) => user);
    const onboardingObject = user?.onboardingObject;
    return <ReconfigureMfa onboardingObject={onboardingObject} />;
};

/**
 * Wrapper around the 2 MFA reconfigure screens for active users that have their MFA type reset.  This component displays the
 * 2 step icons and manages their display state.
 * @param onboardingObject
 * @constructor
 */
export const ReconfigureMfa = ({ onboardingObject }: OwnProps) => {
    const initialStep = onboardingObject?.step || 1;
    const [step, setStep] = React.useState<number>(initialStep);
    const navigate = useNavigate();

    return (
        <div>
            <div className="d-flex justify-content-center mb-5">
                <h1>Multi-Factor Verification</h1>
            </div>
            <Row className="justify-content-center">
                <Col xs={10} sm={8} md={6} lg={4} className="pb-3 border-bottom border-light">
                    <div className="d-flex justify-content-between">
                        <div
                            className="d-flex flex-column justify-content-start align-items-center cursor-pointer"
                            onClick={() => navigate("/auth/reconfigure-mfa/select-mfa")}
                        >
                            <span className="fa-layers fa-fw fa-3x text-primary">
                                <FontAwesomeIcon icon={solidCircle} />
                                <FontAwesomeIcon className="color-filled" icon={solidLock} transform="shrink-6" />
                            </span>
                            {step === 1 ? (
                                <strong className="text-primary">Select Multi-Factor</strong>
                            ) : (
                                <span className="text-primary">Select Multi-Factor</span>
                            )}
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-center">
                            <FontAwesomeIcon
                                icon={step > 1 ? solidPenCircle : faPenCircle}
                                size="3x"
                                className={`${step > 1 ? "text-primary" : "text-secondary"}`}
                            />
                            {step === 3 ? (
                                <strong className="text-primary">Enter Code</strong>
                            ) : (
                                <span className={`${step > 1 ? "text-primary" : "text-muted"}`}>Enter Code</span>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            <Outlet context={{ setStep }} />
        </div>
    );
};

ReconfigureMfa.displayName = "ReconfigureMfa";
export default ReconfigureMfaConnected;

export function useSetStep() {
    return useOutletContext<ContextType>();
}
