import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PopupProperties = {
    eventCount?: any;
    address?: string;
    date?: string;
    callType?: string;
    callNumber?: string;
    name?: string;
    type?: string;
    lng: number;
    lat: number;
    callCount?: number;
    mostRecentCallNature?: string;
    useOffset?: boolean;
    number?: string;
    incidentType?: string;
    lookupIds?: string[];
    closeButton?: boolean;
    totalCalls?: number;
    totalPeople?: number;
    totalAddresses?: number;
    totalIncidents?: number;
    itemList?: any[];
    searchQuery?: string;
    hoverEvent?: Function;
};

export type MapState = {
    showPopup: boolean;
    popupProperties?: PopupProperties;
    showMapModal: boolean;
};

const initialState: MapState = {
    showPopup: false,
    showMapModal: false,
};

export const mapSlice = createSlice({
    name: "map",
    initialState,
    reducers: {
        setShowPopup: (state, action: PayloadAction<boolean>) => {
            state.showPopup = action.payload;
        },
        setPopupProperties: (state, action: PayloadAction<PopupProperties>) => {
            state.popupProperties = action.payload;
        },
    },
});

export const { setShowPopup, setPopupProperties } = mapSlice.actions;

export default mapSlice.reducer;
