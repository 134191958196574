import React from "react";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGavel, faHome, faPhoneSquare, faUser } from "@fortawesome/pro-light-svg-icons";
import { Link, useSearchParams } from "react-router-dom";
import { LayerTypes } from "../../types/old_v1/types";
import Highlighter from "../../components/highlighter/Highlighter";
import { formatDate } from "../../utils/date";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import useAgencySettings from "../../hooks/useAgencySettings";

export type PopupProperties = {
    eventCount?: any;
    address?: string;
    date?: string;
    callType?: string;
    callNumber?: string;
    name?: string;
    type?: string;
    lng: number;
    lat: number;
    callCount?: number;
    mostRecentCallNature?: string;
    useOffset?: boolean;
    number?: string;
    incidentType?: string;
    lookupIds?: string[];
    closeButton?: boolean;
    totalCalls?: number;
    totalPeople?: number;
    totalAddresses?: number;
    totalIncidents?: number;
    itemList?: any[];
    searchQuery?: string;
    hoverEvent?: Function;
};

const Popup = ({
    address,
    date,
    callType,
    callNumber,
    name,
    type,
    eventCount,
    number,
    incidentType,
    totalAddresses,
    totalCalls,
    totalPeople,
    totalIncidents,
    itemList,
    searchQuery,
    hoverEvent,
}: PopupProperties) => {
    const { constructPersonUrl, constructEventUrl, constructAddressUrl } = useMultiAgency();
    const [searchParams] = useSearchParams();
    const { incidentTextPlural, callTextPlural } = useAgencySettings();
    /**
     * When hovering on a popup cluster list item this will highlight and scroll to the corresponding event in the list
     * @param item
     */
    const mouseHoverEvent = (item: any) => {
        if (hoverEvent && item) {
            hoverEvent(item);
        }
    };

    const buildListGroupLink = (item: any) => {
        let url = "";
        switch (item.type) {
            case "address":
                url = constructAddressUrl(item.agency_id, item, searchQuery);
                break;
            case "person":
                url = constructPersonUrl(item.agency_id, item, searchParams).url;
                break;
            case "incident":
            case "call":
                url = constructEventUrl(item.agency_id, item.call_number, item.incident_number, searchParams);
                break;
        }

        return url;
    };

    if (type === LayerTypes.call) {
        return (
            <div data-testid="call-popup-container" className="popup-content">
                <h5 className="fw-bold text-black mb-1">
                    <Highlighter>{address}</Highlighter>
                </h5>
                <div>
                    <div>{!!date ? <small className="text-black">{formatDate(date)}</small> : null}</div>
                    <small className="text-black">
                        <Highlighter>{callNumber}</Highlighter> - <Highlighter>{callType}</Highlighter>
                    </small>
                </div>
            </div>
        );
    } else if (type === LayerTypes.address) {
        return (
            <div data-testid="address-popup-container" className="popup-content">
                <h5 className="fw-bold text-black mb-1">
                    <Highlighter>{address}</Highlighter>
                </h5>
                <div>
                    <div>
                        <small className="text-black">Event Count: {isNaN(eventCount) ? 0 : eventCount}</small>
                    </div>
                </div>
            </div>
        );
    } else if (type === LayerTypes.incident) {
        return (
            <div data-testid="incident-popup-container" className="popup-content">
                <h5 className="fw-bold text-black mb-1">
                    <Highlighter>{address}</Highlighter>
                </h5>
                <div>
                    <div>{!!date ? <small className="text-black">{formatDate(date)}</small> : null}</div>
                    <small className="text-black">
                        <Highlighter>{number}</Highlighter> - <Highlighter>{incidentType}</Highlighter>
                    </small>
                </div>
            </div>
        );
    } else if (type === LayerTypes.person) {
        return (
            <div data-testid="person-popup-container" className="popup-content">
                <h5 className="fw-bold text-black mb-1">
                    <Highlighter>{name}</Highlighter>
                </h5>
                <div>
                    <div>
                        <small className="text-black">
                            <Highlighter>{address}</Highlighter>
                        </small>
                    </div>
                </div>
            </div>
        );
    } else if (type === LayerTypes.event) {
        return (
            <div data-testid="event-popup-container" className="popup-content">
                <h5 className="fw-bold text-black mb-1">
                    <Highlighter>{address}</Highlighter>
                </h5>
                <div>
                    <div>{!!date ? <small className="text-black">{formatDate(date)}</small> : null}</div>
                    <small className="text-black">
                        <Highlighter>{number}</Highlighter> - <Highlighter>{incidentType}</Highlighter>
                    </small>
                    <small className="text-black">
                        <Highlighter>{callNumber}</Highlighter> - <Highlighter>{callType}</Highlighter>
                    </small>
                </div>
            </div>
        );
    } else if (type === LayerTypes.list) {
        return (
            <div className="popup-content">
                <h5 className="fw-bold text-black mb-1">Multiple Results</h5>
                <div>
                    <div>
                        <small className="text-black">Addresses: {totalAddresses}</small>
                    </div>
                    <div>
                        <small className="text-black">
                            {callTextPlural}: {totalCalls}
                        </small>
                    </div>
                    <div>
                        <small className="text-black">
                            {incidentTextPlural}: {totalIncidents}
                        </small>
                    </div>
                    <div>
                        <small className="text-black">People: {totalPeople}</small>
                    </div>
                </div>
            </div>
        );
    } else if (type === LayerTypes.clickList) {
        return (
            <ListGroup className="map-app-popup-container popup-content">
                {itemList?.map((item, index) => (
                    <ListGroup.Item key={item.id + "_" + index}>
                        <Link
                            to={buildListGroupLink(item)}
                            target="_blank"
                            onMouseEnter={() => mouseHoverEvent(item)}
                            onMouseLeave={() => (hoverEvent ? hoverEvent({}) : null)}
                        >
                            <div className="row">
                                <div className="col d-flex">
                                    <div className="me-3">
                                        {item.type === LayerTypes.call && <FontAwesomeIcon icon={faPhoneSquare} color="blue" />}
                                        {item.type === LayerTypes.address && <FontAwesomeIcon icon={faHome} color="red" />}
                                        {item.type === LayerTypes.person && <FontAwesomeIcon icon={faUser} color="green" />}
                                        {item.type === LayerTypes.incident && <FontAwesomeIcon icon={faGavel} color="orange" />}
                                    </div>
                                    <div>
                                        {item.type === LayerTypes.call && (
                                            <div className="text-bold">
                                                <Highlighter>{item.call_type}</Highlighter>-<Highlighter>{item.call_number}</Highlighter>
                                            </div>
                                        )}
                                        {item.type === LayerTypes.address && (
                                            <div className="text-bold">
                                                <Highlighter>{item.address}</Highlighter>
                                            </div>
                                        )}
                                        {item.type === LayerTypes.person && (
                                            <div className="text-bold">
                                                <Highlighter>{item.full_name}</Highlighter>
                                            </div>
                                        )}
                                        {item.type === LayerTypes.incident && (
                                            <div className="text-bold">
                                                <Highlighter>{item.incident_type}</Highlighter> - <Highlighter>{item.id}</Highlighter>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        );
    } else {
        return <div />;
    }
};

export default Popup;
