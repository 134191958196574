import { useAppSelector } from "../app/hooks";

/**
 * Small hook with logic related to Walmart specific agencies
 */
export function useWalmart() {
    const agencyId = useAppSelector((state) => state.user && state.user.userObject && state.user.userObject.agency_shortname);

    return {
        // current agency is a walmart agency if prefixed with `wm-`
        isWalmart: !!agencyId?.startsWith("wm-"),
    };
}
