import React from "react";
import { getKeyValuePairs } from "../../utils/getKeyValuePairs";
import Highlighter from "../../components/highlighter/Highlighter";
import { parseAsHTML } from "../string";

type OwnProps = {
    beautifiedKey?: string;
    showBreak?: boolean;
    value: any;
};

type ArrayDisplayOwnProps = {
    index: number;
    length: number;
    val: any;
};

const ArrayDisplay = ({ val, index, length }: ArrayDisplayOwnProps) => {
    if (typeof val === "string" || typeof val === "number") {
        return (
            <span className="me-1" key={`${index}${val}`} style={{ whiteSpace: "pre-line" }}>
                <Highlighter>{val}</Highlighter>
                {index !== length - 1 ? ", " : ""}
            </span>
        );
    } else if (Array.isArray(val)) {
        return (
            <>
                {val.map((innerVal, innerIndex) => (
                    <React.Fragment key={`${innerIndex}+${index}`}>
                        <ArrayDisplay val={innerVal} index={innerIndex} length={val.length} />
                        {innerIndex === val.length - 1 && index !== length - 1 ? ", " : ""}
                    </React.Fragment>
                ))}
            </>
        );
    } else {
        return (
            <div className="ms-2">
                {<BeautifiedKeyValuePairDisplay key={`${index}`} beautifiedKey={val.beautifiedKey} value={val.value} />}
            </div>
        );
    }
};

const BeautifiedKeyValuePairDisplay = ({ beautifiedKey, value, showBreak }: OwnProps) => {
    if (typeof value === "string" || typeof value === "number") {
        return (
            <div className="mb-2" key={`${beautifiedKey}`}>
                <span className="fw-bold text-capitalize">{beautifiedKey}</span>:{" "}
                <span style={{ whiteSpace: "pre-line" }}>
                    {value && typeof value === "string" ? (
                        <div>{parseAsHTML(value)}</div>
                    ) : value && typeof value === "number" ? (
                        <Highlighter>{value}</Highlighter>
                    ) : (
                        "--"
                    )}
                </span>
            </div>
        );
    } else if (Array.isArray(value)) {
        return (
            <div className="mb-2" key={`${beautifiedKey}`}>
                {value.map((val, index) => (
                    <React.Fragment key={index}>
                        <ArrayDisplay val={val} index={index} length={value.length} />
                    </React.Fragment>
                ))}
                {showBreak ? <hr /> : null}
            </div>
        );
    } else if (value) {
        const pairs = getKeyValuePairs(value);
        return (
            <div className="mb-2" key={`${beautifiedKey}`}>
                <span className="fw-bold text-capitalize">{beautifiedKey}</span>:{" "}
                <div className="ms-2">
                    {pairs.map((pair, index) => (
                        <React.Fragment key={index}>
                            <BeautifiedKeyValuePairDisplay {...pair} />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default BeautifiedKeyValuePairDisplay;
