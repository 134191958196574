import React from "react";
import { useSearchParams } from "react-router-dom";
import Select, { components, OptionProps } from "react-select";
import { useFilters } from "../../hooks/useFilters";

interface ICustomOptions {
    name?: string;
    value: string;
    label?: string;
    present?: boolean | null | undefined;
    count?: number | null | undefined;
    ariaLabel?: string;
}

type OwnProps = {
    ariaLabel?: string;
    options: ICustomOptions[];
    pendoClass?: string;
    urlParamName: string;
    inputPlaceholder?: string;
    prebuiltSelectedOptions?: any[];
    addParamToUrl?: boolean;
};

const MultiSelectFilter = ({
    ariaLabel = "",
    options,
    pendoClass = "",
    urlParamName,
    inputPlaceholder,
    prebuiltSelectedOptions,
    addParamToUrl = true,
}: OwnProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const paramValue = searchParams.get(urlParamName);
    const { updateFilters, filters } = useFilters({ skipBeatsQuery: true });

    const [selectedList, setSelectedList] = React.useState<string[]>([]);
    const [input, setInput] = React.useState("");
    const [selectionLoader, setSelectionLoader] = React.useState(true);

    // used for quick name lookup based on value
    const optionsDictionary: { [key: string]: string | undefined } = {};
    options.forEach((option) => {
        optionsDictionary[option.value] = option.name;
    });

    // Now that we are using a filter object to maintain filter data that is not present in the url we will be pre-building
    // some of the filters that require more specific complicated logic such as beats
    const selectedOptions = prebuiltSelectedOptions
        ? prebuiltSelectedOptions
        : selectedList
              .filter((optionValue) => !!optionsDictionary[optionValue])
              .map((optionValue) => {
                  return { value: optionValue, label: optionsDictionary[optionValue] };
              });

    React.useEffect(() => {
        if (!prebuiltSelectedOptions) {
            let decodedParamList = paramValue?.split(",").map((item) => decodeURIComponent(item || "")) || [];
            setSelectedList(decodedParamList);
        }
        // Once paramValue changes for this component, we can assume selectionLoader is ready to be turned off.
        // This is the case whether added or removed values.
        setSelectionLoader(false);
    }, [paramValue, prebuiltSelectedOptions]);

    const apply = (newList?: any[]) => {
        setSelectionLoader(true);
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete("page");
        if (newList && newList.length) {
            // With the creation of the filter object to maintain filter data we will slowly phase out adding filters independently
            // to the url and instead only to the filter object
            if (addParamToUrl) {
                const stringList = newList.map((item) => encodeURIComponent(item.value));
                newSearchParams.set(urlParamName, stringList.toString());
                setSearchParams(newSearchParams);
            }

            updateFilters(urlParamName, newList, newSearchParams);
        } else {
            newSearchParams.delete(urlParamName);
            setSearchParams(newSearchParams);
            if (filters) {
                updateFilters(urlParamName, null, newSearchParams);
            }
        }
    };

    const updateSelectedList = (option: any) => {
        apply(option);
    };

    const selectOptions: readonly any[] = options
        .map((o) => {
            // need to map because Select Component is expecting a specific object
            return { label: o.value, present: o?.present, count: o?.count, ...o };
        })
        .sort((a, b) => (a.present === b.present ? 0 : a.present ? -1 : 1));

    return (
        <Select
            aria-label={ariaLabel}
            components={{ Option: CustomOption }}
            closeMenuOnSelect={false}
            isSearchable={true}
            isClearable={true}
            isMulti={true}
            isDisabled={selectOptions.length === 0}
            isLoading={selectionLoader}
            onInputChange={(value, action) => {
                // only set the input when the action that caused the
                // change equals to "input-change" and ignore the other
                // ones like: "set-value", "input-blur", and "menu-close"
                if (action.action === "input-change") setInput(value);
            }}
            inputValue={input}
            options={selectOptions}
            onChange={(e) => {
                // prevent weird loading state to show up
                // we don't want to endlessly run updateSelectedList/apply endlessly
                if (selectedOptions.length === 0 && e.length === 0) return;
                updateSelectedList(e);
            }}
            value={selectedOptions}
            placeholder={inputPlaceholder}
            className={`react-select-container ${pendoClass}`}
            classNamePrefix="react-select"
            menuPosition="fixed"
        />
    );
};

export const CustomOption = (props: OptionProps<ICustomOptions>) => {
    const { children, className, cx, data } = props;
    const count = data?.count;
    const isDisabled = (data?.present === false && data?.present !== undefined) || count === 0;
    const isAvailable = data?.present !== null && data?.present !== undefined && data?.count !== null && data?.count !== undefined;

    return (
        <div>
            <components.Option
                {...props}
                className={cx(
                    {
                        option: true,
                        "option--is-disabled": isDisabled,
                    },
                    className,
                    "p-3"
                )}
                children={
                    <span>
                        {children}
                        {isAvailable && <span className="opacity-75 fs-5">&nbsp;({count || 0})</span>}
                    </span>
                }
            />
        </div>
    );
};

export default MultiSelectFilter;
