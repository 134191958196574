import { Alert, Button, Col, ListGroup, Row, Stack } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { AssociatedPerson } from "../../types/old_v1/types";
import SimplePersonCard from "./SimplePersonCard";
import FMSpinner from "../Spinner";

type OwnProps = {
    associatedPeople: AssociatedPerson[];
    isLoading?: boolean;
    showRole?: boolean;
    agencyId: string | undefined;
    setCurrentTab: () => void;
};

const SimpleAssociatedPeopleView = ({ associatedPeople, isLoading = false, showRole = false, agencyId, setCurrentTab }: OwnProps) => {
    return (
        <Stack className="mt-3">
            <Row>
                {isLoading && <FMSpinner centered />}
                {!isLoading && associatedPeople?.length > 0 && (
                    <>
                        <Col xs={12}>
                            <ListGroup>
                                {associatedPeople.slice(0, 5).map((person) => (
                                    <SimplePersonCard
                                        associatedPerson={person}
                                        key={person.person_id || uuid()}
                                        showRole={showRole}
                                        agencyId={agencyId}
                                    />
                                ))}
                            </ListGroup>
                        </Col>
                        {associatedPeople?.length > 5 && (
                            <div>
                                <Button className="pendo_event_simple_people_view_more" variant="link" onClick={setCurrentTab}>
                                    View More
                                </Button>
                            </div>
                        )}
                    </>
                )}
                {!isLoading && associatedPeople.length === 0 && (
                    <Col xs={12}>
                        <Alert variant="warning">No associated people.</Alert>
                    </Col>
                )}
            </Row>
        </Stack>
    );
};

export default SimpleAssociatedPeopleView;
