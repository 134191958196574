import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { usePostFeedbackMutation } from "../../../api/api";
import ContactMeToggle from "./ContactMeToggle";
import { FeedbackStatus } from "../../../types/types";

const FeedbackFormSchema = Yup.object().shape({
    feedbackType: Yup.string().required("Required"),
    feedback: Yup.string().required("Required"),
});

type OwnProps = {
    feedbackStatus: FeedbackStatus;
};

const FeedbackForm = ({ feedbackStatus }: OwnProps) => {
    const location = useLocation();
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);

    const initialValues = {
        feedbackType: feedbackStatus || "bug",
        feedback: "",
        contactMe: false,
        page: `${location.pathname}${location.search}`,
    };

    const [postFeedback, { isLoading: isUpdating, isSuccess }] = usePostFeedbackMutation();

    const submitFn = (values: { feedbackType: string; feedback: string; contactMe: boolean; page: string }) => {
        if (values) {
            setSubmitted(true);
            postFeedback(values);
        }
    };

    React.useEffect(() => {
        if (isSuccess && submitted) {
            setShowSuccessMessage(true);
            setTimeout(() => {
                setShowSuccessMessage(false);
                setSubmitted(false);
            }, 2500);
        }
    }, [isSuccess, submitted]);

    if (showSuccessMessage) {
        return <h1>Thank you for your feedback!</h1>;
    }

    return (
        <Formik initialValues={initialValues} validationSchema={FeedbackFormSchema} onSubmit={submitFn}>
            {({ errors, touched }) => (
                <Form>
                    <Row>
                        <Col xs={12} className="mb-3">
                            <label htmlFor="feedbackType">Feedback Type</label>
                            <Field className="form-select" type="text" name="feedbackType" as="select">
                                <option value="bug">Bug</option>
                                <option value="concern">Concern / Comment</option>
                                <option value="data">Data Issue</option>
                                <option value="question">Question</option>
                            </Field>
                            {errors.feedbackType && touched.feedbackType ? <div className="text-danger">{errors.feedbackType}</div> : null}
                        </Col>
                        <Col xs={12} className="mb-3">
                            <label htmlFor="feedback">Feedback</label>
                            <Field
                                className="form-control"
                                type="text"
                                name="feedback"
                                as="textarea"
                                rows={6}
                                data-testid="feedback-textarea"
                            />
                            {errors.feedback && touched.feedback ? <div className="text-danger">{errors.feedback}</div> : null}
                        </Col>
                        <Col xs={12} className="mb-3">
                            <label htmlFor="feedback">Contact Me</label>
                            <Field component={ContactMeToggle} name="contactMe" />
                            {errors.contactMe && touched.contactMe ? <div className="text-danger">{errors.contactMe}</div> : null}
                        </Col>
                    </Row>
                    <Button data-testid="submit-feedback-button" variant="primary" type="submit" disabled={isUpdating}>
                        Submit Feedback
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default FeedbackForm;
