import React from "react";
import { faMap, faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { faMap as faMapOutline } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Container, Modal } from "react-bootstrap";
import FMSpinner from "../../components/Spinner";
import Highlighter from "../../components/highlighter/Highlighter";
import { formatName } from "../../utils/person";
import { renderField } from "../../utils/string";
import PersonMap from "./PersonMap";
import ErrorBoundary from "../support/errorboundary/ErrorBoundary";
import useIntegrations from "../../hooks/useIntegrations";
import { useGetPersonPhotosAllQuery } from "../../api/api";

import "./PersonOverviewFirstSegment.css";
import defaultPerson from "../../static/img/person.png";
import { useParams } from "react-router-dom";

const PersonOverviewFirstSegment = ({ person, eventData }: { person: any; eventData: any }) => {
    const { personPhotosEnabled } = useIntegrations();
    const { agencyUUID } = useParams();
    const [currentSelection, setCurrentSelection] = React.useState(0);
    const [showPhotoModal, setShowPhotoModal] = React.useState(false);
    const { data: personPhotosResp, isFetching: isFetchingPersonPhotosResp } = useGetPersonPhotosAllQuery(
        {
            agency_uuid: agencyUUID,
            person_id: person?.id,
        },
        { skip: !person?.id || !personPhotosEnabled }
    );

    let personPhotosList;
    let personPhotosListCount = 0;

    if (personPhotosResp?.results) {
        personPhotosList = personPhotosResp.results;
        personPhotosListCount = personPhotosList.length;
    }

    React.useEffect(() => {
        if ((personPhotosListCount !== 0 && personPhotosResp) || (personPhotosEnabled && personPhotosListCount !== 0)) {
            setCurrentSelection(1);
        } else {
            setCurrentSelection(0);
        }
    }, [personPhotosListCount, personPhotosResp, personPhotosEnabled]);

    return (
        <Card.Body className="p-0">
            <div>
                <div className="d-flex flex-row align-items-center">
                    {isFetchingPersonPhotosResp && personPhotosListCount === 0 && (
                        <div className="d-flex justify-content-center w-100 my-3">
                            <FMSpinner />
                        </div>
                    )}
                    {!isFetchingPersonPhotosResp && personPhotosListCount !== 0 && (
                        <span role="button" data-testid="person-photo-left-nav-button">
                            <FontAwesomeIcon
                                className="fs-2 mt-3 p-3 py-5 slideshow-nav-chevrons pendo_person_photo_left_nav_button"
                                icon={faChevronLeft}
                                style={{ userSelect: "none" }}
                                onClick={() => {
                                    const container = document.getElementById("personPhotoThumbnailContainer");
                                    const lastPhoto = document.getElementById(`personPhotoThumbnail${personPhotosListCount}`);
                                    const currentPhoto = document.getElementById(`personPhotoThumbnail${currentSelection}`);

                                    if (currentSelection === 0 && container && lastPhoto) {
                                        container.scrollLeft = lastPhoto?.offsetLeft;
                                    } else if (container && currentPhoto) {
                                        // allows for the next photo to be seen when moving back
                                        // keeps current photo second in the list that is viewable
                                        container.scrollLeft = currentPhoto?.offsetLeft - 175;
                                    }
                                    // go to end or go back 1 from current
                                    setCurrentSelection(currentSelection === 0 ? personPhotosList.length : currentSelection - 1);
                                }}
                            />
                        </span>
                    )}
                    {((personPhotosEnabled && !isFetchingPersonPhotosResp) || (!personPhotosEnabled && personPhotosListCount === 0)) && (
                        <div
                            className={`${currentSelection === 0 ? "d-block" : "d-none"} ${personPhotosEnabled ? "rounded" : "pb-5"} ${
                                personPhotosListCount === 0 ? "" : "mx-3 mt-3"
                            } overflow-hidden w-100`}
                        >
                            <PersonMap person={person} eventData={eventData} />
                        </div>
                    )}
                    {personPhotosEnabled && personPhotosListCount > 0 && !isFetchingPersonPhotosResp && (
                        <Container
                            className={`${
                                currentSelection > 0 ? "d-flex" : "d-none"
                            } justify-content-center mt-3 mx-auto px-3 overflow-hidden rounded flex-grow-1 position-relative`}
                            style={{ height: "250px", maxWidth: "250px" }}
                        >
                            {personPhotosList?.map((photo: any, idx: number) => (
                                <ErrorBoundary>
                                    <img
                                        alt="Person"
                                        key={photo?.file_name_s3}
                                        onClick={() => setShowPhotoModal(true)}
                                        style={{ objectFit: "cover", cursor: "zoom-in" }}
                                        className={`${
                                            idx + 1 === currentSelection ? "d-block" : "d-none"
                                        } w-100 rounded pendo_person_photo_image_modal_open_button`}
                                        src={`data:image/png;base64, ${photo?.base64_image || ""}`}
                                    />
                                </ErrorBoundary>
                            ))}
                            <Modal show={showPhotoModal} onHide={() => setShowPhotoModal(false)}>
                                <Modal.Header closeButton={true}>
                                    <h2 className="m-0">
                                        {renderField(formatName(person), "Unknown Name")} -{" "}
                                        <small>{personPhotosList[currentSelection - 1]?.person_id}</small>
                                    </h2>
                                </Modal.Header>
                                <Modal.Body className="d-flex align-items-center justify-content-center">
                                    {personPhotosListCount !== 0 && (
                                        <span role="button" data-testid="person-photo-modal-left-nav-button">
                                            <FontAwesomeIcon
                                                className="fs-2 p-3 py-5 slideshow-nav-chevrons pendo_person_photo_modal_left_nav_button"
                                                icon={faChevronLeft}
                                                style={{ userSelect: "none" }}
                                                onClick={() => {
                                                    const container = document.getElementById("personPhotoThumbnailContainer");
                                                    const lastPhoto = document.getElementById(
                                                        `personPhotoThumbnail${personPhotosListCount}`
                                                    );
                                                    const currentPhoto = document.getElementById(`personPhotoThumbnail${currentSelection}`);

                                                    if (currentSelection === 1 && container && lastPhoto) {
                                                        container.scrollLeft = lastPhoto?.offsetLeft;
                                                    } else if (container && currentPhoto) {
                                                        // allows for the next photo to be seen when moving back
                                                        // keeps current photo second in the list that is viewable
                                                        container.scrollLeft = currentPhoto?.offsetLeft - 175;
                                                    }
                                                    // go to end or go back 1 from current
                                                    setCurrentSelection(
                                                        currentSelection === 1 ? personPhotosList.length : currentSelection - 1
                                                    );
                                                }}
                                            />
                                        </span>
                                    )}
                                    <img
                                        alt="Person"
                                        className="h-100 rounded"
                                        style={{ maxWidth: "420px", width: "calc(100% - 60px)" }}
                                        src={`data:image/png;base64, ${personPhotosList[currentSelection - 1]?.base64_image || ""}`}
                                    />
                                    {personPhotosListCount !== 0 && (
                                        <span role="button" data-testid="person-photo-modal-right-nav-button">
                                            <FontAwesomeIcon
                                                className="fs-2 p-3 py-5 slideshow-nav-chevrons pendo_person_photo_modal_right_nav_button"
                                                icon={faChevronRight}
                                                style={{ userSelect: "none" }}
                                                onClick={() => {
                                                    const container = document.getElementById("personPhotoThumbnailContainer");
                                                    const currentPhoto = document.getElementById(`personPhotoThumbnail${currentSelection}`);

                                                    if (
                                                        currentSelection !== 0 &&
                                                        currentSelection !== personPhotosList.length &&
                                                        container &&
                                                        currentPhoto
                                                    ) {
                                                        // keeps current photo second in the list that is viewable
                                                        container.scrollLeft = currentPhoto?.offsetLeft - 90;
                                                    } else if (currentSelection === personPhotosList.length && container) {
                                                        container.scrollLeft = 0;
                                                    }
                                                    // go back to front or +1 from current
                                                    setCurrentSelection(
                                                        currentSelection === personPhotosList.length ? 1 : currentSelection + 1
                                                    );
                                                }}
                                            />
                                        </span>
                                    )}
                                </Modal.Body>
                                <Modal.Footer className="d-flex gap-4 justify-content-center flex-wrap p-3">
                                    <span>
                                        <strong>Captured</strong>: {personPhotosList[currentSelection - 1]?.when_captured}
                                    </span>
                                    <span>
                                        <strong>Entered</strong>: {personPhotosList[currentSelection - 1]?.when_entered}
                                    </span>
                                    <span>
                                        <strong>Updated</strong>: {personPhotosList[currentSelection - 1]?.when_updated}
                                    </span>
                                    {personPhotosList[currentSelection - 1]?.description && (
                                        <p>
                                            <strong>Description: </strong>
                                            {personPhotosList[currentSelection - 1]?.description}
                                        </p>
                                    )}
                                </Modal.Footer>
                            </Modal>
                        </Container>
                    )}
                    {!isFetchingPersonPhotosResp && personPhotosListCount !== 0 && (
                        <span role="button" data-testid="person-photo-right-nav-button">
                            <FontAwesomeIcon
                                className="fs-2 mt-3 p-3 py-5 slideshow-nav-chevrons pendo_person_photo_right_nav_button"
                                icon={faChevronRight}
                                style={{ userSelect: "none" }}
                                onClick={() => {
                                    const container = document.getElementById("personPhotoThumbnailContainer");
                                    const currentPhoto = document.getElementById(`personPhotoThumbnail${currentSelection}`);

                                    if (
                                        currentSelection !== 0 &&
                                        currentSelection !== personPhotosList.length &&
                                        container &&
                                        currentPhoto
                                    ) {
                                        // keeps current photo second in the list that is viewable
                                        container.scrollLeft = currentPhoto?.offsetLeft - 90;
                                    } else if (currentSelection === personPhotosList.length && container) {
                                        container.scrollLeft = 0;
                                    }
                                    // go back to front or +1 from current
                                    setCurrentSelection(currentSelection === personPhotosList.length ? 0 : currentSelection + 1);
                                }}
                            />
                        </span>
                    )}
                </div>
            </div>
            {personPhotosEnabled && (
                <Container className="p-3 pb-5 d-flex flex-column align-items-center">
                    <Container className="pt-3 px-3 gap-3 d-inline-flex justify-content-center">
                        <div
                            role="button"
                            data-testid="map-icon-nav-button"
                            className={`pendo_map_icon_nav_button d-flex justify-content-center align-items-center rounded me-2 cursor-pointer overflow-hidden ${
                                currentSelection === 0 ? "border border-2 border-primary" : ""
                            }`}
                            style={{
                                minWidth: "50px",
                                maxWidth: "50px",
                                minHeight: "50px",
                                maxHeight: "50px",
                                // prevents highlighting for double-click
                                userSelect: "none",
                            }}
                            onClick={() => {
                                setCurrentSelection(0);
                                const container = document.getElementById("personPhotoThumbnailContainer");
                                if (container) {
                                    container.scrollLeft = 0;
                                }
                            }}
                        >
                            <FontAwesomeIcon
                                role="button"
                                className="fs-2 text-primary"
                                icon={currentSelection === 0 ? faMap : faMapOutline}
                            />
                        </div>
                        <div id="personPhotoThumbnailContainer" className="d-flex flex-row overflow-auto flex-basis-1">
                            {isFetchingPersonPhotosResp && <FMSpinner />}
                            {!isFetchingPersonPhotosResp && personPhotosList && personPhotosList?.length === 0 && (
                                <div
                                    role="button"
                                    className={`d-flex flex-shrink-0 justify-content-center align-items-center rounded me-2 cursor-default overflow-hidden ${
                                        currentSelection === 1 ? "border border-2 border-primary" : ""
                                    }`}
                                    style={{ maxWidth: "50px", maxHeight: "50px" }}
                                >
                                    <img
                                        alt="Default Person Thumbnail - No Available Data"
                                        className="w-100 h-100"
                                        style={{ objectFit: "cover" }}
                                        src={defaultPerson}
                                    ></img>
                                </div>
                            )}
                            {!isFetchingPersonPhotosResp &&
                                personPhotosList?.map((photo: any, idx: number) => {
                                    const index = idx + 1;
                                    return (
                                        <ErrorBoundary>
                                            <div
                                                role="button"
                                                key={photo.file_name_s3}
                                                id={`personPhotoThumbnail${index}`}
                                                onClick={() => {
                                                    const container = document.getElementById("personPhotoThumbnailContainer");
                                                    const photo = document.getElementById(`personPhotoThumbnail${index}`);
                                                    if (container && photo) {
                                                        // moves selected to second in list
                                                        container.scrollLeft = photo?.offsetLeft - 135;
                                                    }
                                                    setCurrentSelection(index);
                                                }}
                                                className={`pendo_person_photo_thumbnail_nav_images d-flex flex-shrink-0 justify-content-center align-items-center rounded me-2 overflow-hidden ${
                                                    currentSelection === index ? "border border-2 border-primary" : ""
                                                }`}
                                                style={{ maxWidth: "50px", maxHeight: "50px" }}
                                            >
                                                <img
                                                    alt="Person Thumbnail"
                                                    className="w-100 h-100"
                                                    style={{ objectFit: "cover" }}
                                                    src={`data:image/png;base64, ${photo.base64_image || ""}`}
                                                />
                                            </div>
                                        </ErrorBoundary>
                                    );
                                })}
                        </div>
                    </Container>
                </Container>
            )}
            <div className="fw-bold fs-2 p-0 pb-3 text-center">
                <Highlighter>{renderField(formatName(person), "Unknown Name")}</Highlighter>
            </div>
        </Card.Body>
    );
};

export default PersonOverviewFirstSegment;
