import { defaultMobileSizePx } from "../constants/constants";

// Creates the url for running the saved search
export const getUrl = (saved: any, isMap?: boolean) => {
    if (saved.body.filters) {
        saved.body.filters = JSON.stringify(saved.body.filters);
    }
    const params = new URLSearchParams(saved.body);
    return isMap ? `../search/map?${params.toString()}` : `../search?${params.toString()}`;
};

/**
 * This function generates the object for the body of a [BE] request.
 * Adding additional body declarations will enable params to be passed
 * through to the backend. Adding additonal params require setting body
 * keys to enable RTK requerying.
 * @param params - UrlSearchParams
 */
export const getBody = (params: any) => {
    const body: { [key: string]: string } = {};
    if (params.query || params.query === "") {
        body.search_for = params.query;
    }
    if (params.dateRange) {
        body.date_range = params.dateRange;
    }
    if (params.startDate) {
        body.start_date = params.startDate;
    }
    if (params.endDate) {
        body.end_date = params.endDate;
    }
    if (params.pageSize) {
        body.page_size = params.pageSize;
    }
    if (params.page) {
        body.page_number = params.page;
    }
    if (params.sort) {
        body.sort = params.sort;
    }
    if (params.sortDirection) {
        body.sort_direction = params.sortDirection;
    }
    if (params.tags && params.tags !== "allEvents") {
        body.tags = params.tags;
    }
    if (params.offenseCodes) {
        body.offense_codes = params.offenseCodes;
    }
    if (params.stateStatutes) {
        body.state_statutes = params.stateStatutes;
    }
    if (params.race) {
        body.race = params.race;
    }
    if (params.gender) {
        body.gender = params.gender;
    }
    if (params.topLeft) {
        body.top_left = params.topLeft;
    }
    if (params.bottomRight) {
        body.bottom_right = params.bottomRight;
    }
    if (params.queryType) {
        body.query_type = params.queryType;
    }
    if (params.selectedTab) {
        // not used in any BE queries,  just for FE to return to page.
        body.selected_tab = params.selectedTab;
    }
    if (params.personId) {
        body.person_id = params.personId;
    }
    if (params.indexType) {
        body.index_type = params.indexType;
    }
    if (params.incidentTypes) {
        body.incident_types = params.incidentTypes;
    }
    if (params.callTypes) {
        body.call_types = params.callTypes;
    }
    if (params.timeRange) {
        const [startTime, endTime] = params.timeRange.split("-");
        body.start_time = `${startTime < 10 ? "0" : ""}${startTime}:00:00.00Z`;
        body.end_time = `${endTime < 10 ? "0" : ""}${endTime}:00:00.00Z`;
    }
    if (params.beats) {
        body.beats = params.beats;
    }
    if (params.isMap) {
        body.is_map = params.isMap;
    }
    if (params.interval) {
        body.interval = params.interval;
    }
    if (params.officer) {
        body.officer = params.officer;
    }
    if (params.agencyUUID) {
        body.agency_uuid = params.agencyUUID;
    }
    if (params.dispatcher) {
        body.dispatcher = params.dispatcher;
    }
    if (params.formatted_results) {
        body.formatted_results = params.formatted_results;
    }

    // search info for the App Stats charts in the search_history index
    if (params.searchId) {
        body.search_id = params.searchId;
    }
    if (params.source) {
        body.source = params.source;
    }

    return body;
};

//Premise notes search by address details street_number and address are required city/state/zip are optional
export const getPremNotesBody = (params: any) => {
    const body: { [key: string]: string } = {};

    if (params.address) {
        body.address = params.address;
    }

    if (params.city) {
        body.city = params.city;
    }

    if (params.state) {
        body.state = params.state;
    }

    if (params.zipcode) {
        body.zipcode = params.zipcode;
    }

    return body;
};

export const convertBodyParamNames = (body: { [key: string]: string }) => {
    const obj = !!body ? { ...body } : undefined;
    if (!obj) {
        return "";
    }
    if (obj && obj.search_for) {
        obj.query = obj.search_for;
        delete obj.search_for;
    }
    if (obj && obj.date_range) {
        obj.dateRange = obj.date_range;
        delete obj.date_range;
    }
    if (obj && obj.start_date) {
        obj.startDate = obj.start_date;
        delete obj.start_date;
    }
    if (obj && obj.end_date) {
        obj.endDate = obj.end_date;
        delete obj.end_date;
    }
    if (obj && obj.page_size) {
        // do not include page size in url if for map search
        if (obj.is_map !== "true") {
            obj.pageSize = obj.page_size;
        }
        delete obj.page_size;
    }
    if (obj && obj.top_left) {
        obj.topLeft = obj.top_left;
        delete obj.top_left;
    }
    if (obj && obj.bottom_right) {
        obj.bottomRight = obj.bottom_right;
        delete obj.bottom_right;
    }
    if (obj && obj.query_type) {
        obj.queryType = obj.query_type;
        delete obj.query_type;
    }
    if (obj && obj.index_type) {
        obj.indexType = obj.index_type;
        delete obj.index_type;
    }
    if (obj && obj.is_map) {
        delete obj.is_map;
    }

    return obj;
};

// Grab all filter params in object
export const getFilterParams = (body: { [key: string]: string }) => {
    const filterParams = ["date_range", "index_type", "tags", "gender", "race"];
    const filterObj: any = {};

    filterParams.forEach((field: string) => {
        if (!!body[field]) {
            filterObj[field] = body[field];
        }
    });

    return filterObj;
};

//Sets highchart to dark theme if ins darkmode
const HIGHCHART_DARK_THEME_TEXT_COLOR = "#c0c0c0";
export const handleHighChartDarkMode = (options: any, isDarkMode: boolean) => {
    if (isDarkMode) {
        options.chart = {
            ...options.chart,
            backgroundColor: "#1F2227",
            textColor: HIGHCHART_DARK_THEME_TEXT_COLOR,
            highlightColor: "#8db4d6",
        };
        options.title.style = {
            ...options.title.style,
            color: HIGHCHART_DARK_THEME_TEXT_COLOR,
        };
        options.xAxis = {
            ...options.xAxis,
            gridLineColor: "#6c757d",
            title: {
                ...options.xAxis.title,
                style: {
                    color: HIGHCHART_DARK_THEME_TEXT_COLOR,
                },
            },
            labels: {
                style: {
                    color: HIGHCHART_DARK_THEME_TEXT_COLOR,
                },
            },
        };
        options.yAxis = {
            ...options.yAxis,
            gridLineColor: "#6c757d",
            title: {
                ...options.yAxis.title,
                style: {
                    color: HIGHCHART_DARK_THEME_TEXT_COLOR,
                },
            },
            labels: {
                style: {
                    color: HIGHCHART_DARK_THEME_TEXT_COLOR,
                },
            },
        };
    }
};

export const processFilterAgg = (optionData: any): any[] =>
    (optionData &&
        optionData?.aggregations &&
        optionData?.aggregations?.unique &&
        optionData?.aggregations?.unique?.buckets &&
        optionData?.aggregations?.unique?.buckets
            .map((opt: any) => ({ id: opt.key, option: opt.key }))
            .sort((a: any, b: any) => (a.option.toLowerCase() < b.option.toLowerCase() ? -1 : 1))) ||
    [];

/**
 * Function to set up the queryString to append to the url.  It will check for applied filters add the search input on
 * the initial user search
 * @param params
 * @param query
 * @param mapBounds
 */
type OwnProps = {
    params: URLSearchParams;
    query: string;
    mapBounds?: { topLeft: string; bottomRight: string };
    isMap?: boolean;
};
export const setParams = ({ params, query, mapBounds, isMap }: OwnProps) => {
    let dateRange, sort;
    params.set("query", query);
    if (mapBounds) {
        params.set("topLeft", mapBounds.topLeft);
        params.set("bottomRight", mapBounds.bottomRight);
    }
    dateRange = params.get("dateRange");

    if (!dateRange) {
        params.set("dateRange", "30days");
    }

    if (isMap) {
        const entity = params.get("entity");
        if (!entity) {
            params.set("entity", [].join(","));
        }
    }

    // if query is an empty string, set sort to date
    // else, if sort isn't present on the url, set it to relevance
    if (query === "") {
        params.set("sort", "date");
    } else {
        sort = params.get("sort");
        if (!sort) {
            params.set("sort", "relevance");
        }
    }

    params.delete("page");

    return params;
};

export const scrollToTop = (element?: string, pageReset = false) => {
    // scrolling to a specific ID element/container into view and on mobile only
    // If we are not on main search or map search, we assume we will target a pill nav container on an entity page using mobile screen size
    if (
        element &&
        (pageReset || (element !== "mainContainer" && !element?.includes("BodyContent"))) &&
        window.innerWidth <= defaultMobileSizePx
    ) {
        const scrollContainer = document.getElementById(element);
        scrollContainer?.scrollIntoView();
        return;
    }

    // TODO: Get rid of this code smell using includes
    // Default to mainContainer unless we have a valid element string and on mobile or we are on mapSearchPage
    const scrollContainer = document.getElementById(
        (element && window.innerWidth <= defaultMobileSizePx) || element?.includes("BodyContent") ? element : "mainContainer"
    );
    if (scrollContainer) {
        scrollContainer.scrollTo({ behavior: "auto", top: 0 });
        return;
    }
    return;
};

// TODO: DEBT: DIRTY way of normalizing narratives for now
export const getNarrativeStyleNormalizationClass = (agency_shortname?: string) => {
    return agency_shortname && ["co-acso1", "va-alpd1", "co-ewpd1"].includes(agency_shortname) ? "fm-narratives-style-normalization" : "";
};
