import React from "react";
import { useGetAddressesQuery } from "../api/api";
import { createAddressGeoJson } from "../components/map/utils/gis";
import { ADDRESS_ACCENT, MAP_BORDER_ACCENT } from "../app/colors";
import { elasticHitsExtraction } from "../utils/elastic";
import { LayerTypes, SearchQueryParams, SourceConfig } from "../types/old_v1/types";
import { WHITE } from "../utils/colors";

export type AddressSearchLayerConfig = {
    addressSourceConfig: SourceConfig | undefined;
    addressesFetching: boolean;
    addressLoading: boolean;
    addressHits: any;
    addressCount: number;
};

/**
 * Hook used for fetching and building an address search layer
 * @param {SearchQueryParams} searchQueryParams Query parameters used for calling search API.
 * @param {boolean} showSinglePoint Flag to determine whether to only show first address with coordinates on map
 * @returns {AddressSearchLayerConfig}
 */

export const useAddressSearchLayer = (searchQueryParams: SearchQueryParams, showSinglePoint: boolean): AddressSearchLayerConfig => {
    const queryParams = { ...searchQueryParams };
    // override the parameters that could come from URL
    queryParams["selectedTab"] = LayerTypes.address;

    const [addressSourceConfig, setAddressSourceConfig] = React.useState<SourceConfig | undefined>();

    const addressResponse = useGetAddressesQuery(queryParams, {
        skip:
            queryParams.query === "" ||
            queryParams.query === null ||
            (queryParams.entity !== null && !queryParams?.entity?.split(",").includes(LayerTypes.address)),
    }) as any;

    React.useEffect(() => {
        if (addressResponse && addressResponse.data) {
            const hits = elasticHitsExtraction(addressResponse.data);
            if (hits) {
                const config = prepareAddressMapConfig(hits, showSinglePoint);
                setAddressSourceConfig(config);
            }
        }
    }, [addressResponse, showSinglePoint]);

    return {
        addressSourceConfig: addressSourceConfig,
        addressesFetching: addressResponse?.isFetching,
        addressLoading: addressResponse?.isLoading,
        addressHits: addressResponse?.data ? elasticHitsExtraction(addressResponse?.data) : [],
        addressCount:
            addressResponse?.data && addressResponse?.data?.success
                ? addressResponse?.data?.aggregations?.unique_addresses?.buckets.length
                : 0,
    };
};

export const prepareAddressMapConfig = (hits: Array<any>, showSinglePoint: boolean, pinnedResult?: any, cluster: boolean = false) => {
    //build one unique address point for map if showSinglePoint enabled, use all hits otherwise
    const addressGeoJson = createAddressGeoJson(showSinglePoint ? [hits.find((hit) => hit.gps_lon && hit.gps_lat)] : hits);
    const config: SourceConfig = {
        data: addressGeoJson,
        id: LayerTypes.address,
        setBounds: true,
        cluster: cluster,
        layers: [
            {
                id: "address-layer",
                type: "circle",
                source: "address-layer",
                paint: {
                    "circle-color": ADDRESS_ACCENT,
                    "circle-radius": 8,
                    "circle-stroke-color": [
                        "case",
                        ["==", ["get", "address_id"], pinnedResult?.address_id || ""],
                        WHITE,
                        MAP_BORDER_ACCENT,
                    ],
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": 0.7,
                },
            },
            {
                id: "address-layer-cluster",
                type: "circle",
                source: "address-layer",
                filter: ["has", "point_count"],
                paint: {
                    "circle-color": ADDRESS_ACCENT,
                    "circle-stroke-color": MAP_BORDER_ACCENT,
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": 0.7,
                    "circle-radius": ["step", ["get", "point_count"], 10, 20, 20, 30, 30, 40, 40, 50, 50],
                },
            },
            {
                id: "address-layer-cluster-count",
                type: "symbol",
                source: "address-layer",
                filter: ["has", "point_count"],
                layout: {
                    "text-field": "{point_count}",
                    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                    "text-size": 12,
                },
                paint: {
                    "text-color": "white",
                },
            },
        ],
    };
    return config;
};
