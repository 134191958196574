import { Table } from "react-bootstrap";
import { formatDate } from "../../utils/date";

const InternalArrestsDropdown = ({ showArrestsDropdown, openRows, row, arrests }: any) => {
    return (
        <>
            {showArrestsDropdown && openRows?.includes(row.person_id) ? (
                <Table responsive className="mt-2 mb-2">
                    <thead>
                        <tr>
                            <th className="text-nowrap">Arrest Number</th>
                            <th>Occurred</th>
                            <th>Type</th>
                            <th className="text-nowrap">Charge</th>
                            <th className="text-nowrap">Location</th>
                            <th className="text-nowrap">Officer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {arrests.map((arrest: any, index: number) => {
                            return (
                                <tr key={`${arrest.arrest_id}-${index}`}>
                                    <td className={`text-nowrap`}>{arrest.arrest_number}</td>
                                    <td>{formatDate(arrest.arrest_date)}</td>
                                    <td>{arrest.arrest_type}</td>
                                    <td>{arrest.charge}</td>
                                    <td>{arrest.location}</td>
                                    <td className="text-center">{arrest.officer}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            ) : null}
        </>
    );
};

export default InternalArrestsDropdown;
