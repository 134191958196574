import React from "react";

declare let darkmode: any;

export function useDarkMode(): { isDarkMode: boolean } {
    const [isDarkMode, setIsDarkMode] = React.useState<boolean>(false);

    const modeSetInLocalStorage = darkmode.getFromLocalStorage();

    const setDarkMode = React.useCallback(() => {
        const usingPreferenceDarkMode = window?.matchMedia("(prefers-color-scheme: dark)").matches;

        if (modeSetInLocalStorage === "dark" || modeSetInLocalStorage === "light") {
            // if either dark or light mode is set in local storage, use them
            setIsDarkMode(modeSetInLocalStorage === "dark");
        } else if (!modeSetInLocalStorage || modeSetInLocalStorage === "system") {
            // if auto is set in local storage or if no value is stored in local storage,
            // use the preference color scheme
            setIsDarkMode(usingPreferenceDarkMode);
        }
    }, [modeSetInLocalStorage]);

    React.useEffect(() => {
        setDarkMode();
        window?.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", setDarkMode);
        return window?.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", setDarkMode);
    }, [setDarkMode]);

    React.useEffect(() => {
        setDarkMode();
    }, [setDarkMode, modeSetInLocalStorage]);

    return {
        isDarkMode,
    };
}
