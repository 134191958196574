export const getQueryParams = (searchParams: URLSearchParams) => {
    return {
        query: searchParams.get("query"),
        entity: searchParams.get("entity"),
        dateRange: searchParams.get("dateRange"),
        startDate: searchParams.get("startDate"),
        endDate: searchParams.get("endDate"),
        page: searchParams.get("page"),
        pageSize: searchParams.get("pageSize"),
        sort: searchParams.get("sort"),
        sortDirection: searchParams.get("sortDirection"),
        tags: searchParams.get("tags"),
        queryType: searchParams.get("queryType"),
        offenseCodes: searchParams.get("offenseCodes"),
        stateStatutes: searchParams.get("stateStatutes"),
        race: searchParams.get("race"),
        gender: searchParams.get("gender"),
        topLeft: searchParams.get("topLeft"),
        bottomRight: searchParams.get("bottomRight"),
        selectedTab: searchParams.get("selectedTab"),
        personId: searchParams.get("personId"),
        importantEvent: searchParams.get("importantEvent"),
        indexType: searchParams.get("indexType") as string | string[] | null,
        incidentTypes: searchParams.get("incidentTypes"),
        callTypes: searchParams.get("callTypes"),
        timeRange: searchParams.get("timeRange"),
        isMap: searchParams.get("isMap"),
        officer: searchParams.get("officer"),
        dispatcher: searchParams.get("dispatcher"),
        hasAllTags: searchParams.get("hasAllTags"),
        agencies: searchParams.get("agencies"),
        agencyUUID: searchParams.get("agencyUUID"),
        ageType: searchParams.get("ageType"),
        age: searchParams.get("age"),
        searchId: searchParams.get("searchId"),
        offenses: searchParams.get("offenses"),
        callSources: searchParams.get("callSources"),
        callPriorities: searchParams.get("callPriorities"),
        filters: searchParams.get("filters"),
    };
};

export const formatBaseApiAgencyUUIDUrl = (agencyUUID: string | undefined) => {
    return `v2/search${agencyUUID ? `/${agencyUUID}` : ""}`;
};

/**
 * Handles validating a given url and returning corresponding pathname.
 * Falls back to not found page if invalid url
 * @param url full url to be navigated to (includes http, domain, etc)
 */
export const getUrlPathname = (url: string) => {
    let urlToNavigate = "";

    try {
        urlToNavigate = new URL(url).pathname;
    } catch (e) {
        urlToNavigate = "/not-found";
    }

    return urlToNavigate;
};
