import icon from "../../static/img/logo_white.png";
import printIcon from "../../static/img/logo.png";

import { getValidatedLabels } from "../../utils/TagDetails";
import { useDarkMode } from "../../hooks/useDarkMode";

const Tags = ({ tags, showNoTagsMessage = false }: { tags: string[]; showNoTagsMessage?: boolean }) => {
    const validatedTags = getValidatedLabels(tags);

    const { isDarkMode } = useDarkMode();
    return (
        <>
            {validatedTags.length ? (
                <div className={validatedTags.length ? "" : "mb-3"}>
                    {validatedTags?.map((tag) => {
                        return (
                            <span
                                key={tag?.code}
                                className={`d-inline-block badge fw-normal ${
                                    tag?.isFM ? "border-radius" : ""
                                } me-1 mb-1 p-1 align-content-bottom text-black`}
                                style={{
                                    backgroundColor: tag?.color,
                                    border: `2px solid ${tag?.color}`,
                                }}
                            >
                                <div className={`${tag?.isFM ? "mt-1 me-1" : ""}`}>
                                    {tag?.isFM && (
                                        <>
                                            <img
                                                src={icon}
                                                alt="ForceMetrics Logo"
                                                style={{ height: "18px", filter: !isDarkMode ? "invert(100%)" : "invert(0%)" }}
                                                className="me-1 mt-n1 d-print-none"
                                            />
                                            <img
                                                src={printIcon}
                                                alt="ForceMetrics_Logo_printable"
                                                style={{ height: "18px", filter: !isDarkMode ? "invert(100%)" : "invert(0%)" }}
                                                className="me-1 mt-n1 d-none d-print-inline-block"
                                            />
                                        </>
                                    )}
                                    {tag?.name}
                                </div>
                            </span>
                        );
                    })}
                </div>
            ) : (
                <>{showNoTagsMessage ? <p>No labels to display.</p> : null}</>
            )}
        </>
    );
};

export default Tags;
