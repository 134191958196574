import { Card, Col, Container, Row, Stack } from "react-bootstrap";
import ShareEntity from "../../components/share/ShareEntity";
import Highlighter from "../../components/highlighter/Highlighter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { PERSON_ACCENT } from "../../utils/colors";
import { formatAge, formatDateWithoutTime } from "../../utils/date";
import PanelCell from "../../components/entity/PanelCell";
import { renderField } from "../../utils/string";
import BackToSearchButton from "../../components/BackToSearchButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import LinkPanelCell from "../../components/entity/LinkPanelCell";
import FollowButton from "../../components/FollowButton";
import AgencyDescription from "../../components/AgencyDescription";
import PersonAliases from "../../components/entity/PersonAliases";

import { populatePhoneNumberSearchPageParams, useMultiAgency } from "../../hooks/useMultiAgency";
import FMSpinner from "../../components/Spinner";
import LinkCell from "../../components/LinkCell";
import { getAddressLinkIsDisabled } from "../../utils/address";
import PersonOverviewFirstSegment from "./PersonOverviewFirstSegment";
import PersonTags from "../../components/tags/PersonTags";
import AddressSearchModal from "../../components/entity/AddressSearchModal";
import React from "react";
import useNextAwarePathname from "../../hooks/useNextAwarePathname";

type OwnProps = {
    person: any;
    aliases: any[];
    isFetchingPerson: boolean;
    eventData: any[];
};

const PersonOverview = ({ person, aliases, isFetchingPerson = false, eventData }: OwnProps) => {
    const { canPerformNextFESearch, peopleSearchPath } = useNextAwarePathname();
    const { constructAddressUrl } = useMultiAgency();
    const [prefillSearch, setPrefillSearch] = React.useState("");
    const navigate = useNavigate();

    const age = !!person?.birthdate ? formatAge(person?.birthdate, person?.deathdate) : formatAge(person?.birthdate);
    const birthdateFormatted = formatDateWithoutTime(person?.birthdate);
    const dobString = !!birthdateFormatted ? `${birthdateFormatted} (${age} yrs)` : "--";
    const [searchParams] = useSearchParams();
    const addressLink = constructAddressUrl(person?.agency_id, person, searchParams.get("query") || "");

    const followText = `${person?.full_name_middle ? person?.full_name_middle : person?.full_name}`;
    const canViewMapboxSearchboxModal = window?.__RUNTIME_CONFIG__?.USE_LOCATION_SEARCH_MODAL === "true";

    const onAddressClick = () => {
        if (canViewMapboxSearchboxModal) {
            setPrefillSearch(person?.full_address);
        } else {
            navigate(addressLink);
        }
    };

    return (
        <>
            <Row className="flex-grow-1">
                <Col className="mb-4 d-block d-md-none" xs={12}>
                    <BackToSearchButton />
                </Col>
                <Col className="p-0">
                    <Card className="border-0 border-md">
                        <Card.Header className="bg-dark py-3 d-flex justify-content-between flex-wrap">
                            <div className="align-self-center text-white d-flex flex-column">
                                <span className="fw-bold">Person Details</span>
                                <AgencyDescription agencyDescription={person?.agency_desc} />
                            </div>
                            <Stack direction="horizontal" gap={2} className="align-items-start">
                                <FollowButton followText={followText} type={"person"} />
                                <ShareEntity />
                            </Stack>
                        </Card.Header>
                        {/* First segment of the overview page for Map, Photos, Name, and Tags */}
                        <PersonOverviewFirstSegment eventData={eventData} person={person} />
                        <Card.Header className="py-4 fs-3">
                            <FontAwesomeIcon icon={faCircle} size="xs" color={PERSON_ACCENT} className="me-2" />
                            Person ID: <Highlighter>{renderField(person?.id)}</Highlighter>
                        </Card.Header>
                        <Card.Body className="p-0 border-bottom">
                            {person?.alerts && (
                                <Container className="p-3">
                                    <PersonTags tags={person?.alerts} agencyId={person?.agency_id} showExpand={true} centerTags={true} />
                                </Container>
                            )}
                            {isFetchingPerson && (
                                <div className="text-center my-3">
                                    <FMSpinner />
                                </div>
                            )}
                            {!isFetchingPerson && person && (
                                <>
                                    <PanelCell fieldName="DOB" fieldValue={renderField(dobString)} />
                                    <LinkCell
                                        fieldName="Address"
                                        fieldValue={person?.full_address}
                                        cellClick={onAddressClick}
                                        testId="person-address-link"
                                        disabled={
                                            canViewMapboxSearchboxModal ? !person?.full_address : getAddressLinkIsDisabled(addressLink)
                                        }
                                        pendoClass="pendo_address_link_button"
                                    />
                                    <PanelCell
                                        fieldName="Race/Gender"
                                        fieldValue={`${renderField(person?.race)}/${renderField(person?.gender)}`}
                                    />
                                    <PanelCell
                                        fieldName="Height/Weight"
                                        fieldValue={`${renderField(person?.height)}/${renderField(person?.weight)}`}
                                    />
                                    <PanelCell
                                        fieldName="Hair/Eyes"
                                        fieldValue={`${renderField(person?.hair)}/${renderField(person?.eyes)}`}
                                    />
                                    <PanelCell fieldName="DL Number" fieldValue={person?.dl_complete} />
                                    <PanelCell fieldName="SSN" fieldValue={person?.ssn} />
                                    {person?.phone_main ? (
                                        <LinkPanelCell
                                            pendoClass="pendo_main_phone_link_button"
                                            fieldName="Main Phone"
                                            fieldValue={person?.phone_main}
                                            link={populatePhoneNumberSearchPageParams(
                                                person?.phone_main,
                                                canPerformNextFESearch,
                                                peopleSearchPath
                                            )}
                                        />
                                    ) : (
                                        <PanelCell fieldName="Main Phone" fieldValue={person?.phone_main} />
                                    )}
                                    {person?.phone_work ? (
                                        <LinkPanelCell
                                            pendoClass="pendo_work_phone_link_button"
                                            fieldName="Work Phone"
                                            fieldValue={person?.phone_work}
                                            link={populatePhoneNumberSearchPageParams(
                                                person?.phone_work,
                                                canPerformNextFESearch,
                                                peopleSearchPath
                                            )}
                                        />
                                    ) : (
                                        <PanelCell fieldName="Work Phone" fieldValue={person?.phone_work} />
                                    )}
                                    {/* DEBT: This feature is only for the nc-gbpd1 agency, one off implementation. */}
                                    {person?.agency_id === "nc-gbpd1" && <PersonAliases aliases={aliases} person={person} />}
                                </>
                            )}
                            {!isFetchingPerson && !person && (
                                <div className="px-3 py-3">
                                    <span>No person information available.</span>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* Mapbox address suggestions based on person's full address */}
            {canViewMapboxSearchboxModal && (
                <AddressSearchModal show={prefillSearch !== ""} hideModalFn={() => setPrefillSearch("")} prefillSearch={prefillSearch} />
            )}
        </>
    );
};

export default PersonOverview;
