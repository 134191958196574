export const ASCENDING = "asc";
export const DESCENDING = "desc";

const sortFn = (a: any, b: any, property: string, sortString: boolean) => {
    if (!property) {
        return 0;
    }
    const reversed = property[0] === "-";
    let reversedFactor = 1;
    if (reversed) {
        property = property.substring(1);
        reversedFactor = -1;
    }
    if (!Object.prototype.hasOwnProperty.call(a, property) && !Object.prototype.hasOwnProperty.call(b, property)) {
        return 0;
    }
    if (Object.prototype.hasOwnProperty.call(a, property) && !Object.prototype.hasOwnProperty.call(b, property)) {
        return reversedFactor;
    }
    if (Object.prototype.hasOwnProperty.call(b, property) && !Object.prototype.hasOwnProperty.call(a, property)) {
        return -1 * reversedFactor;
    }

    if (sortString) {
        if (reversed) {
            if (typeof b[property] === "number") {
                return b[property] > a[property] ? 1 : -1;
            } else {
                return (b[property] || "").localeCompare(a[property] || "");
            }
        } else {
            if (typeof b[property] === "number") {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return (a[property] || "").localeCompare(b[property] || "");
            }
        }
    } else {
        const result = a[property] - b[property];
        return result * reversedFactor;
    }
};

export const sort = (keys: string, sortString: boolean = false) => {
    return (a: any, b: any) => {
        const objKeys = keys.split(",");
        let i = 0;
        let result = 0;
        while (result === 0 && i < objKeys.length) {
            result = sortFn(a, b, objKeys[i], sortString);
            i++;
        }
        return result;
    };
};

export const sortSortableHeader = (keys: string, sortString: boolean, sortDirection: string) =>
    sort(handleSortKeyReverse(keys, sortDirection), sortString);

export const handleSortKeyReverse = (sortColumn: string, sortDirection: string) => `${sortDirection === ASCENDING ? "" : "-"}${sortColumn}`;
