import { useCookies } from "react-cookie";
import { useLazyGetLogoutQuery } from "../api/api";
import { useAppDispatch } from "../app/hooks";
import { clearState } from "../app/slices/user/userSlice";
import { getRefreshToken } from "../utils/token";

export function useSignOut() {
    const [getLogOut] = useLazyGetLogoutQuery();
    const dispatch = useAppDispatch();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_cookies, _setCookies, removeCookies] = useCookies(["accessToken", "refreshToken"]);

    const signOut = () => {
        const clear = () => {
            dispatch(clearState());
            removeCookies("accessToken", { path: "/" });
            removeCookies("refreshToken", { path: "/" });
        };

        try {
            getLogOut({ refresh: getRefreshToken() });
            clear();
        } catch (e) {
            clear();
        }
    };

    // runs the logout api call and immediately clears state and redirects to the sign-in page.
    const signOutAndReRoute = () => {
        signOut();
        window.location.assign("/auth/sign-in");
    };

    return { signOut, signOutAndReRoute };
}
