/**
 * Small helper hook to check if user has reduced motion enabled (i.e. browser preference to disable animations)
 */
const useReducedMotion = () => {
    const isReducedMotion = window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

    return {
        isReducedMotion,
    };
};

export default useReducedMotion;
