import { SearchHit, SearchResponse } from "@elastic/elasticsearch/lib/api/types";
import { v4 as uuid } from "uuid";

// import { distance } from "../components/searchCard/utils";

/**
 * elasticHitsExtraction takes in any source search response and returns array of hits
 *
 * @param {SearchResponse<any>} es SearchResponse from elastic search call
 * @returns hits array
 */
export function elasticCountExtraction(es: SearchResponse<any>) {
    return es?.hits?.total;
}

/**
 * elasticHitsExtraction takes in any source search response and returns array of hits
 *
 * @param {SearchResponse<any>} es SearchResponse from elastic search call
 * @returns hits array
 */
export function elasticHitsExtraction(es: any) {
    if (es?.aggregations?.unique_addresses) {
        return getArrayOfAddressData(es);
    }

    return es?.hits?.hits;
}

type FieldValues = {
    [key: string]: any;
};

/**
 * Converts fields array values from ES to strings when necessary
 * @param fields Fields values returned from ES
 * @returns {FieldValues}
 */
export const getFieldValues = (fields: any) => {
    const fieldValues: FieldValues = {};
    if (fields && typeof fields === "object") {
        for (const fieldKey in fields) {
            const fieldValue = fields[fieldKey];
            if (fieldValue && Array.isArray(fieldValue)) {
                if (fieldValue.length > 1) {
                    fieldValues[fieldKey] = fieldValue;
                } else if (fieldKey === "flags") {
                    // flags should always be an array
                    fieldValues[fieldKey] = fieldValue;
                } else {
                    fieldValues[fieldKey] = fieldValue[0];
                }
            } else if (fieldValue) {
                fieldValues[fieldKey] = fieldValue;
            }
        }
    }

    return fieldValues;
};

/**
 * Until all calls have been converted to using fields we need to be able to return the _source values as well
 * @param result
 */
export const getSourceOrField = (result: any) => {
    if (result.fields) {
        return getFieldValues(result.fields);
    } else if (result._source || result.source) {
        return result._source || result.source;
    }

    return result;
};

/**
 * getElasticSourceWithHighlights takes in a search hit and returns data with source, highlights, and appends the index
 *
 * if a highlight is the same as the source, we overwrite the source with the highlight data, to ensure highlights are displayed as expected.
 * highlights are cleaned so they return a string instead of an array to make it easier to render
 *
 * @param {SearchHit<any>} es single SearchHit from elastic search hits array
 * @returns hit data with source, cleaned highlights, and the index
 */
export function getElasticSourceWithHighlights(es: SearchHit<any>) {
    const sourceData = es.fields ? getFieldValues(es.fields) : es._source;
    const highlights = es.highlight;
    const index = es._index;
    let cleanedHighlights: { [key: string]: string } = {};

    if (highlights) {
        Object.keys(highlights).forEach((highlight) => {
            if (highlight !== "flags") {
                cleanedHighlights[highlight] = highlights[highlight][0];
            }
        });
    }

    return {
        ...sourceData,
        ...cleanedHighlights,
        source: {
            ...sourceData,
        },
        highlights: {
            ...cleanedHighlights,
        },
        index: index ? index.toLowerCase() : "",
        id: sourceData.id ? sourceData.id : es._id ? es._id : "",
    };
}

/**
 * getArrayOfDataFromElasticSearchReponse takes in any source search response and returns array of
 * hit data with source, cleaned highlights, and the index
 *
 * Utilizes functions elasticHitsExtraction and getElasticSourceWithHighlights to get the data
 * and return it in a usable format
 *
 * @param {SearchResponse<any>} es SearchResponse from elastic search call
 * @returns hit data with source, cleaned highlights, and the index
 */
export function getArrayOfDataFromElasticSearchReponse(es: SearchResponse<any>) {
    const hits = elasticHitsExtraction(es);

    return hits?.map((hit: any) => getElasticSourceWithHighlights(hit)) || [];
}

/**
 * getAggregationsFromElasticSearchResponse takes in any source search response and returns array of
 * hit data with source, cleaned highlights, and the index
 *
 * Utilizes functions elasticHitsExtraction and getElasticSourceWithHighlights to get the data
 * and return it in a usable format
 *
 * @param {SearchResponse<any>} es SearchResponse from elastic search call
 * @returns hit data with source, cleaned highlights, and the index
 */
export function getAggregationsFromElasticSearchResponse(es: SearchResponse<any>) {
    return es?.aggregations;
}

export function getArrayOfAddressData(data: any) {
    // parses the returned aggregation data into an array of objects
    const cleanedData = data?.aggregations?.unique_addresses?.buckets.reduce((accum: any[], bucket: any) => {
        const hit = bucket.recent_events.hits.hits[0];

        const occurred_at = Array.isArray(hit?.fields?.occurred_at) && hit?.fields?.occurred_at[0];

        // calls and incidents counts are in the event_counts agg in buckets with each unique index named
        // with a doc_count.
        const event_counts = bucket?.event_counts;
        let counts = { calls: 0, incidents: 0 };
        if (event_counts && event_counts.buckets) {
            counts = event_counts.buckets.reduce(
                (counts: any, evt: any) => {
                    if (evt.key && evt.key.includes("calls") && evt.doc_count) {
                        counts.calls += evt.doc_count;
                    } else if (evt.key && evt.key.includes("incidents") && evt.doc_count) {
                        counts.incidents += evt.doc_count;
                    }
                    return counts;
                },
                { calls: 0, incidents: 0 }
            );
        }

        let obj = { ...hit._source, occurred_at, counts };
        obj.key = bucket.key;
        obj.highlight = hit.highlight;
        accum.push(obj);
        return accum;
    }, []);

    return cleanedData;
}

/**
 * getHitDataAndCountsFromElasticSearchResponse takes in any source search response and returns array of
 * hit data with source, cleaned highlights, and the index
 *
 * Utilizes functions elasticHitsExtraction and getElasticSourceWithHighlights to get the data
 * and return it in a usable format
 *
 * @param {SearchResponse<any>} es SearchResponse from elastic search call
 * @returns hit data with source, cleaned highlights, and the index
 */
export function getHitDataAndCountsFromElasticSearchResponse(es: SearchResponse<any>) {
    const hits = elasticHitsExtraction(es);
    const count = elasticCountExtraction(es);

    const dataToReturn = hits?.map((hit: any) => getElasticSourceWithHighlights(hit));

    return { count, hits: dataToReturn };
}

// export function getElasticAggregations(es: SearchResponse<any>) {}

/**
 * getIndexFromFirstResultOfElasticResultsArray takes in the data object from elastic
 * and checks to make sure it exists, it's an array, and that it has a first value
 * then it grabs the index from that value, if that isn't there, return an empty string
 *
 * @param array
 * @returns index or empty string
 */
export function getIndexFromFirstResultOfElasticResultsArray(array: any) {
    return array && Array.isArray(array) && array[0] ? array[0].index : "";
}

export function getDocFromElasticResultsArray(array: any) {
    if (array?.hits?.hits && array.hits.hits[0]) {
        return { index: array.hits.hits[0]._index, ...array.hits.hits[0]._source };
    }
}

export const getIncidentAndCallDataFromBucket = (data: any) => {
    const dataToReturn = {
        incidents: 0,
        calls: 0,
    };
    if (data?.event_type?.buckets) {
        const callsData = data?.event_type?.buckets.find((bucket: any) => bucket.key.includes("calls"));
        const incidentsData = data?.event_type?.buckets.find((bucket: any) => bucket.key.includes("incidents"));

        dataToReturn.calls = callsData?.doc_count ? callsData?.doc_count : 0;
        dataToReturn.incidents = incidentsData?.doc_count ? incidentsData?.doc_count : 0;
    }

    return dataToReturn;
};

/**
 * Returns are re-formatted version of the ES response for the React component that displays the data.
 * @param es
 */
export const getArrayOfEventResults = (es: SearchResponse<any>) => {
    const hits = elasticHitsExtraction(es);

    return (
        hits?.map((hit: any) => {
            return getHighlights(hit);
        }) || []
    );
};

/**
 * Re-formats the ES response for a hit into an object used to display the results and highlight snippets for main
 * search.  The highlight snippets are found in multiple locations at the hit level or in the inner_hits for nested
 * objects.  This code returns the source data in the hit._source field, the index in hit._index and a highlights
 * object that has in it { <field name>: [{id:xxx, text:<highlight snippet>}]}} so each field that has a match
 * will have an array of snippets with the text to highlight surrounded in <em></em> for the highlighter.
 *
 * @param hit
 * @return Object {
 *     <_source field>: <value>,
 *     source: { // legacy field not sure if it is still required
 *         <_source field>: <value>
 *     },
 *     index: <_index>,
 *     highlights: {
 *         <field>: [{
 *             id: uuid,
 *             text: string
 *         }, ...]
 *     }
 * }
 */
export const getHighlights = (hit: any) => {
    let highlights: any;
    // if the hit is for an incident, check for any nested object snippets
    if (hit._index.includes("incident")) {
        const nestedNarratives = getNestedNarrativeHighlights(hit);
        if (nestedNarratives) {
            highlights = nestedNarratives;
        }
    }
    // Get the highlights for the non-nested objects
    if (hit.highlight) {
        let his = Object.keys(hit.highlight).reduce((accum: { [key: string]: [string] }, key): { [key: string]: [string] } => {
            // create an object for each item in the array
            accum[key] = hit.highlight[key].map((item: string) => {
                return { id: uuid(), text: item };
            });
            return accum;
        }, {});
        highlights = { ...highlights, ...his };
    }

    return {
        ...hit._source,
        source: {
            ...hit._source,
        },
        index: hit._index,
        highlights,
    };
};

/**
 * Retrieves a narrative_body highlight from an incident ES hit.  Looks through the incident's narratives
 * for any highlights and returns the first one it finds since all we need is a preview for the search results.
 * The returned object will be {narratives_body: [{id: uuid, text: <highlight snippet>}, ...]}.  There may be more
 * than one snippet per narrative body.  The uuid is supplied for the key value for the React component map.
 * @param hit
 */
export const getNestedNarrativeHighlights = (hit: any): any | undefined => {
    if (hit.inner_hits) {
        // We only need one nested narrative highlight for the preview so return just the first one.
        const inHits = hit.inner_hits?.narratives?.hits?.hits;
        if (inHits && inHits.length) {
            // find the first narrative with highlights for the preview.  (there can be an array for each narrative)
            const hi = inHits.filter((ih: any) => ih.highlight && !!ih.highlight["narratives.body"]);
            if (hi.length) {
                // create the appropriate object for each item in the array.  need an id for the key value
                // In the future, this id should be set in the api.ts response so it is cached
                return {
                    narratives_body: inHits[0].highlight["narratives.body"].map((item: string) => {
                        return { id: uuid(), text: item };
                    }),
                };
            }
        }
    }
    return undefined;
};
