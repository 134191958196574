import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Button, Col, ListGroup, Row, Stack } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetTextSummarizationsQuery } from "../../api/api";
import NarrativeListItem from "./NarrativeListItem";
import usePermissions from "../../hooks/usePermissions";
import FMSpinner from "../../components/Spinner";
import SortButton from "../../components/SortButton";
import { NarrativeSummary, NewNarrative, SortConfig, SortDirectionEnum } from "../../types/old_v1/types";
import { FMPaginationNonLinks } from "../../components/pagination/Pagination";
import useSortState from "../../hooks/useSortState";
import { sortSortableHeader } from "../../utils/sort";
import { usePagination } from "../../hooks/usePagination";
import { faMinus } from "@fortawesome/pro-regular-svg-icons";

type OwnProps = {
    narratives: any[];
    isNarrativesFetching: boolean;
    incidentId: string;
    currentPage?: any;
    totalPages?: any;
    setCurrentPage?: any;
    scrollToTop?: any;
    pageReset: any;
};

const sortConfig: SortConfig = {
    created_at: {
        label: "Date",
        defaultSortDirection: SortDirectionEnum.DESCENDING,
        sortString: true,
    },
    author: {
        label: "Author",
        defaultSortDirection: SortDirectionEnum.ASCENDING,
        sortString: true,
    },
};

const ConnectedNarrativesView = ({ narratives, isNarrativesFetching, incidentId, pageReset, scrollToTop }: OwnProps) => {
    const { agencyUUID } = useParams();
    const [highlightsOnly, setHighlightsOnly] = React.useState(false);

    const [searchParams] = useSearchParams();
    const { hasPermission } = usePermissions();
    const query = searchParams.get("query");
    const [expandAll, setExpandAll] = React.useState(true);
    const { sortField, setSortField, sortDirection, setSortDirection } = useSortState(
        "created_at",
        sortConfig["created_at"].defaultSortDirection
    );

    let sortedData = [...narratives].sort(sortSortableHeader(sortField, sortConfig[sortField].sortString, sortDirection));
    const containsHighlight = !!sortedData.filter((narrative) => narrative.contains_highlight).length;

    if (highlightsOnly) {
        sortedData = sortedData.filter((narrative) => (highlightsOnly ? narrative.contains_highlight : null));
    }

    //Greensboro has narratives specific to the officer that need to be displayed at the top of the list
    let officerNarrative = null;
    if (Array.isArray(narratives)) {
        const officerIndex = sortedData.findIndex((narrative) => narrative.narrative_type === "officer");
        if (officerIndex > -1) {
            officerNarrative = sortedData[officerIndex];
            sortedData.splice(officerIndex, 1);
        }
    }

    const reset = React.useMemo(() => ({ ...pageReset, sortField, sortDirection }), [pageReset, sortDirection, sortField]);

    const { currentPage, currentPageData, totalPages, setCurrentPage } = usePagination(sortedData || [], 10, reset, scrollToTop);

    const { data: textSummarizationsResponse, isFetching: isTextSummarizationsFetching } = useGetTextSummarizationsQuery(
        { id: incidentId, queryType: "cross_fields", query, agencyUUID, formattedResults: true },
        { skip: !incidentId || !hasPermission("view_narrative_summaries") }
    );

    return (
        <NarrativesView
            narratives={currentPageData}
            textSummarizations={textSummarizationsResponse?.text_summarizations || []}
            isLoading={isTextSummarizationsFetching || isNarrativesFetching}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            expandAll={expandAll}
            setExpandAll={setExpandAll}
            sortField={sortField}
            setSortField={setSortField}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            containsHighlight={containsHighlight}
            highlightsOnly={highlightsOnly}
            setHighlightsOnly={setHighlightsOnly}
            officerNarrative={officerNarrative}
            pageReset={reset}
        />
    );
};

type NarrativesViewProps = {
    narratives: NewNarrative[];
    textSummarizations: NarrativeSummary[];
    isLoading: boolean;
    currentPage?: any;
    totalPages?: any;
    setCurrentPage?: any;
    expandAll: any;
    setExpandAll: any;
    sortField: any;
    setSortField: any;
    sortDirection: any;
    setSortDirection: any;
    containsHighlight: boolean;
    highlightsOnly: boolean;
    setHighlightsOnly: Function;
    officerNarrative: NewNarrative;
    pageReset: any;
};
export const NarrativesView = ({
    narratives,
    textSummarizations,
    isLoading,
    currentPage,
    totalPages,
    setCurrentPage,
    expandAll,
    setExpandAll,
    sortField,
    setSortField,
    sortDirection,
    setSortDirection,
    containsHighlight,
    highlightsOnly,
    setHighlightsOnly,
    officerNarrative,
    pageReset,
}: NarrativesViewProps) => {
    const [toggledNarratives, setToggledNarratives] = React.useState<Set<string>>(new Set());

    const handleNarrativeToggle = (narrativeId: string) => {
        if (toggledNarratives.has(narrativeId)) {
            toggledNarratives.delete(narrativeId);
        } else {
            toggledNarratives.add(narrativeId);
        }
        setToggledNarratives(new Set(toggledNarratives));
    };

    React.useEffect(() => {
        setToggledNarratives(new Set());
    }, [expandAll]);

    const isNarrativeOpen = (narrativeId: string) => {
        // if current state is that items are expanded by default (expandAll === true), then those
        // narratives in toggledNarratives represent the closed narratives
        return expandAll ? !toggledNarratives.has(narrativeId) : toggledNarratives.has(narrativeId);
    };

    const narrativesOnlyContainsHighlights = narratives.every((narrative) => narrative.contains_highlight);

    return !isLoading ? (
        // must check existence of narratives length OR officerNarrative to determine if there are narratives to view
        // if narratives.length is 0, but officerNarrative exists, there is still a narrative to view
        !!officerNarrative || narratives.length > 0 ? (
            <Stack className="position-relative" gap={3}>
                <div className="w-100 d-flex justify-content-between">
                    <SortButton
                        sortField={sortField}
                        setSortField={setSortField}
                        sortDirection={sortDirection}
                        setSortDirection={setSortDirection}
                        sortConfig={sortConfig}
                    />
                    <div className="w-100 d-flex justify-content-end">
                        {containsHighlight && (
                            <Button
                                variant={highlightsOnly ? "secondary" : "outline-secondary"}
                                size="sm"
                                disabled={narrativesOnlyContainsHighlights && !highlightsOnly}
                                onClick={(e) => {
                                    setCurrentPage(0);
                                    setHighlightsOnly(!highlightsOnly);
                                }}
                                className="me-3 ms-3 pendo_event_narrative_matches"
                            >
                                {highlightsOnly ? "Show All Narratives" : "Only Show Matches"}
                            </Button>
                        )}
                        <Button
                            className="pendo_event_narratives_expand_all_toggle"
                            size="sm"
                            variant="outline-secondary"
                            onClick={() => setExpandAll(!expandAll)}
                        >
                            <span className="me-3">{expandAll ? "Collapse All" : "Expand All"}</span>
                            <FontAwesomeIcon icon={expandAll ? faMinus : faPlus} size="sm" />
                        </Button>
                    </div>
                </div>
                <ListGroup>
                    {officerNarrative && (
                        <NarrativeListItem
                            narrative={officerNarrative}
                            // key consists of constructed narrative id and body_hash (when available) to maximize uniqueness
                            key={`${officerNarrative.id as string}_${officerNarrative?.body_hash}`}
                            textSummary={textSummarizations?.find((textSummary) => textSummary?.id === officerNarrative.id)}
                            handleNarrativeToggle={() => handleNarrativeToggle(officerNarrative.id)}
                            isOpen={isNarrativeOpen(officerNarrative.id)}
                        />
                    )}
                    {narratives.map((narrative) => (
                        <NarrativeListItem
                            narrative={narrative}
                            // key consists of constructed narrative id and body_hash (when available) to maximize uniqueness
                            key={`${narrative.id as string}_${narrative?.body_hash}`}
                            textSummary={textSummarizations?.find((textSummary) => textSummary?.id === narrative.id)}
                            handleNarrativeToggle={() => handleNarrativeToggle(narrative.id)}
                            isOpen={isNarrativeOpen(narrative.id)}
                        />
                    ))}
                </ListGroup>
                <Row className="mt-3 mb-2 d-flex justify-content-center">
                    <Col xs={12} md={9}>
                        <FMPaginationNonLinks
                            page={currentPage + 1}
                            setPage={(page: number) => setCurrentPage(page - 1)}
                            totalPages={totalPages}
                            scrollToId={pageReset.scrollToId}
                        />
                    </Col>
                </Row>
            </Stack>
        ) : (
            <Alert variant="warning">No narratives to view.</Alert>
        )
    ) : (
        <div className="d-flex justify-content-center">
            <FMSpinner />
        </div>
    );
};

export default ConnectedNarrativesView;
