import React from "react";
import { parseAsHTML } from "../../utils/string";

/**
 * Runs the regex over the string passed in as children and splits the string up into jsx renderable components.
 * Each chunk of text is placed in the out array as an object with the text to render, an id and a flag indicating
 * if the text was surrounded by <em> tags that will be replaced by a <mark> tag with our highlight css class.
 *
 * The regex exec finds all of the <em> tags and pulls the text out from a substring if not wrapped in <em> tags or
 * from the regex capture group at match[2]
 *
 * Once all of the text is found, the code simply creates an outer <span> with the text in <span> or mark tags for
 * the highlighted matches.
 *
 * @param children text supplied to the Highlighter as a child object
 * @constructor
 */
const Highlighter = ({ children }: any) => {
    const regex = /<em>([\s\S]*?)<\/em>/gi;

    if (typeof children === "string" && !!children) {
        // replace &nbsp;
        let text = children.replace(/&nbsp;/gi, " ");
        text = text.replace(/&<em>nbsp<\/em>;/gi, " ");

        let match;
        let start = 0; // the start position of the next block of text
        let out = [];
        while ((match = regex.exec(text)) !== null) {
            if (match.index !== start) {
                out.push({ text: text.substring(start, match.index), id: start });
            }
            out.push({ text: match[1], highlight: true, id: match.index });
            start = regex.lastIndex;
        }
        out.push({ text: text.substring(start), id: text.length });

        return (
            <span>
                {out.map((item) => {
                    if (item.highlight) {
                        return (
                            <mark key={item.id} className="bg-warning bg-opacity-50 text-body" data-testid="highlighter">
                                {item.text}
                            </mark>
                        );
                    } else {
                        return <span key={item.id}>{parseAsHTML(item.text)}</span>;
                    }
                })}
            </span>
        );
    } else {
        return children;
    }
};

export default Highlighter;
