import React from "react";
import OptionFilter from "./OptionFilter";
import TagDetails from "../../../utils/TagDetails";

type TagsFilterType = {
    close: Function;
    clearView?: boolean;
    pendoClass?: string;
};

const TagsFilter = ({ close, clearView, pendoClass = "" }: TagsFilterType) => {
    const tags = TagDetails();

    // gets the options form index
    const options = tags.sort((a, b) => (a.name < b.name ? -1 : 1)).map((tag) => ({ value: tag.code, display: tag.name }));

    return (
        <OptionFilter
            options={options}
            pendoClass={pendoClass}
            paramName="tags"
            close={close}
            label="Choose Label Filters"
            clearView={clearView}
        />
    );
};

TagsFilter.displayName = "TagsFilter";
export default TagsFilter;
