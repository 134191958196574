import { faCheckSquare, faSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroup, Stack } from "react-bootstrap";

type OwnProps = {
    users: any[];
    usersToShare: any[];
    setUserFilterString: Function;
    toggleUser: Function;
};
const UserListGroup = ({ users, usersToShare, setUserFilterString, toggleUser }: OwnProps) => {
    return (
        <>
            <span className="fs-2 fw-bold">Select users to share with</span>
            <input
                name="share-filter-input"
                className="form-control mb-3"
                onChange={(event) => setUserFilterString(event.target.value)}
                placeholder="Filter by email or name"
            />
            <ListGroup className="flex-grow-1 overflow-auto">
                {users &&
                    users.length > 0 &&
                    users.map((user: any) => (
                        <ListGroup.Item
                            onClick={() => toggleUser(user.email)}
                            className="cursor-pointer d-flex align-items-center"
                            key={user.email}
                        >
                            <FontAwesomeIcon icon={usersToShare.includes(user.email) ? faCheckSquare : faSquare} />
                            <Stack className="ms-3 overflow-hidden">
                                <span>{user.full_name}&nbsp;</span>
                                <span className="d-inline-block text-truncate">{user.email}</span>
                            </Stack>
                        </ListGroup.Item>
                    ))}
            </ListGroup>
        </>
    );
};

export default UserListGroup;
