import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCircleExclamation, faCalendarClock, faCalendars, faCircleXmark, faTags } from "@fortawesome/pro-light-svg-icons";
import DateRange from "./DateRange";
import { useSearchParams } from "react-router-dom";
import TagDetails, { getValidatedLabels } from "../../../utils/TagDetails";
import { format, parseISO } from "date-fns";
import { RadarConfigNameEnum } from "../../../types/old_v1/types";
import TagsFilter from "./TagsFilter";
import IndexType from "./IndexType";
import TimeRange from "./TimeRange";
import AgencyFilter from "./AgencyFilter";
import { useMultiAgency } from "../../../hooks/useMultiAgency";
import SingleAgencyFilter from "./SingleAgencyFilter";
import useSingleAgencyFilter from "../../../hooks/useSingleAgencyFilter";
import { useFilters } from "../../../hooks/useFilters";

type StringStruct = {
    [key: string]: string;
};

const dateFormat = "yyyy-MM-dd";
const ONE_YEAR = "1year";
export const THREE_YEARS = "3years";

export const getRangeLabel = (dateRange: string | null, startDate: string | null, endDate: string | null) => {
    let label = "Date Range";
    const labels: StringStruct = {
        "3days": "3 Days",
        "4days": "4 Days",
        "7days": "7 Days",
        "8days": "8 Days",
        "30days": "30 days",
        "90days": "90 days",
        [ONE_YEAR]: "1 year",
        [THREE_YEARS]: "3 years",
    };
    // if no date range param is received, or if date range is custom, use start & end date
    // otherwise, get label from labels struct
    if (dateRange === "custom") {
        if (startDate && endDate) {
            const start = parseISO(startDate);
            const end = parseISO(endDate);
            label = `${format(start, dateFormat)} - ${format(end, dateFormat)}`;
        }
    } else if (!dateRange) {
        if (window.location.pathname.includes("map")) {
            label = labels["30days"];
        } else {
            label = labels[THREE_YEARS];
        }
    } else if (labels[dateRange]) {
        label = labels[dateRange];
    }
    return label;
};

export const getIndexTypeLabel = (indexType: string | null, incidentTextPlural?: string, callTextPlural?: string) => {
    let label = `${callTextPlural}/${incidentTextPlural}`;
    if (indexType === "calls") {
        label = callTextPlural || "Calls";
    } else if (indexType === "incidents") {
        label = incidentTextPlural || "Incidents";
    }
    return label;
};

export const getTagsLabel = (tags: string | null) => {
    const tagInfo = TagDetails();
    let label = "Labels";
    if (tags) {
        const tagsArray = tags.split(",");
        if (tagsArray.length && tagsArray.length > 2) {
            label = `${tagsArray.length} Labels`;
        } else {
            const displayTags = tagInfo.filter((detail) => tagsArray.includes(detail.code)).map((detail) => detail.name);
            if (displayTags.length) {
                label = displayTags.join(", ");
            }
        }
    }
    return label;
};

type OwnProps = {
    close: any;
    dateRange: any;
    startDate: any;
    endDate: any;
    pendoClass?: string;
};
export const DateRangeFilter = ({ close, dateRange, startDate, endDate, pendoClass = "" }: OwnProps) => {
    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            placement={"bottom-start"}
            defaultShow={false}
            overlay={
                <Popover className="mw-100 hide-arrow" style={{ width: "min(450px, 95%)" }}>
                    <Popover.Body>
                        <DateRange pendoClass={pendoClass} close={close} />
                    </Popover.Body>
                </Popover>
            }
        >
            <Button
                data-testid="date-filter-button"
                className={`cursor-pointer mb-2 text-nowrap rounded-pill date-filter`}
                variant="secondary"
                size="sm"
                onClick={() => close("dateRange")}
            >
                <FontAwesomeIcon icon={faCalendars} size={"1x"} />
                <span className="ms-2 fs-5">{getRangeLabel(dateRange, startDate, endDate)}</span>
            </Button>
        </OverlayTrigger>
    );
};

export const TagFilter = ({
    close,
    tags,
    clearView,
    pendoClass = "",
}: {
    close: any;
    tags: any;
    clearView?: boolean;
    pendoClass?: string;
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { updateFilters } = useFilters({ skipBeatsQuery: true });
    // get validated tags from tags, then join validated result back into string
    const validatedTagsString = getValidatedLabels((tags || "").split(","))
        .map((tag) => tag.code)
        .join(",");

    const clear = () => {
        const updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.delete("tags");
        if (clearView) {
            updatedSearchParams.set("view", RadarConfigNameEnum.CUSTOM);
        }
        setSearchParams(updatedSearchParams.toString());
        updateFilters("tags", null, updatedSearchParams);
    };

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            placement={"bottom-start"}
            defaultShow={false}
            overlay={
                <Popover className="mw-100 hide-arrow">
                    <Popover.Body>
                        <TagsFilter pendoClass={pendoClass} close={close} clearView={clearView} />
                    </Popover.Body>
                </Popover>
            }
        >
            <Button
                data-testid="tags-filter-button"
                className="cursor-pointer mb-2 ms-2 text-nowrap rounded-pill labels-filter"
                variant={validatedTagsString ? "secondary" : "outline-secondary"}
                size="sm"
                onClick={() => close("tags")}
            >
                <FontAwesomeIcon icon={faTags} /> {getTagsLabel(validatedTagsString)}
                {validatedTagsString && (
                    <FontAwesomeIcon
                        size="sm"
                        icon={faCircleXmark}
                        className="ms-1"
                        onClick={(e) => {
                            e.stopPropagation();
                            clear();
                        }}
                    />
                )}
            </Button>
        </OverlayTrigger>
    );
};

export const TimeRangeFilter = ({ close, pendoClass = "" }: { close: Function; pendoClass?: string }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const timeRange = searchParams.get("timeRange");
    const { updateFilters } = useFilters({ skipBeatsQuery: true });

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            placement="bottom-start"
            defaultShow={false}
            overlay={
                <Popover className="mw-100 hide-arrow" style={{ width: "min(650px, 95%)" }}>
                    <Popover.Body>
                        <TimeRange pendoClass={pendoClass} close={close} />
                    </Popover.Body>
                </Popover>
            }
        >
            <Button
                data-testid="time-filter-button"
                className="cursor-pointer ms-2 mb-2 text-nowrap rounded-pill time-filter"
                variant={!!timeRange ? "secondary" : "outline-secondary"}
                size="sm"
                onClick={() => close("timeRange")}
            >
                <FontAwesomeIcon icon={faCalendarClock} />
                &nbsp;{!!timeRange ? timeRange.replace(",", ", ") : "Time"}
                {timeRange && (
                    <FontAwesomeIcon
                        size="sm"
                        icon={faCircleXmark}
                        className="ms-1"
                        onClick={(e) => {
                            e.stopPropagation();
                            const updatedSearchParams = new URLSearchParams(searchParams.toString());
                            updatedSearchParams.delete("timeRange");
                            setSearchParams(updatedSearchParams.toString());
                            updateFilters("timeRange", null, updatedSearchParams);
                        }}
                    />
                )}
            </Button>
        </OverlayTrigger>
    );
};

export const IndexTypeFilter = ({
    close,
    indexType,
    pendoClass = "",
    incidentTextPlural,
    callTextPlural,
}: {
    close: Function;
    indexType: any;
    pendoClass?: string;
    incidentTextPlural?: string;
    callTextPlural?: string;
}) => {
    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            placement={"bottom-start"}
            defaultShow={false}
            overlay={
                <Popover className="mw-100 hide-arrow">
                    <Popover.Body>
                        <IndexType pendoClass={pendoClass} close={close} />
                    </Popover.Body>
                </Popover>
            }
        >
            <Button
                data-testid="index-type-filter-button"
                className="cursor-pointer mb-2 ms-2 text-nowrap rounded-pill index-type-filter"
                variant="secondary"
                size="sm"
                onClick={() => close("indexType")}
            >
                <FontAwesomeIcon icon={faCalendarCircleExclamation} /> {getIndexTypeLabel(indexType, incidentTextPlural, callTextPlural)}
            </Button>
        </OverlayTrigger>
    );
};

export const AgencyFilterButton = ({ close, agencies }: { close: Function; agencies: string | null }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { showMultiAgencyFilter, userSubAgencies } = useMultiAgency();
    const filterAgenciesList = agencies?.split(",") || [];

    const filterAgenciesNames = userSubAgencies
        .filter((agency: any) => filterAgenciesList.includes(agency.fm_uuid))
        .map((agency: any) => agency.name);

    let label = "Agency";

    if (filterAgenciesNames.length > 2) {
        label = `${filterAgenciesNames.length} Agencies`;
    } else if (filterAgenciesNames.length > 0) {
        label = filterAgenciesNames.join(", ");
    }

    const clear = () => {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete("agencies");
        setSearchParams(updatedSearchParams);
    };

    // only display agency filter button if there are at least two sub agencies user can view
    return !showMultiAgencyFilter ? (
        <></>
    ) : (
        <OverlayTrigger
            rootClose
            trigger="click"
            placement={"bottom-start"}
            defaultShow={false}
            overlay={
                <Popover className="mw-100 hide-arrow">
                    <Popover.Body>
                        <AgencyFilter close={close} />
                    </Popover.Body>
                </Popover>
            }
        >
            <Button
                data-testid="index-type-filter-button"
                className="cursor-pointer mb-2 me-2 text-nowrap rounded-pill pendo-agencies-filter"
                variant={filterAgenciesNames.length > 0 ? "secondary" : "outline-secondary"}
                size="sm"
                onClick={() => close("agencies")}
            >
                <FontAwesomeIcon icon={faCalendarCircleExclamation} />
                &nbsp;{label}
                {filterAgenciesNames.length > 0 && (
                    <FontAwesomeIcon
                        size="sm"
                        icon={faCircleXmark}
                        className="ms-1"
                        onClick={(e) => {
                            e.stopPropagation();
                            clear();
                        }}
                    />
                )}
            </Button>
        </OverlayTrigger>
    );
};

export const SingleAgencyFilterButton = ({ close }: { close: Function }) => {
    const { showMultiAgencyFilter, userSubAgencies } = useMultiAgency();
    const { selectedAgency } = useSingleAgencyFilter();

    // only display agency filter button if there are at least two sub agencies user can view
    return !showMultiAgencyFilter ? (
        <></>
    ) : (
        <OverlayTrigger
            rootClose
            trigger="click"
            placement={"bottom-start"}
            defaultShow={false}
            overlay={
                <Popover className="mw-100 hide-arrow">
                    <Popover.Body>
                        <SingleAgencyFilter close={close} selectedAgencyUUID={selectedAgency?.fm_uuid} agencies={userSubAgencies} />
                    </Popover.Body>
                </Popover>
            }
        >
            <Button
                className="cursor-pointer mb-2 me-2 text-nowrap rounded-pill pendo-agencies-filter"
                variant={"secondary"}
                size="sm"
                onClick={() => close("agencies")}
            >
                <FontAwesomeIcon icon={faCalendarCircleExclamation} />
                &nbsp;{selectedAgency?.name || ""}
            </Button>
        </OverlayTrigger>
    );
};
