import { useMultiAgency } from "../../hooks/useMultiAgency";
import SingleSelectFilter from "./SingleSelectFilter";

const SingleAgencyFilter = () => {
    const { userSubAgencies } = useMultiAgency();

    return (
        <SingleSelectFilter
            pendoClass="pendo_single_agency_filter_modal_select"
            options={userSubAgencies.map((agency: any) => ({ value: agency?.fm_uuid, name: agency?.name, label: agency?.name }))}
            urlParamName={"agencyUUID"}
            filterLabel="Select single agency to filter by"
            clearFilterParams={["beats"]}
        />
    );
};

export default SingleAgencyFilter;
