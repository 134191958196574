import React from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faTrashUndo, faXmark } from "@fortawesome/pro-light-svg-icons";
import { useSearchParams } from "react-router-dom";

type TimeRangeProps = {
    close: Function;
    pendoClass?: string;
};

const PARAM_NAME = "timeRange";

const HOURS = Array.from(Array(24).keys());

const TimeRange = ({ close, pendoClass = "" }: TimeRangeProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [startTimeInitial, endTimeInitial] = (searchParams.get(PARAM_NAME) || "").split("-");
    const [startTime, setStartTime] = React.useState(startTimeInitial);
    const [endTime, setEndTime] = React.useState(endTimeInitial);

    const apply = () => {
        const updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.set(PARAM_NAME, `${startTime}-${endTime}`);
        setSearchParams(updatedSearchParams.toString());
        close(PARAM_NAME);
    };

    const clear = () => {
        const updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.delete("timeRange");
        setSearchParams(updatedSearchParams.toString());
        close(PARAM_NAME);
    };

    return (
        <div>
            <Form className="mt-4 mb-3">
                <Form.Group>
                    <Row>
                        <Col xs={6}>
                            <Form.Label>Start Time</Form.Label>
                            <Form.Select value={startTime} onChange={(e) => setStartTime(e.target.value)}>
                                <option>Choose Hour...</option>
                                {HOURS.map((option) => (
                                    <option key={option} value={option}>
                                        {option !== 0 ? option : `${option} (start of day)`}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={6}>
                            <Form.Label>End Time</Form.Label>
                            <Form.Select value={endTime} onChange={(e) => setEndTime(e.target.value)}>
                                <option>Choose Hour...</option>
                                {HOURS.map((option) => (
                                    <option key={option} value={option}>
                                        {option !== 0 ? option : `${option} (end of day)`}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
            <Row>
                <Col xs={6}>
                    <Button className="text-nowrap" variant="outline-secondary" onClick={() => close(PARAM_NAME)}>
                        <FontAwesomeIcon icon={faXmark} /> Cancel
                    </Button>
                </Col>
                <Col xs={6}>
                    <Button
                        className={`float-end ${pendoClass ? `${pendoClass}-apply` : ""}`}
                        variant="primary"
                        onClick={apply}
                        disabled={!startTime || !endTime}
                    >
                        <FontAwesomeIcon icon={faPlay} /> Apply
                    </Button>
                    <Button
                        className={`float-end me-2 ${pendoClass ? `${pendoClass}-clear` : ""}`}
                        variant="primary"
                        onClick={clear}
                        disabled={!startTimeInitial || !endTimeInitial}
                    >
                        <FontAwesomeIcon icon={faTrashUndo} /> Clear
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default TimeRange;
