import { Alert, Col, ListGroup, Row, Stack } from "react-bootstrap";
import PersonCard from "./PersonCard";
import SortButton from "../SortButton";
import { v4 as uuid } from "uuid";
import { AssociatedPerson, SortConfig, SortDirectionEnum } from "../../types/old_v1/types";
import { sortSortableHeader } from "../../utils/sort";
import React from "react";
import { FMPaginationNonLinks } from "../pagination/Pagination";
import { usePagination } from "../../hooks/usePagination";
import FMSpinner from "../Spinner";

type OwnProps = {
    emptyResultsMessage?: string;
    isLoading?: boolean;
    associatedPeople: AssociatedPerson[];
    associatedPeopleCount?: number;
    eventData?: any[];
    sortConfig: SortConfig;
    defaultSortField: string;
    defaultSortDirection: SortDirectionEnum;
    showRole?: boolean;
    scrollToTop?: any;
    pageReset?: any;
    agencyId: string | undefined;
};

const AssociatedPeopleView = ({
    associatedPeople,
    isLoading = false,
    eventData,
    sortConfig,
    defaultSortField,
    defaultSortDirection,
    showRole = false,
    scrollToTop,
    pageReset,
    agencyId,
}: OwnProps) => {
    const [sortField, setSortField] = React.useState(defaultSortField);
    const [sortDirection, setSortDirection] = React.useState(defaultSortDirection);
    const [personFilterString, setPersonFilterString] = React.useState("");

    const sortedPeople = [...associatedPeople]
        .filter((person) => {
            if (!personFilterString) {
                return true;
            }
            const lowercaseFilter = personFilterString.toLowerCase();
            return (
                `${person?.first_name} ${person?.middle_name} ${person?.last_name}`.toLowerCase().includes(lowercaseFilter) ||
                `${person?.first_name} ${person?.last_name}`.toLowerCase().includes(lowercaseFilter) ||
                `${person?.role}`.toLowerCase().includes(lowercaseFilter)
            );
        })
        .sort(sortSortableHeader(sortField, true, sortDirection));

    const filterPeopleList = (event: any) => {
        setPersonFilterString(event.target.value);
    };

    const reset = React.useMemo(
        () => ({ ...pageReset, sortField, sortDirection, personFilterString }),
        [pageReset, sortDirection, sortField, personFilterString]
    );

    const { currentPage, currentPageData, totalPages, setCurrentPage } = usePagination(sortedPeople || [], 10, reset, scrollToTop);

    return (
        <Stack gap={3}>
            <Stack direction="horizontal" gap={5}>
                <SortButton
                    sortField={sortField}
                    setSortField={setSortField}
                    sortConfig={sortConfig}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                />
                <input
                    className="form-control pendo_by_name_filter_input"
                    name="associated-people-filter-input"
                    onChange={filterPeopleList}
                    placeholder="Filter by Name"
                />
            </Stack>
            {isLoading && <FMSpinner centered />}
            {!isLoading && sortedPeople.length > 0 && (
                <>
                    <ListGroup className="mt-3 gap-5">
                        {currentPageData.map((person) => (
                            <PersonCard
                                associatedPerson={person}
                                key={person.person_id || uuid()}
                                eventData={eventData}
                                showRole={showRole}
                                agencyId={agencyId}
                            />
                        ))}
                    </ListGroup>
                    <Row className="mt-3 mb-2 d-flex justify-content-center">
                        <Col xs={12} md={9}>
                            <FMPaginationNonLinks
                                page={currentPage + 1}
                                setPage={(page: number) => setCurrentPage(page - 1)}
                                totalPages={totalPages}
                                scrollToId={pageReset?.scrollToId}
                            />
                        </Col>
                    </Row>
                </>
            )}
            {!isLoading && sortedPeople.length === 0 && <Alert variant="warning">No associated people.</Alert>}
        </Stack>
    );
};

export default AssociatedPeopleView;
