import React from "react";
import { Formik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import * as Yup from "yup";
import { validators } from "../../utils/validators";
import { EmployeePositionsEnum } from "../../types/old_v1/types";
import { underToWords } from "../../utils/string";
import { useUpdateUserDetailsMutation } from "../../api/api";
import { setShownPositionPrompt } from "../../app/slices/user/userSlice";
import ModalHeader from "../../components/ModalHeader";

const PhoneAndPositionSchema = Yup.object().shape({
    agencyPosition: Yup.string().required("Required").not([EmployeePositionsEnum.NOT_SET], "Position must be a valid value"),
    phone: validators.phone.notRequired(),
});

const ConnectedPhoneAndPositionModal = () => {
    const [updateUserDetails, { isLoading: isUpdating }] = useUpdateUserDetailsMutation();
    const user = useAppSelector(({ user }) => user.userObject);
    const shownPositionPrompt = useAppSelector(({ user }) => user.shownPositionPrompt);

    /**
     * if updated EULA has not been accepted yet, then EULA modal will display
     * we do not want two modals to display at the same time
     * so we do not try to render this component until EULA has been accepted
     */
    return !!user && !user.must_accept_eula ? (
        <PhoneAndPositionModal
            shownPositionPrompt={shownPositionPrompt}
            agencyPosition={user.agency_position}
            phone={user.phone}
            updateUserDetails={updateUserDetails}
            isUpdating={isUpdating}
        />
    ) : (
        <></>
    );
};

type OwnProps = {
    shownPositionPrompt: boolean;
    agencyPosition: EmployeePositionsEnum;
    phone?: string | null;
    updateUserDetails: Function;
    isUpdating: boolean;
};
export const PhoneAndPositionModal = ({ shownPositionPrompt, agencyPosition, phone, updateUserDetails, isUpdating }: OwnProps) => {
    const dispatch = useAppDispatch();

    const initialValues = {
        agencyPosition: agencyPosition || "",
        phone: phone || "",
    };

    const submitFn = (values: { agencyPosition: string; phone: string }) => {
        if (values) {
            updateUserDetails(values);
            dispatch(setShownPositionPrompt(true));
        }
    };

    const closeFn = () => {
        dispatch(setShownPositionPrompt(true));
    };

    return (
        <Modal show={!shownPositionPrompt && agencyPosition === EmployeePositionsEnum.NOT_SET} centered onHide={closeFn}>
            <ModalHeader title="Please select a position" />
            <Formik initialValues={initialValues} onSubmit={submitFn} validationSchema={PhoneAndPositionSchema}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            {!phone && (
                                <Form.Group controlId="formPhone" className="mb-3">
                                    <Form.Label className="w-100">Phone</Form.Label>
                                    <Form.Control
                                        type="phone"
                                        name="phone"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone || ""}
                                        isInvalid={!!errors.phone && !!touched.phone}
                                    />
                                    {!!errors.phone && !!touched.phone ? <div className="text-danger">{errors.phone}</div> : null}
                                </Form.Group>
                            )}
                            <Form.Group controlId="formAgencyPosition">
                                <Form.Label className="w-100">Position</Form.Label>
                                <Form.Select
                                    value={values.agencyPosition || ""}
                                    name="agencyPosition"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.agencyPosition && !!touched.agencyPosition}
                                >
                                    <option value="">Choose position...</option>
                                    {Object.values(EmployeePositionsEnum).map((position: string) => (
                                        <option key={position} value={position}>
                                            {underToWords(position)}
                                        </option>
                                    ))}
                                </Form.Select>
                                {!!errors.agencyPosition && !!touched.agencyPosition ? (
                                    <div className="text-danger">{errors.agencyPosition}</div>
                                ) : null}
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                disabled={
                                    values.agencyPosition === EmployeePositionsEnum.NOT_SET ||
                                    !!errors.phone ||
                                    !!errors.agencyPosition ||
                                    isUpdating
                                }
                                type="submit"
                                variant="success"
                                className="save-phone-position"
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ConnectedPhoneAndPositionModal;
