import React from "react";
import Map, {
    Layer,
    LngLatBoundsLike,
    MapLayerMouseEvent,
    NavigationControl,
    FullscreenControl,
    Popup,
    Source,
    useMap,
    ViewStateChangeEvent,
} from "react-map-gl";
import { useAppSelector } from "../../app/hooks";
import { MapStyles, SourceConfig } from "../../types/old_v1/types";
import { store } from "../../app/store";
import { useSearchParams } from "react-router-dom";
import { flatten } from "lodash";
import PopupComponent from "./Popup";
import { setShowPopup } from "../../app/slices/map/mapSlice";
import { useDispatch } from "react-redux";
import { useDarkMode } from "../../hooks/useDarkMode";
import { calcBoundsFromCoordinates, calculateBbox } from "./utils/gis";
import { defaultMobileSizePx } from "../../constants/constants";

type Styles = "streets" | "outdoor" | "light" | "dark" | "satellite";

type StyleObj = {
    [key in Styles]: string;
};

const STYLES: StyleObj = {
    streets: "mapbox://styles/mapbox/streets-v11",
    outdoor: "mapbox://styles/mapbox/outdoors-v11",
    light: "mapbox://styles/mapbox/light-v10",
    dark: "mapbox://styles/mapbox/dark-v10",
    satellite: "mapbox://styles/mapbox/satellite-streets-v11",
};

type OwnProps = {
    id: string;
    sources?: SourceConfig[];
    layerClickEventHandler?: (event: MapLayerMouseEvent) => void;
    layerMouseEnterEventHandler?: (event: MapLayerMouseEvent) => void;
    layerMouseLeaveEventHandler?: (event: MapLayerMouseEvent) => void;
    layerMouseOverEventHandler?: (event: MapLayerMouseEvent) => void;
    layerMouseOutEventHandler?: (event: MapLayerMouseEvent) => void;
    moveEndEventHandler?: (event: ViewStateChangeEvent) => void;
    moveStartEventHandler?: (event: ViewStateChangeEvent) => void;
    disableRebounding?: boolean;
    fillContainer?: boolean;
    hoverEvent?: Function;
    style?: MapStyles;
    padding?: number;
    zoomLevel?: number;
    maxZoom?: number;
    clickableBeats?: boolean;
    isNotInteractive?: boolean;
    mapV2?: boolean;
    dynamicCentering?: boolean;
    navigationControl?: boolean;
    fullscreenControl?: boolean;
};

const initialMapCenter = {
    latitude: 39.8283,
    longitude: -98.5795,
    zoom: 4.5,
};

const MapComponent = ({
    id,
    sources,
    moveEndEventHandler,
    moveStartEventHandler,
    layerMouseEnterEventHandler,
    layerMouseLeaveEventHandler,
    layerMouseOverEventHandler,
    layerMouseOutEventHandler,
    layerClickEventHandler,
    fillContainer,
    disableRebounding,
    hoverEvent,
    style,
    padding = 10,
    maxZoom = 17,
    zoomLevel,
    clickableBeats,
    isNotInteractive,
    mapV2,
    dynamicCentering = false,
    navigationControl = true,
    fullscreenControl = false,
}: OwnProps) => {
    const { isDarkMode } = useDarkMode();
    const mapStyle = !!style ? STYLES[style] : isDarkMode ? STYLES["dark"] : window?.__RUNTIME_CONFIG__?.REACT_APP_MAPBOX_DEFAULT_STYLE;
    const showPopup = useAppSelector((state) => state.map.showPopup);
    const popupProperties = useAppSelector((state) => state.map.popupProperties);
    const agencyCoords = store.getState().user.userAgencyCoords;
    const [searchParams] = useSearchParams();
    const searchQuery = searchParams.get("query") || "";
    const [cursor, setCursor] = React.useState<string>("default");
    const [mapId, setMapId] = React.useState<string>("");
    const mapObj = useMap();
    const dispatch = useDispatch();
    const topLeft = searchParams.get("topLeft") || "";

    //useMap hook returns an object of all maps rendered by id. We are rendering two maps and showing one based on
    // breakpoint so set id in state of each, so we can access the correct map for initial zoom bounding
    React.useEffect(() => {
        setMapId(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fitBounds = React.useCallback(
        (bounds: number[][], singleDot: boolean) => {
            if (!!mapObj[mapId]) {
                const defaultConfig: {
                    padding: number;
                    duration: number;
                    maxZoom: number;
                    zoom?: number;
                } = {
                    padding,
                    duration: 0,
                    maxZoom,
                };

                //single dot situations need more zoom than may be provided especially on person map
                if (singleDot) {
                    defaultConfig.zoom = 14;
                } else if (zoomLevel) {
                    defaultConfig.zoom = zoomLevel;
                }
                mapObj[mapId]?.fitBounds(bounds as LngLatBoundsLike, defaultConfig);
                if (dynamicCentering && window.innerWidth > defaultMobileSizePx) {
                    mapObj[mapId]?.easeTo({
                        padding: {
                            left: document.getElementById("desktopMapSidePanel")?.offsetWidth || 300,
                            right: (document.getElementById("desktopMapSidePanel")?.offsetWidth || 300) / 2,
                            top: 20,
                            bottom: 20,
                        },
                        duration: 333,
                    });
                }
            }
        },
        [mapObj, mapId, padding, maxZoom, zoomLevel, dynamicCentering]
    );

    //if topLeft has value we have manually zoomed and moved the map so don't reset
    React.useEffect(() => {
        if (!disableRebounding && !!sources && !!mapObj[mapId] && topLeft === "") {
            let featureCount = 0;
            let cumulativeBounds: number[][] = [];
            sources.forEach((source) => {
                // calculate each source's bbox
                if (source.setBounds) {
                    if (source.data && source.data.features.length > 0) {
                        featureCount += source.data.features.length;
                        const featureBounds = calculateBbox(source.data, 0);

                        if (!featureBounds.flat().includes(NaN)) {
                            cumulativeBounds = [...cumulativeBounds, ...featureBounds];
                        }
                    }
                }
            });

            // using cumulative bboxes, calculate bounds to fit map to
            if (cumulativeBounds.length) {
                fitBounds(calcBoundsFromCoordinates(cumulativeBounds), featureCount === 1);
            }
        }
    }, [disableRebounding, fitBounds, mapId, mapObj, sources, topLeft]);

    let initialViewState: any = {
        latitude: agencyCoords ? agencyCoords.latitude : initialMapCenter.latitude,
        longitude: agencyCoords ? agencyCoords.longitude : initialMapCenter.longitude,
        zoom: agencyCoords ? agencyCoords.zoom : initialMapCenter.zoom,
        bearing: 0,
        pitch: 0,
        padding: { top: 0, bottom: 0, left: 0, right: 0 },
    };

    const interactiveLayers = !!sources
        ? (flatten(
              sources.map((source) =>
                  source.layers.filter((layer) => (clickableBeats ? layer : !layer?.id?.includes("beat"))).map((layer) => layer.id)
              )
          ) as string[])
        : [];

    let classStyle = { height: fillContainer ? "100%" : "75vh", width: "100%" };
    if (mapV2) {
        classStyle = { height: "25vh", width: "100%" };
    }

    return (
        <Map
            id={id}
            style={classStyle}
            mapboxAccessToken={window?.__RUNTIME_CONFIG__?.REACT_APP_MAPBOX_TOKEN}
            mapStyle={mapStyle}
            initialViewState={initialViewState}
            cursor={cursor}
            onClick={!!sources && !!layerClickEventHandler ? layerClickEventHandler : () => {}}
            onMouseEnter={(evt: any) => {
                setCursor("pointer");
                if (!!layerMouseEnterEventHandler) {
                    layerMouseEnterEventHandler(evt);
                }
            }}
            onMouseOver={(evt: any) => {
                if (!!layerMouseOverEventHandler) {
                    setCursor("pointer");
                    layerMouseOverEventHandler(evt);
                }
            }}
            onMouseOut={(evt: any) => {
                if (!!layerMouseOutEventHandler) {
                    setCursor("default");
                    layerMouseOutEventHandler(evt);
                }
            }}
            onMouseLeave={(evt: any) => {
                setCursor("default");
                if (!!layerMouseLeaveEventHandler) {
                    layerMouseLeaveEventHandler(evt);
                }
            }}
            onDragStart={(evt: ViewStateChangeEvent) => {
                setCursor("move");
                if (!!moveStartEventHandler) {
                    moveStartEventHandler(evt);
                }
            }}
            onDragEnd={(evt: ViewStateChangeEvent) => {
                setCursor("default");
                if (!!moveEndEventHandler) {
                    moveEndEventHandler(evt);
                }
            }}
            onZoomStart={(evt: ViewStateChangeEvent) => {
                if (!!moveStartEventHandler) {
                    moveStartEventHandler(evt);
                }
            }}
            onZoomEnd={(evt: ViewStateChangeEvent) => {
                if (!!moveEndEventHandler) {
                    moveEndEventHandler(evt);
                }
            }}
            interactive={!isNotInteractive}
            attributionControl={false}
            interactiveLayerIds={interactiveLayers}
            logoPosition="bottom-right"
        >
            {showPopup && !!popupProperties && popupProperties.lng && popupProperties.lat && (
                <Popup
                    longitude={popupProperties.lng}
                    latitude={popupProperties.lat}
                    closeButton={false}
                    closeOnClick={false}
                    onClose={() => dispatch(setShowPopup(false))}
                    className="popup-container"
                    anchor="bottom"
                >
                    <PopupComponent {...popupProperties} searchQuery={searchQuery} hoverEvent={hoverEvent} />
                </Popup>
            )}
            {!!sources &&
                sources.map((source) => (
                    <Source
                        key={source.id}
                        id={source.id}
                        type="geojson"
                        data={source.data}
                        cluster={source.cluster}
                        clusterProperties={source.clusterProperties}
                    >
                        {source.layers.map((layer) => (
                            <Layer key={layer.id} {...layer} />
                        ))}
                    </Source>
                ))}
            {navigationControl && <NavigationControl showCompass={false} visualizePitch={false} />}
            {fullscreenControl && <FullscreenControl />}
        </Map>
    );
};

export default MapComponent;
