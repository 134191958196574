import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
// v1 api call
import { useDeleteRadarConfigMutation } from "../../api/api";
import { RadarConfigNameEnum } from "../../types/types";

const RadarDeleteConfigModal = ({
    show,
    close,
    config,
    isSelected,
    setView,
}: {
    show: boolean;
    close: Function;
    config: any;
    isSelected: boolean;
    setView: (v: string) => void;
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [alertMsg, setAlertMsg] = React.useState<string | null>(null);

    const [deleteRadarConfigMutation] = useDeleteRadarConfigMutation();

    const deleteRadarConfig = () => {
        deleteRadarConfigMutation({ id: config.id }).then((response: any) => {
            if (response?.data?.success) {
                if (isSelected) {
                    const updatedSearchParams = new URLSearchParams(searchParams.toString());
                    updatedSearchParams.set("view", RadarConfigNameEnum.CUSTOM);
                    setView(RadarConfigNameEnum.CUSTOM);
                    setSearchParams(updatedSearchParams.toString());
                }
                close();
            } else {
                setAlertMsg("Failed to delete config!");
            }
        });
    };

    return (
        <Modal centered show={show}>
            <Modal.Header>
                <Modal.Title as="h3">Delete Config</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="text-break">Are you sure you would like to delete the "{config?.config_name}" config?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => close()}>
                    Cancel
                </Button>
                <Button disabled={!config} variant="success" onClick={() => deleteRadarConfig()}>
                    Confirm
                </Button>
                {!!alertMsg && (
                    <Alert className="mt-3" variant="warning" onClose={() => setAlertMsg(null)} dismissible>
                        <Alert.Heading>Failed to Delete</Alert.Heading>
                        <p>Failed to delete radar config please try again. If issue persists, please contact system administrator.</p>
                    </Alert>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default RadarDeleteConfigModal;
