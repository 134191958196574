import React from "react";
import { format, isValid, parseISO } from "date-fns";
import { EntitiesType, FilterParamNamesType, LayerTypes } from "../../types/old_v1/types";
import FilterBadge from "./FilterBadge";
import { Entities, FilterParamNames } from "../../constants/constants";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import {
    faBirthdayCake,
    faBook,
    faCalendarCircleExclamation,
    faCalendarClock,
    faCalendars,
    faChartTreeMap,
    faListOl,
    faPeopleSimple,
    faPerson,
    faPhoneArrowUpRight,
    faPoliceBox,
    faSiren,
    faSitemap,
    faTags,
    faUserPolice,
} from "@fortawesome/pro-light-svg-icons";
import { faPhoneOffice } from "@fortawesome/pro-regular-svg-icons";
import usePermissions from "../../hooks/usePermissions";
import useIsMapPage from "../../hooks/useIsMapPage";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";
import useAgencySettings from "../../hooks/useAgencySettings";
import { getValidatedLabels } from "../../utils/TagDetails";
import { useFilters } from "../../hooks/useFilters";

type StringStruct = {
    [key: string]: string;
};

const dateFormat = "yyyy-MM-dd";
const ONE_YEAR = "1year";
export const THREE_YEARS = "3years";
const ALL_TIME = "all_time";

export const getRangeLabel = (dateRange: string | null, startDate: string | null, endDate: string | null) => {
    let label = "";
    const labels: StringStruct = {
        "4days": "4 Days",
        "8days": "8 Days",
        "30days": "30 Days",
        "90days": "90 Days",
        [ONE_YEAR]: "1 Year",
        [THREE_YEARS]: "3 Years",
        [ALL_TIME]: "All Time",
    };
    // if no date range param is received, or if date range is custom, use start & end date
    // otherwise, get label from labels struct
    if (!dateRange || dateRange === "custom") {
        if (isValid(parseISO(startDate || "")) && isValid(parseISO(endDate || ""))) {
            const start = parseISO(startDate || "");
            const end = parseISO(endDate || "");
            label = `${format(start, dateFormat)} - ${format(end, dateFormat)}`;
        } else {
            label = "Malformed Date";
        }
    } else if (dateRange === "all_time") {
        label = "All Time";
    } else if (labels[dateRange]) {
        label = labels[dateRange];
    }
    return label;
};

type OwnProps = {
    tab: EntitiesType | string;
    dateRangeDefaultValue?: string;
    canDateRangeBeAnyTime?: boolean;
    hideFilterList?: FilterParamNamesType[];
    modalView?: boolean;
    showModal?: Function | null;
    showSingleAgencyFilter?: boolean;
    isSingleAgencyMode?: boolean;
    includeBeatGeometry?: boolean;
};
const FilterBar = ({
    tab,
    dateRangeDefaultValue = ALL_TIME,
    canDateRangeBeAnyTime = false,
    hideFilterList = [],
    modalView = false,
    showModal = null,
    showSingleAgencyFilter = false,
    isSingleAgencyMode = false,
    includeBeatGeometry = false,
}: OwnProps) => {
    const { searchParams } = useSingleAgencyFilter();
    const { isMultiAgency, showMultiAgencyFilter, userSubAgencies } = useMultiAgency();
    const { hasPermission } = usePermissions();
    const { callText, incidentText, incidentTextPlural, callTextPlural } = useAgencySettings();
    // Single agency mode is used by dashboards that require geometry data
    // Include Geometry is mainly passed into the FilterBar component and used here to not double trigger the beats query
    const { selectedBeats } = useFilters({
        isSingleAgencyMode: isSingleAgencyMode,
        includeGeometry: includeBeatGeometry,
    });

    const agencies = searchParams.get("agencies");
    const agencyUUID = searchParams.get("agencyUUID");

    // validate labels and reformat for filter badge
    const tags = getValidatedLabels((searchParams.get("tags") || "").split(","))
        .map((tag) => tag.code)
        .join(",");
    const dateRange = searchParams.get("dateRange");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const timeRange = searchParams.get("timeRange");
    const gender = searchParams.get("gender");
    const race = searchParams.get("race");
    const incidentTypes = searchParams.get("incidentTypes");
    const callTypes = searchParams.get("callTypes");
    const officer = searchParams.get("officer");
    const dispatcher = searchParams.get("dispatcher");
    const indexType = searchParams.get("indexType");
    const hasAllLabels = searchParams.get("hasAllTags");
    const age = searchParams.get("age");
    const offenses = searchParams.get("offenses");
    const callSources = searchParams.get("callSources");
    const callPriorities = searchParams.get("callPriorities");
    // Multi-Agency situations require a more descriptive name than just the beat_name so add the agency_desc to differentiate
    // in case there are multiple beats with the same name. If multi agency but in single agency mode(dashboards), just show the beat name.
    const beats = selectedBeats
        ?.map((beat: any) => {
            if (isMultiAgency) {
                return isSingleAgencyMode ? beat.beat_name : beat.agency_desc + "-" + beat.beat_name;
            }

            return beat.beat_name;
        })
        .toString();

    const filterDataStruct = {
        [Entities.EVENT]: [
            {
                displayLabel: "Time Range",
                data: timeRange,
                pendoClass: "pendo_time_range_filter_bar_badge",
                dataLabel: FilterParamNames.TIME_RANGE,
                andFilter: true,
                icon: faCalendarClock,
            },
            {
                displayLabel: "Officer",
                data: officer,
                pendoClass: "pendo_officer_filter_bar_badge",
                dataLabel: FilterParamNames.OFFICER,
                andFilter: true,
                icon: faUserPolice,
                hide: !hasPermission("insights_dashboard_officer"),
            },
            {
                displayLabel: "Dispatcher",
                data: dispatcher,
                pendoClass: "pendo_dispatcher_filter_bar_badge",
                dataLabel: FilterParamNames.DISPATCHER,
                andFilter: true,
                icon: faPhoneOffice,
                hide: !hasPermission("insights_dashboard_dispatcher"),
            },
            {
                displayLabel: "Beats",
                data: beats,
                pendoClass: "pendo_beats_filter_bar_badge",
                dataLabel: FilterParamNames.BEATS,
                andFilter: false,
                icon: faChartTreeMap,
            },
            {
                displayLabel: "Labels",
                data: tags,
                pendoClass: "pendo_labels_filter_bar_badge",
                dataLabel: FilterParamNames.LABELS,
                andFilter: !!hasAllLabels,
                icon: faTags,
            },
            {
                displayLabel: `${incidentText} Types`,
                data: incidentTypes?.replace(",", ", "),
                pendoClass: "pendo_incident_types_filter_bar_badge",
                dataLabel: FilterParamNames.INCIDENT_TYPES,
                andFilter: false,
                icon: faSiren,
            },
            {
                displayLabel: `${callText} Types`,
                pendoClass: "pendo_call_types_filter_bar_badge",
                data: callTypes?.replace(",", ", "),
                dataLabel: FilterParamNames.CALL_TYPES,
                andFilter: false,
                icon: faPoliceBox,
            },
            {
                displayLabel: `${callText} Sources`,
                data: callSources?.replace(",", ", "),
                pendoClass: "pendo_call_sources_filter_bar_badge",
                dataLabel: FilterParamNames.CALL_SOURCES,
                andFilter: false,
                icon: faPhoneArrowUpRight,
            },
            {
                displayLabel: `${callText} Priorities`,
                data: callPriorities?.replace(",", ", "),
                pendoClass: "pendo_call_priorities_filter_bar_badge",
                dataLabel: FilterParamNames.CALL_PRIORITIES,
                andFilter: false,
                icon: faListOl,
            },
            {
                displayLabel: "Offenses",
                pendoClass: "pendo_offenses_filter_bar_badge",
                data: offenses?.replace(",", ", "),
                dataLabel: FilterParamNames.OFFENSES,
                andFilter: false,
                icon: faBook,
            },
        ],
        [Entities.PERSON]: [
            {
                displayLabel: "Gender",
                data: gender ? gender.replace(",", ", ") : null,
                pendoClass: "pendo_gender_filter_bar_badge",
                dataLabel: FilterParamNames.GENDER,
                andFilter: true,
                icon: faPeopleSimple,
            },
            {
                displayLabel: "Races",
                data: race?.replace(",", ", "),
                pendoClass: "pendo_races_filter_bar_badge",
                dataLabel: FilterParamNames.RACE,
                andFilter: false,
                icon: faPerson,
            },
            {
                displayLabel: "Age",
                data: age ? `${age} years old` : null,
                pendoClass: "pendo_age_filter_bar_badge",
                dataLabel: FilterParamNames.AGE,
                andFilter: false,
                icon: faBirthdayCake,
            },
        ],
        dispatcher: [
            {
                displayLabel: "Beats",
                data: beats,
                pendoClass: "pendo_beats_filter_bar_badge",
                dataLabel: FilterParamNames.BEATS,
                andFilter: false,
                icon: faChartTreeMap,
            },
            {
                displayLabel: "Dispatcher",
                data: dispatcher,
                pendoClass: "pendo_dispatcher_filter_bar_badge",
                dataLabel: FilterParamNames.DISPATCHER,
                andFilter: true,
                icon: faPhoneOffice,
                hide: !hasPermission("insights_dashboard_dispatcher"),
            },
        ],
        insights: [
            {
                displayLabel: "Beats",
                data: beats,
                pendoClass: "pendo_beats_filter_bar_badge",
                dataLabel: FilterParamNames.BEATS,
                andFilter: false,
                icon: faChartTreeMap,
            },
            {
                displayLabel: "Labels",
                data: tags,
                pendoClass: "pendo_labels_filter_bar_badge",
                dataLabel: FilterParamNames.LABELS,
                andFilter: !!hasAllLabels,
                icon: faTags,
            },
            {
                displayLabel: `${incidentText} Types`,
                data: incidentTypes?.replace(",", ", "),
                pendoClass: "pendo_incident_types_filter_bar_badge",
                dataLabel: FilterParamNames.INCIDENT_TYPES,
                andFilter: false,
                icon: faSiren,
            },
            {
                displayLabel: `${callText} Types`,
                data: callTypes?.replace(",", ", "),
                pendoClass: "pendo_call_types_filter_bar_badge",
                dataLabel: FilterParamNames.CALL_TYPES,
                andFilter: false,
                icon: faPoliceBox,
            },
            {
                displayLabel: `${callText} Sources`,
                data: callSources?.replace(",", ", "),
                pendoClass: "pendo_call_sources_filter_bar_badge",
                dataLabel: FilterParamNames.CALL_SOURCES,
                andFilter: false,
                icon: faPhoneArrowUpRight,
            },
            {
                displayLabel: `${callText} Priorities`,
                data: callPriorities?.replace(",", ", "),
                pendoClass: "pendo_call_priorities_filter_bar_badge",
                dataLabel: FilterParamNames.CALL_PRIORITIES,
                andFilter: false,
                icon: faListOl,
            },
            {
                displayLabel: "Offenses",
                pendoClass: "pendo_offenses_filter_bar_badge",
                data: offenses?.replace(",", ", "),
                dataLabel: FilterParamNames.OFFENSES,
                andFilter: false,
                icon: faBook,
            },
        ],
        officer: [
            {
                displayLabel: "Beats",
                data: beats,
                pendoClass: "pendo_beats_filter_bar_badge",
                dataLabel: FilterParamNames.BEATS,
                andFilter: false,
                icon: faChartTreeMap,
            },
            {
                displayLabel: "Officer",
                data: officer,
                pendoClass: "pendo_officer_filter_bar_badge",
                dataLabel: FilterParamNames.OFFICER,
                andFilter: true,
                icon: faUserPolice,
                hide: !hasPermission("insights_dashboard_officer"),
            },
            {
                displayLabel: "Offenses",
                pendoClass: "pendo_offenses_filter_bar_badge",
                data: offenses?.replace(",", ", "),
                dataLabel: FilterParamNames.OFFENSES,
                andFilter: false,
                icon: faBook,
            },
        ],
    };

    // in this context, secondary refers to a filter placed on the right side
    // of the filter bar. So NOT date range, agency, or event type
    const firstSecondaryEventFilter = filterDataStruct[Entities.EVENT].find(
        (filter) => !!filter.data && !hideFilterList.includes(filter.dataLabel)
    )?.dataLabel;
    const firstSecondaryPersonFilter = filterDataStruct[Entities.PERSON].find(
        (filter) => !!filter.data && !hideFilterList.includes(filter.dataLabel)
    )?.dataLabel;
    const firstSecondaryOfficerFilter = filterDataStruct["officer"].find(
        (filter) => !!filter.data && !hideFilterList.includes(filter.dataLabel)
    )?.dataLabel;
    const firstSecondaryInsightFilter = filterDataStruct["insights"].find(
        (filter) => !!filter.data && !hideFilterList.includes(filter.dataLabel)
    )?.dataLabel;
    const firstSecondaryDispatcherFilter = filterDataStruct["dispatcher"].find(
        (filter) => !!filter.data && !hideFilterList.includes(filter.dataLabel)
    )?.dataLabel;

    const { isMapPage } = useIsMapPage();
    const defaultDateRange = isMapPage ? "30days" : dateRangeDefaultValue;

    const fallbackDateRange = getRangeLabel(defaultDateRange, null, null);

    const agencyNames = !!agencies
        ? userSubAgencies.filter((agency: any) => agencies.includes(agency.fm_uuid)).map((agency: any) => agency?.name)
        : [];
    const singleAgencyName = !!agencyUUID
        ? userSubAgencies.find((agency: any) => agencyUUID === agency.fm_uuid)?.name || "All Agencies"
        : "";

    return (
        <>
            <div
                data-testid="filter-bar"
                className={`d-inline-flex align-middle p-1 flex-grow-1 flex-wrap gap-2 ${modalView ? "" : "border rounded"}`}
            >
                {showSingleAgencyFilter ? (
                    <FilterBadge
                        showModal={showModal}
                        displayLabel="Agency"
                        data={singleAgencyName}
                        pendoClass="pendo_agency_filter_bar_badge"
                        dataLabel="agencyUUID"
                        andFilter={false}
                        canDelete={false}
                        hidePrefixText
                        icon={faSitemap}
                    />
                ) : (
                    !hideFilterList.includes("agencies") &&
                    showMultiAgencyFilter && (
                        <FilterBadge
                            showModal={showModal}
                            displayLabel="Agencies"
                            data={agencyNames.length > 0 ? agencyNames.join(",") : "All agencies"}
                            pendoClass="pendo_agencies_filter_bar_badge"
                            dataLabel="agencies"
                            andFilter={false}
                            canDelete={agencyNames.length > 0}
                            hidePrefixText={true}
                            icon={faSitemap}
                        />
                    )
                )}
                {tab === Entities.EVENT && (
                    <>
                        <FilterBadge
                            showModal={showModal}
                            displayLabel="Date Range"
                            data={!!dateRange ? getRangeLabel(dateRange, startDate, endDate) : fallbackDateRange}
                            pendoClass="pendo_date_range_filter_bar_badge"
                            dataLabel="dateRange"
                            andFilter={true}
                            canDelete={canDateRangeBeAnyTime && !!dateRange && dateRange !== dateRangeDefaultValue}
                            hidePrefixText={true}
                            icon={faCalendars}
                        />
                        <FilterBadge
                            showModal={showModal}
                            displayLabel="Event Type"
                            data={
                                !!indexType
                                    ? indexType.replace(/incidents/g, incidentTextPlural).replace(/calls/g, callTextPlural)
                                    : `${callTextPlural} & ${incidentTextPlural}`
                            }
                            pendoClass="pendo_calls_and_incidents_filter_bar_badge"
                            dataLabel="indexType"
                            andFilter={false}
                            canDelete={false}
                            hidePrefixText={true}
                            icon={faCalendarCircleExclamation}
                        />
                    </>
                )}
                {["insights", "dispatcher", "officer"].includes(tab) && (
                    <FilterBadge
                        showModal={showModal}
                        displayLabel="Date Range"
                        data={!!dateRange ? getRangeLabel(dateRange, startDate, endDate) : fallbackDateRange}
                        pendoClass="pendo_date_range_filter_bar_badge"
                        dataLabel="dateRange"
                        andFilter={true}
                        canDelete={canDateRangeBeAnyTime && !!dateRange && dateRange !== dateRangeDefaultValue}
                        hidePrefixText={true}
                        icon={faCalendars}
                    />
                )}

                {/* Only render divider bar IF at least one secondary filter is applied on current tab */}
                {((tab === LayerTypes.event && !!firstSecondaryEventFilter) ||
                    (tab === LayerTypes.person && showMultiAgencyFilter && !!firstSecondaryPersonFilter) ||
                    (tab === "officer" && !!firstSecondaryOfficerFilter) ||
                    (tab === "dispatcher" && !!firstSecondaryDispatcherFilter) ||
                    (tab === "insight" && !!firstSecondaryInsightFilter)) && <div className="vr"></div>}

                {/* Event Tab Filters */}
                {tab === LayerTypes.event &&
                    filterDataStruct[LayerTypes.event]
                        .filter((filter) => !hideFilterList.includes(filter.dataLabel))
                        .map((filter) =>
                            !filter.hide && !hideFilterList.includes(filter.dataLabel) ? (
                                <FilterBadge
                                    showModal={showModal}
                                    key={filter.displayLabel}
                                    {...filter}
                                    hidePrefixText={firstSecondaryEventFilter === filter.dataLabel}
                                    icon={filter.icon}
                                    canDelete={!hasPermission("use_nextfe_search")}
                                />
                            ) : (
                                <></>
                            )
                        )}
                {/* Person Tab Filters */}
                {tab === LayerTypes.person &&
                    filterDataStruct[LayerTypes.person]
                        .filter((filter) => !hideFilterList.includes(filter.dataLabel))
                        .map((filter) => (
                            <FilterBadge
                                showModal={showModal}
                                key={filter.displayLabel}
                                {...filter}
                                hidePrefixText={firstSecondaryPersonFilter === filter.dataLabel}
                            />
                        ))}
                {/* Officer Dashboard Filters */}
                {tab === "officer" &&
                    filterDataStruct["officer"]
                        .filter((filter) => !hideFilterList.includes(filter.dataLabel))
                        .map((filter) => (
                            <FilterBadge
                                showModal={showModal}
                                key={filter.displayLabel}
                                {...filter}
                                hidePrefixText={firstSecondaryOfficerFilter === filter.dataLabel}
                            />
                        ))}
                {/* Insights Dashboard Filters */}
                {tab === "insights" &&
                    filterDataStruct["insights"]
                        .filter((filter) => !hideFilterList.includes(filter.dataLabel))
                        .map((filter) => (
                            <FilterBadge
                                showModal={showModal}
                                key={filter.displayLabel}
                                {...filter}
                                hidePrefixText={firstSecondaryInsightFilter === filter.dataLabel}
                            />
                        ))}
                {/* Dispatcher Dashboard Filters */}
                {tab === "dispatcher" &&
                    filterDataStruct["dispatcher"]
                        .filter((filter) => !hideFilterList.includes(filter.dataLabel))
                        .map((filter) => (
                            <FilterBadge
                                showModal={showModal}
                                key={filter.displayLabel}
                                {...filter}
                                hidePrefixText={firstSecondaryDispatcherFilter === filter.dataLabel}
                            />
                        ))}
            </div>
        </>
    );
};

export default FilterBar;
