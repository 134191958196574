import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Outlet, useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserCheck, faLock, faShield, faShieldCheck } from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router-dom";
import Disclaimer from "../support/Disclaimer";
import Logo from "../support/logo/Logo";

type ContextType = { setStep: Function };

/**
 * Wrapper for the Account Setup pages that has the buttons/icons that indicate the setup status.  Maintains a step value that indicates
 * where the user is in the process.  The setStep function that each page sets is passed from the wrapper to the child components in the
 * outlet using the useOutletContext hook that react-router provides.
 * @constructor
 */
export const AccountSetup = () => {
    const [step, setStep] = React.useState<number>(1);
    const navigate = useNavigate();

    return (
        <Container>
            <Row className="justify-content-center">
                <Logo className="w-50" />
            </Row>
            <div className="d-flex justify-content-center mb-5">
                <h1 className="text-center">Configure Your Account</h1>
            </div>
            <Row className="justify-content-center">
                <Col md={8} lg={6} className="pb-3 border-bottom border-light">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column justify-content-between align-items-center">
                            <Button
                                type="button"
                                variant={step > 1 ? "primary" : "outline-primary"}
                                onClick={() => {
                                    if (step > 1) {
                                        setStep(1);
                                        navigate("/account-setup/step1-password");
                                    }
                                }}
                                className="rounded-circle btn-lg d-flex justify-content-center align-items-center shadow-none fm-circle-button"
                            >
                                <FontAwesomeIcon icon={step > 1 ? faUserCheck : faUser} size="lg" />
                            </Button>
                            <span className={`text-primary mt-2 ${step > 1 ? "fw-bold" : ""}`}>Create Password</span>
                        </div>
                        <div className="d-flex flex-column justify-content-between align-items-center">
                            <Button
                                type="button"
                                variant={step > 2 ? "primary" : "outline-primary"}
                                disabled={step < 3}
                                onClick={() => {
                                    if (step > 2) {
                                        navigate("/account-setup/step2-select-mfa");
                                    }
                                }}
                                className="rounded-circle btn-lg d-flex justify-content-center align-items-center shadow-none fm-circle-button"
                            >
                                <FontAwesomeIcon icon={step > 2 ? faShieldCheck : faShield} size="xl" />
                            </Button>
                            <span className={`text-primary mt-2 ${step > 2 ? "fw-bold" : ""}`}>Select Multi-Factor</span>
                        </div>
                        <div className="d-flex flex-column justify-content-between align-items-center">
                            <Button
                                type="button"
                                variant="outline-primary"
                                disabled={step < 3}
                                className="rounded-circle btn-lg d-flex justify-content-center align-items-center shadow-none fm-circle-button"
                            >
                                <FontAwesomeIcon icon={faLock} size="xl" />
                            </Button>
                            <span className="text-primary mt-2">Enter Code</span>
                        </div>
                    </div>
                </Col>
            </Row>

            <Outlet context={{ setStep }} />

            <Row className="justify-content-center">
                <Col lg={8} className="p-4 mt-5">
                    <Disclaimer className="text-muted" />
                </Col>
            </Row>
        </Container>
    );
};

AccountSetup.displayName = "AccountSetup";
export default AccountSetup;

export function useStep() {
    return useOutletContext<ContextType>();
}
