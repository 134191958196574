import React from "react";
import { Alert, Container, Modal } from "react-bootstrap";
import ModalHeader from "../../../components/ModalHeader";
import BeautifiedKeyValuePairDisplay from "../../../utils/beautifiedkeyvaluepairdisplay/BeautifiedKeyValuePairDisplay";
import useAgencySettings from "../../../hooks/useAgencySettings";
import { getNarrativeStyleNormalizationClass } from "../../../utils/helpers";
import { useAppSelector } from "../../../app/hooks";

const NarrativesModal = ({
    narratives,
    textSummarizations,
    show,
    close,
}: {
    narratives: any;
    textSummarizations: any;
    show: boolean;
    close: any;
}) => {
    const { incidentText } = useAgencySettings();
    const user = useAppSelector(({ user }) => user.userObject);
    return (
        <Modal show={show} onHide={close} size="xl" scrollable={true}>
            <ModalHeader title="Narratives" />
            <Modal.Body className={`${getNarrativeStyleNormalizationClass(user?.agency_shortname)} px-0`}>
                <Container fluid>
                    {narratives && narratives.length ? (
                        narratives.map((pair: any, index: number) => (
                            <React.Fragment key={index}>
                                <BeautifiedKeyValuePairDisplay value={pair} showBreak={index < narratives.length} />
                            </React.Fragment>
                        ))
                    ) : (
                        <Alert variant="warning">This {incidentText} does not currently have any narratives associated with it.</Alert>
                    )}
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default NarrativesModal;
