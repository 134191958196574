import jwt_decode from "jwt-decode";
import { isAfter } from "date-fns";
import { Token } from "../types/old_v1/types";
import { Cookies } from "react-cookie";

const isDevelopment = window?.__RUNTIME_CONFIG__?.REACT_APP_IMAGE_TAG === "local";

export const tokenIsValid = (tokenIn: string | null): boolean => {
    if (!tokenIn) {
        return false;
    }
    const token: Token | null = decodeToken(tokenIn);
    if (!token) {
        return false;
    }

    const now = new Date();
    const refreshExpire = new Date(token.exp * 1000);
    return !isAfter(now, refreshExpire);
};

export const decodeToken = (token: string): Token | null => {
    try {
        return jwt_decode(token);
    } catch (e) {
        return null;
    }
};

export const getAccessToken = (): string | null => {
    const cookies = new Cookies(null, { path: "/" });
    return cookies.get("accessToken");
};

export const getRefreshToken = (): string | null => {
    const cookies = new Cookies(null, { path: "/" });
    return cookies.get("refreshToken");
};

export const setAccessToken = (accessToken: string) => {
    const cookies = new Cookies(null, { path: "/" });
    const newAccessTokenDecoded = decodeToken(accessToken);
    if (newAccessTokenDecoded) {
        cookies.set("accessToken", accessToken, {
            path: "/",
            maxAge: newAccessTokenDecoded.exp - new Date().getTime() / 1000,
            sameSite: "lax",
            secure: !isDevelopment,
        });
    }
};

export const setRefreshToken = (refreshToken: string) => {
    const cookies = new Cookies(null, { path: "/" });
    const newRefreshTokenDecoded = decodeToken(refreshToken);
    if (newRefreshTokenDecoded) {
        cookies.set("refreshToken", refreshToken, {
            path: "/",
            maxAge: newRefreshTokenDecoded.exp - new Date().getTime() / 1000,
            sameSite: "lax",
            secure: !isDevelopment,
        });
    }
};
