export const FilterParamNames = {
    AGENCIES: "agencies",
    DATE_RANGE: "dateRange",
    TIME_RANGE: "timeRange",
    EVENT_TYPES: "eventTypes",
    BEATS: "beats",
    LABELS: "tags",
    INCIDENT_TYPES: "incidentTypes",
    CALL_TYPES: "callTypes",
    CALL_PRIORITIES: "callPriorities",
    OFFICER: "officer",
    DISPATCHER: "dispatcher",
    RACE: "race",
    GENDER: "gender",
    INDEX_TYPE: "indexType",
    AGE: "age",
    ENTITY: "entity",
    OFFENSES: "offenses",
    CALL_SOURCES: "callSources",
    VIEW: "view",
    PERSON_ID: "personId",
} as const;

export const Entities = {
    PERSON: "person",
    EVENT: "event",
    ADDRESS: "address",
} as const;

export const defaultMobileSizePx = 768;
