import React from "react";
import { useSearchParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useGetActivePeopleQuery } from "../../api/api";
import { getQueryParams } from "../../utils/url";
import InvolvedPeople from "../radar/InvolvedPeople";
import InsightsSpinner from "./insightsspinner/InsightsSpinner";
import { useFilters } from "../../hooks/useFilters";

const ActivePeople = () => {
    const [searchParams] = useSearchParams();
    const queryParams = getQueryParams(searchParams);
    const { filterPayload, beatsIsFetching } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });
    const { data: activePeople, isFetching } = useGetActivePeopleQuery(
        { ...queryParams, filters: filterPayload },
        { skip: beatsIsFetching }
    );

    return (
        <Card>
            <Card.Header>
                <Card.Title className="mb-0" as="h3">
                    Most Active People
                </Card.Title>
            </Card.Header>
            <Card.Body className="d-flex flex-column overflow-auto" style={{ height: "30em" }}>
                {isFetching ? (
                    <InsightsSpinner />
                ) : activePeople && activePeople.length ? (
                    <InvolvedPeople people={activePeople} ignorePersonSelect dashboardTable />
                ) : (
                    <h1 className="text-center">No Involved People</h1>
                )}
            </Card.Body>
        </Card>
    );
};

export default ActivePeople;
