import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDarkMode } from "../../../hooks/useDarkMode";
import { handleHighChartDarkMode } from "../../../utils/helpers";

interface LineChartProcessedDataType {
    labels: [string];
    counts: [number];
    avg: [number];
}

interface LinechartProps {
    title?: string;
    processedData: LineChartProcessedDataType;
    xAxis: string;
    yAxis: string;
    mainSeriesName: string;
    onClickFn?: Function;
}

const BG_LIGHT = "#f8f9fa";
const BG_SECONDARY = "#6c757d";
/**
 * Component for a wrapper around Highcharts line chart
 */
const LineChart = ({ title, processedData, xAxis, yAxis, mainSeriesName, onClickFn }: LinechartProps) => {
    const { isDarkMode } = useDarkMode();
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const options = {
        chart: {
            type: "line",
        },
        title: {
            text: title,
            style: {
                fontWeight: "bold",
            },
        },
        xAxis: {
            categories: processedData.labels,
            title: {
                text: xAxis,
            },
            tickWidth: 1,
            tickmarkPlacement: "on",
        },
        yAxis: {
            title: {
                text: yAxis,
            },
            color: "#c0c0c0",
            allowDecimals: false,
        },
        tooltip: {
            valueDecimals: 0,
        },
        series: [
            {
                type: "line",
                cursor: onClickFn ? "pointer" : "default",
                data: processedData.counts,
                showInLegend: false,
                name: mainSeriesName,
                marker: {
                    enabled: false,
                },
                point: {
                    events: {
                        click: (e: any) => {
                            if (onClickFn) {
                                onClickFn(e);
                            }
                        },
                    },
                },
            },
            {
                type: "line",
                data: processedData.avg,
                showInLegend: false,
                name: `Avg ${mainSeriesName}`,
                dashStyle: "ShortDash",
                marker: {
                    enabled: false,
                },
                color: isDarkMode ? BG_LIGHT : BG_SECONDARY,
            },
        ],
    };
    handleHighChartDarkMode(options, isDarkMode);
    return (
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} containerProps={{ style: { height: "100%" } }} />
    );
};

export default React.memo(LineChart);
