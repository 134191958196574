import { Card } from "react-bootstrap";
import InsightsSpinner from "./insightsspinner/InsightsSpinner";
import TagsHistogram from "../radar/TagsHistogram";

type OwnProps = {
    tagBuckets: any[];
    isFetching: boolean;
    isClickable: boolean;
    indexType?: string;
};

const TopLabels = ({ tagBuckets, isFetching, isClickable, indexType }: OwnProps) => {
    const processedTagBuckets =
        tagBuckets &&
        tagBuckets.reduce((coll: any, curr: any) => {
            coll[curr.key] = curr.doc_count;
            return coll;
        }, {});

    const labelKeys = processedTagBuckets && Object.keys(processedTagBuckets);

    return (
        <Card>
            <Card.Header>
                <Card.Title className="m-0" as="h3">
                    Top Labels
                </Card.Title>
            </Card.Header>
            <Card.Body className="overflow-auto" style={{ height: "28em" }}>
                {isFetching ? (
                    <InsightsSpinner />
                ) : labelKeys && labelKeys.length ? (
                    <TagsHistogram tags={processedTagBuckets} isClickable={isClickable} indexType={indexType} />
                ) : (
                    <h1 className="text-center">No Events</h1>
                )}
            </Card.Body>
        </Card>
    );
};

export default TopLabels;
