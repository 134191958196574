import React from "react";
import { Button, Col, Container, Modal, Row, Stack } from "react-bootstrap";
import { faShareFromSquare, faSpinner, faTimes } from "@fortawesome/pro-regular-svg-icons";
import ModalHeader from "../ModalHeader";
import { useGetShareUsersListQuery, useShareEntityMutation } from "../../api/api";
import { sort } from "../../utils/sort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FmAlert from "../../pages/support/FmAlert";
import ShareMessage from "./ShareMessage";
import UserListGroup from "./UserListGroup";
import { useParams, useSearchParams } from "react-router-dom";
import "./shareModal.css";
import useAgencySettings from "../../hooks/useAgencySettings";

export const getInitialShareTitle = ({
    callId,
    incidentId,
    personId,
    addressString,
    query,
    incidentText = "Incident",
    callText = "Call",
}: {
    callId?: string;
    incidentId?: string;
    personId?: string;
    addressString?: string | null;
    query?: string | null;
    incidentText?: string;
    callText?: string;
}) => {
    if (!!callId) {
        const incidentString = !!incidentId ? ` | ${incidentText} ${incidentId}` : "";
        return `${callText} ${callId}${incidentString}`;
    } else if (!!incidentId) {
        return `${incidentText} ${incidentId}`;
    } else if (!!personId) {
        return `Person ${personId}`;
    } else if (!!addressString) {
        return addressString;
    } else {
        return `ForceMetrics search for ${query}`;
    }
};

type ConnectedOwnProps = {
    close: Function;
    show: boolean;
};
const ConnectedShareModal = ({ show, close }: ConnectedOwnProps) => {
    const { callId, incidentId, personId } = useParams();
    const [searchParams] = useSearchParams();
    const addressString = searchParams.get("address");
    const query = searchParams.get("query");
    const { incidentText, callText } = useAgencySettings();
    const initialTitle = getInitialShareTitle({ callId, incidentId, personId, addressString, query, incidentText, callText });
    let { data } = useGetShareUsersListQuery({});
    const [shareFn, shareFnResult] = useShareEntityMutation();
    const users = data && data.users ? data.users : [];

    return (
        <ShareModal close={close} shareFn={shareFn} shareFnResult={shareFnResult} show={show} users={users} initialTitle={initialTitle} />
    );
};

type OwnProps = {
    close: Function;
    shareFn: Function;
    shareFnResult: any;
    show: boolean;
    users: any[];
    initialTitle: string;
};
const ShareModal = ({ close, shareFn, shareFnResult, show, users, initialTitle }: OwnProps) => {
    const [usersToShare, setUsersToShare] = React.useState<string[]>([]);
    const [message, setMessage] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");

    const [sending, setSending] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertContent, setAlertContent] = React.useState({
        variant: "",
        key: "",
    });
    const [userFilterString, setUserFilterString] = React.useState("");

    const filteredUsers = users
        .map((user: any) => ({ email: user?.email, full_name: user?.full_name }))
        .sort(sort("full_name", true))
        .filter((user: any) => {
            if (!userFilterString) {
                return true;
            }
            const lowerCaseFilter = userFilterString.toLowerCase();
            return user.email.toLowerCase().includes(lowerCaseFilter) || user.full_name.toLowerCase().includes(lowerCaseFilter);
        });

    const toggleUser = (email: string) => {
        let updatedUsersToShare = [...usersToShare];
        if (usersToShare.includes(email)) {
            updatedUsersToShare = updatedUsersToShare.filter((e: string) => e !== email);
        } else {
            updatedUsersToShare.push(email);
        }
        setUsersToShare(updatedUsersToShare);
    };

    const onHide = () => {
        setUsersToShare([]);
        setMessage("");
        setTitle(initialTitle);
        setUserFilterString("");
        setSending(false);
        setAlertContent({
            variant: "",
            key: "",
        });
        close();
    };

    const onShare = () => {
        if (!title) {
            setAlertContent({
                variant: "warning",
                key: "share_title_required",
            });
            setShowAlert(true);
        } else {
            setSending(true);
            const params: { [key: string]: any } = {};
            params.url = window.location.href;
            params.emails = usersToShare;
            params.msg = message;
            params.title = title;
            shareFn(params);
        }
    };

    // update title when initial title changes
    React.useEffect(() => {
        setTitle(initialTitle);
    }, [initialTitle]);

    React.useEffect(() => {
        if (shareFnResult?.data?.success === false) {
            setAlertContent({
                variant: "warning",
                key: "share_entity_failed",
            });
            setShowAlert(true);
            setSending(false);
        } else if (shareFnResult?.data?.success) {
            onHide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shareFnResult]);

    return (
        <Modal show={show} onHide={onHide} scrollable size="xl">
            <ModalHeader icon={faShareFromSquare} title="Share" />
            <Modal.Body>
                <Container fluid className="h-100">
                    {/* desktop view */}
                    <Row className="h-100 d-none d-lg-flex">
                        <Col lg={5} xl={4} className="h-100 d-flex flex-column">
                            <UserListGroup
                                users={filteredUsers}
                                usersToShare={usersToShare}
                                setUserFilterString={setUserFilterString}
                                toggleUser={toggleUser}
                            />
                        </Col>
                        <Col lg={7} xl={8} className="d-flex flex-column">
                            <ShareMessage
                                message={message}
                                setMessage={setMessage}
                                title={title}
                                setTitle={setTitle}
                                usersToShare={usersToShare}
                                toggleUser={toggleUser}
                                toSectionMaxHeight="150px"
                            />
                        </Col>
                    </Row>
                    {/* responsive view */}
                    <Stack direction="vertical" gap={2} className="h-100 d-lg-none">
                        <div className="h-50 d-flex flex-column">
                            <UserListGroup
                                users={filteredUsers}
                                usersToShare={usersToShare}
                                setUserFilterString={setUserFilterString}
                                toggleUser={toggleUser}
                            />
                        </div>
                        <div className="h-50 d-flex flex-column">
                            <ShareMessage
                                message={message}
                                setMessage={setMessage}
                                title={title}
                                setTitle={setTitle}
                                usersToShare={usersToShare}
                                toggleUser={toggleUser}
                                toSectionMaxHeight="100px"
                            />
                        </div>
                    </Stack>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                {showAlert && <FmAlert variant={alertContent.variant} alertKey={alertContent.key} onClose={() => setShowAlert(false)} />}
                <Button variant="outline-danger" onClick={() => onHide()} disabled={sending}>
                    <FontAwesomeIcon icon={faTimes} className="me-2" />
                    Cancel
                </Button>
                <Button variant="outline-primary" onClick={onShare} disabled={!usersToShare.length || sending}>
                    <FontAwesomeIcon icon={faShareFromSquare} className="me-2" />
                    Share
                    {sending && <FontAwesomeIcon icon={faSpinner} className="ms-2" />}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConnectedShareModal;
