import React from "react";
import { useGetUniqueGendersQuery } from "../../api/api";
import { processFilterAgg } from "../../utils/helpers";
import MultiSelectFilter from "./MultiSelectFilter";

const GenderFilter = () => {
    // gets the options form index
    const { data: optionData } = useGetUniqueGendersQuery({});
    const options = processFilterAgg(optionData);

    const selectOptions = options.map((gender: { id: string; option: string }) => ({
        value: gender?.id,
        name: gender?.option,
    }));

    return (
        <MultiSelectFilter
            pendoClass="pendo_gender_filter_select"
            options={selectOptions || []}
            urlParamName={"gender"}
            inputPlaceholder={"Select Gender"}
        />
    );
};

GenderFilter.displayName = "OptionFilterConnected";
export default GenderFilter;
