import React from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

type OwnProps = {
    alertKey: string;
    variant?: string;
    onClose?: any;
};

const defaultErrorMessage = "An error has occurred. Please try again or if the problem persists, contact your system administrator.";

/**
 * Returns react-bootstrap Alert component with proper message.  If onClose is included, the Alert will be dismissible.
 * @param key - The key for the alert message
 * @param onClose - Optional function to run to make the panel dismissible
 * @param variant - the alert component style type: danger, success, etc.
 * @constructor
 */
const FmAlert = ({ alertKey, onClose, variant = "danger" }: OwnProps) => {
    React.useEffect(() => {
        if (onClose) {
            setTimeout(() => {
                onClose();
            }, 3999);
        }
    }, [onClose]);

    // adds dismissible and onClose to the Alert component if there is an onClose function present
    const AlertWrapper = ({ children }: { children: any }) => {
        if (onClose) {
            return (
                <Alert className="shadow w-100 fw-bold" variant={variant} onClose={onClose} dismissible={true}>
                    {children}
                </Alert>
            );
        } else {
            return (
                <Alert className="shadow w-100 fw-bold" variant={variant}>
                    {children}
                </Alert>
            );
        }
    };

    switch (alertKey) {
        case "change_password_token_invalid":
            return (
                <AlertWrapper>
                    <Alert.Heading>
                        <h2>Invalid Password Change Session</h2>
                    </Alert.Heading>
                    <span>
                        The password change email may have expired. Try clicking on the link in the email again and if that doesn't work,
                        contact you system administrator.
                    </span>
                </AlertWrapper>
            );
        case "user_already_setup":
            return (
                <AlertWrapper>
                    <Alert.Heading>
                        <h2>This account has already been setup</h2>
                    </Alert.Heading>
                    <Link to="/auth/sign-in">Click here</Link> to log in or to change your password.
                </AlertWrapper>
            );
        case "token_invalid":
            return (
                <AlertWrapper>
                    <Alert.Heading>
                        <h2>Invalid Account Setup Session</h2>
                    </Alert.Heading>
                    <span>
                        The email invite may have expired. Try clicking on the link in the email invite again to restart the process or
                        contact your system administrator for a new invitation email.
                    </span>
                </AlertWrapper>
            );
        case "token_invalid_reconfigure_mfa":
            return (
                <AlertWrapper>
                    <Alert.Heading>
                        <h2>Invalid Multi-Factor Setup Session</h2>
                    </Alert.Heading>
                    <span>
                        The multi-factor validation you have selected could not be completed. Try logging in again and make sure to complete
                        setup process timely or your session could time out.&nbsp;
                        <Link to="/auth/sign-in">Click here</Link> to log in again and start over.
                    </span>
                </AlertWrapper>
            );
        case "text_failed":
            return (
                <AlertWrapper>
                    <Alert.Heading>
                        <h2>Text Message Failed to Send</h2>
                    </Alert.Heading>
                    <span>
                        There was a problem sending the text message to the number you provided. Check the number and try again. You can{" "}
                        <Link to="/auth/sign-in">Click here</Link> to log in again and start over.
                    </span>
                </AlertWrapper>
            );
        case "password_invalid":
            return (
                <AlertWrapper>
                    <Alert.Heading>
                        <h2>Invalid Password Format</h2>
                    </Alert.Heading>
                    Passwords must:
                    <ul>
                        <li>Not be too similar to name</li>
                        <li>Minimum 8 characters</li>
                        <li>Not too common</li>
                        <li>Not entirely numbers</li>
                    </ul>
                </AlertWrapper>
            );
        case "mfa_code_invalid":
            return (
                <AlertWrapper>
                    <span>
                        The code you entered isn't correct. Please try again or if the problem persists, contact your system administrator.
                    </span>
                </AlertWrapper>
            );
        case "text_message_problem":
            return (
                <AlertWrapper>
                    <span>An error occurred attempting to deliver the verification code to your mobile device.</span>
                </AlertWrapper>
            );
        case "dupe_email_request":
            return (
                <AlertWrapper>
                    <span>
                        An email has already been sent to this address. Check your spam folder or contact your administrator for help.
                    </span>
                </AlertWrapper>
            );
        case "invalid_credentials":
            return (
                <AlertWrapper>
                    <span>
                        Invalid Username and/or Password. Note: your account will be locked after 5 invalid attempts. If the problem
                        persists, contact your system administrator.
                    </span>
                </AlertWrapper>
            );
        case "new_code_sent":
            return (
                <AlertWrapper>
                    <span>A new code has been sent</span>
                </AlertWrapper>
            );
        case "sms_failed":
            return (
                <AlertWrapper>
                    <span>Could not send the code</span>
                </AlertWrapper>
            );
        case "email_sent":
            return (
                <AlertWrapper>
                    <Alert.Heading>
                        <h2>Email Sent</h2>
                    </Alert.Heading>
                    <span>An email with a link to change your password has been sent to the email you have provided.</span>
                </AlertWrapper>
            );
        case "password_changed":
            return (
                <AlertWrapper>
                    <Alert.Heading>
                        <h2>Password Has Been Changed</h2>
                    </Alert.Heading>
                    <span>
                        Your password has been successfully changed.&nbsp;
                        <Link to="/auth/sign-in">Click here</Link> to log in with your new password.
                    </span>
                </AlertWrapper>
            );
        case "invalid_login_session":
            return (
                <AlertWrapper>
                    <Alert.Heading>
                        <h2>Login Session is Invalid</h2>
                    </Alert.Heading>
                    <span>
                        There is a problem with your login session.&nbsp;
                        <Link to="/auth/sign-in">Click here</Link> to log in and try again.
                    </span>
                </AlertWrapper>
            );
        case "follow_address_success":
            return (
                <AlertWrapper>
                    <span>Successfully followed address!</span>
                </AlertWrapper>
            );
        case "follow_person_success":
            return (
                <AlertWrapper>
                    <span>Successfully followed person!</span>
                </AlertWrapper>
            );
        case "follow_search_success":
            return (
                <AlertWrapper>
                    <span>Successfully followed search!</span>
                </AlertWrapper>
            );
        case "unfollow_search_success":
            return (
                <AlertWrapper>
                    <span>Successfully unfollowed search!</span>
                </AlertWrapper>
            );
        case "unfollow_address_success":
            return (
                <AlertWrapper>
                    <span>Successfully unfollowed address!</span>
                </AlertWrapper>
            );
        case "unfollow_person_success":
            return (
                <AlertWrapper>
                    <span>Successfully unfollowed person!</span>
                </AlertWrapper>
            );
        case "follow_failed":
            return (
                <AlertWrapper>
                    <span>{defaultErrorMessage}</span>
                </AlertWrapper>
            );
        case "share_entity_failed":
            return (
                <AlertWrapper>
                    <span>{defaultErrorMessage}</span>
                </AlertWrapper>
            );
        case "share_title_required":
            return (
                <AlertWrapper>
                    <span>Share Subject is required</span>
                </AlertWrapper>
            );
        case "update_user_details_failed":
            return (
                <AlertWrapper>
                    <span>{defaultErrorMessage}</span>
                </AlertWrapper>
            );
        default:
            return (
                <AlertWrapper>
                    <span>Server Error</span>
                </AlertWrapper>
            );
    }
};

FmAlert.displayName = "FmAlert";
export default FmAlert;
