import { Outlet, useParams } from "react-router-dom";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import Unauthorized from "../support/Unauthorized";
import { IAgencyMembership, SubAgencyEntity } from "../../types/types";

/**
 * Process the agency UUID on the route and determine
 * whether it is valid. The UUID is valid in two cases:
 *   1. The parent agency is not multi-agency and the
 *      uuid from the url matches the parent agency's
 *      uuid
 *   2. The parent agency is multi-agency and the uuid
 *      from the url matches one of the sub agencies'
 *      uuids
 * @returns either an Outlet to render the actual content
 *          or a Navigate to the 403 unauthorized route
 */
const ValidateAgencyUUID = () => {
    const { agencyUUID } = useParams();
    const { parentAgencyUUID, isMultiAgency, userSubAgencies, userSharedAgencies } = useMultiAgency();

    // list of agency uuids for the sub agencies the user is active in for the current agency
    const userSubAgenciesUUIDs = userSubAgencies?.length
        ? Object.values(userSubAgencies).map((subAgency: SubAgencyEntity) => subAgency?.fm_uuid)
        : [];

    // userSharedAgencies is a list of agencies the user has access to if they have the use_multiagency permission on current agency
    const userSharedAgenciesUUIDs =
        userSharedAgencies && userSharedAgencies?.length > 0
            ? userSharedAgencies.map((agency: IAgencyMembership) => agency?.agency_info?.agency_uuid)
            : [];

    if (
        // agency is set to multi agency mode and the agencyUUID is in the list of sub agencies the user is active in for the current agency
        (agencyUUID && isMultiAgency && userSubAgenciesUUIDs.includes(agencyUUID)) ||
        // agency is not in multi agency mode and the agencyUUID matches the parent agency UUID(home agency)
        (agencyUUID && !isMultiAgency && agencyUUID === parentAgencyUUID) ||
        // agency UUID exists, user has the use_multiagency permission, and the agencyUUID is in the list of shared agencies
        (agencyUUID && userSharedAgenciesUUIDs.includes(agencyUUID))
    ) {
        return <Outlet />;
    }

    return <Unauthorized />;
};

export default ValidateAgencyUUID;
