import { Feature, FeatureCollection, featureCollection, Point, point } from "@turf/helpers";
import { getSourceOrField } from "../../../utils/elastic";
import { indexToLayerType } from "./naming";
import bbox from "@turf/bbox";
import { ADDRESS_ACCENT, CALL_ACCENT, INCIDENT_ACCENT, MAP_BORDER_ACCENT, PERSON_ACCENT } from "../../../app/colors";
import { LayerTypes, SourceConfig } from "../../../types/old_v1/types";

/**
 * Builds a geojson FeatureCollection from an array of ES hits. Filters out invalid GIS coordinates
 * @param hits An array of hits from ES
 * @param type layer type for map popup logic
 * @returns {FeatureCollection<Point, any>}
 */
export const createGeoJson = (hits: any, type?: string) => {
    const points: Feature<Point, any>[] = [];
    let features: FeatureCollection<Point, any> = {
        type: "FeatureCollection",
        features: [],
    };

    hits.forEach((hit: any) => {
        const data = getSourceOrField(hit);
        if (data) {
            if (data.gps_lon && data.gps_lat) {
                const indexType = hit._index || hit.index;
                const layerType = indexToLayerType(indexType || type);
                let lon = data.gps_lon;
                let lat = data.gps_lat;

                //People coordinates are being returned as string so convert them to type of number
                if (typeof lon === "string" && typeof lat === "string") {
                    lon = parseFloat(lon);
                    lat = parseFloat(lat);
                }

                const coordinatesAreValid = validateCoordinates(lon, lat);
                if (coordinatesAreValid) {
                    const newPoint = point([lon, lat], {
                        ...getSourceOrField(data),
                        call_number: Array.isArray(data.call_number) ? data.call_number[0] : data.call_number,
                        type: layerType,
                    });
                    points.push(newPoint);
                }
            }
        }
    });

    features = featureCollection(points);
    return features;
};

const getSWCoordinates = (coordinatesCollection: number[][]) => {
    const lowestLng = Math.min(...coordinatesCollection.map((coordinates) => coordinates[0]));
    const lowestLat = Math.min(...coordinatesCollection.map((coordinates) => coordinates[1]));

    return [lowestLng, lowestLat];
};

const getNECoordinates = (coordinatesCollection: number[][]) => {
    const highestLng = Math.max(...coordinatesCollection.map((coordinates) => coordinates[0]));
    const highestLat = Math.max(...coordinatesCollection.map((coordinates) => coordinates[1]));

    return [highestLng, highestLat];
};

// calculates bounding coordinates for map based on list of coordinates
export const calcBoundsFromCoordinates = (coordinatesCollection: number[][]) => {
    return [getSWCoordinates(coordinatesCollection), getNECoordinates(coordinatesCollection)];
};

export const calculateBbox = (geojson: FeatureCollection, buffer: number): number[][] => {
    const layerBoundingBox = bbox(geojson);
    return [
        [layerBoundingBox[0] - buffer, layerBoundingBox[1] - buffer],
        [layerBoundingBox[2] + buffer, layerBoundingBox[3] + buffer],
    ];
};

/**
 * Builds a geosjson FeatureCollection from the address aggregation ES query
 * @param addressResponse The ES query respsonse
 * @returns {FeatureCollection<Point, any>}
 */
export const createAddressGeoJson = (addressResponse: any) => {
    const points: Feature<Point, any>[] = [];
    let features: FeatureCollection<Point, any> = {
        type: "FeatureCollection",
        features: [],
    };

    if (addressResponse) {
        for (const address of addressResponse) {
            const data = address.source;
            if (data && data.gps_lon && data.gps_lat && validateCoordinates(data.gps_lon, data.gps_lat)) {
                const newPoint = point([data.gps_lon, data.gps_lat], {
                    ...getSourceOrField(data),
                    type: LayerTypes.address,
                    eventCount: address?.counts?.calls + address?.counts?.incidents,
                    address: data.key || data.full_address,
                    address_id: data.address_id || data.id || data.key,
                    person_address_street: data.key || data.full_address,
                });
                points.push(newPoint);
            }
        }
    }

    features = featureCollection(points);
    return features;
};

export const buildDataSources = (geojson: any) => {
    return [
        {
            data: geojson as any,
            id: "search-results",
            setBounds: true,
            cluster: false,
            layers: [
                {
                    id: "address-layer",
                    type: "circle",
                    source: "search-results",
                    filter: ["==", ["get", "type"], "address"],
                    paint: {
                        "circle-color": ADDRESS_ACCENT,
                        "circle-radius": 8,
                        "circle-stroke-color": MAP_BORDER_ACCENT,
                        "circle-stroke-width": 2,
                        "circle-stroke-opacity": 0.7,
                    },
                },
                {
                    id: "event-call-layer",
                    type: "circle",
                    source: "search-results",
                    filter: ["==", ["get", "type"], "call"],
                    paint: {
                        "circle-color": CALL_ACCENT,
                        "circle-radius": 8,
                        "circle-stroke-color": MAP_BORDER_ACCENT,
                        "circle-stroke-width": 2,
                        "circle-stroke-opacity": 0.7,
                    },
                },
                {
                    id: "event-incident-layer",
                    type: "circle",
                    source: "search-results",
                    filter: ["==", ["get", "type"], "incident"],
                    paint: {
                        "circle-color": INCIDENT_ACCENT,
                        "circle-radius": 8,
                        "circle-stroke-color": MAP_BORDER_ACCENT,
                        "circle-stroke-width": 2,
                        "circle-stroke-opacity": 0.7,
                    },
                },
                {
                    id: "person-layer",
                    type: "circle",
                    source: "search-results",
                    filter: ["==", ["get", "type"], "person"],
                    paint: {
                        "circle-color": PERSON_ACCENT,
                        "circle-radius": 8,
                        "circle-stroke-color": MAP_BORDER_ACCENT,
                        "circle-stroke-width": 2,
                        "circle-stroke-opacity": 0.7,
                    },
                },
            ],
        },
    ] as SourceConfig[];
};

export const validateCoordinates = (lon: number, lat: number) => {
    let lonIsValid = false;
    let latIsValid = false;

    if (typeof lon === "number" && typeof lat === "number") {
        if (lon >= -180 && lon <= 180) {
            lonIsValid = true;
        }

        if (lat >= -90 && lat <= 90) {
            latIsValid = true;
        }
    }

    return lonIsValid && latIsValid;
};
