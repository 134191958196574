import MapComponent from "../../components/map/MapComponent";
import { Col, Container, Row, Stack } from "react-bootstrap";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import { LayerTypes, MapStyles, SortConfig, SortDirectionEnum, SourceConfig } from "../../types/old_v1/types";
import { prepareEventMapConfig } from "../../hooks/useEventSearchLayer";
import { useGetEventsQuery } from "../../api/api";
import { getQueryParams } from "../../utils/url";
import { defaultMobileSizePx, Entities } from "../../constants/constants";
import MapEntityPane from "../../components/map/MapEntityPane";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faFilter, faMagnifyingGlass, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { MapLayerMouseEvent, useMap } from "react-map-gl";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import LoadingModal from "../../components/LoadingModal";
import { useBeatLayer } from "../../hooks/useBeatLayer";
import { useDarkMode } from "../../hooks/useDarkMode";
import SortButton from "../../components/SortButton";
import { useManageSortSearchParam } from "../../hooks/useManageSortSearchParam";
import Button from "react-bootstrap/Button";
import FiltersModal from "../../components/modalFilters/FiltersModal";
import FilterBar from "../../components/modalFilters/FilterBar";
import { useClusterMapEventHandlers } from "../../hooks/useClusterMapEventHandlers";
import BaseMapSelector from "../../components/map/BaseMapSelector";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import { useFilters } from "../../hooks/useFilters";

const MapSearch = () => {
    useSetPageTitle("ForceMetrics | Map");
    const [search, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isDarkMode } = useDarkMode();
    const { clusterClick, clusterMouseEnter, clusterMouseLeave } = useClusterMapEventHandlers();
    const currentPopupProperties = useAppSelector(({ map }) => map.popupProperties);
    const [dataSources, setDataSources] = React.useState<SourceConfig[]>([]);
    const [show, setShow] = React.useState<boolean>(false);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const searchParams = React.useMemo(() => new URLSearchParams(search), [search]);
    const [selectedStyle, setSelectedStyle] = React.useState<MapStyles>("streets");
    const { filterPayload, beatsIsFetching } = useFilters({ includeGeometry: true });

    // always want to fetch max of 2500, and paginate results on FE in list view
    const queryParams = { ...getQueryParams(searchParams), pageSize: "2500" };
    // if no dateRange, set it to 30days as default
    if (!queryParams.dateRange) {
        queryParams.dateRange = "30days";
    }
    queryParams.page = null;
    const map = useMap();
    const [localCoords, setLocalCoords] = React.useState<any>(null);

    // skip the search if query is not present in URL at all
    // but don't skip for empty search (query = "")
    // alternatively, even if query is null, perform the search if
    // user attempts to search this area (i.e. topLeft will be present in URL)
    let skipQuery = true;
    if (queryParams.query !== null || queryParams.topLeft) {
        skipQuery = false;
    }

    const { data: events, isFetching: eventsFetching } = useGetEventsQuery(
        { formattedResults: true, source: "map", ...queryParams, filters: filterPayload },
        { skip: skipQuery || beatsIsFetching }
    );

    const { beatSourceConfig, beatsFetching } = useBeatLayer(isDarkMode ? "dark" : "streets");

    const eventFiltersAggregations = events?.aggregations;

    const clickEventHandler = (event: MapLayerMouseEvent) => {
        clusterClick(event);
    };

    const mouseEnterEventHandler = (event: MapLayerMouseEvent) => {
        clusterMouseEnter(event, dispatch);
    };

    const mouseLeaveEventHandler = () => {
        clusterMouseLeave(currentPopupProperties, dispatch);
    };

    const moveEndEventHandler = () => {
        const specificMap = map && map["map-search"];
        if (specificMap) {
            const mapboxBounds = specificMap.getBounds();
            const topLeft = mapboxBounds.getNorthWest();
            const bottomRight = mapboxBounds.getSouthEast();
            setLocalCoords({ topLeft, bottomRight });
        }
    };
    const searchArea = (topLeft: any, bottomRight: any) => {
        let updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.set("topLeft", `${topLeft.lat},${topLeft.lng}`);
        updatedSearchParams.set("bottomRight", `${bottomRight.lat},${bottomRight.lng}`);
        setSearchParams(updatedSearchParams.toString());
    };

    const centerMap = () => {
        const specificMap = map && map["map-search"];
        if (specificMap && window.innerWidth > defaultMobileSizePx) {
            // ease back to center when close, go to new center when open
            const easeToConfig = !show
                ? {
                      padding: {
                          left: (document.getElementById("desktopMapSidePanel")?.offsetWidth || 300) * 2,
                          right: 100,
                          top: 20,
                          bottom: 20,
                      },
                      duration: 333,
                  }
                : {
                      padding: {
                          left: (document.getElementById("desktopMapSidePanel")?.offsetWidth || 300) / 2,
                          right: (document.getElementById("desktopMapSidePanel")?.offsetWidth || 300) / 2,
                          top: 20,
                          bottom: 20,
                      },
                      duration: 333,
                  };

            specificMap.easeTo(easeToConfig);
        }
    };

    const { updateSortUrlParam } = useManageSortSearchParam();

    React.useEffect(() => {
        updateSortUrlParam();
    }, [searchParams, updateSortUrlParam]);

    // reset map to default map style when dark mode value changes
    React.useEffect(() => {
        setSelectedStyle(isDarkMode ? "dark" : "streets");
    }, [isDarkMode]);

    React.useEffect(() => {
        const configs: SourceConfig[] = [];

        if (beatSourceConfig && !beatsFetching) {
            configs.push(beatSourceConfig);
        }

        if (events && events.search_results) {
            configs.push(prepareEventMapConfig(events.search_results, false));
            setShow(true);
        }

        if (configs.length) {
            setDataSources(configs);
        }
    }, [beatSourceConfig, beatsFetching, events]);

    const sortConfig: SortConfig = {
        relevance: {
            label: "Relevance",
            defaultSortDirection: SortDirectionEnum.NONE,
            sortString: true,
            isDisabled: searchParams.get("query")?.length === 0,
        },
        date: {
            label: "Date",
            defaultSortDirection: SortDirectionEnum.DESCENDING,
            sortString: true,
        },
    };

    // The apply button will set or remove params based on Sort Button changes
    const apply = (option: string, direction?: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("sort", option);
        // setting direction via arrow button
        if (direction) {
            newSearchParams.set("sortDirection", direction);
            // removing sortDirection when config option is none
        } else if (sortConfig[option]?.defaultSortDirection === SortDirectionEnum.NONE) {
            newSearchParams.delete("sortDirection");
            // setting sortDirection on first change to option without NONE
        } else if (sortConfig[option]?.defaultSortDirection !== SortDirectionEnum.NONE) {
            newSearchParams.set("sortDirection", sortConfig[option]?.defaultSortDirection);
        }

        navigate(`.?${newSearchParams.toString()}`);
    };

    return (
        <Container className="mt-3 d-flex flex-grow-1 flex-column" fluid>
            <LoadingModal show={eventsFetching} />
            <Row>
                <Col lg={{ span: 6, offset: show ? 4 : 3 }} md={{ span: 8, offset: 2 }} sm={12}>
                    <Row className="d-flex">
                        <Col xs={12}>
                            <SearchInput searchPath="./" isMap={true} />
                        </Col>
                    </Row>
                    <Row className="d-flex flex-grow-1">
                        <Col xs={12}>
                            <div className="align-self-center d-md-flex">
                                <div className="me-md-3 mb-3 mb-md-0">
                                    <Button
                                        className="pendo_filters_modal_open_button cursor-pointer container-md justify-content-center d-inline-flex align-items-center gap-2 text-nowrap rounded sort-filter"
                                        onClick={() => setShowModal(true)}
                                    >
                                        <FontAwesomeIcon icon={faFilter} /> Filters
                                    </Button>
                                </div>
                                <FilterBar
                                    showModal={!showModal ? () => setShowModal(true) : null}
                                    tab={LayerTypes.event}
                                    dateRangeDefaultValue="30days"
                                    canDateRangeBeAnyTime={true}
                                    //include beat geometry for the filter bar to use the cached get beats call
                                    includeBeatGeometry
                                />
                                <FiltersModal
                                    eventFiltersAggregations={eventFiltersAggregations}
                                    show={showModal}
                                    handleClose={() => setShowModal(false)}
                                    selectedTab={Entities.EVENT}
                                    resultsText="Events"
                                    defaultFilterValues={{ dateRange: "30days" }}
                                    defaultDateRange="30days"
                                    totalSearchResults={events?.search_results?.length || null}
                                    includeBeatGeometry
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex mb-3">
                        <Col xs={12} md={10}>
                            {!!events && (
                                <div className="mt-3">
                                    <SortButton
                                        sortField={searchParams.get("sort") || "relevance"}
                                        sortConfig={sortConfig}
                                        sortDirection={
                                            searchParams.get("sortDirection") !== null
                                                ? searchParams.get("sortDirection") === SortDirectionEnum.DESCENDING
                                                    ? SortDirectionEnum.DESCENDING
                                                    : SortDirectionEnum.ASCENDING
                                                : sortConfig[searchParams.get("sort") || "relevance"]?.defaultSortDirection
                                        }
                                        apply={apply}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="flex-grow-1 mb-4">
                <Stack direction={"horizontal"} className={"h-100 overflow-auto"}>
                    <Col xs={12} className={`position-relative h-100 d-flex flex-column`}>
                        <Stack className="position-relative d-flex flex-grow-1 overflow-hidden rounded">
                            {!!localCoords && (
                                <div className="d-flex justify-content-center">
                                    <Button
                                        variant="light"
                                        style={{ zIndex: 1, backgroundColor: "white" }}
                                        className="mt-2 position-absolute"
                                        onClick={() => searchArea(localCoords.topLeft, localCoords.bottomRight)}
                                        disabled={eventsFetching}
                                    >
                                        {eventsFetching ? (
                                            <FontAwesomeIcon className="text-danger" icon={faSpinner} spin />
                                        ) : (
                                            <FontAwesomeIcon className="text-primary" icon={faMagnifyingGlass} />
                                        )}
                                        &nbsp;Search this area
                                    </Button>
                                </div>
                            )}
                            <MapComponent
                                id={"map-search"}
                                sources={dataSources}
                                layerClickEventHandler={clickEventHandler}
                                layerMouseLeaveEventHandler={mouseLeaveEventHandler}
                                layerMouseEnterEventHandler={mouseEnterEventHandler}
                                moveEndEventHandler={moveEndEventHandler}
                                fillContainer
                                style={selectedStyle}
                                padding={50}
                                dynamicCentering
                            />
                            <BaseMapSelector selectedStyle={selectedStyle} setSelectedStyle={setSelectedStyle} />
                            <div className="d-flex justify-content-start">
                                {!show && (
                                    <div
                                        style={{ zIndex: 1 }}
                                        className="d-flex map-toggle px-4"
                                        onClick={() => {
                                            centerMap();
                                            setShow(!show);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                )}
                            </div>
                        </Stack>
                    </Col>
                </Stack>
            </Row>
            <MapEntityPane
                show={show}
                handleClose={() => {
                    centerMap();
                    setShow(false);
                }}
                events={events}
            />
        </Container>
    );
};

export default MapSearch;
