import React from "react";
import { Badge, Button, Container, Nav, Navbar, NavDropdown, Stack } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import TextField from "../formcontrols/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faMailbox, faSend } from "@fortawesome/pro-solid-svg-icons";
import logo from "../../static/img/logo.png";
import usePermissions from "../../hooks/usePermissions";
import { PermissionType } from "../../types/old_v1/types";
import { getRadarInitialLink } from "../../pages/radar/utils";
import { v4 as uuid } from "uuid";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";
import useNextAwarePathname from "../../hooks/useNextAwarePathname";

enum AppStatsURLEnum {
    DASHBOARD = "/app/insights-dashboard",
    OFFICER_DASHBOARD = "/app/officer-insights-dashboard",
    DISPATCHER_DASHBOARD = "/app/dispatcher-insights-dashboard",
    TRENDS = "/app/metrics-dashboard",
}

const pathnameStartsWith = (pathname: string, pathValue: string) => {
    return pathname.startsWith(pathValue);
};

// returns true if the given pathname starts with any of the values in the pathValues array
export const pathnameStartsWithAnyOf = (currentPathname: string, pathValues: string[]) => {
    return pathValues.length
        ? pathValues
              .map((pathValue) => pathnameStartsWith(currentPathname, pathValue))
              .reduce((previousValue, currentValue) => previousValue || currentValue)
        : false;
};

type OwnProps = {
    notificationsCount?: number;
    setShowNotifications: Function;
    setShowUserMenu: Function;
    radarConfigsData: Array<any>;
};
const LargeNavBar = ({ notificationsCount = 0, setShowNotifications, setShowUserMenu, radarConfigsData = [] }: OwnProps) => {
    const location = useLocation();
    const { hasPermission } = usePermissions();
    const inputRef = React.useRef<any>(null);
    const { selectedAgency } = useSingleAgencyFilter();
    const navigate = useNavigate();

    const { canPerformNextFESearch, landingPath } = useNextAwarePathname();

    const activeKey = (): string => {
        if (pathnameStartsWith(location.pathname, "/app/search/map")) {
            return "/app/search/map";
        } else if (location.pathname === "/app" || pathnameStartsWith(location.pathname, "/app/search")) {
            return "/app/search";
        } else if (pathnameStartsWith(location.pathname, "/app/metrics-results")) {
            return "/app/metrics-dashboard";
        } else {
            return location.pathname;
        }
    };

    const submitQuickSearch = (values: { query: string }, formik: { resetForm: Function }) => {
        formik.resetForm();
        const newParams = new URLSearchParams({
            query: values.query,
            dateRange: "3years",
            sort: values.query === "" ? "date" : "relevance",
            source: "main_search",
            searchId: uuid(),
        });

        // if the user has the use_nextfe_search permission, redirect to the new search page via window.location.replace otherwise use the navigate hook
        hasPermission("use_nextfe_search")
            ? window.location.assign(`/search/results/events?${new URLSearchParams({ query: values.query }).toString()}`)
            : navigate(`/app/search?${newParams.toString()}`);
    };

    const numberOfInsights = () => {
        const perms: PermissionType[] = [
            "insights_dashboard_dashboard",
            "insights_dashboard_officer",
            "insights_dashboard_dispatcher",
            "insights_dashboard_trends",
        ];
        let count = 0;
        perms.forEach((perm) => {
            if (hasPermission(perm)) {
                count += 1;
            }
        });
        return count;
    };

    const hasInsights = () => {
        return numberOfInsights() > 0;
    };

    const displayDropdown = () => {
        return numberOfInsights() > 1;
    };

    const landingLinkHref = canPerformNextFESearch ? landingPath : "#";
    const landingLinkAs = canPerformNextFESearch ? undefined : Link;
    const landingLinkTo = canPerformNextFESearch ? "#" : landingPath;

    return (
        <Navbar collapseOnSelect bg="dark" variant="dark" expand="md" className="pt-0 pb-0 d-none d-md-flex">
            <Container fluid>
                <Nav className="w-100 align-items-center" activeKey={activeKey()}>
                    <Navbar.Brand href={landingLinkHref} as={canPerformNextFESearch ? undefined : Link} to={landingLinkTo}>
                        <img src={logo} className="mx-auto header-logo-height" alt="ForceMetrics logo" />
                    </Navbar.Brand>
                    {hasPermission("search") && (
                        <Nav.Link className="px-4" href={landingLinkHref} as={landingLinkAs} to={landingLinkTo} eventKey="/app/search">
                            <span data-testid="Search">Search</span>
                        </Nav.Link>
                    )}
                    {hasPermission("map") && (
                        <Nav.Link className="px-4" as={Link} to="/app/search/map?dateRange=30days&sort=date" eventKey="/app/search/map">
                            <span>Map</span>
                        </Nav.Link>
                    )}

                    {hasInsights() && displayDropdown() && (
                        <NavDropdown
                            data-testid="insightsDropdown"
                            title="Insights"
                            active={pathnameStartsWithAnyOf(location.pathname, Object.values(AppStatsURLEnum))}
                        >
                            {hasPermission("insights_dashboard_dashboard") && (
                                <NavDropdown.Item
                                    as={Link}
                                    to={`${AppStatsURLEnum.DASHBOARD}?dateRange=30days`}
                                    eventKey={AppStatsURLEnum.DASHBOARD}
                                    active={pathnameStartsWith(location.pathname, AppStatsURLEnum.DASHBOARD)}
                                >
                                    Dashboard
                                </NavDropdown.Item>
                            )}
                            {hasPermission("insights_dashboard_officer") && (
                                <NavDropdown.Item
                                    as={Link}
                                    to={`${AppStatsURLEnum.OFFICER_DASHBOARD}?dateRange=30days`}
                                    eventKey={AppStatsURLEnum.OFFICER_DASHBOARD}
                                    active={pathnameStartsWith(location.pathname, AppStatsURLEnum.OFFICER_DASHBOARD)}
                                >
                                    Officer Dashboard
                                </NavDropdown.Item>
                            )}
                            {hasPermission("insights_dashboard_dispatcher") && (
                                <NavDropdown.Item
                                    as={Link}
                                    to={`${AppStatsURLEnum.DISPATCHER_DASHBOARD}?dateRange=30days`}
                                    eventKey={AppStatsURLEnum.DISPATCHER_DASHBOARD}
                                    active={pathnameStartsWith(location.pathname, AppStatsURLEnum.DISPATCHER_DASHBOARD)}
                                >
                                    Dispatcher Dashboard
                                </NavDropdown.Item>
                            )}
                            {hasPermission("insights_dashboard_trends") && (
                                <NavDropdown.Item
                                    as={Link}
                                    to={`${AppStatsURLEnum.TRENDS}?tab=AtlBolo`}
                                    eventKey={AppStatsURLEnum.TRENDS}
                                    active={pathnameStartsWith(location.pathname, AppStatsURLEnum.TRENDS)}
                                >
                                    Trends
                                </NavDropdown.Item>
                            )}
                        </NavDropdown>
                    )}

                    {hasInsights() && !displayDropdown() && hasPermission("insights_dashboard_dashboard") && (
                        <Nav.Link
                            className="px-4"
                            as={Link}
                            to="/app/insights-dashboard?dateRange=30days"
                            eventKey="/app/insights-dashboard?dateRange=30days"
                        >
                            <span>Insights</span>
                        </Nav.Link>
                    )}

                    {hasInsights() && !displayDropdown() && hasPermission("insights_dashboard_officer") && (
                        <Nav.Link
                            className="px-4"
                            as={Link}
                            to="/app/officer-insights-dashboard?dateRange=30days"
                            eventKey="/app/officer-insights-dashboard?dateRange=30days"
                        >
                            <span>Officer Dashboard</span>
                        </Nav.Link>
                    )}

                    {hasInsights() && !displayDropdown() && hasPermission("insights_dashboard_dispatcher") && (
                        <Nav.Link
                            className="px-4"
                            as={Link}
                            to="/app/dispatcher-insights-dashboard?dateRange=30days"
                            eventKey="/app/dispatcher-insights-dashboard?dateRange=30days"
                        >
                            <span>Dispatcher Dashboard</span>
                        </Nav.Link>
                    )}

                    {hasInsights() && !displayDropdown() && hasPermission("insights_dashboard_trends") && (
                        <Nav.Link className="px-4" as={Link} to="/app/metrics-dashboard" eventKey="/app/metrics-dashboard">
                            <span>Trends</span>
                        </Nav.Link>
                    )}

                    {hasPermission("radar_main") && (
                        <Nav.Link
                            className="px-4"
                            as={Link}
                            to={getRadarInitialLink(radarConfigsData, selectedAgency?.fm_uuid)}
                            eventKey="/app/radar"
                        >
                            Radar
                        </Nav.Link>
                    )}

                    {hasPermission("app_stats_main") && (
                        <Nav.Link className="px-4" as={Link} to="/app/agency-metrics/stats" eventKey="/app/agency-metrics/stats">
                            <span className="text-nowrap">App Stats</span>
                        </Nav.Link>
                    )}
                    <div className="ms-auto">
                        <Stack direction="horizontal" gap={0}>
                            {hasPermission("search") && (
                                <Navbar.Text>
                                    <Formik initialValues={{ query: "" }} onSubmit={submitQuickSearch} enableReinitialize={true}>
                                        <Form>
                                            <Stack direction="horizontal" gap={2}>
                                                <TextField
                                                    innerRef={inputRef as any}
                                                    type="text"
                                                    name="query"
                                                    placeholder="Quick Search"
                                                    autoComplete="off"
                                                    className="pt-1 pb-1"
                                                    size={35}
                                                />
                                                <Button size="sm" type="submit" variant="outline-light" className="text-nowrap me-5">
                                                    <FontAwesomeIcon icon={faSend} className="me-2" />
                                                    <span className="d-md-none d-lg-inline-block">Search</span>
                                                </Button>
                                            </Stack>
                                        </Form>
                                    </Formik>
                                </Navbar.Text>
                            )}
                            <Navbar.Text>
                                <Button
                                    type="button"
                                    className="border-0 bg-transparent text-nowrap"
                                    size="sm"
                                    onClick={() => setShowNotifications(true)}
                                >
                                    <FontAwesomeIcon icon={faMailbox} size="lg" />
                                    {notificationsCount > 0 && (
                                        <sup>
                                            <Badge bg="danger" pill={true}>
                                                {notificationsCount}
                                            </Badge>
                                        </sup>
                                    )}
                                </Button>
                            </Navbar.Text>
                            <Navbar.Text>
                                <Button className="border-0 bg-transparent" size="sm" onClick={() => setShowUserMenu(true)}>
                                    <FontAwesomeIcon icon={faGear} size="lg" />
                                </Button>
                            </Navbar.Text>
                        </Stack>
                    </div>
                </Nav>
            </Container>
        </Navbar>
    );
};

LargeNavBar.displayName = "LargeNavBar";
export default LargeNavBar;
