import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { isBefore, isEqual, parseISO, sub } from "date-fns";
import { useNavigate } from "react-router";
import { formatDateToISO } from "../../utils/date";
import { faCalendar, faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const now = new Date();

export const calculateDates = (dateRange: string, startDate: string, endDate: string, now: Date) => {
    let start, end;
    if (dateRange === "custom") {
        const parsedStart = parseISO(startDate);
        const parsedEnd = parseISO(endDate);
        start = parsedStart.toString() !== "Invalid Date" ? formatDateToISO(parseISO(startDate)) : "";
        end = parsedEnd.toString() !== "Invalid Date" ? formatDateToISO(parseISO(endDate)) : "";
    } else {
        end = dateRange === "custom" ? "" : formatDateToISO(now);
        switch (dateRange) {
            case "4days":
                start = formatDateToISO(sub(now, { days: 4 }));
                break;
            case "8days":
                start = formatDateToISO(sub(now, { days: 8 }));
                break;
            case "30days":
                start = formatDateToISO(sub(now, { days: 30 }));
                break;
            case "90days":
                start = formatDateToISO(sub(now, { days: 90 }));
                break;
            case "1year":
                start = formatDateToISO(sub(now, { days: 365 }));
                break;
            case "3years":
                start = formatDateToISO(sub(now, { days: 1096 }));
                break;
            case "all_time":
            default:
                start = "";
        }
    }
    return {
        startDate: start,
        endDate: end,
    };
};

const DateRange = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const params = new URLSearchParams(searchParams);
    const dateRange = params.get("dateRange") || "all_time";
    const startDate = params.get("startDate") ?? "";
    const endDate = params.get("endDate") ?? "";
    const [range, setRange] = React.useState({ startDate: "", endDate: "" });
    const [selected, setSelected] = React.useState(dateRange);

    // Initially populates range
    React.useEffect(() => {
        const calculatedRange = calculateDates(dateRange, startDate, endDate, now);
        setRange(calculatedRange);
    }, [dateRange, startDate, endDate]);

    const handleOptionClick = (option: string) => {
        if (option === "custom") {
            setRange({ startDate: "", endDate: "" });
        } else {
            const calculatedRange = calculateDates(option, range.startDate, range.endDate, now);
            setRange(calculatedRange);
        }
        setSelected(option);
        apply(option);
    };

    const checkDateRangeValid = () => {
        return (
            !!range.startDate &&
            !!range.endDate &&
            (isBefore(parseISO(range.startDate), parseISO(range.endDate)) || isEqual(parseISO(range.startDate), parseISO(range.endDate)))
        );
    };

    const apply = (dateRange: string) => {
        //apply change only if date range changes
        params.delete("page");
        params.set("dateRange", dateRange);
        if (dateRange === "custom") {
            if (checkDateRangeValid()) {
                params.set("startDate", range.startDate);
                params.set("endDate", range.endDate);
            }
        } else {
            params.delete("startDate");
            params.delete("endDate");
        }
        navigate(`.?${params}`);
    };

    const handleDateInput = (field: string, value: string) => {
        setSelected("custom");
        setRange({ ...range, ...{ [field]: value } });
    };

    const getOptionButtonVariant = (option: string) => {
        return selected === option ? "primary" : "outline-primary";
    };

    return (
        <div>
            <Row className="gx-2 text-nowrap gap-2 mb-3">
                <Col className="text-center p-0">
                    <Button
                        className={"mb-2 four-days-filter w-100 pendo_date_range_4_days_sidebar_filter_button"}
                        variant={getOptionButtonVariant("4days")}
                        onClick={() => handleOptionClick("4days")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 4 Days
                    </Button>
                </Col>
                <Col className="text-center p-0">
                    <Button
                        className={"mb-2 eight-days-filter w-100 pendo_date_range_8_days_sidebar_filter_button"}
                        variant={getOptionButtonVariant("8days")}
                        onClick={() => handleOptionClick("8days")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 8 Days
                    </Button>
                </Col>
                <Col className="text-center p-0">
                    <Button
                        className={"mb-2 thirty-days-filter w-100 pendo_date_range_30_days_sidebar_filter_button"}
                        variant={getOptionButtonVariant("30days")}
                        onClick={() => handleOptionClick("30days")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 30 Days
                    </Button>
                </Col>
                <Col className="text-center p-0">
                    <Button
                        className={"mb-2 ninety-days-filter w-100 pendo_date_range_90_days_sidebar_filter_button"}
                        variant={getOptionButtonVariant("90days")}
                        onClick={() => handleOptionClick("90days")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 90 Days
                    </Button>
                </Col>
                <Col className="text-center p-0">
                    <Button
                        className={"mb-2 one-year-filter w-100 pendo_date_range_1_year_sidebar_filter_button"}
                        variant={getOptionButtonVariant("1year")}
                        onClick={() => handleOptionClick("1year")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 1 Year
                    </Button>
                </Col>
                <Col className="text-center p-0">
                    <Button
                        className={"mb-2 three-years-filter w-100 pendo_date_range_3_years_sidebar_filter_button"}
                        variant={getOptionButtonVariant("3years")}
                        onClick={() => handleOptionClick("3years")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 3 Years
                    </Button>
                </Col>
                <Col className="text-center p-0">
                    <Button
                        className="mb-2 all-time-filter w-100 pendo_date_range_all_time_sidebar_filter_button"
                        variant={getOptionButtonVariant("all_time")}
                        onClick={() => handleOptionClick("all_time")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> All Time
                    </Button>
                </Col>
                <Col className="text-center p-0">
                    <Button
                        className="mb-2 custom-filter w-100 pendo_date_range_custom_sidebar_filter_button"
                        variant={getOptionButtonVariant("custom")}
                        onClick={() => handleOptionClick("custom")}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} /> Custom
                    </Button>
                </Col>
            </Row>

            {selected === "custom" && (
                <>
                    <Row className="mt-5 mb-3 justify-content-center">
                        <Col xs={12} className="mb-3">
                            <label className="form-label mb-0" htmlFor="startDate">
                                Start Date
                            </label>
                            <input
                                data-testid="start-date"
                                className="form-control pendo_date_range_custom_start_date_sidebar_filter_button"
                                type="date"
                                id="startDate"
                                value={range.startDate}
                                onChange={(e) => handleDateInput("startDate", e.target.value)}
                                onBlur={() => apply(dateRange)}
                            />
                        </Col>
                        <Col xs={12}>
                            <label className="form-label mb-0" htmlFor="endDate">
                                End Date
                            </label>
                            <input
                                data-testid="end-date"
                                className="form-control pendo_date_range_custom_end_date_sidebar_filter_button"
                                type="date"
                                id="endDate"
                                value={range.endDate}
                                onChange={(e) => handleDateInput("endDate", e.target.value)}
                                onBlur={() => apply(dateRange)}
                                onMouseLeave={() => apply(dateRange)}
                            />
                        </Col>
                    </Row>
                    {startDate && endDate && !checkDateRangeValid() && (
                        <span className="d-flex justify-content-center text-danger">Invalid Date Range</span>
                    )}
                </>
            )}
        </div>
    );
};

DateRange.displayName = "DateRange";
export default DateRange;
