import React from "react";
import Highlighter from "../../../components/highlighter/Highlighter";
import { getElasticSourceWithHighlights } from "../../../utils/elastic";
import { sort } from "../../../utils/old_v1/sort";
import { format, parseISO } from "date-fns";

const CastleRockCallNotes = ({ callNotes, sortBy = "created_at" }: { callNotes?: any; sortBy?: string }) => {
    //don't allow created_at date to get highlighted since that doesn't make much sense since it's just a time stamp and it leads to issues
    const sortedCallNotes = (callNotes ? [...callNotes] : [])
        .map((callNote: any) => ({
            ...getElasticSourceWithHighlights(callNote),
            created_at: callNote?._source?.created_at,
            id: callNote?._id,
        }))
        .sort(sort(sortBy, true));
    return (
        <React.Fragment>
            {sortedCallNotes && Array.isArray(sortedCallNotes) && sortedCallNotes.length
                ? sortedCallNotes.map((note: any, index: number) => {
                      const timeAuthorString = `${note.created_at && format(parseISO(note.created_at), "HH:mm:ss")}${
                          !!note.author ? ` [${note.author}]` : ""
                      }`;
                      return (
                          <p className={`mb-1${index === 0 ? "" : " mt-4"}`} key={note.id} style={{ whiteSpace: "pre-line" }}>
                              <strong>{timeAuthorString}</strong>
                              &nbsp;
                              <Highlighter>{note.body}</Highlighter>
                          </p>
                      );
                  })
                : null}
        </React.Fragment>
    );
};

export default CastleRockCallNotes;
