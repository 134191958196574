import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, ListGroup } from "react-bootstrap";
import { formatBirthdayAndDOB } from "../../utils/date";
import { formatName } from "../../utils/person";
import { faPerson, faPersonDress } from "@fortawesome/pro-light-svg-icons";
import { useManageSearchParams } from "../../hooks/useManageSearchParams";
import { PERSON_ACCENT } from "../../utils/colors";

import { useMultiAgency } from "../../hooks/useMultiAgency";
import LinkWrapper from "../LinkWrapper";

type OwnProps = {
    associatedPerson: any;
    eventData?: any[];
    showRole: boolean;
    agencyId: string | undefined;
    currentPerson?: any;
};

const SimplePersonCard = ({ associatedPerson, showRole, agencyId }: OwnProps) => {
    const { constructPersonUrl } = useMultiAgency();
    const { processSearchParams } = useManageSearchParams();

    const formattedName = formatName(associatedPerson);

    const constructedPersonURL = constructPersonUrl(agencyId, associatedPerson, processSearchParams([], ["currentTab"]));

    return (
        <ListGroup.Item as={Card} className="p-0 mb-3 border-0">
            <Card.Header className="w-100 d-flex align-items-center justify-content-between border-0 rounded">
                <div className="d-flex flex-wrap align-items-center">
                    <FontAwesomeIcon
                        icon={
                            associatedPerson.gender === "F" || associatedPerson.gender?.toLowerCase() === "female"
                                ? faPersonDress
                                : faPerson
                        }
                        className="fs-2 me-4"
                        color={PERSON_ACCENT}
                    />
                    {showRole && (
                        <div>
                            <span className="fw-bold me-3">{associatedPerson.role}</span>
                        </div>
                    )}
                    {!!formattedName ? (
                        <LinkWrapper className="fw-bold me-3" isAnchor={constructedPersonURL.isNextFELink} to={constructedPersonURL.url}>
                            {formattedName}
                        </LinkWrapper>
                    ) : (
                        <span className="fw-bold me-3">Unknown</span>
                    )}
                    {!!associatedPerson.birthdate && <span className="me-3">DOB: {formatBirthdayAndDOB(associatedPerson.birthdate)}</span>}
                </div>
            </Card.Header>
        </ListGroup.Item>
    );
};

export default SimplePersonCard;
