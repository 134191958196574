import React from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";
import { useUpdateEulaAcceptanceMutation } from "../api/api";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setUserObject } from "../app/slices/user/userSlice";
import PhoneLink from "../components/PhoneLink";
import { formatDateWithoutTime } from "../utils/date";
import { format } from "date-fns";

type ConnectedProps = {
    show?: boolean;
    close?: () => void;
};

const ConnectedEulaModal = ({ show, close }: ConnectedProps) => {
    const user = useAppSelector(({ user }) => user.userObject);
    const dispatch = useAppDispatch();
    const [updateEulaAcceptance, { isLoading: isUpdating }] = useUpdateEulaAcceptanceMutation();

    const submit = async () => {
        await updateEulaAcceptance({});
        dispatch(setUserObject({ ...user, accepted_eula_at: format(new Date(), "yyyy-MM-dd hh:mm:ss"), must_accept_eula: false }));
    };

    return <EulaModal user={user} submit={submit} isUpdating={isUpdating} show={show} close={close} />;
};

type OwnProps = {
    user: any;
    submit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
    isUpdating: boolean;
    show?: boolean;
    close?: () => void;
};

const EulaModal = ({ user, submit, isUpdating, show, close = () => {} }: OwnProps) => {
    return (
        <Modal show={user?.must_accept_eula || show} size="xl" onHide={close} scrollable={true}>
            <Modal.Header closeButton={show}>
                <Modal.Title>FORCEMETRICS PLATFORM TERMS AND CONDITIONS</Modal.Title>
            </Modal.Header>
            <Modal.Body data-testid="eula-modal-body" className="px-0">
                <Container fluid={true}>
                    <Row className="m-2">
                        <p>
                            Please read these ForceMetrics Platform Terms and Conditions (“Terms and Conditions”) carefully. Your use of the
                            Platform (as defined below) indicates that You have read, accepted and agreed to these Terms and Conditions and
                            constitutes Your consent to these Terms and Conditions.
                        </p>
                        <p>
                            Your consent to these Terms and Conditions is required in order to use the ForceMetrics Platform. If you do not
                            accept these Terms and Conditions, you may not install, access or use the ForceMetrics Platform.
                        </p>
                        <p>THESE TERMS AND CONDITIONS CONTAIN A MANDATORY INDIVIDUAL CLASS ACTION WAIVER PROVISION. </p>

                        <h3>1. TERMS AND CONDITIONS</h3>
                        <p>
                            These Terms and Conditions are between you, as an individual or as a person authorized to act on behalf of your
                            organization (“You”, “Your,” or “User”), and ForceMetrics Inc (“ForceMetrics”) concerning Your installation of,
                            access to and use of ForceMetrics’ web-based application software (“Platform”).&nbsp;
                            <span className="fw-bold fst-italic">
                                THE PLATFORM SHOULD BE USED IN ADDITION TO AND NOT IN LIEU OF OR AS A REPLACEMENT FOR CURRENT OR OTHER
                                APPLICABLE INFORMATION DATABASES.
                            </span>
                        </p>

                        <h3>2. ACCEPTANCE OF THE TERMS AND CONDITIONS</h3>
                        <p>
                            These Terms and Conditions may be accepted by clicking the “I accept” button if such button or function is
                            available or present via the user interface for the Platform. You also accept the Terms and Conditions each time
                            You access or use the Platform.
                        </p>
                        <p>
                            By using the Platform, You affirm that You are of legal age to enter into these Terms and Conditions, are duly
                            authorized by your employer or other authorized entity with which you are associated (an “Organization”) to
                            access and use the Platform, and have the legal authority to accept and agree to be bound by these Terms and
                            Conditions.
                        </p>
                        <p>
                            You may use the Platform only within the parameters provided by Your employment or affiliation with the
                            Organization.
                        </p>

                        <h3>3. CHANGES TO THE TERMS AND CONDITIONS</h3>
                        <p>
                            ForceMetrics may, at any time and in its sole discretion, without warning or notice, modify, update, or
                            otherwise change these Terms and Conditions. Each such modification will require You to review and accept the
                            updated Terms and Conditions prior to obtaining access to the Platform which promptly will be presented upon the
                            next log on.
                        </p>
                        <p>
                            The current version of these Terms and Conditions will be available for review on the ForceMetrics secure
                            website and supersedes all prior versions. The latest publication or revision date will be located at the bottom
                            of the Terms and Conditions. Your continued use of the Platform constitutes Your acceptance of such
                            modifications and Your agreement to be bound by the Terms and Conditions as modified. If You do not agree to any
                            modification of these Terms and Conditions, Your sole remedy is to discontinue use of the Platform.
                        </p>

                        <h3>4. TERMINATION</h3>
                        <p>
                            Termination of Your Account by You. You may request termination of Your use of the Platform at any time by
                            communicating such request to ForceMetrics, your organization’s administrator(s) and/or deleting the application
                            from your device(s).
                        </p>
                        <p>
                            Termination of Your Account by ForceMetrics. ForceMetrics may immediately disable and/or terminate Your Platform
                            at any time for any or no reason without notice.
                        </p>
                        <p>
                            Termination will result in the forfeiture of any and all information input by You (“User-Generated Data”) into
                            the Platform. You shall have no ownership or other rights to the User-Generated Data.
                        </p>
                        <p>
                            You are not permitted to use the Platform or any information available via the Platform, including
                            User-Generated Data, outside of the parameters under which you have been granted access to the Platform or
                            following the termination of your account or access thereto.
                        </p>

                        <h3>5. LICENSE; LIMITATIONS ON USE</h3>
                        <p>
                            License. ForceMetrics grants to You a non-exclusive, royalty-free, limited term license to install, access and
                            use the Platform in your Organization-owned and issued devices, including desktop computers, laptops and tablets
                            and mobile devices and the like, in connection with the performance of Your job duties.
                        </p>
                        <p>
                            Access/Credentials. You will create and keep strictly confidential a unique user ID and password for access to
                            the Platform.
                        </p>
                        <p>
                            Limitations on Use. You will not: (a) rent, lease, provide access to or sublicense the Platform to any third
                            party; (b) use the Platform to provide, or incorporate the Platform into, any commercial offering (or otherwise
                            directly expose the functionality of the Platform) for the benefit of a third party; (c) reverse engineer,
                            decompile, disassemble, or otherwise seek to obtain the source code or non-public APIs to the Platform; or (d)
                            remove or obscure any proprietary or other notices contained in the Platform, including references or notices
                            with respect to Federal Bureau of Investigation Criminal Justice Information Services.
                        </p>
                        <p>
                            Operation Restrictions. It may be dangerous to operate a moving vehicle while attempting to operate a laptop,
                            mobile device or other touch screen and any of their applications. Licensee agrees that Users will be instructed
                            to only utilize the interface for the Service at times when it is safe to do so. ForceMetrics is not liable for
                            any accident caused by a result of distraction such as from viewing the screen while operating a moving vehicle.
                        </p>

                        <h3>6. PLATFORM CONTENT; MACHINE LEARNING (AI/GENERATIVE AI)</h3>
                        <p>
                            Data from External Data Sources. You understand and acknowledge that the Platform provides You with search
                            capabilities, visualizations of public safety data, private and public data sources, and data linkage from
                            multiple existing data sources related to community impact, operational efficiencies, and performance metrics in
                            order to identify community needs, measure success and mitigate risks, and ForceMetrics does not investigate,
                            qualify or verify the accuracy of any such information and in no case will be liable for the same.
                        </p>
                        <p>
                            Machine Learning. As permitted by applicable law, the Platform may utilize non-generative and generative AI to
                            process and create probabilistic outputs and predictions based on existing data, including natural language
                            processing to create officer/community labels, text summarizations, topic modeling, and sequential pattern
                            analysis. ForceMetrics reserves the right, in its sole discretion to incorporate additional machine learning
                            processes, including but not limited to named entity extraction, sentiment analysis, speech recognition, text
                            clustering, ontology mapping, labeling accuracy, and other techniques. User acknowledges that AI information,
                            predictions or probabilities are not guaranteed to be accurate or complete, and that User should not rely solely
                            on the Platform content for critical decision making.
                        </p>
                        <p>
                            Text/Narrative Summaries. Text summarizations (“Narrative Summaries”), within the Platform are automatically
                            generated excerpts of full reports, are not comprehensive and are not guaranteed to be accurate. User
                            acknowledges that such Narrative Summaries may omit certain information or be open to interpretation due to
                            information that is included or not included therein. Narrative Summaries are “AS IS” without any assurances of
                            completeness, accuracy, usefulness, or timeliness, and without any explicit or implied warranties. It is crucial
                            that User not make public safety decisions, take actions or fail to take actions based solely on Narrative
                            Summaries.
                        </p>

                        <h3>7. PLATFORM UPDATES</h3>
                        <p>
                            ForceMetrics may, at any time and in its sole discretion, without prior warning or notice, modify, update, add
                            or remove features and functionality of the Platform or suspend and/or cease providing the Platform or any part
                            of the Platform. The Platform may communicate with ForceMetrics’ servers from time to time to check for
                            available updates, such as bug fixes, patches, enhanced functions, missing plug-ins and new versions
                            (collectively, “Software Updates”). Such Software Updates may be in various forms and are generally provided for
                            the purposes of improving the performance, security and reliability of the Platform. You are required to accept
                            and install all Software Updates and upgrades.
                        </p>

                        <h3>8. OWNERSHIP OF PLATFORM</h3>
                        <p>
                            You acknowledge that You are obtaining only a limited right to the Platform and that no ownership rights or
                            extended usage rights are granted to You under these Terms and Conditions. ForceMetrics retains all right, title
                            and interest (including all patent, copyright, trademark, trade secret and other intellectual property rights)
                            in and to the Platform, to any and all related and underlying technology and documentation for the Platform, and
                            to any derivative works, modifications or improvements of any of the foregoing, specifically including any
                            feedback from You (“ForceMetrics Technology”). Except as expressly set forth in the Terms and Conditions, no
                            right, title or interest in any ForceMetrics Technology is granted to You.
                        </p>

                        <h3>9. PRIVACY</h3>
                        <p>
                            Each party shall comply with its respective obligations under applicable data protection laws and as set forth
                            in the Security Policy:&nbsp;
                            <a href="https://www.fbi.gov/file-repository/cjis_security_policy_v5-9_20200601.pdf">
                                https://www.fbi.gov/file-repository/cjis_security_policy_v5-9_20200601.pdf
                            </a>
                            .
                        </p>

                        <h3>10. CONFIDENTIALITY</h3>
                        <p>
                            The Platform is Confidential Information of ForceMetrics. As used herein, “Confidential Information” shall mean
                            information regarding business and technology which is either identified at the time of disclosure as
                            confidential or which should be reasonably known by the receiving party to be confidential. Except as specified
                            otherwise herein, You will (i) hold in confidence and not disclose any Confidential Information to third parties
                            and (ii) not use Confidential Information for any purpose other than fulfilling Your obligations and exercising
                            Your rights under these Terms and Conditions. These restrictions on disclosure will not apply to any information
                            which: (a) is or becomes generally known or publicly available through no act or omission of You; (b) is known
                            by the You without confidentiality restriction at the time of receiving such information, as shown by written
                            records; or (c) is furnished to the You by a third party without confidentiality restriction. You may make
                            disclosures to the extent required by law or court order, provided You notify ForceMetrics in advance and
                            cooperate in any effort to obtain confidential treatment. You acknowledge that disclosure of Confidential
                            Information would cause substantial harm for which damages alone would not be a sufficient remedy, and therefore
                            that upon any such disclosure by You, ForceMetrics will be entitled to seek appropriate equitable relief in
                            addition to whatever other remedies it might have at law.
                        </p>

                        <h3>11. NO WARRANTY</h3>
                        <p>
                            THE PLATFORM IS PROVIDED “AS IS” AND FORCEMETRICS MAKES NO WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR
                            OTHERWISE, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE
                            OR NONINFRINGEMENT. FORCEMETRICS DOES NOT WARRANT THAT USE OF THE PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE,
                            NOR DOES FORCEMETRICS WARRANT THAT IT WILL REVIEW THE DATA THERIN FOR ACCURACY OR THAT IT WILL PRESERVE OR
                            MAINTAIN SUCH DATA WITHOUT LOSS. FORCEMETRICS SHALL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS, SERVICE FAILURES OR
                            OTHER PROBLEMS INHERENT IN USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE OF
                            FORCEMETRICS’ REASONABLE CONTROL.
                        </p>

                        <h3>12. YOUR LIABILITY</h3>
                        <p>
                            You are entirely responsible for any breach of Your obligations under (a) these Terms and Conditions, and (b)
                            any applicable law or regulation in the relevant jurisdiction, including the jurisdiction where You are a
                            resident or from where You are using the Platform and for the consequences of any such breach.
                        </p>

                        <h3>13. FORCEMETRICS’ LIABILITY</h3>
                        <p>
                            FORCEMETRICS IS NOT LIABLE TO YOU, REGARDLESS OF LEGAL GROUNDS, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE)
                            OR ANY THEORY OF LIABILITY, AND WHETHER OR NOT FORCEMETRICS HAS BEEN NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGE
                            OR LOSSES, FOR: (a) ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (b) ANY LOSS OF INCOME,
                            BUSINESS, ACTUAL OR ANTICIPATED PROFITS, OPPORTUNITY, GOODWILL OR REPUTATION (WHETHER DIRECT OR INDIRECT); (c)
                            ANY DAMAGE TO AND/OR CORRUPTION OR LOSS OF DATA (WHETHER DIRECT OR INDIRECT); (d) ANY LOSS OR DAMAGE AS A RESULT
                            OF: (I) ANY BREACH OF THE TERMS AND CONDITIONS OF THESE TERMS AND CONDITIONS OR ANY OTHER AGREEMENT OR
                            CONTRACTUAL RELATIONSHIP BETWEEN FORCEMETRICS AND YOU WHICH IS ATTRIBUTABLE TO NEGLIGENCE ON THE PART OF
                            FORCEMETRICS; (ii) ANY RELIANCE PLACED BY YOU ON THE SUITABILITY, ACCURACY, COMPLETENESS, RELIABILITY OR
                            EXISTENCE OF THE PLATFORM; (iii) ANY CONTENT, INCLUDING, BUT NOT LIMITED TO, THE LOSS OF CONTENT, ANY ERRORS OR
                            OMISSIONS IN ANY CONTENT, OR ANY LOSS OR DAMAGE OF ANY KIND INCURRED IN CONNECTION WITH USE OF OR EXPOSURE TO
                            ANY CONTENT ACCESSED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE PLATFORM; (iv) ANY CHANGES,
                            MODIFICATIONS, EXTENSIONS OR LIMITATIONS (INCLUDING ANY SUSPENSION OR TERMINATION OF YOUR USE OF THE PLATFORM,
                            ACCESS THERETO) TO THE PLATFORM; OR (v) THE USE OF THE PLATFORM VIA YOUR DEVICE(S) BY ANY OTHER PERSON THAN
                            YOURSELF (REGARDLESS OF YOUR KNOWLEDGE OF SUCH ACCESS). YOU ARE FULLY RESPONSIBLE AND LIABLE TO COMPENSATE
                            FORCEMETRICS FOR ANY DAMAGE AND/OR LOSS INCURRED DUE TO THE USE OF THE PLATFORM BY AN UNAUTHORIZED USER.
                        </p>
                        <p>
                            Notwithstanding the foregoing, nothing in these terms and conditions shall exclude or limit ForceMetrics’
                            liability for damages or losses which may not be lawfully excluded or limited under applicable mandatory law.
                        </p>

                        <h3>14. FORCE MAJEURE</h3>
                        <p>
                            ForceMetrics will not be responsible for any delay or failure to perform any obligation which is caused by any
                            Force Majeure event or other action beyond its control. “Force Majeure” will include but not be limited to: acts
                            of nature, floods or fire; transportation, power or other supply shortages or unavailability, failure or delays;
                            strikes or labor shortages; government actions, orders or restrictions; disease or pandemic; and wars,
                            insurrections, or acts of terrorism.
                        </p>

                        <h3>15. THIRD PARTY SITES AND OTHER CONTENT</h3>
                        <p>
                            The Platform contains content and content resources generated by third parties, including other websites and
                            third-party content (“External Resources”). ForceMetrics does not review, is not responsible for, does not
                            endorse and has no control over the content provided in, made available and/or displayed in the External
                            Resources within the Platform and is not responsible or liable, directly or indirectly, for any damage or loss
                            caused or alleged to be caused by or in connection with Your use of or reliance on such content (including any
                            reliance by You on the availability, accuracy, applicability or completeness of the content) which is made
                            available to You, provided to You or displayed to You from these External Resources.
                        </p>

                        <h3>16. GENERAL PROVISIONS</h3>
                        <p>
                            Entire Agreement. These Terms and Conditions constitute the entire agreement between You and ForceMetrics and
                            govern Your use of the Platform superseding any prior agreements between You and ForceMetrics with respect to
                            the Platform. You may also be subject to additional terms and conditions that may apply when You access or use
                            information from External Resources.
                        </p>
                        <p>
                            Waiver and Severability of Terms. The failure of ForceMetrics to exercise or enforce any right or provision of
                            these Terms and Conditions will not constitute a waiver of such right or provision. If any provision of these
                            Terms and Conditions is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree
                            that the court should endeavor to give effect to the parties’ intentions as reflected in the provision and the
                            other provisions of these Terms and Conditions remain in full force and effect.
                        </p>
                        <p>
                            Assignment. You are not permitted to transfer, assign or otherwise dispose of these Terms and Conditions which
                            are personal to You, or any of Your rights or obligations arising under these Terms and Conditions without the
                            prior written consent of ForceMetrics. ForceMetrics has the right to transfer, assign or otherwise dispose of
                            these Terms and Conditions without Your consent.
                        </p>
                        <p>
                            Choice of Law and Forum. These Terms and Conditions will be governed by and construed under the laws of the
                            State of Colorado. Any suit or proceeding arising out of or relating to these Terms and Conditions will be
                            commenced exclusively in the state or federal courts in Denver, Colorado and each party irrevocably submits to
                            the exclusive jurisdiction and venue of such courts. Any action under these Terms and Conditions will take place
                            on an individual basis: class arbitrations and class actions are not permitted. YOU UNDERSTAND AND AGREE THAT BY
                            ENTERING INTO THESE TERMS AND CONDITIONS, YOU ARE WAIVING THE RIGHT TO PARTICIPATE IN A CLASS ACTION.
                        </p>
                        <p>
                            Interpretation. The section titles in these Terms and Conditions are for convenience only and have no legal or
                            contractual effect.
                        </p>
                        <p>
                            Survival. The following sections will survive any termination or expiration of these Terms and Conditions:
                            Sections 5, 8, 9, 10, 11, 12, 13 and 16.
                        </p>

                        <h3>CONTACT</h3>
                        <p>The following ForceMetrics entity is responsible for delivering the Platform:</p>
                        <p>FORCEMETRICS INC</p>
                        <p>2590 Welton Street, Suite 200</p>
                        <p>Denver, Colorado 80205</p>
                        <p>
                            Corporate Office Phone: <PhoneLink number="415-475-9176" />
                        </p>
                        <p>2021 FORCEMETRICS, INC. All Rights Reserved.</p>
                        <p>Effective: April 04, 2024</p>
                    </Row>

                    {!user?.must_accept_eula && (
                        <Row className="m-2 pb-5">
                            <p>Accepted EULA on {formatDateWithoutTime(user?.accepted_eula_at)}</p>
                        </Row>
                    )}
                </Container>
            </Modal.Body>
            {user?.must_accept_eula && (
                <Modal.Footer>
                    <Button onClick={submit} disabled={isUpdating}>
                        I accept the terms and conditions
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default ConnectedEulaModal;
