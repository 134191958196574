import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import SearchResultMap from "../../components/map/SearchResultMap";
import { useGetCommunityMetricsDataQuery } from "../../api/api";
import { getArrayOfDataFromElasticSearchReponse, getIndexFromFirstResultOfElasticResultsArray } from "../../utils/elastic";
import { setToastMessage } from "../../app/slices/user/userSlice";
import { useAppDispatch } from "../../app/hooks";
import { Card, Col, Row, Stack } from "react-bootstrap";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import { getQueryParams } from "../../utils/url";
import BaseEventTable from "../../components/eventtable/BaseEventTable";
import "./MetricsResults.css";
import LoadingModal from "../../components/LoadingModal";

type OwnProps = {
    data: any;
    category: string;
    subcategory: string;
    index: string;
    daysBack: string;
    date: string;
    dataIndex: string;
};

/**
 * Creates the Metrics Results page presentation component
 * @param data
 * @param rawData
 * @param category
 * @param subcategory
 * @param index
 * @param daysBack
 * @param date
 * @param dataIndex - the index of the data
 * @constructor
 */
const MetricsResults = ({ data, category, subcategory, index, daysBack, date, dataIndex }: OwnProps) => {
    const [searchParams] = useSearchParams();
    const responsiveEventCardRef = React.useRef<HTMLDivElement | null>(null);
    const scrollToTopFn = () => responsiveEventCardRef?.current?.scrollIntoView();

    const days = daysBack && Number(daysBack) > 1 ? `${daysBack} days` : date;

    const indexDisplay = index === "calls" ? "911 Calls" : index === "incidents" ? "Police Reports" : index;

    const resultsMessage = `${data?.length || 0} Events`;

    return (
        <>
            <Row className="py-3">
                <Col>
                    <h2>
                        {category} - {subcategory} - {indexDisplay} - {days}
                    </h2>
                </Col>
            </Row>
            {!!data ? (
                <>
                    {/* desktop */}
                    <Row className="g-3 d-none d-md-flex flex-grow-1 overflow-auto pb-3">
                        <Col xs={12} md={6} className="h-100 mt-0 overflow-auto">
                            <Card className="h-100">
                                <Card.Body className="overflow-auto">
                                    <div className="d-flex flex-column h-100">
                                        {index === "calls" ? (
                                            <BaseEventTable events={data} resultsMessage={resultsMessage} showIncidentInfo={false} />
                                        ) : null}
                                        {index === "incidents" ? (
                                            <BaseEventTable
                                                events={data}
                                                resultsMessage={resultsMessage}
                                                showCallInfo={false}
                                                showVictim={true}
                                            />
                                        ) : null}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} className="mt-0">
                            <Card className="d-none d-md-flex h-100">
                                <Card.Body className="p-0">
                                    <div className="w-100" />
                                    {data ? <SearchResultMap searchResults={data} searchParams={getQueryParams(searchParams)} /> : null}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {/* responsive */}
                    <Stack direction="vertical" gap={3} className="pb-3 d-flex d-md-none flex-grow-1 overflow-auto">
                        <Card ref={responsiveEventCardRef}>
                            <Card.Body>
                                <div className="d-flex flex-column">
                                    {index === "calls" ? (
                                        <BaseEventTable
                                            events={data}
                                            resultsMessage={resultsMessage}
                                            showIncidentInfo={false}
                                            scrollToTopFn={scrollToTopFn}
                                        />
                                    ) : null}
                                    {index === "incidents" ? (
                                        <BaseEventTable
                                            events={data}
                                            resultsMessage={resultsMessage}
                                            showCallInfo={false}
                                            showVictim={true}
                                            scrollToTopFn={scrollToTopFn}
                                        />
                                    ) : null}
                                </div>
                            </Card.Body>
                        </Card>
                        <Card style={{ minHeight: "50vh" }}>
                            <Card.Body className="p-0">
                                <div className="w-100" />
                                {data ? (
                                    <SearchResultMap id="search-result-map-responsive" searchResults={data} searchParams={searchParams} />
                                ) : null}
                            </Card.Body>
                        </Card>
                    </Stack>
                </>
            ) : null}
        </>
    );
};

/**
 * Creates the Metrics Results page connected component to be displayed in the page wrapper
 * @constructor
 */

const ConnectedMetricsResults = () => {
    useSetPageTitle("ForceMetrics | Insights Dashboard Results");

    const { search } = useLocation();
    const category = new URLSearchParams(search).get("category");
    const subcategory = new URLSearchParams(search).get("subcat");
    const index = new URLSearchParams(search).get("index");
    const daysBack = new URLSearchParams(search).get("daysBack");
    const date = new URLSearchParams(search).get("date");
    const apiUrl = new URLSearchParams(search).get("page");
    const dispatch = useAppDispatch();

    // I'm thinking the easiest way is to update teh partial url to get the api call, instead of havign to re-find it from the cat/subcat

    // now, instead of pullign this from redux, we are gonna make the api call instead
    // i need to pass
    //result_range (week, month or three_months) ORRR
    // day (this is for a click on a point in the line chart in the form yyyy-mm-dd)
    // AND result_type (calls or incidents)

    let apiPayload: {
        api: string;
        result_type: string;
        day?: string;
        result_range?: string;
    } = {
        api: apiUrl as string,
        result_type: index as string,
    };

    if (daysBack === "0") {
        // if daysback is 0, we've only clicked into one day's record. so we want to provide date

        const dateSplit = date?.split("/");

        const formattedDay = dateSplit ? `${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]}` : "";
        apiPayload = {
            ...apiPayload,
            day: formattedDay,
        };
    } else {
        let formattedResultRange;

        switch (daysBack) {
            case "90":
                formattedResultRange = "three_months";
                break;
            case "30":
                formattedResultRange = "month";
                break;
            case "7":
                formattedResultRange = "week";
                break;
            default:
                formattedResultRange = "";
        }

        apiPayload = {
            ...apiPayload,
            result_range: formattedResultRange,
        };
    }

    let beautifiedData;
    let dataIndex;

    const { data, isLoading } = useGetCommunityMetricsDataQuery(apiPayload);

    if (data) {
        if (!!data?.success) {
            beautifiedData = getArrayOfDataFromElasticSearchReponse(data);
            dataIndex = getIndexFromFirstResultOfElasticResultsArray(beautifiedData);
        } else {
            // dispatch the error toast
            dispatch(setToastMessage("An error has occurred, please try again. "));
        }
    }
    return (
        <>
            <MetricsResults
                data={beautifiedData}
                category={decodeURIComponent(category as string)}
                subcategory={decodeURIComponent(subcategory as string)}
                index={index as string}
                dataIndex={dataIndex}
                daysBack={daysBack as string}
                date={date as string}
            />
            <LoadingModal show={isLoading} />
        </>
    );
};

export default ConnectedMetricsResults;
