import React from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSend } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router";
import { Button, Overlay } from "react-bootstrap";
import SearchLandingHistoryList from "../pages/searchlanding/SearchLandingHistory";
import TextField from "./formcontrols/TextField";
import { v4 as uuidv4 } from "uuid";

type OwnProps = {
    searchPath: string;
    includeSearchHistory?: boolean;
    isMap?: boolean;
    className?: string;
};

const SearchInput = ({ searchPath, includeSearchHistory, isMap, className = "" }: OwnProps) => {
    const [searchId] = React.useState<string>(uuidv4());
    const [searchParams] = useSearchParams();
    const params = new URLSearchParams(searchParams);
    const navigate = useNavigate();
    const searchFor = searchParams.get("query") || "";
    const [showHistory, setShowHistory] = React.useState(false);
    const target = React.useRef<any>();
    params.set("searchId", searchId);

    // Function to set up the queryString to append to the url
    // will check for applied filters add the search input
    // on the initial user search, it will add date range, sort and selectedTab values
    let dateRange, sort;
    const setParams = (query: string) => {
        params.set("query", query);

        // reset map bounds on new search queries
        params.delete("topLeft");
        params.delete("bottomRight");

        dateRange = params.get("dateRange");

        if (!dateRange) {
            if (isMap) {
                dateRange = query === "" ? "4days" : "30days";
            } else {
                dateRange = "3years";
            }
        }

        params.set("dateRange", dateRange);

        // if query is an empty string, set sort to date
        // else, if sort isn't present on the url, set it to relevance
        if (query === "") {
            params.set("sort", "date");
        } else {
            sort = params.get("sort");
            if (!sort) {
                params.set("sort", "relevance");
            }
        }

        params.delete("page");

        return params;
    };

    const submitForm = (values: { query: string }) => {
        let searchQuery = setParams(values.query);
        navigate(`${searchPath}?${searchQuery.toString()}`);
    };

    // when leaving the input (not onSubmit) update the query type and close the history window
    const handleBlur = () => {
        setShowHistory(false);
    };

    return (
        <Formik
            initialValues={{
                query: searchFor,
            }}
            onSubmit={submitForm}
            enableReinitialize={true}
        >
            {(props) => {
                return (
                    <Form className={className}>
                        <div className="d-flex gap-4 mb-3">
                            <div className="fm-bg-color d-flex flex-row border align-items-center rounded flex-grow-1" ref={target}>
                                <FontAwesomeIcon icon={faSearch} className="text-muted mx-3" />
                                <TextField
                                    data-testid="searchbox"
                                    type="text"
                                    name="query"
                                    placeholder="What are you looking for?"
                                    className="shadow-none border-0 ps-0"
                                    autoComplete="off"
                                    onFocus={() => setShowHistory(true)}
                                    onBlur={handleBlur}
                                />
                                {showHistory && includeSearchHistory && (
                                    <Overlay target={target.current} show={true} placement="bottom">
                                        {({ placement, arrowProps, show: _show, popper, ...props }) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    width: (target.current && target.current.offsetWidth) || undefined,
                                                }}
                                            >
                                                <SearchLandingHistoryList />
                                            </div>
                                        )}
                                    </Overlay>
                                )}
                            </div>
                            <div>
                                <Button
                                    data-testid="searchbutton"
                                    className="pendo_search_button text-nowrap m-0 h-100"
                                    type="submit"
                                    variant="outline-primary"
                                >
                                    <FontAwesomeIcon style={{ opacity: 0.9 }} icon={faSend} />
                                    <span style={{ opacity: 0.9 }} className="ms-2 d-none d-md-inline">
                                        Search
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SearchInput;
