import { useAppSelector } from "../app/hooks";

const useAgencySettings = () => {
    const user = useAppSelector(({ user }) => user?.userObject);

    const incidentText = user?.agency_incident_text || "Incident";
    const callText = user?.agency_call_text || "Call";
    const incidentTextPlural = user?.agency_incident_text_plural || "Incidents";
    const callTextPlural = user?.agency_call_text_plural || "Calls";

    return {
        incidentText,
        callText,
        incidentTextPlural,
        callTextPlural,
    };
};

export default useAgencySettings;
