import React from "react";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import TextField from "../../components/formcontrols/TextField";
import { INVALID_CREDENTIALS, useRunPreAuthApiMutation, useRunPreEmailCheckSAMLOrDefaultApiMutation } from "../../api/api";
import { useAppDispatch } from "../../app/hooks";
import { setMfaData, setOnboardingObject } from "../../app/slices/user/userSlice";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faRightToBracket, faSpinner } from "@fortawesome/pro-light-svg-icons";
import FmAlert from "../support/FmAlert";
import { LOGIN_ROUTE, SEND_CODE_ROUTE } from "../../api/api";
import { useCookies } from "react-cookie";

type FormProps = {
    email: string;
    password: string;
};

const Login = () => {
    useSetPageTitle("ForceMetrics | Login");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookies, removeCookies] = useCookies(["saml_session", "accessToken", "refreshToken"]);
    const [alert, setAlert] = React.useState<string | undefined>(undefined);
    const [showPasswordField, setShowPasswordField] = React.useState(false);
    const [login, loginResult] = useRunPreAuthApiMutation();
    const [samlLogin, samlLoginResult] = useRunPreEmailCheckSAMLOrDefaultApiMutation();
    const [sendSmsCode] = useRunPreAuthApiMutation();
    const [searchParams] = useSearchParams();

    const initial: FormProps = {
        email: "",
        password: "",
    };

    const submitEmailAndPassword = (values: FormProps) => {
        if (showPasswordField) {
            login({ ...values, route: LOGIN_ROUTE });
        } else {
            samlLogin({ ...values });
        }
    };

    // Listens for the results from login, which sends the username and password to the server
    // If sms user, make api call to send text message (move to the server?)
    // then either takes the user to the mfa code entry screen if mfa is enabled (setup)
    // or to the select-mfa screen to configure their mfa
    React.useEffect(() => {
        // If SAML login is good, redirect to sso_url
        if (samlLoginResult?.data?.success === true && samlLoginResult?.data?.sso_url) {
            // Purge necessary storage items before new authentication via SAML
            // they will repopulate after successful authentication
            removeCookies("saml_session");
            removeCookies("accessToken");
            removeCookies("refreshToken");
            window.sessionStorage.removeItem("saml_session");
            window.sessionStorage.removeItem("accessToken");
            window.sessionStorage.removeItem("refreshToken");
            window.localStorage.removeItem("saml_session");
            window.localStorage.removeItem("accessToken");
            window.localStorage.removeItem("refreshToken");

            window.location.replace(samlLoginResult?.data?.sso_url);
        }
        // If not SAML login and is default, show password field
        // OR Initial email isn't saml, show password field for further validation
        if (
            (samlLoginResult?.data?.success === true && samlLoginResult?.data?.auth_type === "default") ||
            samlLoginResult?.data?.success === false
        ) {
            setShowPasswordField(true);
        }

        if (loginResult.isError) {
            const theError = loginResult.error;
            if ("error" in theError && theError.error && Array.isArray(theError.error) && theError.error[0] === INVALID_CREDENTIALS) {
                setAlert("invalid_credentials");
            } else {
                setAlert("server_error");
            }
        } else {
            setAlert(undefined);
        }
        if (loginResult.data) {
            dispatch(setMfaData(loginResult.data));
            if (loginResult.data && loginResult.data.mfaEnabled) {
                if (loginResult.data.mfaType.toLowerCase() === "sms") {
                    const email = loginResult.data.email;
                    const phone = loginResult.data.phone;
                    const mfaToken = loginResult.data.mfa;
                    sendSmsCode({ email, phone, mfaToken, route: SEND_CODE_ROUTE });
                }
                navigate(`../mfa?${searchParams.toString()}`);
            } else {
                dispatch(
                    setOnboardingObject({
                        mfa_type: loginResult.data.mfaType,
                        email: loginResult.data.email,
                        phone: loginResult.data.phone,
                        token: loginResult.data.mfa,
                        mfa_url: loginResult.data.mfaUrl,
                        mfaChange: true,
                    })
                );
                navigate("../reconfigure-mfa/select-mfa");
            }
        }
    }, [loginResult, dispatch, navigate, sendSmsCode, samlLoginResult, removeCookies, searchParams]);

    const loading = loginResult && loginResult.isLoading;
    return (
        <div className={"mt-4"}>
            {alert && (
                <Row className="justify-content-center">
                    <Col md={8} lg={6}>
                        <FmAlert alertKey={alert} />
                    </Col>
                </Row>
            )}
            <Row>
                <Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className="p-4 bg-filled">
                    <Formik
                        initialValues={initial}
                        onSubmit={submitEmailAndPassword}
                        validateOnBlur={false}
                        validateOnMount={false}
                        validationSchema={Yup.object(
                            showPasswordField
                                ? {
                                      email: Yup.string().email("Invalid email").required("Required"),
                                      password: Yup.string().required("Required"),
                                  }
                                : {
                                      email: Yup.string().email("Invalid email").required("Required"),
                                  }
                        )}
                    >
                        {(props) => (
                            <Form>
                                <Stack gap={3}>
                                    <TextField
                                        label="Email"
                                        name="email"
                                        type="text"
                                        autoFocus={true}
                                        autoCapitalize="none"
                                        autoComplete="username email"
                                        data-testid="email"
                                    />
                                    {showPasswordField && (
                                        <TextField
                                            label="Password"
                                            name="password"
                                            // should autoFocus when showPasswordField becomes true
                                            autoFocus={true}
                                            autoComplete="current-password"
                                            autoCapitalize="none"
                                            type="password"
                                            data-testid="password"
                                        />
                                    )}
                                    <div>
                                        <Button
                                            type="submit"
                                            variant={loading || !props.dirty ? "secondary" : "success"}
                                            disabled={loading || !props.dirty}
                                            className="float-end"
                                            size="sm"
                                        >
                                            {/* show this if we need a password */}
                                            {showPasswordField && (
                                                <FontAwesomeIcon className="me-2" icon={loading ? faSpinner : faRightToBracket} />
                                            )}
                                            {/* Show this first for email */}
                                            {!showPasswordField && (
                                                <FontAwesomeIcon className="me-2" icon={loading ? faSpinner : faArrowRight} />
                                            )}
                                            {/* Next to test Saml, otherwise Sign In with password */}
                                            {showPasswordField ? "Sign In" : "Next"}
                                        </Button>
                                    </div>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
            {showPasswordField && (
                <Row>
                    <Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className="text-center mt-5">
                        <Link to="/forgot-password" className="fw-bold">
                            Forgot Password
                        </Link>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default Login;
