import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setOnboardingObject } from "../../app/slices/user/userSlice";
import { useStep } from "./AccountSetup";
import FmAlert from "../support/FmAlert";
import MfaOptions from "./MfaOptions";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import { OnboardingObject } from "../../types/old_v1/types";

type OwnProps = {
    onboardingObject: OnboardingObject;
};

const Step2SelectMfaConnected = () => {
    const user = useAppSelector(({ user }) => user);
    const onboardingObject = user.onboardingObject;
    return <Step2SelectMfa onboardingObject={onboardingObject} />;
};

/**
 * Container for displaying the mfa options in the onboarding screens
 *
 * @constructor
 */
const Step2SelectMfa = ({ onboardingObject }: OwnProps) => {
    useSetPageTitle("ForceMetrics | Step2 - MFA");
    const { setStep } = useStep();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [alert, setAlert] = React.useState<string | undefined>();
    const [disabled, setDisabled] = React.useState<boolean>(false);

    // Verifies the onboardingObject has the appropriate values from the previous step. Must have token, mfa_url and password
    React.useEffect(() => {
        if (!onboardingObject || !onboardingObject.token || !onboardingObject.mfa_url || !onboardingObject.password) {
            setAlert("token_invalid");
            setDisabled(true);
        }
        setStep(2);
    }, [onboardingObject, setStep]);

    // Sets the mfa type selected in redux and navigates to the code entry page.
    const setMfaType = (mfa_type: string) => {
        dispatch(setOnboardingObject({ ...onboardingObject, mfa_type }));
        navigate("/account-setup/step3-enter-code");
    };

    return (
        <div className="mt-4">
            <div className="d-flex justify-content-center p-3">
                <h2>Select Multi-Factor Verification Type</h2>
            </div>
            {alert && (
                <Row className="justify-content-center">
                    <Col xs={11} sm={10} md={8} lg={7} xl={6} className={"mt-2"}>
                        <FmAlert alertKey={alert} onClose={() => setAlert(undefined)} />
                    </Col>
                </Row>
            )}
            <Row className="justify-content-center">
                <Col xs={11} sm={10} md={7} lg={6} xl={5}>
                    <MfaOptions setMfaType={setMfaType} disabled={disabled} />
                </Col>
            </Row>
        </div>
    );
};

Step2SelectMfa.displayName = "Step2SelectMfa";
export default Step2SelectMfaConnected;
