import React from "react";
import { Col, Container, ListGroup, Row, Stack } from "react-bootstrap";
import CallCard from "./CallCard";
import IncidentCard from "./IncidentCard";
import { sortSortableHeader } from "../../utils/sort";
import { LayerTypes, SortConfig, SortDirectionEnum } from "../../types/old_v1/types";
import useSortState from "../../hooks/useSortState";
import SortButton from "../SortButton";
import { FMPaginationNonLinks } from "../pagination/Pagination";
import { usePagination } from "../../hooks/usePagination";
import FilterBar from "../modalFilters/FilterBar";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import FiltersModal from "../modalFilters/FiltersModal";
import { Entities, FilterParamNames } from "../../constants/constants";
import tagDetails from "../../utils/TagDetails";
import { FilterParamNamesType } from "../../types/types";
import NoResults from "../NoResults";
import FMSpinner from "../Spinner";

const defaultSortConfig: SortConfig = {
    occurred_at: {
        label: "Occurred At",
        defaultSortDirection: SortDirectionEnum.DESCENDING,
        sortString: true,
    },
};

type TEventViewProps = {
    eventData: any;
    sortConfig?: SortConfig;
    isLoading?: boolean;
    defaultSortField?: string;
    defaultSortDirection?: SortDirectionEnum;
    scrollToTop?: any;
    pageReset: any;
    showInvolvedPeople: boolean;
    hideAgenciesFilter?: boolean;
};

const EventList = ({
    eventData = [],
    sortConfig = defaultSortConfig,
    defaultSortField = "occurred_at",
    defaultSortDirection = SortDirectionEnum.DESCENDING,
    isLoading = false,
    scrollToTop,
    pageReset,
    showInvolvedPeople = false,
    hideAgenciesFilter = false,
}: TEventViewProps) => {
    const [eventFilterString, setEventFilterString] = React.useState("");
    const { sortField, setSortField, sortDirection, setSortDirection } = useSortState(defaultSortField, defaultSortDirection);
    const tags = tagDetails();

    const sortedEvents = [...eventData]
        .filter((event) => {
            if (!eventFilterString) {
                return true;
            }

            const lowercaseFilter = eventFilterString.toLowerCase();
            return (
                `${event.source.incident_number}`.toLowerCase().includes(lowercaseFilter) ||
                `${event.source.call_type}`.toLowerCase().includes(lowercaseFilter) ||
                `${event.source.full_address}`.toLowerCase().includes(lowercaseFilter) ||
                `${event.source.incident_type}`.toLowerCase().includes(lowercaseFilter) ||
                event?.source.flags?.some((flag: any) => {
                    const tag = tags.find((tagDatum) => flag === tagDatum.code);
                    return flag.includes(lowercaseFilter) || tag?.name.toLowerCase().includes(lowercaseFilter);
                })
            );
        })
        .sort(sortSortableHeader(sortField, true, sortDirection));

    const reset = React.useMemo(
        () => ({ ...pageReset, sortField, sortDirection, eventFilterString }),
        [pageReset, sortDirection, sortField, eventFilterString]
    );

    const { currentPage, currentPageData, totalPages, setCurrentPage } = usePagination(sortedEvents || [], 10, reset, scrollToTop);
    const [show, setShow] = React.useState(false);

    const filterEventList = (event: any) => {
        setEventFilterString(event.target.value);
    };

    const defaultFilterValues: { [key in FilterParamNamesType]?: string } = {
        dateRange: "all_time",
    };

    return (
        <Stack gap={3} direction="vertical">
            <Container fluid className="p-0">
                <div className="align-self-center d-md-flex">
                    <div className="me-md-3 mb-3 mb-md-0">
                        <Button
                            className="pendo_filters_modal_open_button cursor-pointer container-md justify-content-center d-inline-flex align-items-center gap-2 text-nowrap rounded sort-filter"
                            onClick={() => setShow(true)}
                        >
                            <FontAwesomeIcon icon={faFilter} /> Filters
                        </Button>
                    </div>
                    <FilterBar
                        showModal={!show ? () => setShow(true) : null}
                        tab={LayerTypes.event}
                        dateRangeDefaultValue="all_time"
                        canDateRangeBeAnyTime={true}
                        hideFilterList={hideAgenciesFilter ? [FilterParamNames.AGENCIES] : []}
                    />
                    <FiltersModal
                        show={show}
                        handleClose={() => setShow(false)}
                        selectedTab={Entities.EVENT}
                        resultsText="Events"
                        defaultFilterValues={defaultFilterValues}
                        defaultDateRange="all_time"
                        totalSearchResults={sortedEvents?.length || null}
                        hideAgenciesFilter={hideAgenciesFilter}
                    />
                </div>
            </Container>
            <Stack direction="horizontal" gap={5}>
                <SortButton
                    sortConfig={sortConfig}
                    sortField={sortField}
                    setSortField={setSortField}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                />
                <input
                    name="associated-event-filter-input"
                    className="form-control pendo_text_filter_input"
                    onChange={filterEventList}
                    placeholder="Filter by Event Number, Type, Address, or Labels"
                />
            </Stack>
            {isLoading && <FMSpinner centered={true} />}
            {!isLoading && sortedEvents.length === 0 && <NoResults />}
            <ListGroup className="gap-5 mt-3">
                {currentPageData?.map((event: any) => {
                    // pass flattened event object to cards
                    const flattenedEvent: any = {
                        ...event,
                        ...event?.source,
                        source: undefined,
                        id: event.id,
                    };
                    if (event.index.includes("call")) {
                        return <CallCard key={event.id} call={flattenedEvent} />;
                    } else {
                        return <IncidentCard key={event.id} incident={flattenedEvent} showInvolvedPeople={showInvolvedPeople} />;
                    }
                })}
            </ListGroup>
            <Row className="mt-5 mb-2 d-flex justify-content-center">
                <Col xs={12} md={9}>
                    <FMPaginationNonLinks
                        page={currentPage + 1}
                        setPage={(page: number) => setCurrentPage(page - 1)}
                        totalPages={totalPages}
                        scrollToId={pageReset?.scrollToId}
                    />
                </Col>
            </Row>
        </Stack>
    );
};

export default EventList;
