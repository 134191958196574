import { differenceInMinutes, differenceInYears, format, getMonth, parseISO, sub } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

export const SHORT_DATE = "MM/dd/yy";
export const NORMAL_DATE = "MM/dd/yyyy";

export const formatAge = (timeString: string, calcDate?: string) => {
    if (!timeString) {
        return "--";
    }
    try {
        const time = parseISO(timeString);
        if ((time.toString() as any) === "Invalid Date") {
            return "--";
        }
        const date = calcDate || new Date();
        return differenceInYears(new Date(date), time);
    } catch (e) {
        return "--";
    }
};

export const formatDateToISO = (date: Date) => {
    return format(new Date(date), "yyyy-MM-dd");
};

export const convertDateToLocalUTC = (dateString: string) => {
    const offset = new Date().getTimezoneOffset();
    const utcDate = new Date(dateString);
    utcDate.setMinutes(utcDate.getMinutes() - offset);
    return utcDate;
};

export const formatDateMMDDYYYY = (dateString: string, def: string = "--", formatString: string = "MM/dd/yyyy") => {
    if (!dateString) {
        return def;
    }

    try {
        const dt = parseISO(dateString);
        return format(dt, formatString);
    } catch (e) {
        return def;
    }
};

export const formatDate = (dateString: string, def: string = "--", formatString: string = "MMM dd, yyyy h:mm aaa") => {
    if (!dateString) {
        return def;
    }

    try {
        const dt = parseISO(dateString);
        return format(dt, formatString);
    } catch (e) {
        return def;
    }
};

export const formatDateWithoutTime = (dateString: string, def: string = "--", formatString: string = "MMM dd, yyyy") => {
    if (!dateString) {
        return def;
    }

    try {
        const dt = parseISO(dateString);
        return format(dt, formatString);
    } catch (e) {
        return def;
    }
};

export const formatBirthdayAndDOB = (date: string) => {
    const birthdate = formatDateWithoutTime(date);
    let formatted = `${birthdate}`;

    const age = formatAge(date);

    if (age && age !== "--") {
        formatted += ` (${age} yrs)`;
    }

    return formatted;
};

export const formatBirthdateAndDeathDate = (birthDate: string, deathDate: string) => {
    let formatted = `${formatDateWithoutTime(birthDate)} - ${formatDateWithoutTime(deathDate)}`;

    const age = formatAge(birthDate, deathDate);

    if (age && age !== "--") {
        formatted += ` (${age} yrs)`;
    }

    return formatted;
};
/**
 * Calculates the number of days back from the calcFrom date the timeString is.
 * Assumes calcFrom is more recent than the timeString passed in.  Displays
 * the results as a string eg: "23 minutes", "12 hours, 11 minutes" or "1 day, 2 hours", etc.
 * @param calcFrom
 * @param timeString
 */
export const ago = (calcFrom: Date, timeString: string) => {
    if (!calcFrom || !timeString) {
        return "--";
    }

    try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const time = parseISO(timeString);

        if ((time.toString() as any) === "Invalid Date") {
            throw new Error("Invalid Date");
        }

        const date = zonedTimeToUtc(calcFrom, timezone);

        const delta = differenceInMinutes(date, time);
        if (delta < 0) {
            return "recently";
        } else if (delta < 60) {
            return `${delta} minute${delta === 1 ? "" : "s"}`;
        } else if (delta >= 60 && delta < 1440) {
            const hours = Math.floor(delta / 60);
            const minutes = delta - 60 * hours;
            return `${hours} hour${hours === 1 ? "" : "s"}${minutes > 0 ? `, ${minutes} minute${minutes === 1 ? "" : "s"}` : ""}`;
        } else if (delta >= 1440) {
            const days = Math.floor(delta / 1440);
            let result = `${days} day${days === 1 ? "" : "s"}`;
            const minutes = delta - 1440 * days;
            let hours = 0;
            if (minutes >= 60) {
                hours = Math.floor(minutes / 60);
                result += `, ${hours} hour${hours === 1 ? "" : "s"}`;
            } else if (minutes > 0) {
                result += `, ${minutes} min${minutes === 1 ? "" : "s"}`;
            }
            return result;
        }
    } catch (e) {
        return "--";
    }
};

export const get24HoursAgo = (calcFrom: Date, formatString: string) => {
    const newDate = sub(calcFrom, { hours: 24 });
    return format(newDate, formatString);
};

export const get7DaysAgo = (calcFrom: Date, formatString: string) => {
    const newDate = sub(calcFrom, { days: 7 });
    return format(newDate, formatString);
};

export const get30DaysAgo = (calcFrom: Date, formatString: string) => {
    const newDate = sub(calcFrom, { days: 30 });
    return format(newDate, formatString);
};

export const getYearAgo = (calcFrom: Date, formatString: string) => {
    const newDate = sub(calcFrom, { years: 1 });
    return format(newDate, formatString);
};

export const getMonthString = (month: number) => {
    switch (month) {
        case 1:
            return "January";
        case 2:
            return "February";
        case 3:
            return "March";
        case 4:
            return "April";
        case 5:
            return "May";
        case 6:
            return "June";
        case 7:
            return "July";
        case 8:
            return "August";
        case 9:
            return "September";
        case 10:
            return "October";
        case 11:
            return "November";
        case 12:
            return "December";
        default:
            return "";
    }
};

export const getMonthNumber = (month: string) => {
    switch (month) {
        case "January":
            return "01";
        case "February":
            return "02";
        case "March":
            return "03";
        case "April":
            return "04";
        case "May":
            return "05";
        case "June":
            return "06";
        case "July":
            return "07";
        case "August":
            return "08";
        case "September":
            return "09";
        case "October":
            return "10";
        case "November":
            return "11";
        case "December":
            return "12";
        default:
            return null;
    }
};

export const getDayString = (day: number) => {
    switch (day) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        default:
            return "";
    }
};

export const getMonthNum = (date: Date = new Date()) => {
    return getMonth(date) + 1;
};
