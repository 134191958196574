import React from "react";
import { Badge } from "react-bootstrap";
import { formatDate, SHORT_DATE } from "../../utils/date";
import { useMultiAgency } from "../../hooks/useMultiAgency";

export const getTimeLabel = (body: any) => {
    const dateRange = body.date_range;
    const startDate = body.start_date;
    const endDate = body.end_date;
    const isCustom = dateRange === "custom";
    let label;
    if (dateRange && !isCustom) {
        if (dateRange === "4days") {
            label = "4 Days";
        } else if (dateRange === "8days") {
            label = "8 Days";
        } else if (dateRange === "30days") {
            label = "30 Days";
        } else if (dateRange === "90days") {
            label = "90 Days";
        } else if (dateRange === "1year") {
            label = "1 Year";
        } else if (dateRange === "3years") {
            label = "3 Years";
        } else {
            label = dateRange;
        }
    } else if (isCustom && startDate && endDate) {
        label = `${formatDate(startDate, undefined, SHORT_DATE)} - ${formatDate(endDate, undefined, SHORT_DATE)}`;
    }
    return label;
};

export const getTagDisplay = (body: any, key: string) => {
    if (key === "sort") {
        return `Sort: ${body[key]}`;
    } else if (key === "query_type") {
        return `Query Type: ${body[key] === "cross_fields" ? "Any" : "Phrase"}`;
    } else if (key === "selected_tab") {
        return `Tab: ${body[key]}`;
    } else if (key === "race") {
        return `Race: ${body[key]}`;
    } else if (key === "gender") {
        return `Gender: ${body[key]}`;
    } else if (key === "tags") {
        return `Labels: ${body[key].split(",").join(", ")}`;
    } else if (key === "page_number") {
        return `Page: ${body[key]}`;
    } else if (key === "page_size") {
        return `Page Size: ${body[key]}`;
    } else {
        return decodeURIComponent(body[key]);
    }
};

// Filters in the url filter object will most likely all use different params for display values so splitting them out to a separate function
// from the regular url string params.
export const getFilterTagDisplay = (key: any, body: string, isMultiAgency: boolean) => {
    if (key === "beats" && Array.isArray(body)) {
        return isMultiAgency
            ? body.map((beat) => beat.agency_desc + "-" + beat.beat_name).toString()
            : body.map((beat) => beat.beat_name).toString();
    } else {
        return "";
    }
};

type OwnProps = {
    body: any;
    id: number;
};

/**
 * Converts the search history values stored in the body field of the search_history index into badges for display.
 * Doesn't display a few fields like search_for as that is in a separate column on the report and start_date
 * and end_date which are converted to a data range using getTimeLabel function.
 * The getTagDisplay properly formats each of the labels.
 *
 * @param body
 * @param id
 * @constructor
 */
const SearchHistoryLabels = ({ body, id }: OwnProps) => {
    const skips = ["request_body", "search_for", "start_date", "end_date", "top_left", "bottom_right", "is_map", "page_size", "filters"];
    const labelKeys = Object.keys(body);
    const { isMultiAgency } = useMultiAgency();

    return (
        <>
            {labelKeys.map((key: string) => {
                if (key === "date_range") {
                    return (
                        <Badge key={`${id}-${key}`} bg="secondary" className="me-2 mb-2">
                            {getTimeLabel(body)}
                        </Badge>
                    );
                } else if (!skips.includes(key) || (key === "page_size" && !labelKeys.includes("is_map"))) {
                    return (
                        <Badge key={`${id}-${key}`} bg="secondary" className="me-2 mb-2">
                            {getTagDisplay(body, key)}
                        </Badge>
                    );
                } else {
                    return [];
                }
            })}
            {body.filters &&
                Object.entries(body.filters).map((filter: any) => {
                    // filter[0] is the key and filter[1] is the value
                    return (
                        <Badge key={`${id}`} bg="secondary" className="me-2 mb-2">
                            {getFilterTagDisplay(filter[0], filter[1], isMultiAgency)}
                        </Badge>
                    );
                })}
        </>
    );
};

export default SearchHistoryLabels;
