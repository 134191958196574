import { useGetUniqueBeatsQuery } from "../api/api";
import useSingleAgencyFilter from "./useSingleAgencyFilter";

/**
 * Queries for beats based on the selected agency(ies) & isSingleAgencyMode
 * @param isSingleAgencyMode
 * @param includeGeometry
 * @param skipBeatsQuery
 * @returns
 */
const useBeatsQueryWrapper = (isSingleAgencyMode: boolean, includeGeometry: boolean, skipBeatsQuery: boolean) => {
    const { selectedAgency } = useSingleAgencyFilter();

    // Insight Dashboards send the isSingleAgencyMode prop to force a single agency be set for all api calls and the uuid won't always
    // be present in the url queryParams so we need to utilize the selectedAgency from our useSingleAgency hook.
    const queryAgencies = selectedAgency && isSingleAgencyMode ? [selectedAgency.fm_nickname] : [];

    // fetch all of given agency beats
    const { data: beatsData, isFetching: beatsIsFetching } = useGetUniqueBeatsQuery(
        {
            filters: {
                agency_ids: queryAgencies,
            },
            include_geometry: includeGeometry,
        },
        { skip: skipBeatsQuery }
    );

    return {
        beatsData,
        beatsIsFetching,
        queryAgencies,
    };
};

export default useBeatsQueryWrapper;
