import { Row } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/date";
import { isAfter, isBefore, parseISO } from "date-fns";
import "./timeline.css";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import { useManageSearchParams } from "../../hooks/useManageSearchParams";
import FMSpinner from "../Spinner";
import NoResults from "../NoResults";

type OwnProps = {
    incidents?: any[];
    isLoading?: boolean;
};

export type TimelineItem = {
    id: string;
    occurred_at: string;
    yearOnly: boolean;
    monthOnly: boolean;
};

/**
 *
 * @param incidents array of incidents
 * @returns array containing sorted (by most recent to oldest) timeline items,
 *          with month and year tickets inserted up through the year of the
 *          most recent incident
 */
export const populateTimelineItems = (incidents: any[]) => {
    // only displaying incidents with occurred at date on timeline
    const incidentsWithOccurredAtDates = incidents.filter((incident) => !!incident.occurred_at);

    let incidentsCopy = [...incidentsWithOccurredAtDates];
    if (incidentsWithOccurredAtDates.length) {
        let oldestYear = new Date(incidentsWithOccurredAtDates[0].occurred_at).getUTCFullYear();
        let mostRecentIncidentDate = new Date(incidentsWithOccurredAtDates[0].occurred_at);

        // find oldest & newest event year to begin building month and year list
        incidentsWithOccurredAtDates?.forEach((incident: any) => {
            const tempDate = new Date(incident.occurred_at);
            const tempYear = tempDate.getUTCFullYear();
            if (tempYear < oldestYear) {
                oldestYear = tempYear;
            }
            if (isBefore(mostRecentIncidentDate, tempDate)) {
                mostRecentIncidentDate = tempDate;
            }
        });

        // loop through dates and build list of months and years to show time gaps
        let monthsAndYears: TimelineItem[] = [];
        let month = 0;

        while (oldestYear <= mostRecentIncidentDate.getUTCFullYear()) {
            monthsAndYears.push({
                occurred_at: new Date(`${oldestYear}-01-01`).toISOString(),
                yearOnly: true,
                monthOnly: false,
                id: `${oldestYear}-01-01`,
            });

            // add up to 12 month ticks (will stop when next tick would be after most recent incident)
            let newDate = new Date(oldestYear, month, 1);
            while (!isAfter(newDate, mostRecentIncidentDate) && month < 12) {
                let y = {
                    occurred_at: newDate.toISOString(),
                    yearOnly: false,
                    monthOnly: true,
                    id: `${oldestYear}-${month}`,
                };
                monthsAndYears.push(y);
                month += 1;
                newDate = new Date(oldestYear, month, 1);
            }
            month = 0;
            oldestYear++;
        }

        incidentsCopy = incidentsCopy?.concat(monthsAndYears);
    }

    return [...incidentsCopy].sort((a: any, b: any) => (isBefore(parseISO(a.occurred_at), parseISO(b.occurred_at)) ? 1 : -1));
};

const TimelineView = ({ incidents, isLoading }: OwnProps) => {
    const { constructEventUrl } = useMultiAgency();
    const { processSearchParams } = useManageSearchParams();

    const timelineItems = populateTimelineItems(incidents || []);

    return (
        <div id="timeline-container" data-testid="timeline-container">
            {isLoading && (
                <div className="text-center">
                    <FMSpinner />
                </div>
            )}
            {timelineItems.length > 0 && (
                <ul className="timeline h-100" data-testid="timeline-list">
                    {timelineItems?.map((incident, i) => {
                        const callNumber = incident?.call_number && incident?.call_number.length ? incident.call_number[0] : undefined;
                        const link = constructEventUrl(
                            incident?.agency_id,
                            callNumber,
                            incident?.incident_number,
                            processSearchParams(["query"])
                        );

                        return (
                            <React.Fragment key={incident.id}>
                                {!incident.yearOnly && !incident.monthOnly && (
                                    <>
                                        <span className="timeline-badge text-nowrap">{formatDate(incident.occurred_at)}</span>
                                        <li className="event">
                                            <Row>
                                                <Link to={link} id={`incident-label-${i}`} className="pendo_timeline_incident_link_button">
                                                    {incident.incident_type}
                                                </Link>
                                                {incident.full_address && <span>{incident.full_address}</span>}
                                                <span>{incident?.roles?.join(", ") || "--"}</span>
                                            </Row>
                                        </li>
                                    </>
                                )}
                                {incident.yearOnly && (
                                    <li className="year-tick" data-testid="year-tick">
                                        {incident.occurred_at && <h3>{new Date(incident.occurred_at).getUTCFullYear()}</h3>}
                                    </li>
                                )}
                                {incident.monthOnly && (
                                    <li className="month-tick" data-testid="month-tick">
                                        {incident.occurred_at && (
                                            <h4 className="text-secondary">
                                                {new Date(incident.occurred_at).toLocaleString("en-US", {
                                                    month: "long",
                                                })}
                                            </h4>
                                        )}
                                    </li>
                                )}
                            </React.Fragment>
                        );
                    })}
                </ul>
            )}
            {timelineItems.length === 0 && !isLoading && <NoResults text="No timeline incidents." />}
        </div>
    );
};

export default TimelineView;
