import React from "react";
import { useGetUniqueOffensesQuery } from "../../api/api";
import { processFilterAgg } from "../../utils/helpers";
import MultiSelectFilter from "./MultiSelectFilter";
import aggregationDataPresenceCount from "../../utils/aggregationDataPresenceCount";
import { byNameSort, TAggregationType } from "../../hooks/useSearchFiltersAggregations";

const OffensesFilterConnected = ({ offensesAggregation }: { offensesAggregation?: Array<TAggregationType> }) => {
    const { data: optionData } = useGetUniqueOffensesQuery({});
    const offenses = processFilterAgg(optionData);

    const options = React.useMemo(
        () =>
            offenses
                .map((offense: { id: string; option: string }) => {
                    const { present, count } = aggregationDataPresenceCount(offensesAggregation, offense?.id);

                    return {
                        id: offense?.id,
                        value: offense?.option,
                        name: offense?.option,
                        present,
                        count,
                    };
                })
                .sort(byNameSort),
        [offenses, offensesAggregation]
    );

    return (
        <MultiSelectFilter
            ariaLabel="Offenses"
            pendoClass="pendo_offenses_filter_select"
            options={options}
            urlParamName={"offenses"}
            inputPlaceholder={`Select Offenses`}
        />
    );
};

export default OffensesFilterConnected;
