import { useSearchParams } from "react-router-dom";
import { FilterParamNames } from "../constants/constants";
import { FilterParamNamesType } from "../types/types";
import React from "react";
import useBeatsQueryWrapper from "./useBeatsQueryWrapper";

const initialFilters = {
    beats: [],
};

type FilterProps = {
    isSingleAgencyMode?: boolean;
    includeGeometry?: boolean;
    skipBeatsQuery?: boolean;
};

export const useFilters = ({ isSingleAgencyMode = false, includeGeometry = false, skipBeatsQuery = false }: FilterProps) => {
    const { beatsData, beatsIsFetching, queryAgencies } = useBeatsQueryWrapper(isSingleAgencyMode, includeGeometry, skipBeatsQuery);
    const [searchParams, setSearchParams] = useSearchParams();
    const filterString = searchParams.get("filters");
    const filters = React.useMemo(() => {
        try {
            if (filterString) {
                return JSON.parse(filterString);
            } else {
                return initialFilters;
            }
        } catch (e) {
            return initialFilters;
        }
    }, [filterString]);

    const resetFilters = (defaultParamValues: { [key in FilterParamNamesType]?: string }) => {
        Object.values(FilterParamNames).forEach((filterName) => {
            searchParams.delete(filterName);
        });

        Object.entries(defaultParamValues).forEach(([paramName, paramValue]) => {
            searchParams.set(paramName, paramValue);
        });
        searchParams.delete("filters");
        setSearchParams(searchParams);
    };

    const updateFilters = (paramName: string, newValue: any, newSearchParams: URLSearchParams) => {
        let newFilters = { ...filters };
        // if new filter values is null remove key from filterObject before saving
        if (!newValue) {
            delete newFilters[paramName];
        } else {
            newFilters[paramName] = newValue;
        }

        // update url filterObject param or remove it if object is empty
        if (Object.keys(newFilters).length === 0) {
            newSearchParams.delete("filters");
        } else {
            newSearchParams.set("filters", JSON.stringify(newFilters));
        }

        setSearchParams(newSearchParams);
    };

    // Yields the filtered beats with ES docs included if geometry was included don't include it for the payload to the backend
    const fullFilteredBeats = React.useMemo(() => {
        if (filters?.beats?.length === 0) {
            return [];
        } else {
            return beatsData?.results
                .filter((beat: any) => {
                    return filters?.beats?.find(
                        (filterBeat: any) => filterBeat.agency_id === beat.agency_id && filterBeat.beat_name === beat.beat_name
                    );
                })
                .map((beat: any) => {
                    return includeGeometry
                        ? {
                              agency_desc: beat.agency_desc,
                              beat_name: beat.beat_name,
                              agency_id: beat.agency_id,
                              es_docs: beat.es_docs,
                          }
                        : beat;
                });
        }
    }, [beatsData?.results, filters?.beats, includeGeometry]);

    return {
        resetFilters,
        updateFilters,
        filters,
        filterPayload: { ...filters, beats: fullFilteredBeats, agency_ids: queryAgencies },
        beatsData,
        beatsIsFetching,
        // Beats selected by the user that correspond to the agency(ies) that have been selected in the Agency filter
        // to be used for display in FilterBar/Badge and mapbox layer filtering
        selectedBeats: fullFilteredBeats,
    };
};
