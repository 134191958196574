import React from "react";
import { useGetPeopleQuery } from "../api/api";
import { createGeoJson } from "../components/map/utils/gis";
import { MAP_BORDER_ACCENT, PERSON_ACCENT } from "../app/colors";
import { elasticHitsExtraction } from "../utils/elastic";
import { LayerTypes, SearchQueryParams, SourceConfig } from "../types/old_v1/types";
import { WHITE } from "../utils/colors";

export type PersonSearchLayerConfig = {
    personSourceConfig: SourceConfig | undefined;
    personFetching: boolean;
    personLoading: boolean;
    personHits: any;
    personCount: number;
    personUnpagedCount: number;
};

/**
 * Hook used for fetching and building an person search layer
 * @param {SearchQueryParams} searchQueryParams Query parameters used for calling search API.
 * @returns {PersonSearchLayerConfig}
 */

export const usePersonSearchLayer = (searchQueryParams: SearchQueryParams): PersonSearchLayerConfig => {
    const queryParams = { ...searchQueryParams };
    // override the parameters that could come from URL
    queryParams["selectedTab"] = "people";

    const [personSourceConfig, setPersonSourceConfig] = React.useState<SourceConfig | undefined>();
    const personResponse = useGetPeopleQuery(queryParams, {
        skip:
            queryParams.query === "" ||
            queryParams.query === null ||
            (queryParams.entity !== null && !queryParams?.entity?.split(",").includes(LayerTypes.person)),
    });

    React.useEffect(() => {
        if (personResponse && personResponse.data) {
            const hits = elasticHitsExtraction(personResponse.data);
            if (hits) {
                const config = preparePersonMapConfig(hits);
                setPersonSourceConfig(config);
            }
        }
    }, [personResponse]);

    return {
        personSourceConfig: personSourceConfig,
        personFetching: personResponse.isFetching,
        personLoading: personResponse.isLoading,
        personHits: personResponse.data ? elasticHitsExtraction(personResponse.data) : [],
        personCount: personResponse.data && personResponse.data.success ? personResponse.data.hits.hits.length : 0,
        personUnpagedCount: personResponse.data && personResponse.data.success ? personResponse.data.hits.total.value : 0,
    };
};

export const preparePersonMapConfig = (hits: Array<any>, pinnedResult?: any, cluster: boolean = false) => {
    const personGeoJson = createGeoJson(hits, "people");
    const config: SourceConfig = {
        data: personGeoJson,
        id: LayerTypes.person,
        setBounds: true,
        cluster: cluster,
        layers: [
            {
                id: "person-layer",
                type: "circle",
                source: "person-layer",
                paint: {
                    "circle-color": PERSON_ACCENT,
                    "circle-radius": 8,
                    "circle-stroke-color": ["case", ["==", ["get", "id"], pinnedResult?.id || ""], WHITE, MAP_BORDER_ACCENT],
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": 0.7,
                },
            },
            {
                id: "person-layer-cluster",
                type: "circle",
                source: "person-layer",
                filter: ["has", "point_count"],
                paint: {
                    "circle-color": PERSON_ACCENT,
                    "circle-stroke-color": MAP_BORDER_ACCENT,
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": 0.7,
                    "circle-radius": ["step", ["get", "point_count"], 10, 20, 20, 30, 30, 40, 40, 50, 50],
                },
            },
            {
                id: "person-layer-cluster-count",
                type: "symbol",
                source: "person-layer",
                filter: ["has", "point_count"],
                layout: {
                    "text-field": "{point_count}",
                    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                    "text-size": 12,
                },
                paint: {
                    "text-color": "white",
                },
            },
        ],
    };
    return config;
};
