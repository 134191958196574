import { useWalmart } from "../hooks/useWalmart";

/* Keys */
// Red
export const GUN_KEY = "gun";
export const KNIFE_KEY = "knife";
export const NIBIN_KEY = "nibin";
export const AFIS_KEY = "afis";
export const GANG_KEY = "gang";

// Orange
export const DRUG_ABUSE_KEY = "drug_abuse";
export const NARCAN_KEY = "narcan";

// Purple
export const MENTAL_HEALTH_KEY = "mental_health";
// Blue-ish
export const AUTISM_KEY = "autistic";
export const DEMENTIA_KEY = "dementia";
export const PTSD_KEY = "ptsd";
export const SUICIDAL_KEY = "suicidal";

// Green
export const HOMELESS_KEY = "homeless";
// Military Green
export const MILITARY_KEY = "military";
// Brown
export const DV_KEY = "dv";
export const STUDENT_KEY = "student";
export const CODIS_KEY = "codis";
// Light Green
export const DEAF_KEY = "deaf";

// Gray
export const BODY_CAMERA_KEY = "body_camera";

// Brand Colors
export const WALMART_KEY = "walmart";
export const SAMS_CLUB_KEY = "sams_club";

/* Display Names */
const GUN_NAME = "Gun Mentioned";
const KNIFE_NAME = "Knife Mentioned";
const NIBIN_NAME = "NIBIN";
const AFIS_NAME = "AFIS";
const GANG_NAME = "Gang Mentioned";

const DRUG_ABUSE_NAME = "Alcohol/Drugs";
const NARCAN_NAME = "Narcan";

const MENTAL_HEALTH_NAME = "Mental Health";
const AUTISM_NAME = "Autism";
const DEMENTIA_NAME = "Dementia";
const PTSD_NAME = "PTSD";
const SUICIDAL_NAME = "Suicidal";

const HOMELESS_NAME = "Homelessness";
const MILITARY_NAME = "Military";
const DV_NAME = "Domestic Violence";
const STUDENT_NAME = "Student";
const CODIS_NAME = "CODIS";
const DEAF_NAME = "Deaf";

const BODY_CAMERA_NAME = "Body Camera";

const WALMART_NAME = "Walmart";
const SAMS_CLUB_NAME = "Sam's Club";

export const getTagName = (tag: string) => {
    switch (tag) {
        case MENTAL_HEALTH_KEY:
            return MENTAL_HEALTH_NAME;
        case DRUG_ABUSE_KEY:
            return DRUG_ABUSE_NAME;
        case HOMELESS_KEY:
            return HOMELESS_NAME;
        case MILITARY_KEY:
            return MILITARY_NAME;
        case GUN_KEY:
            return GUN_NAME;
        case KNIFE_KEY:
            return KNIFE_NAME;
        case DV_KEY:
            return DV_NAME;
        case CODIS_KEY:
            return CODIS_NAME;
        case NIBIN_KEY:
            return NIBIN_NAME;
        case AFIS_KEY:
            return AFIS_NAME;
        case DEAF_KEY:
            return DEAF_NAME;
        case AUTISM_KEY:
            return AUTISM_NAME;
        case SUICIDAL_KEY:
            return SUICIDAL_NAME;
        case DEMENTIA_KEY:
            return DEMENTIA_NAME;
        case PTSD_KEY:
            return PTSD_NAME;
        case GANG_KEY:
            return GANG_NAME;
        case NARCAN_KEY:
            return NARCAN_NAME;
        case STUDENT_KEY:
            return STUDENT_NAME;
        case BODY_CAMERA_KEY:
            return BODY_CAMERA_NAME;
        case WALMART_KEY:
            return WALMART_NAME;
        case SAMS_CLUB_KEY:
            return SAMS_CLUB_NAME;
        default:
            return tag;
    }
};

export const getTagKey = (tag: string) => {
    switch (tag) {
        case MENTAL_HEALTH_NAME:
            return MENTAL_HEALTH_KEY;
        case DRUG_ABUSE_NAME:
            return DRUG_ABUSE_KEY;
        case HOMELESS_NAME:
            return HOMELESS_KEY;
        case MILITARY_NAME:
            return MILITARY_KEY;
        case GUN_NAME:
            return GUN_KEY;
        case KNIFE_NAME:
            return KNIFE_KEY;
        case DV_NAME:
            return DV_KEY;
        case CODIS_NAME:
            return CODIS_KEY;
        case NIBIN_NAME:
            return NIBIN_KEY;
        case AFIS_NAME:
            return AFIS_KEY;
        case DEAF_NAME:
            return DEAF_KEY;
        case AUTISM_NAME:
            return AUTISM_KEY;
        case SUICIDAL_NAME:
            return SUICIDAL_KEY;
        case DEMENTIA_NAME:
            return DEMENTIA_KEY;
        case PTSD_NAME:
            return PTSD_KEY;
        case GANG_NAME:
            return GANG_KEY;
        case NARCAN_NAME:
            return NARCAN_KEY;
        case STUDENT_NAME:
            return STUDENT_KEY;
        case BODY_CAMERA_NAME:
            return BODY_CAMERA_KEY;
        case WALMART_NAME:
            return WALMART_KEY;
        case SAMS_CLUB_NAME:
            return SAMS_CLUB_KEY;
        default:
            return tag;
    }
};

export const TAG_DETAILS = (bgOpacity = "0.5") => [
    {
        id: "1",
        name: getTagName(GUN_KEY),
        code: GUN_KEY,
        color: `rgba(241, 145, 145, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "2",
        name: getTagName(KNIFE_KEY),
        code: KNIFE_KEY,
        color: `rgba(241, 145, 145, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "3",
        name: getTagName(NIBIN_KEY),
        code: NIBIN_KEY,
        color: `rgba(216, 188, 102, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "4",
        name: getTagName(AFIS_KEY),
        code: AFIS_KEY,
        color: `rgba(216, 188, 102, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "5",
        name: getTagName(GANG_KEY),
        code: GANG_KEY,
        color: `rgba(241, 145, 145, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "6",
        name: getTagName(DRUG_ABUSE_KEY),
        code: DRUG_ABUSE_KEY,
        color: `rgba(227, 150, 85, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "7",
        name: getTagName(NARCAN_KEY),
        code: NARCAN_KEY,
        color: `rgba(227, 150, 85, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "8",
        name: getTagName(MENTAL_HEALTH_KEY),
        code: MENTAL_HEALTH_KEY,
        color: `rgba(160, 125, 193, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "9",
        name: getTagName(AUTISM_KEY),
        code: AUTISM_KEY,
        color: `rgba(143, 125, 193, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "10",
        name: getTagName(DEMENTIA_KEY),
        code: DEMENTIA_KEY,
        color: `rgba(160, 125, 193, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "11",
        name: getTagName(PTSD_KEY),
        code: PTSD_KEY,
        color: `rgba(160, 125, 193, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "12",
        name: getTagName(SUICIDAL_KEY),
        code: SUICIDAL_KEY,
        color: `rgba(160, 125, 193, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "13",
        name: getTagName(HOMELESS_KEY),
        code: HOMELESS_KEY,
        color: `rgba(185, 163, 127, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "14",
        name: getTagName(MILITARY_KEY),
        code: MILITARY_KEY,
        color: `rgba(124, 129, 95, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "15",
        name: getTagName(DV_KEY),
        code: DV_KEY,
        color: `rgba(241, 145, 145, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "17",
        name: getTagName(STUDENT_KEY),
        code: STUDENT_KEY,
        color: `rgba(185, 163, 127, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "18",
        name: getTagName(CODIS_KEY),
        code: CODIS_KEY,
        color: `rgba(216, 188, 102, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "19",
        name: getTagName(DEAF_KEY),
        code: DEAF_KEY,
        color: `rgba(76, 106, 170, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "20",
        name: getTagName(WALMART_KEY),
        code: WALMART_KEY,
        color: `rgba(0, 113, 206, ${bgOpacity})`,
        isFM: true,
    },
    {
        id: "21",
        name: getTagName(SAMS_CLUB_KEY),
        code: SAMS_CLUB_KEY,
        color: `rgba(93, 151, 50, ${bgOpacity})`,
        isFM: true,
    },
];

const TagDetails = () => {
    const { isWalmart } = useWalmart();
    let tagDetails = [...TAG_DETAILS()];

    // If not walmart, we want to filter out the Walmart & Sam Club's tags, so they won't exist for other clients in the tagDetails list.
    // If is walmart, we want the Walmart and Sam's Club tags to exist in the tagDetails list.
    if (!isWalmart) {
        tagDetails = tagDetails.filter((tag: any) => tag.code !== WALMART_KEY && tag.code !== SAMS_CLUB_KEY);
    }
    return tagDetails;
};

/**
 * Returns an array of tag objects based on the provided tag codes, effectively filtering out any invalid tags
 * @param tags array of tag codes
 * @returns array of tag objects
 */
export const getValidatedLabels = (selectedTags: string[]) => {
    const tagDetails = TagDetails();
    return Array.isArray(selectedTags) && selectedTags.length
        ? tagDetails.filter((tagObject) => selectedTags.includes(tagObject.code))
        : [];
};

export default TagDetails;
