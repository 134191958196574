import { Card } from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import { useSearchParams } from "react-router-dom";
import { useGetOfficerIncidentsByHourQuery } from "../../../api/api";
import { getQueryParams } from "../../../utils/url";
import InsightsSpinner from "../../insightsdashboard/insightsspinner/InsightsSpinner";
import { useDarkMode } from "../../../hooks/useDarkMode";
import { handleHighChartDarkMode } from "../../../utils/helpers";
import { getHeatmapMax, heatmapBaseConfig, heatmapStops, initializeHighchartsHeatmap } from "../../insightsdashboard/heatmap";
import { getDayString } from "../../../utils/date";
import { getByHourSearchParams } from "../../insightsdashboard/insights";
import useAgencySettings from "../../../hooks/useAgencySettings";
import { useFilters } from "../../../hooks/useFilters";

const initializedHighcharts = initializeHighchartsHeatmap();

const IncidentsByHour = () => {
    const [searchParams] = useSearchParams();
    const queryParams = getQueryParams(searchParams);
    const { beatsIsFetching, filterPayload } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });
    const {
        data: incidentsByHour,
        isLoading,
        isFetching,
    } = useGetOfficerIncidentsByHourQuery(
        {
            ...queryParams,
            filters: filterPayload,
        },
        { skip: beatsIsFetching }
    );
    const { incidentTextPlural } = useAgencySettings();
    let days = [] as string[];
    const { isDarkMode } = useDarkMode();
    let latestDateString: string | null = null;

    let processedData = null;
    if (incidentsByHour && !isLoading) {
        const buckets = incidentsByHour?.data?.results;

        if (buckets && buckets.length) {
            latestDateString = buckets[buckets.length - 1]?.key_as_string?.split(" ")[0];
            processedData = buckets.map((bucket: any) => {
                const parts = bucket.key_as_string.split(" ");
                const dayString = getDayString(new Date(parts[0]).getUTCDay());
                if (!days.includes(dayString)) {
                    days.push(dayString);
                }
                const day = days.length - 1;
                const hour = parseInt(parts[1]);
                return [hour, day, bucket.doc_count];
            });
        }
    }

    const options = {
        chart: {
            type: "heatmap",
            plotBorderWidth: 1,
            rowSize: 500,
            colSize: 500,
        },
        legend: false,
        title: {
            text: "",
        },
        colorAxis: {
            stops: heatmapStops,
            max: getHeatmapMax(processedData),
        },
        xAxis: {
            min: 0,
            max: 23,
            tickInterval: 1,
            title: {
                text: "Hour",
            },
        },
        yAxis: {
            categories: days,
            title: {
                text: "Days",
            },
            reversed: true,
        },
        tooltip: {
            formatter(this: any) {
                return `<b>${incidentTextPlural}: ${this.point && this.point.value}</b>`;
            },
            shared: true,
        },
        series: [
            {
                ...heatmapBaseConfig,
                name: incidentTextPlural,
                data: processedData,
                point: {
                    events: {
                        click: (e: any) => {
                            const newSearchParams = getByHourSearchParams(e, latestDateString, searchParams);
                            newSearchParams.set("indexType", "incidents");
                            window.open(`/app/search?${newSearchParams}`, "_blank");
                        },
                    },
                },
            },
        ],
    };

    handleHighChartDarkMode(options, isDarkMode);

    return (
        <Card>
            <Card.Header>
                <Card.Title className="m-0" as="h3">
                    {incidentTextPlural} Per Day and Hour
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {isFetching ? (
                    <InsightsSpinner />
                ) : (
                    processedData && <HighchartsReact highcharts={initializedHighcharts} options={options} />
                )}
            </Card.Body>
        </Card>
    );
};

export default IncidentsByHour;
