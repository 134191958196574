import React from "react";
import { Form, InputGroup, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrashUndo } from "@fortawesome/pro-light-svg-icons";
import { useSearchParams } from "react-router-dom";

const RadarSearch = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [query, setQuery] = React.useState<any>(searchParams.get("query") || "");

    React.useEffect(() => {
        setQuery(searchParams.get("query") || "");
    }, [searchParams]);

    const onChange = (e: any) => {
        setQuery(e.target.value);
    };

    const submitForm = (q: any) => {
        let updatedSearchParams = new URLSearchParams(searchParams.toString());
        if (q) {
            updatedSearchParams.set("query", q);
        } else {
            updatedSearchParams.delete("query");
        }
        setSearchParams(updatedSearchParams.toString());
    };

    const clearSearch = () => {
        setQuery("");
        submitForm("");
    };

    return (
        <ListGroup>
            <ListGroup.Item>
                <Form
                    onSubmit={(e) => e.preventDefault()}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            submitForm(query);
                        }
                    }}
                >
                    <InputGroup size="sm">
                        <Form.Control
                            type="text"
                            name="query"
                            placeholder="Filter results by keyword"
                            className="form-control shadow-none ms-n3 border-0"
                            onChange={onChange}
                            autoComplete="off"
                            value={query}
                            style={{ borderRight: "none" }}
                        />
                        <InputGroup.Text id="btnGroupAddon" className="bg-body border-0 header-search-icons">
                            <div className="d-flex ms-3 me-n3 cursor-pointer align-items-center" onClick={() => submitForm(query)}>
                                <FontAwesomeIcon style={{ opacity: 0.9 }} className="text-primary" icon={faSearch} />
                                <span
                                    style={{ opacity: 0.9 }}
                                    className="d-none d-md-inline ms-2 fs-4 text-primary fw-bold pendo-radar-filter-results-search"
                                >
                                    Search
                                </span>
                            </div>
                            <div
                                className={`d-flex align-items-center ms-3 me-n3 ${
                                    !!query ? "text-primary cursor-pointer" : "text-muted cursor-not-allowed"
                                }`}
                                onClick={clearSearch}
                            >
                                <FontAwesomeIcon style={{ opacity: 0.9, marginLeft: ".5em" }} icon={faTrashUndo} />
                                <span
                                    style={{ opacity: 0.9 }}
                                    className="d-none d-md-inline ms-2 fs-4 fw-bold pendo-radar-filter-results-clear"
                                >
                                    Clear
                                </span>
                            </div>
                        </InputGroup.Text>
                    </InputGroup>
                </Form>
            </ListGroup.Item>
        </ListGroup>
    );
};

export default RadarSearch;
