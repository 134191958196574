import React, { useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faCheckCircle, faCircleXmark, faPencil, faPlusCircle, faTrash } from "@fortawesome/pro-light-svg-icons";
import { Alert, Button, Card, Col, Container, Form, Modal, Row, Stack, ToggleButton } from "react-bootstrap";
import ModalHeader from "../../components/ModalHeader";
import { useDarkMode } from "../../hooks/useDarkMode";
import { useAppSelector } from "../../app/hooks";
import {
    useDeleteKnowledgeBaseArticleMutation,
    useGetKnowledgeBaseQuery,
    useGetUserAgenciesQuery,
    useSaveKnowledgeBaseArticleMutation,
    useUpdateKnowledgeBaseArticleMutation,
} from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../utils/date";
import icon from "../../static/img/logo_white.png";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import usePermissions from "../../hooks/usePermissions";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";

type Article = {
    title?: string;
    body: string;
    author?: string;
    saved_date?: string;
    id?: string;
    status?: string;
    agencies?: any[];
};

const emptyArticle = { title: "", body: "", author: "", id: "" };

interface ownProps {
    data?: Article[];
    agencies?: any[] | undefined;
    redirectFilter?: boolean;
}

const KnowledgeBaseConnected = () => {
    const { data } = useGetKnowledgeBaseQuery({}, { refetchOnMountOrArgChange: true });
    const { data: agencyData } = useGetUserAgenciesQuery({});
    const { filter } = useParams();
    const navigate = useNavigate();
    const { hasPermission } = usePermissions();

    //Redirect based on permissions to avoid showing error page
    if (filter !== "public" && !hasPermission("knowledge_base_edit")) {
        navigate(`../knowledge-base/public`);
        return <KnowledgeBase data={data && data.results} agencies={agencyData && agencyData.results} redirectFilter />;
    }
    return <KnowledgeBase data={data && data.results} agencies={agencyData && agencyData.results} />;
};

export const KnowledgeBase = ({ data, agencies, redirectFilter }: ownProps) => {
    useSetPageTitle("ForceMetrics | KnowledgeBase");
    const { isDarkMode } = useDarkMode();
    const { articleId, filter } = useParams();
    const { hasPermission } = usePermissions();
    const [body, setBody] = React.useState("**Enter text here**");
    const [title, setTitle] = React.useState("");
    const [article, setArticle] = React.useState<Article>(emptyArticle);
    const [showCreate, setShowCreate] = React.useState(false);
    const [showEdit, setShowEdit] = React.useState(false);
    const [showApprove, setShowApprove] = React.useState(false);
    const [showDelete, setShowDelete] = React.useState(false);
    const [articleFilterString, setArticleFilterString] = React.useState("");
    const [showAlert, setShowAlert] = useState(false); // alert messages
    const [alertMessage, setAlertMessage] = useState<any>(null);
    const [selectedAgencies, setSelectedAgencies] = useState<any[]>([]);
    const user = useAppSelector(({ user }) => user.userObject);

    const [saveFn] = useSaveKnowledgeBaseArticleMutation();
    const [deleteFn] = useDeleteKnowledgeBaseArticleMutation();
    const [editFn] = useUpdateKnowledgeBaseArticleMutation();

    const navigate = useNavigate();

    const getRadios = () => {
        const radios = [];
        hasPermission("knowledge_base_view") && radios.push({ name: "Public", value: "public" });
        (hasPermission("knowledge_base_approve") || hasPermission("knowledge_base_edit")) &&
            radios.push({ name: "Drafts", value: "draft" });
        hasPermission("knowledge_base_edit") && radios.push({ name: "Archived", value: "archived" });

        return radios;
    };

    const radios = getRadios();

    React.useEffect(() => {
        const findArticleWithStatus = (data: any, status: string) => {
            return data.find((article: Article) =>
                article.id && articleId ? article.id.toString() === articleId && article.status === status : article.status === status
            );
        };

        if (data && data.length) {
            let foundArticle: Article | undefined;
            if (filter) {
                if (filter === "public") {
                    foundArticle = data.find((article) =>
                        article.id && articleId
                            ? article?.id.toString() === articleId && ["public_all", "public_some"].includes(article.status as string)
                            : ["public_all", "public_some"].includes(article.status as string)
                    );
                } else {
                    foundArticle = findArticleWithStatus(data, filter);
                }
            }

            setArticle(foundArticle || emptyArticle);
        }
    }, [filter, articleId, data, navigate]);

    const submitKnowledge = () => {
        if (title === "" || body === "") {
            validateArticle();
        } else {
            saveFn({ title, body, agencies: selectedAgencies }).then((response: any) => {
                navigate(`../knowledge-base/${filter}/${response.data.results.id}`);
            });
            closeModal(setShowCreate);
        }
    };

    const deleteKnowledge = () => {
        deleteFn({ title: article.title, id: article.id });
        setShowDelete(false);
        navigate(`../knowledge-base/${filter}`);
    };

    const showEditModal = (showFn: Function) => {
        setTitle(article.title || "");
        setBody(article.body);
        showFn(true);
        setSelectedAgencies([...(article.agencies || [])]);
    };

    const editKnowledge = (showFn: Function, isEdit: boolean) => {
        if (title === "" || body === "") {
            validateArticle();
        } else {
            setArticle({ title, body, author: filter === "draft" ? article.author : user.full_name });
            editFn({ title, body, id: article.id, agencies: selectedAgencies, isEdit: isEdit });
            closeModal(showFn);
        }
    };

    const validateArticle = () => {
        switch (title === "" || body === "") {
            case title === "" && body !== "":
                setAndShowAlert("Missing Required Fields", `Title is a required field`, "danger");
                break;
            case title !== "" && body === "":
                setAndShowAlert("Missing Required Fields", `Body is a required field`, "danger");
                break;
            default:
                setAndShowAlert("Missing Required Fields", `Title and Body are required fields`, "danger");
        }
    };

    const articleClick = (article: Article) => {
        setArticle(article);
        navigate(`../knowledge-base/${filter}/${article.id}`);
    };

    const closeModal = (showFunc: Function) => {
        showFunc(false);
        setTitle("");
        setBody("**Enter text here**");
        setSelectedAgencies([]);
    };

    const filterArticleList = (event: any) => {
        setArticleFilterString(event.target.value);
    };

    const setAndShowAlert = (title: string, message: string, variant: string) => {
        setAlertMessage({ title, message, variant });
        setShowAlert(true);
    };

    const handleChange = (name: string) => {
        const found = agencies?.find((sel) => sel.agency_name.toString() === name);
        if (found) {
            setSelectedAgencies([...selectedAgencies, ...[found]]);
        }
    };
    const setDisabled = (name: string) => !!selectedAgencies.find((sel) => sel.agency_name === name);

    const removeAgency = (agency: any) => {
        const found = selectedAgencies?.findIndex((sel) => sel.agency_name === agency.agency_name);

        if (found > -1 && selectedAgencies) {
            let newList = selectedAgencies;
            newList.splice(found, 1);
            setSelectedAgencies([...newList]);
        }
    };

    let filteredArticles = (data && data.length && Array.isArray(data) ? [...data] : [])
        .filter((article) => {
            if (!articleFilterString) {
                return true;
            }

            const lowercaseFilter = articleFilterString.toLowerCase();
            return article?.title?.toLowerCase().includes(lowercaseFilter) || article?.author?.toLowerCase().includes(lowercaseFilter);
        })
        .filter((article) => {
            if (filter === "public") {
                return ["public_all", "public_some"].includes(article.status as string);
            } else {
                return article.status === filter;
            }
        });

    const filterChange = (newFilter: string) => {
        navigate(`../knowledge-base/${newFilter}`);
    };

    const AgencySelector = () => {
        return (
            <Row>
                <Col xs={4}>
                    <h3>Assign Agencies:</h3>
                    <Form.Select onChange={(e) => handleChange(e.target.value)}>
                        <option>Choose...</option>
                        {agencies?.map((option) => (
                            <option key={option.agency_name} value={option.agency_name} disabled={setDisabled(option.agency_name)}>
                                {option.agency_name}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col xs={2}></Col>
                <Col>
                    <h3>Agencies selected:</h3>
                    {selectedAgencies &&
                        selectedAgencies.map((agency) => {
                            return (
                                <span
                                    key={agency.agency_name}
                                    className="d-inline-block badge rounded-pill me-2 mb-1 align-content-bottom"
                                    style={{ backgroundColor: "grey" }}
                                >
                                    <div className="mt-1 me-1">
                                        <img
                                            src={icon}
                                            alt="ForceMetrics Logo"
                                            style={{ height: "18px" }}
                                            className="me-1 mt-n1 d-print-none"
                                        />
                                        {agency.agency_name}
                                        <FontAwesomeIcon
                                            size="sm"
                                            icon={faCircleXmark}
                                            className="cursor-pointer ms-1"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                removeAgency(agency);
                                            }}
                                        />
                                    </div>
                                </span>
                            );
                        })}
                    {selectedAgencies.length === 0 && <span>Visible to all</span>}
                </Col>
            </Row>
        );
    };

    return (
        <Container className="mb-3" style={{ marginTop: "1em" }} fluid>
            <Row className="d-flex align-items-center">
                <Col>
                    <h2 data-testid="knowledge-title">
                        <FontAwesomeIcon icon={faBook} />
                        &nbsp;Knowledge Base
                    </h2>
                </Col>
                <Col className="d-flex justify-content-end">
                    {radios.length > 1 && (
                        <ButtonGroup className="me-2">
                            {radios.map((radio) => (
                                <ToggleButton
                                    key={radio.value}
                                    id={`radio-${radio.value}`}
                                    type="radio"
                                    variant={filter === radio.value ? "primary" : "outline-primary"}
                                    name={`radio-${radio.value}`}
                                    value={radio.value}
                                    checked={filter === radio.value}
                                    onChange={(e) => filterChange(e.currentTarget.value)}
                                    className="d-flex align-items-center"
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    )}
                    {hasPermission("knowledge_base_create") && (
                        <Button onClick={() => setShowCreate(true)}>
                            Create New <FontAwesomeIcon icon={faPlusCircle as any} className="mt-1 cursor-pointer" />
                        </Button>
                    )}
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={2}>
                    <Card className="mt-3">
                        <Card.Header>
                            <h3 className="ms-1">Article List</h3>
                            <input className="mb-1 form-control" placeholder="Filter by Title or Author" onChange={filterArticleList} />
                        </Card.Header>
                        <Card.Body>
                            {data &&
                                filteredArticles.map((article: Article) => {
                                    return (
                                        <React.Fragment key={article.id}>
                                            <h3
                                                className={"mb-0 cursor-pointer text-primary text-decoration-underline"}
                                                onClick={() => articleClick(article)}
                                                role="link"
                                            >
                                                {article.title}
                                            </h3>
                                            <p className="mb-2">by {article.author}</p>
                                        </React.Fragment>
                                    );
                                })}
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    {article && article.title && (
                        <Card className="mt-3">
                            <Card.Header>
                                <Row className="d-flex align-items-center">
                                    <Col>
                                        <h3 data-testid="article-title">{article.title} </h3>
                                        <span>
                                            by {article.author} at {formatDate(article.saved_date || "")}
                                        </span>
                                    </Col>
                                    <Col>
                                        {hasPermission("knowledge_base_edit") && (
                                            <Button variant="danger" className="float-end" onClick={() => setShowDelete(true)}>
                                                Delete
                                                <FontAwesomeIcon icon={faTrash as any} className="ms-2" />
                                            </Button>
                                        )}
                                        {hasPermission("knowledge_base_edit") && (
                                            <Button variant="success" className="me-3 float-end" onClick={() => showEditModal(setShowEdit)}>
                                                Edit
                                                <FontAwesomeIcon icon={faPencil} className="ms-2" />
                                            </Button>
                                        )}
                                        {filter === "draft" && hasPermission("knowledge_base_approve") && (
                                            <Button
                                                variant="success"
                                                className="me-3 float-end"
                                                onClick={() => showEditModal(setShowApprove)}
                                                disabled={filter === "draft" && article.author === user.full_name}
                                            >
                                                Approve
                                                <FontAwesomeIcon icon={faCheckCircle} className="ms-2" />
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <MDEditor.Markdown
                                    className={isDarkMode ? "dark-markdown" : ""}
                                    wrapperElement={{ "data-color-mode": isDarkMode ? "dark" : "light" }}
                                    source={article.body}
                                    style={{ whiteSpace: "pre-wrap" }}
                                />
                            </Card.Body>
                        </Card>
                    )}
                </Col>
            </Row>

            <Modal show={showCreate} onHide={() => closeModal(setShowCreate)} size="xl" scrollable={true}>
                <ModalHeader title="Create new knowledge base article" />
                {showAlert ? (
                    <Alert className="mt-3" variant={alertMessage.variant} onClose={() => setShowAlert(false)} dismissible>
                        <Alert.Heading>{alertMessage.title}</Alert.Heading>
                        <p>{alertMessage.message}</p>
                    </Alert>
                ) : null}
                <Modal.Body className="px-0 d-flex flex-grow-1">
                    <Container fluid className="d-flex flex-grow-1">
                        <Stack gap={3}>
                            <Stack direction={"horizontal"} gap={3}>
                                <h3>Title:</h3>

                                <input autoFocus={true} className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </Stack>
                            <Row className="d-flex flex-grow-1 overflow-hidden">
                                <Col>
                                    <h3>Body:</h3>
                                </Col>
                                <Col>
                                    <h3>Preview:</h3>
                                </Col>
                                <MDEditor
                                    className="h-100"
                                    data-color-mode={isDarkMode ? "dark" : "light"}
                                    value={body}
                                    onChange={(newValue) => setBody(newValue || "")}
                                />
                            </Row>
                            <AgencySelector />
                        </Stack>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} className={"mt-3 float-end"} onClick={submitKnowledge}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={filter !== "drafts" && showEdit} onHide={() => closeModal(setShowEdit)} size="xl" scrollable={true}>
                <ModalHeader title="Edit existing knowledge base article" />
                {showAlert ? (
                    <Alert className="mt-3" variant={alertMessage.variant} onClose={() => setShowAlert(false)} dismissible>
                        <Alert.Heading>{alertMessage.title}</Alert.Heading>
                        <p>{alertMessage.message}</p>
                    </Alert>
                ) : null}
                <Modal.Body className="px-0 d-flex flex-grow-1">
                    <Container fluid className="d-flex flex-grow-1">
                        <Stack gap={3}>
                            <Stack direction={"horizontal"} gap={3}>
                                <h3>Title:</h3>

                                <input className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </Stack>
                            <Row className="d-flex flex-grow-1 overflow-hidden">
                                <Col>
                                    <h3>Body:</h3>
                                </Col>
                                <Col>
                                    <h3>Preview:</h3>
                                </Col>
                                <MDEditor
                                    className="h-100"
                                    data-color-mode={isDarkMode ? "dark" : "light"}
                                    value={body}
                                    onChange={(newValue) => setBody(newValue || "")}
                                />
                            </Row>
                            <AgencySelector />
                        </Stack>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} className={"mt-3 float-end"} onClick={() => editKnowledge(setShowEdit, true)}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={filter === "draft" && showApprove} onHide={() => closeModal(setShowApprove)}>
                <ModalHeader title="Approve draft article" />
                <Modal.Body>Are you sure you would like to approve this article?</Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} className={"mt-3 float-end"} onClick={() => editKnowledge(setShowApprove, false)}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDelete} onHide={() => setShowDelete(false)}>
                <ModalHeader title="Delete knowledge base article" />
                <Modal.Body>Are you sure you would like to delete this article?</Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} className={"mt-3 float-end"} onClick={deleteKnowledge}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default KnowledgeBaseConnected;
