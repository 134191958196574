import { useMultiAgency } from "./useMultiAgency";
import useSingleAgencyFilter from "./useSingleAgencyFilter";

const useInsights = () => {
    const { searchParams } = useSingleAgencyFilter();
    const { isMultiAgency } = useMultiAgency();
    const updatedSearchParams = new URLSearchParams(searchParams);

    if (isMultiAgency && updatedSearchParams.get("agencyUUID")) {
        updatedSearchParams.set("agencies", updatedSearchParams.get("agencyUUID") || "");
        updatedSearchParams.delete("agencyUUID");
    }

    return {
        searchParams: updatedSearchParams,
    };
};

export default useInsights;
