import { useMultiAgency } from "../../hooks/useMultiAgency";
import MultiSelectFilter from "./MultiSelectFilter";

const AgencyFilter = () => {
    const { userSubAgencies } = useMultiAgency();

    return (
        <MultiSelectFilter
            pendoClass="pendo_agencies_filter_modal_select"
            options={userSubAgencies.map((agency: any) => ({ value: agency?.fm_uuid, name: agency?.name, label: agency?.name }))}
            urlParamName={"agencies"}
            inputPlaceholder="Select agencies to filter by"
        />
    );
};

export default AgencyFilter;
