import React from "react";
import TagDetails from "../../utils/TagDetails";
import MultiSelectFilter from "./MultiSelectFilter";
import { Form, Stack } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { byNameSort, TAggregationType } from "../../hooks/useSearchFiltersAggregations";
import aggregationDataPresenceCount from "../../utils/aggregationDataPresenceCount";

type TTagsFilter = {
    labelAggregation?: Array<TAggregationType>;
};

const TagsFilter = ({ labelAggregation }: TTagsFilter) => {
    const tags = TagDetails();
    const [searchParams, setSearchParams] = useSearchParams();

    // gets the options from index
    const options = React.useMemo(
        () =>
            tags
                .map((tag) => {
                    const { present, count } = aggregationDataPresenceCount(labelAggregation, tag.code);

                    return {
                        id: tag.id,
                        value: tag.code,
                        name: tag.name,
                        label: tag.name,
                        present,
                        count,
                    };
                })
                .sort(byNameSort),
        [labelAggregation, tags]
    );

    const toggleAllTags = (radioValue: boolean) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (!radioValue) {
            newSearchParams.delete("hasAllTags");
        } else {
            newSearchParams.set("hasAllTags", "true");
        }
        setSearchParams(newSearchParams);
    };

    return (
        <>
            <Stack direction="horizontal" gap={5}>
                <Form.Check
                    type={"radio"}
                    onChange={() => toggleAllTags(false)}
                    checked={!searchParams.get("hasAllTags")}
                    label="Any of"
                    className="pendo_has_any_tags_filter_radio"
                    id={`any-tags-radio`}
                    disabled={options.length === 0}
                />
                <Form.Check
                    type={"radio"}
                    onChange={() => toggleAllTags(true)}
                    checked={!!searchParams.get("hasAllTags")}
                    label="All of"
                    className="pendo_has_all_tags_filter_radio"
                    id={`all-tags-radio`}
                    disabled={options.length === 0}
                />
            </Stack>
            <MultiSelectFilter
                pendoClass="pendo_tags_filter_select"
                options={options}
                urlParamName={"tags"}
                inputPlaceholder={"Select Labels"}
            />
        </>
    );
};

TagsFilter.displayName = "TagsFilter";
export default TagsFilter;
