import { MapLayerMouseEvent } from "react-map-gl";
import { setPopupProperties, setShowPopup } from "../../../app/slices/map/mapSlice";
import { LayerTypes, PopupProperties } from "../../../types/old_v1/types";
import { validateCoordinates } from "../utils/gis";
import { defaultMobileSizePx } from "../../../constants/constants";

export const filterUniqueFeatures = (featureArray: any[]) => {
    let uniqueSet = new Set();
    return featureArray.filter((feature) => {
        const id = feature.properties.id ? feature.properties.id : feature.properties.address_id;

        return id && !id.includes("beat") && !uniqueSet.has(id) && uniqueSet.add(id);
    });
};

export const getUniqueFeatures = (event: MapLayerMouseEvent) => {
    const { features } = event;

    let uniqFeatures = features && filterUniqueFeatures(features);

    if (!uniqFeatures && Array.isArray(event)) {
        uniqFeatures = filterUniqueFeatures(event);
    }

    let callCount = 0;
    let addressCount = 0;
    let personCount = 0;
    let incidentCount = 0;

    uniqFeatures?.forEach((feature: any) => {
        if (feature.layer.id === "call-layer-cluster" && !!feature.properties) {
            callCount += feature.properties.point_count;
        }

        if (feature.layer.id === "person-layer-cluster" && !!feature.properties) {
            personCount += feature.properties.point_count;
        }

        if (feature.layer.id === "incident-layer-cluster" && !!feature.properties) {
            incidentCount += feature.properties.point_count;
        }

        if (feature.layer.id === "event-layer") {
            callCount += 1;
        }

        if (feature.layer.id === "person-layer") {
            personCount += 1;
        }

        if (feature.layer.id === "address-layer") {
            addressCount += 1;
        }

        if (feature.layer.id === "event-incident-layer") {
            incidentCount += 1;
        }

        if (feature.layer.id === "event-call-layer") {
            callCount += 1;
        }
    });

    const countSum = callCount + addressCount + personCount + incidentCount;
    const counts = { countSum, callCount, addressCount, personCount, incidentCount };

    return { uniqFeatures, counts };
};

export const triggerPopup = (searchResult: any, type: string, dispatch: any, width?: number) => {
    if (width && width <= defaultMobileSizePx) {
        return null;
    }

    // Only set up popup if coordinates exist for result
    if (searchResult.gps_lon && searchResult.gps_lat && validateCoordinates(searchResult.gps_lon, searchResult.gps_lat)) {
        const popupProperties: PopupProperties = {
            lng: searchResult.gps_lon,
            lat: searchResult.gps_lat,
            closeButton: false,
            type: searchResult.type,
        };

        if (type === "address" || searchResult.index?.includes("addresses")) {
            popupProperties.type = "address";
            popupProperties.address = searchResult.key;
            popupProperties.eventCount = searchResult.counts.calls + searchResult.counts.incidents;
            popupProperties.lng = searchResult.gps_lon;
            popupProperties.lat = searchResult.gps_lat;
        } else if (type === "call" || searchResult.index?.includes("calls")) {
            popupProperties.type = "call";
            popupProperties.callType = searchResult.call_type;
            popupProperties.callNumber = searchResult.call_number;
            popupProperties.address = searchResult.full_address;
            popupProperties.date = searchResult.occurred_at;
        } else if (type === "incident" || searchResult.index?.includes("incident")) {
            popupProperties.type = "incident";
            popupProperties.address = searchResult.full_address;
            popupProperties.incidentType = searchResult.incident_type;
            popupProperties.date = searchResult.occurred_at;
            popupProperties.number = searchResult.id;
        } else if (type === "person" || searchResult.index?.includes("people")) {
            popupProperties.type = "person";
            popupProperties.name = searchResult.full_name;
            popupProperties.address = searchResult.full_address;
        }

        dispatch(setPopupProperties(popupProperties));
        dispatch(setShowPopup(true));
    }
};

export const clusterMouseEnter = (event: MapLayerMouseEvent, dispatch: any, hoverEvent?: Function) => {
    const { uniqFeatures, counts } = getUniqueFeatures(event);
    const { lngLat } = event;

    if (!!uniqFeatures && counts.countSum === 1) {
        const feature = uniqFeatures[0];
        const { properties } = feature;
        if (!!properties) {
            const popupProperties: PopupProperties = {
                lng: lngLat.lng,
                lat: lngLat.lat,
                closeButton: false,
                type: properties.type,
            };

            if (properties.type === LayerTypes.call) {
                popupProperties.callType = properties.call_type;
                popupProperties.callNumber = properties.call_number;
                popupProperties.address = properties.full_address;
                popupProperties.date = properties.occurred_at;
            }

            if (properties.type === LayerTypes.person) {
                popupProperties.name = properties.full_name;
                popupProperties.address = properties.full_address;
            }

            if (properties.type === LayerTypes.address) {
                popupProperties.address = properties.address || properties.key;
                popupProperties.eventCount = properties.eventCount || properties.num_calls + properties.num_incidents;
            }

            if (properties.type === LayerTypes.incident) {
                popupProperties.address = properties.full_address;
                popupProperties.incidentType = properties.incident_type;
                popupProperties.date = properties.occurred_at;
                popupProperties.number =
                    properties.id || (properties.call_number && properties.call_number.length ? properties.call_number[0] : undefined);
            }

            dispatch(setPopupProperties(popupProperties));
            dispatch(setShowPopup(true));
            if (hoverEvent) {
                hoverEvent(properties);
            }
        }
    }

    if (!!uniqFeatures && counts.countSum > 1) {
        const popupProperties: PopupProperties = {
            lng: lngLat.lng,
            lat: lngLat.lat,
            closeButton: false,
            type: LayerTypes.list,
            totalAddresses: counts.addressCount,
            totalCalls: counts.callCount,
            totalPeople: counts.personCount,
            totalIncidents: counts.incidentCount,
        };
        dispatch(setPopupProperties(popupProperties));
        dispatch(setShowPopup(true));
    }
};

export const clusterMouseLeave = (currentPopupProperties: PopupProperties | undefined, dispatch: any, hoverEvent?: Function) => {
    if (!!currentPopupProperties && currentPopupProperties.type !== "clickList") {
        dispatch(setShowPopup(false));
        if (hoverEvent) {
            hoverEvent({});
        }
    }
};
