import React from "react";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { faRectangleHistoryCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { useGetSearchHistoryQuery } from "../../api/api";
import { convertBodyParamNames, getUrl } from "../../utils/helpers";
import { useNavigate } from "react-router";
import "./searchlandinghistory.css";
import { FromES, SearchHistory } from "../../types/old_v1/types";
import { v4 as uuidv4 } from "uuid";

interface SearchHistoryWithUrl extends SearchHistory {
    url: string;
}

type OwnProps = {
    searchHistory: FromES<SearchHistoryWithUrl>[];
};

export const SearchLandingHistoryList = ({ searchHistory }: OwnProps) => {
    const navigate = useNavigate();

    return (
        <ListGroup>
            {searchHistory && searchHistory.length > 0 && (
                <React.Fragment>
                    {searchHistory.map((historyItem: any, idx: number) => (
                        <ListGroup.Item
                            key={historyItem.id}
                            className={`border-top-0 history-item text-truncate cursor-pointer${
                                idx === searchHistory.length - 1 ? "" : " border-bottom-0"
                            }`}
                            onClick={() => navigate(historyItem.url)}
                            onMouseDown={(e: any) => e.preventDefault()}
                        >
                            <FontAwesomeIcon className="text-muted" icon={faClock} style={{ marginRight: ".5em" }} />
                            {historyItem.body.search_for}
                        </ListGroup.Item>
                    ))}
                    <ListGroup.Item
                        className="history-item text-truncate cursor-pointer"
                        onClick={() => navigate("../search-history")}
                        onMouseDown={(e: any) => e.preventDefault()}
                    >
                        <FontAwesomeIcon icon={faRectangleHistoryCirclePlus} />
                        &nbsp;View All
                    </ListGroup.Item>
                </React.Fragment>
            )}
        </ListGroup>
    );
};

const SearchLandingHistoryListConnected = () => {
    const { data } = useGetSearchHistoryQuery({ formattedResults: true }, { refetchOnMountOrArgChange: true });
    let searchHistoryData = [];
    if (data && data.search_history) {
        searchHistoryData = data.search_history
            .map((doc: any) => ({
                ...doc,
                url: getUrl({ body: { ...convertBodyParamNames(doc.body), searchId: uuidv4(), source: "main_search" } }),
            }))
            .reduce(
                (coll: any, curr: any) => {
                    if (curr.body.search_for && !coll.processedSearches.includes(curr.body.search_for)) {
                        coll.items.push(curr);
                        coll.processedSearches.push(curr.body.search_for);
                    }
                    return coll;
                },
                { processedSearches: [], items: [] }
            ).items;
    }
    return <SearchLandingHistoryList searchHistory={searchHistoryData.slice(0, 8)} />;
};

export default SearchLandingHistoryListConnected;
