import React from "react";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalTitle } from "react-bootstrap";

type OwnProps = {
    icon?: IconDefinition;
    title?: string;
    titleComponent?: React.ReactNode;
};

const ModalHeader = ({ icon, title, titleComponent }: OwnProps) => {
    return (
        <Modal.Header closeButton>
            <ModalTitle className="d-flex flex-row align-items-center">
                {!!icon && <FontAwesomeIcon icon={icon} size="lg" className="me-2" />}
                {!!title && <span>{title}</span>}
                {!!titleComponent && titleComponent}
            </ModalTitle>
        </Modal.Header>
    );
};

export default ModalHeader;
