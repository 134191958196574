import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import useAgencySettings from "../../../hooks/useAgencySettings";

type OwnProps = {
    close: Function;
    pendoClass?: string;
};

const IndexType = ({ close, pendoClass = "" }: OwnProps) => {
    const [searchParams] = useSearchParams();
    const { incidentTextPlural, callTextPlural } = useAgencySettings();
    const params = React.useRef(new URLSearchParams(searchParams));
    const navigate = useNavigate();
    const selectedIndexType = params.current.get("indexType") || "";

    const apply = (option: string) => {
        if (option === "") {
            params.current.delete("indexType");
        } else {
            params.current.set("indexType", option);
        }

        navigate(`.?${params.current.toString()}`);
        close("indexType");
    };

    return (
        <div style={{ width: "350px" }}>
            <Row className="p-3">
                <Col>
                    <Form.Check
                        className={`ms-4 mt-2 index-type-both-filter ${pendoClass ? `${pendoClass}-both` : ""}`}
                        type="radio"
                        label={`Both ${callTextPlural}/${incidentTextPlural}`}
                        value=""
                        id="both-index-type-radio"
                        checked={selectedIndexType === "" || selectedIndexType === null}
                        onChange={(e) => apply(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="p-3">
                <Col>
                    <Form.Check
                        className={`ms-4 mt-2 index-type-calls-filter ${pendoClass ? `${pendoClass}-calls` : ""}`}
                        type="radio"
                        label={callTextPlural}
                        value="calls"
                        id="calls-index-type-radio"
                        checked={selectedIndexType === "calls"}
                        onChange={(e) => {
                            apply(e.target.value);
                        }}
                    />
                </Col>
            </Row>
            <Row className="p-3">
                <Col>
                    <Form.Check
                        className={`ms-4 mt-2 index-type-incidents-filter ${pendoClass ? `${pendoClass}-incidents` : ""}`}
                        type="radio"
                        label={incidentTextPlural}
                        value="incidents"
                        id="incidents-index-type-radio"
                        checked={selectedIndexType === "incidents"}
                        onChange={(e) => apply(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="p-3">
                <Col xs={4}>
                    <Button variant="outline-secondary" onClick={() => close("indexType")}>
                        <FontAwesomeIcon icon={faXmark} /> Cancel
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

IndexType.displayName = "IndexType";
export default IndexType;
