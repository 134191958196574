import React from "react";
import { Form, Row, Stack } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import useAgencySettings from "../../hooks/useAgencySettings";

const IndexType = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = new URLSearchParams(searchParams);
    const indexType = params.get("indexType") || "";
    const { incidentTextPlural, callTextPlural } = useAgencySettings();

    const apply = (option: string) => {
        if (option === "calls" && indexType === "") {
            params.set("indexType", "incidents");
        } else if (option === "incidents" && indexType === "") {
            params.set("indexType", "calls");
        } else if ((option === "calls" && indexType === "incidents") || (option === "incidents" && indexType === "calls")) {
            params.delete("indexType");
        } else {
            params.set("indexType", option);
        }
        params.delete("page");

        setSearchParams(params);
    };

    return (
        <Row>
            <Stack direction="horizontal" gap={5}>
                <Form.Check
                    type={"checkbox"}
                    onChange={() => apply("calls")}
                    checked={searchParams.get("indexType") === "calls" || !searchParams.get("indexType")}
                    label={callTextPlural}
                    className="pendo_has_calls_filter_check fs-3"
                    id={`calls-check`}
                />

                <Form.Check
                    type={"checkbox"}
                    onChange={() => apply("incidents")}
                    checked={searchParams.get("indexType") === "incidents" || !searchParams.get("indexType")}
                    label={incidentTextPlural}
                    className="pendo_has_incidents_tags_filter_check fs-3"
                    id={`incidents-check`}
                />
            </Stack>
        </Row>
    );
};

IndexType.displayName = "IndexType";
export default IndexType;
