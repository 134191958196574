import { Card, ListGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faGavel } from "@fortawesome/pro-light-svg-icons";
import { INCIDENT_ACCENT } from "../../utils/colors";
import { Link } from "react-router-dom";
import React from "react";
import Tags from "../tags/Tags";
import { formatDate } from "../../utils/date";
import AssociatedPeopleModal from "./AssociatedPeopleModal";
import { useManageSearchParams } from "../../hooks/useManageSearchParams";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import useAgencySettings from "../../hooks/useAgencySettings";
import Highlighter from "../highlighter/Highlighter";
import { highlightDisplay } from "../../utils/display";

// leaving this function here for now since it is only used here
// removing all html tags and \n's except the highlight <em> and </em>
export const stripHtml = (input: string) => input.replace(/<(?!\/?em(?=>|\s.*>))\/?.*?>|\\n/gi, "");

type OwnProps = {
    incident: any;
    showRoles?: boolean;
    showInvolvedPeople?: boolean;
};

const IncidentCard = ({ incident, showRoles = false, showInvolvedPeople = true }: OwnProps) => {
    const { constructEventUrl } = useMultiAgency();
    const { processSearchParams } = useManageSearchParams();
    const [selectedIncident, setSelectedIncident] = React.useState<any>();
    const { incidentText } = useAgencySettings();

    return (
        <ListGroup.Item className="mb-3 p-0 border-0" as={Card}>
            <Card.Header className="d-flex justify-content-between gap-3 border rounded">
                <div className="d-flex d-md-inline-flex flex-column flex-md-row justify-content-between flex-grow-1">
                    <div className="d-inline-flex flex-grow-1">
                        <FontAwesomeIcon icon={faGavel} className="fs-2" color={INCIDENT_ACCENT} />
                        <Link
                            className="ms-3 fw-bold pendo_incident_link_button"
                            to={constructEventUrl(
                                incident?.agency_id,
                                incident?.call_number?.length ? incident?.call_number[0] : undefined,
                                incident?.incident_number,
                                processSearchParams(["dateRange", "query"])
                            )}
                        >
                            {incidentText} {incident.incident_number} {incident.incident_type ? ` | ${incident.incident_type}` : ""}
                        </Link>
                    </div>
                    <div className="d-none d-md-flex flex-column gap-1 text-end">
                        <span>{formatDate(incident.occurred_at)}</span>
                        {showRoles && (
                            <span>
                                Role: <span className="fw-bold">{incident?.roles?.join(", ") || "--"}</span>
                            </span>
                        )}
                    </div>
                    <div className="d-inline-flex d-md-none gap-4 justify-content-between text-end">
                        <span>{formatDate(incident.occurred_at)}</span>
                        {showRoles && (
                            <span>
                                Role: <span className="fw-bold">{incident?.roles?.join(", ") || "--"}</span>
                            </span>
                        )}
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="pt-2 fm-left-indent">
                <div className="d-flex flex-col justify-content-between">
                    <div>
                        <Tags tags={incident.flags || []} />
                        <div data-testid="third-row-container">{incident.full_address}</div>
                        {/* Mobile style */}
                        {incident?.involved_people && showInvolvedPeople && (
                            <div className="d-md-none">
                                <span
                                    onClick={() => setSelectedIncident(incident)}
                                    className="link-primary text-decoration-underline cursor-pointer pendo_associated_person_modal_button"
                                >{`${incident?.involved_people.length} Associated ${
                                    incident?.involved_people.length === 1 ? "Person" : "People"
                                }`}</span>
                            </div>
                        )}
                    </div>
                    {/* Desktop style */}
                    {incident?.involved_people && showInvolvedPeople && (
                        <div className="d-none d-md-block text-end">
                            <span
                                onClick={() => setSelectedIncident(incident)}
                                className="link-primary text-decoration-underline cursor-pointer pendo_associated_person_modal_button"
                            >{`${incident?.involved_people.length} Associated ${
                                incident?.involved_people.length === 1 ? "Person" : "People"
                            }`}</span>
                        </div>
                    )}
                </div>
                <div>
                    {((incident?.narratives && incident?.narratives[0]?.highlight?.body) || incident?.highlight) && (
                        <div className="d-inline-flex d-md-none flex-grow-1">
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="top"
                                overlay={
                                    <Popover>
                                        <Popover.Header>Match Previews</Popover.Header>
                                        <Popover.Body>
                                            <ul>
                                                {incident?.narratives[0]?.highlight?.body.map((text: any, index: number) => {
                                                    return (
                                                        <li key={index}>
                                                            <Highlighter className="pe-3">{stripHtml(text)}</Highlighter>
                                                        </li>
                                                    );
                                                })}
                                                {incident?.highlight &&
                                                    Object.keys(incident?.highlight).map((text: any, index: number) => {
                                                        return (
                                                            <li key={index}>
                                                                <Highlighter className="pe-3">
                                                                    {`${highlightDisplay[text]}: ${stripHtml(
                                                                        incident?.highlight[text].join(", ")
                                                                    )}`}
                                                                </Highlighter>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <span className="cursor-pointer text-primary d-inline-flex align-items-center mt-2 fm-preview-font-size fm-preview-font-size">
                                    <FontAwesomeIcon icon={faArrowCircleRight} />
                                    &nbsp;Preview
                                </span>
                            </OverlayTrigger>
                        </div>
                    )}
                    {((incident?.narratives && incident?.narratives[0]?.highlight?.body) || incident?.highlight) && (
                        <div className="d-none d-md-flex">
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="auto-start"
                                overlay={
                                    <Popover>
                                        <Popover.Header>Match Previews</Popover.Header>
                                        <Popover.Body>
                                            <ul>
                                                {incident?.narratives[0]?.highlight?.body.map((text: any, index: number) => {
                                                    return (
                                                        <li key={index}>
                                                            <Highlighter className="pe-3">{stripHtml(text)}</Highlighter>
                                                        </li>
                                                    );
                                                })}
                                                {incident?.highlight &&
                                                    Object.keys(incident?.highlight).map((text: any, index: number) => {
                                                        return (
                                                            <li key={index}>
                                                                <Highlighter className="pe-3">
                                                                    {`${highlightDisplay[text]}: ${stripHtml(
                                                                        incident?.highlight[text].join(", ")
                                                                    )}`}
                                                                </Highlighter>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <span className="cursor-pointer text-primary d-inline-flex align-items-center mt-2 fm-preview-font-size">
                                    <FontAwesomeIcon icon={faArrowCircleRight} />
                                    &nbsp;Preview
                                </span>
                            </OverlayTrigger>
                        </div>
                    )}
                </div>
            </Card.Body>
            {!!selectedIncident && <AssociatedPeopleModal selectedIncident={selectedIncident} setSelectedIncident={setSelectedIncident} />}
        </ListGroup.Item>
    );
};

export default IncidentCard;
