import React from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MfaOptions from "./MfaOptions";
import { setOnboardingObject } from "../../app/slices/user/userSlice";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { OnboardingObject } from "../../types/old_v1/types";
import { useSetStep } from "./ReconfigureMfa";

type OwnProps = {
    onboardingObject: OnboardingObject;
};

const SelectMfaConnected = () => {
    const user = useAppSelector(({ user }) => user);
    const onboardingObject = user.onboardingObject;
    return <SelectMfa onboardingObject={onboardingObject} />;
};

const SelectMfa = ({ onboardingObject }: OwnProps) => {
    const { setStep } = useSetStep();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        setStep(1);
    }, [navigate, setStep]);

    const setMfaType = (mfaType: string) => {
        if (mfaType) {
            setStep(2);
            dispatch(setOnboardingObject({ ...onboardingObject, mfa_type: mfaType, step: 2 }));
            navigate("../enter-code");
        }
    };

    return (
        <div className="mt-4">
            <div className="d-flex justify-content-center p-3">
                <h2>Select Multi-Factor Verification Type</h2>
            </div>
            <Row className="justify-content-center">
                <Col xs={11} sm={10} md={7} lg={6} xl={5}>
                    <MfaOptions setMfaType={setMfaType} disabled={false} />
                </Col>
            </Row>
        </div>
    );
};

SelectMfa.displayName = "SelectMfa";
export default SelectMfaConnected;
