import React from "react";
import { useSearchParams } from "react-router-dom";
import { FilterParamNamesType, LayerTypes } from "../../types/old_v1/types";
import TagsFilter from "../modalFilters/TagsFilter";
import RaceFilter from "../modalFilters/RaceFilter";
import GenderFilter from "../modalFilters/GenderFilter";
import IndexType from "../modalFilters/IndexType";
import TimeRange from "../modalFilters/TimeRange";
import BeatsFilter from "../modalFilters/BeatsFilter";
import OfficerFilter from "../modalFilters/OfficerFilter";
import DispatcherFilter from "../modalFilters/DispatcherFilter";
import usePermissions from "../../hooks/usePermissions";
import IncidentTypeFilter from "../modalFilters/IncidentTypeFilter";
import CallTypeFilter from "../modalFilters/CallTypeFilter";
import { FilterParamNames } from "../../constants/constants";
import "./filters.css";
import AgencyFilter from "../modalFilters/AgencyFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import AgeFilter from "../modalFilters/AgeFilter";
import { TEventFiltersAggregations } from "../modalFilters/FiltersModal";
import { useSearchFiltersAggregations } from "../../hooks/useSearchFiltersAggregations";
import DateRange from "../modalFilters/DateRange";
import { Col, Row } from "react-bootstrap";
import useAgencySettings from "../../hooks/useAgencySettings";
import OffensesFilter from "../modalFilters/OffensesFilter";
import CallSourcesFilter from "../modalFilters/CallSourcesFilter";
import CallPriorityFilter from "../modalFilters/CallPriorityFilter";

type FiltersProps = {
    selectedEntities: string[];
    defaultDateRange: string;
    hideFilterList?: FilterParamNamesType[];
    eventFiltersAggregations?: TEventFiltersAggregations;
};

const Filters = ({ selectedEntities, hideFilterList = [], eventFiltersAggregations = null }: FiltersProps) => {
    const { hasPermission } = usePermissions();
    const [searchParams] = useSearchParams();
    const { incidentText, callText } = useAgencySettings();
    const params = React.useMemo(() => new URLSearchParams(searchParams), [searchParams]);
    const [activeFilters, setActiveFilters] = React.useState<string[]>([]);
    const { showMultiAgencyFilter } = useMultiAgency();

    React.useEffect(() => {
        let urlFilters = [];

        for (const key of params.keys() as any) {
            urlFilters.push(key);
            setActiveFilters(urlFilters);
        }
    }, [params]);

    const isFilterActive = (paramName: FilterParamNamesType) => activeFilters.includes(paramName);

    const {
        offensesAggregation,
        labelAggregation,
        dispatcherAggregation,
        officerAggregation,
        callTypeAggregation,
        incidentTypeAggregation,
        callSourcesAggregation,
        callPriorityAggregation,
    } = useSearchFiltersAggregations(eventFiltersAggregations);

    return (
        <div className="pendo_filter_side_panel gap-5 d-flex flex-column mt-4">
            {showMultiAgencyFilter && !hideFilterList.includes(FilterParamNames.AGENCIES) && (
                <div className="border-0">
                    <FilterHeaderLabel isActive={true}>Agencies</FilterHeaderLabel>
                    <div className="px-4 py-3">
                        <AgencyFilter />
                    </div>
                </div>
            )}
            {selectedEntities.includes(LayerTypes.event) && (
                <>
                    {!hideFilterList.includes(FilterParamNames.DATE_RANGE) && (
                        <Row>
                            <Col xs={12}>
                                <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.DATE_RANGE)}>Date Range</FilterHeaderLabel>
                                <DateRange />
                            </Col>
                        </Row>
                    )}
                    {!hideFilterList.includes(FilterParamNames.EVENT_TYPES) && (
                        <div className="border-0">
                            <FilterHeaderLabel isActive={true}>Event Types</FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <IndexType />
                            </div>
                        </div>
                    )}
                    {!hideFilterList.includes(FilterParamNames.LABELS) && (
                        <div className="border-0">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.LABELS)}>Labels</FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <TagsFilter labelAggregation={labelAggregation} />
                            </div>
                        </div>
                    )}
                    {!hideFilterList.includes(FilterParamNames.BEATS) && (
                        <div className="border-0" data-testid="beats-filter-button">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.BEATS)}>Beats</FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <BeatsFilter />
                            </div>
                        </div>
                    )}
                    {!hideFilterList.includes(FilterParamNames.CALL_TYPES) && (
                        <div className="border-0">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.CALL_TYPES)}>{callText} Types</FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <CallTypeFilter callTypeAggregation={callTypeAggregation} />
                            </div>
                        </div>
                    )}
                    {!hideFilterList.includes(FilterParamNames.CALL_SOURCES) && (
                        <div className="border-0">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.CALL_SOURCES)}>
                                {callText} Sources
                            </FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <CallSourcesFilter callSourceAggregation={callSourcesAggregation} />
                            </div>
                        </div>
                    )}
                    {!hideFilterList.includes(FilterParamNames.CALL_PRIORITIES) && (
                        <div className="border-0">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.CALL_PRIORITIES)}>
                                {callText} Priorities
                            </FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <CallPriorityFilter callPrioritiesAggregation={callPriorityAggregation} />
                            </div>
                        </div>
                    )}
                    {!hideFilterList.includes(FilterParamNames.INCIDENT_TYPES) && (
                        <div className="border-0">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.INCIDENT_TYPES)}>
                                {incidentText} Types
                            </FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <IncidentTypeFilter incidentTypeAggregation={incidentTypeAggregation} />
                            </div>
                        </div>
                    )}
                    {!hideFilterList.includes(FilterParamNames.OFFENSES) && (
                        <div className="border-0">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.OFFENSES)}>Offenses</FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <OffensesFilter offensesAggregation={offensesAggregation} />
                            </div>
                        </div>
                    )}
                    {!hideFilterList.includes(FilterParamNames.TIME_RANGE) && (
                        <div className="border-0">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.TIME_RANGE)}>Hours of Day</FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <TimeRange fullWidth />
                            </div>
                        </div>
                    )}
                    {hasPermission("insights_dashboard_officer") && !hideFilterList.includes(FilterParamNames.OFFICER) && (
                        <div className="border-0">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.OFFICER)}>Officers</FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <OfficerFilter officerAggregation={officerAggregation} />
                            </div>
                        </div>
                    )}
                    {hasPermission("insights_dashboard_dispatcher") && !hideFilterList.includes(FilterParamNames.DISPATCHER) && (
                        <div className="border-0">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.DISPATCHER)}>Dispatchers</FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <DispatcherFilter dispatcherAggregation={dispatcherAggregation} />
                            </div>
                        </div>
                    )}
                </>
            )}
            {selectedEntities.includes(LayerTypes.person) && (
                <>
                    {!hideFilterList.includes(FilterParamNames.RACE) && (
                        <div className="border-0 fm-no-arrow-accordion">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.RACE)}>Race</FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <RaceFilter />
                            </div>
                        </div>
                    )}
                    {!hideFilterList.includes(FilterParamNames.GENDER) && (
                        <div className="border-0 fm-no-arrow-accordion">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.GENDER)}>Gender</FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <GenderFilter />
                            </div>
                        </div>
                    )}
                    {!hideFilterList.includes(FilterParamNames.AGE) && (
                        <div className="border-0 fm-no-arrow-accordion">
                            <FilterHeaderLabel isActive={isFilterActive(FilterParamNames.AGE)}>Age</FilterHeaderLabel>
                            <div className="px-4 py-3">
                                <AgeFilter />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

type FilterHeaderLabelProps = {
    children: React.ReactNode;
    icon?: any;
    isActive: Boolean;
};
const FilterHeaderLabel = ({ children, isActive, icon }: FilterHeaderLabelProps) => {
    return (
        <h4 className={`${isActive && "fw-bold text-primary"} rounded border-bottom rounded-0 pb-2 ps-2`}>
            {icon && <FontAwesomeIcon icon={icon} className="me-2" />}
            {children}
        </h4>
    );
};

Filters.displayName = "Filters";
export default Filters;
