import "./cardcell.css";
import { MouseEventHandler } from "react";

type OwnProps = {
    fieldName: string;
    fieldValue?: string;
    cellClick?: MouseEventHandler;
    disabled?: boolean;
    pendoClass?: string;
    testId?: string;
};
const LinkCell = ({ fieldName, fieldValue, cellClick, disabled = false, pendoClass, testId }: OwnProps) => {
    return (
        <div
            onClick={cellClick}
            className={`border-top px-3 py-2 ${disabled ? "disabled-link" : ""} ${pendoClass}`}
            data-testid={testId || undefined}
        >
            <span className="fw-bold">{fieldName}</span>
            <div className={`link-primary cursor-pointer text-decoration-underline ${disabled ? "disabled" : ""}`}>
                {fieldValue || "Unknown"}
            </div>
        </div>
    );
};

export default LinkCell;
