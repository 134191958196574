import React from "react";
import { Card, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useGetInsightsIncidentsOverTimeQuery } from "../../api/api";
import { processLineChartData } from "./utils";
import LineChart from "./charts/LineChart";
import { format, subMonths, subWeeks, subYears } from "date-fns";
import { calculateEnabledDateViews } from "../../utils/insights";
import { InsightsDateViewEnum } from "../../types/types";
import useInsights from "../../hooks/useInsights";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";
import useAgencySettings from "../../hooks/useAgencySettings";
import { useFilters } from "../../hooks/useFilters";

const now = new Date();

const IncidentsOverTime = () => {
    const [dateInterval, setDateInterval] = React.useState(InsightsDateViewEnum.DAY);
    const { queryParams } = useSingleAgencyFilter();
    const { searchParams } = useInsights();
    const { filterPayload, beatsIsFetching } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });
    const { data, isLoading } = useGetInsightsIncidentsOverTimeQuery(
        { ...queryParams, filters: filterPayload, interval: dateInterval },
        { skip: beatsIsFetching }
    );
    const { incidentTextPlural } = useAgencySettings();
    const processedData = !isLoading && data && processLineChartData(data);
    const dateRange = searchParams.get("dateRange");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");

    const dateButtonStatuses = calculateEnabledDateViews(dateRange || "", startDate || "", endDate || "", now);

    React.useEffect(() => {
        if (dateRange) {
            if (["4days", "8days", "30days"].includes(dateRange)) {
                setDateInterval(InsightsDateViewEnum.DAY);
            } else if ("90days" === dateRange) {
                setDateInterval(InsightsDateViewEnum.WEEK);
            } else if ("1year" === dateRange) {
                setDateInterval(InsightsDateViewEnum.MONTH);
            } else if ("3years" === dateRange) {
                setDateInterval(InsightsDateViewEnum.YEAR);
            }
        } else {
            setDateInterval(InsightsDateViewEnum.DAY);
        }
    }, [dateRange]);

    return (
        <Card style={{ height: "100%" }}>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title className="m-0" as="h3">
                    {incidentTextPlural} Over Time
                </Card.Title>
                <ToggleButtonGroup name="options" size="sm" type="radio" value={dateInterval} onChange={setDateInterval}>
                    <ToggleButton id={InsightsDateViewEnum.DAY} value={InsightsDateViewEnum.DAY} variant="outline-primary">
                        Day
                    </ToggleButton>
                    <ToggleButton
                        id={InsightsDateViewEnum.WEEK}
                        value={InsightsDateViewEnum.WEEK}
                        variant="outline-primary"
                        disabled={!dateButtonStatuses[InsightsDateViewEnum.WEEK]}
                    >
                        Weekly
                    </ToggleButton>
                    <ToggleButton
                        id={InsightsDateViewEnum.MONTH}
                        value={InsightsDateViewEnum.MONTH}
                        variant="outline-primary"
                        disabled={!dateButtonStatuses[InsightsDateViewEnum.MONTH]}
                    >
                        Month
                    </ToggleButton>
                    <ToggleButton
                        id={InsightsDateViewEnum.YEAR}
                        value={InsightsDateViewEnum.YEAR}
                        variant="outline-primary"
                        disabled={!dateButtonStatuses[InsightsDateViewEnum.YEAR]}
                    >
                        Year
                    </ToggleButton>
                </ToggleButtonGroup>
            </Card.Header>
            <Card.Body>
                {processedData && processedData.labels && processedData.labels.length ? (
                    <LineChart
                        processedData={processedData}
                        mainSeriesName={incidentTextPlural}
                        xAxis="Date"
                        yAxis={`Number of ${incidentTextPlural}`}
                        onClickFn={(e: any) => {
                            const endDate = e.point.category;
                            let startDate;
                            if (dateInterval === InsightsDateViewEnum.DAY) {
                                startDate = endDate;
                            } else {
                                let tmpDate = new Date(endDate);
                                let sd;
                                if (dateInterval === InsightsDateViewEnum.WEEK) {
                                    sd = subWeeks(tmpDate, 1);
                                } else if (dateInterval === InsightsDateViewEnum.MONTH) {
                                    sd = subMonths(tmpDate, 1);
                                } else if (dateInterval === InsightsDateViewEnum.YEAR) {
                                    sd = subYears(tmpDate, 1);
                                }
                                if (sd) {
                                    startDate = format(sd, "yyyy-MM-dd");
                                }
                            }

                            searchParams.set("dateRange", "custom");
                            searchParams.set("startDate", startDate);
                            searchParams.set("endDate", endDate);
                            searchParams.set("indexType", "incidents");

                            window.open(`/app/search?${searchParams}`, "_blank");
                        }}
                    />
                ) : (
                    <h1 className="text-center">No Events</h1>
                )}
            </Card.Body>
        </Card>
    );
};

export default IncidentsOverTime;
