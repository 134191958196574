import React from "react";
import { Button, Card, Col, Row, Stack } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useRunPreAuthApiMutation, SEND_CODE_ROUTE, VALIDATE_CODE_ROUTE } from "../../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faShieldCheck, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { Form, Formik } from "formik";
import { setIsLoggedIn, setUserObject } from "../../app/slices/user/userSlice";
import TextField from "../../components/formcontrols/TextField";
import * as Yup from "yup";
import FmAlert from "../support/FmAlert";
import { setAccessToken, setRefreshToken } from "../../utils/token";

const SMS = "SMS";

type FormProps = {
    mfaCode: string;
};

/**
 * Handles the MFA input after the login page.
 * Page 2 of the login process.
 *
 * @constructor
 */
const MfaContainerConnected = () => {
    // attempt to get the mfa from redux
    const mfa = useAppSelector(({ user }) => user?.mfaData);
    return <MfaContainer mfa={mfa} />;
};

const MfaContainer = ({ mfa }: { mfa: any }) => {
    const [sendSmsCode, smsResults] = useRunPreAuthApiMutation(); // call to resend sms code for sms users
    const [verifyMfaCode, verifyResults] = useRunPreAuthApiMutation(); // call to verify mfa code
    const [alert, setAlert] = React.useState<string | undefined>(undefined);
    const dispatch = useAppDispatch();
    const isSMS = (mfa && mfa?.mfaType) === SMS;
    const email = mfa && mfa?.email;
    const phone = mfa && mfa?.phone;
    const mfaToken = mfa && mfa?.mfa;
    const formikRef = React.useRef<any>();
    const mfaInputRef = React.useRef<any>();

    const initial: FormProps = {
        mfaCode: "",
    };

    // make sure necessary values are present
    React.useEffect(() => {
        if (!mfaToken || !email) {
            setAlert("invalid_login_session");
        }
    }, [mfaToken, email]);

    // handles the input mfa code
    const submitMfaCode = (values: FormProps) => {
        if (mfaToken) {
            if (alert) {
                setAlert(undefined);
            }
            const mfaCode = values?.mfaCode?.replace(/\s+/g, "");
            verifyMfaCode({
                mfaToken: mfaToken,
                mfaCode,
                isSMS,
                route: VALIDATE_CODE_ROUTE,
            });
        } else {
            setAlert("token_invalid");
        }
    };

    // listen for the mfa code validation response
    React.useEffect(() => {
        if (verifyResults?.isError) {
            setAlert("mfa_code_invalid");
        }
        if (verifyResults?.data) {
            setAccessToken(verifyResults?.data?.access);
            setRefreshToken(verifyResults?.data?.refresh);
            dispatch(setIsLoggedIn(true));
            dispatch(setUserObject(verifyResults?.data?.user));
        }
    }, [verifyResults, dispatch]);

    // for sms users, handles the request for a new token to be texted
    const requestSmsCode = () => {
        if (formikRef?.current && mfaInputRef?.current) {
            formikRef?.current.resetForm();
            mfaInputRef?.current.focus();
        }
        sendSmsCode({ email, phone, mfaToken, route: SEND_CODE_ROUTE });
    };

    // listens for the results of sendTokenSMS call initiated by the resend button for SMS users
    // This error will happen if the client can't communicate with the server.
    React.useEffect(() => {
        if (smsResults.isError) {
            setAlert("text_message_problem");
        }
        if (smsResults.data) {
            setAlert("new_code_sent");
        }
    }, [smsResults]);

    const loading = (verifyResults && verifyResults?.isLoading) || (smsResults && smsResults?.isLoading);
    const disabled = !!(alert && alert !== "mfa_code_invalid" && alert !== "new_code_sent");
    return (
        <>
            {alert && (
                <Row className="justify-content-center">
                    <Col md={6} lg={4}>
                        <FmAlert alertKey={alert} variant={alert === "new_code_sent" ? "success" : "danger"} />
                    </Col>
                </Row>
            )}
            <Row className="justify-content-center mt-3">
                <Col xs={11} sm={9} md={7} lg={5} xl={5} xxl={4}>
                    <Card className="overflow-hidden">
                        <Card.Header>Enter Your Verification Code</Card.Header>
                        <Card.Body>
                            <div className="d-flex justify-content-center">
                                <Formik
                                    innerRef={formikRef}
                                    initialValues={initial}
                                    validateOnBlur={false}
                                    onSubmit={submitMfaCode}
                                    validationSchema={Yup.object({
                                        mfaCode: Yup.string().required("Required"),
                                    })}
                                >
                                    {(props) => (
                                        <Form>
                                            <div className="text-muted mb-3">
                                                {isSMS
                                                    ? `Input code sent to number ending in ${phone && `+1-XXX-XXX-XX${phone.slice(-2)}`}.`
                                                    : "Enter code from authenticator app."}
                                            </div>
                                            <Stack direction="horizontal" gap={3}>
                                                <TextField
                                                    innerRef={mfaInputRef as any}
                                                    name="mfaCode"
                                                    autoFocus={true}
                                                    placeholder="Enter Code"
                                                    showErrorText={false}
                                                    autoComplete="off"
                                                    disabled={disabled}
                                                />
                                                <Button
                                                    type="submit"
                                                    variant={loading || !props.dirty || disabled ? "secondary" : "success"}
                                                    className="text-nowrap mt-n2"
                                                    disabled={loading || !props.dirty || disabled}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={verifyResults && verifyResults?.isLoading ? faSpinner : faShieldCheck}
                                                    />
                                                    <span className="ms-2">Verify</span>
                                                </Button>
                                            </Stack>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Card.Body>
                    </Card>
                    {isSMS && (
                        <div className="d-flex justify-content-center mt-5">
                            <Button
                                type="button"
                                variant="secondary"
                                className="text-nowrap"
                                onClick={requestSmsCode}
                                disabled={(verifyResults && verifyResults.isLoading) || (smsResults && smsResults.isLoading)}
                            >
                                <FontAwesomeIcon icon={smsResults && smsResults.isLoading ? faSpinner : faPaperPlane} />
                                <span className="ms-2">Resend Code</span>
                            </Button>
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default MfaContainerConnected;
