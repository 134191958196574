import { Button } from "react-bootstrap";
import Highlighter from "../../../components/highlighter/Highlighter";
import { sort } from "../../../utils/sort";
import { format, parseISO } from "date-fns";

const MultiNoteCallNotes = ({
    callNotes,
    isTruncated = false,
    setCurrentTab = () => null,
    sortBy = "created_at",
}: {
    callNotes?: any;
    isTruncated?: boolean;
    setCurrentTab?: () => void | null;
    sortBy?: string;
}) => {
    const sortedCallNotes = (callNotes ? [...callNotes] : []).sort(sort(sortBy, true));

    return (
        <>
            {sortedCallNotes && Array.isArray(sortedCallNotes) && sortedCallNotes.length
                ? sortedCallNotes.map((note: any, index: number) => {
                      const timeString = `${note?.created_at && format(parseISO(note?.created_at), "MM-dd-yy HH:mm:ss")}`;
                      const noteBody = (note?.body || "") as string;
                      return (
                          <p className={`mb-1${index === 0 ? "" : " mt-4"}`} key={note?.id} style={{ whiteSpace: "pre-line" }}>
                              <span className="border-bottom">
                                  <small className="text-secondary">{timeString}</small>&nbsp;
                                  <small>
                                      <strong>{note?.author ? note.author : ""}</strong>
                                  </small>
                              </span>
                              <br />
                              <Highlighter>
                                  {isTruncated ? `${noteBody.slice(0, 90)}${noteBody.length > 90 ? "..." : ""}` : noteBody}
                              </Highlighter>
                          </p>
                      );
                  })
                : null}
            {isTruncated && sortedCallNotes.length === 5 && (
                <div>
                    <Button
                        className="px-0 pendo_multi_call_notes_see_more"
                        variant="link"
                        onClick={setCurrentTab}
                        data-testid="call-notes-view-more-button"
                    >
                        View More
                    </Button>
                </div>
            )}
        </>
    );
};

export default MultiNoteCallNotes;
