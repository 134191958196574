import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare } from "@fortawesome/pro-regular-svg-icons";
import ShareModal from "./ShareModal";
import usePermissions from "../../hooks/usePermissions";

const ShareEntity = () => {
    const { hasPermission } = usePermissions();
    const [showShareModal, setShowShareModal] = React.useState(false);

    return hasPermission("share") ? (
        <>
            <Button onClick={() => setShowShareModal(true)} variant="primary" className="pendo_share_button cursor pointer" size="sm">
                <FontAwesomeIcon icon={faShareFromSquare} />
                <span className="ms-2">Share</span>
            </Button>
            <ShareModal show={showShareModal} close={() => setShowShareModal(false)} />
        </>
    ) : (
        <></>
    );
};

export default ShareEntity;
