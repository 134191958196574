import React from "react";
import { Button, Row } from "react-bootstrap";
import { useLazyUpdateMFAQuery } from "../../api/api";
import { useSignOut } from "../../hooks/useSignOut";

const MFAResetForm = () => {
    const [updateMFa] = useLazyUpdateMFAQuery();
    const { signOutAndReRoute } = useSignOut();

    const resetMFA = async () => {
        if (
            window.confirm(
                "Are you sure you want to reset Multi-Factor Authentication? You will be immediately signed out, then during your next login will be prompted to reconfigure Multi-Factor Authentication in order to use ForceMetrics."
            )
        ) {
            await updateMFa({});
            signOutAndReRoute();
        }
    };

    return (
        <div>
            <Row className="justify-content-between">
                <h2 className="text-primary fw-bold">Reset your Multi-Factor Authentication</h2>
                <p className="text-muted">Multi-Factor Authentication is a CJIS requirement due to the sensitivity of the data.</p>
            </Row>

            <Button className="mb-2 reset-mfa" onClick={resetMFA}>
                Reset MFA
            </Button>
        </div>
    );
};

export default MFAResetForm;
