import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { useMultiAgency } from "../hooks/useMultiAgency";

type OwnProps = ComponentPropsWithoutRef<"span"> & {
    agencyDescription: ReactNode | string;
};

/**
 * Only displays agency description if current agency is multiagency
 */
const AgencyDescription = ({ agencyDescription, ...rest }: OwnProps) => {
    const { isMultiAgency } = useMultiAgency();

    return isMultiAgency ? (
        <span data-testid="search-result-agency" {...rest}>
            {agencyDescription}
        </span>
    ) : (
        <></>
    );
};

export default AgencyDescription;
