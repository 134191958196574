import React from "react";
import { setShowFeedbackModal } from "../../app/slices/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ModalCenter from "../../components/modalcenter/ModalCenter";
import FeedbackForm from "./form/FeedbackForm";

const FeedbackModal = () => {
    const dispatch = useAppDispatch();
    const showFeedbackModal = useAppSelector((state) => state.user.showFeedbackModal);
    const feedbackStatus = useAppSelector((state) => state.user.feedbackStatus);

    const toggleModal = () => {
        dispatch(setShowFeedbackModal({ show: !showFeedbackModal }));
    };
    return (
        <ModalCenter title="Submit Feedback" show={showFeedbackModal} setShow={toggleModal}>
            <FeedbackForm feedbackStatus={feedbackStatus} />
        </ModalCenter>
    );
};

export default FeedbackModal;
