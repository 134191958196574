import { Card } from "react-bootstrap";
import { sort } from "../../utils/sort";
import Histogram from "./charts/Histogram";
import InsightsSpinner from "./insightsspinner/InsightsSpinner";
import { useSearchParams } from "react-router-dom";
import useAgencySettings from "../../hooks/useAgencySettings";

type OwnProps = {
    offensesBuckets: any[];
    isFetching: boolean;
    isClickable: boolean;
};

const OffensesHistogram = ({ offensesBuckets, isFetching, isClickable }: OwnProps) => {
    const { incidentTextPlural } = useAgencySettings();
    const [searchParams] = useSearchParams();
    const processedOffensesBuckets =
        offensesBuckets &&
        [...offensesBuckets].sort(sort("key", true)).reduce(
            (coll: any, curr: any) => {
                coll.labels.push(curr.key);
                coll.counts.push(curr.doc_count);
                return coll;
            },
            { labels: [], counts: [] }
        );

    return (
        <Card>
            <Card.Header>
                <Card.Title className="m-0" as="h3">
                    Top Offenses
                </Card.Title>
            </Card.Header>
            <Card.Body className="overflow-auto" data-testid="offenses-histogram-container" style={{ height: "28em" }}>
                {isFetching ? (
                    <InsightsSpinner />
                ) : processedOffensesBuckets && processedOffensesBuckets.labels && processedOffensesBuckets.labels.length ? (
                    <Histogram
                        title=""
                        processedData={processedOffensesBuckets || {}}
                        xAxis=""
                        yAxis={`Number of ${incidentTextPlural}`}
                        onPointClick={
                            isClickable
                                ? (e: any) => {
                                      const newSearchParams = new URLSearchParams(searchParams);
                                      newSearchParams.set("offenses", encodeURIComponent(e.point.category));
                                      newSearchParams.set("indexType", "incidents");
                                      window.open(`/app/search?${newSearchParams}`, "_blank");
                                  }
                                : undefined
                        }
                    />
                ) : (
                    <h1 className="text-center" data-testid="no-offenses-text">
                        No {incidentTextPlural}
                    </h1>
                )}
            </Card.Body>
        </Card>
    );
};

export default OffensesHistogram;
