import React from "react";
import MultiSelectFilter from "./MultiSelectFilter";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import { useFilters } from "../../hooks/useFilters";

// Value and label for the Multi-select dropdown and then url props for beats
type OptionType = {
    agency_desc: string;
    agency_id: string;
    beat_name: string;
    value: string;
    label: string;
};

const BeatsFilterConnected = ({ isSingleAgencyMode, includeGeometry }: { isSingleAgencyMode?: boolean; includeGeometry?: boolean }) => {
    const { filters, beatsData } = useFilters({ isSingleAgencyMode: isSingleAgencyMode, includeGeometry: includeGeometry });
    const { isMultiAgency } = useMultiAgency();

    // construct the available options for the dropdown to only have the props we want in the url mainly getting rid of es_docs and geometry data
    // Multi agency naming convention is used to display the beat properly and filter the map layers for map search
    const options: OptionType[] = React.useMemo(() => {
        return beatsData?.results
            ?.map((beatData: any) => {
                return {
                    agency_desc: beatData.agency_desc,
                    agency_id: beatData.agency_id,
                    beat_name: beatData.beat_name,
                    value: isMultiAgency && !isSingleAgencyMode ? beatData.agency_desc + "-" + beatData.beat_name : beatData.beat_name,
                    label: isMultiAgency && !isSingleAgencyMode ? beatData.agency_desc + "-" + beatData.beat_name : beatData.beat_name,
                };
            })
            .sort((a: any, b: any) => {
                return a.label < b.label ? -1 : 1;
            });
    }, [beatsData?.results, isMultiAgency, isSingleAgencyMode]);

    // pre-build selected options since multi-agency situations require a new display name to be created to display properly in the dropdown
    // only adding the 3 props we use in the url and ditching es_docs and geometry data
    const selectedOptions: OptionType[] = React.useMemo(() => {
        if (beatsData?.results?.length > 0 && !!filters && !!filters.beats && beatsData?.success) {
            return beatsData?.results
                .filter((beat: any) => {
                    return filters.beats.find(
                        (filterBeat: any) => filterBeat.agency_desc === beat.agency_desc && filterBeat.beat_name === beat.beat_name
                    );
                })
                .map((beat: any) => {
                    return {
                        agency_desc: beat.agency_desc,
                        agency_id: beat.agency_id,
                        beat_name: beat.beat_name,
                        value: isMultiAgency && !isSingleAgencyMode ? beat.agency_desc + "-" + beat.beat_name : beat.beat_name,
                        label: isMultiAgency && !isSingleAgencyMode ? beat.agency_desc + "-" + beat.beat_name : beat.beat_name,
                    };
                });
        }
    }, [beatsData?.results, beatsData?.success, filters, isMultiAgency, isSingleAgencyMode]);

    if (beatsData?.results?.length > 0) {
        return <BeatsFilter options={options} selectedOptions={selectedOptions} />;
    } else {
        return null;
    }
};

const BeatsFilter = ({ options, selectedOptions }: { options: any[]; selectedOptions: any[] }) => {
    return (
        <MultiSelectFilter
            pendoClass="pendo_beats_filter_select"
            options={options}
            urlParamName={"beats"}
            inputPlaceholder={"Select Beats"}
            prebuiltSelectedOptions={selectedOptions}
            addParamToUrl={false}
        />
    );
};

export default BeatsFilterConnected;
