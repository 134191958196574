import React from "react";
import { Alert, Button, Modal, OverlayTrigger, Stack, Table, Tooltip } from "react-bootstrap";
import { Field, FieldAttributes, Form, Formik } from "formik";
import { add } from "date-fns";
import { convertDateToLocalUTC, formatDate, formatDateToISO } from "../../utils/date";
import { useDeleteFollowMutation, useGetFollowsHitsQuery, useResetFollowTimestampMutation, useSaveFollowMutation } from "../../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBan,
    faBullseyeArrow,
    faPlusCircle,
    faRotateLeft,
    faSave,
    faTrashAlt,
    faUpRightFromSquare,
} from "@fortawesome/pro-light-svg-icons";
import LoadingModal from "../../components/LoadingModal";
import usePermissions from "../../hooks/usePermissions";
import { useNavigate } from "react-router-dom";

const now = new Date();

const FollowsConnected = () => {
    const { data, error, isLoading, isFetching } = useGetFollowsHitsQuery(undefined);
    const [deleteFn] = useDeleteFollowMutation();

    const deleteFollow = (id: number) => {
        deleteFn({ id });
    };

    if (!!error) {
        return <Alert variant="danger">Server error retrieving follows</Alert>;
    }

    return <Follows follows={(data && data.follows_hits) || []} loading={isLoading || isFetching} deleteFn={deleteFollow} />;
};

interface ownProps {
    follows: Array<any>;
    loading: boolean;
    deleteFn: Function;
}

type Follow = { id: number; text: string; saved_date: string; callCount: number };

/**
 * Manages the text the user wants to follow.  A follow is a text string used to query the call and incidents indexes along with the
 * timestamp that the follow was saved.  The timestamp is used to filter the search to look for calls and incidents
 * that contain the follow string after the saved timestamp.  The idea is to display a badge and let the user know when the followed
 * string appears in calls or incidents since the time the follow was saved.
 * @param follows List of follow objects {id, text, savedDate, eventCount}
 * @param loading
 * @param deleteFn
 * @constructor
 */
export const Follows = ({ follows, loading, deleteFn }: ownProps) => {
    const [show, setShow] = React.useState(false); // show/hide follow input modal
    const [showAlert, setShowAlert] = React.useState(false); // alert messages
    const [alertMessage, setAlertMessage] = React.useState("");
    const [showConfirm, setShowConfirm] = React.useState(false); // show/hide delete confirmation modal
    const [saveFn] = useSaveFollowMutation();
    const [resetFollowTimestamp] = useResetFollowTimestampMutation();
    const [delObject, setDelObject] = React.useState<{ id: number; text: string }>({ id: -1, text: "" }); // holds the item delete info
    const inputRef = React.useRef<FieldAttributes<any>>(null); // ref to the text input to set focus
    const { hasPermission } = usePermissions();
    const navigate = useNavigate();

    const save = (text: string): void => {
        if (!follows.some((item) => item.text?.toLowerCase() === text.toLowerCase())) {
            saveFn({ text });
        } else {
            setAlertMessage(`An alert has already been set for: ${text}`);
            setShowAlert(true);
        }
    };

    // focuses the text input when the input modal is activated
    React.useEffect(() => {
        if (show) {
            return inputRef.current?.focus();
        }
    }, [show]);

    // Opens confirm delete modal and sets the id of the follow object in a state variable so when confirm
    // is clicked, the deleteFn is run with that id.
    const confirmDelete = (idIn: number, text: string) => {
        setShowConfirm(true);
        setDelObject({ id: idIn, text });
    };

    // When the eventCount is > 0 on a follow object, a url is created to run the search query when the user
    // clicks on the follow text
    const goTo = (follow: Follow) => {
        const urlParams = new URLSearchParams();
        urlParams.set("query", follow.text);

        // If the user has the next fe search permission we need to format the date params to match the new style
        // also using window.location.assign for next fe routing instead of the navigate hook
        if (hasPermission("use_nextfe_search")) {
            urlParams.set(
                "search_settings",
                JSON.stringify({
                    occurred_at: {
                        gte: convertDateToLocalUTC(follow.saved_date).toISOString(),
                        lte: formatDateToISO(add(now, { days: 1 })),
                    },
                })
            );

            window.location.assign(`/search/results/events?${urlParams.toString()}`);
        } else {
            urlParams.set("sort", "relevance");
            urlParams.set("dateRange", "custom");
            urlParams.set("startDate", convertDateToLocalUTC(follow.saved_date).toISOString());
            urlParams.set("endDate", formatDateToISO(add(now, { days: 1 })));

            navigate(`/app/search?${urlParams.toString()}`);
        }
    };

    return (
        <>
            <h2 className="mt-3">
                <FontAwesomeIcon icon={faBullseyeArrow} />
                &nbsp;Follows
            </h2>
            {showAlert ? (
                <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible>
                    <Alert.Heading>Warning: Did Not Save Alert</Alert.Heading>
                    <p>{alertMessage}</p>
                </Alert>
            ) : (
                []
            )}
            <LoadingModal show={loading} />
            <Table responsive>
                <thead className="sticky-header">
                    <tr>
                        <th className="col-10">Followed Searches</th>
                        <th className="col-1"> </th>
                        <th className="col-1 text-center">
                            <Button
                                data-testid="addButton"
                                variant="primary"
                                onClick={() => {
                                    setShow(true);
                                }}
                                size="sm"
                            >
                                <FontAwesomeIcon icon={faPlusCircle} /> Add
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {follows &&
                        follows.length > 0 &&
                        follows.map((follow, idx) => (
                            <tr key={follow.id}>
                                <td className="cursor-pointer" onClick={() => goTo(follow)}>
                                    <Stack direction="horizontal" gap={3}>
                                        {Object.prototype.hasOwnProperty.call(follow, "eventCount") && follow.eventCount !== 0 && (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="follow-hit-tooltip">Recent Activity Found. Click to See Details.</Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon icon={faBullseyeArrow} className="text-danger" size="lg" />
                                            </OverlayTrigger>
                                        )}
                                        <Stack direction="horizontal" gap={1}>
                                            <FontAwesomeIcon
                                                icon={faUpRightFromSquare}
                                                className={`${
                                                    Object.prototype.hasOwnProperty.call(follow, "eventCount") && follow.eventCount !== 0
                                                        ? "text-danger"
                                                        : "text-primary"
                                                } me-2`}
                                            />
                                            <Stack>
                                                {Object.prototype.hasOwnProperty.call(follow, "eventCount") && follow.eventCount !== 0 ? (
                                                    <span className="text-danger">{follow.text}</span>
                                                ) : (
                                                    <span>{follow.text}</span>
                                                )}
                                                <h6
                                                    className={
                                                        Object.prototype.hasOwnProperty.call(follow, "eventCount") &&
                                                        follow.eventCount !== 0
                                                            ? "text-danger"
                                                            : "text-muted"
                                                    }
                                                >
                                                    {formatDate(follow.saved_date)}
                                                </h6>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </td>
                                <td className="text-center">
                                    <Button
                                        className="text-nowrap"
                                        variant="primary"
                                        data-testid={`deleteButton-${idx}`}
                                        onClick={() => confirmDelete(follow.id, follow.text)}
                                        size="sm"
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                    </Button>
                                </td>
                                <td className="text-center">
                                    <Button
                                        className="text-nowrap"
                                        variant="primary"
                                        data-testid={`clearButton-${idx}`}
                                        onClick={() => resetFollowTimestamp({ id: follow.id })}
                                        size="sm"
                                    >
                                        <FontAwesomeIcon icon={faRotateLeft} /> Reset
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    {follows && follows.length === 0 && (
                        <tr>
                            <td colSpan={3} className="text-center">
                                Click Add to Follow Something
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Modal show={show} onHide={() => setShow(false)} data-testid="addModal">
                <Modal.Header closeButton>Add text you would like to follow i.e.: name, address, license plate number, etc.</Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{ follow: "" }}
                        onSubmit={({ follow }) => {
                            if (follow) {
                                save(follow);
                                setShow(false);
                            }
                        }}
                    >
                        {({ errors, touched, values }) => (
                            <Form>
                                <div className="mb-3">
                                    <label>Follow Text</label>
                                    <Field name="follow" type="text" className="form-control shadow-none" innerRef={inputRef} />
                                </div>
                                {errors.follow && touched.follow ? <div className="text-danger">{errors.follow}</div> : null}
                                <Button variant="primary" className="float-end" type="submit">
                                    <FontAwesomeIcon icon={faSave} /> Save
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <Modal show={showConfirm} onHide={() => setShowConfirm(false)} data-testid="deleteModal">
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure you want to delete:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{delObject.text}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="float-end" onClick={() => setShowConfirm(false)}>
                        <FontAwesomeIcon icon={faBan} /> Cancel
                    </Button>
                    <Button
                        variant="danger"
                        className="float-end"
                        onClick={() => {
                            deleteFn(delObject.id);
                            setShowConfirm(false);
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FollowsConnected;
