import { faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Button } from "react-bootstrap";

type OwnProps = {
    message: string;
    setMessage: Function;
    title: string;
    setTitle: Function;
    usersToShare: any[];
    toggleUser: Function;
    toSectionMaxHeight: string;
};
const ShareMessage = ({ message, setMessage, title, setTitle, usersToShare, toggleUser, toSectionMaxHeight }: OwnProps) => {
    return (
        <>
            <span className="fs-2 fw-bold">Compose message</span>
            <Stack gap={1} direction="horizontal" className="border rounded mb-3 ps-2">
                <span>Subject:</span>
                <input className="border-0 form-control" maxLength={100} onChange={(e) => setTitle(e.target.value)} value={title} />
            </Stack>
            <div className="border rounded p-2 mb-3">
                <Stack gap={1} direction="horizontal" className="flex-wrap overflow-auto" style={{ maxHeight: toSectionMaxHeight }}>
                    <span>To:</span>
                    {usersToShare.map((email) => (
                        <Button key={email} size="sm" variant="secondary" className="fs-5 rounded-pill" onClick={() => toggleUser(email)}>
                            {email}
                            <FontAwesomeIcon size="lg" icon={faCircleXmark} className="ms-1" />
                        </Button>
                    ))}
                </Stack>
            </div>
            <textarea
                onChange={(e: any) => setMessage(e.target.value)}
                value={message}
                className="d-flex form-control flex-grow-1"
                placeholder="Optional message to attach to shared entity"
                maxLength={2000}
            />
        </>
    );
};

export default ShareMessage;
