import React from "react";
import { Formik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useCreateUserMutation } from "../../api/api";
import ErrorBoundary from "../../pages/support/errorboundary/ErrorBoundary";
import ModalCenter from "../modalcenter/ModalCenter";
import { faPaperPlane, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddUserModal = ({
    showModal,
    toggleModal,
    setAndShowAlert,
    showSecurityLevel,
}: {
    showModal: boolean;
    toggleModal: () => void;
    setAndShowAlert: (title: string, message: string, variant: string) => void;
    showSecurityLevel: boolean;
}) => {
    const initial = {
        email: "",
        firstName: "",
        lastName: "",
        role: "",
        securityLevel: showSecurityLevel ? 0 : null,
    };

    const [createUser, { isLoading }] = useCreateUserMutation();

    const submitNewUser = (values: any) => {
        createUser(values).then((response: any) => {
            if (!response?.data?.success) {
                setAndShowAlert("User Creation", "An error has occurred, please try again.", "warning");
                toggleModal();
            } else {
                setAndShowAlert("User Creation", "Onboarding email sent successfully!", "success");
                toggleModal();
            }
        });
    };

    return (
        <ModalCenter title="Create User" show={showModal} setShow={() => toggleModal()}>
            <ErrorBoundary>
                <>
                    <Formik initialValues={initial} onSubmit={submitNewUser}>
                        {({ handleChange, handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group className="mb-5" controlId="formFirstName">
                                            <Form.Label className="w-100">First Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="firstName"
                                                onChange={handleChange}
                                                autoCapitalize="none"
                                                autoFocus={true}
                                                data-testid="firstName"
                                                required={true}
                                                disabled={isLoading}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-5" controlId="formLastName">
                                            <Form.Label className="w-100">Last Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="lastName"
                                                onChange={handleChange}
                                                autoCapitalize="none"
                                                data-testid="lastName"
                                                required={true}
                                                disabled={isLoading}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-5" controlId="formEmail">
                                        <Form.Label className="w-100">Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            autoCapitalize="none"
                                            data-testid="email"
                                            required={true}
                                            disabled={isLoading}
                                        />
                                    </Form.Group>
                                </Row>
                                {showSecurityLevel && (
                                    <Row>
                                        <Form.Group className="mb-5" controlId="formSecurityLevel">
                                            <Form.Label className="w-100">Security Level</Form.Label>
                                            <Form.Select
                                                defaultValue={0}
                                                onChange={(e) =>
                                                    handleChange({
                                                        target: {
                                                            name: "securityLevel",
                                                            value: parseInt(e.target.value),
                                                        },
                                                    })
                                                }
                                                name="securityLevel"
                                            >
                                                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                )}
                                <Row className="d-flex justify-content-end">
                                    <Button variant="danger" onClick={toggleModal} className="w-25 me-2">
                                        Cancel
                                    </Button>
                                    <Button disabled={isLoading} variant="success" type="submit" className="text-nowrap w-25 ms-2 me-4">
                                        <FontAwesomeIcon icon={isLoading ? faSpinner : faPaperPlane} className="me-2" />
                                        Submit
                                    </Button>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </>
            </ErrorBoundary>
        </ModalCenter>
    );
};

export default AddUserModal;
