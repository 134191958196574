import { faUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Popover } from "react-bootstrap";
import React from "react";
import { ConstructedURLType } from "../../types/types";
import LinkWrapper from "../LinkWrapper";

type OwnProps = {
    link?: string;
    constructedURL?: ConstructedURLType;
    disabled?: boolean;
    child: any;
    useLinkStyle?: boolean;
    newTabLink?: boolean;
    includePopover?: boolean;
    popoverTitle?: string;
    popoverMessage?: string;
};

const TableCellLinkWrapper = ({
    link,
    constructedURL,
    disabled,
    child,
    useLinkStyle,
    newTabLink,
    includePopover,
    popoverTitle,
    popoverMessage,
}: OwnProps) => {
    return disabled ? (
        child
    ) : (
        <>
            {useLinkStyle && newTabLink ? (
                <FontAwesomeIcon
                    icon={faUpRightFromSquare}
                    className="text-primary me-2 mt-1 d-inline-block"
                    onClick={() => window.open(link, "_blank")}
                />
            ) : null}
            {includePopover ? (
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    delay={250}
                    placement="top"
                    rootClose
                    overlay={
                        <Popover>
                            {popoverTitle && <Popover.Header>{popoverTitle}</Popover.Header>}
                            <Popover.Body>{popoverMessage}</Popover.Body>
                        </Popover>
                    }
                >
                    <div>{child}</div>
                </OverlayTrigger>
            ) : (
                <LinkWrapper
                    isAnchor={!link && !!constructedURL?.isNextFELink}
                    to={link ? link : constructedURL?.url || "#"}
                    className={
                        useLinkStyle ? "d-inline-block text-primary text-decoration-underline" : "d-block text-reset text-decoration-none"
                    }
                    target={newTabLink ? "_blank" : ""}
                >
                    {child}
                </LinkWrapper>
            )}
        </>
    );
};

export default TableCellLinkWrapper;
