import React from "react";
import { v4 as uuid } from "uuid";
import { Alert, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { formatBirthdayAndDOB, formatGender, formatRace } from "../../utils/person";
import { renderOrDash } from "../../utils/display";
import { useColumnSort } from "../../hooks/useColumnSort";
import { sortSortableHeader } from "../../utils/sort";
import { formatDate, SHORT_DATE } from "../../utils/date";
import { usePagination } from "../../hooks/usePagination";

import { isBefore, parseISO } from "date-fns";
import usePermissions from "../../hooks/usePermissions";
import { useSearchParams } from "react-router-dom";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import SortableHeaderColumn from "../SortableHeader";
import TableCellLinkWrapper from "../tablecelllinkwrapper/TableCellLinkWrapper";
import Highlighter from "../highlighter/Highlighter";
import InternalIncidentsTable from "../associatedpeople/InternalIncidentsTable";
import InternalArrestsTable from "../associatedpeople/InternalArrestsTable";
import { FMPaginationNonLinks } from "../pagination/Pagination";
import { ASCENDING, DESCENDING } from "../../utils/old_v1/sort";
import { SortDirectionEnum } from "../../types/types";

type OwnProps = {
    people: any[];
    person?: any;
    query?: string;
    showRole?: boolean;
    showIncidents?: boolean;
    showIncidentsDropdown?: boolean;
    hideFilter: boolean;
    newTabLink?: boolean;
    scrollToTopFn: Function;
    arrests?: any[];
    showArrests?: boolean;
    showArrestsDropdown?: boolean;
    agencyId: string;
};

const DEFAULT_SORT = "numberIncidents";
const DEFAULT_SORT_ORDER = SortDirectionEnum.DESCENDING;

const BaseAssociatedPeople = ({
    people,
    person,
    arrests,
    query,
    showRole,
    showIncidents,
    showIncidentsDropdown,
    showArrestsDropdown,
    showArrests,
    hideFilter,
    newTabLink,
    scrollToTopFn,
    agencyId,
}: OwnProps) => {
    const [params] = useSearchParams();
    const { constructPersonUrl, constructEventUrl } = useMultiAgency();
    const [personFilterString, setPersonFilterString] = React.useState("");
    const { sortColumn, sortDirection, setSort } = useColumnSort(DEFAULT_SORT, DEFAULT_SORT_ORDER);
    const [openRows, setOpenRows] = React.useState<string[]>([]);
    const associatedPeopleTableRef = React.useRef<HTMLTableElement | null>(null);
    const { hasPermission } = usePermissions();

    const disabledCell = showIncidentsDropdown || (hasPermission("arrest_details") && showArrestsDropdown);

    const rowClick = (id: string) => {
        const arrestCount = arrests?.filter((arrest) => arrest.person_id === id).length;
        if (showIncidentsDropdown || (hasPermission("arrest_details") && showArrestsDropdown && arrestCount)) {
            if (openRows?.includes(id)) {
                const newRows = [...openRows];
                const index = newRows.indexOf(id);
                if (index > -1) {
                    newRows.splice(index, 1);
                }

                setOpenRows(newRows);
            } else {
                const newRows = [...openRows];
                newRows.push(id);
                setOpenRows(newRows);
            }
        } else {
            return null;
        }
    };

    let sortedPeople = (Array.isArray(people) ? [...people] : [])
        .filter((person) => {
            if (!personFilterString) {
                return true;
            }
            const lowercaseFilter = personFilterString.toLowerCase();
            return (
                `${person?.first_name} ${person?.middle_name} ${person?.last_name}`.toLowerCase().includes(lowercaseFilter) ||
                `${person?.first_name} ${person?.last_name}`.toLowerCase().includes(lowercaseFilter)
            );
        })
        .sort(sortSortableHeader(sortColumn, sortColumn !== "numberIncidents", sortDirection));

    // ensure page does not scroll to top of card on page load if not in modal view
    const pageResetDependencies = React.useMemo(
        () => (!!scrollToTopFn ? null : { sortColumn, sortDirection, personFilterString, scrollToId: "associatedPeopleFilterInput" }),
        [scrollToTopFn, sortColumn, sortDirection, personFilterString]
    );
    const {
        currentPage,
        setCurrentPage,
        currentPageData: currentPagePeople,
        totalPages,
    } = usePagination(sortedPeople, 20, pageResetDependencies, scrollToTopFn);

    if (!!person) {
        // if we have a person, we want to filter them to the top of the list if they exist
        // we can have multiple rows for the same person depending on the agency.
        const personRows = sortedPeople.filter((row) => person.id === row.person_id).map((row) => ({ ...row, currentPerson: true }));
        const otherPeopleRows = sortedPeople.filter((row) => person.id !== row.person_id);
        sortedPeople = [...personRows, ...otherPeopleRows];
    }

    const filterPeopleList = (event: any) => {
        setPersonFilterString(event.target.value);
    };

    const resultsMessage = `${sortedPeople.length !== people.length ? `displaying ${sortedPeople.length} of ` : ""}${
        people.length
    } results`;

    const popoverTitle = "Missing ID";
    const popoverMessage = "This person does not have an ID in the source data. Viewing person details is disabled.";

    const getIncidentLink = (item: any) => {
        if (item && item.incidents && item.incidents.length) {
            return constructEventUrl(
                agencyId,
                undefined,
                item.incidents[0].incident_number,
                !!query ? new URLSearchParams({ query }) : undefined
            );
        }
        return "";
    };

    return (
        <>
            {!hideFilter && (
                <>
                    <input
                        name="associated-people-filter-input"
                        className="mb-1 form-control"
                        onChange={filterPeopleList}
                        placeholder="Filter by Name"
                    />
                    <span>{resultsMessage}</span>
                </>
            )}
            {sortedPeople.length > 0 ? (
                <>
                    <Table responsive className="flex-grow-1 overflow-auto" ref={associatedPeopleTableRef}>
                        <thead className="sticky-header">
                            <tr>
                                {(showIncidentsDropdown || (hasPermission("arrest_details") && showArrestsDropdown)) && <th></th>}
                                <SortableHeaderColumn
                                    columnName="Name"
                                    columnClass={"name-column"}
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="last_name,first_name,middle_name"
                                    defaultDirection={ASCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="DOB (Age)"
                                    columnClass={"dob-column"}
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="birthdate"
                                    defaultDirection={ASCENDING}
                                />
                                {showIncidents && (
                                    <SortableHeaderColumn
                                        columnName="Linked Incidents"
                                        sortFn={setSort}
                                        activeSortField={sortColumn}
                                        activeSortDirection={sortDirection}
                                        sortKey="numberIncidents"
                                        defaultDirection={DESCENDING}
                                    />
                                )}
                                <SortableHeaderColumn
                                    columnName="Race"
                                    columnClass={"race-column"}
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="race"
                                    defaultDirection={ASCENDING}
                                />
                                <SortableHeaderColumn
                                    columnName="Gender"
                                    columnClass={"gender-column"}
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey="gender"
                                    defaultDirection={ASCENDING}
                                />
                                {showRole && (
                                    <SortableHeaderColumn
                                        columnName="Role"
                                        sortFn={setSort}
                                        activeSortField={sortColumn}
                                        activeSortDirection={sortDirection}
                                        sortKey="role"
                                        defaultDirection={ASCENDING}
                                    />
                                )}
                                {showIncidents && (
                                    <SortableHeaderColumn
                                        columnName="Last Incident"
                                        sortFn={setSort}
                                        activeSortField={sortColumn}
                                        activeSortDirection={sortDirection}
                                        sortKey="lastIncident"
                                        defaultDirection={DESCENDING}
                                    />
                                )}
                                {hasPermission("arrest_details") && showArrests && (
                                    <SortableHeaderColumn
                                        columnName="Charges"
                                        sortFn={setSort}
                                        activeSortField={sortColumn}
                                        activeSortDirection={sortDirection}
                                        sortKey="arrests"
                                        defaultDirection={ASCENDING}
                                    />
                                )}
                            </tr>
                        </thead>
                        <tbody className="overflow-auto">
                            {currentPagePeople.map((row: any) => {
                                const constructedPersonURL = constructPersonUrl(
                                    agencyId,
                                    { ...row, source: { ...row?.source, agency_id: person?.source?.agency_id } },
                                    params
                                );
                                if (row.incidents) {
                                    row.incidents = row.incidents.sort((a: any, b: any) =>
                                        isBefore(parseISO(a.occurred_at), parseISO(b.occurred_at)) ? 1 : -1
                                    );
                                }

                                const associateName = row.first_name ? row.first_name : row.full_name;
                                const personName = person ? (person?.first_name ? person?.first_name : person?.full_name) : "--";
                                const isOpenRow = openRows.includes(row.person_id);
                                const isOpenRowTdClass = isOpenRow ? "border-0" : "";
                                const arrestCount = arrests?.filter((arrest) => arrest.person_id === row.person_id).length;
                                const showCaret =
                                    showIncidentsDropdown || (hasPermission("arrest_details") && showArrestsDropdown && !!arrestCount);

                                const constructedURL = row.noId ? undefined : constructedPersonURL;

                                return (
                                    // in some agencies, the person comes up multiple times in the involved people list, this is to give react unique keys
                                    <React.Fragment key={`${row.person_id}-${uuid()}`}>
                                        <tr
                                            className={`${row.noId && !showIncidentsDropdown ? "cursor-help" : "cursor-pointer"} ${
                                                row.currentPerson ? "table-highlight" : ""
                                            } ${isOpenRow ? "border-bottom-0" : ""}`}
                                            onClick={() => rowClick(row.person_id)}
                                        >
                                            {showCaret && (
                                                <td className={isOpenRowTdClass}>
                                                    {isOpenRow ? (
                                                        <FontAwesomeIcon
                                                            icon={faCaretDown as any}
                                                            className="mt-1"
                                                            onClick={() => rowClick(row.person_id)}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={faCaretRight as any}
                                                            className="mt-1"
                                                            onClick={() => rowClick(row.person_id)}
                                                        />
                                                    )}
                                                </td>
                                            )}
                                            {hasPermission("arrest_details") && showArrestsDropdown && !arrestCount && <td></td>}
                                            <td className={`text-nowrap ${isOpenRowTdClass}`}>
                                                <TableCellLinkWrapper
                                                    disabled={showIncidentsDropdown}
                                                    constructedURL={constructedURL}
                                                    child={<Highlighter>{row.full_name ? row.full_name : "Unknown"}</Highlighter>}
                                                    useLinkStyle={!row.noId}
                                                    newTabLink={row.noId ? undefined : newTabLink}
                                                    includePopover={row.noId}
                                                    popoverTitle={popoverTitle}
                                                    popoverMessage={popoverMessage}
                                                />
                                            </td>

                                            <td className={`text-nowrap ${isOpenRowTdClass}`}>
                                                <TableCellLinkWrapper
                                                    disabled={disabledCell}
                                                    constructedURL={constructedURL}
                                                    child={renderOrDash(formatBirthdayAndDOB(row && row.birthdate))}
                                                    newTabLink={row.noId ? undefined : newTabLink}
                                                    includePopover={row.noId}
                                                    popoverTitle={popoverTitle}
                                                    popoverMessage={popoverMessage}
                                                />
                                            </td>
                                            {showIncidents && (
                                                <td className={`text-center ${isOpenRowTdClass}`}>
                                                    <TableCellLinkWrapper
                                                        disabled={disabledCell}
                                                        constructedURL={constructedURL}
                                                        child={row.numberIncidents}
                                                        newTabLink={newTabLink}
                                                        includePopover={row.noId}
                                                        popoverTitle={popoverTitle}
                                                        popoverMessage={popoverMessage}
                                                    />
                                                </td>
                                            )}
                                            <td className={isOpenRowTdClass}>
                                                <TableCellLinkWrapper
                                                    disabled={disabledCell}
                                                    constructedURL={constructedURL}
                                                    child={formatRace(row.race)}
                                                    newTabLink={row.noId ? undefined : newTabLink}
                                                    includePopover={row.noId}
                                                    popoverTitle={popoverTitle}
                                                    popoverMessage={popoverMessage}
                                                />
                                            </td>
                                            <td className={isOpenRowTdClass}>
                                                <TableCellLinkWrapper
                                                    disabled={disabledCell}
                                                    constructedURL={constructedURL}
                                                    child={formatGender(row.gender)}
                                                    newTabLink={row.noId ? undefined : newTabLink}
                                                    includePopover={row.noId}
                                                    popoverTitle={popoverTitle}
                                                    popoverMessage={popoverMessage}
                                                />
                                            </td>
                                            {showRole && (
                                                <td className={isOpenRowTdClass}>
                                                    <TableCellLinkWrapper
                                                        disabled={disabledCell}
                                                        constructedURL={constructedURL}
                                                        child={renderOrDash(row.role)}
                                                        newTabLink={row.noId ? undefined : newTabLink}
                                                        includePopover={row.noId}
                                                        popoverTitle={popoverTitle}
                                                        popoverMessage={popoverMessage}
                                                    />
                                                </td>
                                            )}
                                            {showIncidents && (
                                                <>
                                                    <td className={isOpenRowTdClass}>
                                                        <TableCellLinkWrapper
                                                            disabled={disabledCell}
                                                            link={getIncidentLink(row)}
                                                            child={formatDate(row.lastIncident, undefined, SHORT_DATE)}
                                                            newTabLink={newTabLink}
                                                            useLinkStyle={true}
                                                        />
                                                    </td>
                                                </>
                                            )}
                                            {hasPermission("arrest_details") && showArrests && (
                                                <>
                                                    <td className={isOpenRowTdClass}>
                                                        <TableCellLinkWrapper
                                                            disabled={disabledCell}
                                                            constructedURL={constructedURL}
                                                            child={
                                                                arrests?.filter((arrest) => arrest.person_id === row.person_id).length || 0
                                                            }
                                                            newTabLink={newTabLink}
                                                        />
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                        {isOpenRow && (
                                            <tr>
                                                {showIncidentsDropdown && (
                                                    <td colSpan={12} className="border-0">
                                                        <InternalIncidentsTable
                                                            showIncidentsDropdown={showIncidentsDropdown}
                                                            openRows={openRows}
                                                            row={row}
                                                            personName={personName}
                                                            associateName={associateName}
                                                            person={person}
                                                            query={query}
                                                            newTabLink={newTabLink}
                                                        />
                                                    </td>
                                                )}
                                                {hasPermission("arrest_details") && showArrestsDropdown && (
                                                    <td colSpan={12} className="border-0">
                                                        <InternalArrestsTable
                                                            showArrestsDropdown={showArrestsDropdown}
                                                            openRows={openRows}
                                                            row={row}
                                                            arrests={arrests?.filter((arrest) => arrest.person_id === row.person_id)}
                                                        />
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </Table>
                    {totalPages > 1 && (
                        <div className="d-flex justify-content-end mt-2">
                            <FMPaginationNonLinks
                                page={currentPage + 1}
                                setPage={(page: number) => {
                                    setCurrentPage(page - 1);
                                    document.getElementById("associatedPeopleModalBody")?.scrollTo({ top: 0 });
                                }}
                                totalPages={totalPages}
                            />
                        </div>
                    )}
                </>
            ) : (
                <Alert variant="secondary" className="text-center">
                    No associated people.
                </Alert>
            )}
        </>
    );
};

export default BaseAssociatedPeople;
