export const getUniqueAssociatedPeopleFromEvents = (events: any[], personId: string = ""): any[] => {
    const associatedPeopleObject: any = {};

    events?.forEach((event) => {
        if (event.source.involved_people) {
            event.source.involved_people?.forEach((person: any) => {
                if (!associatedPeopleObject[person.person_id] && personId !== person.person_id) {
                    associatedPeopleObject[person.person_id] = person;
                }
            });
        }
    });

    return Object.values(associatedPeopleObject);
};

/**
 * If the given address link does not point to main search & does not contain an address and no GPS coordinates, this is a completely unknown address
 * @param addressLink
 * @returns
 */
export const getAddressLinkIsDisabled = (addressLink: string) => {
    try {
        const addressUrl = new URL(`${window.location.origin}${addressLink}`);
        const isMainSearch = addressUrl.pathname === "/app/search";
        const addressUrlParams = addressUrl.searchParams;
        return !isMainSearch && !addressUrlParams.get("address") && (!addressUrlParams.get("gpsLon") || !addressUrlParams.get("gpsLat"));
    } catch {
        return true;
    }
};
