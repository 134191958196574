import React from "react";
import { useGetUniqueCallSourcesQuery } from "../../api/api";
import { processFilterAgg } from "../../utils/helpers";
import MultiSelectFilter from "./MultiSelectFilter";
import { byNameSort, TAggregationType } from "../../hooks/useSearchFiltersAggregations";
import aggregationDataPresenceCount from "../../utils/aggregationDataPresenceCount";
import useAgencySettings from "../../hooks/useAgencySettings";

const CallSourcesFilterConnected = ({ callSourceAggregation }: { callSourceAggregation?: Array<TAggregationType> }) => {
    const { data: optionData } = useGetUniqueCallSourcesQuery({});
    const { callText } = useAgencySettings();
    const callSources = processFilterAgg(optionData);

    const options = React.useMemo(
        () =>
            callSources
                .map((callSource: { id: string; option: string }) => {
                    const { present, count } = aggregationDataPresenceCount(callSourceAggregation, callSource?.option);

                    return {
                        id: callSource?.id,
                        value: callSource?.option,
                        name: callSource?.option,
                        present,
                        count,
                    };
                })
                .sort(byNameSort),
        [callSources, callSourceAggregation]
    );

    return (
        <MultiSelectFilter
            ariaLabel="Call Source"
            pendoClass="pendo_call_sources_filter_select"
            options={options}
            urlParamName={"callSources"}
            inputPlaceholder={`Select ${callText} Sources`}
        />
    );
};

export default CallSourcesFilterConnected;
