import React from "react";
import { useGetUniqueIncidentTypesQuery } from "../../api/api";
import { processFilterAgg } from "../../utils/helpers";
import MultiSelectFilter from "./MultiSelectFilter";
import { TAggregationType, byNameSort } from "../../hooks/useSearchFiltersAggregations";
import aggregationDataPresenceCount from "../../utils/aggregationDataPresenceCount";
import useAgencySettings from "../../hooks/useAgencySettings";

const IncidentTypeFilterConnected = ({ incidentTypeAggregation }: { incidentTypeAggregation?: Array<TAggregationType> }) => {
    const { data: optionData } = useGetUniqueIncidentTypesQuery({});
    const incidentTypes = processFilterAgg(optionData);
    const { incidentText } = useAgencySettings();

    const options = React.useMemo(
        () =>
            incidentTypes
                .map((incidentType: { id: string; option: string }) => {
                    const { present, count } = aggregationDataPresenceCount(incidentTypeAggregation, incidentType?.id);

                    return {
                        id: incidentType?.id,
                        value: incidentType?.option,
                        name: incidentType?.option,
                        present,
                        count,
                    };
                })
                .sort(byNameSort),
        [incidentTypeAggregation, incidentTypes]
    );

    return (
        <MultiSelectFilter
            ariaLabel="Incident Type"
            pendoClass="pendo_incident_type_filter_select"
            options={options}
            urlParamName={"incidentTypes"}
            inputPlaceholder={`Select ${incidentText} Types`}
        />
    );
};

export default IncidentTypeFilterConnected;
