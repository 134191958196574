import { OverlayTrigger, Popover, Stack } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import React from "react";
import { formatBirthdateAndDeathDate, formatBirthdayAndDOB } from "../../utils/date";
import { formatName } from "../../utils/person";
import { renderOrDash, renderOrDashSpecialCase } from "../../utils/display";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import LinkWrapper from "../LinkWrapper";

type OwnProps = {
    aliases: any[];
    person: any;
};
const PersonAliases = ({ aliases, person }: OwnProps) => {
    const { constructPersonUrl } = useMultiAgency();
    const [search] = useSearchParams();
    return (
        <Stack gap={2} className="border-top px-3 py-2">
            <span className="fw-bold">Aliases</span>
            {aliases && aliases.length > 0 ? (
                aliases.map((alias, idx) => {
                    const aliasAgeLabel = alias?.source.deathdate ? "DOB/DOD" : "DOB";
                    const aliasAge = alias?.source.deathdate
                        ? formatBirthdateAndDeathDate(alias?.source.birthdate, alias?.source.deathdate)
                        : formatBirthdayAndDOB(alias?.source.birthdate);
                    const constructedPersonURL = constructPersonUrl(person?.agency_id, alias.source, search);

                    return (
                        <OverlayTrigger
                            key={alias.id}
                            placement="top-start"
                            overlay={
                                <Popover>
                                    <Popover.Header>{formatName(alias?.source)}</Popover.Header>
                                    <Popover.Body>
                                        <div>
                                            <strong>{aliasAgeLabel}</strong>
                                            <div>{aliasAge}</div>
                                        </div>
                                        <div>
                                            <strong>Race/Gender</strong>
                                            <div>{`${renderOrDash(alias?.source.race)}/ ${renderOrDash(alias?.source.gender)}`}</div>
                                        </div>
                                        <div>
                                            <strong>Height/Weight</strong>
                                            <div>
                                                {`${renderOrDash(alias?.source.height)}/${renderOrDashSpecialCase(
                                                    alias?.source.weight,
                                                    "0"
                                                )}`}
                                            </div>
                                        </div>
                                        <div>
                                            <strong>Hair/Eyes</strong>
                                            <div>{`${renderOrDash(alias?.source.hair)}/${renderOrDash(alias?.source.eyes)}`}</div>
                                        </div>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <LinkWrapper isAnchor={constructedPersonURL.isNextFELink} to={constructedPersonURL.url}>
                                {formatName(alias.source)}
                                {idx < aliases.length - 1 ? <br /> : ""}
                            </LinkWrapper>
                        </OverlayTrigger>
                    );
                })
            ) : (
                <span>--</span>
            )}
        </Stack>
    );
};

export default PersonAliases;
