import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isBefore, isEqual, parseISO, sub } from "date-fns";
import { formatDateToISO } from "../../utils/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import useIsMapPage from "../../hooks/useIsMapPage";

const now = new Date();

export const calculateDates = (dateRange: string, startDate: string, endDate: string, now: Date) => {
    let start, end;
    if (dateRange === "custom") {
        const parsedStart = parseISO(startDate);
        const parsedEnd = parseISO(endDate);
        start = parsedStart.toString() !== "Invalid Date" ? formatDateToISO(parseISO(startDate)) : "";
        end = parsedEnd.toString() !== "Invalid Date" ? formatDateToISO(parseISO(endDate)) : "";
    } else {
        end = dateRange === "custom" ? "" : formatDateToISO(now);
        switch (dateRange) {
            case "4days":
                start = formatDateToISO(sub(now, { days: 4 }));
                break;
            case "8days":
                start = formatDateToISO(sub(now, { days: 8 }));
                break;
            case "30days":
                start = formatDateToISO(sub(now, { days: 30 }));
                break;
            case "90days":
                start = formatDateToISO(sub(now, { days: 90 }));
                break;
            case "1year":
                start = formatDateToISO(sub(now, { years: 1 }));
                break;
            case "3years":
                start = formatDateToISO(sub(now, { years: 3 }));
                break;
            case "all_time":
                start = formatDateToISO(new Date("1900-01-01"));
                break;
            default:
                start = "";
        }
    }
    return {
        startDate: start,
        endDate: end,
    };
};

const DateRange = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const params = new URLSearchParams(searchParams);

    const { isMapPage } = useIsMapPage();
    const defaultDateRange = isMapPage ? "30days" : "all_time";

    const dateRange = params.get("dateRange") || defaultDateRange;
    const startDate = params.get("startDate") ?? "";
    const endDate = params.get("endDate") ?? "";
    const [range, setRange] = React.useState({ startDate: "", endDate: "" });
    const [selected, setSelected] = React.useState(dateRange);

    // Initially populates range
    React.useEffect(() => {
        const calculatedRange = calculateDates(dateRange, startDate, endDate, now);
        setRange(calculatedRange);
        setSelected(dateRange);
    }, [dateRange, startDate, endDate]);

    const handleOptionClick = (option: string) => {
        if (option === "custom") {
            setRange({ startDate: "", endDate: "" });
        } else {
            const calculatedRange = calculateDates(option, range.startDate, range.endDate, now);
            setRange(calculatedRange);
        }
        setSelected(option);
        apply(option);
    };

    const checkDateRangeValid = () => {
        return (
            !!range.startDate &&
            !!range.endDate &&
            (isBefore(parseISO(range.startDate), parseISO(range.endDate)) || isEqual(parseISO(range.startDate), parseISO(range.endDate)))
        );
    };

    const apply = (dateRange: string) => {
        //apply change only if date range changes
        if (dateRange !== "custom" && params.get("dateRange") !== dateRange) {
            params.set("dateRange", dateRange);
            params.delete("page");
            params.delete("startDate");
            params.delete("endDate");
            navigate(`.?${params}`);
        }
        if (dateRange === "custom") {
            if (params.get("dateRange") === "all_time" || checkDateRangeValid()) {
                params.set("dateRange", dateRange);
                params.set("startDate", range.startDate);
                params.set("endDate", range.endDate);
                params.delete("page");
                navigate(`.?${params}`);
            }
        }
    };

    const handleDateInput = (field: string, value: string) => {
        setSelected("custom");
        setRange({ ...range, ...{ [field]: value } });
    };

    const getOptionButtonVariant = (option: string) => {
        return selected === option ? "primary" : "outline-secondary";
    };

    return (
        <Col>
            <Row>
                <Col md={6} className="pb-3">
                    <Button
                        className="three-days-filter w-100 pendo_date_range_4_days_modal_filter_button"
                        variant={getOptionButtonVariant("4days")}
                        onClick={() => handleOptionClick("4days")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 4 Days
                    </Button>
                </Col>
                <Col md={6} className="pb-3">
                    <Button
                        className="seven-days-filter w-100 pendo_date_range_8_days_modal_filter_button"
                        variant={getOptionButtonVariant("8days")}
                        onClick={() => handleOptionClick("8days")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 8 Days
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="pb-3">
                    <Button
                        className="thirty-days-filter w-100 pendo_date_range_30_days_modal_filter_button"
                        variant={getOptionButtonVariant("30days")}
                        onClick={() => handleOptionClick("30days")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 30 days
                    </Button>
                </Col>
                <Col md={6} className="pb-3">
                    <Button
                        className="ninety-days-filter w-100 pendo_date_range_90_days_modal_filter_button"
                        variant={getOptionButtonVariant("90days")}
                        onClick={() => handleOptionClick("90days")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 90 days
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="pb-3">
                    <Button
                        className="one-year-filter w-100 pendo_date_range_1_year_modal_filter_button"
                        variant={getOptionButtonVariant("1year")}
                        onClick={() => handleOptionClick("1year")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 1 year
                    </Button>
                </Col>
                <Col md={6} className="pb-3">
                    <Button
                        className="three-years-filter w-100 pendo_date_range_3_years_modal_filter_button"
                        variant={getOptionButtonVariant("3years")}
                        onClick={() => handleOptionClick("3years")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> 3 years
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="pb-3">
                    <Button
                        className="all-time-filter w-100 pendo_date_range_all_time_modal_filter_button"
                        variant={getOptionButtonVariant("all_time")}
                        onClick={() => handleOptionClick("all_time")}
                    >
                        <FontAwesomeIcon icon={faCalendar} /> All Time
                    </Button>
                </Col>
                <Col md={6} className="pb-3">
                    <Button
                        className="custom-filter w-100 pendo_date_range_custom_modal_filter_button"
                        variant={getOptionButtonVariant("custom")}
                        onClick={() => handleOptionClick("custom")}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} /> Custom
                    </Button>
                </Col>
            </Row>
            {selected === "custom" && (
                <>
                    <Row className="mt-5 mb-3 justify-content-center">
                        <Col xs={12} className="mb-3">
                            <label className="form-label mb-0" htmlFor="startDate">
                                Start Date
                            </label>
                            <input
                                data-testid="start-date"
                                className="form-control pendo_date_range_custom_start_date_modal_filter_button"
                                type="date"
                                id="startDate"
                                value={range.startDate}
                                onChange={(e) => handleDateInput("startDate", e.target.value)}
                                onBlur={() => apply(dateRange)}
                            />
                        </Col>
                        <Col xs={12}>
                            <label className="form-label mb-0" htmlFor="endDate">
                                End Date
                            </label>
                            <input
                                data-testid="end-date"
                                className="form-control pendo_date_range_custom_end_date_modal_filter_button"
                                type="date"
                                id="endDate"
                                value={range.endDate}
                                onChange={(e) => handleDateInput("endDate", e.target.value)}
                                onBlur={() => apply(dateRange)}
                            />
                        </Col>
                    </Row>
                    {range.startDate !== "" && range.endDate !== "" && !checkDateRangeValid() && (
                        <span className="d-flex justify-content-center text-danger">Invalid Date Range</span>
                    )}
                </>
            )}
        </Col>
    );
};

DateRange.displayName = "DateRange";
export default DateRange;
