import { Navigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import NotFound from "../support/NotFound";

/**
 * For non multi-agency setups, this component will ensure backwards compatiblity
 * with the old entity page url formats (i.e `/app/search/` & `/app/search/uuid`)
 * and forward the user to the new URL format (i.e. `/app/uuid`)
 * with either the already specified UUID, or the parent agency UUID
 * @returns Navigate to path with uuid included
 */
const RouteUUIDForwarder = () => {
    const { agencyUUID } = useParams();
    const { parentAgencyUUID } = useMultiAgency();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    // construct new path, inserting the parent agency UUID into the URL
    const path = location.pathname;

    const legacyPathPrefix = "/app/search/";

    if (path.startsWith(legacyPathPrefix)) {
        // get remainder of path sting, excluding legacy path prefix
        const entityPath = path.substring(legacyPathPrefix.length);

        // if agency uuid is not present already in URL, then prefix entity path with parent agency uuid
        const newEntityPath = !!agencyUUID ? entityPath : `${parentAgencyUUID}/${entityPath}`;

        // if agency uuid found in url, populate new path with that uuid -- otherwise, fall back to parent uuid
        const newPath = `/app/${newEntityPath}?${searchParams}`;

        return <Navigate to={newPath} replace={true} />;
    } else {
        // As a fallback, render not found content. This really shouldn't be hit, ever
        return <NotFound />;
    }
};

export default RouteUUIDForwarder;
