import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Disclaimer from "../support/Disclaimer";
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import TextField from "../../components/formcontrols/TextField";
import { differenceInMinutes, parseISO } from "date-fns";
import { REQUEST_PASSWORD_RESET_ROUTE, useRunPreAuthApiMutation } from "../../api/api";
import FmAlert from "../support/FmAlert";
import Logo from "../support/logo/Logo";
import * as Yup from "yup";

type FormProps = {
    email: string;
};

/**
 * form to request a password reset.  Presents an input for an email address that needs to have a password reset.
 * Always returns success for security reasons unless there is a complete failure like the server is down.
 *
 * Makes sure a password reset request for an email address is not sent more than once per 5 minutes.
 * The email input is not displayed once the email request returns successfully.  This page doesn't redirect
 * anywhere and displays a success message prompting the user to check their email for a link to reset the password.
 * @constructor
 */
const ForgotPassword = () => {
    const [alert, setAlert] = React.useState<string | undefined>();
    const [requestPasswordReset, results] = useRunPreAuthApiMutation();

    const initial: FormProps = {
        email: "",
    };

    // the email sent is queued in localstorage to debounce multiple sends.  This code makes sure a request for
    // a password reset isn't set to the server more than once every 5 minutes.
    const submitEmailForPasswordReset = async (values: FormProps) => {
        // make sure a reset request hasn't been sent for this email in the last 5 minutes
        const resetRequest = localStorage.getItem("resetRequest");
        let send = true;
        if (resetRequest) {
            const now = new Date();
            const sentRequest = JSON.parse(resetRequest);
            if (
                sentRequest.time &&
                sentRequest.email &&
                differenceInMinutes(now, parseISO(sentRequest.time)) < 4 &&
                sentRequest.email === values.email
            ) {
                send = false;
                setAlert("dupe_email_request");
            }
        }
        // if there isn't a previous request in local storage or the there has been one but for a different email of
        // after 5 minutes, send the email to the server so a reset email will be sent.
        if (!resetRequest || send) {
            requestPasswordReset({ ...values, route: REQUEST_PASSWORD_RESET_ROUTE });
            // save to local storage for making sure request isn't sent again within 5 minutes
            localStorage.setItem("resetRequest", JSON.stringify({ time: new Date(), email: values.email }));
            setAlert("email_sent");
        }
    };

    const loading = results && results.isLoading;
    return (
        <Container>
            <Row className="justify-content-center">
                <Logo className="w-50" />
            </Row>
            <Row className="justify-content-center">
                <Col md={8} className="mb-5">
                    <h1 className="text-center">Password Reset</h1>
                </Col>
            </Row>
            {alert && (
                <Row className="justify-content-center">
                    <Col md={10} lg={7} xl={6}>
                        <FmAlert variant={alert === "email_sent" ? "success" : "danger"} alertKey={alert} />
                    </Col>
                </Row>
            )}
            {(!results || !results.data) && !alert && (
                <>
                    <Row className="justify-content-center">
                        <Col md={10} lg={7} xl={6}>
                            <p>Enter your login email to receive a secure reset password link sent to your email.</p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={10} lg={7} xl={6}>
                            <p>
                                <strong>NOTE:</strong> ForceMetrics staff will never ask for your credentials. Please report any such
                                behavior to your IT team or support@forcemetrics.ai as it could be a phishing attack.
                            </p>
                        </Col>
                    </Row>
                </>
            )}
            {(!results || !results.data) && !alert && (
                <Row className="justify-content-center">
                    <Col xs={11} sm={10} md={7} lg={5} xl={4} className="border p-4 bg-filled">
                        <Formik
                            initialValues={initial}
                            onSubmit={submitEmailForPasswordReset}
                            validationSchema={Yup.object({
                                email: Yup.string().email("Invalid email").required("Required"),
                            })}
                        >
                            <Form>
                                <TextField label="Email" name="email" type="email" />
                                <div>
                                    <Button
                                        style={{ marginTop: "2em" }}
                                        size="sm"
                                        type="submit"
                                        disabled={loading}
                                        className="text-nowrap float-end"
                                    >
                                        <FontAwesomeIcon icon={loading ? faSpinner : faPaperPlane} />
                                        <span className="ms-2">Send</span>
                                    </Button>
                                </div>
                            </Form>
                        </Formik>
                    </Col>
                </Row>
            )}
            <Row className="justify-content-center">
                <Col lg={{ span: 8 }} className="p-4 mt-5">
                    <Disclaimer className="text-muted" />
                </Col>
            </Row>
        </Container>
    );
};

ForgotPassword.displayName = "ResetPassword";
export default ForgotPassword;
