import { Alert, Nav, Offcanvas, Stack } from "react-bootstrap";
import { LayerTypes } from "../../types/old_v1/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCircleExclamation, faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { CALL_ACCENT } from "../../utils/colors";
import CallResult from "../../pages/search/CallResult";
import IncidentResult from "../../pages/search/IncidentResult";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { usePagination } from "../../hooks/usePagination";
import { FMPaginationNonLinks } from "../pagination/Pagination";
import { scrollToTop } from "../../utils/helpers";

type OwnProps = {
    show: boolean;
    handleClose: Function;
    events: any;
};

type BodyProps = {
    handleClose: Function;
    events: any;
    scrollToId?: string;
};

const FIXED_PAGE_SIZE = 100;

const MapEntityPaneBody = ({ events, handleClose, scrollToId }: BodyProps) => {
    const [search] = useSearchParams();
    const searchParams = React.useMemo(() => new URLSearchParams(search), [search]);
    const query = searchParams.get("query");

    const eventsCount = events && events?.success && events?.search_results?.length ? events?.search_results?.length : 0;

    const listData = events && events?.success ? events?.search_results : [];

    const linkParams = new URLSearchParams();

    if (!!query) {
        linkParams.set("query", query);
    }

    const pageResetDependencies = React.useMemo(() => ({ searchParams }), [searchParams]);

    // pass the correct ID to scroll function
    // useCallback ensures we don't stay stuck to page 1
    const passScroll = React.useCallback(() => scrollToTop(scrollToId), [scrollToId]);

    const { currentPage, setCurrentPage, currentPageData, totalPages } = usePagination(
        listData,
        FIXED_PAGE_SIZE,
        pageResetDependencies,
        passScroll
    );

    return (
        <Offcanvas.Body className="d-flex flex-column">
            <div style={{ zIndex: 1 }} className="d-flex map-toggle expanded px-4" onClick={() => handleClose()}>
                <div>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </div>
            </div>
            <Nav variant="tabs" className="border-bottom-0 text-nowrap flex-nowrap" activeKey={LayerTypes.event}>
                <Nav.Item className="events-tab">
                    <Nav.Link eventKey={LayerTypes.event} disabled>
                        <span>
                            <FontAwesomeIcon icon={faCalendarCircleExclamation} className="me-2" color={CALL_ACCENT} />
                            <span className="d-sm-none d-md-none d-lg-inline-block d-xl-none">{eventsCount}</span>
                            <span className="d-none d-sm-inline-block d-lg-none d-xl-inline-block">{`Events - ${eventsCount}`}</span>
                        </span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <div id={scrollToId} className="fm-bg-color p-3 rounded-end rounded-bottom border mb-5 overflow-auto flex-grow-1">
                <div>
                    {eventsCount >= 2500 ? (
                        <Alert className="mt-3" variant="danger">
                            Only the top 2500 events are shown. Please reduce the date range and/or add other filters and details to your
                            search to narrow results.
                        </Alert>
                    ) : null}
                    {eventsCount === 0 ? (
                        <div>
                            <span className="fw-bold">No events found. Refine your search criteria for better results.</span>
                            <ul>
                                <li>Check for typos</li>
                                <li>Remove any filter labels</li>
                                <li>Expand date range to 3 years or more</li>
                            </ul>
                        </div>
                    ) : null}

                    <Stack gap={3}>
                        {events &&
                            events?.search_results &&
                            currentPageData.map((event: any) => {
                                if (event.index.includes("call")) {
                                    return <CallResult key={event.id} call={event} linkParams={linkParams} />;
                                } else {
                                    return <IncidentResult key={event.id} incident={event} linkParams={linkParams} />;
                                }
                            })}
                    </Stack>
                </div>
            </div>
            <div className="mb-2 d-flex justify-content-center">
                <FMPaginationNonLinks
                    page={currentPage + 1}
                    setPage={(page: number) => {
                        scrollToTop(scrollToId);
                        setCurrentPage(page - 1);
                    }}
                    totalPages={totalPages}
                />
            </div>
        </Offcanvas.Body>
    );
};

const MapEntityPane = ({ show, handleClose, events }: OwnProps) => {
    const [topPosition, setTopPosition] = React.useState<number>(0);

    const updateTopPosition = () => {
        const mainContainer = document.getElementById("mainContainer");
        if (mainContainer) {
            setTopPosition(document.getElementById("mainContainer")?.offsetTop || 0);
        }
    };

    // no bueno depending on the dom... but this is the least hacky
    // way to get things to line up at the moment
    React.useLayoutEffect(() => {
        updateTopPosition();
        window.addEventListener("resize", updateTopPosition);
        return () => window.removeEventListener("resize", updateTopPosition);
    }, []);

    return (
        <>
            <Offcanvas
                // NOTE: no need currently to track mobile offcanvas for map easeTo
                className="d-lg-none w-75 fm-bg-color-darker"
                style={{ top: `${topPosition}px`, zIndex: 900 }}
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
                placement="start"
            >
                <MapEntityPaneBody events={events} handleClose={handleClose} scrollToId="mobileBodyContent" />
            </Offcanvas>
            <Offcanvas
                id="desktopMapSidePanel"
                className="d-none d-lg-flex w-33 fm-bg-color-darker"
                style={{ top: `${topPosition}px`, zIndex: 900 }}
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={false}
                placement="start"
            >
                <MapEntityPaneBody events={events} handleClose={handleClose} scrollToId="desktopBodyContent" />
            </Offcanvas>
        </>
    );
};

export default MapEntityPane;
