import { faExpandAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

export const SeeMore = ({ onClick, pendoClassName }: { onClick: any; pendoClassName?: string }) => {
    return (
        <Button onClick={onClick} variant="outline-primary" className={`${pendoClassName} cursor pointer float-end`} size="sm">
            <span className="me-2">See More</span>
            <FontAwesomeIcon icon={faExpandAlt} size="xs" />
        </Button>
    );
};

export default SeeMore;
