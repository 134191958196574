import React, { ClassAttributes, InputHTMLAttributes } from "react";
import { FieldHookConfig, useField } from "formik";
import { Stack } from "react-bootstrap";

type TextFieldProps = {
    label?: string;
    suggestion?: string;
    innerRef?: any;
    showErrorText?: boolean;
};

/**
 * text input component to use with Formik for convenience.  Displays any yup validation errors.
 * @param label - the label for the input
 * @param innerRef - provides a way to add a ref to the input so it can be cleared of focused
 * @param showErrorText - optional default true, shows Error text otherwise only outline red
 * @param props - optional input props, ie: disabled
 * @constructor
 */
const TextField = ({
    label,
    innerRef,
    showErrorText = true,
    ...props
}: TextFieldProps & InputHTMLAttributes<HTMLInputElement> & ClassAttributes<HTMLInputElement> & FieldHookConfig<string>) => {
    const [field, meta] = useField(props);
    let localProps = { ...props };
    let localClassName = `form-control shadow-none ${meta.touched && meta.error ? "border-danger" : ""}`;
    if (props.className) {
        localClassName += ` ${props.className}`;
        delete localProps.className;
    }
    return (
        <div className="flex-grow-1">
            {label && <label htmlFor={label ? `label-id${label.replace(" ", "-")}` : `input-${props.name}`}>{label}</label>}
            {props.suggestion && <span className="text-muted ms-3">{props.suggestion}</span>}
            <Stack>
                <input
                    ref={innerRef}
                    className={localClassName}
                    id={label ? `label-id${label.replace(" ", "-")}` : `input-${props.name}`}
                    {...field}
                    {...localProps}
                />
                {showErrorText && meta.touched && meta.error ? (
                    <div id="my error" className="text-danger">
                        {meta.error}
                    </div>
                ) : null}
            </Stack>
        </div>
    );
};

TextField.displayName = "TextField";
export default TextField;
