declare let pendo: any;

/**
 * This utility function is used to initialize the pendo agent.
 *
 * Pendo must be initialized on all window reloads (new tabs, page refreshes, etc), but should not be re-initialized within the same session.
 * by checking if pendo is ready, we can prevent re-initializing of the agent if it exists, and re-intialize the agent if it doesn't.
 *
 * More information about the pendo functions can be found here: https://developers.pendo.io/docs/?bash#functions
 *
 * * @returns void;
 */
export const initializePendo = (): void => {
    if (!pendo.isReady || !pendo.isReady()) {
        // if pendo.isReady() returns true, the agent has been initialized and has an api key
        // but if not, we should initialize the pendo agent
        pendo.initialize({
            location: {
                transforms: [
                    {
                        attr: "search",
                        action: "AllowOnlyKeys",
                        data: function () {
                            return ["currentTab"];
                        },
                    },
                ],
            },
        });
    }
};

/**
 * This utility function is used to identify the pendo user from the user data returned from the BE.
 *
 * More information about the pendo functions can be found here: https://developers.pendo.io/docs/?bash#functions
 *
 * * @params data - data object from the api call to the BE for user data
 * * @returns void;
 */
export const identifyPendoUser = (user: any): void => {
    if (!user) {
        return;
    }

    pendo.identify({
        visitor: {
            id: user.id, // Required if user is logged in
            email: user.email, // Recommended if using Pendo Feedback, or NPS Email
            full_name: user.full_name, // Recommended if using Pendo Feedback
            position: user.agency_position, // Added to better understand the vistor for Product team
        },
        account: {
            id: user.agency_name,
        },
    });
};
