import React from "react";
import { useLocation } from "react-router-dom";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import EventDetailsModal from "./EventDetailsModal";
import CardHeader from "../../../components/cardheader/CardHeader";
import BaseCallDetails from "./BaseCallDetails";
import BaseIncidentDetails from "./BaseIncidentDetails";
import useAgencySettings from "../../../hooks/useAgencySettings";

const EventCard = ({ event, call, incident, showShareOption }: { event: any; call: any; incident: any; showShareOption?: boolean }) => {
    const [showMore, setShowMore] = React.useState(false);
    const { search } = useLocation();

    const query = new URLSearchParams(search).get("query") || undefined;

    return (
        <React.Fragment>
            <Card className="h-100">
                <CardHeader
                    title="Event"
                    showMoreFn={() => setShowMore(true)}
                    pendoClassName="call-card"
                    showShareOption={showShareOption}
                    agency={call?.source?.agency_desc || incident?.source?.agency_desc}
                />
                <Card.Body className="overflow-auto px-0">
                    <EventCardBody event={event} query={query} />
                </Card.Body>
            </Card>
            <EventDetailsModal show={showMore} call={call} incident={incident} query={query} close={() => setShowMore(false)} />
        </React.Fragment>
    );
};

export const EventCardBody = ({ event, query }: { event: any; query?: string }) => {
    const { incidentText, callText } = useAgencySettings();
    return (
        <Container fluid>
            <Row>
                <Col sm={6} className={!!event ? "border-end" : ""}>
                    <h3>
                        <Badge bg="primary">{callText} Info</Badge>
                    </h3>
                    <BaseCallDetails call={event?.call} query={query} />
                </Col>
                <Col sm={6}>
                    <h3>
                        <Badge bg="warning">{incidentText} Info</Badge>
                    </h3>
                    <BaseIncidentDetails incident={event?.incident} query={query} />
                </Col>
            </Row>
        </Container>
    );
};

export default EventCard;
