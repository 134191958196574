import React from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import DateRange from "./DateRange";
import { useLocation, useSearchParams } from "react-router-dom";
import { EntitiesType, FilterParamNamesType } from "../../types/old_v1/types";
import TagsFilter from "./TagsFilter";
import RaceFilter from "./RaceFilter";
import GenderFilter from "./GenderFilter";
import IndexType from "./IndexType";
import TimeRange from "./TimeRange";
import BeatsFilter from "./BeatsFilter";
import OfficerFilter from "./OfficerFilter";
import DispatcherFilter from "./DispatcherFilter";
import CallTypeFilter from "./CallTypeFilter";
import { Entities, FilterParamNames } from "../../constants/constants";
import "./filters.css";
import IncidentTypeFilter from "./IncidentTypeFilter";
import { useFilters } from "../../hooks/useFilters";
import FilterBar from "./FilterBar";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import AgencyFilter from "./AgencyFilter";
import usePermissions from "../../hooks/usePermissions";
import AgeFilter from "./AgeFilter";
import useReducedMotion from "../../hooks/useReducedMotion";
import { useSearchFiltersAggregations } from "../../hooks/useSearchFiltersAggregations";
import SingleAgencyFilter from "./SingleAgencyFilter";
import useAgencySettings from "../../hooks/useAgencySettings";
import OffensesFilter from "./OffensesFilter";
import CallSourcesFilter from "./CallSourcesFilter";
import CallPriorityFilter from "./CallPriorityFilter";
import useIsMapPage from "../../hooks/useIsMapPage";

export type TEventFiltersAggregations = {
    labels: Array<any>;
    incident_types: Array<any>;
    call_types: Array<any>;
    officers: Array<any>;
    dispatchers: Array<any>;
    offenses: Array<any>;
    call_sources: Array<any>;
    call_priorities: Array<any>;
} | null;

type FiltersProps = {
    defaultDateRange: string;
    show: boolean;
    handleClose: Function;
    defaultFilterValues: { [key in FilterParamNamesType]?: string };
    selectedTab: EntitiesType | string;
    resultsText?: string;
    eventFiltersAggregations?: TEventFiltersAggregations;
    totalSearchResults?: number | null;
    showSingleAgencyFilter?: boolean;
    isSingleAgencyMode?: boolean;
    hideAgenciesFilter?: boolean; // serves as override to showMultiAgencyFilter to hide agencies filter
    includeBeatGeometry?: boolean;
};

const FiltersModal = ({
    show,
    handleClose,
    defaultFilterValues,
    selectedTab,
    resultsText = "Events",
    eventFiltersAggregations = null,
    totalSearchResults = null,
    showSingleAgencyFilter = false,
    isSingleAgencyMode = false,
    hideAgenciesFilter = false,
    includeBeatGeometry = false,
}: FiltersProps) => {
    const [searchParams] = useSearchParams();
    const params = React.useMemo(() => new URLSearchParams(searchParams), [searchParams]);
    const [activeFilters, setActiveFilters] = React.useState<string[]>([]);
    const { showMultiAgencyFilter } = useMultiAgency();
    const { incidentText, callText } = useAgencySettings();
    const { hasPermission } = usePermissions();
    const { isReducedMotion } = useReducedMotion();
    const { resetFilters, filters } = useFilters({ skipBeatsQuery: true });
    const location = useLocation();
    const { isMapPage } = useIsMapPage();

    React.useEffect(() => {
        let urlFilters = [];

        for (const key of params.keys() as any) {
            if (key === "filters" && filters) {
                for (const filterKey of Object.keys(filters)) {
                    if (filters[filterKey]?.length) {
                        urlFilters.push(filterKey);
                    }
                }
            } else {
                urlFilters.push(key);
            }
            setActiveFilters(urlFilters);
        }
    }, [filters, params]);

    const reset = () => {
        resetFilters(defaultFilterValues);
    };

    const isFilterActive = (paramName: FilterParamNamesType) => activeFilters.includes(paramName);

    const {
        labelAggregation,
        dispatcherAggregation,
        officerAggregation,
        callTypeAggregation,
        incidentTypeAggregation,
        offensesAggregation,
        callSourcesAggregation,
        callPriorityAggregation,
    } = useSearchFiltersAggregations(eventFiltersAggregations);

    const onMapPage = location.pathname.includes("/app/search/map");

    return (
        <Modal
            className="pendo_filter_modal"
            show={show}
            onHide={() => handleClose()}
            scrollable={true}
            animation={!isReducedMotion}
            data-testid="filter-modal"
        >
            <Modal.Header className="bg-dark">
                <Modal.Title className="text-white d-flex w-100">
                    <span className="fs-3 d-inline-flex align-items-center">Filters</span>
                </Modal.Title>
            </Modal.Header>
            <div className="p-3 border-bottom flex-column d-flex">
                <Form.Label className="text-primary fw-bolder">
                    Active Filters{!!totalSearchResults && <small>{` - ${totalSearchResults} ${resultsText}`}</small>}
                </Form.Label>
                <FilterBar
                    tab={selectedTab}
                    modalView={true}
                    showSingleAgencyFilter={showSingleAgencyFilter}
                    isSingleAgencyMode={isSingleAgencyMode}
                    hideFilterList={hideAgenciesFilter ? [FilterParamNames.AGENCIES] : []}
                    includeBeatGeometry={includeBeatGeometry}
                />
            </div>
            <Modal.Body className="px-0">
                <Container className="d-flex flex-column">
                    <div className="px-3">
                        <Col className="g-5">
                            {showSingleAgencyFilter ? (
                                <>
                                    <Row className="pt-3 pb-5">
                                        <h3 className="w-100 text-primary">Agency</h3>
                                        <SingleAgencyFilter />
                                    </Row>
                                    <div className="w-100 g-0" />
                                </>
                            ) : (
                                !hideAgenciesFilter &&
                                showMultiAgencyFilter && (
                                    <>
                                        <Row className="pt-3 pb-5">
                                            <h3 className="w-100 text-primary">Agencies</h3>
                                            <AgencyFilter />
                                        </Row>
                                        {/* spacer div to force new line for column wrapping while not on map page */}
                                        {!onMapPage && <div className="w-100 g-0" />}
                                    </>
                                )
                            )}
                            {selectedTab === Entities.EVENT && (
                                <>
                                    <Row className="pt-3 pb-5">
                                        <h3 className="w-100 text-primary">Date Range</h3>
                                        <DateRange />
                                    </Row>
                                    <Row className="pt-4 pb-5">
                                        <h3 className="w-100 text-primary">Event Types</h3>
                                        <IndexType />
                                    </Row>
                                    <Row className="py-5">
                                        <h3 className={`w-100 ${isFilterActive("tags") ? "text-primary" : ""}`}>Labels</h3>
                                        <TagsFilter labelAggregation={labelAggregation} />
                                    </Row>
                                    <Row className="py-5">
                                        <h3 className={`w-100 ${isFilterActive("beats") ? "text-primary" : ""}`}>Beats</h3>
                                        {/*Geometry is required for the map page in this filter so that we can show the beat outlines when selected*/}
                                        <BeatsFilter includeGeometry={isMapPage} />
                                    </Row>
                                    <Row className="pt-5 pb-4">
                                        <h3 className={`w-100 ${isFilterActive("callTypes") ? "text-primary" : ""}`}>{callText} Types</h3>
                                        <CallTypeFilter callTypeAggregation={callTypeAggregation} />
                                    </Row>
                                    <Row className="py-4">
                                        <h3 className={`w-100 ${isFilterActive("callSources") ? "text-primary" : ""}`}>
                                            {callText} Sources
                                        </h3>
                                        <CallSourcesFilter callSourceAggregation={callSourcesAggregation} />
                                    </Row>
                                    <Row className="py-4">
                                        <h3 className={`w-100 ${isFilterActive("callPriorities") ? "text-primary" : ""}`}>
                                            {callText} Priorities
                                        </h3>
                                        <CallPriorityFilter callPrioritiesAggregation={callPriorityAggregation} />
                                    </Row>
                                    <Row className="py-4">
                                        <h3 className={`w-100 ${isFilterActive("incidentTypes") ? "text-primary" : ""}`}>
                                            {incidentText} Types
                                        </h3>
                                        <IncidentTypeFilter incidentTypeAggregation={incidentTypeAggregation} />
                                    </Row>
                                    <Row className="pt-4 pb-5">
                                        <h3 className={`w-100 ${isFilterActive("offenses") ? "text-primary" : ""}`}>Offenses</h3>
                                        <OffensesFilter offensesAggregation={offensesAggregation} />
                                    </Row>
                                    <Row className="pt-5 pb-4">
                                        <h3 className={`w-100 ${isFilterActive("timeRange") ? "text-primary" : ""}`}>Hours of Day</h3>
                                        <TimeRange />
                                    </Row>
                                    {hasPermission("insights_dashboard_officer") && (
                                        <Row className="py-4">
                                            <h3 className={`w-100 ${isFilterActive("officer") ? "text-primary" : ""}`}>Officers</h3>
                                            <OfficerFilter officerAggregation={officerAggregation} />
                                        </Row>
                                    )}
                                    {hasPermission("insights_dashboard_dispatcher") && (
                                        <Row className="py-4">
                                            <h3 className={`w-100 ${isFilterActive("dispatcher") ? "text-primary" : ""}`}>Dispatchers</h3>
                                            <DispatcherFilter dispatcherAggregation={dispatcherAggregation} />
                                        </Row>
                                    )}
                                </>
                            )}
                            {selectedTab === Entities.PERSON && (
                                <>
                                    <Row className="py-5">
                                        <h3 className={`w-100 ${isFilterActive("race") ? "text-primary" : ""}`}>Race</h3>
                                        <RaceFilter />
                                    </Row>
                                    <Row className="py-5">
                                        <h3 className={`w-100 ${isFilterActive("gender") ? "text-primary" : ""}`}>Gender</h3>
                                        <GenderFilter />
                                    </Row>
                                    <Row className="py-5">
                                        <h3 className={`w-100 ${isFilterActive("gender") ? "text-primary" : ""}`}>Age</h3>
                                        <AgeFilter />
                                    </Row>
                                </>
                            )}
                            {selectedTab === "insights" && (
                                <>
                                    <Row className="pt-3 pb-5">
                                        <h3 className="w-100 text-primary">Date Range</h3>
                                        <DateRange />
                                    </Row>
                                    <Row className="py-5">
                                        <h3 className={`w-100 ${isFilterActive("beats") ? "text-primary" : ""}`}>Beats</h3>
                                        <BeatsFilter isSingleAgencyMode includeGeometry />
                                    </Row>
                                    <Row className="py-5">
                                        <h3 className={`w-100 ${isFilterActive("tags") ? "text-primary" : ""}`}>Labels</h3>
                                        <TagsFilter />
                                    </Row>
                                    <Row className="pt-5 pb-4">
                                        <h3 className={`w-100 ${isFilterActive("callTypes") ? "text-primary" : ""}`}>{callText} Types</h3>
                                        <CallTypeFilter />
                                    </Row>
                                    <Row className="py-4">
                                        <h3 className={`w-100 ${isFilterActive("callSources") ? "text-primary" : ""}`}>
                                            {callText} Sources
                                        </h3>
                                        <CallSourcesFilter callSourceAggregation={callSourcesAggregation} />
                                    </Row>
                                    <Row className="py-4">
                                        <h3 className={`w-100 ${isFilterActive("callPriorities") ? "text-primary" : ""}`}>
                                            {callText} Priorities
                                        </h3>
                                        <CallPriorityFilter callPrioritiesAggregation={callPriorityAggregation} />
                                    </Row>
                                    <Row className="py-4">
                                        <h3 className={`w-100 ${isFilterActive("incidentTypes") ? "text-primary" : ""}`}>
                                            {incidentText} Types
                                        </h3>
                                        <IncidentTypeFilter />
                                    </Row>
                                    <Row className="pt-4 pb-5">
                                        <h3 className={`w-100 ${isFilterActive("offenses") ? "text-primary" : ""}`}>Offenses</h3>
                                        <OffensesFilter offensesAggregation={offensesAggregation} />
                                    </Row>
                                </>
                            )}
                            {selectedTab === "officer" && (
                                <>
                                    <Row className="pt-3 pb-5">
                                        <h3 className="w-100 text-primary">Date Range</h3>
                                        <DateRange />
                                    </Row>
                                    <Row className="py-5">
                                        <h3 className={`w-100 ${isFilterActive("beats") ? "text-primary" : ""}`}>Beats</h3>
                                        <BeatsFilter isSingleAgencyMode includeGeometry />
                                    </Row>
                                    {hasPermission("insights_dashboard_officer") && (
                                        <Row className="py-5">
                                            <h3 className={`w-100 ${isFilterActive("officer") ? "text-primary" : ""}`}>Officers</h3>
                                            <OfficerFilter />
                                        </Row>
                                    )}
                                </>
                            )}
                            {selectedTab === "dispatcher" && (
                                <>
                                    <Row className="pt-3 pb-5">
                                        <h3 className="w-100 text-primary">Date Range</h3>
                                        <DateRange />
                                    </Row>
                                    <Row className="py-5">
                                        <h3 className={`w-100 ${isFilterActive("beats") ? "text-primary" : ""}`}>Beats</h3>
                                        <BeatsFilter isSingleAgencyMode includeGeometry />
                                    </Row>
                                    {hasPermission("insights_dashboard_dispatcher") && (
                                        <Row className="py-5">
                                            <h3 className={`w-100 ${isFilterActive("dispatcher") ? "text-primary" : ""}`}>Dispatchers</h3>
                                            <DispatcherFilter />
                                        </Row>
                                    )}
                                </>
                            )}
                        </Col>
                    </div>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div className="float-right">
                    <Button variant="outline-secondary" className="me-3 pendo_filter_modal_clear_button" onClick={reset}>
                        Clear
                    </Button>
                    <Button variant="primary" onClick={() => handleClose()}>
                        Done
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

FiltersModal.displayName = "FiltersModal";
export default FiltersModal;
