import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownButton, Dropdown, Button, Stack, OverlayTrigger, Tooltip } from "react-bootstrap";
import { SortConfig, SortDirectionEnum } from "../types/old_v1/types";

type OwnProps = {
    sortConfig: SortConfig;
    sortField: string;
    setSortField?: Function;
    sortDirection: SortDirectionEnum;
    setSortDirection?: Function;
    apply?: Function | null;
};
const SortButton = ({ sortConfig, sortField, setSortField, sortDirection, setSortDirection, apply = null }: OwnProps) => {
    const toggleSortDirection = () => {
        const direction = sortDirection === SortDirectionEnum.ASCENDING ? SortDirectionEnum.DESCENDING : SortDirectionEnum.ASCENDING;
        if (setSortDirection) {
            setSortDirection(direction);
        }

        if (apply) {
            apply(sortField, direction);
        }
    };

    const updateSortField = (field: string) => {
        if (setSortField) {
            setSortField(field);
        }

        if (setSortDirection) {
            setSortDirection(sortConfig[field].defaultSortDirection);
        }

        if (apply) {
            apply(field);
        }
    };

    return (
        <Stack gap={2} direction="horizontal">
            <DropdownButton
                size="sm"
                className="pendo_sort_dropdown_button"
                variant="outline-secondary"
                title={`Sort by: ${sortConfig[sortField]?.label || ""}`}
                // mark as disabled if there is only one sort option
                disabled={Object.keys(sortConfig).length <= 1}
            >
                {Object.entries(sortConfig).map(([sortKey, item]) => {
                    const pendoClass = `pendo_${sortKey}_sort_option`;

                    return (
                        <Dropdown.Item
                            key={sortKey}
                            as="button"
                            className={`${pendoClass} ${sortKey === sortField && "bg-primary text-white"}`}
                            onClick={() => updateSortField(sortKey)}
                            disabled={item?.isDisabled}
                        >
                            {item.label}
                        </Dropdown.Item>
                    );
                })}
            </DropdownButton>
            {sortDirection !== SortDirectionEnum.NONE && (
                <OverlayTrigger
                    overlay={
                        <Tooltip>{sortDirection === SortDirectionEnum.ASCENDING ? "Sorted by Ascending" : "Sorted by Descending"}</Tooltip>
                    }
                >
                    <Button
                        size="sm"
                        variant="outline-secondary"
                        className={`pendo_sort_${
                            sortDirection === SortDirectionEnum.ASCENDING ? SortDirectionEnum.DESCENDING : SortDirectionEnum.ASCENDING
                        }_button`}
                        onClick={toggleSortDirection}
                    >
                        <FontAwesomeIcon
                            data-testid="sort-direction-icon"
                            icon={sortDirection === SortDirectionEnum.ASCENDING ? faCaretUp : faCaretDown}
                        />
                    </Button>
                </OverlayTrigger>
            )}
        </Stack>
    );
};

export default SortButton;
