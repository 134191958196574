import { SortDirectionEnum } from "../types/old_v1/types";

// check both root of object and object.source for property existence
const objectHasOwnProperty = (obj: any, property: string) => {
    return (
        Object.prototype.hasOwnProperty.call(obj, property) ||
        ("source" in obj && Object.prototype.hasOwnProperty.call(obj["source"], property))
    );
};

const sortFn = (a: any, b: any, property: string, sortString: boolean) => {
    if (!property) {
        return 0;
    }
    const reversed = property[0] === "-";
    let reversedFactor = 1;
    if (reversed) {
        property = property.substring(1);
        reversedFactor = -1;
    }
    if (!objectHasOwnProperty(a, property) && !objectHasOwnProperty(b, property)) {
        return 0;
    }
    if (objectHasOwnProperty(a, property) && !objectHasOwnProperty(b, property)) {
        return reversedFactor;
    }
    if (objectHasOwnProperty(b, property) && !objectHasOwnProperty(a, property)) {
        return -1 * reversedFactor;
    }

    // if property not present in root in obj, fall back to check in source
    const aProp = a[property] || (!!a?.source ? a.source[property] : undefined);
    const bProp = b[property] || (!!b?.source ? b.source[property] : undefined);

    // for cases where aProp or bProp are undefined, sort to bottom of list
    if (aProp === undefined) {
        return 1;
    } else if (bProp === undefined) {
        return -1;
    } else if (sortString) {
        if (reversed) {
            if (typeof bProp === "number") {
                return bProp > aProp ? 1 : -1;
            } else {
                return (bProp || "").localeCompare(aProp || "");
            }
        } else {
            if (typeof bProp === "number") {
                return aProp > bProp ? 1 : -1;
            } else {
                return (aProp || "").localeCompare(bProp || "");
            }
        }
    } else {
        const result = aProp - bProp;
        return result * reversedFactor;
    }
};

export const sort = (keys: string, sortString: boolean = false) => {
    return (a: any, b: any) => {
        const objKeys = keys.split(",");
        let i = 0;
        let result = 0;

        while (result === 0 && i < objKeys.length) {
            result = sortFn(a, b, objKeys[i], sortString);
            i++;
        }
        return result;
    };
};

export const sortSortableHeader = (keys: string, sortString: boolean, sortDirection: SortDirectionEnum) =>
    sort(handleSortKeyReverse(keys, sortDirection), sortString);

export const handleSortKeyReverse = (sortColumn: string, sortDirection: SortDirectionEnum) =>
    `${sortDirection === SortDirectionEnum.ASCENDING ? "" : "-"}${sortColumn}`;
