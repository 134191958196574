import { GeoJSONSource, MapLayerMouseEvent } from "mapbox-gl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setPopupProperties, setShowPopup } from "../app/slices/map/mapSlice";
import { PopupProperties } from "../types/types";
import { useMultiAgency } from "../hooks/useMultiAgency";
import { useDispatch } from "react-redux";
import { useManageSearchParams } from "../hooks/useManageSearchParams";
import { LayerTypes, SourceConfig } from "../types/old_v1/types";
import { useFilters } from "./useFilters";

export const useClusterMapEventHandlers = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { constructPersonUrl, constructAddressUrl, constructEventUrl } = useMultiAgency();
    const { processSearchParams } = useManageSearchParams();
    const { updateFilters, filters } = useFilters({ skipBeatsQuery: true });

    const filterUniqueFeatures = (featureArray: any[]) => {
        let uniqueSet = new Set();
        return featureArray.filter((feature) => {
            const id = feature.properties.id ? feature.properties.id : feature.properties.address_id;

            return id && !id.includes("beat") && !uniqueSet.has(id) && uniqueSet.add(id);
        });
    };

    const getUniqueFeatures = (event: MapLayerMouseEvent) => {
        const { features } = event;

        let uniqFeatures = features && filterUniqueFeatures(features);

        if (!uniqFeatures && Array.isArray(event)) {
            uniqFeatures = filterUniqueFeatures(event);
        }

        let callCount = 0;
        let addressCount = 0;
        let personCount = 0;
        let incidentCount = 0;

        uniqFeatures?.forEach((feature: any) => {
            if (feature.layer.id === "call-layer-cluster" && !!feature.properties) {
                callCount += feature.properties.point_count;
            }

            if (feature.layer.id === "person-layer-cluster" && !!feature.properties) {
                personCount += feature.properties.point_count;
            }

            if (feature.layer.id === "incident-layer-cluster" && !!feature.properties) {
                incidentCount += feature.properties.point_count;
            }

            if (feature.layer.id === "event-layer") {
                callCount += 1;
            }

            if (feature.layer.id === "person-layer") {
                personCount += 1;
            }

            if (feature.layer.id === "address-layer") {
                addressCount += 1;
            }

            if (feature.layer.id === "event-incident-layer") {
                incidentCount += 1;
            }

            if (feature.layer.id === "event-call-layer") {
                callCount += 1;
            }
        });

        const countSum = callCount + addressCount + personCount + incidentCount;
        const counts = { countSum, callCount, addressCount, personCount, incidentCount };

        return { uniqFeatures, counts };
    };

    const insightBeatClick = (event: MapLayerMouseEvent, dataSources: SourceConfig[]) => {
        const { features } = event;
        const firstFeature = features && features[0];
        const searchBeats = filters?.beats;

        // Beat layer has been clicked on the map for filtering
        if (firstFeature && firstFeature?.source.includes("beat")) {
            if (searchBeats?.length > 0) {
                // if beat is already in the list remove it
                const beatExists = searchBeats.find(
                    (beat: any) =>
                        beat.beat_name === firstFeature?.properties?.beat_name && beat.agency_desc === firstFeature?.properties?.agency_desc
                );
                if (beatExists) {
                    const filteredList = searchBeats.filter((beat: any) => beat.beat_name !== firstFeature?.properties?.beat_name);
                    updateFilters("beats", filteredList.length > 0 ? filteredList : null, searchParams);
                }
            } else {
                // mapbox click event converts the properties to string so we need to retrieve the es_docs array from the original dataSources
                const unformattedProperties = dataSources[0].data.features.find(
                    (feature: any) => feature.properties.beat_name === firstFeature?.properties?.beat_name
                );
                const newValue = {
                    agency_desc: unformattedProperties.properties.agency_desc,
                    agency_id: unformattedProperties.properties.agency_id,
                    beat_name: unformattedProperties.properties.beat_name,
                    es_docs: unformattedProperties.properties.es_docs,
                };
                updateFilters("beats", [newValue], searchParams);
            }

            navigate(`.?${searchParams}`);
        }
    };

    const clusterClick = (event: MapLayerMouseEvent) => {
        const { features, lngLat, target } = event;
        const firstFeature = features && features[0];
        const uniqFeatures = features && filterUniqueFeatures(features);

        if (!!firstFeature) {
            const { properties } = firstFeature;
            // Clicking on a cluster of mixed type points and need to zoom in
            if (!!properties && !!properties.cluster) {
                dispatch(setShowPopup(false));
                const source = target.getSource(firstFeature.source) as GeoJSONSource;
                const clusterId = properties.cluster_id;
                source.getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (!err) {
                        target.easeTo({
                            center: (firstFeature.geometry as any).coordinates,
                            zoom: zoom + 2,
                        });
                    }
                });
                // Clicking on a cluster of one type of points and can present a list of items
            } else if (!!uniqFeatures && !!properties && !properties.cluster && uniqFeatures.length > 1) {
                const popupProperties: PopupProperties = {
                    lng: lngLat.lng,
                    lat: lngLat.lat,
                    closeButton: true,
                    type: "clickList",
                    itemList: uniqFeatures
                        .filter((feature: any) => !!feature.properties && !feature.properties.cluster && feature.properties.gps_lat)
                        .map((feature: any) => feature.properties),
                };
                dispatch(setPopupProperties(popupProperties));
                dispatch(setShowPopup(true));
                // clicking on a single unique point and navigating to entity detail page
            } else if (!!uniqFeatures && !!properties && !properties.cluster && uniqFeatures.length === 1) {
                let url = "";
                switch (properties.type) {
                    case "address":
                        url = constructAddressUrl(
                            properties.agency_id,
                            { ...properties, full_address: properties.address },
                            searchParams.get("query")
                        );
                        break;
                    case "person":
                        url = constructPersonUrl(properties.agency_id, properties, processSearchParams(["query"])).url;
                        break;
                    case "incident":
                        url = constructEventUrl(
                            properties.agency_id,
                            undefined,
                            properties.id,
                            processSearchParams(["query", "dateRange"])
                        );
                        break;
                    case "call":
                        url = constructEventUrl(
                            properties.agency_id,
                            properties.id,
                            undefined,
                            processSearchParams(["query", "dateRange"])
                        );
                        break;
                }
                window.open(url, "_blank");
            }
        } else {
            dispatch(setShowPopup(false));
        }
    };
    const clusterMouseEnter = (event: MapLayerMouseEvent, dispatch: any, hoverEvent?: Function) => {
        const { uniqFeatures, counts } = getUniqueFeatures(event);
        const { lngLat } = event;

        if (!!uniqFeatures && counts.countSum === 1) {
            const feature = uniqFeatures[0];
            const { properties } = feature;
            if (!!properties) {
                const popupProperties: PopupProperties = {
                    lng: lngLat.lng,
                    lat: lngLat.lat,
                    closeButton: false,
                    type: properties.type,
                };

                if (properties.type === LayerTypes.call) {
                    popupProperties.callType = properties.call_type;
                    popupProperties.callNumber = properties.call_number;
                    popupProperties.address = properties.full_address;
                    popupProperties.date = properties.occurred_at;
                }

                if (properties.type === LayerTypes.person) {
                    popupProperties.name = properties.full_name;
                    popupProperties.address = properties.full_address;
                }

                if (properties.type === LayerTypes.address) {
                    popupProperties.address = properties.address || properties.key;
                    popupProperties.eventCount = properties.eventCount || properties.num_calls + properties.num_incidents;
                }

                if (properties.type === LayerTypes.incident) {
                    popupProperties.address = properties.full_address;
                    popupProperties.incidentType = properties.incident_type;
                    popupProperties.date = properties.occurred_at;
                    popupProperties.number =
                        properties.id || (properties.call_number && properties.call_number.length ? properties.call_number[0] : undefined);
                }

                dispatch(setPopupProperties(popupProperties));
                dispatch(setShowPopup(true));
                if (hoverEvent) {
                    hoverEvent(properties);
                }
            }
        }

        if (!!uniqFeatures && counts.countSum > 1) {
            const popupProperties: PopupProperties = {
                lng: lngLat.lng,
                lat: lngLat.lat,
                closeButton: false,
                type: LayerTypes.list,
                totalAddresses: counts.addressCount,
                totalCalls: counts.callCount,
                totalPeople: counts.personCount,
                totalIncidents: counts.incidentCount,
            };
            dispatch(setPopupProperties(popupProperties));
            dispatch(setShowPopup(true));
        }
    };

    const clusterMouseLeave = (currentPopupProperties: PopupProperties | undefined, dispatch: any, hoverEvent?: Function) => {
        if (!!currentPopupProperties && currentPopupProperties.type !== "clickList") {
            dispatch(setShowPopup(false));
            if (hoverEvent) {
                hoverEvent({});
            }
        }
    };

    return {
        clusterClick,
        insightBeatClick,
        clusterMouseEnter,
        clusterMouseLeave,
        filterUniqueFeatures,
    };
};
