import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ASCENDING, DESCENDING } from "../utils/old_v1/sort";
import { faSort, faSortDown, faSortUp } from "@fortawesome/pro-light-svg-icons";

type OwnProps = {
    columnClass?: string;
    columnStyle?: any;
    colSpan?: number;
    sortFn: Function;
    columnName: string;
    iconUp?: any;
    iconDown?: any;
    activeSortField: string;
    activeSortDirection: string;
    sortKey: string;
    defaultDirection: string;
};

const SortableHeaderColumn = ({
    columnClass,
    columnStyle,
    colSpan,
    sortFn,
    columnName,
    iconUp = faSortUp,
    iconDown = faSortDown,
    activeSortField, // the field being sorted in the parent
    activeSortDirection,
    sortKey, // doc field for this column header
    defaultDirection,
}: OwnProps) => {
    const handleSort = () => {
        const direction = activeSortField !== sortKey ? defaultDirection : activeSortDirection === DESCENDING ? ASCENDING : DESCENDING;
        sortFn(sortKey, false, direction);
    };
    const icon =
        sortKey === activeSortField && activeSortDirection === ASCENDING
            ? iconUp
            : sortKey === activeSortField && activeSortDirection === DESCENDING
            ? iconDown
            : faSort;

    return (
        <th
            className={`text-nowrap cursor-pointer ${columnClass || ""} pendo_sortable_header_column_${sortKey}_direction_button`}
            style={columnStyle || null}
            colSpan={colSpan || 1}
            onClick={handleSort}
        >
            {columnName}
            {iconUp && iconDown && <FontAwesomeIcon icon={icon} className="ms-2" />}
        </th>
    );
};

SortableHeaderColumn.displayName = "SortableHeaderColumn";
export default SortableHeaderColumn;
