import React from "react";
import { Modal } from "react-bootstrap";
import FMSpinner from "./Spinner";

const LoadingModal = ({ show }: { show: boolean }) => {
    return (
        <Modal centered show={show} data-testid="loading-modal">
            <Modal.Header className={"d-flex justify-content-center"}>
                <FMSpinner />
                <h3 className={"ms-3 mt-2"}>Loading...</h3>
            </Modal.Header>
        </Modal>
    );
};

export default LoadingModal;
