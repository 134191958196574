import React from "react";
import { CALL_ACCENT, INCIDENT_ACCENT, MAP_BORDER_ACCENT } from "../../utils/colors";
import MapComponent from "../../components/map/MapComponent";
import { SourceConfig } from "../../types/types";
import { MapLayerMouseEvent } from "mapbox-gl";
import { featureCollection, point } from "@turf/helpers";
import { useAppDispatch } from "../../app/hooks";
import { setPopupProperties, setShowPopup } from "../../app/slices/map/mapSlice";
import { PopupProperties } from "../../components/map/Popup";
import { validateCoordinates } from "../../components/map/utils/gis";

const RadarMap = ({
    events,
    height,
    width,
    setSelectedEventFromMap,
}: {
    events: any;
    height: number;
    width: number;
    setSelectedEventFromMap: any;
}) => {
    const [dataSources, setDataSources] = React.useState<SourceConfig[]>([]);
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        if (!!events) {
            const hits = events.reduce((coll: any, curr: any) => {
                const lon = curr?._source?.gps_lon;
                const lat = curr?._source?.gps_lat;
                if (lon && lat && validateCoordinates(lon, lat)) {
                    coll.push(point([lon, lat], { ...curr._source, index: curr._index }));
                }
                return coll;
            }, []);
            const calls = hits.filter((hit: any) => hit.properties.index && hit.properties.index.includes("calls"));
            const incidents = hits.filter((hit: any) => hit.properties.index && hit.properties.index.includes("incident"));
            const geojsonCalls = featureCollection(calls);
            const getjsonNonIncidents = featureCollection(incidents);

            const sources: SourceConfig[] = [
                {
                    data: geojsonCalls,
                    id: "radar-non-important-event-map",
                    setBounds: true,
                    cluster: false,
                    layers: [
                        {
                            id: "event-layer",
                            type: "circle",
                            source: "event-map",
                            paint: {
                                "circle-color": CALL_ACCENT,
                                "circle-radius": 8,
                                "circle-stroke-color": MAP_BORDER_ACCENT,
                                "circle-stroke-width": 2,
                                "circle-stroke-opacity": 0.7,
                            },
                        },
                    ],
                },
                {
                    data: getjsonNonIncidents,
                    id: "radar-important-event-map",
                    setBounds: true,
                    cluster: false,
                    layers: [
                        {
                            id: "ip-event-layer",
                            type: "circle",
                            source: "event-map",
                            paint: {
                                "circle-color": INCIDENT_ACCENT,
                                "circle-radius": 8,
                                "circle-stroke-color": MAP_BORDER_ACCENT,
                                "circle-stroke-width": 2,
                                "circle-stroke-opacity": 0.7,
                            },
                        },
                    ],
                },
            ];
            setDataSources(sources);
        }
    }, [events]);

    const mouseEnterEventHandler = (event: MapLayerMouseEvent) => {
        const { features, lngLat } = event;

        if (!!features) {
            const feature = features[0];
            const { properties } = feature;

            if (!!properties) {
                const popupProperties: PopupProperties = {
                    lng: lngLat.lng,
                    lat: lngLat.lat,
                    closeButton: false,
                    type: "event", //want to change this since it's an event
                    address: properties.full_address,
                    incidentType: properties.incident_type,
                    date: properties.occurred_at,
                    number: properties.id,
                };

                dispatch(setPopupProperties(popupProperties));
                dispatch(setShowPopup(true));
            }
        }
    };

    const mouseLeaveEventHandler = () => {
        dispatch(setShowPopup(false));
    };

    const clickEventHandler = (event: MapLayerMouseEvent) => {
        const { features } = event;
        setSelectedEventFromMap(features && features[0] && features[0].properties);
    };

    return (
        <div style={{ height: "25em" }}>
            <MapComponent
                id={"map"}
                sources={dataSources}
                layerMouseEnterEventHandler={mouseEnterEventHandler}
                layerMouseOutEventHandler={mouseLeaveEventHandler}
                layerClickEventHandler={clickEventHandler}
                fillContainer
                padding={15}
            />
        </div>
    );
};

export default RadarMap;
