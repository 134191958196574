import React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faXmark, faXmarkCircle } from "@fortawesome/pro-light-svg-icons";
import { OptionType, RadarConfigNameEnum } from "../../../types/old_v1/types";
import { useFilters } from "../../../hooks/useFilters";

type OptionFilterTypes = {
    options: OptionType[];
    paramName: string;
    close: Function;
    label?: string;
    clearView?: boolean;
    selectAll?: boolean;
    pendoClass?: string;
    addParamToUrl?: boolean;
    prebuiltSelectedOptions?: any[];
};

const OptionFilter = ({
    options,
    paramName,
    close,
    label,
    clearView,
    selectAll,
    pendoClass = "",
    addParamToUrl = true,
    prebuiltSelectedOptions,
}: OptionFilterTypes) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const params = React.useRef(new URLSearchParams(searchParams)).current;
    const optionParam = React.useRef(params.get(paramName)).current;
    const { updateFilters } = useFilters({ skipBeatsQuery: true });

    const initial: OptionType[] = React.useMemo(() => {
        if (prebuiltSelectedOptions && prebuiltSelectedOptions.length > 0) {
            return prebuiltSelectedOptions;
        }

        const tempInitial: OptionType[] = [];
        if (optionParam) {
            optionParam.split(",").forEach((paramValue) => {
                const item: OptionType | undefined = options.find((option) => option.value === decodeURIComponent(paramValue || ""));
                if (item) {
                    tempInitial.push(item);
                }
            });
        }
        return tempInitial;
    }, [optionParam, options, prebuiltSelectedOptions]);

    const [selected, setSelected] = React.useState<OptionType[]>([]);

    // Update selected on given param update update
    React.useEffect(() => {
        setSelected(initial);
    }, [initial]);

    const handleChange = (value: string) => {
        const found = options.find((sel) => sel.value === value);
        if (found) {
            setSelected([...selected, ...[found]]);
        }
    };

    const remove = (value: string) => {
        const updated = selected.filter((item) => item.value !== value);
        setSelected(updated);
    };

    const apply = () => {
        params.delete("page");
        if (clearView) {
            params.set("view", RadarConfigNameEnum.CUSTOM);
        }
        if (selected.length) {
            if (addParamToUrl) {
                params.set(paramName, selected.map((item) => encodeURIComponent(item.value)).join(","));
            }
            updateFilters(paramName, selected, params);
        } else {
            params.delete(paramName);
        }

        navigate(`.?${params.toString()}`);
        close(paramName);
    };

    const setDisabled = (value: string) => !!selected.find((sel) => sel.value === value);

    const selectedSorted = [...selected].sort((a: any, b: any) => {
        const aVal = a.option ? a.option : "";
        const bVal = b.option ? b.option : "";
        return aVal > bVal ? 1 : -1;
    });

    const selectAllFilters = () => {
        params.set(paramName, options.map((item) => item.value).join(","));
        navigate(`.?${params.toString()}`);
        close(paramName);
    };

    const width =
        selectedSorted.length > 150 ? "80vw" : selectedSorted.length > 100 ? "70vw" : selectedSorted.length > 30 ? "50vw" : "350px";

    return (
        <div className="p-2" style={{ width }}>
            {selectedSorted.length
                ? selectedSorted.map((option) => {
                      return (
                          <Badge
                              key={option.value}
                              className="cursor-pointer fs-4 mt-2 ms-2 d-inline-flex mw-100"
                              onClick={() => remove(option.value)}
                              pill
                              bg="secondary"
                              title={option.display}
                          >
                              <span className="p-1 me-1 text-truncate">{option.display}</span>
                              <FontAwesomeIcon icon={faXmarkCircle} />
                          </Badge>
                      );
                  })
                : []}
            <Form className="mt-4 mb-3">
                <Form.Group>
                    {label ? <Form.Label>{label}</Form.Label> : []}
                    {selectAll && (
                        <div className={"mb-3"}>
                            <Button className={`${pendoClass ? `${pendoClass}-select-all` : ""}`} onClick={selectAllFilters}>
                                Select All
                            </Button>
                        </div>
                    )}
                    <Form.Select onChange={(e) => handleChange(e.target.value)}>
                        <option>Choose...</option>
                        {options.map((option) => {
                            return (
                                <option key={option.value} value={option.value} disabled={setDisabled(option.value)}>
                                    {option.display}
                                </option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
            </Form>
            <Row>
                <Col xs={4}>
                    <Button className="text-nowrap" variant="outline-secondary" onClick={() => close(paramName)}>
                        <FontAwesomeIcon icon={faXmark} /> Cancel
                    </Button>
                </Col>
                <Col>
                    <Button className={`float-end ${pendoClass ? `${pendoClass}-apply` : ""}`} variant="primary" onClick={apply}>
                        <FontAwesomeIcon icon={faPlay} /> Apply
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

OptionFilter.displayName = "OptionFilter";
export default OptionFilter;
