import React from "react";
import { MapLayerMouseEvent } from "mapbox-gl";
import { SourceConfig } from "../../types/old_v1/types";
import MapComponent from "../../components/map/MapComponent";
import { prepareEventMapConfig } from "../../hooks/useEventSearchLayer";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { useClusterMapEventHandlers } from "../../hooks/useClusterMapEventHandlers";

type OwnProps = {
    call: any;
    incident: any;
    isLoading: boolean;
};
const EventMap = ({ call, incident, isLoading }: OwnProps) => {
    const [disableRebounding, setDisableRebounding] = React.useState<boolean>(false);
    const configs: SourceConfig[] = [];
    const dispatch = useDispatch();
    const currentPopupProperties = useAppSelector(({ map }) => map.popupProperties);
    const { clusterMouseEnter, clusterMouseLeave } = useClusterMapEventHandlers();

    if (!isLoading && (call || incident)) {
        const callAndIncidentAtSameAddress =
            !!call?.source?.gps_lon &&
            !!call?.source?.gps_lat &&
            call?.source?.gps_lon === incident?.source?.gps_lon &&
            call?.source?.gps_lat === incident?.source?.gps_lat;
        // if incident & call at same exact address, only show call marker on the map
        const mapConfig = prepareEventMapConfig(
            callAndIncidentAtSameAddress ? [call?.highlights || {}] : [call?.highlights || {}, incident?.highlights || {}],
            false
        );
        configs.push(mapConfig);
    }

    const mouseEnterEventHandler = (event: MapLayerMouseEvent) => {
        setDisableRebounding(true);
        clusterMouseEnter(event, dispatch);
    };

    const mouseLeaveEventHandler = () => {
        clusterMouseLeave(currentPopupProperties, dispatch);
    };

    return (
        <div className="w-100" style={{ height: "200px" }}>
            {!isLoading && (
                <MapComponent
                    id="event-map"
                    sources={configs}
                    fillContainer
                    isNotInteractive={false}
                    layerMouseEnterEventHandler={mouseEnterEventHandler}
                    layerMouseLeaveEventHandler={mouseLeaveEventHandler}
                    disableRebounding={disableRebounding}
                />
            )}
        </div>
    );
};

export default EventMap;
