import React from "react";
import { Alert, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import SortableHeaderColumn from "../../components/SortableHeader";
import { ASCENDING, DESCENDING, sortSortableHeader } from "../../utils/old_v1/sort";
import { useColumnSort } from "../../hooks/old_v1/useColumnSort";
import { formatDate } from "../../utils/date";
import Highlighter from "../../components/highlighter/Highlighter";
import { renderOrDash } from "../../utils/display";
import Tags from "../../components/tags/Tags";
import "./EventTable.css";
import { triggerPopup } from "../map/eventhandlers/clusterMapEventHandlers";
import { LayerTypes } from "../../types/old_v1/types";
import { useDispatch } from "react-redux";
import { setShowPopup } from "../../app/slices/map/mapSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { FMPaginationNonLinks } from "../pagination/Pagination";
import { usePagination } from "../../hooks/usePagination";
import tagData from "../../utils/TagDetails";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import { useManageSearchParams } from "../../hooks/useManageSearchParams";

const DEFAULT_SORT = "occurred_at";
const DEFAULT_SORT_ORDER = DESCENDING;

type OwnProps = {
    events: any;
    showCallInfo?: boolean;
    showIncidentInfo?: boolean;
    showVictim?: boolean;
    elementsRef?: any;
    hoverId?: string;
    paginate?: boolean;
    newTabLink?: boolean;
    scrollToTopFn?: Function;
    resultsMessage: string;
};

const BaseEventTable = ({
    events,
    showCallInfo = true,
    showIncidentInfo = true,
    showVictim = false,
    elementsRef,
    hoverId,
    newTabLink,
    scrollToTopFn,
    resultsMessage,
}: OwnProps) => {
    const { processSearchParams } = useManageSearchParams();
    const { constructEventUrl } = useMultiAgency();
    const [eventFilterString, setEventFilterString] = React.useState("");
    const eventTableRef = React.useRef<HTMLTableElement | null>(null);
    const dispatch = useDispatch();
    const { sortColumn, sortDirection, setSort } = useColumnSort(DEFAULT_SORT, DEFAULT_SORT_ORDER);

    const processedEvents = Array.isArray(events)
        ? events.map((event: any) => {
              const index = event?.index?.includes("incident") ? "incident" : "call";

              return {
                  ...event,
                  displayCallNumber:
                      index === "call" ? event.call_number : event.call_number && event.call_number.length ? event.call_number[0] : null,
              };
          })
        : [];

    const tagDetails = tagData();
    let sortedEvents = (Array.isArray(processedEvents) ? [...processedEvents] : [])
        .filter((event) => {
            if (!eventFilterString) {
                return true;
            }
            const lowercaseFilter = eventFilterString.toLowerCase();
            return (
                `${event.call_type}`.toLowerCase().includes(lowercaseFilter) ||
                `${event.full_address}`.toLowerCase().includes(lowercaseFilter) ||
                `${event.incident_type}`.toLowerCase().includes(lowercaseFilter) ||
                event?.flags?.some((flag: any) => {
                    const tag = tagDetails.find((tagDatum) => flag === tagDatum.code);
                    return flag.includes(lowercaseFilter) || tag?.name.toLowerCase().includes(lowercaseFilter);
                })
            );
        })
        .sort(sortSortableHeader(sortColumn, true, sortDirection));

    if (showVictim) {
        sortedEvents = sortedEvents.map((row) => {
            const victims: string[] = [];

            row?.involved_people?.forEach((person: any) => {
                if (person.role?.toLowerCase() === "victim") {
                    victims.push(person.full_name);
                }
            });
            return {
                ...row,
                victims,
            };
        });
    }

    // use scroll callback if provided, otherwise scroll to top of table
    const scrollToTop = React.useCallback(() => {
        !!scrollToTopFn ? scrollToTopFn() : eventTableRef?.current?.parentElement?.scroll({ top: 0 });
    }, [eventTableRef, scrollToTopFn]);
    // ensure page does not scroll to top of card on page load if not in modal view
    const pageResetDependencies = React.useMemo(
        () => (!!scrollToTopFn ? null : { eventFilterString, sortColumn, sortDirection }),
        [eventFilterString, scrollToTopFn, sortColumn, sortDirection]
    );
    const {
        currentPage,
        setCurrentPage,
        currentPageData: eventsArray,
        totalPages,
    } = usePagination(sortedEvents, 10, pageResetDependencies, scrollToTop);

    const colSpan = 1 + (showCallInfo ? 2 : 0) + (showIncidentInfo ? 2 : 0) + (showVictim ? 1 : 0);

    const filterEventList = (event: any) => {
        setEventFilterString(event.target.value);
    };

    return (
        <>
            <>
                <input
                    name="associated-events-filter-input"
                    className="mb-1 form-control"
                    onChange={filterEventList}
                    placeholder="Filter by Call Type, Address, or Labels"
                />
                <span>{resultsMessage}</span>
            </>
            {sortedEvents.length > 0 ? (
                <>
                    <Table className="address-event-table flex-grow-1 overflow-auto" responsive ref={eventTableRef}>
                        <thead className="sticky-header">
                            <tr>
                                <SortableHeaderColumn
                                    columnName="Date"
                                    columnClass={"date-column"}
                                    sortFn={setSort}
                                    activeSortField={sortColumn}
                                    activeSortDirection={sortDirection}
                                    sortKey={DEFAULT_SORT}
                                    defaultDirection={DESCENDING}
                                />
                                {showCallInfo && (
                                    <React.Fragment>
                                        <SortableHeaderColumn
                                            columnName="Call"
                                            columnClass={"call-number-column"}
                                            sortFn={setSort}
                                            activeSortField={sortColumn}
                                            activeSortDirection={sortDirection}
                                            sortKey="displayCallNumber"
                                            defaultDirection={ASCENDING}
                                        />
                                        <SortableHeaderColumn
                                            columnName="Call Type"
                                            columnClass={"call-nature-column"}
                                            sortFn={setSort}
                                            activeSortField={sortColumn}
                                            activeSortDirection={sortDirection}
                                            sortKey="call_type"
                                            defaultDirection={ASCENDING}
                                        />
                                    </React.Fragment>
                                )}
                                {showIncidentInfo && (
                                    <React.Fragment>
                                        <SortableHeaderColumn
                                            columnName={"Incident"}
                                            columnClass={"incident-number-column"}
                                            sortFn={setSort}
                                            activeSortField={sortColumn}
                                            activeSortDirection={sortDirection}
                                            sortKey="incident_number"
                                            defaultDirection={ASCENDING}
                                        />
                                        <SortableHeaderColumn
                                            columnName="Incident Type"
                                            columnClass={"incident-nature-column"}
                                            sortFn={setSort}
                                            activeSortField={sortColumn}
                                            activeSortDirection={sortDirection}
                                            sortKey="incident_type"
                                            defaultDirection={ASCENDING}
                                        />
                                    </React.Fragment>
                                )}
                                {showVictim && <th>Victim</th>}
                            </tr>
                        </thead>
                        <tbody ref={elementsRef}>
                            {eventsArray.map((event: any) => {
                                const address = event.highlights?.full_address ? event.highlights.full_address : event.full_address || "";
                                const date = formatDate(event.occurred_at);
                                const eventLink = event?.index?.includes("incident")
                                    ? constructEventUrl(
                                          event?.source?.agency_id,
                                          event.displayCallNumber,
                                          event.source?.id,
                                          processSearchParams(["query"], [])
                                      )
                                    : constructEventUrl(
                                          event?.source?.agency_id,
                                          event?.source?.call_number,
                                          undefined,
                                          processSearchParams(["query"], [])
                                      );

                                return (
                                    <React.Fragment key={`${event.displayCallNumber || ""}_${event.incident_number || ""}`}>
                                        <tr
                                            onMouseEnter={() => {
                                                triggerPopup(event, LayerTypes.event, dispatch);
                                            }}
                                            onMouseLeave={() => dispatch(setShowPopup(false))}
                                            id={event.id}
                                            className={`main-row ${event.id && hoverId === event.id ? "bg-gray" : ""}`}
                                        >
                                            <td>
                                                <Link
                                                    to={eventLink}
                                                    target={newTabLink ? "_blank" : ""}
                                                    className="text-reset text-decoration-none text-nowrap"
                                                >
                                                    <Highlighter>{renderOrDash(date)}</Highlighter>
                                                </Link>
                                            </td>
                                            {showCallInfo && (
                                                <React.Fragment>
                                                    <td>
                                                        <Link to={eventLink} target={newTabLink ? "_blank" : ""} className="text-nowrap">
                                                            {newTabLink ? (
                                                                <FontAwesomeIcon icon={faUpRightFromSquare} className="me-2" />
                                                            ) : null}
                                                            <Highlighter>{renderOrDash(event.displayCallNumber)}</Highlighter>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={eventLink}
                                                            target={newTabLink ? "_blank" : ""}
                                                            className="text-reset text-decoration-none"
                                                        >
                                                            <Highlighter>{renderOrDash(event.call_type)}</Highlighter>
                                                        </Link>
                                                    </td>
                                                </React.Fragment>
                                            )}
                                            {showIncidentInfo && (
                                                <React.Fragment>
                                                    <td>
                                                        <Link
                                                            to={eventLink}
                                                            target={newTabLink ? "_blank" : ""}
                                                            className="text-reset text-decoration-none"
                                                        >
                                                            {!!event && event?.index?.includes("incident") && !!event.id ? (
                                                                <>
                                                                    <Highlighter>{event.id}</Highlighter>
                                                                </>
                                                            ) : (
                                                                "--"
                                                            )}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={eventLink}
                                                            target={newTabLink ? "_blank" : ""}
                                                            className="text-reset text-decoration-none"
                                                        >
                                                            {!!event && event?.index?.includes("incident") && !!event.id ? (
                                                                <Highlighter>{event.incident_type}</Highlighter>
                                                            ) : (
                                                                "--"
                                                            )}
                                                        </Link>
                                                    </td>
                                                </React.Fragment>
                                            )}
                                            {showVictim && (
                                                <td>
                                                    <Link
                                                        to={eventLink}
                                                        target={newTabLink ? "_blank" : ""}
                                                        className="text-reset text-decoration-none"
                                                    >
                                                        {event?.victims && event?.victims.length
                                                            ? event?.victims.map((val: string, index: any) => (
                                                                  <React.Fragment key={index}>
                                                                      <Highlighter>{val}</Highlighter>
                                                                      {index !== event.victims.length ? <br /> : null}
                                                                  </React.Fragment>
                                                              ))
                                                            : "--"}
                                                    </Link>
                                                </td>
                                            )}
                                        </tr>
                                        <tr
                                            className={`${event.id && hoverId === event.id ? "bg-gray" : ""} ${
                                                event.flags && event.flags.length > 0 ? "has-flag-row" : ""
                                            }`}
                                            onMouseEnter={() => {
                                                triggerPopup(event, LayerTypes.event, dispatch);
                                            }}
                                            onMouseLeave={() => dispatch(setShowPopup(false))}
                                        >
                                            <td colSpan={colSpan}>
                                                <Link
                                                    to={eventLink}
                                                    target={newTabLink ? "_blank" : ""}
                                                    className="text-reset text-decoration-none"
                                                >
                                                    <div className="text-secondary">
                                                        <Highlighter>{renderOrDash(address)}</Highlighter>
                                                    </div>
                                                </Link>
                                            </td>
                                        </tr>
                                        {event.flags && event.flags.length > 0 && (
                                            <tr className={event.id && hoverId === event.id ? "bg-gray" : ""}>
                                                <td colSpan={colSpan} className="pt-0">
                                                    <Link
                                                        to={eventLink}
                                                        target={newTabLink ? "_blank" : ""}
                                                        className="text-reset text-decoration-none"
                                                    >
                                                        <Tags tags={(event?.flags as any) || []} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </Table>
                    {totalPages > 1 && (
                        <div className="d-flex justify-content-end mt-2">
                            <FMPaginationNonLinks
                                page={currentPage + 1}
                                totalPages={totalPages}
                                setPage={(page: number) => {
                                    setCurrentPage(page - 1);
                                    scrollToTop();
                                }}
                            />
                        </div>
                    )}
                </>
            ) : (
                <Alert variant="secondary" className="text-center">
                    No Results Found
                </Alert>
            )}
        </>
    );
};

export default BaseEventTable;
