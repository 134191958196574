import React from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { useTrackDimension } from "../../../hooks/useTrackDimension";
import DashboardSimpleLine from "../../insightsdashboard/charts/dashboardsimpleline/DashboardSimpleLine";
import ErrorBoundary from "../../support/errorboundary/ErrorBoundary";
import { isAfter, sub } from "date-fns";

type LineGraphOwnProps = {
    data: any;
    name: string;
    partialUrl: string;
};

/**
 * Creates the LineGraph presentation component
 * @param data
 * @param name
 * @param partialUrl
 * @constructor
 */

const LineGraph = ({ data, name, partialUrl }: LineGraphOwnProps) => {
    const [graphToDisplay, setGraphToDisplay] = React.useState<"calls" | "incidents">("calls");
    const [daysToDisplay, setDaysToDisplay] = React.useState<number>(90);
    const now = new Date();

    let beautifiedData: any[] = [];
    const formatDataForLine = (data: any) => {
        return data && Array.isArray(data)
            ? data.map((datum: any) => {
                  const splitDate = datum.key_as_string.split("/");
                  const x = new Date(splitDate[2], Number(splitDate[0]) - 1, splitDate[1]);
                  const bucketData = datum?.by_type?.buckets?.find((bucket: any) => bucket?.key?.includes(graphToDisplay));
                  const y = bucketData?.doc_count || 0;
                  return { x, y };
              })
            : [];
    };

    if (data) {
        if (daysToDisplay === 7) {
            if (data?.day?.buckets) {
                const sevenDays = sub(now, { days: 8 });
                beautifiedData = data?.day?.buckets.filter((day: any) => isAfter(new Date(day.key_as_string), sevenDays));

                if (beautifiedData) {
                    beautifiedData = formatDataForLine(beautifiedData);
                }
            }
        } else if (daysToDisplay === 30) {
            if (data?.day?.buckets) {
                const thirtyDays = sub(now, { days: 31 });
                beautifiedData = data?.day?.buckets.filter((day: any) => isAfter(new Date(day.key_as_string), thirtyDays));

                if (beautifiedData) {
                    beautifiedData = formatDataForLine(beautifiedData);
                }
            }
        } else if (daysToDisplay === 90) {
            if (data?.week?.buckets) {
                const ninetyDays = sub(now, { days: 91 });
                beautifiedData = data?.week?.buckets.filter((day: any) => isAfter(new Date(day.key_as_string), ninetyDays));

                if (beautifiedData) {
                    beautifiedData = formatDataForLine(beautifiedData);
                }
            }
        } else if (daysToDisplay === 365) {
            if (data?.month?.buckets) {
                beautifiedData = data?.month?.buckets.slice(-1 * 12);

                if (beautifiedData) {
                    beautifiedData = formatDataForLine(beautifiedData);
                }
            }
        }

        // if one or less points, then add two points at beginning and end of date range with 0 events
        if (beautifiedData.length <= 1) {
            const currentDate = new Date();
            beautifiedData.unshift({ x: sub(currentDate, { days: daysToDisplay }), y: 0 });
            beautifiedData.push({ x: currentDate, y: 0 });
        }
    }

    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const { width } = useTrackDimension(containerRef, 0, 0);

    React.useEffect(() => {
        setGraphToDisplay("calls");
        setDaysToDisplay(90);
    }, [name]);

    return (
        <ErrorBoundary errorComponentVariant="large">
            <div className="mt-3 mt-xl-0">
                <Row className="mt-xl-n5 mb-4" ref={containerRef}>
                    <Col xs={8} className="z-index-1000">
                        <Stack direction="horizontal" className="justify-content-center">
                            <span
                                className={`badge me-2 p-2 cursor-pointer ${
                                    daysToDisplay === 7 ? "bg-primary text-white" : "bg-light text-dark"
                                }`}
                                onClick={() => setDaysToDisplay(7)}
                            >
                                1 wk
                            </span>
                            <span
                                className={`badge me-2 p-2 cursor-pointer ${
                                    daysToDisplay === 30 ? "bg-primary text-white" : "bg-light text-dark"
                                }`}
                                onClick={() => setDaysToDisplay(30)}
                            >
                                1 mo
                            </span>
                            <span
                                className={`badge me-2 p-2 cursor-pointer ${
                                    daysToDisplay === 90 ? "bg-primary text-white" : "bg-light text-dark"
                                }`}
                                onClick={() => setDaysToDisplay(90)}
                            >
                                3 mo
                            </span>
                            <span
                                className={`badge me-2 p-2 cursor-pointer ${
                                    daysToDisplay === 365 ? "bg-primary text-white" : "bg-light text-dark"
                                }`}
                                onClick={() => setDaysToDisplay(365)}
                            >
                                1 yr
                            </span>
                        </Stack>
                    </Col>
                    <Col xs={4} className="z-index-1000">
                        <Stack direction="horizontal" className="justify-content-center">
                            <span
                                className={`badge me-2 p-2 cursor-pointer ${
                                    graphToDisplay === "calls" ? "bg-primary text-white" : "bg-light text-dark"
                                }`}
                                onClick={() => setGraphToDisplay("calls")}
                            >
                                911 Calls
                            </span>
                            <span
                                className={`badge me-2 p-2 cursor-pointer ${
                                    graphToDisplay === "incidents" ? "bg-primary text-white" : "bg-light text-dark"
                                }`}
                                onClick={() => setGraphToDisplay("incidents")}
                            >
                                Police Reports
                            </span>
                        </Stack>
                    </Col>
                </Row>
                <DashboardSimpleLine
                    data={beautifiedData}
                    width={width}
                    height={175}
                    partialUrl={`${partialUrl}&index=${graphToDisplay}`}
                />
            </div>
        </ErrorBoundary>
    );
};

export default LineGraph;
