import DOMPurify from "dompurify";
import parse from "html-react-parser";

export const underToWords = (str: string) => {
    if (!str) {
        return "";
    }
    return str
        .split("_")
        .map((s: any) => s && s.charAt(0).toUpperCase() + s.slice(1))
        .join(" ");
};

export const toUpperCase = (str: string) => {
    if (!str) {
        return "";
    }
    return str
        .split(" ")
        .map((s: string) => s && s.charAt(0).toUpperCase() + s.slice(1))
        .join(" ");
};

export const parseAsHTML = (str: string) => {
    return parse(DOMPurify.sanitize(str));
};

/**
 *
 * @param field value to attempt to display
 * @param fallback value to display if field is not renderable
 * @returns
 */
export const renderField = (field: string, fallback = "--") => {
    return field || fallback;
};
