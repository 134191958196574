import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { formatBirthdayAndDOB, formatDate } from "../../utils/date";
import { formatName } from "../../utils/person";
import { faPerson, faPersonDress } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { useManageSearchParams } from "../../hooks/useManageSearchParams";
import { PERSON_ACCENT } from "../../utils/colors";

import { useMultiAgency } from "../../hooks/useMultiAgency";
import useAgencySettings from "../../hooks/useAgencySettings";
import LinkWrapper from "../LinkWrapper";

type OwnProps = {
    associatedPerson: any;
    eventData?: any[];
    showRole: boolean;
    agencyId: string | undefined;
};

const PersonCard = ({ associatedPerson, eventData, showRole, agencyId }: OwnProps) => {
    const { constructPersonUrl } = useMultiAgency();
    const { processSearchParams } = useManageSearchParams();
    const { incidentText } = useAgencySettings();

    const formattedName = formatName(associatedPerson);

    const constructedPersonURL = constructPersonUrl(agencyId, associatedPerson, processSearchParams([], ["currentTab"]));

    /**  We assume we will or not have eventData, fall back to empty array
    /* if we have eventData, then we are sending it from the Person Page
    /* if eventData is undefined, then we are coming from the Address Page
    */
    const associatedEvents =
        eventData !== undefined
            ? eventData?.filter((event: any) =>
                  event.source?.involved_people?.find((involvedPerson: any) => involvedPerson.person_id === associatedPerson.person_id)
              )
            : associatedPerson?.associated_events || [];

    return (
        <ListGroup.Item as={Card} className="p-0 mb-3 border-0">
            <Card.Header className="w-100 d-flex align-items-center justify-content-between border rounded">
                <div className="d-flex flex-wrap align-items-center">
                    <FontAwesomeIcon
                        icon={
                            associatedPerson.gender === "F" || associatedPerson.gender?.toLowerCase() === "female"
                                ? faPersonDress
                                : faPerson
                        }
                        className="fs-2 me-4"
                        color={PERSON_ACCENT}
                    />
                    {!!formattedName ? (
                        <LinkWrapper
                            isAnchor={constructedPersonURL.isNextFELink}
                            className="fw-bold me-3 pendo_person_name_link_button"
                            to={constructedPersonURL.url}
                        >
                            {formattedName}
                        </LinkWrapper>
                    ) : (
                        <span className="fw-bold me-3">Unknown</span>
                    )}
                    {!!associatedPerson.birthdate && <span>DOB: {formatBirthdayAndDOB(associatedPerson.birthdate)}</span>}
                </div>
                <div>
                    {showRole && (
                        <div>
                            Role: <span className="fw-bold">{associatedPerson.role}</span>
                        </div>
                    )}
                    <span>Person ID: {associatedPerson.person_id || associatedPerson.id || "Unknown"}</span>
                    <br />
                </div>
            </Card.Header>
            <Card.Body className="overflow-hidden pt-2 fm-left-indent">
                <Container className="m-0">
                    <Row>
                        <Col xs={12} md={6} className="fw-normal text-nowrap px-0">
                            <Row>
                                <Col className="text-wrap">
                                    <span>{associatedPerson.full_address}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={5} sm={4} md={6}>
                                    <span>
                                        <span className="text-secondary me-2">Gender:</span>
                                        <span className="">{associatedPerson.gender || "Unknown"}</span>
                                    </span>
                                </Col>
                                <Col xs={5} sm={4} md={6}>
                                    <span>
                                        <span className="text-secondary me-2">Race:</span>
                                        <span>{associatedPerson.race || "Unknown"}</span>
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6}>
                            {associatedEvents && associatedEvents.length > 0 && (
                                <>
                                    <Row>
                                        <Col className="d-none d-md-flex justify-content-end">
                                            <span>
                                                Last {incidentText}: {formatDate(associatedPerson.latest_incident_occurred_at)}
                                            </span>
                                        </Col>
                                        <Col className="d-flex d-md-none p-0">
                                            <span>
                                                Last {incidentText}: {formatDate(associatedPerson.latest_incident_occurred_at)}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-none d-md-flex justify-content-end">
                                            <div className="p-0">
                                                {associatedEvents.length} Associated {associatedEvents.length > 1 ? "Events" : "Event"}
                                            </div>
                                        </Col>
                                        <Col className="d-flex d-md-none p-0">
                                            <div className="p-0">
                                                {associatedEvents.length} Associated {associatedEvents.length > 1 ? "Events" : "Event"}
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </ListGroup.Item>
    );
};

export default PersonCard;
