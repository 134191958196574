import React, { MouseEventHandler, ReactNode } from "react";
import { ListGroup, Offcanvas, Stack, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseyeArrow } from "@fortawesome/pro-solid-svg-icons";
import { faMessage } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { convertDateToLocalUTC, formatDate, formatDateToISO } from "../utils/date";
import { add } from "date-fns";
import usePermissions from "../hooks/usePermissions";
import { FromES, UserShare } from "../types/old_v1/types";
import { getUrlPathname } from "../utils/url";
import ErrorBoundary from "./support/errorboundary/ErrorBoundary";

type OwnProps = {
    follows?: any[];
    shares?: FromES<UserShare>[];
    showNotifications: boolean;
    setShowNotifications: Function;
};

enum FilterType {
    All = "ALL",
    Follow = "FOLLOW",
    Share = "SHARE",
}

const now = new Date();

type NotificationLinkProps = {
    children: ReactNode;
    onClick: MouseEventHandler<HTMLAnchorElement>;
    to: string;
};
const NotificationLink = ({ children, onClick, to }: NotificationLinkProps) => {
    const { hasPermission } = usePermissions();

    // if user has permission to use nextfe search, use the standard anchor tag to navigate to the search page
    return hasPermission("use_nextfe_search") ? (
        <a href={to}>{children}</a>
    ) : (
        <Link to={to} onClick={onClick}>
            {children}
        </Link>
    );
};

export const NotificationsList = ({ follows, shares, showNotifications, setShowNotifications }: OwnProps) => {
    const { hasPermission } = usePermissions();
    const [list, setList] = React.useState<any[]>([]);
    const [filterNotificationType, setFilterNotificationType] = React.useState<FilterType>(FilterType.All);

    const canUseShare = hasPermission("share");
    const canUseFollow = hasPermission("follows");

    React.useEffect(() => {
        let displayList: any[] = [];
        if (follows && follows.length) {
            displayList = follows.filter((f) => f.eventCount > 0).map((f) => ({ ...f, type: FilterType.Follow, sortDate: f.saved_date }));
        }
        if (shares && shares.length) {
            displayList = displayList.concat(
                shares.map((s) => ({
                    ...s,
                    type: FilterType.Share,
                    sortDate: s.shared_at,
                    id: `${s.shared_at}|${s.shared_at}`,
                }))
            );
        }
        displayList.sort((notification1, notification2) => (notification1.sortDate <= notification2.sortDate ? 1 : -1));
        setList(displayList);
    }, [follows, shares]);

    const closeNotifications = () => {
        setShowNotifications(false);
    };

    const goTo = (item: any) => {
        if (item.type !== FilterType.Share) {
            const urlParams = new URLSearchParams();
            urlParams.set("query", item.text);

            // If the user has the next fe search permission we need to format the date params to match the new style
            // return the new search url based on desired destination
            if (hasPermission("use_nextfe_search")) {
                urlParams.set(
                    "search_settings",
                    JSON.stringify({
                        occurred_at: {
                            gte: convertDateToLocalUTC(item.saved_date).toISOString(),
                            lte: formatDateToISO(add(now, { days: 1 })),
                        },
                    })
                );

                return `/search/results/events?${urlParams.toString()}`;
            } else {
                urlParams.set("sort", "relevance");
                urlParams.set("dateRange", "custom");
                urlParams.set("startDate", convertDateToLocalUTC(item.saved_date).toISOString());
                urlParams.set("endDate", formatDateToISO(add(now, { days: 1 })));

                return `/app/search?${urlParams.toString()}`;
            }
        } else {
            return getUrlPathname(item?.uuid_url);
        }
    };

    const filteredList =
        filterNotificationType === FilterType.All
            ? list
            : filterNotificationType === FilterType.Follow
            ? list.filter((item) => item.type === FilterType.Follow)
            : list.filter((item) => item.type === FilterType.Share);

    return (
        <Offcanvas show={showNotifications} onHide={closeNotifications} placement="end">
            <Offcanvas.Header closeButton className="border-bottom">
                <h3>Notifications Center</h3>
            </Offcanvas.Header>
            <ErrorBoundary errorComponentVariant="large">
                <Offcanvas.Body className="d-flex flex-column">
                    <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                        <div className="d-flex flex-row align-items-center">
                            {canUseFollow && (
                                <Link onClick={closeNotifications} to="/app/follows" className="me-3">
                                    All Follows
                                </Link>
                            )}
                            {canUseShare && (
                                <Link onClick={closeNotifications} to="/app/shares">
                                    All Shares
                                </Link>
                            )}
                        </div>
                        {canUseShare && canUseFollow && (
                            <ToggleButtonGroup
                                name="options"
                                size="sm"
                                type="radio"
                                value={filterNotificationType}
                                onChange={setFilterNotificationType}
                            >
                                <ToggleButton id={FilterType.All} value={FilterType.All} variant="outline-primary">
                                    All
                                </ToggleButton>
                                <ToggleButton id={FilterType.Follow} value={FilterType.Follow} variant="outline-primary">
                                    <FontAwesomeIcon icon={faBullseyeArrow} className="me-2" />
                                    Follows
                                </ToggleButton>
                                <ToggleButton id={FilterType.Share} value={FilterType.Share} variant="outline-primary">
                                    <FontAwesomeIcon icon={faMessage} className="me-2" />
                                    Shares
                                </ToggleButton>
                            </ToggleButtonGroup>
                        )}
                    </div>
                    <ListGroup className="overflow-auto">
                        {filteredList &&
                            filteredList.map((item) => (
                                <ListGroup.Item key={item.id}>
                                    <Stack>
                                        <div className="d-flex flex-row justify-content-between">
                                            <NotificationLink to={goTo(item)} onClick={closeNotifications}>
                                                <FontAwesomeIcon
                                                    icon={item.type === FilterType.Share ? faMessage : faBullseyeArrow}
                                                    className={`me-2 ${
                                                        item.type === FilterType.Share && !!item.viewed_at ? "text-primary" : "text-danger"
                                                    }`}
                                                />
                                                {item.type === FilterType.Share ? (
                                                    <span>{item.title?.length ? item.title : "Shared Item"}</span>
                                                ) : (
                                                    <span>{item.text}</span>
                                                )}
                                            </NotificationLink>
                                            <span className="text-nowrap">{formatDate(item.sortDate, "--", "MM/dd/yy h:mm aaa")}</span>
                                        </div>
                                        {item.type === FilterType.Share && (
                                            <small className="text-secondary">Shared by {item.shared_by_email}</small>
                                        )}
                                    </Stack>
                                </ListGroup.Item>
                            ))}
                    </ListGroup>
                </Offcanvas.Body>
            </ErrorBoundary>
        </Offcanvas>
    );
};

export default NotificationsList;
