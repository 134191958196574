import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

/**
 * Displayed to indicate to user that they do not have permissions to view this page (HTTP 403 equivalent)
 */
const Unauthorized = () => {
    return (
        <Container fluid={true} className="d-flex flex-column flex-grow-1">
            <Row className="align-items-center justify-content-center h-100">
                <Col md={5} lg={4}>
                    <h1 className="display-3 fw-bold text-center">Error</h1>
                    <p className="mb-5 text-center text-muted">You do not have permission to access this page.</p>
                    <div className="text-center">
                        <Button as={Link as any} to="/" className="back-to-home">
                            Back to our home page
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Unauthorized;
