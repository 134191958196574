import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type ManageSortSearchParam = {
    selectedSort: string;
    defaultSelectedSort: string;
    updateSortUrlParam: Function;
};

/**
 * Hook used for getting the processed sort value and default sort value.
 * This hook also provides a function to update the URL if the processed sort
 * does not match the URL param value.
 * @returns {ManageSortSearchParam}
 */
export function useManageSortSearchParam(): ManageSortSearchParam {
    const [searchParams] = useSearchParams();
    const params = new URLSearchParams(searchParams);

    const navigate = useNavigate();

    const query = params.get("query");

    const selectedSort = query === "" ? "date" : params.get("sort") || "relevance";
    const defaultSelectedSort = query === "" ? "date" : "relevance";

    const updateSortUrlParam = () => {
        if (selectedSort !== params.get("sort")) {
            params.set("sort", selectedSort);
            navigate(`.?${params.toString()}`, { replace: true });
        }
    };

    // This is to ensure we only allow specific sorts are available. Any other sort value
    // that isn't present in the array will be defaulted to relevance without a sortDirection applied.
    // NOTE: This useEffect will cause page history update, should only be used when sort is handled
    // in the URLSearchParams.
    React.useEffect(() => {
        if (!["relevance", "date"].includes(searchParams.get("sort") || "")) {
            searchParams.set("sort", "relevance");
            // delete sortDirection since relevance default direction is NONE
            searchParams.delete("sortDirection");
            // Use replace to prevent extra history item from being pushed in
            // This reduces the necessity of ensuring each link to search has the sort query param
            navigate(`.?${searchParams.toString()}`, { replace: true });
        }
    }, [searchParams, navigate]);
    return {
        selectedSort,
        defaultSelectedSort,
        updateSortUrlParam,
    };
}
