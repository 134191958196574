import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faSiren } from "@fortawesome/pro-light-svg-icons";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useGetUniqueIncidentTypesQuery } from "../../../api/api";
import { processFilterAgg } from "../../../utils/old_v1/helpers";
import OptionFilter from "./OptionFilter";
import { RadarConfigNameEnum } from "../../../types/old_v1/types";
import useAgencySettings from "../../../hooks/useAgencySettings";
import { useFilters } from "../../../hooks/useFilters";

const IncidentTypeFilterConnected = ({
    close,
    clearView,
    pendoClass = "",
}: {
    close: Function;
    clearView?: boolean;
    pendoClass?: string;
}) => {
    const { data: optionData } = useGetUniqueIncidentTypesQuery({});
    const options = processFilterAgg(optionData);

    return <IncidentTypeFilter pendoClass={pendoClass} close={close} options={options} clearView={clearView} />;
};

const IncidentTypeFilter = ({
    close,
    options,
    clearView,
    pendoClass = "",
}: {
    close: Function;
    options: any[];
    clearView?: boolean;
    pendoClass?: string;
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { incidentText } = useAgencySettings();
    const incidentTypes = searchParams.get("incidentTypes");
    const incidentTypesList = incidentTypes ? incidentTypes.split(",") : [];
    const label = incidentTypes
        ? incidentTypesList.length > 1
            ? `${incidentTypesList.length} ${incidentText} Types`
            : incidentTypes
        : `${incidentText} Types`;
    const { updateFilters } = useFilters({ skipBeatsQuery: true });

    const clear = () => {
        const updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.delete("incidentTypes");
        if (clearView) {
            updatedSearchParams.set("view", RadarConfigNameEnum.CUSTOM);
        }
        setSearchParams(updatedSearchParams.toString());
        updateFilters("incidentTypes", null, updatedSearchParams);
    };

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            placement={"bottom-start"}
            overlay={
                <Popover className="mw-100 hide-arrow">
                    <Popover.Body>
                        <OptionFilter
                            options={options}
                            paramName="incidentTypes"
                            pendoClass={pendoClass}
                            close={close}
                            label={`Choose ${incidentText} Types`}
                            clearView={clearView}
                        />
                    </Popover.Body>
                </Popover>
            }
        >
            <Button
                data-testid="incident-filter-button"
                className="cursor-pointer mb-2 ms-2 text-nowrap rounded-pill"
                variant={incidentTypesList && incidentTypesList.length ? "secondary" : "outline-secondary"}
                size="sm"
                onClick={() => close("incidentTypes")}
            >
                <FontAwesomeIcon icon={faSiren} />
                &nbsp;{decodeURIComponent(label)}
                {incidentTypes && (
                    <FontAwesomeIcon
                        size="sm"
                        icon={faCircleXmark}
                        className="ms-1"
                        onClick={(e) => {
                            e.stopPropagation();
                            clear();
                        }}
                    />
                )}
            </Button>
        </OverlayTrigger>
    );
};

export default IncidentTypeFilterConnected;
