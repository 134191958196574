import React from "react";
import { useGetUniqueRacesQuery } from "../../api/api";
import { processFilterAgg } from "../../utils/helpers";
import MultiSelectFilter from "./MultiSelectFilter";

const RaceFilter = () => {
    // gets the options form index
    const { data: optionData } = useGetUniqueRacesQuery({});
    let options = processFilterAgg(optionData);

    options = options
        .map((opt: any) => ({ id: opt?.id, name: opt?.option, value: opt?.option }))
        .sort((a: any, b: any) => (a?.value < b?.value ? -1 : 1));

    return (
        <MultiSelectFilter
            pendoClass="pendo_race_filter_select"
            options={options}
            urlParamName={"race"}
            inputPlaceholder={"Select Races"}
        />
    );
};

RaceFilter.displayName = "RaceFilter";
export default RaceFilter;
