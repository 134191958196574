import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useManageSearchParams } from "../hooks/useManageSearchParams";
import useNextAwarePathname, { useNextAwareNavigate } from "../hooks/useNextAwarePathname";

const BackToSearchButton = () => {
    const { processSearchParams } = useManageSearchParams();

    const { mainSearchPath } = useNextAwarePathname();
    const { nextAwareNavigate } = useNextAwareNavigate();
    const backNavigateString = `${mainSearchPath}?${processSearchParams(["query"])}`;

    return (
        <Button
            variant="outline-primary"
            className="border border-primary pendo_back_to_search"
            onClick={() => nextAwareNavigate(backNavigateString)}
        >
            <FontAwesomeIcon icon={faChevronLeft} />
            &nbsp;Back to search
        </Button>
    );
};

export default BackToSearchButton;
