import { Table } from "react-bootstrap";
import Highlighter from "../../components/highlighter/Highlighter";
import TableCellLinkWrapper from "../tablecelllinkwrapper/TableCellLinkWrapper";
import { formatAge, formatDateMMDDYYYY } from "../../utils/date";
import Tags from "../../components/tags/Tags";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import useAgencySettings from "../../hooks/useAgencySettings";

const InternalIncidentsTable = ({ showIncidentsDropdown, openRows, row, personName, associateName, person, query, newTabLink }: any) => {
    const { constructEventUrl } = useMultiAgency();
    const { incidentText } = useAgencySettings();

    let sortedRows = row?.incidents && Array.isArray(row.incidents) ? [...row.incidents] : [];

    sortedRows = sortedRows.sort((a: any, b: any) => {
        const formattedAIncidentNumber = a.incident_number ? a.incident_number : "";
        const formattedBIncidentNumber = b.incident_number ? b.incident_number : "";
        return formattedAIncidentNumber > formattedBIncidentNumber ? -1 : 1;
    });

    return (
        <>
            {showIncidentsDropdown && openRows?.includes(row.person_id) ? (
                <Table responsive className="mt-2 mb-2">
                    <thead>
                        <tr>
                            <th className="text-nowrap">{incidentText} Number</th>
                            <th>Occurred</th>
                            <th>Labels</th>
                            <th className="text-nowrap">{incidentText} Type</th>
                            <th className="text-nowrap">
                                <Highlighter>{personName}</Highlighter>'s Role
                            </th>
                            <th className="text-nowrap">
                                <Highlighter>{personName}</Highlighter>'s Age at {incidentText}
                            </th>
                            <th className="text-nowrap">
                                <Highlighter>{associateName}</Highlighter>'s Role
                            </th>
                            <th className="text-nowrap">
                                <Highlighter>{associateName}</Highlighter>'s Age at {incidentText}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedRows.map((incident: any, index) => {
                            const associate = Array.isArray(incident.involved_people)
                                ? incident.involved_people.find((involvedPerson: any) => involvedPerson.person_id === row.person_id)
                                : null;

                            const currentPerson = Array.isArray(incident.involved_people)
                                ? incident.involved_people.find((involvedPerson: any) => involvedPerson.person_id === person?.id)
                                : null;

                            const rowLink = constructEventUrl(
                                incident?.source?.agency_id,
                                undefined,
                                incident?.incident_number,
                                query ? new URLSearchParams({ query }) : undefined
                            );

                            return (
                                <tr key={index}>
                                    <td className={`text-nowrap`}>
                                        <TableCellLinkWrapper
                                            link={rowLink}
                                            child={<Highlighter>{incident.incident_number}</Highlighter>}
                                            newTabLink={newTabLink}
                                            useLinkStyle={true}
                                        />
                                    </td>
                                    <td>
                                        <TableCellLinkWrapper
                                            link={rowLink}
                                            child={<Highlighter>{formatDateMMDDYYYY(incident.occurred_at)}</Highlighter>}
                                            newTabLink={newTabLink}
                                        />
                                    </td>
                                    <td>
                                        <div className="mb-2 mt-2">
                                            <Tags tags={incident.flags} />
                                        </div>
                                    </td>
                                    <td>
                                        <TableCellLinkWrapper
                                            link={rowLink}
                                            child={<Highlighter>{incident.incident_type}</Highlighter>}
                                            newTabLink={newTabLink}
                                        />
                                    </td>
                                    <td>
                                        <TableCellLinkWrapper
                                            link={rowLink}
                                            child={<Highlighter>{currentPerson?.role}</Highlighter>}
                                            newTabLink={newTabLink}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <TableCellLinkWrapper
                                            link={rowLink}
                                            newTabLink={newTabLink}
                                            child={
                                                <Highlighter>
                                                    {currentPerson?.birthdate
                                                        ? formatAge(currentPerson?.birthdate, incident?.occurred_at)
                                                        : "--"}
                                                </Highlighter>
                                            }
                                        />
                                    </td>
                                    <td>
                                        <TableCellLinkWrapper
                                            link={rowLink}
                                            child={<Highlighter>{associate?.role}</Highlighter>}
                                            newTabLink={newTabLink}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <TableCellLinkWrapper
                                            link={rowLink}
                                            child={
                                                <Highlighter>
                                                    {associate?.birthdate ? formatAge(associate?.birthdate, incident?.occurred_at) : "--"}
                                                </Highlighter>
                                            }
                                            newTabLink={newTabLink}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            ) : null}
        </>
    );
};

export default InternalIncidentsTable;
