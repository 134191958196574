import { Container } from "react-bootstrap";
import { useGetPersonPhotosLatestQuery } from "../../api/api";
import FMSpinner from "../../components/Spinner";
import defaultPerson from "../../static/img/person.png";
import useIntegrations from "../../hooks/useIntegrations";
import { useMultiAgency } from "../../hooks/useMultiAgency";

const PersonResultPhotoSection = ({ person }: { person: any }) => {
    const { personPhotosEnabled } = useIntegrations();
    const { getAgencyUUID } = useMultiAgency();

    const { data: personPhotoResp, isFetching: isFetchingPersonPhotoResp } = useGetPersonPhotosLatestQuery(
        {
            // TODO: only meant to work for legacy multi-agency -- lots to delete once we migrate to the next multi-agency!
            agency_uuid: getAgencyUUID(person?.agency_id),
            person_id: person.id,
        },
        { skip: !personPhotosEnabled }
    );

    let image_base64;
    let isPersonPhotoRespSuccessful = false;
    if (personPhotoResp?.results) {
        image_base64 = personPhotoResp?.results?.[0]?.base64_image;
        isPersonPhotoRespSuccessful = personPhotoResp?.success;
    }

    return (
        <>
            {isFetchingPersonPhotoResp && (
                <div className="d-flex justify-content-center align-items-center" style={{ width: "150px" }}>
                    <FMSpinner />
                </div>
            )}
            {image_base64 && !isFetchingPersonPhotoResp && isPersonPhotoRespSuccessful && (
                <Container
                    className="p-0 d-flex mt-3 mx-auto px-0 overflow-hidden flex-grow-1 rounded"
                    style={{ minWidth: "120px", maxWidth: "120px", height: "150px" }}
                >
                    <img
                        alt="Person Latest"
                        className="rounded"
                        style={{ objectFit: "cover" }}
                        src={`data:image/png;base64, ${image_base64 || ""}`}
                    />
                </Container>
            )}
            {!image_base64 && !isFetchingPersonPhotoResp && isPersonPhotoRespSuccessful && (
                <Container
                    className="p-0 mt-3 mx-auto px-0 overflow-hidden rounded"
                    style={{ minWidth: "120px", maxWidth: "120px", height: "150px" }}
                >
                    <img
                        alt="Default Person - No Available Data"
                        className="rounded"
                        style={{ minWidth: "120px", maxWidth: "120px", height: "auto" }}
                        src={defaultPerson}
                    />
                </Container>
            )}
        </>
    );
};

export default PersonResultPhotoSection;
