import { elasticHitsExtraction } from "../elastic";

export const processTextSummarizationData = (textSummarizations: any) =>
    textSummarizations.reduce((coll: any, curr: any) => {
        const narrativeId = createNarrativeId(curr);
        if (narrativeId) {
            coll[narrativeId] = curr;
        }
        return coll;
    }, {});

/*
Since the narratives are now nested documents inside of the incident index it takes extra work to
get the highlighted narratives.
*/
export const processNarrativeData = (narrativesData: any) =>
    narrativesData &&
    (elasticHitsExtraction(narrativesData) || [])
        .filter((incidentDoc: any) => !!incidentDoc?._source?.narratives)
        .map((incidentDoc: any) => {
            const innerHits = incidentDoc?.inner_hits?.narratives?.hits?.hits || [];
            return incidentDoc._source.narratives.map((narrDoc: any) => {
                let retDoc = narrDoc;
                if (innerHits) {
                    const matchingInnerDoc = innerHits.find(
                        (childDoc: any) =>
                            childDoc?._source?.author === narrDoc.author &&
                            childDoc?._source?.created_at === narrDoc.created_at &&
                            childDoc?._source?.narrative_type === narrDoc.narrative_type
                    );
                    if (matchingInnerDoc) {
                        if (
                            matchingInnerDoc?.highlight &&
                            matchingInnerDoc?.highlight["narratives.body"] &&
                            matchingInnerDoc?.highlight["narratives.body"].length
                        ) {
                            retDoc = {
                                ...matchingInnerDoc._source,
                                body: matchingInnerDoc.highlight["narratives.body"][0],
                                hasHighlight: true,
                            };
                        }
                    }
                }
                return {
                    ...retDoc,
                    incident_number: incidentDoc?._source.incident_number,
                };
            });
        })
        .flat()
        .map((narrative: any) => ({
            ...narrative,
            narrativeId: createNarrativeId(narrative),
        }));

export const processNarrativeDataV2 = (highlightData: any, narrativesData: any, incident_number: any) =>
    //loop through large list of narratives and replace with highlight data if found
    narrativesData &&
    narrativesData
        .map((narrativeSourceDoc: any) => {
            let returnDocument = { ...narrativeSourceDoc, incident_number };
            if (highlightData) {
                const matchingInnerDoc = highlightData.find(
                    (possibleHighlightDocument: any) =>
                        possibleHighlightDocument?.body_hash === narrativeSourceDoc?.body_hash &&
                        possibleHighlightDocument?.author === narrativeSourceDoc?.author &&
                        possibleHighlightDocument?.created_at === narrativeSourceDoc?.created_at &&
                        possibleHighlightDocument?.narrative_type === narrativeSourceDoc?.narrative_type
                );
                if (matchingInnerDoc) {
                    if (matchingInnerDoc?.contains_highlight) {
                        returnDocument = {
                            ...matchingInnerDoc,
                        };
                    }
                }
            }
            return {
                ...returnDocument,
                contains_highlight: returnDocument.contains_highlight || false,
                id: returnDocument.id || createNarrativeId(returnDocument),
            };
        })
        // filter out narratives with duplicate id and body_hash
        // body_hash may not be present in all agencies, but provides a more unique identifier when available
        .filter(
            (value: any, index: number, self: any) =>
                index === self.findIndex((t: any) => `${t.id}_${t?.body_hash}` === `${value.id}_${value?.body_hash}`)
        );

const createNarrativeId = (narrative?: any) => narrative && `${narrative.incident_number}_${narrative.author}_${narrative.created_at}`;
