import React from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { CustomOption } from "./MultiSelectFilter";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";
import useEncoderDecoder from "../../hooks/useEncoderDecoder";
import { useFilters } from "../../hooks/useFilters";

type OwnProps = {
    options: { name: string; value: string; present?: boolean | null | undefined; count?: number | null | undefined }[];
    pendoClass?: string;
    urlParamName: string;
    filterLabel: string;
    clearURLParams?: string[];
    clearFilterParams?: string[];
};

const SingleSelectFilter = ({ options, pendoClass = "", urlParamName, filterLabel, clearURLParams, clearFilterParams }: OwnProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setSearchParams] = useSearchParams();
    const { searchParams } = useSingleAgencyFilter();
    const { decodedData: urlOption } = useEncoderDecoder({ encodedData: searchParams.get(urlParamName) || "" });
    const { updateFilters } = useFilters({ skipBeatsQuery: true });

    const selectOptions: readonly any[] = options
        .map((o) => {
            return { value: o.value, label: o.name, present: o?.present, count: o?.count };
        })
        .sort((a, b) => (a.present === b.present ? 0 : a.present ? -1 : 1));

    const selectedOption: any = selectOptions.find((x) => x.value === urlOption);

    const clearParams = (newSearchParams: URLSearchParams) => {
        if (clearURLParams && clearURLParams.length) {
            clearURLParams.forEach((param) => {
                newSearchParams.delete(param);
            });
        }

        //remove agency specific filter object params from the url
        if (clearFilterParams && clearFilterParams.length) {
            clearFilterParams.forEach((param: string) => {
                updateFilters(param, null, newSearchParams);
            });
        }
    };

    const removedSelectedOption = () => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(urlParamName);

        clearParams(newSearchParams);

        newSearchParams.delete("page");
        setSearchParams(newSearchParams);
    };
    const updateSelectedOption = (newOption: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(urlParamName, encodeURIComponent(newOption));

        clearParams(newSearchParams);

        newSearchParams.delete("page");
        setSearchParams(newSearchParams);
    };

    const handleClick = (newValue: any) => {
        if (newValue === null || newValue.value === selectedOption) {
            removedSelectedOption();
        } else {
            updateSelectedOption(newValue.value);
        }
    };

    return (
        <Select
            components={{ Option: CustomOption }}
            isSearchable={true}
            isClearable={true}
            isDisabled={selectOptions.length === 0}
            options={selectOptions}
            onChange={(e) => {
                handleClick(e);
            }}
            value={selectedOption}
            placeholder={filterLabel}
            className={`react-select-container ${pendoClass}`}
            classNamePrefix="react-select"
            menuPosition="fixed"
        />
    );
};

export default SingleSelectFilter;
