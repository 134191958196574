import { formatAge, formatDateWithoutTime } from "./date";
import { PersonResult } from "../types/old_v1/types";

export const formatRace = (race: string[]) => {
    return (race ? race[0] : "--").toUpperCase();
};

export const formatGender = (gender: string[]) => {
    return (gender ? gender[0] : "--").toUpperCase();
};

export const formatBirthdayAndDOB = (date: string) => {
    const birthdate = formatDateWithoutTime(date);
    let formatted = `${birthdate}`;

    const age = formatAge(date);

    if (age && age !== "--") {
        formatted += ` (${age} yrs)`;
    }

    return formatted;
};

export const formatName = (person: PersonResult) => {
    const namePieces = [];

    if (person?.first_name) {
        namePieces.push(person.first_name);
    }
    if (person?.middle_name) {
        namePieces.push(person.middle_name);
    }
    if (person?.last_name) {
        namePieces.push(person.last_name);
    }

    return namePieces.join(" ");
};
